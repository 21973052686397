import React, { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import "../../styles/activate-account.css";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Modal } from "react-bootstrap";
import ClanInvite from "./ClanInvite";

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  onClickOpenClanInvite = () => {
    this.setState({
      openClanInvite: true
    });
  }

  onClickCloseClanInvite = () => {
    this.setState({
      openClanInvite: false
    });
  }

  render() {
    const {
      openClanInvite,
    } = this.state

    return (
      <>
        <div className="p-3">
          <IoIosCloseCircleOutline className="close-icon" onClick={()=>{this.props.onClick('openAddMember')}} />
          <p className="fs-20 fw-600 text-center text-white mb-0">Add Member</p>
          <p className="fs-12 texte-center text-white mb-1">Invite from the existing clan members</p>

          <form >
            <InputGroup className="mb-3">
              <FormControl placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" />
              <InputGroup.Text id="basic-addon2">
                <AiOutlineSearch className="btn-size-20 fs-16 ms-3 text-white flex-shrink-0" />
              </InputGroup.Text>
            </InputGroup>

            <div className="owner-body">
              <div className="col-3">
                <div className="owner-avatar"></div>
              </div>
              <div className="col-6">
                <div className="owner-name fs-16 fw-500 text-white float-start">Jean Shawn</div>
              </div>
              <div className="col-3" style={{ textAlign: 'right' }}>
                <button type="button" className="btn clanMinus-icon" data-dismiss="modal" aria-label="Close" onClick={this.onClickOpenConfirm}>
                  <AiFillMinusCircle className="btn-size-20 text-white cursor-pointer" />
                </button>
              </div>
            </div>

            <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" type="submit" value="Submit" onClick={this.linkSectionContinue}>Add</button>
            <p className="fs-12 texte-center text-white mb-0" onClick={this.onClickOpenClanInvite}>Invite via link</p>
          </form>
        </div>

        <Modal show={openClanInvite} centered dialogClassName="ClanInvite-modal">
          <Modal.Body>
            <ClanInvite openClanInvite={openClanInvite}
              onClickCloseClanInvite={this.onClickCloseClanInvite}
              onClickOpenClanInvite={this.onClickOpenClanInvite} />
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default AddMember