import React, { Component } from 'react'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//icons
import { FiPlus } from "react-icons/fi";
import stages from "../../../../images/icon/stages.png";

import '../../../../styles/Tournaments.css';
import TournamentHeader from '../common/TournamentHeader';
import { IoInformationCircleOutline } from "react-icons/io5";
import { errorHandler, getMatchesStructure, getTournamentData, getWebData, is_Natural, PlayoffFormats } from '../../../../utils/common.utils';
import TournamentApis from '../../../../helper/tournament.api';
import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import ConfirmationModal from '../../../popup/ConfirmationModal';
import { AiFillDelete, AiOutlineCheck } from 'react-icons/ai';
import { halfThreshold, powerOfTwo, validateMaxParticipant } from '../../../../utils/Validations';
import { WARNING } from '../../../../utils/Strings.utils';
import ReactTooltip from 'react-tooltip';
import { BsInfoCircle } from 'react-icons/bs';

export default class DuelDoubleElimination extends Component {

    constructor(props) {
        super(props)
        const queryParams = new URLSearchParams(window.location.search);
        let localData = JSON.parse(localStorage.getItem('duel-double-elimination'))

        this.state = {
            tournamentDetails: null,
            openConfirmationModal: false,
            openEditConfirmationModal: false,
            maxParticipants: (!queryParams.get('isUpdate') && localData?.maxParticipants && (localData?.tournamentId === props.match.params.id)) ? localData.maxParticipants : '',
            threshold: (!queryParams.get('isUpdate') && localData?.threshold && (localData?.tournamentId === props.match.params.id)) ? localData.threshold : '',
            stageName: (!queryParams.get('isUpdate') && localData?.stageName && (localData?.tournamentId === props.match.params.id)) ? localData.stageName : '',
            updatedMatches: false,
            enabledGrandFinal: (!queryParams.get('isUpdate') && localData?.enabledGrandFinal && (localData?.tournamentId === props.match.params.id)) ? localData.enabledGrandFinal : 'NONE',
            minParticipant: null,
            playoffFormat: (!queryParams.get('isUpdate') && localData?.playoffFormat && (localData?.tournamentId === props.match.params.id)) ? localData.playoffFormat : 1,
        }
        this.localObject = {
            maxParticipants: (!queryParams.get('isUpdate') && localData?.maxParticipants && (localData?.tournamentId === props.match.params.id)) ? localData.maxParticipants : '',
            threshold: (!queryParams.get('isUpdate') && localData?.threshold && (localData?.tournamentId === props.match.params.id)) ? localData.threshold : '',
            stageName: (!queryParams.get('isUpdate') && localData?.stageName && (localData?.tournamentId === props.match.params.id)) ? localData.stageName : '',
            enabledGrandFinal: (!queryParams.get('isUpdate') && localData?.enabledGrandFinal && (localData?.tournamentId === props.match.params.id)) ? localData.enabledGrandFinal : 'NONE',
            playoffFormat: (!queryParams.get('isUpdate') && localData?.playoffFormat && (localData?.tournamentId === props.match.params.id)) ? localData.playoffFormat : 1,
            tournamentId: this.props.match.params.id
        }
        this.props.updateLoader(true);
        getTournamentData(this.props.match.params.id, this.setData)

        this.tournament = new TournamentApis();

        this.isUpdate = queryParams.get('isUpdate');
        this.index = queryParams.get('index');
        this.editIsUpdate = false;
        this.editStages = [];
        this.isValuesChanged = false;
        this.currentStages = null;
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
                threshold: values?.stages[this.index]?.qualificationThreshold ? values?.stages[this.index]?.qualificationThreshold : '',
                stageName: values?.stages[this.index]?.name ? values?.stages[this.index]?.name : this.state.stageName,
                maxParticipants: values?.stages[this.index]?.maxParticipants ? values?.stages[this.index]?.maxParticipants : this.state.maxParticipants,
                enabledGrandFinal: values?.stages[this.index]?.enabledGrandFinal ? values?.stages[this.index]?.enabledGrandFinal : this.state.enabledGrandFinal,
                playoffFormat: values.stages[this.index]?.playoffFormat ? values.stages[this.index].playoffFormat : this.state.playoffFormat,
                minParticipant: values?.stages?.length ? values?.stages[(values?.stages?.length > 1 && this.isUpdate) ? values?.stages?.length - 2 : values?.stages?.length - 1].qualificationThreshold : null
            });
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }


    onSubmit = (values) => {
        var isUpdate;
        if (this.isUpdate === null) {
            isUpdate = false;
        } else {
            isUpdate = true;
        }

        var stages = this.state.tournamentDetails.stages;

        var stage = {
            level: isUpdate ? this.currentStages[this.index].level : ((this.state.tournamentDetails?.stages?.length) + 1),
            name: values.stageName,
            type: 'DUEL',
            subType: 'Double Elimination',
            path: 'duel-double-elimination',//(isUpdate ? stages[this.index].path : this.index),
            status: 'UPCOMING',
            maxParticipants: parseInt(values.maxParticipant),
            participants: 0,
            qualificationThreshold: parseInt(values.qualificationThreshold),
            playoffFormat: (values.playoffFormat ? parseInt(values.playoffFormat) : 1),
            enabledGrandFinal: (values.enabledGrandFinal ? values.enabledGrandFinal : 'NONE'),

        }

        if (isUpdate && !this.state.updatedMatches) {
            stage.groups = this.currentStages[this.index].groups;
        }
        else {
            stage.groups = getMatchesStructure(stage, 2);
        }

        //stage.groups =  isUpdate ? getMatchesStructure(stage,1);

        this.editIsUpdate = isUpdate;
        this.editStages = [stage];
        let previousStageData = this.state.tournamentDetails?.stages[this.index]
        if (isUpdate) {
            if (
                previousStageData.name !== values.stageName ||
                previousStageData.maxParticipants !== values.maxParticipant ||
                previousStageData.enabledGrandFinal !== stage.enabledGrandFinal ||
                previousStageData.qualificationThreshold !== values.qualificationThreshold ||
                previousStageData.playoffFormat !== stage.playoffFormat
            ) {
                this.isValuesChanged = true;
            }
        }

        if (isUpdate && (parseInt(this.index) !== parseInt((this.state.tournamentDetails?.stages.length - 1))) && this.isValuesChanged) {
            this.setState({ openEditConfirmationModal: true })
        } else {
            this.addOrUpdate([stage], isUpdate);
        }


    }

    addOrUpdate = (stages, isUpdate) => {
        if (this.state.tournamentDetails?.participantUserId?.length && this.isUpdate) {
            toast.error('Cannot update the stage after participants added in tournament')
        } else {
            this.props.updateLoader(true);
            this.tournament.addOrUpdateStage(this.props.match?.params?.id, stages, isUpdate)
                .then((res) => {

                    this.props.updateLoader(false);
                    if (isUpdate) {
                        this.props.history.push(`/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure`);
                        toast.success(`Stage updated successfully`);
                    } else {
                        localStorage.removeItem('duel-double-elimination')
                        var index = this.state.tournamentDetails?.stages?.length === 0 ? 0 : this.state.tournamentDetails?.stages?.length;
                        this.props.history.push(`/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure/tournament-stages/${index}/slot-details/0`);
                        toast.success(`Stage created successfully`);
                    }

                }).catch((reason) => {

                    this.props.updateLoader(false);
                    errorHandler(reason);
                });
        }
    }


    deleteStage = (index) => {
        var data = {
            tournamentId: this.props.match?.params?.id,
            stage: parseInt(index)
        }
        // var stages = this.state.tournamentDetails.stages;
        // stages.splice(index, 1);
        // this.update(stages,"deleted");
        this.props.updateLoader(true)
        this.tournament.deleteStage(data)
            .then((res) => {
                this.props.updateLoader(false);
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
                toast.success(`Stage ${parseInt(index) + 1} deleted successfully`)

            }).catch((reason) => {

                this.props.updateLoader(false);
                errorHandler(reason);
            });
    }

    //open confirmation popup
    onClickOpenConfirm = () => {

        this.setState({
            openConfirmationModal: !this.state.openConfirmationModal,
        });
    };

    updateValues = (e, type) => {
        e.preventDefault()
        if (!this.isUpdate) {
            this.localObject[type] = e.target.value;
            localStorage.setItem('duel-double-elimination', JSON.stringify(this.localObject))
        }
        this.setState({
            [type]: e.target.value,
            updatedMatches: true
        })
    }
    maxParticipantVal = (val) => {
        let error;
        let number = parseFloat(val)
        if (!is_Natural(number)) {
            error = `Invalid input value`
        } else if (number > this.state.tournamentDetails?.maxParticipant) {
            error = `Max participants cannot be more than ${this.state.tournamentDetails?.maxParticipant}`;
        } else if (number && (number & (number - 1)) !== 0) {
            error = `Max participants should be in form of 2,4,8,16...`
        }
        if (this.index !== '0') {
            if ((number < this.state.minParticipant)) {
                error = `Max participants should not be less than ${this.state.minParticipant}`
            }
        }
        return error;
    }
    thresholdValidate = (val) => {
        if (val) {
            // let finalType = val === 1 ? 'SIMPLE' : 'NONE'
            this.setState({ threshold: val })
            let error;
            let number = parseFloat(val)
            if (!is_Natural(number)) {
                error = `Invalid input value`
            } else if (number > ((this.state.maxParticipants / 2) + 1)) {
                error = `Threshold should be less than ${(this.state.maxParticipants / 2) + 1}`
            } else if (number && (number & (number - 1)) !== 0) {
                error = `Threshold should be in form of 1,2,4,8,16...`
            }
            return error;
        }
    }
    grandFinalValidate = (val) => {
        if (val) {
            let threshold = parseInt(this.state.threshold)
            // let possibleThreshold = null
            // if ((val === 'DOUBLE') || (val === 'SIMPLE')) {
            //     possibleThreshold = 1
            // } else if (val === 'NONE') {
            //     if (threshold < 3) {
            //         possibleThreshold = 3
            //     }
            // }
            // this.setState({ enabledGrandFinal: val, threshold: possibleThreshold ? possibleThreshold : this.state.threshold })
            this.setState({ enabledGrandFinal: val })
            let error;
            if ((val !== 'NONE') && (threshold !== 1)) {
                error = `To Enable grand final threshold should be 1`
            } else if ((val === 'NONE') && (threshold === 1)) {
                error = `Choose type Simple or Double for threshold 1`
            }
            return error;
        }
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="solid" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    render() {
        const { maxParticipants, threshold } = this.state
        this.currentStages = this.state.tournamentDetails ? this.state.tournamentDetails.stages : false;
        const isUpdated = ((this.isUpdate && this.currentStages) ? true : false);
        const initials = {
            stageName: this.state.stageName,
            maxParticipant: this.state.maxParticipants,
            qualificationThreshold: this.state.threshold,
            // thirdPlaceChecked: (isUpdated ? this.currentStages[this.index]?.includeThirdPlace : false),
            playoffFormat: this.state.playoffFormat,
            enabledGrandFinal: this.state.enabledGrandFinal
        };
        let type = this.state.tournamentDetails?.participantType === 'TEAM' ? 'Teams' : 'Participants';
        let requiredObject = getWebData();

        return (
            <section className="body-section duel-double-elimination tooltip-text">
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={stages} heading={`${isUpdated ? "Update" : "Add"} Stage Details`}
                        subHeading={<h1 className="stage-sub-heading text-center">(Double Elimination)</h1>}
                        history={this.props.history} />

                    <Formik
                        enableReinitialize
                        initialValues={initials}

                        validationSchema={Yup.object({
                            stageName: Yup.string().required("*Stage name is required").max(30, 'Max 30 characters'),
                            maxParticipant: Yup.number().required('Max participants required')
                                .min(2, 'Minimum participant should be 2'),
                            // .oneOf([2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384], 'Max participants should be in form of 2,4,8,16,32...')
                            // .test('maxParticipant', `Max participant should be less than ${this.state.tournamentDetails?.maxParticipant}`, () => validateMaxParticipant(this.state.tournamentDetails?.maxParticipant, this.state.maxParticipants))
                            // .test('maxParticipant', `Max participants should be in form of 2,4,8,16...`, () => powerOfTwo(this.state.maxParticipants)),
                            qualificationThreshold: Yup.number().required("*Qualification threshold required")
                                .min(1, 'Minimum Threshold should be 1')
                            // .test('qualificationThreshold', `Threshold should be in form of 0,1,2,4,8,16...`, () => powerOfTwo(this.state.threshold))
                            // .test('qualificationThreshold', `Threshold should be less than ${(this.state.maxParticipants / 2) + 1}`, () => halfThreshold(this.state.threshold, this.state.maxParticipants))
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.onSubmit(values)
                                setSubmitting(false);
                            }, 400);
                        }}

                    >
                        <Form className="row duel-double-elimination-form m-1 mt-lg-5" autoComplete='off'>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="stageName" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Stage Name</label>
                                <Field name="stageName" type="text" onKeyUp={(e) => this.updateValues(e, 'stageName')} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" placeholder="Enter Stage Name" />
                                <ErrorMessage component="span" className="error-msg" name="stageName"
                                />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="maxParticipantPerGroup" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Max {type}</label>

                                <a href="#" title="Maximum No. of Participants that can Participate in Tournament or Stage" className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="maxParticipant" type="number" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    placeholder="Enter Max Participants" validate={this.maxParticipantVal} onKeyUp={(e) => this.updateValues(e, 'maxParticipants')}
                                    min={1} step={1} onWheel={(e) => e.target.blur()}
                                />
                                <ErrorMessage component="span" className="error-msg" name="maxParticipant" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="qualificationThreshold" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Qualification Threshold</label>

                                <a href="#" title={requiredObject.INFORMATION_TEXT?.STAGE_DETAILS?.QUALIFICATION_THRESHOLD} className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="qualificationThreshold" type="number" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    placeholder="Enter Qualification Threshold" validate={this.thresholdValidate} onKeyUp={(e) => this.updateValues(e, 'threshold')}
                                    min={1} step={1} onWheel={(e) => e.target.blur()}
                                />
                                <ErrorMessage component="span" className="error-msg" name="qualificationThreshold" />
                            </div>

                            {/* <div className="col-lg-6 col-md-6 col-12 pt-lg-3">
                                <Field name='includeThirdPlace' type="checkbox" name='includeThirdPlace' id='includeThirdPlace' label='Include Third Place' className='mt-2 mt-lg-4' />
                                <label htmlFor="includeThirdPlace" className='fs-14 fw-600 text-white ms-2'>Include Third Place</label>
                            </div> */}

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="playoffFormat" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Playoff Format</label>

                                <a href="#" title={requiredObject.INFORMATION_TEXT?.STAGE_DETAILS?.PLAY_OFF_FORMAT} className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field value={this.state.playoffFormat} onChange={(e) => this.updateValues(e, 'playoffFormat')} name="playoffFormat" as="select" id="playOff" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                    {
                                        Object.keys(PlayoffFormats).map(function (key, i) {
                                            return <option key={i} value={PlayoffFormats[key]}>{key}</option>
                                        })
                                    }
                                </Field>
                                <ErrorMessage component="span" className="error-msg" name="playoffFormat" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="qualificationThreshold" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Enable Grand Final</label>

                                <a href="#" title={requiredObject.INFORMATION_TEXT?.STAGE_DETAILS?.ENABLE_GRAND_FINALE} className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field value={this.state.enabledGrandFinal} name="enabledGrandFinal" as="select" validate={this.grandFinalValidate} onChange={(e) => this.updateValues(e, 'enabledGrandFinal')} id="playOff" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                    <option value="NONE" className='' >None</option>
                                    <option value="SIMPLE" className='' >Simple</option>
                                    <option value="DOUBLE" >Double</option>
                                </Field>
                                <ErrorMessage component="span" className="error-msg" name="enabledGrandFinal" />
                            </div>

                            <div className="mt-5 d-flex justify-content-center">
                                {isUpdated ?
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45">
                                            <AiOutlineCheck className="fs-24" />
                                        </button>
                                        <button type="button" onClick={(e) => this.onClickOpenConfirm(e)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45">
                                            <AiFillDelete className="fs-24" />
                                        </button>
                                    </div>
                                    :
                                    <button type="submit" className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-45">
                                        <FiPlus className="fs-24" />
                                    </button>
                                }


                            </div>

                        </Form>
                    </Formik>
                </div>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={
                                {
                                    msg: (
                                        (parseInt(this.index) === parseInt((this.state.tournamentDetails?.stages.length - 1))) ?
                                            <p className="fs-14 fw-600 text-center text-white-50">
                                                Are you sure you want to remove <span className="error-msg">{isUpdated ? this.currentStages[this.index]?.name : ""}</span> from the stages ?
                                            </p> :
                                            <p className="fs-14 fw-600 text-center text-white-50">
                                                Next stages will be deleted on delete of <span className="error-msg">{isUpdated ? this.currentStages[this.index]?.name : ""}</span> stage. Are you sure you want to edit this stage?
                                            </p>
                                    ),
                                    key: (isUpdated ? this.index : null)
                                }
                            }
                            onClickOpenConfirm={this.onClickOpenConfirm}
                            confirmed={this.deleteStage} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openEditConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={{
                                msg: (
                                    <p className="fs-14 fw-600 text-center text-white-50">
                                        {WARNING.NEXT_STAGE_WILL_BE_DELETED}
                                    </p>
                                ),
                                key: (isUpdated ? this.index : null)
                            }}
                            onClickOpenConfirm={() => { this.setState({ openEditConfirmationModal: false }) }}
                            confirmed={() => { this.addOrUpdate(this.editStages, this.editIsUpdate) }} />
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}
