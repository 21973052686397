import React, { Component } from "react";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from '../../../../../images/icon/stageImg.png';
import swapIc from '../../../../../images/icon/swap-ic.png';
import timestampIc from '../../../../../images/icon/timestamp-ic.png';
import { NavLink } from 'react-router-dom';

import { Modal } from "react-bootstrap";
import { HiUserAdd } from "react-icons/hi";
import { errorHandler, getMonthAndDate, getTournamentAdditionalData, getTournamentData, months } from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from 'react-toastify';
import TournamentApis from "../../../../../helper/tournament.api";
//import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { IoIosArrowBack, IoIosCheckbox } from "react-icons/io";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { FaLayerGroup } from "react-icons/fa";
import FFABracketGroupRounds from "./FFABracketGroupRounds";


export default class FFABracketGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: props.state.structure,
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      displayRounds: false,
      groupMatches: null,
      selectedGroupe: 0,
      selectedGroupIndex: 0
    };

    this.prevValue = 0;
    this.marginTopDiv = 35;
    this.maxWidth = 0;


    this.tournamentApis = new TournamentApis();

    this.isSwapped = false;
  }



  componentWillReceiveProps(newProps) {
    this.setState({
      tournamentDetails: newProps.state.tournamentDetails,
      structure: newProps.state.structure,
    });


  }

  zoom = (key) => {
    if ((this.state.scale * 1.1) === 1.1) {
      this.maxWidth = 0;
    }
    switch (key) {
      case 'In':
        this.setState({ scale: this.state.scale * 1.1 });
        break;
      case 'Out':
        this.maxWidth = ((this.maxWidth === 0) ? (this.maxWidth + 10) : (this.maxWidth + this.maxWidth + 1));
        this.setState({ scale: this.state.scale / 1.1 });
        break;
      default:
        this.setState({ scale: 1.1 });
        break;
    }
  }

  openOrCloseInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    })
  }

  openInviteModal = (e, groupIndex) => {
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: groupIndex
    })
  }

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: []
    });
  }

  handleInputChange = (event, groupIndex, player) => {
    const target = event.target;
    const isChecked = target.type === 'checkbox' ? target.checked : target.value;

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {

      if (selectedPlayers.length === 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        let obj = {
          groupIndex: groupIndex,
          playerID: player._id
        };

        selectedPlayers.push(obj);
        this.setState({
          selectedPlayers: selectedPlayers
        });
        target.checked = true;
      }

    } else {
      selectedPlayers = selectedPlayers.filter(function (obj) {
        return obj.playerID !== player._id;
      });
      this.setState({
        selectedPlayers: selectedPlayers
      });

    }

  }

  swapPositions = () => {

    let data = {
      tournamentId: this.props.id,
      stageIndex: parseInt(this.props.index),
      sourceGroupIndex: this.state.selectedPlayers[0].groupIndex,
      sourceParticipantId: this.state.selectedPlayers[0].playerID,
      targetGroupIndex: this.state.selectedPlayers[1].groupIndex,
      targetParticipantId: this.state.selectedPlayers[1].playerID
    }

    if (data.sourceGroupIndex === this.state.selectedPlayers[1].groupIndex) {
      toast.warning("You can not swipe two players within a same group.");
    } else {
      this.isSwapped = true;
      this.props.updateLoader(true);
      this.tournamentApis.swapParticipantsBetweenGroups(data)
        .then((res) => {

          var tournamentDetails = this.state.tournamentDetails;

          tournamentDetails.stages[this.props.index].groups[this.state.selectedPlayers[0].groupIndex] = res.data.result.sourceGroup;
          tournamentDetails.stages[this.props.index].groups[this.state.selectedPlayers[1].groupIndex] = res.data.result.targetGroup;

          this.setState({
            tournamentDetails: tournamentDetails,
            selectedPlayers: [],
            isSwappingEnabled: false
          });
          this.props.updateLoader(false);
          toast.success("Participants Swapped Successfully");
        })
        .catch((err) => {
          this.props.updateLoader(false);
          errorHandler(err);
        });
    }

  };


  refresh = () => {
    setTimeout(() => {
      this.zoom('Reset');
    }, 1000);
  }

  hideOrShowRounds = (index) => {
    if (!this.state.isSwappingEnabled) {
      this.setState({
        selectedGroupe: index
      })

      var groupMatches;
      if (index !== undefined) {
        groupMatches = this.state.tournamentDetails?.stages[this.props.index].groups[index]

      } else {
        groupMatches = null
      }

      this.setState({
        displayRounds: !this.state.displayRounds,
        groupMatches: groupMatches,
        selectedGroupIndex: index
      });
    }
  }

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/${this.state.selectedGroupe}`);
  }

  getTeamMembers = (groupDetails) => {
    var arr = [];
    Object.keys(groupDetails).map((key) => {
      groupDetails[key].map((obj) => {
        obj.participants.map((team) => {
          team.resultAdded = obj.resultAdded;
          arr.push(team)
        });
      });
    });

    var jsonObject = arr.map(JSON.stringify);
    var uniqueSet = new Set(jsonObject);
    var uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    return uniqueArray;
  };

  isAlreadyExist = (id) => {

    var selectedPlayers = this.state.selectedPlayers;
    var result = false;

    selectedPlayers.forEach((team, i) => {
      if (team.playerID === id) {
        result = true
      }
    });

    return result;

  }

  render() {

    if (this.state.tournamentDetails?.stages?.length > 0 && this.state.tournamentDetails?.stages[this.props.index]) {
      // if (false) {
      return (
        <section className="body-section" style={{ paddingBottom: '50px' }}>

          {/* Header Section */}
          <div className="container p-2 mt-lg-3">
            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={this.state.tournamentDetails.stages[this.props.index].name} subHeading={<h1 className="stage-sub-heading text-center">( FFA Bracket Groups {this.state.displayRounds ? 'Rounds ' : ""})</h1>} history={this.props.history} />
            <div className='d-flex overflow-auto mt-2 add-time justify-content-around'>
              {this.state.displayRounds ?
                <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.hideOrShowRounds}>
                  <FaLayerGroup className="btn-size-20 me-2 mb-1" /> Back to Groups
                </button>
                :
                (this.state.selectedPlayers.length === 2 ?
                  <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.swapPositions}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt='' /> Swap Position
                  </button>
                  :
                  <button className="add-btn fs-12 text-black me-2 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt='' /> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                  </button>
                )
              }

              <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black fw-600 rounded-15" >
                <img src={timestampIc} className='btn-size-20 me-2 mb-1' alt='' /> Edit Time Slot
              </button>
            </div>
          </div>

          <div className="container">
            {this.state.displayRounds ?
              <div className='w-100 d-flex overflow-auto' style={{ height: '-webkit-fill-available' }}>
                <FFABracketGroupRounds tournamentDetails={this.state.tournamentDetails} selectedGroupIndex={this.state.selectedGroupIndex} index={this.props.index} groupMatches={this.state.groupMatches} updateLoader={this.props.updateLoader} maxParticipantsPerMatch={this.state.tournamentDetails?.stages[this.props.index]?.maxParticipantsPerGroup} />
              </div>
              :
              <div className="row pt-3 px-3"  >
                {
                  [...Array(this.state.tournamentDetails?.stages[this.props.index]?.maxNumberOfGroups)].map((elementInArray, index) => {

                    const teamMembers = this.getTeamMembers(this.state.tournamentDetails?.stages[this.props.index].groups[index]);
                    const maxParticipantsPerGroup = this.state.tournamentDetails?.stages[this.props.index]?.maxParticipantsPerGroup;
                    const participantType = (this.state.tournamentDetails?.participantType === 'TEAM' ? true : false);

                    if (maxParticipantsPerGroup <= 4) {
                      return (
                        <div key={index} className="col-lg-4 col-md-6 col-12">
                          <div className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" onClick={() => this.hideOrShowRounds(index)} style={{ alignItems: 'center', marginLeft: '-5px' }} key={index}>
                            <span className="match-tag align-self-center py-1 fs-14">Group {(index + 1)}</span>

                            <div className="d-flex justify-content-between align-items-center w-100 px-2 pb-3">
                              <div className="d-flex flex-column">
                                {
                                  [...Array(maxParticipantsPerGroup)].map((elementInArray, i) => {
                                    if (teamMembers[i]) {
                                      return (
                                        <div className="d-flex align-items-center" key={index + ":" + i}>
                                          {this.state.isSwappingEnabled ?
                                            <input type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                                            :
                                            <img src={participantType ? teamMembers[i].logo.url : teamMembers[i].profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt='' />
                                          }
                                          <span className="player-name my-1 "> {participantType ? teamMembers[i].name : teamMembers[i].inGameName}</span>
                                        </div>
                                      )
                                    } else {
                                      return (<span className="player-name my-1 vacant" key={index + ":" + i}> Vacant</span>)
                                    }

                                  })
                                }
                              </div>

                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className="col-lg-4 col-md-6 col-12" key={index}>

                          <div className="card mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn h-auto" onClick={() => this.hideOrShowRounds(index)} style={{ alignItems: 'center', marginLeft: '-5px' }} key={index}>
                            <span className="match-tag align-self-center py-1 fs-14">Group {(index + 1)}</span>

                            <div className="d-flex justify-content-between align-items-center w-100 px-2 pb-3">
                              <div className="d-flex flex-column">
                                {
                                  [...Array(this.state.isSwappingEnabled ? maxParticipantsPerGroup : 4)].map((member, i) => {
                                    if (teamMembers[i]) {
                                      return (
                                        <div className="d-flex align-items-center" key={i}>
                                          {this.state.isSwappingEnabled ?
                                            <input type='checkbox' disabled={teamMembers[i]?.resultAdded} id={`${index}.${i}`} onChange={(e) => this.handleInputChange(e, index, teamMembers[i], true)} />
                                            :
                                            <img alt="" src={participantType ? teamMembers[i].logo.url : teamMembers[i].profilePicture?.url} className='btn-size-15 rounded-circle me-2' />
                                          }
                                          <span className="player-name my-1 " key={i}> {teamMembers[i].name} </span>
                                        </div>
                                      )
                                    } else {
                                      return <span className="player-name my-1 vacant" key={i}> Vacant</span>
                                    }

                                  })
                                }
                                <a className={`more-count ${this.state.isSwappingEnabled ? 'd-none' : null}`}>+{maxParticipantsPerGroup - 4} more</a>
                              </div>

                            </div>

                          </div>
                        </div>
                      )
                    }

                  })
                }
              </div>
            }
          </div>


          {/* Tournament Invitation Section */}
          <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
            <Modal.Body>
              <MatchInvite
                onClickOpenInvite={this.openOrCloseInvite}
                tournamentId={this.props.id}
                stageId={this.props.index}
                matchId={this.state.matchId}
                gameId={this.state.tournamentDetails?.gameId}
                participantType={this.state.tournamentDetails?.participantType}
                choice="Send Invite"
              />
            </Modal.Body>
          </Modal>

        </section>
      );

    } else {
      return (
        <div>
          <h1>No Data Found</h1>
        </div>
      );
    }

  }
}
