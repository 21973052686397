import React, { useState, useEffect } from "react";
// We don't need to import Modal as it will be controlled by the Navbar component

const StreamingComingSoon = (props) => {
  const [waitingGamers, setWaitingGamers] = useState(1200);

  useEffect(() => {
    const increaseInterval = setInterval(() => {
      const randomIncrease = Math.floor(Math.random() * 10) + 5;
      setWaitingGamers((prev) => prev + randomIncrease);
    }, 3000);

    return () => clearInterval(increaseInterval);
  }, []);

  // Main component styles
  const streamingStyles = {
    position: "relative",
    borderRadius: "0.75rem",
    overflow: "hidden",
    border: "1px solid rgba(139, 92, 246, 0.3)",
    background: "linear-gradient(to bottom, rgba(88, 28, 135, 0.4), #111827)",
    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
    height: "calc(100vh - 73px)",
    marginTop: "73px", // Added for navbar height
  };

  // Content wrapper styles
  const contentWrapperStyles = {
    position: "relative",
    padding: "2rem",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
  };

  // Main heading styles
  const headlineStyles = {
    fontSize: "3rem",
    lineHeight: 1.2,
    fontWeight: 800,
    marginBottom: "1rem",
    background: "linear-gradient(to right, #60a5fa, #8b5cf6, #ec4899)",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
  };

  const subheadlineStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#9ca3af",
    fontSize: "1.125rem",
  };

  const iconStyles = {
    color: "#eab308",
    marginRight: "0.5rem",
  };

  const consoleBadgeStyles = {
    display: "inline-block",
    marginTop: "1rem",
    padding: "1px",
    borderRadius: "0.25rem",
    background: "linear-gradient(to right, #3b82f6, #8b5cf6)",
  };

  const badgeInnerStyles = {
    padding: "0.5rem 1rem",
    backgroundColor: "#111827",
    borderRadius: "0.2rem",
    color: "#ffffff",
    fontFamily: "monospace",
  };

  // Tagline styles
  const taglineContainerStyles = {
    marginBottom: "2rem",
    maxWidth: "600px",
    width: "100%",
  };

  const taglineBoxStyles = {
    marginBottom: "2rem",
    background:
      "linear-gradient(to right, rgba(76, 29, 149, 0.8), rgba(30, 58, 138, 0.6), rgba(76, 29, 149, 0.8))",
    padding: "1.25rem",
    borderRadius: "0.5rem",
    border: "1px solid rgba(139, 92, 246, 0.4)",
    textAlign: "center",
  };

  const taglineMainStyles = {
    fontSize: "1.25rem",
    color: "#ffffff",
    marginBottom: "0.5rem",
    fontWeight: 700,
  };

  const taglineSubStyles = {
    fontSize: "1rem",
    fontWeight: "500",
    color: "#22d3ee",
    fontStyle: "italic",
    marginBottom: 0,
  };

  const launchBadgeStyles = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "2rem",
  };

  // Counter styles
  const counterContainerStyles = {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  };

  const counterStyles = {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
    color: "#9ca3af",
  };

  const counterIconStyles = {
    color: "#8b5cf6",
    marginRight: "0.25rem",
  };

  const countStyles = {
    color: "#8b5cf6",
    fontWeight: 700,
  };

  const growingStyles = {
    color: "white",
    fontSize: "1rem",
    marginLeft: "0.5rem",
    fontWeight: "400",
  };

  const statsGrowing = {
    color: "#10b981",
    fontSize: "1rem",
    marginLeft: "0.5rem",
    fontWeight: "400",
  };

  const comingSoon = {
    fontSize: "1.5rem",
    fontWeight: "600",
    margin: "0px",
  };

  // Pseudo element styles (for the glowing orbs) will be added with actual CSS
  const beforeAfterCSS = `
    .streaming-coming-soon::before {
      content: '';
      position: absolute;
      width: 150px;
      height: 150px;
      background-color: #8b5cf6;
      border-radius: 100%;
      filter: blur(60px);
      opacity: 0.15;
      z-index: 0;
      top: -75px;
      left: -75px;
    }
    
    .streaming-coming-soon::after {
      content: '';
      position: absolute;
      width: 150px;
      height: 150px;
      background-color: #3b82f6;
      border-radius: 100%;
      filter: blur(60px);
      opacity: 0.15;
      z-index: 0;
      bottom: -75px;
      right: -75px;
    }

    /* Button animation styles */
    .launch-button {
      position: relative;
      padding: 0.75rem 1.5rem;
      background-color: rgba(31, 41, 55, 0.6);
      backdrop-filter: blur(8px);
      border-radius: 0.25rem;
      border: 1px solid rgba(139, 92, 246, 0.4);
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s ease;
      user-select: none; /* Prevent text selection on click */
    }

    .launch-button:hover {
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba(139, 92, 246, 0.4);
    }

    .launch-button::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, 
        transparent 0%, 
        rgba(139, 92, 246, 0.2) 25%, 
        rgba(236, 72, 153, 0.3) 50%, 
        rgba(139, 92, 246, 0.2) 75%, 
        transparent 100%);
      opacity: 0;
      z-index: -1;
      transition: opacity 0.3s ease;
    }

    .launch-button:hover::before {
      opacity: 1;
      animation: borderShine 1.5s infinite linear;
    }

    @keyframes borderShine {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }

    .launch-button::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 0.25rem;
      padding: 2px;
      background: linear-gradient(90deg, #3b82f6, #8b5cf6, #ec4899, #8b5cf6, #3b82f6);
      background-size: 200% 200%;
      -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .launch-button:hover::after {
      opacity: 1;
      animation: borderGlow 1.5s infinite linear;
    }

    @keyframes borderGlow {
      0% {
        background-position: 0% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `;

  const lightningStyles = {
    marginRight: "0.5rem",
    color: "#eab308",
  };

  const textStyles = {
    fontSize: "1.25rem",
    fontFamily: "monospace",
    color: "#ffffff",
  };

  // Function to open signup modal through the Navbar component
  const handleOpenSignUp = () => {
    // Call the resetAndUpdateFlag function from props
    // This is the same function used in Navbar
    if (props.resetAndUpdateFlag) {
      props.resetAndUpdateFlag(true, 'openSignUp', true);
    }
  };

  return (
    <>
      <style>{beforeAfterCSS}</style>
      <div className="streaming-coming-soon" style={streamingStyles}>
        <div style={contentWrapperStyles}>
          {/* Main heading */}
          <div style={{ marginBottom: "2.5rem" }}>
            <h1 style={headlineStyles}>Stream Like a Pro</h1>
            <div style={subheadlineStyles}>
              <i className="bi bi-lightning" style={iconStyles}></i>
              <p style={comingSoon}>Coming Soon to Your Den</p>
            </div>
            <div style={consoleBadgeStyles}>
              <div style={badgeInnerStyles}>
                <span>LAUNCH_IMMINENT</span>
              </div>
            </div>
          </div>

          {/* Tagline */}
          <div style={taglineContainerStyles}>
            <div style={taglineBoxStyles}>
              <p style={taglineMainStyles}>
                "Initializing a new way to stream, watch & earn"
              </p>
              <p style={taglineSubStyles}>
                Powering up new experiences for players.
              </p>
            </div>
            <div style={launchBadgeStyles}>
              {/* Button with animation and click handler */}
              <div className="launch-button" onClick={handleOpenSignUp}>
                <span style={lightningStyles}>⚡</span>
                <span style={textStyles}>LAUNCHING SOON - SIGNUP NOW!</span>
              </div>
            </div>
          </div>

          {/* Counter - with styling but without avatars */}
          <div style={counterContainerStyles}>
            <div style={counterStyles}>
              <i className="bi bi-people" style={counterIconStyles}></i>
              <p style={growingStyles}>
                <span style={countStyles}>
                  {(waitingGamers / 1000).toFixed(1)}k
                </span>{" "}
                gamers waiting
                <span style={statsGrowing}>↑ growing</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamingComingSoon;