import React, { Component } from 'react';
import "../../styles/points.css";
import { getWebData } from '../../utils/common.utils';

const normalText = {textAlign: 'justify', background: 'transparent', marginBottom: '0cm', lineHeight: '115%', fontWeight: '300'};
const sideHeader = {marginTop: '0.25cm', marginBottom: '0.21cm', lineHeight: '115%', textAlign: 'left', background: 'transparent', fontSize: '19px', color: 'white', fontWeight: 'bold',}

export default class Policies extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
    }

    // this.websiteData = getWebData();

  }

  render() {

    // const privacyPolicies = this.websiteData?.STATIC_PAGE?.PRIVANCY_POLICY?.SET_UP_PRIVACY_POLICY?.split("&&");
    
    return(
 
    <section className="body-section">      
      {/* <div className="p-3 d-flex flex-column mx-auto notifications" >
        <div className="row text-center">
            <div className="col-12">
              <h1 className="fs-20 fw-bold text-white mb-2 mt-3">Privacy Policy</h1>
            </div>
        </div>
        <div className='row '>
          <ol className='text-white'>
            {privacyPolicies?.map((policy,i)=>{
              return <li key={i}>{policy}</li>
            })}
          </ol>
        </div>
      </div> */}
      <div className="p-3 d-flex flex-column mx-auto notifications">
        <h1 style={{ textAlign: 'center', background: 'transparent', fontFamily: 'Liberation Sans', fontSize: '24px', fontWeight: 'bold', lineHeight: '115%'}}>Privacy Notice</h1>
        <p style={normalText}><br /></p>
        <p style={normalText}>This Privacy Notice explains how eSpotz use your personal information when you&rsquo;re using our website.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>Your privacy is important to us, and we are committed to protecting your privacy. We will be clear and open about why we collect your personal information and how we use it. Where you have choices or rights, we will explain these to you.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>If you do not agree with any statements contained within this Privacy Notice, please do not proceed any further on our website. Please be aware that registering an account on our website, placing stakes and transferring funds will be deemed confirmation of your full agreement with our Terms and Conditions and our Privacy Notice. You have the right to cease using the website at any time; however, we may still be legally required to retain some of your personal information.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>If we change any of the terms of this Privacy Notice in the future, we will post the amended Privacy Notice on the website and, where appropriate, we will notify you by email.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>Throughout this Privacy Notice, &quot;eSpotz&rdquo;, &quot;we&quot;, &quot;our&quot; and &quot;us&quot; relates to eSpotz Inc, a company incorporated under the laws of the Canada, (Registry ID 4363755) whose registered office is at Suite 1300, 2000 Barrington Street, Halifax, Nova Scotia, B3J 3K1&nbsp;</p>
        <p style={normalText}>Canada. Your personal data will be held and processed in the Canada. All information will be held in accordance with the relevant Data Protection Laws and have a designed &lsquo;Controller&rsquo;. A &lsquo;Controller&rsquo; is a person or company who determines how and why your personal information is used.</p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Our Data Protection Officer</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}><span size="3" style={{fontSize: '16px'}}>If you have concerns or would like any further information about how eSpotz handles your personal information, you can contact our Data Protection Officer at <a href="mailto:privacy@espotz.live">privacy@espotz.live</a>&nbsp;</span></p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Personally identifiable information</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>You provide this information to us in the process of setting up an account, placing stakes and using the services of the website. This information is required to give you access to certain parts of our website and related services. This data is collected when you:</p>
        <ul>
            <li>
                <p style={normalText}>Register an account with eSpotz</p>
            </li>
            <li>
                <p style={normalText}>Voluntarily provide it when using the website</p>
            </li>
            <li>
                <p style={normalText}>Personally disclose the information in public areas of the website</p>
            </li>
            <li>
                <p style={normalText}>Provide it when you contact our customer support team</p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <p style={normalText}><br /></p>
        <p style={normalText}><br /></p>
        <p style={normalText}><br /></p>
        <p style={normalText}>The information includes your:</p>
        <ul>
            <li>
                <p style={normalText}>first and surname</p>
            </li>
            <li>
                <p style={normalText}>gender</p>
            </li>
            <li>
                <p style={normalText}>date of birth</p>
            </li>
            <li>
                <p style={normalText}>email address</p>
            </li>
            <li>
                <p style={normalText}>phone number</p>
            </li>
            <li>
                <p style={normalText}><span lang="it-IT">Social media sign-in access&nbsp;</span></p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <p style={normalText}>The information is also required for billing purposes and for the protection of minors. You can amend and update this information by contacting Customer Support. This data is for internal use only and is never passed to any third parties except those stated below.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>In order to ensure that you can access all of the benefits of having an account on the Website, we may collect and process the following additional personal data about you:</p>
        <p style={normalText}><br /></p>
        <ul>
            <li>
                <p style={normalText}>the currency in which you hold your account currency, encrypted password, payment details, bank account details and card details</p>
            </li>
            <li>
                <p style={normalText}>Whenever you interact with our customer support team and share Personal Information, please be aware that this includes conversations conducted through our AI-powered chat system, utilizing large language models (LLM)</p>
            </li>
            <li>
                <p style={normalText}>information that you submit in response to a survey(s) or poll</p>
            </li>
            <li>
                <p style={normalText}>details of transactions you carry out through the Website and your account number with us;</p>
            </li>
            <li>
                <p style={normalText}>details of your visits to the website including, but not limited to stakes and transaction records, traffic data, location data, weblogs and other communication data, whether this is required for our own billing purposes or otherwise and the resources that you access.</p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Non-personally identifiable information and traffic analysis</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>eSpotz strives to make our website as user friendly as possible and easy to find on the internet. eSpotz collects data on how you use the site, which does not identify you personally. This includes information such as: the pages you browse through; websites that directed you to us; keywords you used to find us in search engines; your IP address; cookies (please see cookies section of this Notice) and other information. The non-identifiable data is analysed in order to make the website as user-friendly and as optimised as possible.</p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Cookies</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>Cookies are small text files which are stored on a user&apos;s computer. They are designed to hold a modest amount of data specific to a particular client and website.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>This allows eSpotz to deliver a more personalised experience with pages tailored to a particular user. This leads to less keystrokes and a much quicker and more personal experience for you.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>For example, during the registration process we collect information so we can recognise you as a customer the next time you login.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}><span size="3" style={{fontSIze: '16px'}}>Cookies allow us to understand your preferences and to offer more relevant promotions.&nbsp;</span></p>
        <p style={normalText}><br /></p>
        <p style={normalText}>We also use them to collect statistics on the usage of our services and to ensure that our email tools are working correctly.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>We may collect information about your computer, including (where available) your IP address, operating system, browser type and other individually identifiable data, for system administration, statistical and fraud prevention purposes. Such information may be used to uniquely identify users to prevent fraudulent activity and perform identity verification.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>You can either accept or decline cookies. Most web browsers accept cookies by default but this can be set to decline cookies. The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether. If you do decline cookies on your browser, you may not be able to experience all of the interactive features on our site.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>We use your personal information in a range of ways that fall into the following categories:</p>
        <ul>
            <li>
                <p style={normalText}>To provide you with the products or services you have requested:</p>
            </li>
            <li>
                <p style={normalText}>To meet our legal or regulatory obligations;</p>
            </li>
            <li>
                <p style={normalText}>To monitor our website performance; and</p>
            </li>
            <li>
                <p style={normalText}>To provide you with marketing information</p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <p style={normalText}>Your rights over your personal information differ according to which category and lawful basis these fall into. This section provides more information about each category, the rights it gives you, and how to exercise these rights. These rights are in bold following each category.</p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Providing our products and services</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>We use your personal information to enable you to use our websites, to set up your account, and to provide you with customer service assistance.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>To provide our products and services, we share your information with external organizations working on our behalf. Further information can be found in the Sharing Information section.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>This category covers the essential activities required in order for us to provide you with the services you use or have signed up for. If you don&rsquo;t want your information used in this way, your option is to not use our services and close your account.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}><br /></p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>To monitor our website performance</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>As detailed above, we use cookies and traffic analysis in order to improve the performance of our website and services available. We have a legitimate interest in carrying out these activities and we ensure that we minimise any impact on your privacy.</p>
        <p style={normalText}>You have the &lsquo;right to object&rsquo; to activities carried out for our legitimate interest if you believe your right to privacy outweighs our legitimate business interests. However, as the activities involved are central to our business, if you wish to object further than managing your cookies this may mean you need to close your account.</p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Marketing</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>If you have given us your consent to do so, we will send you offers and promotions via email, SMS or online. We do not share your information with third parties for them to use for their own marketing.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>You have the right to withdraw consent or update your marketing preferences at any time.</p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Your rights to rectification</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}><span size="3" style={{fontSIze: '16px'}}>If you believe the personal information, we hold on you is incorrect, you have the right for this to be rectified. For any information that cannot be updated through My Account, please contact <a href="mailto:support@espotz.live">support@espotz.live</a>&nbsp;</span></p>
        <p style={normalText}><br /></p>
        <h3 style={sideHeader}>Your right to update, access, or delete your Personal Information</h3>
        <p style={normalText}><br /></p>
        <p style={normalText}>If you wish to update Personal Information you believe is incorrect, you may contact <a href="mailto:support@espotz.live">support@espotz.live</a> Please note that you may be able to update some of your information via the &lsquo;My Account&rsquo; page on our website.</p>
        <p style={normalText}>You may also request a copy of your Personal Information record or request deletion of Personal Information by the following process;</p>
        <p style={normalText}><br /></p>
        <ul>
            <li>
                <p style={normalText}>Contact us at support@espotz.live or through this link;</p>
            </li>
            <li>
                <p style={normalText}>We will also require valid proof of your identity to provide with your request; and</p>
            </li>
            <li>
                <p style={normalText}>After we have had a reasonable time to process the request, and your verification of identity, we will respond within a reasonable time (usually thirty (30) days).</p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <p style={normalText}>Further, we reserve our right to reject an unreasonable request for Personal Information in circumstances where the form of the request would be unreasonably arduous or otherwise unreasonable, or where we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>It is Your responsibility to maintain your Personal Information and ensure that it is up-to-date and accurate.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}><span size="3" style={{fontSIze: '16px'}}>Personal Information requests, questions or other related privacy issues may also be made via live chat or by e-mail to the eSpotz Data Privacy Officer at <a href="mailto:privacy@espotz.live">privacy@espotz.live</a>&nbsp;</span></p>
        <h3 style={sideHeader}>We may disclose your Personal Data to third parties:</h3>
        <p style={normalText}><br /></p>
        <ul>
            <li>
                <p style={normalText}>if we are under a duty to disclose or share your personal information in order to comply with any legal or regulatory obligation;</p>
            </li>
            <li>
                <p style={normalText}>in order to enforce or apply the terms of this notice, the Terms &amp; Conditions, or any other agreements;</p>
            </li>
            <li>
                <p style={normalText}>to assist us in providing you with the products and services you request, including but not limited to third party software providers;</p>
            </li>
            <li>
                <p style={normalText}>to protect the rights, property or safety of us, our customers or others;</p>
            </li>
            <li>
                <p style={normalText}>in the event that we sell or buy any business, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets; and</p>
            </li>
            <li>
                <p style={normalText}>where we have received your permission for us to do so.&nbsp;</p>
            </li>
            <li>
                <p style={normalText}>to analyze interactions and feedback to enhance the performance and efficiency of our AI-powered chat system.</p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <p style={normalText}>When we transfer any part of your Personal Data outside the EEA or adequate jurisdictions, we will take reasonable steps to ensure that it is treated as securely as it is within the EEA or adequate jurisdictions. These steps include but are not limited to the following:</p>
        <ul>
            <li>
                <p style={normalText}>Binding corporate rules;</p>
            </li>
            <li>
                <p style={normalText}>Model contracts; or</p>
            </li>
            <li>
                <p style={normalText}>US/EU privacy shield</p>
            </li>
        </ul>
        <p style={normalText}><br /></p>
        <p style={normalText}>We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Notice. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>We store all of the Personal Information we receive directly from you in an encrypted at rest and password protected database residing within our secure network behind active state-of-the-art firewall software. (Our Services support SSL Version TLS 1.2 with 256-bit encryption). We also take measures to ensure our business partners and suppliers employ adequate security measures.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>The Website may from time to time contain links to and from the websites of our partner networks, advertisers and affiliates as well as third party websites to which we have no affiliation. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>We retain personal information for as long as we reasonably require it for legal or business purposes. In determining data retention periods, eSpotz takes into consideration local laws, contractual obligations, and the expectations and requirements of our customers. When we no longer need your personal information, we securely delete or destroy it.</p>
        <p style={normalText}><br /></p>
        <p style={normalText}>We will use reasonable efforts to protect your personally identifiable information. Transmission of information over the internet is never totally secure, therefore we cannot be held liable or responsible if any of this information becomes disclosed to unauthorised third parties due to causes beyond our control.</p>
      </div>
    </section>
    );
  }
}