import React,{ Component } from 'react';
import '../../../../styles/den.css';

import { AiFillCloseCircle, AiFillEdit, AiOutlineClose, AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai';
import { getDenPostsByType, getDenProfiles, getPostInteractionsDetails, likeDenPost, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';
import { receivedAt } from '../../../../utils/dateTime.utils';


import { Carousel } from 'react-bootstrap';
import LeftSideBar from '../common/LeftSideBar';
import RightSideBar from '../common/RightSideBar';
import { profile } from '../../../../utils/localstorage.utils';
import PostCard from '../common/PostCard';
import { DEN } from '../../../../utils/Strings.utils';
import { capitalize, getDetailsObj } from '../../../../utils/common.utils';
import PostActions from '../../../popup/den/PostActions';
import { toast } from 'react-toastify';
import { DEN_EMPTY_SCREENS } from '../../../../utils/Images.utils';

/**
* @author
* @class DenPostsTab
**/

class DenPostsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            denProfile: null,
            postType: DEN.POST_TYPES.SAVED,
            activeTab: DEN.TABS.POSTS,
            feedDetails: [],
            showMoreNotificationIndex: null,

            selectedActionIndex: null,
            selectedReportType: null,
            selectedReportTypeDescription: null,
            openPostActionModal: false,
            openPostReportModal: false,
            detailsObj: null
        };
    }

    componentDidMount(){

        if(this.props.match.params.denId){
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile);
        }
     
       
    }

    setCurrentUserDenProfile = (result) =>{
        if(result[0]){
            this.setState({ denProfile: result[0] })
            
            if(result[0]?.savedPosts?.length > 0){
                getDenPostsByType(result[0]._id ,this.state.postType, this.setSelectedTypePosts);
            }
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }

    setSelectedTypePosts = (result) =>{
        this.updateState('feedDetails',result);
        this.closeAllActions();
    }

    updateState = (key, val) => {
        this.setState({
            [key] : val
        })
    }

    showMore = (index)=>{
        this.setState({ showMoreNotificationIndex : index });
    }

    updatePostType = (type) => { 

        if(type !== this.state.postType){
            this.updateState('postType',type);
            getDenPostsByType(this.state.denProfile._id, type, this.setSelectedTypePosts);
        }

    }

    
    showHidePostAction = (index, val1, val2) => {

        if(!val1 && !val2){
            this.setState({
                selectedActionIndex: null,
                selectedReportType: null,
                selectedReportTypeDescription: null,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }else{
            this.setState({
                selectedActionIndex: index,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }
    }

    closeAllActions = () =>{
        this.setState({
            selectedActionIndex: null,
            selectedReportType: null,
            selectedReportTypeDescription: null,
            openPostActionModal: false,
            openPostReportModal: false
        })
    }

    updatePostDetails = () =>{
        getDenPostsByType(this.state.denProfile._id, this.state.postType, this.setSelectedTypePosts);
    }

    updateProfileDetails = (updatedProfileDetails) =>{
        //updateDenProfileInLocalStorage(updatedProfileDetails);
        this.updateState('denProfile',updatedProfileDetails);
        getDenPostsByType(this.state.denProfile._id, this.state.postType, this.setSelectedTypePosts);
    }

    checkDenStatus = () => {
        if(!this.state.denProfile.isActive){
            toast.error('Please re-activate your den profile to perform any action');
            return false;
        }
        return true;
    }

    getView = () => {
        //console.log("@@@@ feedDetails :: ",this.state.feedDetails)
        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2'>
                        <div className='row'>
                            <div className='col-3'></div>
                            <div className='col-6'>
                                <p className='fs-16 text-center fw-700 my-0'>POSTS</p>
                            </div>
                            <div className='col-3 text-end'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.history.goBack()} />
                            </div>
                        </div>
                        <hr className='hr-line' />
                        <div className='row'>
                            {Object.keys(DEN.POST_TYPES).map((key,i)=>{
                                return(
                                    <div className='col-4 text-center' key={i}>
                                        <button className={`normal-post-btn ${this.state.postType === DEN.POST_TYPES[key] ? 'selected-post-btn' : ''}`} onClick={() => this.updatePostType(DEN.POST_TYPES[key])}>{capitalize(DEN.POST_TYPES[key])}</button>
                                    </div>                                    
                                )
                            })}
                        </div>
                    </div>

                    <div className='row my-3'>

                        {/* Show Posts Here */}
                        {
                        this.state.feedDetails?.length ?
                        this.state.feedDetails.map((post, i) => {
                       
                            var likes = 0;
                            var likedUsers = [];    
                            Object.keys(post.likes).map((key,i)=>{ 
                                likes += post.likes[key].length 
                                likedUsers = likedUsers.concat(post.likes[key]);
                            });
                            
                    
                            const interactions = getPostInteractionsDetails(i, post, likedUsers, this.state.denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                        
                            return (
                                <PostCard 
                                    key={i} 
                                    i={i} 
                                    history={this.props.history}
                                    post={post}
                                    denProfile={this.state.denProfile} 
                                    postType={this.state.postType} 
                                    interactions={interactions} 
                                    feedDetails={this.state.feedDetails} 
                                    selectedPostIndex={this.state.selectedPostIndex}
                                    selectedReportType={this.state.selectedReportType}
                                    showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                    postToInteract={this.state.postToInteract}
                                    showHidePostAction={this.showHidePostAction}
                                    updateProfileDetails={this.updateProfileDetails}
                                    updatePostDetails={this.updatePostDetails}
                                    isLiveStraming={false}
                                    checkDenStatus={this.checkDenStatus}
                                />
                            )
                            })
                            :
                            <div className='post-card-bg'>
                                <div className="column-center-align-div" style={{height:'400px'}}>
                                    <img className="" src={DEN_EMPTY_SCREENS.NO_POST_FOUND} alt={""} />
                                </div>
                            </div>
                        }

                    </div>
                </div>

                 {/* Post Action Div */}
                 <PostActions 
                    postType={this.state.postType}
                    show={this.state.openPostActionModal} 
                    denProfile={this.state.denProfile}
                    feedDetails={this.state.feedDetails}
                    updatePostDetails={this.updatePostDetails}
                    updateProfileDetails={this.updateProfileDetails}
                    selectedActionIndex={this.state.selectedActionIndex}
                    showHidePostAction={this.showHidePostAction}
                    onClose={() => this.showHidePostAction(null, false, false)}  
                />
            
            </div>
        )
    }

    render() {
        return (
            <div className='body-section den_page'>
                <div className='container'>
                    <div className='row'>
                        <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                        {this.getView()}
                        <RightSideBar activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                    </div>
                </div>
            </div>
        );
    }
}


DenPostsTab.propTypes = {}
export default DenPostsTab