// socketService.js
import io from 'socket.io-client';

class SocketService {
  constructor() {
    this.socket = null;
    this.listeners = new Map();
  }

  // Initialize the socket connection
  connect() {
    if (!this.socket) {
      this.socket = io(import.meta.env.VITE_APP_SOCKET_URL, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5
      });

      this.socket.on('connect', () => {
        console.log('Socket connected:', this.socket.id);
      });

      this.socket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
      });

      this.socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });
    }
    return this.socket;
  }

  // Get the socket instance
  getSocket() {
    if (!this.socket) {
      return this.connect();
    }
    return this.socket;
  }

  // Disconnect the socket
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  // Emit an event
  emit(event, data, callback) {
    if (this.socket) {
      return this.socket.emit(event, data, callback);
    }
  }

  // Subscribe to an event with a unique identifier
  on(event, callback, id) {
    if (!this.socket) {
      this.connect();
    }

    const key = `${event}-${id}`;
    
    // Remove any existing listener for this key
    this.off(event, id);
    
    // Save the listener reference
    this.listeners.set(key, callback);
    
    // Add the actual listener
    this.socket.on(event, callback);
    
    return () => this.off(event, id);
  }

  // Remove a specific event listener
  off(event, id) {
    const key = `${event}-${id}`;
    if (this.listeners.has(key)) {
      const callback = this.listeners.get(key);
      if (this.socket) {
        this.socket.off(event, callback);
      }
      this.listeners.delete(key);
    }
  }

  // Join a room
  joinRoom(room) {
    if (this.socket) {
      this.socket.emit('join-room', room);
    }
  }

  // Leave a room
  leaveRoom(room) {
    if (this.socket) {
      this.socket.emit('leave-room', room);
    }
  }
}

// Create a singleton instance
const socketService = new SocketService();

export default socketService;