import { toast } from 'react-toastify';
import React, { Component } from 'react'
import {Button, Modal } from 'react-bootstrap'
import { AiFillCloseCircle } from 'react-icons/ai';
import Image from '../../common/Image';
import { DEN, POST_INTERACTIONS, PROFILE_PAGE_IMAGES } from '../../../utils/Images.utils';
import { DEN as DEN_STRING } from '../../../utils/Strings.utils';
import { IoIosArrowBack } from 'react-icons/io';
import { getDenProfilePicture, replyOnPostOrComment, sendSpotlightToDenProfile } from '../../../utils/Den.utils';
import '../../../styles/spotlight.css';
import { STYLE } from '../../../utils/Style.utils';
import { getAdminData, getDenDetailsByType } from '../../../utils/common.utils';




export default class SpotLight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            denProfile: props.denProfile,
            openShare: false,
            openAction: props.show,
            spotlightAmount: 0,
            spotlightReply: ((props.type === DEN_STRING.SPOTLIGHT.TYPE.COMMENT) ? props.commentDetails.description : (props.message ? props.message :'')),
            styleData: STYLE.DEN.SPOTLIGHT.STAR,
            spotLightRange: null,
            commentDetails: props.commentDetails? props.commentDetails : null
        }
    }

    componentDidMount(){

        
        let spotLightRange = getDenDetailsByType('SPOTLIGHT');
        if(spotLightRange){
            this.setState({
                spotLightRange: spotLightRange
            })
        }
    }

    delay = () => {
        var timer = 0;
        return function(callback, ms) {
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    };

    componentWillReceiveProps(newProps){
        this.setState({
            denProfile: newProps.denProfile,
        })
    }

    updateSpotlightAmount = (key) => {
        let {spotlightAmount} = this.state
        if(key === 'increment'){
        spotlightAmount += 100;
        }else{
        spotlightAmount -= 100;
        }
        if(spotlightAmount > 500){
        toast.warning('Maximum alowed spotlight amount is 500 EC');
        return false;
        }
        if(spotlightAmount < 100){
        toast.warning('Minimun alowed spotlight amount is 100 EC');
        return false;
        }
        this.setState({spotlightAmount})
    }

    updateSpotlightReply = (val) => { 
        this.setState({spotlightReply: val})
    }

    sendSpotlightToDenProfile = () => {

        if(this.props.type === DEN_STRING.SPOTLIGHT.TYPE.COMMENT){
            this.sendSpotlightAsReplyOrComment();
        }else{
            var data = {
                type : this.props.type,
                message : this.state.spotlightReply,
                amount : parseInt(this.state.spotLightRange?.SET_SPOTLIGHT_VALUE[this.state.spotlightAmount]),
                star :  parseInt(this.state.spotlightAmount)+1,
                from: this.state.denProfile?._id,
                to: this.props.toUser,
                parentId: this.props.parentId
            }
    
            console.log("\n\n\n sendSpotlightToDenProfile :: ", data)
            sendSpotlightToDenProfile(data, this.updatedSpotlightDetails)
        }

    }

    updatedSpotlightDetails = (result) =>{
        //console.log("\n\n\n updatedSpotlightDetails :: ", result)
        toast.success(`${result.amount} EC has been donated as spotlight.`)
        this.props.updateState('openSpotlight', false)
        if(this.props.type === DEN_STRING.SPOTLIGHT.TYPE.USER){
            this.props.close();
        }else if(this.props.isLiveStreaming){
            this.props.close(result);
        }
    }

    sendSpotlightAsReplyOrComment = () => {
        

        var comment = {
            postId: this.state.commentDetails.postId,
            description: this.state.spotlightReply,
            denId: this.state.commentDetails.denId,
            spotlight: this.state.spotLightRange?.SET_SPOTLIGHT_VALUE[this.state.spotlightAmount],
            spotlightStar: parseInt(this.state.spotlightAmount)+1,
            isPostComment: this.state.commentDetails.isPostComment,
        }

        console.log("\n\n\n INSIDE COMMENT TO SEND  ",comment )

        replyOnPostOrComment(comment, comment.isPostComment, this.props.setReply, this.props.updateDenProfile);
    }

    changeSpotlightAmount = (event) =>{
        this.setState({
            spotlightAmount: event.target.value
        });
    }
    
    render() {

        const {denProfile,spotlightAmount, spotLightRange, styleData} = this.state;
        var profilePicture = getDenProfilePicture(denProfile);
        const colorCode = styleData[this.state.spotlightAmount];
        return (
            <Modal className="d-flex flex-column w-100" show={this.props.openSpotlight} dialogClassName="testsss my-0">
                <div className='text-center cursor-pointer'><AiFillCloseCircle onClick={() => {return(this.props.onClose, this.props.updateState('openSpotlight', false))}} style={{height:'40px', width:'40px', color: 'white' }} /></div>
                <div className='text-white post-action-container mt-auto actions-body  my-2'>
                    <div className='cursor-pointer d-flex align-items-center m-2'>
                        {/* <img src={POST_INTERACTIONS.ACTIONS.SAVE} alt='report-post' width='32' className='me-3' /> */}
                        <IoIosArrowBack className='cursor-pointer' onClick={() => this.props.updateState('openSpotlight', false) } />
                        <p className='fs-16 fw-700 my-0 mx-3 flex-grow-1'> Send Spotlight</p>
                        {/* 
                            <div className='d-flex align-items-center'>
                                <Button className='fs-700' variant='secondary' onClick={() => this.updateSpotlightAmount('decrement')}>-</Button>
                                <input type='number' className='spotlight-input text-center text-white' value={this.state.spotlightAmount} min={100} max={500}/>
                                <Button className='fs-700' variant='secondary' onClick={() => this.updateSpotlightAmount('increment')}>+</Button>
                            </div> 
                        */}
                     
                        <input type="range" min="0" max="4" style={{accentColor: colorCode}} value={this.state.spotlightAmount} onChange={(e)=>this.changeSpotlightAmount(e)} step="1" />

                    </div>
                    <div className='d-flex m-2'>
                        <div className='spotlight-container flex-grow-1 me-2' style={this.props.enableComment ? {minHeight: '64px'}:{minHeight: 'auto'}}>
                        <div className='send-spotlight-top d-flex align-items-center p-0'>
                            <Image src={profilePicture?.url} param={denProfile?.name} alt='user' className='user-icon mx-2' />
                            <p className='fs-12 fw-600 my-0 text-white-50 flex-grow-1'>{denProfile?.name}</p>
                            <div className='d-flex align-items-center spotlight-number p-2'>
                            <Image src={DEN.SPOTLIGHT.STARS[spotlightAmount]} param='send' alt='' className='me-1' style={{ width: '15px', height: '15px'}}/>
                            <p className='fs-14 fw-600 my-0' style={{color: colorCode}}>{spotLightRange?.SET_SPOTLIGHT_VALUE[spotlightAmount]}</p>
                            </div>
                        </div>
                        {this.props.enableComment && 
                            <input placeholder={`Post as ${this.props.denProfile?.name}...`} className='spotlight-text-input text-white w-100 p-2' 
                                onChange={(e) => this.updateSpotlightReply(e.target.value)}
                                value={this.state.spotlightReply}
                            />
                        }
                        </div>
                        <div className='column-center-align-div send-spotlight-btn cursor-pointer' onClick={()=>this.sendSpotlightToDenProfile()}>
                            <Image src={POST_INTERACTIONS.ACTIONS.SEND} param='send' alt='' className='' style={{ width: '15px'}}/>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
