import React, { Component } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, LineShareButton,
    FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LineIcon
} from "react-share";

import { toast } from "react-toastify";
import { Dropdown } from 'react-bootstrap';
import { capitalize, dateFormat, ellipsis, errorHandler, updateLoader } from '../../utils/common.utils';
import { MdOutlineContentCopy } from 'react-icons/md';
import TeamApis from '../../helper/teams.api';
import { MultiSelect } from "react-multi-select-component";
import MatchesApis from '../../helper/matches.api';
import TournamentApis from '../../helper/tournament.api';
import { addParticipantToFirstRound } from '../../utils/Tournament.utils';

export default class MatchInvite extends Component {

    constructor(props) {
        super(props)
        this.state = {
            invitationLink: null,
            selectedRole: ((props.choice === 'Join Tournament') && (props.teamsArray[0]) ? props.teamsArray[0].name : null),
            selectedRoleId: ((props.choice === 'Join Tournament') && (props.teamsArray[0]) ? props.teamsArray[0]._id : null),
            isPaidTournament: ((props.choice === 'Join Tournament') && (props.isPaidTournament) ? props.isPaidTournament : false),
            step: 1,
            membersToDistributePrizeMoney: [],
            selectedMembersToDistributePrizeMoney: [],
            tournamentDetails: null
        }

        this.teamApis = new TeamApis();
        this.matchesApis = new MatchesApis();
        this.tournamentApis = new TournamentApis();
        if(props.tournamentId){
            this.getData(props.tournamentId)
        }
    }

    componentDidMount() {
        const baseUrl = window.location.hostname === 'localhost' ? process.env.REACT_APP_WEB_URL : window.location.origin;

        if(this.props.choice === "Send Invite"){
            console.log(this.props.choice+"---groupIndex :: ",this.props.groupIndex)
            var additionalParam = (this.props.matchId ? `matchId=${this.props.matchId}` : `groupIndex=${this.props.groupIndex}`);
            this.setState({
                invitationLink:`${baseUrl}/?joinTournamentId=${this.props.tournamentId}&stageId=${this.props.stageId}&${additionalParam}&gameId=${this.props.gameId}&participantType=${this.props.participantType}&isRoundRobin=${this.props.isRoundRobin ? this.props.isRoundRobin : false}&isPaidTournament=${this.props.isPaidTournament ? this.props.isPaidTournament : false}&groupIndex=${this.props.groupIndex}`
            });
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps.choice === 'Join Tournament'){
            this.setState({
                selectedRole: newProps.teamsArray[0]? newProps.teamsArray[0].name : this.state.selectedRole,
                selectedRoleId: newProps.teamsArray[0]? newProps.teamsArray[0]._id : this.state.selectedRoleId,
            })
        }
    }

    getData = (id) => {
        const data = {
            _id: id
        };

        updateLoader(true);

        this.tournamentApis.getTournaments(data).then(
            (res) => {
                //this.props.dispatchData(res.data.result[0])
                this.setState({
                    tournamentDetails: res.data.result[0]
                });
                updateLoader(false);
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    copyLinkToClipBoard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(this.state.invitationLink);
        toast.success("Invitation link has been copied to the clipboard.");
    }

    sendInvite = () => {
        const tournamentDetails = this.state.tournamentDetails;
        var inviteText = `Join Espotz Tournament with name-${tournamentDetails?.name}, participation type -${tournamentDetails?.participantType}, competition type-${tournamentDetails?.competitionType}`;
        if(tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount){
            inviteText = inviteText + ` with entry fee ${tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount}EC having prize pool amount ${tournamentDetails?.entryFeeAndPrizeDetails?.prizePoolAmount}EC. Registration starts at ${dateFormat(tournamentDetails?.registrationStartTime)}`
        }else{
            inviteText = inviteText + `.Registration starts at ${dateFormat(tournamentDetails?.registrationStartTime)}`
        }

        console.log("this.state.invitationLink :: ",this.state.invitationLink)
        

        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={this.props.onClickOpenInvite} />

                <p className="fs-20 fw-600 text-center text-white mb-0">Invite</p>
                <div id="link-section">

                    <div className="d-flex justify-content-between my-4">
                        <p className="fw-bold fs-16 text-white">Link</p>
                        <p className="fw-bold fs-16 text-white">Participant</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            type="url"
                            defaultValue={this.state.invitationLink ? this.state.invitationLink : ""}
                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                            disabled
                            style={{ paddingRight: '35px' }}
                        />
                        <MdOutlineContentCopy className="text-white me-3 position-absolute" style={{ right: '10px',zIndex:1 }} onClick={(e) => this.copyLinkToClipBoard(e)} />
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <div className="d-flex align-items-center w-50 mb-0">
                            <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                            <p className="fs-14 fw-normal text-white-50 mx-2 my-0">OR</p>
                            <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                        </div>
                    </div>
                    <div className="text-center">
                        <WhatsappShareButton className="px-1" url={this.state.invitationLink} quote={inviteText} title={inviteText}>
                            <WhatsappIcon size={"2rem"} round={true} />
                        </WhatsappShareButton>

                        <FacebookShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                            <FacebookIcon size={"2rem"} round={true} />
                        </FacebookShareButton>

                        <TelegramShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                            <TelegramIcon size={"2rem"} round={true} />
                        </TelegramShareButton>

                        {/* <LineShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                            <LineIcon size={"2rem"} round={true} />
                        </LineShareButton> */}

                        <TwitterShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                            <TwitterIcon size={"2rem"} round={true} />
                        </TwitterShareButton>
                    </div>
                </div>
            </div>
        );
    }

    onRoleChangeHandler = (e) => {
        this.setState({
            selectedRole: e.target.name,
            selectedRoleId: e.target.id
        });
    };

    joinTournament = () => {
       
        return (
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={() => this.props.onClickOpenInvite(false)} />

                <p className="fs-20 fw-600 text-center text-white mb-0">Join Tournament</p>
                <div id="invite-section">
                    <p className="fw-600 fs-14 text-center text-white-50 mt-4 mb-1">
                        Please select a team by which you wish to join this tournament.
                    </p>
                    <Dropdown className="mb-2 select-game-dropdown">
                        <Dropdown.Toggle className='dropdown-basic w-100 text-start ps-3 height-45'>
                            {ellipsis(this.state.selectedRole, 25)}
                        </Dropdown.Toggle>
                        {
                            this.props.teamsArray.length > 1 ?
                                <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                                    {
                                        this.props.teamsArray.map((team, i) => {
                                            if (this.state.selectedRole !== team.name) {
                                                return (
                                                    <Dropdown.Item key={i} href="#" id={team._id} name={team.name}>
                                                        {ellipsis(team.name, 25)}
                                                    </Dropdown.Item>
                                                )
                                            }
                                        })
                                    }
                                </Dropdown.Menu>
                                :
                                null
                        }
                    </Dropdown>

                    <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" onClick={() => this.props.onClickOpenInvite(this.state.selectedRoleId)}>
                        Join Tournament
                    </button>
                </div>
            </div>
        );
    }

    fetchClanDetails = () =>{
        console.log("onClickOpenInvite............ ",this.state.selectedRoleId)
    
        updateLoader (true);
        this.teamApis.getClanFromTeamId({ teamId: this.state.selectedRoleId }).then(
            (res) => {
                console.log("RESPONSE :: ",res.data.result.members);

                var membersToDistributePrizeMoney = [];
                Object.keys(res.data.result.members).map((key, i) => {
        
                    if (key !== 'creator') {
                        res.data.result.members[key].map((member, index) => {
                            if (this.state.selectedTeamMember !== `${member.name} (${key})`) {
                                member.label = `${member.name} ( ${key} )`;
                                member.value = member.id;
                                membersToDistributePrizeMoney.push(member);
                            }
                        });
                    }
                });
        
                this.setState({
                    membersToDistributePrizeMoney: membersToDistributePrizeMoney,
                    step: 2
                });

                updateLoader (false);

            }
        ).catch(
            (err) => {
                updateLoader (false);
                errorHandler(err);
            }
        )
        
    }


    initiateRegistration = (e) => {
        e.preventDefault();
        var distributePrizeTo = this.state.selectedMembersToDistributePrizeMoney.map((value, i) => {
            return value.id
        });
        
        var data = {
            participantId: this.state.selectedRoleId,
            distributePrizeTo: distributePrizeTo
        };
        var stageId = parseInt(this.props.param.stageId);
        console.log("----this.props :: ",this.props)
        console.log("----stageId :: ",stageId)
        console.log("this.stages :: ",this.props.joinTournamentDetails?.stages)
        if(stageId === 0 && this.props.joinTournamentDetails?.competitionType === "DUEL" && this.props.joinTournamentDetails?.stages[stageId]?.subType !== 'Round Robin Groups'){
            addParticipantToFirstRound(this.props.param, data, this.props.clearParams)
        }else{
            
            updateLoader(true);
            this.matchesApis.addParticipants(this.props.param?.matchId, data).then((res) => {
                toast.success("You are participated in tournament successfully");
                console.log("********8 RESPONSE :: ", res.data.result);
                updateLoader(false);
                this.props.onClickOpenInvite();
                this.props.history.push(`/tournament/${this.props.param?.joinTournamentId}`);
            }).catch((reason) => {
                errorHandler(reason)
                this.props.onClickOpenInvite();
                updateLoader(false);
            });
        }
       // addParticipantToFirstRound(this.props.param, data, this.props.clearParams);
        
    }


    getStepDetails = (step) => {

        if(step === 1){
            return (
                <div id="invite-section">
                    <p className="fw-600 fs-14 text-center text-white-50 mt-4 mb-1">
                        Please select a team by which you wish to join this tournament.
                    </p>
                    <Dropdown className="mb-2 select-game-dropdown">
                        <Dropdown.Toggle className='dropdown-basic w-100 text-start ps-3 height-45'>
                            {this.state.selectedRole}
                        </Dropdown.Toggle>
                        {
                            this.props.teamsArray.length > 1 ?
                                <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                                    {
                                        this.props.teamsArray.map((team, i) => {
                                            if (this.state.selectedRole !== team.name) {
                                                return (
                                                    <Dropdown.Item key={i} href="#" id={team._id} name={team.name}>
                                                        {ellipsis(team.name, 15)}
                                                    </Dropdown.Item>
                                                )
                                            }
                                        })
                                    }
                                </Dropdown.Menu>
                                :
                                null
                        }
                    </Dropdown>

                    <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" onClick={() => this.fetchClanDetails()}>
                        Continue
                    </button>
                </div>
            );
        }else{
            return (
                <div id="invite-section">
                    <p className="fw-600 fs-14 text-center text-white-50 mt-4 mb-1">
                        Prize money will be credited to wallets.It will distributed equally among the selected players
                    </p>

                    <MultiSelect
                        className='text-dark'
                        options={this.state.membersToDistributePrizeMoney}
                        value={this.state.selectedMembersToDistributePrizeMoney}
                        onChange={(e) => this.setState({ selectedMembersToDistributePrizeMoney: e })}
                        labelledBy="Prize Money Collectors"
                    />

                    <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" disabled={this.state.selectedMembersToDistributePrizeMoney.length === 0 ? true : false} onClick={(e) => this.initiateRegistration(e)}>
                        Join Tournament
                    </button>
                </div>
            );
        }
       
       
    }

    joinPaidTournament = () => {
        return(
            <div className='p-3'>
                <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={() => this.props.onClickOpenInvite(false)} />

                <p className="fs-20 fw-600 text-center text-white mb-0">{this.state.step !== 2 ? 'Join Tournament' : 'Who will receive money'}</p>
                <p className="fs-12 fw-500 text-center text-white-50 mb-2">Step {this.state.step} of 2</p>

                {this.getStepDetails(this.state.step)}
            
            </div>
        );
    }

    render() {
      //console.log("this.props.teamsArray :: ",this.props.isPaidTournament)
        return (
            <div>
                {(() => {
                    switch (this.props.choice) {

                        case 'Send Invite':
                            return this.sendInvite();

                        case 'Join Tournament':
                            if(this.state.isPaidTournament){
                                return this.joinPaidTournament();
                            }else{
                                return this.joinTournament();
                            }

                        default:
                            return this.sendInvite();

                    }
                }
                )()}
            </div>
        );
    }
}


