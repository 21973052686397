import React, {Component} from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

export default class ColorPicker extends Component {
   
    constructor(props) {
        super(props)
        this.state = {
            showPicker: false,
            color:  props.value? props.value: '#ffc107',
            uniqueKey: props.uniqueKey? props.uniqueKey: null,
        }
    }

    // To Set previously selected color code on color picker
    componentWillReceiveProps(newProps){
        this.setState({
            color:  newProps.value? newProps.value: this.state.color,
            uniqueKey: newProps.uniqueKey? newProps.uniqueKey: this.state.uniqueKey,
        });
    }
 
    onClick = () => {
        this.setState({ 
          showPicker: !this.state.showPicker 
        })
    };
 
    // To close color picker
    onClose = () => {
      this.setState({ 
        showPicker: false 
      })
    };
 
    // Set selected color code
    onChange = (color) => {
        this.setState({ 
          color: color.hex 
        });
        this.props.colorOnChange(this.props.uniqueKey, color);
    };
 
    render() {
      const styles = reactCSS({
        'default': {
          color: {
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            background: this.state.color,
          },
          popover: {
            position: 'absolute',
            zIndex: '3',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
          swatch: {
            padding: '2px',
            background: '#ffffff',
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
          },          
        },
      });
 
      return (
        <div>
          <div style={ styles.swatch } onClick={ this.onClick }>
            <div style={ styles.color } />
          </div>
          { this.state.showPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ this.onClose }/>
            <SketchPicker color={ this.state.color } onChange={ this.onChange } />
          </div> : null }
 
        </div>
      )
    }
}