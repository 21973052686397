import React, { Component } from "react";
// Assets
import { IoIosCloseCircleOutline } from "react-icons/io";
import { VscLock } from "react-icons/vsc";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { RiErrorWarningLine } from "react-icons/ri";
// Style
import '../../styles/CreateNewPassword.css'
// Services
import AuthApis from "../../helper/auth.api";
// Utils
import { AxiosResponse, AxiosError } from "axios";
import validator from "validator";
import { errorHandler, updateLoader } from "../../utils/common.utils";
import { toast } from "react-toastify";

class CreateNewPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            newPasswordErrMsg: '',
            invalidPassword: false,
            confirmPasswordErrMsg: '',
            passwordMatch: false,
            showPassword: false
        };

        this.api = new AuthApis();
    }

    // Process new password to check if it's ready to process or not
    updateNewPassword = (e) => {
        this.setState({ newPassword: e.target.value });
        this.validateNewPassword(e.target.value);
    }

    // Process confirm password to check if it's ready to process or not
    updateConfirmPassword = (e) => {
        const { newPassword } = this.state;
        this.setState({ confirmPassword: e.target.value });
        this.validateRepeatPassword(e.target.value, newPassword);
    }

    // To check if the new Password is strong or not
    validateNewPassword = (password) => {
        if (password === "") {
            this.setState({
                newPasswordErrMsg: "*password required",
                invalidPassword: true,
            });
        } else {
            if (
                validator.isStrongPassword(password, {
                    minLength: 6,
                    minUppercase: 1,
                    minNumbers: 1,
                    minSymbols: 1,
                })
            ) {
                this.setState({
                    newPasswordErrMsg: "Strong Password",
                    invalidPassword: false
                });
            } else {
                this.setState({
                    newPasswordErrMsg: "Not so strong password",
                    invalidPassword: true
                });
            }
        }
    };

    // To check if new and confirmed password is same or not
    validateRepeatPassword = (repeat, newPassword) => {
        const { invalidPassword } = this.state;
        if (!invalidPassword) {
            if (newPassword === repeat) {
                this.setState({
                    confirmPasswordErrMsg: '',
                    passwordMatch: true
                })
            } else {
                this.setState({
                    confirmPasswordErrMsg: 'Passwords must be same',
                    passwordMatch: false
                })
            }
        }
    }

    // To display entered password field in readable format
    handleShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword });
    };

    // Make API call to update new password
    createNewPassword = (e) => {
        e.preventDefault();
        var data = {
            token: this.props.createNewPasswordToken,
            newPassword: this.state.confirmPassword.trim()
        };
        updateLoader(true);
        this.api.resetPasswordThroughLink(data)
            .then((response) => {
                toast.success(response.data.message);
                this.closeCreateNewPassword();
                updateLoader(false);
            }).catch((reason) => {
                updateLoader(false);
                errorHandler(reason);
            });

    }

    // Discard new password process and close modal
    closeCreateNewPassword = () => {
        this.props.closeCreateNewPassword();
    }

    // Get input field to receive new password
    renderNewPassword = () => {
        return (
            <div className="">
                <label htmlFor="newPassword" className="fs-16 fw-600 text-white mb-1">New Password</label>
                <div className="">
                    <VscLock className="field-icon position-absolute" style={{ marginTop: '12px' }} />
                    <input
                        type="password"
                        id="newPassword"
                        placeholder="Password"
                        autoComplete="off"
                        onChange={(e) => this.updateNewPassword(e)}
                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white height-45"/>
                </div>
                {this.state.invalidPassword && (<p className="error-msg">{this.state.newPasswordErrMsg}</p>)}
            </div>
        )
    }

    // Get input field to receive confirmed new password
    renderRepeatPassword = () => {
        const { showPassword } = this.state
        const inputType = showPassword ? "text" : "password";
        return (
            <div className="mt-3">
                <label htmlFor="repeatPassword" className="fs-16 fw-600 text-white mb-1">Repeat Password</label>
                <div className="">
                    <VscLock className="field-icon position-absolute" style={{ marginTop: '12px' }} />
                    {showPassword && (
                        <FiEye className="field-icon position-absolute field-eye-icon" style={{ marginTop: '12px', right: '30px' }} onClick={this.handleShowPassword} />
                    )}
                    {!showPassword && (
                        <FiEyeOff className="field-icon position-absolute field-eye-icon" style={{ marginTop: '12px', right: '30px' }} onClick={this.handleShowPassword} />
                    )}
                    <input
                        type={inputType}
                        id="repeatPassword"
                        placeholder="Repeat Password"
                        autoComplete="off"
                        onChange={(e) => this.updateConfirmPassword(e)}
                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white height-45" />

                </div>
                {!this.state.passwordMatch && (<p className="error-msg">{this.state.confirmPasswordErrMsg}</p>)}
            </div>
        )
    }

    render() {
        const { passwordMatch } = this.state
        const buttonClass = passwordMatch ? '' : 'button-disabled'
        return (
            <div className="create-new-password-page p-3">
                <div className="row">
                    <div className="col-lg-6 col-md-7 col-12 d-flex flex-column">
                        <div>
                            <h1 className="text-heading fs-20 fw-600 text-white text-center mb-1">Create New Password</h1>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.closeCreateNewPassword} />
                        </div>
                        <p className="text-description fs-14 text-white text-center px-2 fw-normal mb-1">
                            Please enter a new password for your Espotz account
                        </p>
                        <form className="d-grid ">
                            {this.renderNewPassword()}
                            {this.renderRepeatPassword()}
                            <div className="d-flex my-2">
                                <RiErrorWarningLine className="btn-size-20 text-warning flex-shrink-0" />
                                <p className="fs-14 text-warning fw-normal mb-0">
                                    Password should have a minimum of 6 characters, 1 capital
                                    letter, 1 number and 1 special character
                                </p>
                            </div>
                            <button disabled={!passwordMatch} className={`white-flat-btn w-75 fw-bold py-2 my-3 m-auto ${buttonClass}`} onClick={(e) => this.createNewPassword(e)} type="submit">
                                Create New Password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateNewPassword