import React, { Component } from "react";
import { Form, Modal } from 'react-bootstrap';
import '../../../styles/Tournaments.css';
//images, icons
import { BsInfoCircle } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import cancel from '../../../images/games/cancel.png';
import TournamentHeader from './common/TournamentHeader';
import { errorHandler, getCancelTournamentCommission, getTermsAndConditionsByType, getTournamentCancellationReasons, getTournamentData, updateLoader } from '../../../utils/common.utils';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import TournamentApis from '../../../helper/tournament.api';
import { toast } from 'react-toastify';
import { createAndSendNotification } from '../../../utils/notification.utils';
import { TERMS_AND_CONDITIONS_TYPES } from '../../../utils/enums.utils';
import TermsConditions from '../../popup/TermsConditions';

class CancelAndRefund extends Component {
    constructor(props) {
        super(props)
        this.tournamentApis = new TournamentApis();
        this.state = {
            openConfirmModal: false,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : null,
            cancellationReasons: [],
            selectedReason: '',
            openTermsModal: false,
            acceptCheck: false,
        }
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        };
        getTournamentCancellationReasons(this.setReasons)
        this.getTermsData = getTermsAndConditionsByType(TERMS_AND_CONDITIONS_TYPES.CANCEL_REFUND);
        //getCancelTournamentCommission(props.tournamentData)
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.refundPercent = getCancelTournamentCommission(this.state.tournamentDetails);
    }

    setReasons = (reasons) => {
        this.setState({cancellationReasons: reasons});
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    toggleConfirmModalStatus = () => {
        this.setState({ openConfirmModal: !this.state.openConfirmModal })
    }

    unPublishAndRefund = () => {
        if(this.state.selectedReason === ''){
            toast.warning('Please select a reason for cancellation.');
            return false;
        }
        const data = {
            tournamentId: this.props.match.params.id,
            reason: this.state.selectedReason
        }
        
        updateLoader(true);
        this.tournamentApis.unPublishAndRefund(data).then((res) => {
            updateLoader(false);
            // this.props.dispatchData(res.data.result)
            toast.success("Tournament unpublish and amount refunded successfully");
            var notification =  {
                type : "TOURNAMENT",
                heading : "Tournament Cancelled ❌\n",
                message :  `Tournament '${this.state.tournamentDetails.name}' has been cancelled by Organizer, You are eligible to get refund in case you have paid entry fees.`,
                link : null
            };
            createAndSendNotification(notification,this.state.tournamentDetails.participantUserId);
            this.props.history.push({
                pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview`
            });
        }).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err, this.props.logout);
        });
    }

    updateReason = (value) => { 
        this.setState({selectedReason: value})
    }

    updateOpenTermsModalStatus = (type) => {
        this.setState({ openTermsModal: type })
    }

    onAcceptTerms = (type) => {
        this.setState({
            openTermsModal: false,
            acceptCheck: type,
        })
    }

    render() {
        console.log('Cancel Tournament Commission ::', getCancelTournamentCommission(this.state.tournamentDetails));
        console.log('this.refundPercent ::', this.refundPercent);
        return (
            <div>
                <section className="body-section cancel-refund-page create-tournament">
                    <div className="container h-100 d-flex flex-column p-3">

                        <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={cancel} heading="Cancel & Refund" history={this.props.history} />

                        <p className='fs-12 fw-600 text-center text-white-50 mt-4 mb-5'>
                            Every participant will be notified that the tournament has been cancelled and they will
                            receive their refund
                        </p>
                        <div className='row'>
                            <div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12'>
                                <div className="purple-field d-flex align-items-center w-100 border-0 text-white fs-14 height-45">
                                    <label className='fs-16 fw-600 text-center text-white w-100 mb-0'>Amount Deposited = {this.state.tournamentDetails?.entryFeeAndPrizeDetails?.commissionPaidAmount ? this.state.tournamentDetails?.entryFeeAndPrizeDetails?.commissionPaidAmount : '00'} EC</label>
                                </div>
                            </div>
                            <div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12'>
                                <div className="purple-field d-flex align-items-center w-100 border-0 text-white fs-14 height-45 mt-4">
                                    <div className='fs-16 fw-600 text-center w-100 mb-0'>
                                        <label className='mb-0' style={{ color: '#60f82b' }}>
                                            Available Refund Amount = {this.refundPercent ? (100-this.refundPercent)*(this.state.tournamentDetails?.entryFeeAndPrizeDetails?.commissionPaidAmount)/100 : '00' } EC
                                        </label>
                                        <a href="#" title={`You can get a refund of ${100-this.refundPercent}% of your deposited amount.`} className="tooltip-info cancel_and_refund">
                                            <BsInfoCircle className="text-white btn-size-12 ms-1" />
                                        </a>
                                    </div>
                                </div>
                                <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#8C2FB8' }}>{`You can get a refund of ${100-this.refundPercent}% of your deposited amount.`}<span className='text-warning px-2'>$$$</span> {`You can get a refund of ${100-this.refundPercent}% of your deposited amount.`} <span className='text-warning px-4'>$$$</span> {`You can get a refund of ${100-this.refundPercent}% of your deposited amount.`} <span className='text-warning px-4'>$$$</span> {`You can get a refund of ${100-this.refundPercent}% of your deposited amount.`}</marquee>
                            </div>

                            <div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 my-3'>
                                <label className="fs-14 fw-500 text-white">Reason for cancellation</label>
                                <Form.Select aria-label="Default select example" className="" value={this.state.selectedReason} onChange={(e) => this.updateReason(e.target.value)}>
                                    <option value=''>Choose one option from below</option>
                                    {this.state.cancellationReasons.length ? 
                                        this.state.cancellationReasons?.map((reason, i) => { 
                                            return <option value={reason.REASONS} key={i}>{reason.REASONS}</option>
                                        })
                                        :
                                        null
                                    }
                                </Form.Select>
                            </div>
                        </div>


                        <div className="d-flex flex-column justify-content-center align-items-center mt-auto" style={{ marginTop: 'auto' }}>
                            <div className='d-flex justify-content-center align-items-center w-100 my-3'>
                                <input type="checkbox" checked={this.state.acceptCheck}
                                    onChange={() => this.setState({ openTermsModal: !this.state.openTermsModal })}
                                    className='me-2' id="dialog" 
                                />
                                <label className='fs-14 fw-600 mb-0' htmlFor="dialog">I accept the terms of service</label>
                            </div>
                            <button className='white-flat-btn fs-16 fw-600 p-2 align-items-center' style={{ width: 'fit-content' }} onClick={this.toggleConfirmModalStatus}>
                                Cancel & Refund
                            </button>
                        </div>
                    </div>
                </section>

                <Modal centered show={this.state.openConfirmModal} dialogClassName="cancel-refund-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={this.toggleConfirmModalStatus} />
                            <p className="fs-20 fw-600 text-center text-white mb-3">Confirm</p>

                            <p className='fs-14 fw-600 text-center my-3' style={{ color: '#ed4848' }}>
                                Are you sure you want to proceed with the cancellation
                                and refund for the tournament?
                            </p>
                            <div className='d-flex justify-content-center my-3'>
                                <button className='white-flat-btn d-block fw-bold py-2 px-4 mx-2 mt-3"' onClick={this.unPublishAndRefund}>
                                    Yes
                                </button>
                                <button className='white-flat-btn d-block fw-bold py-2 px-4 mx-2 mt-3"' onClick={this.toggleConfirmModalStatus}>No</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Terms and Services Modal */}
                <Modal centered show={this.state.openTermsModal} dialogClassName="social-media-modal">
                    <Modal.Body>
                        <TermsConditions accept={() => { this.setState({ openTermsModal: false, acceptCheck: true }) }} onClose={() => { this.setState({ openTermsModal: false, acceptCheck: false }) }} terms={this.getTermsData?.rule} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(CancelAndRefund)