import React, { Component } from 'react';

import formInfo from '../../../../images/icon/formInfo.png';
import TournamentHeader from '../common/TournamentHeader';
import { errorHandler, getMatchAndParticipantsDetailsForBracketsDoubleElimination, getTournamentAdditionalData } from '../../../../utils/common.utils';
import MatchesApis from '../../../../helper/matches.api';
import { AxiosResponse, AxiosError } from "axios";
import Accordion from 'react-bootstrap/Accordion'
import { ButtonGroup, Dropdown, SplitButton } from 'react-bootstrap';
import NoRecordFound from '../../../../images/clip-arts/noRecordFound.png';
import { dateFormat } from '../../../../utils/common.utils';
import { BsCalendar } from 'react-icons/bs';
import { HEADING } from '../../../../utils/Strings.utils';
import { getGroupName } from '../../../../utils/Tournament.utils';
import { toast } from 'react-toastify';
import { sendTimeChangeNotificationsToParticipants } from '../../../../utils/notification.utils';

export default class SlotDetails extends Component {

    constructor(props) {
        super(props)

        this.state = {
            openTimeSlotModal: false,
            tournamentDetails: null,
            matchDetailsAdded: false,
            groupIndex: 0,
            round: 1,
            isWinningBrackets: 0,
            isBracketGroup: false,
            errorMessage: ''
        }

        this.matchesApi = new MatchesApis();
        this.tournamentDetails = null;
        this.row = 0;
        this.col = 0;

        props.updateLoader(true);
        getTournamentAdditionalData(this.props.match.params.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    setData = (values) => {
        if (values) {
            this.tournamentDetails = values;

            if (values.stages.length > 0) {
                this.props.updateLoader(true);
                var stageDetails = values.stages[this.props.match.params.index];
                if (stageDetails.type === "DUEL" && stageDetails.subType === "Bracket Groups" && stageDetails.bracketType === "DOUBLE") {
                    this.setState({ isBracketGroup: true });
                    //  getMatchAndParticipantsDetailsForBracketsDoubleElimination(values.stages,this.setMatchData,this.props.match.params.index);
                }
                // else{
                //     getMatchAndParticipantsDetails3(values.stages,this.setMatchData,this.props.match.params.index);
                // }
                getMatchAndParticipantsDetailsForBracketsDoubleElimination(this.props.match.params.id, this.setMatchData, this.props.match.params.index, values.stages);

            }
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }

    }

    setMatchData = (values) => {
        this.props.updateLoader(false);
        if (values) {
            var tournamentDetails = this.tournamentDetails;
            tournamentDetails.stages = values;
            this.setState({
                tournamentDetails: tournamentDetails,
                matchDetailsAdded: true
            })
            this.props.updateLoader(false);

        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }

    }

    OnclickEditTimeSlot = () => {
        this.setState({
            openTimeSlotModal: !this.state.openTimeSlotModal
        })
    }

    editMatchDetails = (event, name, matchId, row, col,status) => {
        
        if(status !=='UPCOMING'){
            toast.error('Edit access denied after match scheduled time.')
            return false
        }
        this.row = row;
        this.col = col;
        let givenDate = new Date(event.target.value) // future Date
        let diff = new Date().getTime() - givenDate.getTime();
        if (diff > 0) {
            toast.error('Edit access denied after match scheduled time.')
            return false
        }
        let newDate = new Date(event.target.value).toUTCString()
        if (newDate !== 'Invalid Date') {
            var data = {
                matchSchedule: newDate
            }

            this.props.updateLoader(true);
            this.matchesApi.editMatchDetails(matchId, data).then((res) => {
                var tourData = this.state.tournamentDetails;
                tourData.stages[this.props.match.params.index].groups[this.state.groupIndex][row][col] = res.data.result;
                sendTimeChangeNotificationsToParticipants(this.state.tournamentDetails, name, res.data.result.participants, res.data.result.matchSchedule);
                this.setState({
                    tournamentDetails: tourData
                });
                this.props.updateLoader(false);
            }).catch((reason) => {
                this.props.updateLoader(false);
                errorHandler(reason);
            });
        }
    }

    filterGroups = (groupIndex) => {
        this.setState({
            groupIndex: groupIndex
        });
    }




    getEditTimeSlotDetails = () => {
        const stageDetails = this.state.tournamentDetails?.stages ? this.state.tournamentDetails.stages[this.props.match.params.index] : [];
        if (this.state.matchDetailsAdded) {
            if (this.state.isBracketGroup) {
                const matches = stageDetails.groups[this.state.groupIndex]? stageDetails.groups[this.state.groupIndex][this.state.isWinningBrackets] : [];
                console.log("groups :: ", matches);
                return (
                    <div className="body-section slot-details">
                        <div className="container p-3 mt-lg-3">
                            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={formInfo} heading={HEADING.SLOT_DETAILS} subHeading={null} history={this.props.history} />

                            <div className='row d-flex mx-2 my-2 filter-btn split-btn justify-content-around' style={{ flexWrap: 'wrap', fontSize: "10px !important" }}>
                                <SplitButton as={ButtonGroup} key={2} id={`dropdown-button-drop-${2}`} size="sm" variant="primary" title={getGroupName(stageDetails,this.state.groupIndex)} className='col-5 mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                                    {stageDetails?.groups?.map((group, index) => {
                                        return <Dropdown.Item eventKey={index} key={index} onClick={() => this.filterGroups(index)}>{getGroupName(stageDetails,index)}</Dropdown.Item>
                                    })}
                                </SplitButton>

                                {
                                    <SplitButton as={ButtonGroup} id={`dropdown-button-drop-${2}`} size="sm" variant="primary" title={this.state.isWinningBrackets === 0 ? 'Winners' : 'Loosers'} className='col-5 mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                                        <Dropdown.Item key={0} onClick={() => this.setState({ isWinningBrackets: 0 })}>Winners</Dropdown.Item>
                                        <Dropdown.Item key={1} onClick={() => this.setState({ isWinningBrackets: 1 })}>Loosers</Dropdown.Item>
                                    </SplitButton>
                                }
                            </div>

                            <div>
                                <Accordion defaultActiveKey="1">
                                    {
                                        Object.keys(matches).map((key, index) => {
                                            return (
                                                <Accordion.Item eventKey={key} key={key} className='mt-3 rounds-time' style={{ backgroundColor: '#170d2a' }}>
                                                    <Accordion.Header className='round-slot-details rounds-time'>Round {key}</Accordion.Header>
                                                    <Accordion.Body className='row justify-content-center'>
                                                        {matches[key].map((match, i) => {
                                                            const matchScheduledAt = match.matchSchedule;
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-12 my-1 text-center" key={i}>
                                                                    <label className='fs-16 fw-500' htmlFor={`M${key}.${i + 1}`}>M {this.state.groupIndex + 1}.{key}.{i + 1}</label>
                                                                    <div className='d-flex flex-column my-2'>
                                                                        <input onChange={(e) => this.editMatchDetails(e, `M${key}.${i + 1}`, (typeof match === 'string' ? match : match._id), (index + 1), i, match.status)}
                                                                            id={match._id} name={`M${key}.${i + 1}`} type="datetime-local" min={new Date().toISOString().slice(0, 16)}
                                                                            className="bg-transparent text-transparent border-0 p-0 w-100" placeholder="" value={matchScheduledAt}
                                                                            style={{ color: 'transparent', opacity: 0, zIndex: 1 }}
                                                                        />
                                                                        <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ marginTop: '-45px', marginBottom: '0px' }}>
                                                                            {dateFormat(matchScheduledAt)}
                                                                            <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                                                                        </p>
                                                                    </div>
                                                                    {
                                                                        ((this.state.errorMessage) && (this.row === (index + 1)) && (this.col === i)) ?
                                                                            <p style={{ marginTop: "-1px" }} className='text-danger'>{this.state.errorMessage}</p> : null
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                )
            } else {
                const matches = stageDetails.groups[this.state.groupIndex] ? stageDetails.groups[this.state.groupIndex] : [];
                console.log("groups :: ", matches);
                return (
                    <div className="body-section slot-details">
                        <div className="container p-3 mt-lg-3">
                            <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={formInfo} heading={HEADING.SLOT_DETAILS} subHeading={null} history={this.props.history} />

                            {/* Filter to select a group */}
                            <div className='row d-flex mx-2 my-2 filter-btn split-btn justify-content-around ' style={{ flexWrap: 'wrap', fontSize: "10px !important" }}>
                                <SplitButton as={ButtonGroup} key={2} id={`dropdown-button-drop-${2}`} size="sm" variant="primary" title={getGroupName(stageDetails,this.state.groupIndex)} className='col-5 mx-2 my-1 flex-shrink-0 filter-btn-sm slot_details-dropdown' >
                                    {stageDetails?.groups?.map((group, index) => {
                                        return <Dropdown.Item eventKey={index} key={index} onClick={() => this.filterGroups(index)}>{getGroupName(stageDetails,index)}</Dropdown.Item>
                                    })
                                    }   
                                </SplitButton>
                            </div>

                            <div>
                                <Accordion defaultActiveKey="1">
                                    {
                                        Object.keys(matches).map((key, index) => {
                                            return (
                                                <Accordion.Item eventKey={key} key={key} className='mt-3 rounds-time' style={{ backgroundColor: '#170d2a' }}>
                                                    <Accordion.Header className='round-slot-details rounds-time'><b>Round {key}</b></Accordion.Header>
                                                    <Accordion.Body className='row justify-content-center'>
                                                        {matches[key]?.map((match, i) => {
                                                            const matchScheduledAt = match.matchSchedule;
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-12 my-1 text-center" key={i}>
                                                                    <label className='fs-16 fw-500' htmlFor={`M${key}.${i + 1}`}>M {this.state.groupIndex + 1}.{key}.{i + 1}</label>
                                                                    <div className='d-flex flex-column my-2'>
                                                                        <input id={match._id} name={`M${key}.${i + 1}`} type="datetime-local"
                                                                            onChange={(e) => this.editMatchDetails(e, `M${key}.${i + 1}`, (typeof match === 'string' ? match : match._id), (index + 1), i, match.status)} 
                                                                            className="bg-transparent text-transparent border-0 p-0 w-100" 
                                                                            placeholder="" value={matchScheduledAt} style={{ color: 'transparent', opacity: 0, zIndex: 1 }} 
                                                                        />
                                                                        <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ marginTop: '-45px', marginBottom: '0px' }}>
                                                                            {dateFormat(matchScheduledAt)}
                                                                            <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                                                                        </p>
                                                                    </div>
                                                                    {
                                                                        ((this.state.errorMessage) && (this.row === (index + 1)) && (this.col === i)) ?
                                                                            <p style={{ marginTop: "-1px" }} className='text-danger'>{this.state.errorMessage}</p> : null
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </div>
                )
            }

        } else {
            return (
                <div className='body-section d-flex'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <img src={NoRecordFound} className="w-100" alt='' />
                    </div>
                </div>
            );
        }
    }

    render() {
        const getEditTimeSlotDetails = this.getEditTimeSlotDetails();
        return (
            <div>
                {getEditTimeSlotDetails}
            </div>
        )

    }
}
