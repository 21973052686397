import React, { Component } from 'react';
import { ellipsis, scoreCardGameURL } from '../../../../utils/common.utils';
import orgIc from "../../../../images/icon/org-ic.png";

export default class CardHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {
        this.setState({
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getHeader = () => {
        const organizationDetails = this.state.tournamentDetails?.organizationDetails
        if(this.state.tournamentDetails.competitionType === 'DUEL'){
            return(
                <div className='text-center d-flex justify-content-between align-items-end'>
                    <div className='mb-auto'>
                        <img src={this.gameUrl} className="mt-2" alt='' style={{ zIndex: '1', height: '25px' }} />
                    </div>
                    <div className='d-flex align-items-center'>
                        <h5 className='text-white fw-bold fs-10 mt-2 me-2 mb-0'>{ellipsis(organizationDetails?.name, 9)}</h5>
                        <img src={organizationDetails ? organizationDetails?.logo?.url :orgIc} className="mt-2" alt='' style={{ zIndex: '1', height: '30px' }} />
                    </div>
                </div>
            )
        }else{
            return(
                <div className='row'>
                    <div className='col-4 text-start'>
                        <p className='fs-8 fw-700 text-info mb-0'>R = RANK</p>
                        <p className='fs-8 fw-700 text-info mb-0'>K = KILLS</p>
                    </div>
                    <div className='col-4 text-center'>
                        <img src={this.gameUrl} className="" alt='' style={{ zIndex: '1', height: '25px' }} />
                    </div>
                    <div className='col-4 text-end'>
                    <div className='d-flex align-items-center justify-content-end'>
                            <h5 className='text-white fw-bold fs-8 me-2 mb-0'>{ellipsis(organizationDetails?.name, 9)}</h5>
                            <img src={organizationDetails ? organizationDetails?.logo?.url :orgIc} className="" alt='' style={{ zIndex: '1', height: '25px' }} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.getHeader()
    }
}