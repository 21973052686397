import React, { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../../styles/activate-account.css";

class AssignMember extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <>
                <div className="p-3">
                    <IoIosCloseCircleOutline className="close-icon" onClick={()=>{this.props.onClick('openAssignMember')}} />
                    <p className="fw-bold fs-20 text-center text-white mb-4">Assign Captain</p>
                    <div className="owner-body">
                        <div className="col-3">
                            <div className="owner-avatar"></div>
                        </div>
                        <div className="col-6">
                            <div className="fs-16 fw-500 text-white text-start text-truncate">Jean Shawn</div>
                        </div>
                        <div className="col-3 text-start">
                            <button type="button" className="btn clanMinus-icon" data-dismiss="modal" aria-label="Close" onClick={this.onClickOpenConfirm}>
                                <AiOutlineCheckCircle className="btn-size-20 text-white cursor-pointer" />
                            </button>
                        </div>
                    </div>

                    <div className="owner-body mt-2">
                        <div className="col-3">
                            <div className="owner-avatar"></div>
                        </div>
                        <div className="col-6">
                            <div className="fs-16 fw-500 text-white text-start text-truncate">Jean Shawn</div>
                        </div>
                        <div className="col-3 text-start">
                        </div>
                    </div>
                    <button type="button" className="white-flat-btn fw-bold py-2 px-4 m-auto mt-3 d-block">Ok</button>
                </div>
            </>
        )
    }
}

export default AssignMember