import React, { Component } from 'react';
// Style
import "../../styles/EmailVeification.css";
// Assets
import Checked from "../../images/checked.png";
import Expired from "../../images/expired.png";
// Services
import AuthApis from '../../helper/auth.api';
// Utils
import { errorHandler } from '../../utils/common.utils';
import { AxiosResponse, AxiosError } from "axios";

export default class EmailVerification extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isLinkVerified: false
    };

    this.authService = new AuthApis();
  }

  componentDidMount() {
    
    this.props.showOrHideNav(false);

    // Fetch verification token from URL
    var authToken = new URLSearchParams(this.props.location.search).get("emailVerificationToken");
    var requestBody = {
      token: authToken
    };

    // Validating email
    this.authService.verifyAccount(requestBody)
    .then((response) => {
      this.setState({
        isLinkVerified: true
      });
    })
    .catch((error) => {
      this.setState({
        isLinkVerified: false
      });
      errorHandler(error);
    });

  }

  render() {
    const baseUrl = window.location.hostname === 'localhost' ? process.env.REACT_APP_WEB_URL : window.location.origin;
    return (
      <div id='oopss'>
        <div id='error-text'>
          {this.state.isLinkVerified ?
            // If Email verification is succeed
            <div className="w-75">
              <img src={Checked} width="200" height="200" alt="checked" />
              <h3 className="mtp">Congratulations !</h3>
              <p className="fs-30">Your email address has been verified successfully</p>
              <p className='hmpg'><a href={baseUrl} className="back">Back To Home</a></p>
            </div>
            :
            // Else Email verification is failed or Link expired
            <div className="w-75">
              <img src={Expired} width="200" height="200" alt="checked" />
              <h3 className="mtp">This link is expired</h3>
              <p className="fs-30">Please <a href={baseUrl}>contact customer</a> support to receive a new verification Link. The Link will be active for 10 minutes.</p>
              <p className='hmpg'><a href={baseUrl} className="back">Back To Home</a></p>
            </div>
          }
        </div>
      </div>
    );
  }
}
