import React, { Component } from 'react';

// Images
import descriptionIc from '../../../images/icon/description-ic.png';
import stages from "../../../images/icon/stages.png";
import { ASSETS } from '../../../utils/Strings.utils';
import Scoreboard from './Scoreboard';


export default class PageInformation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: this.props.tournamentDetails,
        }

    }

    render() {
        console.log("this.props.tournamentDetails?.stages :: ",this.props.tournamentDetails?.stages?.length)
        return (
            <section className='tournament-information-page text-white'>
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <span className='d-flex'>
                                <img src={descriptionIc} className='' alt='' width={'16px'} height={'20px'}/><h1 className='fs-16 fw-700 ms-2'>Description</h1>
                            </span>
                            <p className='fs-14 ms-2 fw-bolder text-white-50'>
                                {this.props.tournamentDetails?.bio}
                            </p>
                        </div>
                        {/* <Scoreboard /> */}
                        <div className="col-12 mt-2">
                            <span className='d-flex'>
                                <img src={stages} className='' alt='' width={'16px'} height={'20px'}/><h1 className='fs-16 fw-700 ms-2'>Stages</h1>
                            </span>
                            {this.props.tournamentDetails?.stages?.length === 0 ?
                                 <div className="d-flex justify-content-center">
                                    <img className="no-tour-found " src={ASSETS.EMPTY_COLLECTION} alt={""} />
                                </div>
                                :
                                <div className='row'>
                                    {this.props.tournamentDetails?.stages?.map((stage, i)=>{
                                        var maxParticipants;
                                        let playerType = this.state.tournamentDetails?.participantType === 'SINGLE' ? 'Players' : 'Teams'
                                        if(stage.subType === 'FFA Flexible Stage'){
                                            maxParticipants = (stage.numberOfMatches)*(stage.maxParticipantsPerMatch);
                                        }else{
                                            maxParticipants = (stage.maxParticipants)
                                        }
                                        return (
                                            <div className='col-12 col-md-6 col-lg-4' key={i}>
                                        <div className='grey-card mt-2 mt-lg-4 p-3'>
                                            <div className='d-flex justify-content-between'>
                                                <h1 className='fs-16 fw-700'>{stage.name}</h1>
                                                <button className='flat-btn border-0 px-4 fw-600' onClick={(e)=>{this.props.activeTab(e,'Matches',i)}}>View</button>
                                            </div>
                                            <div className='d-flex justify-content-between mt-2'>
                                                <h1 className='fs-12 fw-700 text-white-50 mb-0'>{stage.subType}</h1>
                                                <h1 className='fs-12 fw-700 text-white-50 mb-0'>{stage.participants}/{maxParticipants} {playerType}</h1>
                                            </div>
                                        </div>
                                    </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
