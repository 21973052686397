import React, { Component } from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { DEN_EMPTY_SCREENS, INNER_CIRCLE_ASSETS } from '../../../../utils/Images.utils';
import Image from '../../../common/Image';

export default class InnerCircle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      innerCircleProfiles: props.innerCircleProfiles ? props.innerCircleProfiles : []
    };
  }

  componentDidMount(){}

  componentWillReceiveProps = (newProps) => {
    this.setState({innerCircleProfiles : newProps.innerCircleProfiles});
  }

  getTopThreeView = () => {
    const innerCircleProfiles = this.props.innerCircleProfiles;
    if(innerCircleProfiles?.length === 1){
      return(
        <div className='d-flex justify-content-around my-3'>
          {/* RANK 1 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>1</p>
            <Image src={innerCircleProfiles[0]?.profilePicture?.url} param={innerCircleProfiles[0]?.from?.name? innerCircleProfiles[0].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[0]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[0]?.spotlight}</p>
          </div>
        </div>
      )
    }else if(innerCircleProfiles?.length === 2){
      return(
        <div className='d-flex justify-content-around my-3'>
          {/* RANK 1 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>1</p>
            <Image src={innerCircleProfiles[0]?.profilePicture?.url} param={innerCircleProfiles[0]?.from?.name? innerCircleProfiles[0].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[0]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[0]?.spotlight}</p>
          </div>
          {/* RANK 2 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>2</p>
            <Image src={innerCircleProfiles[1]?.profilePicture?.url} param={innerCircleProfiles[1]?.from?.name? innerCircleProfiles[1].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[1]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[1]?.spotlight}</p>
          </div>
        </div>
      )
    }else{
      return(
        <div className='d-flex justify-content-around my-3'>
          {/* RANK 2 */}
          <div className='text-center' style={{marginTop: '50px'}}>
            {/* <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.RANK_DOWN} alt='' className='me-2' style={{width:'13px'}}/>2</p> */}
            <p className='fs-16 fw-700 my-1'>2</p>
            <Image src={innerCircleProfiles[1]?.profilePicture?.url} param={innerCircleProfiles[1]?.from?.name? innerCircleProfiles[1].from?.name : 'Test' } className='inner-circle-rank-2' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[1]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[1]?.spotlight}</p>
          </div>
          {/* RANK 1 */}
          <div className='text-center'>
            <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.CROWN} alt='' className='me-2' style={{width:'13px'}}/>1</p>
            <Image src={innerCircleProfiles[0]?.profilePicture?.url} param={innerCircleProfiles[0]?.from?.name? innerCircleProfiles[0].from?.name : 'Test' } className='inner-circle-rank-1' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[0]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[0]?.spotlight}</p>
          </div>
          {/* RANK 3 */}
          <div className='text-center' style={{marginTop: '50px'}}>
            {/* <p className='fs-16 fw-700 my-1'><img src={INNER_CIRCLE_ASSETS.RANK_UP} alt='' className='me-2' style={{width:'13px'}}/>3</p> */}
            <p className='fs-16 fw-700 my-1'>3</p>
            <Image src={innerCircleProfiles[2]?.profilePicture?.url} param={innerCircleProfiles[2]?.from?.name? innerCircleProfiles[2].from?.name : 'Test' } className='inner-circle-rank-3' />
            <p className='fs-16 fw-500 my-1'>{innerCircleProfiles[2]?.from?.name}</p>
            <p className='fs-16 fw-500 my-1' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{innerCircleProfiles[2]?.spotlight}</p>
          </div>
        </div>
      )
    }
  }

  getView =()=>{
    const {innerCircleProfiles} = this.state;
    if(!innerCircleProfiles?.length){
      return(
        <div className='col-12'>
          <div className="inner-circle-container text-center py-3">
            <div className='d-flex align-items-center p-2'>
              <MdOutlineArrowBackIosNew className="cursor-pointer me-2" style={{height:'20px'}} onClick={() => this.props.updateState({showInnerCircle: false})} />
              <p className='fs-24 fw-700 my-0 flex-grow-1 text-start'>Inner Circle</p>
              <IoIosCloseCircleOutline className="cursor-pointer me-2" style={{height:'20px'}} onClick={() => this.props.updateState({showInnerCircle: false})} />
            </div>
            <img className="my-3" src={DEN_EMPTY_SCREENS.NO_INNER_CIRCLE} alt={""} />
            <p className='fs-20 fw-500 opacity-75 my-3'>Build your game by posting, commenting, liking posts, might get you some new fans.</p>
          </div>
        </div>
      )
  }

    return(
      <div className='col-12'>
          <div className='d-flex flex-column' style={{maxHeight: '450px', overflow: 'auto'}}>
              <div className='inner-circle-container'>
                <div className='d-flex align-items-center p-2'>
                    <MdOutlineArrowBackIosNew className="cursor-pointer me-2" style={{height:'20px'}} onClick={() => this.props.updateState({showInnerCircle: false})} />
                    <p className='fs-24 fw-700 my-0 flex-grow-1 text-start'>Inner Circle</p>
                    <IoIosCloseCircleOutline className="cursor-pointer me-2" style={{height:'20px'}} onClick={() => this.props.updateState({showInnerCircle: false})} />
                </div>

                <div >
                  { innerCircleProfiles && this.getTopThreeView() }
              
                  <div className='p-2'>
                    {innerCircleProfiles?.map((topFan,i) => {
                      if(i>2){
                        return(
                          <div className='d-flex my-0 px-2 py-1' key={i}>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                              <p className='fs-16 fw-700 my-0'>{i+1}</p>
                              {/* <img src={INNER_CIRCLE_ASSETS.RANK_UP} alt='' className='' style={{width:'13px'}}/> */}
                            </div>
                            <div className='inner-circle-rank-div flex-grow-1 ms-3'>
                            <Image src={topFan?.profilePicture?.url} param={topFan?.from?.name? topFan.from?.name : 'Test' }  className='inner-circle-avatar' />
                              <p className='fs-16 fw-500 my-0 flex-grow-1 ms-3'>{topFan.from.name}</p>
                              <p className='fs-16 fw-500 my-0 me-3' style={{color:'#52FF00'}}><img src={INNER_CIRCLE_ASSETS.SPOTLIGHT} alt='' className='me-2' style={{width:'15px'}}/>{topFan.spotlight}</p>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
                
              </div>
          </div>
      </div>
    )
  }

  render() {
    if(this.state.innerCircleProfiles){
      return(
        <div className='text-white' style={{borderRadius: '8px'}}>
            <div className='container p-0'>
                <div className='row'>
                    {this.getView()}
                </div>
            </div>
        </div>
      )
    }else{
      return null;
    }
    
  }
}
