import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ConfirmationModal from '../../popup/ConfirmationModal';
import TournamentApis from '../../../helper/tournament.api';
import { errorHandler, getTournamentData } from '../../../utils/common.utils';
import { toast } from 'react-toastify';
//icons
import { AiFillPlusCircle, AiFillCloseCircle } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
//images
import form from '../../../images/games/form.png';
import refresh from '../../../images/icon/refresh-ic.png';
import TournamentHeader from './common/TournamentHeader';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';

class RegistrationForm extends Component {
    fieldsObject = [
        { label: 'Real Name', value: 'PUBLIC', isCustom: false },
        { label: 'In-Game Name', value: 'PUBLIC', isCustom: false },
        { label: 'In-Game ID', value: 'PUBLIC', isCustom: false },
        { label: 'Mobile Number', value: 'PUBLIC', isCustom: false },
        { label: 'Discord ID', value: 'PUBLIC', isCustom: false }
    ]
    constructor(props) {
        super(props);
        this.state = {
            openAddDetails: false,
            fieldsArray: props?.tournamentData?.formConfig?.length ? props?.tournamentData?.formConfig : this.fieldsObject,
            details: null,
            openConfirm: false,
            confirm: {
                msg: '',
                key: ''
            },
            deleteItemIndex: null,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : {}
        }
        this.tournamentApis = new TournamentApis();
    }
    componentDidMount() {
        this.props.showOrHideNav(true);
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
    }
    validateLabel = (e) => {
        var letters = /^[a-zA-Z ]*$/;
        let error = '';
        if (!e.match(letters)) {
            error = 'Only alphabet with spaces allowed'
        }
        return error
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
                fieldsArray: values.formConfig?.length ? values.formConfig : this.fieldsObject
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    toggleOpenCustomDetails = () => {
        if (this.state.tournamentDetails?.participantType === 'TEAM') {
            toast.error('You cannot add custom details in team participation mode.');
            return false;
        }
        this.setState(prevState => ({
            openAddDetails: !prevState.openAddDetails,
            details: null
        }))
    }
    confirmed = () => {
        const { fieldsArray } = this.state
        fieldsArray.splice(this.state.deleteItemIndex, 1);
        this.setState({ fieldsArray, openConfirm: false })
    };
    detailsDeleteMsg = (name) => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">
                Are you sure you want to delete {name} from custom fields ?
            </p>
        );
    };
    onClickOpenConfirm = (key, msg) => {
        const data = {
            msg: msg ? msg : "",
            key: key ? key : "",
        };
        this.setState({
            openConfirm: !this.state.openConfirm,
            confirm: data,
        });
    };
    updateFieldsArray = (e, i, value) => {
        e.preventDefault()
        const detailsArr = this.state.fieldsArray
        detailsArr[i].value = value
        this.setState({ fieldsArray: detailsArr })
    };
    onAppend = (values) => {
        let data = this.state.fieldsArray
        data.push(values)
        this.setState({ fieldsArray: data, openAddDetails: false })
    };
    onSubmit = () => {
        const data = {
            formConfig: this.state.fieldsArray
        }
        this.props.updateLoader(true);
        this.tournamentApis.updateTournaments(this.props.match.params.id, data).then(
            (res) => {
                this.props.dispatchData(res.data.result)
                this.props.updateLoader(false);
                toast.success("Registration form updated successfully");
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${this.props.match.params.id}/overview`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    render() {
        return (
            <>
                <section className="body-section tournament-registration-formd-flex flex-column">
                    <div className="container p-3 flex-grow-1 mt-lg-3">
                        <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={form} heading="Registration Form" subHeading={null} history={this.props.history} />

                        <div className='row mt-3 mt-lg-5 p-3'>
                            {this.state.fieldsArray.map((field, i) => {
                                return (
                                    (!field.isCustom) ?
                                        (<div className="col-lg-6 col-12 mt-2" key={i}>
                                            <label htmlFor='customField' className="fs-14 fw-600 text-white">{field.label}</label>
                                            <select
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={field.value}
                                                onChange={(e) => this.updateFieldsArray(e, i, e.target.value)}
                                            >
                                                <option value="PUBLIC">Public</option>
                                                <option value="PRIVATE">Private</option>
                                            </select>
                                        </div>)
                                        :
                                        null
                                )
                            })}
                        </div>

                        <div className="d-flex my-3 align-items-center px-3">
                            <hr className="border-1 flex-grow-1" />
                            <div className="d-flex mx-3 align-items-center">
                                <p className="fs-16 fw-bold text-white-50 mb-0">Add Custom Field</p>
                            </div>
                            <hr className="border-1 flex-grow-1" />
                        </div>
                        {/* Custom Fields */}
                        {this.state.fieldsArray.map((field, i) => {
                            return (
                                (field.isCustom) ?
                                    (<div className="my-2 px-3" key={i}>
                                        <div className='d-flex justify-content-between'>
                                            <label htmlFor='customField' className="fs-14 fw-600 text-white">{field.label}</label>
                                            <AiFillDelete onClick={() => {
                                                this.onClickOpenConfirm(null, this.detailsDeleteMsg(field.label));
                                                this.setState({ deleteItemIndex: i });
                                            }} />
                                        </div>
                                        <select
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            value={field.value}
                                            onChange={(e) => this.updateFieldsArray(e, i, e.target.value)}
                                        >
                                            <option value="PUBLIC">Public</option>
                                            <option value="PRIVATE">Private</option>
                                        </select>
                                    </div>)
                                    :
                                    null
                            )
                        })}

                        <div className='row px-3 mb-5'>
                            <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12'>
                                <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-3 " onClick={this.toggleOpenCustomDetails}>
                                    <button className="btn">
                                        <AiFillPlusCircle className="btn-size-25 text-white" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        {/* <NavLink to="/configureMenu">
                                <button className="border-0 bg-transparent cursor-pointer   " type="submit" onClick={this.onSubmit}>
                                    <img src={refresh} alt="" />
                                </button>
                            </NavLink> */}
                        <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45 cursor-pointer position-fixed" style={{ bottom: '60px' }} type="submit" onClick={this.onSubmit}>
                            <img src={refresh} alt="" className='btn-size-20' />
                        </button>
                    </div>
                </section>
                <Modal centered show={this.state.openAddDetails} dialogClassName="social-media-modal">
                    <Modal.Body>
                        <div className='Registration-form-field p-3'>
                            <AiFillCloseCircle className="close-icon position-absolute" style={{ right: '15px', }} onClick={this.toggleOpenCustomDetails} />
                            <Formik
                                initialValues={{
                                    label: "",
                                    value: 'PUBLIC',
                                    type: 'string',
                                    maxLength: '',
                                    isCustom: true
                                }}

                                validationSchema={Yup.object({
                                    label: Yup.string().required('Required'),
                                    value: Yup.string(),
                                    maxLength: Yup.number().required('Enter max characters allowed')
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.onAppend(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}

                            >
                                <Form className="mt-4" autoComplete='off'>
                                    <div className="my-2">
                                        <label htmlFor="name" className="fs-14 fw-600 text-white mb-2">Label</label>
                                        <Field name='label' className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            validate={this.validateLabel}
                                        />
                                        <ErrorMessage component="span" className="error-msg" name='label' />
                                    </div>
                                    <div className="my-2">
                                        <label htmlFor="name" className="fs-14 fw-600 text-white mb-2">Visibility</label>
                                        <Field name='value' as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" >
                                            <option value="PUBLIC">Public</option>
                                            <option value="PRIVATE">Private</option>
                                        </Field>
                                        <ErrorMessage component="span" className="error-msg" name='value' />
                                    </div>
                                    <div className="my-2">
                                        <label htmlFor="name" className="fs-14 fw-600 text-white mb-2">Type</label>
                                        <Field name='type' as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" >
                                            <option value="string">String</option>
                                            <option value="number">Number</option>
                                        </Field>
                                        <ErrorMessage component="span" className="error-msg" name='type' />
                                    </div>
                                    <div className="my-2">
                                        <label htmlFor="maxLength" className="fs-14 fw-600 text-white mb-2">Max length</label>
                                        <Field
                                            type='number' name='maxLength' placeholder='Enter max length allowed'
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                        />
                                        <ErrorMessage component="span" className="error-msg" name='maxLength' />
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <button type='submit' className='white-flat-btn d-block fw-bold py-1 px-4 m-auto mt-3 mb-2' > Add </button>
                                        {/* <button className='add-btn mx-1' onClick={this.toggleOpenCustomDetails}>Close</button> */}
                                    </div>
                                </Form>
                            </Formik>

                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.openConfirm}
                    centered
                    dialogClassName="Confirmation-modal"
                >
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={this.state.confirm}
                            onClickOpenConfirm={this.onClickOpenConfirm}
                            confirmed={this.confirmed}
                        />
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(RegistrationForm)