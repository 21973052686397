import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import CommonApis from '../../helper/common.api';
import { updateLoader } from '../../utils/common.utils';
import { toast } from 'react-toastify';


class RequestFeature extends Component {
    constructor(props) {
        super(props)
        this.state={
            email: props.authUser ? props.authUser?.user?.email :'',
            featureName: '',
            description:'',
            references: ''
        }
        this.commonApis = new CommonApis();
    }
    
    componentDidMount(){
        this.props.showOrHideNav(true);
    }

    resetState = () => { 
        this.setState({
            email:  this.props.authUser ? this.props.authUser?.user?.email :'',
            featureName: '',
            description: '',
            references: ''
        })
    }

    updateState = (key, value) => {
        this.setState({
            [key] : value
        })
    }

    formSubmit = (e) => { 
        e.preventDefault();
        const {email, featureName, description, references} = this.state;
        const data = {
            email: email.trim(), 
            featureName: featureName.trim(), 
            description: description.trim(), 
            references: references.trim(),
        }
        if(email.trim() === ''){
            toast.warning('email required');
            return false;
        }
        if(featureName.trim() === ''){
            toast.warning('Feature name required');
            return false;
        }
        if(description.trim() === ''){
            toast.warning('Description name required');
            return false;
        }
        updateLoader(true);
        this.commonApis.createFeatureRequest(data)
            .then((response) => {
                this.resetState()
                toast.success(response.data.message);
                updateLoader(false)
            })
            .catch((err) => {
                console.error(err)
                updateLoader(false)
            });
    }
 
    render() {
        return(
            <section className='body-section'>
                <div className='container d-flex flex-column align-items-center'>
                    <h2 className='text-center'>Feature request form</h2>
                    <div className='row w-100 p-3'>
                        <div className='col-12'>
                            <Form onSubmit={(e) => this.formSubmit(e)}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" 
                                        value={this.state.email} required 
                                        onChange={(e) => this.updateState('email', e.target.value)}
                                    />
                                    <Form.Text className="text-white">
                                    We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Feature Name*</Form.Label>
                                    <Form.Control type="text" placeholder="Enter feature name" 
                                        value={this.state.featureName} required maxLength={30}
                                        onChange={(e) => this.updateState('featureName', e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Feature Description*</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter feature description" 
                                        value={this.state.description} required maxLength={150}
                                        onChange={(e) => this.updateState('description', e.target.value)} 
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>References(if any)</Form.Label>
                                    <Form.Control type="text" placeholder="Enter references" 
                                        value={this.state.references} 
                                        onChange={(e) => this.updateState('references', e.target.value)} 
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default RequestFeature