import React,{ Component } from 'react';
import '../../../styles/den.css';
// Images
import awardIc from '../../../images/icon/den-award-ic.png';
import locationRight from '../../../images/icon/location-right.png';
// Icons
import {AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { getDenPostsForTimeline, getDenPostsLeaderBoard, getDenProfiles, getFeedPosts, getPostInteractionsDetails, getPublicFeedPosts, reportDenPost, updateDenProfileInLocalStorage } from '../../../utils/Den.utils';
import { toast } from 'react-toastify';
import LeftSideBar from './common/LeftSideBar';
import RightSideBar from './common/RightSideBar';
import { profile } from '../../../utils/localstorage.utils';
import ReportPost from './common/ReportPost';
import PostCard from './common/PostCard';
import WelcomToDenCard from './common/WelcomToDenCard';
import PostSomething from './common/PostSomething';
import LeftSideBarUnRegistered from './common/LeftSideBarUnRegistered';
import DenCommonTabs from './common/DenCommonTabs';
import PostActions from '../../popup/den/PostActions';
import DailySpinWheel from './common/DailySpinWheel';
import InfiniteScroll from "react-infinite-scroll-component";
import { GIF } from '../../../utils/Images.utils';
import PublicPostCard from './common/PublicPostCard';
import winnerCrown from "../../../images/icon/winner-crown.png";
import denStar1 from '../../../images/den/den-star-1.png';
import denStar2 from '../../../images/den/den-star-2.png';
import Image from '../../common/Image';
import { DEN } from '../../../utils/Strings.utils';
import { DEN_POST_REPORT_TYPES } from '../../../utils/enums.utils';
import UndoReportPost from '../../common/UndoReportPost';
import { getDetailsObj } from '../../../utils/common.utils';

export default class Den extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPostActionModal: false,
            openPostReportModal: false,
            selectedPostIndex: null,
            showWelcomePopup: true,
            feedDetails: [],
            showMoreNotificationIndex: null,
            selectedActionIndex: null,
            selectedReportType: null,
            selectedReportTypeDescription: null,
            activeTab: 'HOME',
            denProfile: null,
            isSparked:  false,
            postToInteract: null,
            openSpotlightForPostIndex: null,
            searchString: null,
            openMobileSubTabs: false,
            isSpinnedToday: false,
            pageNo:0,
            hasMorePosts: true,
            showLeaderboard: false,
            denLeaderBoardDetails: [],
            reportedDetails: null,
            detailsObj: null
        };
       //this.componentRef=[];
        getDenPostsLeaderBoard(this.setDenPostsLeaderBoard)
        
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        if(this.props.match.params.denId !== 'undefined') {
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile)
        }else{
            this.fetchPublicPosts()
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }

    //Den leaderboard details
    setDenPostsLeaderBoard = (result) => {
        this.setState({
            denLeaderBoardDetails: result
        });
    }
    //Set den profile data from API request
    setCurrentUserDenProfile = (result) =>{
        
        if(result[0]){
            this.setState({
                denProfile: result[0],
                isSparked: result[0].isSparked
            });
            this.fetchMorePosts();
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    updateDenPostsOnTimeline = (feedDetails) =>{
        this.setState({
            feedDetails: feedDetails
        });
    }

    updateState = (key,val)=>{
        this.setState({
            [key] : val
        });
    } 
    //Open action pop-up for posts
    showHidePostAction = (index, val1, val2) => {

        if(!val1 && !val2){
            this.setState({
                selectedActionIndex: null,
                selectedReportType: null,
                selectedReportTypeDescription: null,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }else{
            this.setState({
                selectedActionIndex: index,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }
    }


    toggleWelcomePopup = (val) => { 
        this.setState({showWelcomePopup: val})
    }

    onCheckReportType = (value) =>{
        this.setState({
            selectedReportType: ((this.state.selectedReportType === value)? null : value)
        });
    }

    onChangeReportDescription = (val) =>{
        this.setState({
            selectedReportTypeDescription: val
        })
    }

    onSubmitReport = () =>{
        reportDenPost(this.state.feedDetails[this.state.selectedActionIndex]._id, {type: this.state.selectedReportType, description:this.state.selectedReportTypeDescription }, this.showHidePostAction, this.enableUndoReport);
    }

    enableUndoReport = (report) => {
        this.setState({
            reportedDetails: report
        });
        setTimeout(() => {
            this.clearUndoReportPopup();
        }, 10000);
      
    }

    clearUndoReportPopup = () => {
        this.setState({
            reportedDetails: null
        });
    }

    updateProfileDetails = (updatedProfileDetails) =>{
        this.showHidePostAction(null, false, false);
        //updateDenProfileInLocalStorage(updatedProfileDetails);
        this.setState({
            denProfile : updatedProfileDetails
        });
    }

    updatePostDetails = (result,selectedActionIndex, message) =>{

        if(message){ toast.success(message) }
        const updatedPostDetails = this.state.feedDetails.map((post,i)=>{
            if(post._id === result._id){
                result.from = this.state.feedDetails[i].from;
                return result;
            }else{
                return post;
            }
        });

        this.setState({
            feedDetails: updatedPostDetails
        });
       this.showHidePostAction(null, false, false);
    }

    postToInteract = (index) =>{
        this.setState({
            postToInteract: index
        });
    }

    updateSearch = (e) => { 
        const searchTerm = e.target.value;
       
        if(searchTerm.trim().length > 0){
            this.props.history.push(`/den/${this.state.denProfile?._id}/search?param=${searchTerm}`)
        }
     
    }

    isSpinnedToday = (date1, date2) =>{
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        return (date1.toString() === date2.toString());
    }

    //Feed for registered den users
    fetchMorePosts = () =>{
        var searchString = `pageNo=${this.state.pageNo}&limit=12`;
        getFeedPosts(this.state.denProfile?._id, searchString,this.setFeedPosts)
    }
    //Feed for unregistered den users
    fetchPublicPosts = () =>{
        var searchString = `pageNo=${this.state.pageNo}&limit=12`;
        getPublicFeedPosts(searchString,this.setFeedPosts)
    }
    //get feed from API and add to feedFetails on scroll event
    setFeedPosts = (newPosts) => {
        if(newPosts.length > 0){
            this.setState({
                feedDetails :  this.state.feedDetails.concat(newPosts),
                pageNo :  this.state.pageNo+1
            });
        }else{
            this.setState({
                hasMorePosts: false
            })
        }
    }

    checkDenStatus = () => {
        if(!this.state.denProfile.isActive){
            toast.error('Please re-activate your den profile to perform any action');
            return false;
        }
        return true;
    }

    getDenFeed = () => {
        var isRoportingEnabled = (this.state.selectedReportType !== null); 
        const isSpinnedToday = (this.isSpinnedToday((new Date(this.state.denProfile?.lastSpin)),(new Date())) || this.state.isSpinnedToday)
      
        return(
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column' >

                    <div className='d-flex mb-2'>
                        <div className='purple-field d-flex align-items-center border-0 height-45 flex-grow-1'>
                            <input
                                type='search'
                                placeholder='search'
                                className='flex-grow-1 border-0 ps-3 text-white fs-14'
                                style={{background:'transparent', outline: 'none' }}
                                onChange={(e) => this.updateSearch(e)}
                                value={this.state.searchString}
                            />
                            <AiOutlineSearch className='btn-size-20 me-2' />
                        </div>
                        <div className='violet_blue-gradient height-45 float-end d-flex justify-content-center align-items-center d-block d-lg-none ms-2' style={{ width: '45px' }} onClick={() => this.updateState('showLeaderboard', true)}>
                            <img src={awardIc} className='btn-size-25' alt='' />
                        </div>
                    </div>

                    <PostSomething updateState={this.updateState} denProfile={this.state.denProfile} history={this.props.history} />

                    {/* Nav on users feed */}
                    {!this.state.denProfile &&
                        <NavLink to='/den-profile' className="gold_magenta-gradient border-0 d-flex justify-content-center align-items-center my-2">
                            <span className='fs-16 fw-500 py-2 px-3 text-white'>Complete profile to use Den</span>
                            <img src={locationRight} className='btn-size-15 ms-2' alt=''/>
                        </NavLink>
                    }

                    
                    {/* Welcome bonus card on feed */}
                    {!this.state.isSparked && <WelcomToDenCard updateState={this.updateState} denProfile={this.state.denProfile}/>}

                    {(!isSpinnedToday && this.state.denProfile?.isActive) && <DailySpinWheel updateState={this.updateState} denProfile={this.state.denProfile}/>}
                 
                    
                    {/* Posts on feed */}
                    <div className='row mb-3'>
                    <InfiniteScroll
                        dataLength={this.state.feedDetails.length}
                        next={this.fetchMorePosts}
                        hasMore={this.state.hasMorePosts}
                        loader={<div className='text-center'><img src={GIF.POST_LOAD} alt='' style={{ height: '75px', width: '-webkit-fill-available'}} /></div>}
                    >
                        {this.state.feedDetails.map((post, i) => {
                       
                            var likes = 0;
                            var likedUsers = [];    
                            Object.keys(post.likes).map((key,i)=>{ 
                                likes += post.likes[key].length 
                                likedUsers = likedUsers.concat(post.likes[key]);
                            });
                            
                    
                            const interactions = getPostInteractionsDetails(i, post, likedUsers, this.state.denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                            const isPrivatePost =   (!post.from[0]?.isPublic && !post.from[0]?.followers?.includes(this.state.denProfile?._id) && (post.from[0]._id !== this.state.denProfile?._id))
                               
                            return (
                                <div>
                                <PostCard 
                                    key={i} 
                                    i={i} 
                                    post={post} 
                                    isPrivatePost={isPrivatePost}
                                    denProfile={this.state.denProfile} 
                                    postType={null}
                                    interactions={interactions} 
                                    feedDetails={this.state.feedDetails} 
                                    selectedPostIndex={this.state.selectedPostIndex}
                                    selectedReportType={this.state.selectedReportType}
                                    showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                    postToInteract={this.state.postToInteract}
                                    showHidePostAction={this.showHidePostAction}
                                    updateProfileDetails={this.updateProfileDetails}
                                    updatePostDetails={this.updatePostDetails}
                                    history={this.props.history}
                                    isLiveStraming={false}
                                    checkDenStatus={this.checkDenStatus}
                                />
                                {(this.state.reportedDetails && this.state.reportedDetails?.postId  === post._id) && <UndoReportPost updateState={this.updateState} reportedDetails={this.state.reportedDetails}  clearUndoReportPopup={this.clearUndoReportPopup} />}
                                </div>
                            )
                            })
                        }
                    </InfiniteScroll>
                    </div>
                </div>

                <DenCommonTabs show={this.state.openMobileSubTabs} updateState={this.updateState} denProfile={this.state.denProfile} history={this.props.history} />
                
                {/* Post Action Div */}
                <PostActions 
                    postType={null}
                    show={this.state.openPostActionModal} 
                    denProfile={this.state.denProfile}
                    feedDetails={this.state.feedDetails}
                    updatePostDetails={this.updatePostDetails}
                    updateProfileDetails={this.updateProfileDetails}
                    selectedActionIndex={this.state.selectedActionIndex}
                    showHidePostAction={this.showHidePostAction}
                    onClose={() => this.showHidePostAction(null, false, false)}  
                />

                {/* Report Post Details */}
                <ReportPost 
                    onSubmitReport={this.onSubmitReport} 
                    showHidePostAction={this.showHidePostAction} 
                    onChangeReportDescription={this.onChangeReportDescription} 
                    onCheckReportType={this.onCheckReportType} 
                    isRoportingEnabled={isRoportingEnabled} 
                    openPostReportModal={this.state.openPostReportModal} 
                    selectedReportType={this.state.selectedReportType} 
                    selectedReportTypeDescription={this.state.selectedReportTypeDescription} 
                />
            </div>
        )
    }

    nonDenUserFeed = () => { 
        return(
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    {/* Nav on users feed */}
                    <NavLink to='/den-profile' className="gold_magenta-gradient border-0 d-flex justify-content-center align-items-center my-2">
                        <span className='fs-16 fw-500 py-2 px-3 text-white'>Complete profile to use Den</span>
                        <img src={locationRight} className='btn-size-15 ms-2' alt=''/>
                    </NavLink>

                    
                    {/*Public posts on feed */}
                    <div className='row my-3'>
                    <InfiniteScroll
                        dataLength={this.state.feedDetails.length}
                        next={this.fetchPublicPosts}
                        hasMore={this.state.hasMorePosts}
                        loader={<div className='text-center'><img src={GIF.POST_LOAD} alt='' style={{ height: '75px', width: '-webkit-fill-available'}} /></div>}
                    >
                        {this.state.feedDetails.map((post, i) => {
                       
                            var likes = 0;
                            var likedUsers = [];    
                            Object.keys(post.likes).map((key,i)=>{ 
                                likes += post.likes[key].length 
                                likedUsers = likedUsers.concat(post.likes[key]);
                            });
                            
                    
                            const interactions = getPostInteractionsDetails(i, post, likedUsers, this.state.denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                            const isPrivatePost =   (!post.from[0]?.isPublic && !post.from[0]?.followers?.includes(this.state.denProfile?._id))
                               
                            return (
                                <PublicPostCard 
                                    key={i} 
                                    i={i} 
                                    post={post} 
                                    isPrivatePost={isPrivatePost}
                                    denProfile={this.state.denProfile} 
                                    postType={null}
                                    interactions={interactions} 
                                    feedDetails={this.state.feedDetails} 
                                    selectedPostIndex={this.state.selectedPostIndex}
                                    selectedReportType={this.state.selectedReportType}
                                    showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                    postToInteract={this.state.postToInteract}
                                    showHidePostAction={this.showHidePostAction}
                                    updateProfileDetails={this.updateProfileDetails}
                                    updatePostDetails={this.updatePostDetails}
                                    history={this.props.history}
                                />
                            )
                            })
                        }
                    </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }

    getLeaderBoardStats = () => { 
        const {denLeaderBoardDetails} = this.state;

        return (
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column'>
                    <div className='den-leaderboard-top p-2'>
                        <div className='d-flex justify-content-between'>
                            <p className='fs-24 fw-bold text-white'>Den's Leaderboard</p>
                            <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.updateState('showLeaderboard', false)} />
                        </div>
                        
                        <div className='row'>
                            <div className='col-12'>
                                <div className='d-flex justify-content-center'>
                                    <p className='fw-bold me-2' style={{fontSize: '40px'}}>1</p>
                                    <div className='d-flex flex-column align-items-center'>
                                        <img src={winnerCrown} className="" alt='' style={{ height: '20px' }} />
                                        <Image src={denLeaderBoardDetails[0]?.type === DEN.SEARCH.TYPES.USER ? denLeaderBoardDetails[0]?.profilePicture?.url : denLeaderBoardDetails[0]?.logo?.url} alt='' param={denLeaderBoardDetails[0]?.name} className='rounded-circle mt-2' style={{height:'80px', width: '80px'}} />
                                        {denLeaderBoardDetails[0]?.name?.length > 20 ? <marquee className='marquee-text fs-20 fw-500 my-1 text-white' direction="left">{denLeaderBoardDetails[0]?.name}</marquee> :<p className='fs-20 fw-500 my-1 text-white'>{denLeaderBoardDetails[0]?.name}</p>}
                                        <p className='fs-20 fw-500 my-1 text-white'><img src={denStar1} alt='' style={{ height:'50px'}}/> {denLeaderBoardDetails[0]?.denStar}</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    <p className='fw-bold me-2' style={{fontSize: '25px'}}>2</p>
                                    <div className='d-flex flex-column align-items-center'>
                                        <Image src={denLeaderBoardDetails[1]?.type === DEN.SEARCH.TYPES.USER ? denLeaderBoardDetails[1]?.profilePicture?.url : denLeaderBoardDetails[1]?.logo?.url} alt='' param={denLeaderBoardDetails[1]?.name} className='rounded-circle mt-2' style={{height:'50px', width: '50px'}} /> 
                                        {denLeaderBoardDetails[1]?.name.length > 15 ? <marquee className='marquee-text fs-16 fw-500 my-1 text-white' direction="left">{denLeaderBoardDetails[1]?.name}</marquee> : <p className='fs-16 fw-500 my-1 text-white'>{denLeaderBoardDetails[1]?.name}</p>}
                                        <p className='fs-16 fw-500 my-1 text-white'><img src={denStar1} alt='' style={{ height:'25px'}} /> {denLeaderBoardDetails[1]?.denStar}</p>   
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='d-flex'>
                                    <p className='fw-bold me-2' style={{fontSize: '25px'}}>3</p>
                                    <div className='d-flex flex-column align-items-center'>
                                        <Image src={denLeaderBoardDetails[2]?.type === DEN.SEARCH.TYPES.USER ? denLeaderBoardDetails[2]?.profilePicture?.url : denLeaderBoardDetails[2]?.logo?.url} param={denLeaderBoardDetails[2]?.name} className='rounded-circle mt-2' style={{height:'50px', width: '50px'}} /> 
                                        {denLeaderBoardDetails[2]?.name.length > 15 ? <marquee className='marquee-text fs-16 fw-500 my-1 text-white' direction="left">{denLeaderBoardDetails[2]?.name}</marquee> : <p className='fs-16 fw-500 my-1 text-white'>{denLeaderBoardDetails[2]?.name}</p>}
                                        <p className='fs-16 fw-500 my-1 text-white'><img src={denStar1} alt='' style={{ height:'25px'}} /> {denLeaderBoardDetails[2]?.denStar}</p>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='den-leaderboard-bottom'>
                        {denLeaderBoardDetails.map((profile, i) => {
                            const profilePicture = profile.type === DEN.SEARCH.TYPES.USER ? profile.profilePicture : profile.logo;
                            if(i > 2){
                                return (
                                    <div className='container d-flex align-items-center py-3' key={i} style={{borderTop: '1px solid rgba(173, 38, 255, 0.5)'}}>
                                        <p className='den-leaderboard-bottom-text my-1'>{i+1}</p>
                                        <Image src={profilePicture?.url} param={profile?.name} alt='' className='rounded-circle mx-2' style={{height:'32px', width: '32px'}} /> 
                                        {profile.name.length > 15 ? <marquee className='marquee-text den-leaderboard-bottom-text my-1 flex-grow-1' direction="left">{profile?.name}</marquee> : <p className='den-leaderboard-bottom-text my-1 flex-grow-1'>{profile?.name}</p>}
                                        <p className='den-leaderboard-score my-1'><img src={denStar2} alt='' style={{ height:'25px'}} /> {profile.denStar}</p> 
                                    </div>
                                )
                            }
                        })}
                        
                    </div>
                </div>
            </div>
        )
    }

    renderPresentView = () => { 
        if(this.state.showLeaderboard){
            return this.getLeaderBoardStats();
        }else{
            if(this.state.denProfile){
                return this.getDenFeed()
            }else{
                return this.nonDenUserFeed()
            }
        }
    }

    render() {
        return (
            <div className='body-section den_page' style={{height: 'auto'}}>
                <div className='container p-3'>
                    <div className='row'>
                        {this.state.denProfile ?
                            <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                            :
                            <LeftSideBarUnRegistered  profile={profile()} /> // UnAuth User Den Side Panel
                        }
                        {/* {this.state.denProfile ? this.getDenFeed() : this.nonDenUserFeed()} */}
                        {this.renderPresentView()}
                        <RightSideBar denId={this.props.match.params.denId} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                    </div>
                </div>
            </div>
        );
    }
    
}