import React, { Component } from 'react';
import { Tabs, Tab, Nav, NavDropdown } from 'react-bootstrap';
//styles, images
import '../../../styles/TournamentPage.css';

//components
import TournamentApis from '../../../helper/tournament.api';
import { getTournamentData, saveGetBackToMatches } from '../../../utils/common.utils';
import Streams from './Streams';
import TournamentHeader from '../common/TournamentHeader';
import TournamentFooter from '../common/TournamentFooter';
import Participants from './Participant';
import TournamentRules from './TournamentRules';
import TournamentRatings from './TournamentRatings';
import PageInformation from './PageInformation';
import LatestResult from './LatestResult';
import StageMatches from './StageMatches';
import UpcomingMatchesFFA from './matches/ffa/UpcomingMatchesFFA';
import BettingZone from './BettingZone';
import FinalStanding from './FinalStandings/FinalStanding';
import PointsMatrix from './PointsMatrix';
import PrizePoolDistribution from './PrizePoolDistribution';
import Disputes from './Disputes';



export default class TournamentHome extends Component {

  constructor(props) {
    super(props)
    this.TournamentApis = new TournamentApis();

    const query = new URLSearchParams(this.props.location.search);
    this.query = query;
    const page = query.get('page');

    this.state = {
      tournamentDetails: null,
      activeTab: page ? page : 'Matches',//'Information',
      stageIndex: 0
    }
    getTournamentData(this.props.match.params.id, this.setData);

    this.url = new URL(window.location);
    this.url.searchParams.delete('page')
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    saveGetBackToMatches(this.getBackToMatches);
    //this.getData(this.props.location.state?.orgId)
  }

  // when data not came from location.state it returns data from API
  setData = (values) => {
    if (values) {
      this.setState({
        tournamentDetails: values
      })
    } else {
      this.props.history.push('/pageNotFound')
    }
  }

  getBackToMatches = (tab, index) => {
    this.setState({
      activeTab: tab,
      stageIndex: index
    });
  }

  Sonnet = (text) => {
    return <h1 className='text-white'>{text}</h1>
  }

  activeTab = (e, selectedTab, stageIndex) => {
    e.preventDefault();
    //if(stageIndex !== this.state.stageIndex){
    this.setState({
      activeTab: selectedTab,
      stageIndex: stageIndex ? stageIndex : 0
    });
    //}

  }

  registerForTournament = null;

  setGetAlertMethod = (newMethod) => {
    this.registerForTournament = newMethod;
  }

  setRoutParam = (value) => {
    this.url.searchParams.set('page', value);
    window.history.replaceState(null, '', this.url.toString());
  }

  renderSwitch = () => {
    switch (this.state.activeTab) {
      case 'Information':
        this.setRoutParam('Information');
        return <PageInformation activeTab={this.activeTab} tournamentDetails={this.state.tournamentDetails} />
      case 'Matches':
        this.setRoutParam('Matches');
        return <StageMatches setGetAlertMethod={this.setGetAlertMethod} history={this.props.history} stageIndex={this.state.stageIndex} tournamentId={this.props.match.params.id} stageDetails={this.state.tournamentDetails.stages[this.state.stageIndex]} updateLoader={this.props.updateLoader} tournamentDetails={this.state.tournamentDetails} />
      case 'Result':
        return <LatestResult tournamentId={this.props.match.params.id} history={this.props.history} updateLoader={this.props.updateLoader} tournamentDetails={this.state.tournamentDetails} />
      case 'Upcoming':
        this.setRoutParam('Upcoming');
        return <UpcomingMatchesFFA tournamentId={this.props.match.params.id} tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} />
      case 'BettingZone':
        this.setRoutParam('BettingZone');
        return <BettingZone query={this.query} tournamentId={this.props.match.params.id} tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} />
      case 'Participants':
        this.setRoutParam('Participants');
        return <Participants tournamentId={this.props.match.params.id} tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} />
      case 'Watch':
        this.setRoutParam('Watch');
        return <Streams tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} />
      case 'Rules':
        this.setRoutParam('Rules');
        return <TournamentRules tournamentDetails={this.state.tournamentDetails} />
      case 'FinalStanding':
        this.setRoutParam('FinalStanding');
        return <FinalStanding tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} TournamentApis={this.TournamentApis} />
      case 'PointsMatrix':
        this.setRoutParam('PointsMatrix');
        return <PointsMatrix tournamentDetails={this.state.tournamentDetails} />
      case 'PrizePoolDistribution':
        this.setRoutParam('PrizePoolDistribution');
        return <PrizePoolDistribution tournamentDetails={this.state.tournamentDetails} />
      case 'Disputes':
        this.setRoutParam('Disputes');
        return <Disputes tournamentDetails={this.state.tournamentDetails} />
      default:
        this.setRoutParam('Rating');
        return <TournamentRatings tournamentId={this.props.match.params.id} updateLoader={this.props.updateLoader} TournamentApis={this.TournamentApis} tournamentDetails={this.state.tournamentDetails} />
    }

  }

  getTabsContent = () => {
    const tournamentDetails = this.state.tournamentDetails
    if (this.state.tournamentDetails !== null) {
      return (
        <section className='tournament-page-home tab-cells'>

          <div className='container overflow-auto tournament-page-body'>

            <Nav variant="pills bg-transparent" className='m-auto' style={{ width: 'max-content' }} >
              <Nav.Item onClick={(e) => this.activeTab(e, 'Information')}>
                <Nav.Link eventKey="1" title="Information" className={`fs-14 fw-600 ${this.state.activeTab === 'Information' ? 'active' : null} py-0`}>Information</Nav.Link>
              </Nav.Item>
              <NavDropdown title="Matches" id="nav-dropdown" className='fs-14 fw-600 py-0' style={{ borderBottom: `${['Matches', 'Result', 'Upcoming', 'FinalStanding', 'PointsMatrix', 'PrizePoolDistribution'].includes(this.state.activeTab) ? '2px solid #FFB800' : 'none'}` }}>
                {this.state?.tournamentDetails?.stages?.map((stage, i) => {
                  return <NavDropdown.Item key={i} eventKey={`2.${stage.level}`} className='fs-12 fw-500 text-white' onClick={(e) => this.activeTab(e, 'Matches', i)}>Stage {i + 1}</NavDropdown.Item>
                })}
                <NavDropdown.Item eventKey="LR" className='fs-12 fw-500 text-white' onClick={(e) => this.activeTab(e, 'Result')}>Latest Results</NavDropdown.Item>
                <NavDropdown.Item eventKey="UM" className='fs-12 fw-500 text-white' onClick={(e) => this.activeTab(e, 'Upcoming')}>Upcoming Matches</NavDropdown.Item>
                <NavDropdown.Item eventKey="FinalStanding" className='fs-12 fw-500 text-white' onClick={(e) => this.activeTab(e, 'FinalStanding')}>Final Standing</NavDropdown.Item>
                <NavDropdown.Item eventKey="PointsMatrix" className='fs-12 fw-500 text-white' onClick={(e) => this.activeTab(e, 'PointsMatrix')} style={{ display: tournamentDetails.competitionType === 'FFA' ? 'block' : 'none' }}>Points Matrix</NavDropdown.Item>
                <NavDropdown.Item eventKey="PrizePoolDistribution" className='fs-12 fw-500 text-white' onClick={(e) => this.activeTab(e, 'PrizePoolDistribution')}>Prize Pool Distribution</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item onClick={(e) => this.activeTab(e, 'BettingZone')}>
                <Nav.Link eventKey="3" title="BettingZone" className={`fs-14 fw-600 ${this.state.activeTab === 'BettingZone' ? 'active' : null} py-0`}>Staking Zone</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={(e) => this.activeTab(e, 'Participants')}>
                <Nav.Link eventKey="4" title="Participants" className={`fs-14 fw-600 ${this.state.activeTab === 'Participants' ? 'active' : null} py-0`}>Participants</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={(e) => this.activeTab(e, 'Watch')}>
                <Nav.Link eventKey="5" title="Watch" className={`fs-14 fw-600 ${this.state.activeTab === 'Watch' ? 'active' : null} py-0`}>Watch</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={(e) => this.activeTab(e, 'Rules')}>
                <Nav.Link eventKey="6" title="Rules" className={`fs-14 fw-600 ${this.state.activeTab === 'Rules' ? 'active' : null} py-0`}>Rules</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={(e) => this.activeTab(e, 'Ratings')}>
                <Nav.Link eventKey="7" title="Ratings" className={`fs-14 fw-600 ${this.state.activeTab === 'Ratings' ? 'active' : null} py-0`}>Ratings</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={(e) => this.activeTab(e, 'Disputes')}>
                <Nav.Link eventKey="8" title="Disputes" className={`fs-14 fw-600 ${this.state.activeTab === 'Disputes' ? 'active' : null} py-0`}>Disputes</Nav.Link>
              </Nav.Item>

            </Nav>
          </div>
          <div className='container px-0 py-4'>
            {this.renderSwitch()}
          </div>
        </section>
      )
    } else {
      return (
        <div>
          <h1>No Data Found</h1>
        </div>
      )
    }

  }

  render() {

    return (
      <>
        <section className='body-section' style={{ backgroundColor: '#1c1c1c' }}>
          <TournamentHeader registerForTournament={this.registerForTournament} tournamentDetails={this.state.tournamentDetails} />
          {this.getTabsContent()}
          <TournamentFooter tournamentDetails={this.state.tournamentDetails} />
        </section>
      </>
    )
  }
}
