import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { AiFillCloseCircle, AiFillPlusCircle, AiOutlineCaretDown, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineArrowBack } from 'react-icons/md';
import { isValidString } from '../../../../../utils/common.utils';
import { CREATE_POST_VIEWS } from '../../../../../utils/enums.utils';
import { ACTION, POST_INTERACTIONS } from '../../../../../utils/Images.utils';
import { POST_FEELINGS } from '../../../../../utils/Strings.utils';


export default class PostFeelingSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFeeling: null,
            selectedEmoji: 'HAPPY'
         };
    }

    updateState = (key, val) => {
        this.setState({
            [key] : val
        });
    }


 
    render() {
        var feeling;
        if(this.state.selectedFeeling === POST_FEELINGS[0]){
            feeling =  this.state.selectedEmoji 
        }else{
            feeling = `is ${this.state.selectedFeeling}`;
        }
        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{ overflow: 'auto'}}>
                    <div className='post-card-bg p-2 flex-grow-1'>
                        <div className='row px-2'>
                            <div className='col-2'>
                                <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)} />
                            </div>
                            <div className='col-8'>
                                <p className='fs-16 text-center fw-700 my-0'>How are you feeling?</p>
                            </div>
                            <div className='col-2'>
                               <p className={`cursor-pointer fs-16 fw-600 my-0 mx-1 ${this.state.selectedFeeling ? '' : 'opacity-50'}`} style={{ color:'#AD26FF'}} onClick={() => this.state.selectedFeeling ? this.props.backToMainView('currentView',CREATE_POST_VIEWS.CREATE_POST, 'feeling',feeling) : ()=>{}}>Done</p>
                            </div>
                        </div>
                        <hr className='hr-line' />
                        {isValidString(this.state.selectedFeeling) ?
                            <div>
                                <div className='d-flex align-items-center px-2'>
                                    {this.state.selectedFeeling && <p className='fs-14 fw-bold text-white my-0 flex-grow-1'>{POST_INTERACTIONS.FEELINGS[feeling]? `is feeling ${POST_INTERACTIONS.FEELINGS[feeling].NAME}` : feeling}  {POST_INTERACTIONS.FEELINGS[feeling] && <img src={POST_INTERACTIONS.FEELINGS[feeling]?.LOGO} alt='' style={{height:'18px'}} />}</p>}
                                    <AiOutlineClose className='cursor-pointer' onClick={() => this.updateState('selectedFeeling',null)} />
                                </div>
                                <hr className='hr-line' />
                            </div>
                            :
                            <div>
                            <div className='d-flex align-items-center px-2'>
                               <h1 className='fs-16 text-center fw-700 my-0'>Select a feeling from the list... </h1>
                            </div>
                            <hr className='hr-line' />
                        </div>
                        }
                        {POST_FEELINGS.map((el, i) => {
                            return (
                            <div key={i}>
                                <div className='d-flex align-items-center px-2'>
                                    <p className='fs-14 fw-600 text-white-50 my-0 flex-grow-1'>{el}</p>
                                    {this.state.selectedFeeling === el ? 
                                        <img src={ACTION.SELECTED} alt='' className='cursor-pointer' style={{ height:'20px'}} />
                                        :
                                        <img src={ACTION.UNSELECTED} alt='' className='cursor-pointer' style={{ height:'20px'}} onClick={() => this.updateState('selectedFeeling', el)} />
                                    }
                                </div>
                                <hr className='hr-line' />
                            </div>
                            )
                        })}
                    </div>

                    <div className='post-card-bg p-2 my-1' style={{ display: this.state.selectedFeeling === POST_FEELINGS[0] ? 'block' : 'none' }}>
                        <div className='row'>
                            {Object.keys(POST_INTERACTIONS.FEELINGS).map((key,i) => {
                                return (
                                    <div className='col-4 col-md-2 ' key={i}>
                                        <div className={`emoji-container cursor-pointer ${this.state.selectedEmoji === key ? 'selected-emoji' : ''} p-1 mb-1`} onClick={() => this.updateState('selectedEmoji',key)}>
                                            <img src={POST_INTERACTIONS.FEELINGS[key].LOGO} alt='' style={{height:'36px'}} />
                                            <p className='fs-14 fw-500 text-white-50 my-1'>{POST_INTERACTIONS.FEELINGS[key].NAME}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
