import React, { Component } from 'react';
import TournamentApis from '../../../helper/tournament.api';
import { ellipsis, errorHandler, updateLoader } from '../../../utils/common.utils';
import { Formik, Field, Form, } from "formik";
import * as Yup from "yup";
//component
import MicroWebSiteNav from '../configuration/MicroWebSiteNav'
import { IoMdArrowDropdown } from "react-icons/io";
import { RiFacebookFill, RiWhatsappFill } from "react-icons/ri";
import { AiFillInstagram, AiFillStar, AiOutlineSearch } from "react-icons/ai";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { Carousel, ProgressBar } from 'react-bootstrap';
import { getMonthAndDate } from '../../../utils/common.utils';
//styles
import '../../../styles/Layout.css'
import premium from '../../../images/premium-card.png';
import regular from '../../../images/regular.png';
import gameCover from '../../../images/League_of_legends.png';
import betBadge from '../../../images/common/bet_badge.png';
import MicroWebSiteContactUs from '../configuration/MicroWebSiteContactUs';
import TeamApis from '../../../helper/teams.api';
import { regions } from '../../../utils/common.utils';
import { NavLink } from 'react-router-dom';
import { ASSETS } from '../../../utils/Strings.utils';
import { getAllGamesDetails } from '../../../utils/Games.utils';
import {ParticipantType, ListingType, TournamentVisibility, PrizePoolType} from '../../../utils/enums.utils'

class MicroWebSiteTournaments extends Component {
  TournamentApis = new TournamentApis();
  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData: props.microWebSiteData,
      allGames: [],
      regions: regions,
      backgroundColors: ["#145DA0", "#05445E", "#2F5233", "#620ff0", "#050A30", "#000"],
      textColors: ["#2E8BC0", "#189AB4", "#76B947", "#9a65f6", "#055C9D", "#2c2c2c"],
      secondaryTextColors: ["#B1D4E0", "#D4F1F4", "#B1D8B7", "#cfb5fa", "#68BBE3", "#404040"],
      background: (props.microWebSiteData ? props.microWebSiteData.color.background : 4),
      primary: (props.microWebSiteData ? props.microWebSiteData.color.primary : 4),
      secondary: (props.microWebSiteData ? props.microWebSiteData.color.secondary : 4),
      openNavbar: false,
      tournamentsArray: [],
      showFilters: false,
      searchInput: ''
    };
    getAllGamesDetails(this.setAllGamesDetails)
    this.apiPayload = {};
    this.teamApis = new TeamApis();
    this.offSet = 30;
    this.pageNo = 0
  }

  componentDidMount() {
    this.getData(this.apiPayload)
    this.props.showOrHideNav(true);
  }

  setAllGamesDetails = (response) => {
    this.setState({allGames : response});
  }

  getData = (params) => {
    // if(val.length){
    //   var data = {
    //     organizationId: id,
    //     searchTerm: 'name',
    //     searchValue: val
    //   }
    // }else{
    //   var data = {
    //     organizationId: id
    //   }
    // }
    params['visibility'] = 'PUBLIC';
    params['organizationId'] = this.state.microWebSiteData.organizationId;
    
    updateLoader(true);
    this.TournamentApis.getPublicTournaments(params).then(
      (res) => {
        var data = res.data['result']
        this.setState({ tournamentsArray: data })
        updateLoader(false);
      }
    ).catch(
      (err) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  toggleOpenNavbar = () => {
    this.setState({ openNavbar: !this.state.openNavbar })
  }

  clearParams = (isRedirect) => {
        
    if(isRedirect){
        this.props.history.push(`/tournament/${this.state.param.joinTournamentId}`);
    }
    this.setState({
        param: null
    });
  }

  toggleFilterStatus = () => {
    this.setState({ showFilters: !this.state.showFilters })
  }

  getSponsors = sponsors => {
    if (sponsors.length === 0) {
        return <h1 className="text-white fs-10 text-center">No Sponsors</h1>
    }
    return (
        <section className='sponsor-names-carousel'>
            <Carousel>
                {sponsors.map((sponsor, i) => {
                    return (
                        <Carousel.Item key={i}>
                            <h1 className="text-white fs-10 text-center">{sponsor.type}: {sponsor.name}</h1>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </section >
    )
  }

  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    // const params = {
    //     pageNo: pageNumber - 1,
    //     limit: this.offSet
    // }
    this.apiPayload.pageNo =  pageNumber - 1;
    this.apiPayload.limit =  this.offSet;
    this.getData(this.apiPayload);
    this.setState({ activePage: pageNumber });
  }

  passDetails = (values) => {
    this.setState({ showFilters: false })
    Object.keys(values).map((key, i) => {
        if (values[key] === '') {
            delete values[key];
        }
    });
    var data = { ...values };
    if (data['listingType']) {
        data = { ...data, 'entryFeeAndPrizeDetails.listingType': data['listingType'] };
        delete data['listingType'];
    }
    if (data['prizePoolType']) {
        data = { ...data, 'entryFeeAndPrizeDetails.prizePoolType': data['prizePoolType'] };
        delete data['prizePoolType'];
    }
    if (data['entryFee']) {
        data = { ...data, 'entryFeeAndPrizeDetails.entryFee': data['entryFee'] };
        delete data['entryFee'];
    }
    if (this.state.searchInput?.length) {
        data = { ...data, searchTerm: 'name', searchValue: this.state.searchInput };
    }
    for(let key in data){
        this.apiPayload[key] = data[key]
    }
    // this.apiPayload = {...data}
    //data = {...data, pageNo: this.state.activePage - 1, limit: this.offSet}
    //console.log('data::', data);
    this.getData(this.apiPayload);
  }

  clearFilters = () => {
    const params = {
        pageNo: this.pageNo,
        limit: this.offSet
    }
    this.getData(params);
    this.setState({ showFilters: false })
  }

  searchFilter = (e) => {
    // let data = {...this.apiPayload}
    this.apiPayload.pageNo =  this.pageNo;
    this.apiPayload.limit =  this.offSet;
    if (e.target.value?.length) {
        this.apiPayload.searchTerm = 'name'
        this.apiPayload.searchValue = e.target.value
        // data = { searchTerm: 'name', searchValue: e.target.value }
    }else{
        delete this.apiPayload.searchTerm
        delete this.apiPayload.searchValue
    }
    // this.apiPayload = {...data}
    this.getData(this.apiPayload);
  }


  render() {

    return (
      <div>
        <MicroWebSiteNav path={this.props.path} redirectTo={this.props.redirectTo} state={this.state} microWebSiteData={this.state.microWebSiteData} toggleOpenNavbar={this.toggleOpenNavbar} />
        {this.state.microWebSiteData ?
          <section className="layout" style={{ display: (this.state.openNavbar) ? 'none' : 'block', backgroundColor: `${this.state.background}`, paddingBottom: '60px', paddingTop: '1rem', paddingRight: '1rem', paddingLeft: '1rem',height:'95vh'}}>
            <div className='container mt-8'>

              <h1 className="text-white fs-24 text-center fw-600 mt-1">Browse Tournaments</h1>
              <div className="row my-3">
                <div className="col-7">
                  <input 
                    placeholder="Search" className="w-100 height-45 bg-black ps-3 fs-14 text-white rounded-3" 
                    style={{ color: '#ffb800', border: '1px solid #ffb800' }}
                    onChange={e => {
                      this.setState({ searchInput: e.target.value })
                      this.searchFilter(e)
                    }}
                  />
                </div>
                <div className="col-5 d-flex">
                  <div className="w-100 height-45 bg-black ps-3 fs-14 text-white rounded-3 d-flex flex-row align-items-center justify-content-between" style={{ color: '#ffb800', border: '1px solid #ffb800' }} onClick={this.toggleFilterStatus} >
                    <h6 className="my-auto">Filter</h6>
                    <IoMdArrowDropdown />
                  </div>
                </div>
              </div>
              <div className='p-2' style={{ backgroundColor: '#272b1c', display: (this.state.showFilters) ? 'block' : 'none' }}>
                <Formik
                  enableReinitialize
                  initialValues={{
                      gameId: '',
                      platform: '',
                      betting: '',
                      listingType: '',
                      participantType: '',
                      region: '',
                      entryFee: '',
                      prizePool: '',
                      tournamentStatus: ''
                  }}
                  validationSchema={Yup.object({
                      gameId: Yup.string(),
                      platform: Yup.string(),
                      betting: Yup.string(),
                      listingType: Yup.string(),
                      participantType: Yup.string(),
                      region: Yup.string(),
                      entryFee: Yup.string(),
                      prizePool: Yup.string()
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                          this.passDetails(values)
                          setSubmitting(false);
                      }, 400);
                  }}>
                  <Form>
                      <div className='row p-2'>
                          <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                              <Field name="gameId" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">All Game</option>
                                  {this.state.allGames?.map((game, i) => {
                                      return <option value={game._id} key={i}>{game.name}</option>
                                  })}
                              </Field>
                          </div>
                          <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                              <Field name="platform" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Platform</option>
                                  <option value="PC">PC</option>
                                  <option value="MOBILE">Mobile</option>
                                  <option value="Nintendo">Nintendo</option>
                                  <option value="PS">PS</option>
                                  <option value="XBOX">X-box</option>
                              </Field>
                          </div>
                          <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                              <Field name="betting" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Staking</option>
                              </Field>
                          </div>
                          <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                              <Field name="region" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Region</option>
                                  {regions?.map((region, i) => {
                                      return <option key={i} value={region}>{region}</option>
                                  })}
                              </Field>
                          </div>
                          <div className='col-lg-3 col-md-4 col-12 pe-1 my-1 px-1'>
                              <Field name="listingType" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Listing Type</option>
                                  <option value="REGULAR">Regular</option>
                                  <option value="PREMIUM">Premium</option>
                              </Field>
                          </div>
                          <div className='col-lg-3 col-md-4 col-12 ps-1 my-1 px-1'>
                              <Field name="participantType" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Participant Type</option>
                                  <option value="SINGLE">Single</option>
                                  <option value='TEAM'>Team</option>
                              </Field>
                          </div>

                          <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                              <Field name="entryFee" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Entry</option>
                                  <option value={false}>Free</option>
                                  <option value={true}>Paid</option>
                              </Field>
                          </div>
                          <div className='col-lg-3 col-md-4 col-6 my-1 px-1'>
                              <Field name="prizePoolType" as="select" className="fs-14 rounded-3 ps-3 text-white w-100 bg-black height-45">
                                  <option value="">Prize Pool</option>
                                  <option value="FIXED">Fixed</option>
                                  <option value="CROWDFUNDED">Crowd Funded</option>
                              </Field>
                          </div>
                      </div>
                      <div role="group" className='d-flex justify-content-around text-white'>
                          <label className='fs-14 fw-500'>
                              <Field type="radio" name="tournamentStatus" value="UPCOMING" className='me-1' />
                              Open
                          </label>
                          <label className='fs-14 fw-500'>
                              <Field type="radio" name="tournamentStatus" value="COMPLETED" className='me-1' />
                              Past
                          </label>
                          <label className='fs-14 fw-500'>
                              <Field type="radio" name="tournamentStatus" value="ONGOING" className='me-1' />
                              Live
                          </label>
                          <label className='fs-14 fw-500'>
                          <Field type="radio" name="tournamentStatus" value="DRAFT" className='me-1' />
                            Draft
                      </label>
                      </div>
                      <div className='d-flex justify-content-center w-100 my-2'>
                          <button className='mx-auto bg-black text-warning border-0 rounded-pill px-3 py-1' type='submit'>Apply</button>
                          <button className='mx-auto bg-black text-warning border-0 rounded-pill px-3 py-1' type='reset' onClick={this.clearFilters}>Clear</button>
                      </div>
                  </Form>
                </Formik>
              </div>

              <div className="row my-2">
                {/* Tournaments */}
                {this.state.tournamentsArray.map((tournament, id) => {
                  const { baner, participantType, tournamentEndTime, tournamentStartTime, maxParticipant, entryFeeAndPrizeDetails } = tournament
                  const startDate = getMonthAndDate(tournamentStartTime)
                  const endDate = getMonthAndDate(tournamentEndTime)
                  const cardBg = (entryFeeAndPrizeDetails?.listingType === ListingType.PREMIUM) ? 't-card-premium' : 't-card-regular'
                  const bgUrl = (baner?.url) ? baner.url : gameCover;
                  const detailsStyle = (entryFeeAndPrizeDetails?.listingType === ListingType.PREMIUM) ? 't-body-premium' : 't-body-regular';
                  const participant_Type = (participantType === ParticipantType.SINGLE ? 'Single' : 'Team');
                  return (
                    <div className="col-6 col-md-4 col-lg-2 card-padding" key={id}>
                      <NavLink to={`/tournament/${tournament._id}`} style={{textDecoration: 'none'}}>
                      <div className={`${cardBg} tournament-card m-lg-2 m-md-2 flex-grow-1 px-2 rounded-10`}>
                          <div className="d-flex flex-column h-100 py-2">
                            <div className="game d-flex flex-column" style={{ backgroundImage: `url(${bgUrl})`, height: '100px', backgroundRepeat: 'round', borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}>
                              <img src={betBadge} className='ms-auto' alt='' height='39px' width='26px' style={{display: tournament.isBettingEnabled ? 'block' : 'none'}} />
                              <div className='flex-grow-1'></div>
                              <div className="d-flex justify-content-between w-100 p-1">
                                  <span className="fs-10 text-dark bg-white px-2 py-1 fw-700 rounded-pill">{tournament.gameDetails.name}</span>
                                  {/* <span className="name"> <AiFillStar style={{ color: '#ffb800' }} /> 4.9</span> */}
                              </div>
                            </div>

                            <div className={`${detailsStyle} px-2 pb-3`}>
                              {tournament.name?.length <= 15 ? 
                                <h1 className="text-white fs-14 fw-600 text-center my-2">{tournament.name}</h1>
                                :
                                <marquee className='text-white fs-14 fw-600 text-center my-2' direction="left">{tournament?.name}<span className='text-warning px-4'>$$$</span> {tournament?.name}</marquee>
                              }
                              <h1 className="text-warning fs-10 text-center my-2 text-truncate">By {tournament?.organizationDetails?.name}</h1>
                              {/* {this.getSponsors(tournament.sponsorDetails)} */}
                              <ProgressBar now={(tournament.teams.length / maxParticipant) * 100} className='w-100 mx-0' />
                              <div className="d-flex justify-content-between w-100 my-1 mx-0" style={{ width: '90%', margin: 'auto' }}>
                                <p className="spots">Spots</p>
                                <p className="spots text-white">{`${tournament.teams.length}/${maxParticipant}`}</p>
                              </div>
                              <div className="d-flex justify-content-between mt-1" >
                                <div>
                                  <p className="fs-10 text-white fw-500 mb-1">Entry Fee</p>
                                  <p className="fs-10 text-success fw-bold mb-1">{(entryFeeAndPrizeDetails?.entryFeeAmount) ? `EC ${entryFeeAndPrizeDetails.entryFeeAmount}` : 'Free'}</p>
                                </div>
                                <div className="" style={{ textAlign: 'end' }}>
                                  <p className="fs-10 text-white fw-500 mb-1">Prize Pool</p>
                                  <p className="fs-10 text-primary fw-bold mb-1" style={{ color: '#0bb5fe', fontWeight: '600' }}>{(entryFeeAndPrizeDetails?.prizePoolAmount) ? `EC ${entryFeeAndPrizeDetails.prizePoolAmount}` : 'TBD'}</p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-1" >
                                <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Type:</p>
                                <p className="fs-10 text-white fw-500 mb-1" >{participant_Type}</p>
                              </div>
                              <div className="d-flex justify-content-between mt-1" >
                                <p className="fs-10 text-white fw-bold mb-1" >Pool Type:</p>
                                <p className="fs-10 text-white fw-500 mb-1" >{!entryFeeAndPrizeDetails.entryFee ? 'None' : entryFeeAndPrizeDetails.prizePoolType}</p>
                              </div>
                              <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                              <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>{`From ${startDate[0]} - ${endDate[0]}`}</p>
                            </div>
                          </div>
                      </div>
                      </NavLink>
                    </div>
                  )
                })}
              </div>
              {!this.state.tournamentsArray.length &&
                <div className="text-center">
                  <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                </div>
              }

              <MicroWebSiteContactUs microWebSiteData={this.state.microWebSiteData} backgroundColors={this.state.backgroundColors} secondaryTextColors={this.state.secondaryTextColors} background={this.state.background} secondary={this.state.secondary} />
            </div>
          </section>
          :
          <section className="layout1 p-3" style={{ display: (this.state.openNavbar) ? 'none' : 'block' }}>
            <div className='container mt-8'>
              <h1 className="heading mt-1">Browse Tournaments</h1>
              <div className="row my-3">
                <div className="col-7">
                  <input
                    placeholder="Search"
                    className="tournament-input"
                    style={{ color: '#ffb800', border: '1px solid #ffb800' }}
                  />
                </div>
                <div className="col-5 d-flex">
                  <div className="filter-input" style={{ color: '#ffb800', border: '1px solid #ffb800' }}>
                    <h6 className="my-auto">Filter</h6>
                    <IoMdArrowDropdown />
                  </div>
                </div>
              </div>

              <div className="row ">
                {/* Premium Type */}
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="tournament-card" style={{ backgroundImage: `url(${premium})` }}>
                    <div className="tournament-game">
                      <div className="game" style={{ backgroundImage: `url(${gameCover})` }}>
                        <div className="game-card-footer">
                          <span className="name">Valorant</span>
                          <span className="name"> <AiFillStar style={{ color: '#ffb800' }} /> 4.9</span>
                        </div>
                      </div>
                      <div className="game-details px-1">
                        <h1 className="details-heading">Grand Pixas</h1>
                        <h1 className="text-center fs-10 text-center">By Bluethorns</h1>
                        <ProgressBar now={50} />
                        <div className="d-flex justify-content-between my-2" style={{ width: '90%', margin: 'auto' }}>
                          <p className="spots">1 spot left</p>
                          <p className="spots" style={{ color: '#ffffff' }}>2 spot left</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <div>
                            <p className="fs-10 text-white fw-500 mb-1">Prizing</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#30d158', fontWeight: '600' }}>$20</p>
                          </div>
                          <div className="" style={{ textAlign: 'end' }}>
                            <p className="fs-10 text-white fw-500 mb-1">Time Left</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#ff453a', fontWeight: '600' }}>72 hr 25 min</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Type:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Solo</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Platform:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Steam</p>
                        </div>

                        <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                        <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>From Aug 2 - Aug 3</p>

                      </div>
                    </div>
                  </div>
                </div>
                {/* Regular Type */}
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="tournament-card" style={{ backgroundColor: '#222222' }}>
                    <div className="regular-game">
                      <div className="game" style={{ backgroundImage: `url(${gameCover})` }}>
                        <div className="game-card-footer">
                          <span className="name">Valorant</span>
                          <span className="name"> <AiFillStar style={{ color: '#ffb800' }} /> 4.9</span>
                        </div>
                      </div>
                      <div className="regular-game-details px-1">
                        <h1 className="details-heading">Grand Pixas</h1>
                        <h1 className="text-center fs-10 text-center">By Bluethorns</h1>
                        <ProgressBar now={50} />
                        <div className="d-flex justify-content-between my-2" style={{ width: '90%', margin: 'auto' }}>
                          <p className="spots">1 spot left</p>
                          <p className="spots" style={{ color: '#ffffff' }}>2 spot left</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <div>
                            <p className="fs-10 text-white fw-500 mb-1">Prizing</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#30d158', fontWeight: '600' }}>$20</p>
                          </div>
                          <div className="" style={{ textAlign: 'end' }}>
                            <p className="fs-10 text-white fw-500 mb-1">Time Left</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#ff453a', fontWeight: '600' }}>72 hr 25 min</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Type:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Solo</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Platform:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Steam</p>
                        </div>

                        <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                        <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>From Aug 2 - Aug 3</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="tournament-card" style={{ backgroundImage: `url(${premium})` }}>
                    <div className="tournament-game">
                      <div className="game" style={{ backgroundImage: `url(${gameCover})` }}>
                        <div className="game-card-footer">
                          <span className="name">Valorant</span>
                          <span className="name"> <AiFillStar style={{ color: '#ffb800' }} /> 4.9</span>
                        </div>
                      </div>
                      <div className="game-details px-1">
                        <h1 className="details-heading">Grand Pixas</h1>
                        <h1 className="text-center fs-10 text-center">By Bluethorns</h1>
                        <ProgressBar now={50} />
                        <div className="d-flex justify-content-between my-2" style={{ width: '90%', margin: 'auto' }}>
                          <p className="spots">1 spot left</p>
                          <p className="spots" style={{ color: '#ffffff' }}>2 spot left</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <div>
                            <p className="fs-10 text-white fw-500 mb-1">Prizing</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#30d158', fontWeight: '600' }}>$20</p>
                          </div>
                          <div className="" style={{ textAlign: 'end' }}>
                            <p className="fs-10 text-white fw-500 mb-1">Time Left</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#ff453a', fontWeight: '600' }}>72 hr 25 min</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Type:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Solo</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Platform:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Steam</p>
                        </div>

                        <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                        <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>From Aug 2 - Aug 3</p>

                      </div>
                    </div>
                  </div>
                </div>
                {/* Regular Type */}
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="tournament-card" style={{ backgroundColor: '#222222' }}>
                    <div className="regular-game">
                      <div className="game" style={{ backgroundImage: `url(${gameCover})` }}>
                        <div className="game-card-footer">
                          <span className="name">Valorant</span>
                          <span className="name"> <AiFillStar style={{ color: '#ffb800' }} /> 4.9</span>
                        </div>
                      </div>
                      <div className="regular-game-details px-1">
                        <h1 className="details-heading">Grand Pixas</h1>
                        <h1 className="text-center fs-10 text-center">By Bluethorns</h1>
                        <ProgressBar now={50} />
                        <div className="d-flex justify-content-between my-2" style={{ width: '90%', margin: 'auto' }}>
                          <p className="spots">1 spot left</p>
                          <p className="spots" style={{ color: '#ffffff' }}>2 spot left</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <div>
                            <p className="fs-10 text-white fw-500 mb-1">Prizing</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#30d158', fontWeight: '600' }}>$20</p>
                          </div>
                          <div className="" style={{ textAlign: 'end' }}>
                            <p className="fs-10 text-white fw-500 mb-1">Time Left</p>
                            <p className="fs-10 text-white fw-500 mb-1" style={{ color: '#ff453a', fontWeight: '600' }}>72 hr 25 min</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-1" >
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Type:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Solo</p>
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                          <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>Platform:</p>
                          <p className="fs-10 text-white fw-500 mb-1" >Steam</p>
                        </div>

                        <p className="fs-10 text-white fw-500 mb-1">Tournament Date</p>
                        <p className="fs-10 text-white fw-500 mb-1" style={{ fontWeight: 'bold' }}>From Aug 2 - Aug 3</p>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="d-flex justify-content-center" style={{ marginTop: '40px', marginBottom: '20px' }}>
                <img src={"https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Screenshot_from_2021-12-07_17-09-15-removebg-preview.png"} alt={""} className="border border-2 border-white rounded-10" style={{ height: '100px' }} />
              </div>
              <div className="d-flex justify-content-center">
                <div className="icon-container">
                  <RiFacebookFill className="btn-size-20" />
                </div>
                <div className="icon-container">
                  <AiFillInstagram className="btn-size-20" />
                </div>
                <div className="icon-container">
                  <FaTelegramPlane className="btn-size-20" />
                </div>
                <div className="icon-container">
                  <FaTwitter className="btn-size-20" />
                </div>
                <div className="icon-container">
                  <RiWhatsappFill className="btn-size-20" />
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    );
  }
}

export default MicroWebSiteTournaments;
