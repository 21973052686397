import React, { Component } from 'react';
import { RiFacebookFill, RiWhatsappFill } from "react-icons/ri";
import { AiFillInstagram, AiFillStar } from "react-icons/ai";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import MicroWebSiteNav from '../configuration/MicroWebSiteNav';
import bannerCover from '../../../images/about-us.png'
import MicroWebSiteContactUs from '../configuration/MicroWebSiteContactUs';
import StarRatingComponent from 'react-star-rating-component';

class MicroWebsiteAboutUs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData: props.microWebSiteData,
      games: [],
      regions: ["India", "Dubai", "SE Asia", "Russia", "Europe East", "Europe West", "Japan", "South Africa", "Australia", "US West", "US East", "Brazil", "Argentia", "China"],
      backgroundColors: ["#145DA0", "#05445E", "#2F5233", "#620ff0", "#050A30", "#000"],
      textColors: ["#2E8BC0", "#189AB4", "#76B947", "#9a65f6", "#055C9D", "#2c2c2c"],
      secondaryTextColors: ["#B1D4E0", "#D4F1F4", "#B1D8B7", "#cfb5fa", "#68BBE3", "#404040"],
      background: (props.microWebSiteData ? props.microWebSiteData.color.background : 4),
      primary: (props.microWebSiteData ? props.microWebSiteData.color.primary : 4),
      secondary: (props.microWebSiteData ? props.microWebSiteData.color.secondary : 4),
      openNavbar: false
    };
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  toggleOpenNavbar = () => {
    this.setState({ openNavbar: !this.state.openNavbar })
  }

  render() {
    const organizationDetails = this.state.microWebSiteData?.organizationDetails;
    return (
      <>
        <MicroWebSiteNav path={this.props.path} redirectTo={this.props.redirectTo} state={this.state} microWebSiteData={this.state.microWebSiteData} toggleOpenNavbar={this.toggleOpenNavbar} />
        {this.state.microWebSiteData ?
          <section className="micro-website-about-us-page layout p-3" style={{ display: (this.state.openNavbar) ? 'none' : 'block', backgroundColor: `${this.state.background}`, height:'100vh' }}>
            <div className='container mt-7'>
              <div className='row'>
                <div className='col-lg-6 col-12'>
                  <h1 className={`fs-20 fs-lg-24 mb-4 fw-700 mt-1 text-start micro-web-site-text-${this.state.primary}`}>About Us</h1>
                  <div className="about-us-image" style={{ backgroundImage: `url(${this.state.microWebSiteData.aboutUs.img.url})` }}></div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className='row'>
                    <div className='col-12'>
                      <p className={`fs-16 fs-lg-18 fw-bold mt-5 micro-web-site-sc-text-${this.state.secondary}`}>
                        {this.state.microWebSiteData.aboutUs.bio}
                      </p>
                    </div>
                    <div className='col-12'>
                      <div className="rating-container my-3">
                        <h3 className={`fw-bold text-center mt-1 micro-web-site-text-${this.state.primary}`}>Overall Rating</h3>
                        <div className="text-center text-warning">
                          {/* <AiFillStar className="btn-size-40" />
                          <AiFillStar className="btn-size-40" />
                          <AiFillStar className="btn-size-40" />
                          <AiFillStar className="btn-size-40" />
                          <AiFillStar className="btn-size-40" /> */}
                          
                          <StarRatingComponent
                              name="rate1"
                              starCount={5}
                              value={organizationDetails.organizationRating ? organizationDetails.organizationRating : 0}
                              emptyStarColor="#b4b4b4"
                              editing={false}
                          />
                        </div>
                        <p className={`fw-600 text-center my-2 micro-web-site-text-${this.state.primary}`} >
                          <span className='text-warning'>{organizationDetails.organizationRating ? organizationDetails.organizationRating : 0}</span>{' '}
                          Stars Out of 5 Stars
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MicroWebSiteContactUs microWebSiteData={this.state.microWebSiteData} backgroundColors={this.state.backgroundColors} secondaryTextColors={this.state.secondaryTextColors} background={this.state.background} secondary={this.state.secondary} />
            </div>
          </section>
          :
          <section className="layout1 p-3" style={{ backgroundColor: '#0a0a0a' }}>
            <h1 className="heading mt-1 text-start">About Us</h1>
            <div className="banner-card" style={{ backgroundImage: `url(${bannerCover})` }}></div>
            <p className="fs-16 fw-bold mt-3" style={{ color: '#77b903' }}>
              In 1999 sparked the growth of the PC gaming market, redefined modern computer graphics,
              and revolutionized parallel computing. More recently, GPU deep learning ignited modern
              AI — the next era of computing — with the GPU acting as the brain of computers, robots, and
              self-driving cars that can perceive and understand the world.
            </p>
            <div className="rating-container my-3">
              <h3 className="text-center" style={{ color: '#ffffff', fontWeight: 'bold' }}>Overall Rating</h3>
              <div className="stars-container" style={{ color: '#ffb800' }}>
                <AiFillStar className="btn-size-40" />
                <AiFillStar className="btn-size-40" />
                <AiFillStar className="btn-size-40" />
                <AiFillStar className="btn-size-40" />
                <AiFillStar className="btn-size-40" />
              </div>
              <p className="text-center my-2" style={{ color: 'white', fontWeight: '600' }}>
                <span style={{ color: '#ffb800' }}>4.99</span>{' '}
                Stars Out of 5 Stars
              </p>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: '40px', marginBottom: '20px' }}>
              <img src={"https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Logo!_Logo.svg"} alt={""} className="border border-2 border-white rounded-10" style={{height:'100px'}} />
            </div>
            <div className="d-flex justify-content-center">
              <div className="icon-container">
                <RiFacebookFill className="btn-size-20" />
              </div>
              <div className="icon-container">
                <AiFillInstagram className="btn-size-20" />
              </div>
              <div className="icon-container">
                <FaTelegramPlane className="btn-size-20" />
              </div>
              <div className="icon-container">
                <FaTwitter className="btn-size-20" />
              </div>
              <div className="icon-container">
                <RiWhatsappFill className="btn-size-20" />
              </div>
            </div>
          </section>
        }
      </>
    );
  }
}

export default MicroWebsiteAboutUs;
