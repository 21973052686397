import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class TeamApis {
    queryParams(params) {
        return Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = import.meta.env.VITE_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getTeams = (params) => {
        if (params) {
            const searchString = this.queryParams(params);
            return this.init().get(`/private/api/v1/team/getTeams?${searchString}`);
        }
        return this.init().get("/private/api/v1/team/getTeams");
    };

    addNewTeam = (data) => {
        return this.init().post("/private/api/v1/team/createTeam", data);
    };
    updateTeam = (id, data) => {
        return this.init().put(`/private/api/v1/team/updateTeam/${id}`, data);
    };
    deleteTeam = (id) => {
        return this.init().delete(`/private/api/v1/team/deleteTeam/${id}`);
    };
    getGames = () => {
        return this.init().get("/public/api/v1/game/getGames");
    };
    getMyTeams = (params) => {
        const searchString = this.queryParams(params);
        return this.init().get(`/private/api/v1/team/getMyTeams?${searchString}`);
    };

    getMyParticipatedTeam = (params) => {
        const searchString = this.queryParams(params);
        return this.init().get(`/private/api/v1/team/getMyParticipatedTeam?${searchString}`);
    };

    createTeamMember = (data) => {
        return this.init().post("/private/api/v1/teamMembers/createTeamMember",data);
    };

    getTeamMember = (id) => {
        return this.init().get(`/private/api/v1/teamMembers/getTeamMembers?_id=${id}`);
    };


    updateTeamMember = (id, data) => {
        return this.init().put(`/private/api/v1/teamMembers/updateTeamMember/${id}`, data);
    };

    removeTeamMember = (data) => {
        return this.init().post("/private/api/v1/team/removeFromTeam", data);
    };

    leaveTeam = (data) => {
        return this.init().post("/private/api/v1/team/leaveTeam", data);
    };

    getClanFromTeamId = (data) =>{
        return this.init().post("/private/api/v1/team/getClanFromTeamId", data);
    };

    getParticipantDetails = (data) => {
        return this.init().post(`/private/api/v1/team/getParticipantDetails`, data); 
    };
    
}