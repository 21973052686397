import React, { Component } from 'react';
import hammerInline from '../../../../images/den/interactions/hammer-inline.png';
import spotlightActive from '../../../../images/den/interactions/spotlight-active.png';
import { deleteCommentOnPostOrReply, deletePostCommentById, getDenProfilePicture, getPostComments, pinComment, replyOnPostOrComment, updateComment } from '../../../../utils/Den.utils';
import { receivedAt } from '../../../../utils/dateTime.utils';
import Image from '../../../common/Image';
import { profile } from '../../../../utils/localstorage.utils';
import SparksOnComments from './SparksOnComments';
import { POST_INTERACTIONS } from '../../../../utils/Images.utils';
import { NavLink } from 'react-router-dom';
import ReactionsNavInCommments from './ReactionsNavInCommments';
import CommentActions from '../../../popup/den/CommentActions';
import SpotLight from '../../../popup/den/SpotLight';
import { DEN } from '../../../../utils/Strings.utils';
import { STYLE } from '../../../../utils/Style.utils';
import { toast } from 'react-toastify';

export default class PostComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            replyOnComment: null,
            replies: [],
            denProfile: props.denProfile,
            postCommentDescription: "",
            replyOnPostCommentDescription: "",
            selectedCommentIndex: null,
            selectedReplyIndex: null,
            enableCommentActions: false,
            commentActionsFor : "Creator",
            selectedComment: null,
            selectedActionCommentIndex: null,
            editCommentId : null,
            editCommentDescription : null,
            pinnedCommments: props.postDetails ? props.postDetails.pinnedCommments : [],
            openSpotlight: false,
            commentDetails: null
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps){
            this.setState({
                denProfile: newProps.denProfile
            })
        }
    }

    componentDidMount() {
        getPostComments(this.props.postId, this.setPostComments);
    }

    filterComments = (comments, pinnedCommments) => {
        const pinnedComments = comments.filter((comment) => pinnedCommments.includes(comment._id));
        const unPinnedComments = comments.filter((comment) => !pinnedCommments.includes(comment._id));
        return pinnedComments.concat(unPinnedComments);
    }

    setPostComments = (comments) =>{

        var filteredComments = this.filterComments(comments, this.state.pinnedCommments);
        this.setState({
            comments: filteredComments
        })
    }

    getReplies = (replyOnComment) => {
        this.setState({
            replyOnComment: replyOnComment,
            selectedCommentIndex : null
        });
        getPostComments(replyOnComment, this.setReplies);
    }

    setReplies = (replies) =>{
        this.setState({
            replies: replies
        });
    }

    replyOnPostOrComment = (id, commentDescription, isPostComment) => {
        var comment = {
            postId : id,
            description : commentDescription,
            denId: this.state.denProfile?._id
        }

        replyOnPostOrComment(comment,isPostComment,this.setReply,this.updateDenProfile);
    }

    spotlightAsReplyOrComment = (id, commentDescription, isPostComment) => {
        var comment = {
            postId : id,
            description : commentDescription,
            denId: this.state.denProfile?._id,
            spotlight: 1000,
            spotlightStar: 1,
            isPostComment: isPostComment
        }

        this.setState({
            openSpotlight: true,
            commentDetails: comment,
        })
        //replyOnPostOrComment(comment,isPostComment,this.setReply,this.updateDenProfile);
    }


    onClickSpotlight = (postOrReplyId, postCommentOrReplyDescription, isPostComment) =>{
        if(this.state.denProfile?._id === this.props.postDetails.from[0]._id){
            toast.warning(isPostComment ? "You can not send spotlight to your own post's comment" : "You can not send spotlight to your own post's comment's reply")
        }else{
            this.spotlightAsReplyOrComment(postOrReplyId, postCommentOrReplyDescription, isPostComment);
        }
    }

    updateState = (key,val) =>{ 
        if(key === "comments") {
            this.setPostComments(val)
        }else{
            this.setState({
                [key] : val
            });
        }

    }

    setReply = (result, isPostComment) => {
        if(isPostComment){
            getPostComments(this.props.postId, this.setPostComments);
            document.getElementById('postCommentDescription').value = "";
            this.clearInputField();
        }else{
            getPostComments(this.state.replyOnComment, this.setReplies);
            document.getElementById('replyOnPostCommentDescription').value = "";
            this.clearInputField();
        }
    }

    clearInputField = () => {
        this.updateState('postCommentDescription',"");
        this.updateState('replyOnPostCommentDescription',"");
        this.updateState('openSpotlight',false);
        this.updateState('commentDetails',null);
    }

    updateLikedPostCommentDetails = (result) =>{
        var comments = this.state.comments;
        result.from = comments[this.state.selectedCommentIndex].from;
        comments[this.state.selectedCommentIndex] = result;

        this.setPostComments(comments);
        this.setState({
            selectedCommentIndex: null
        });
    }

    updateLikedPostCommentsReplyDetails = (result) =>{
        
        var replies = this.state.replies;
        result.from = replies[this.state.selectedReplyIndex].from;
        replies[this.state.selectedReplyIndex] = result;
        this.setState({
            replies : replies,
            selectedReplyIndex: null
        });
    }

    enableDisableCommentActions = (comment,commentIndex) =>{
        if(this.state.enableCommentActions === false){
            var commentActionsFor = (comment?.from?._id === this.state.denProfile?._id) ? "Creator" : "Commenter";
            if(comment?.from?._id === this.state.denProfile?._id && this.state.denProfile?._id !== this.props.postDetails?.from[0]?._id){
                commentActionsFor = "Commenter";
            }else if(comment?.from?._id === this.state.denProfile?._id && this.state.denProfile?._id === this.props.postDetails?.from[0]?._id){
                commentActionsFor = "Creator";
            }else if(comment?.from?._id !== this.state.denProfile?._id && this.state.denProfile?._id === this.props.postDetails?.from[0]?._id){
                commentActionsFor = "Other";
            }else{
                commentActionsFor = "None";
            }
            this.setState({
                enableCommentActions : true,
                commentActionsFor : commentActionsFor,
                selectedComment: comment,
                selectedActionCommentIndex: commentIndex
            })
        }else{
            this.setState({
                enableCommentActions : false,
                commentActionsFor : "Creator",
                selectedComment:  null,
                selectedActionCommentIndex: null
            })
        }
    }

    afterDelete = (result) =>{
      
        var updatedComments = this.state.comments;
        var updatedReplies = this.state.replies;
        
        if(this.state.selectedActionCommentIndex !== null){
            updatedComments[this.state.selectedActionCommentIndex].hammer =  updatedComments[this.state.selectedActionCommentIndex].hammer.filter((id) => id !== result._id);
            updatedReplies = updatedReplies.filter((reply) => reply._id !== result._id)
        }else{
            updatedComments = this.state.comments.filter((comment) => comment._id !== result._id);
        }
        this.setPostComments(updatedComments);
        this.setState({
            enableCommentActions : false,
            commentActionsFor : "Creator",
            selectedActionCommentIndex: null,
            selectedComment:  null,
            replies: updatedReplies
        });
      
    }

    enableEditComment = () => {
        
        this.setState({
            editCommentDescription: this.state.selectedComment.description,
            editCommentId: this.state.selectedComment._id,
            enableCommentActions : false,
            commentActionsFor : "Creator"
        })
    }

    updateComment = (commentId) =>{
        updateComment(commentId,{description : this.state.editCommentDescription, denId:this.state.denProfile?._id}, this.afterUpdate)
    }

    updateDenProfile = (updatedDenProfile) =>{
        this.setState({
            denProfile: updatedDenProfile
        });
        this.props.updateProfileDetails(updatedDenProfile);
    }

    afterUpdate = (result) =>{
        var updatedComments = this.state.comments;
        var updatedReplies = this.state.replies;
        if(this.state.selectedActionCommentIndex !== null){
            updatedReplies = updatedReplies.filter((com)=>{
                if(com._id === result._id){
                    com.description = result.description;
                    return com;
                }else{
                    return com;
                }
            })
        }else{
            updatedComments = updatedComments.filter((com)=>{
                if(com._id === result._id){
                    com.description = result.description;
                    return com;
                }else{
                    return com;
                }
            })
        }

        this.setPostComments(updatedComments);
        this.setState({
            editCommentDescription: null,
            editCommentId: null,
            selectedActionCommentIndex: null,
            selectedComment:  null,
            replies: updatedReplies
        });

    }

    pinUnpinCommentOnPost = () => {
        pinComment(this.state.selectedComment._id,{isPin: true, postId: this.props.postId}, this.afterPinComment);
    }

    afterPinComment = (result) => {
        const filteredComments = this.filterComments(this.state.comments, result.pinnedCommments);
        this.setState({
            comments: filteredComments,
            pinnedCommments: result.pinnedCommments,
            editCommentDescription: null,
            editCommentId: null,
            selectedActionCommentIndex: null,
            selectedComment:  null,
            enableCommentActions : false
        });

    }


    render() {
        const {denProfile, openSpotlight, commentDetails} = this.state;
        const profilePicture = getDenProfilePicture(denProfile);
        return(
            <div className='den-post-comments'>
                <hr className='my-0' style={{border: '1px solid #6D53DB'}} />

                <div className="p-2">
                    <ReactionsNavInCommments denProfile={denProfile} postId={this.props.postId}/>

                    <div className='user-comment-container p-1 my-3 px-3'>
                        <Image src={profilePicture?.url} param={this.state.denProfile?.name ? this.state.denProfile?.name : profile()?.username} alt='' className='rounded-circle' style={{ height:'28px', width:'28px' }} />
                        <input type='text' className='fs-14 text-white flex-grow-1 mx-2' 
                            id="postCommentDescription"
                            autoComplete='off'
                            maxLength={500}
                            onChange={(e) => this.updateState('postCommentDescription', e.target.value)}
                            value={this.state.postCommentDescription}
                            style={{ background: 'transparent', border: 'none', outline: 'none' }}
                            placeholder={`Reply as ${this.state.denProfile?.name ? this.state.denProfile?.name : profile()?.username}`}
                        />
                        {/* <img src={hammerInline} alt='' className='rounded-circle' style={{ height:'18px', width:'18px' }} onClick={()=> this.replyOnPostOrComment(this.props.postId, this.state.postCommentDescription, true)} /> */}
                        {/* <p className='fs-12 fw-bold opacity-75 my-0 mx-1'>{denProfile?.hammer} Left</p> */}
                        {/* <img src={spotlightActive} alt='' className='rounded-circle' style={{ height:'28px', width:'28px' }} onClick={()=> this.spotlightAsReplyOrComment(this.props.postId, this.state.postCommentDescription, true)}  /> */}
                        <div className ='send-spotlight-btn d-flex align-items-center cursor-pointer mx-1 px-1' onClick={()=> this.replyOnPostOrComment(this.props.postId, this.state.postCommentDescription, true)} style={{width: 'auto'}}>
                            <span className='fw-500'>{denProfile?.hammer}</span>
                            <img src={POST_INTERACTIONS.ACTIONS.SEND} alt='' className='rounded-circle ms-1' style={{ height:'18px', width:'18px' }} />
                        </div>
                        
                        <div className ='send-spotlight-btn column-center-align-div cursor-pointer' onClick={()=> this.onClickSpotlight(this.props.postId, this.state.postCommentDescription, true)}>
                            <img src={spotlightActive} alt='' className='rounded-circle' style={{ height:'24px', width:'24px' }} />
                        </div>
                        
                    </div>

                    <p className='fs-16 fw-500 my-0'>Comments {this.state.comments?.length > 0 ? `(${this.state.comments?.length})` : ''}</p>
                    <div className='comments-section'>
                        {this.state.comments.map((comment, i)=>{
                            if(!comment.isDeleted){
                                var commentSparkDetails = {
                                    logo : [],
                                    likes : 0,
                                    users : []
                                };
                            
                                Object.keys(comment.likes).map((key,i)=>{
                        
                                    commentSparkDetails.likes += comment.likes[key].length 
                                    commentSparkDetails.users = commentSparkDetails.users.concat(comment.likes[key]); 
                                    if(comment.likes[key].length >0){
                                        commentSparkDetails.logo.push(key)
                                    }
                                });
                                const commentedUserProfilePicture = getDenProfilePicture(comment?.from);
                                
                                return(
                                    <div className='d-flex my-2' key={i}>
                                        <Image param={comment?.from?.name} src={commentedUserProfilePicture?.url ? commentedUserProfilePicture?.url : "Test"} alt='' className='rounded-circle me-2' style={{ height:'32px', width:'32px' }} />
                                        <div className='w-100'>
                                            <div className='comment-thread-container flex-grow-1 p-2' style={comment.spotlight > 0 ? STYLE.DEN.SPOTLIGHT.COMMENT[comment.spotlightStar]:{} }>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='fs-14 fw-600 my-0 pe-3'>{comment?.from?.name}  </p>
                                                    
                                                    {(comment?.from?._id === this.state.denProfile?._id || this.state.denProfile?._id === this.props.postDetails?.from[0]?._id ) ?  
                                                        <p className='fs-14 fw-bold my-0 ' style={{ color: '#fff'}} >{this.state.pinnedCommments.includes(comment._id) ? <img src={POST_INTERACTIONS.COMMENT.PINNED} alt=''/> : ""} <b className='cursor-pointer' onClick={()=>this.enableDisableCommentActions(comment,null)}>...</b></p>
                                                        :
                                                        <p className='fs-14 fw-bold my-0'>
                                                            {this.state.pinnedCommments.includes(comment._id) ? <img src={POST_INTERACTIONS.COMMENT.PINNED} alt=''/> : ""}
                                                        </p>
                                                    }
                                                </div>
                                                {this.state.editCommentId === comment._id ?
                                                    <div className='user-comment-container p-1 my-3'>
                                                        <input type='text' className='fs-14 text-white flex-grow-1 mx-2' 
                                                            id="editPostCommentDescription"
                                                            autoComplete='off'
                                                            maxLength={500}
                                                            defaultValue={this.state.editCommentDescription}
                                                            onChange={(e) => this.updateState('editCommentDescription', e.target.value)}
                                                            style={{ background: 'transparent', border: 'none', outline: 'none' }}
                                                            placeholder={`Type Something to edit this comment`}
                                                        />
                                                        <img src={hammerInline} alt='' className='rounded-circle' style={{ height:'18px', width:'18px' }} onClick={()=> this.updateComment(comment._id)} />
                                            
                                                    </div>
                                                :
                                                    <p className='fs-14 fw-400 my-1'>
                                                        {comment.description}
                                                    </p>
                                                }
                                                
                                            </div>
                                            <div className='d-flex align-items-center my-1'>
                                                <p className='fs-14 fw-400 text-white-50 mx-2 my-0'>{receivedAt(comment.createdAt)}</p>
                                                <p className='fs-14 fw-400 text-white-50 mx-2 my-0 cursor-pointer' onClick={()=>this.setState({selectedCommentIndex : i,selectedReplyIndex: null, replyOnComment:null})}>Like</p>
                                                <p className='fs-14 fw-400 text-white-50 mx-2 my-0 flex-grow-1 cursor-pointer' onClick={()=>this.getReplies(comment._id)}>Reply {comment.hammer.length > 0 ? `(${comment.hammer.length})` : ''}</p>
                                                {commentSparkDetails.likes > 0 && <p className='fs-14 fw-400 text-white-50 mx-2 my-0'>{commentSparkDetails.likes}</p> }
                                            
                                                {commentSparkDetails.likes > 0 && <NavLink to={`/den/feed/hammer/${comment._id}`} >
                                                    <div className='d-flex cursor-pointer'>
                                                        {commentSparkDetails.logo.map((key,k)=>{
                                                            return  <img src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle' style={(k === commentSparkDetails.logo.length-1) ?{ height:'16px', width:'16px' , marginLeft:'-5px'} : { height:'16px', width:'16px' , marginLeft:'-5px'}} />
                                                        })}
                                                    </div>
                                                </NavLink>}
                                            </div>
                                            <div className='d-flex align-items-center my-1'>
                                                {this.state.selectedCommentIndex === i &&  <SparksOnComments updateProfileDetails={this.props.updateProfileDetails} denProfile={this.state.denProfile} updateLikedPostDetails={this.updateLikedPostCommentDetails} comment={comment} index={i} /> }
                                            </div>
                                            {/* Reply on comment */}
                                            {this.state.replyOnComment === comment._id &&
                                                <div>
                                                    <div className='user-comment-container p-1 my-3'>
                                                        <Image src={profilePicture?.url} param={this.state.denProfile?.name} alt='' className='rounded-circle' style={{ height:'28px', width:'28px' }} />
                                                        <input type='text' className='fs-14 text-white flex-grow-1 mx-2' 
                                                            id="replyOnPostCommentDescription"
                                                            autoComplete='off'
                                                            onChange={(e) => this.updateState('replyOnPostCommentDescription', e.target.value)}
                                                            style={{ background: 'transparent', border: 'none', outline: 'none' }}
                                                            placeholder={`Reply on comment as ${this.state.denProfile?.name}`}
                                                        />
                                                        <img src={hammerInline} alt='' className='rounded-circle' style={{ height:'18px', width:'18px' }} onClick={()=> this.replyOnPostOrComment(comment._id, this.state.replyOnPostCommentDescription, false)} />
                                                        <p className='fs-12 fw-bold opacity-75 my-0 mx-1'>{denProfile?.hammer}  Left</p>
                                                        <img src={spotlightActive} alt='' className='rounded-circle' style={{ height:'28px', width:'28px' }} onClick={()=> this.onClickSpotlight(comment._id, this.state.replyOnPostCommentDescription , false)} />
                                                    </div>
                                                    {/* Replies on Comments */}
                                                    {this.state.replies.map((reply,j)=>{
                                                        if(!reply.isDeleted){
                                                            var sparkDetails = {
                                                                logo : [],
                                                                likes : 0,
                                                                users : []
                                                            };
                                                        
                                                            Object.keys(reply.likes).map((key,i)=>{
                                                                sparkDetails.likes += reply.likes[key].length 
                                                                sparkDetails.users = sparkDetails.users.concat(reply.likes[key]); 
                                                                if(reply.likes[key].length >0){
                                                                    sparkDetails.logo.push(key)
                                                                }
                                                            });

                                                            const repliedUserProfilePicture = getDenProfilePicture(reply?.from);
                                                            return(
                                                                <div className='d-flex my-2' key={j} >
                                                                    <Image param={reply?.from?.name} src={repliedUserProfilePicture?.url}  alt='' className='rounded-circle me-2' style={{ height:'32px', width:'32px' }} />
                                                                    <div>
                                                                        <div className='comment-thread-container flex-grow-1 p-2' style={reply.spotlight > 0 ? STYLE.DEN.SPOTLIGHT.COMMENT[reply.spotlightStar]:{} }>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <p className='fs-14 fw-600 my-0 pe-3'>{reply?.from?.name} </p>
                                                                                {(reply?.from?._id === this.state.denProfile?._id || this.state.denProfile?._id === this.props.postDetails?.from[0]?._id ) &&  <p className='fs-14 fw-bold my-0 cursor-pointer' style={{ color: '#fff'}} onClick={()=>this.enableDisableCommentActions(reply,i)}>...</p>}
                                                                            </div>

                                                                            {this.state.editCommentId === reply._id ?
                                                                                <div className='user-comment-container p-1 my-3'>
                                                                                    <input type='text' className='fs-14 text-white flex-grow-1 mx-2' 
                                                                                        id="editPostCommentDescription"
                                                                                        autoComplete='off'
                                                                                        defaultValue={this.state.editCommentDescription}
                                                                                        onChange={(e) => this.updateState('editCommentDescription', e.target.value)}
                                                                                        style={{ background: 'transparent', border: 'none', outline: 'none' }}
                                                                                        placeholder={`Type Something to edit this comment`}
                                                                                    />
                                                                                    <img src={hammerInline} alt='' className='rounded-circle' style={{ height:'18px', width:'18px' }} onClick={()=> this.updateComment(reply._id)} />
                                                                        
                                                                                </div>
                                                                            :
                                                                                <p className='fs-14 fw-400 my-1'>
                                                                                    {reply.description}
                                                                                </p>
                                                                            }
                                                
                                                                        </div>
                                                                        <div className='d-flex align-items-center my-1'>
                                                                            <p className='fs-14 fw-400 text-white-50 mx-2 my-0'>{receivedAt(reply.createdAt)}</p>
                                                                            <p className='fs-14 fw-400 text-white-50 mx-2 my-0 flex-grow-1  cursor-pointer' onClick={()=>this.setState({selectedReplyIndex : j})}>Like</p>
                                                                            {sparkDetails.likes > 0 &&  <p className='fs-14 fw-400 text-white-50 mx-2 my-0'>{sparkDetails.likes}</p>}
                                                                            {sparkDetails.likes > 0 && <NavLink to={`/den/feed/hammer/${reply._id}`} >
                                                                                <div className='d-flex cursor-pointer'>
                                                                                    {sparkDetails.logo.map((key,k)=>{
                                                                                        return  <img src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle' style={(k === sparkDetails.logo.length-1) ?{ height:'16px', width:'16px' , marginLeft:'-5px'} : { height:'16px', width:'16px' , marginLeft:'-5px'}} />
                                                                                    })}
                                                                                </div>
                                                                                </NavLink>
                                                                            }
                                                                        </div>
                                                                        <div className='d-flex align-items-center my-1'>
                                                                            {this.state.selectedReplyIndex === j &&  <SparksOnComments updateProfileDetails={this.props.updateProfileDetails} denProfile={this.state.denProfile} updateLikedPostDetails={this.updateLikedPostCommentsReplyDetails} comment={reply} index={j}  /> }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

                <div className='comment-actions-modal'>
                    {/* <CommentActions deleteComment={()=>deletePostCommentById(this.state.selectedComment?._id, this.afterDelete)} show={this.state.enableCommentActions} commentActionsFor={this.state.commentActionsFor}  onClose={()=>this.setState({enableCommentActions : false})} /> */}
                    <CommentActions 
                        pinUnpinCommentOnPost={this.pinUnpinCommentOnPost} 
                        showPinPost={this.state.selectedActionCommentIndex === null ? true : false}  
                        enableEditComment={this.enableEditComment} 
                        deleteComment={()=>deleteCommentOnPostOrReply(this.state.selectedComment?._id,{postId: this.props.postDetails._id, commentId: this.state.replyOnComment}, this.afterDelete)} 
                        show={this.state.enableCommentActions} 
                        commentActionsFor={this.state.commentActionsFor}  
                        onClose={()=>this.setState({enableCommentActions : false})} 
                    />

                </div>

                {openSpotlight && <SpotLight 
                    enableComment={true}
                    isLiveStreaming={false}
                    type={DEN.SPOTLIGHT.TYPE.COMMENT} 
                    denProfile={denProfile} 
                    openSpotlight={openSpotlight} 
                    updateState={this.updateState} 
                    setReply={this.setReply}
                    updateDenProfile={this.updateDenProfile}
                    commentDetails={commentDetails}
                />}
            </div>
        )
    }
}