import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// Services
import MicroWebSiteApis from '../../helper/microwebsite.api';
// Utils
import { errorHandler, updateLoader } from '../../utils/common.utils';
// Components
import MicroWebSiteHome from '../micro-website/page/MicroWebSiteHome';

export default class NotFound extends Component {

  constructor(props) {
    
    super(props);
    this.state = {
      microWebSiteData: null,
      path: null
    };

    this.microWebSiteService = new MicroWebSiteApis();

  }

  // To check if give URL matches with any other micro web site of an organization
  componentDidMount() {
    this.props.showOrHideNav(false);
    var path = this.props.history.location.pathname.split("/");
    var requestBody = {
      name: path[1]
    };

    updateLoader(true);
    this.microWebSiteService.getMicroWebsite(requestBody)
      .then((response) => {
        this.setState({
          microWebSiteData: response.data.result,
          path: path[1]
        });
        updateLoader(false);
      }).catch((reason) => {
        updateLoader(false);
        if (reason.response.status === 404) {
          updateLoader(false);
        } else {
          errorHandler(reason);
        }
      });
  }

  render() {
    return (
      <div>
        {this.state.microWebSiteData ?
          <MicroWebSiteHome path={this.state.path} showOrHideNav={this.props.showOrHideNav} microWebSiteData={this.state.microWebSiteData} />
          :
          <header className="App-header page-not-found-bg">
            {/* Header */}
            <div className='position-absolute' style={{ top: '80px' }}>
              <h2 className='fs-18 fw-600 rounded-pill p-3' style={{ background: '#874CD9' }}>Page not found</h2>
            </div>
            {/* Container */}
            <div className="container text-center">
              <div className='text-center'>
                <div className="row">
                  <div className="col-md-12">
                    <div className="error-template">
                      
                      <h2 className='fs-24 fw-700 text-warning mb-3'>Oh No! Error 404</h2>
                      <p className='fs-20 fw-600 px-3'>Maybe we’ve lost connection to the moon</p>
                      <p className='fs-20 fw-600 px-3'>Come back to the homepage</p>
                     
                      {/* Get back to home page */}
                      <NavLink to="/" className="text-decoration-none">
                        <button className='white-flat-btn fw-bold py-2 px-5 m-auto my-4 rounded-15'>Go To Home</button>
                      </NavLink>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        }
      </div>

    );
  }
}