import { LOGOS } from "./Images.utils"

export const HEADING = {
    ADD_STAGE: 'Add Stage Details',
    UPDATE_STAGE: 'Update Stage Details',
    SINGLE_ELIMINATION: 'Single Elimination',
    CONFIG_MICRO_WEBSITE : 'Configure Micro-Website',
    SLOT_DETAILS : 'Slot Details'
}

export const SUB_HEADING = {
    STAGE_NAME: 'Stage Name',
}

export const VERIFICATION_STATUS = {
    NOT_VERIFIED: 'Verify',
    VERIFICATION_REQUESTED: 'Verification Requested',
    VERIFIED: 'Verified',
}

export const STAGE = {
    SINGLE_ELIMINATION: 'Single Elimination',
    DOUBLE_ELIMINATION: 'Double Elimination',
    BRACKET_GROUPS: 'Bracket Groups',
    ROUND_ROBIN: 'Round Robin Groups',
    FFA_BRACKET_GROUPS: 'FFA Bracket Groups',
    FFA_SIMPLE_STAGE: 'Simple Stage',
    FFA_SINGLE_ELIMINATION: 'Single Elimination'
}

export const PATH = {
    SINGLE_ELIMINATION: 'duel-single-elimination',
    DOUBLE_ELIMINATION: 'duel-double-elimination',
    BRACKET_GROUPS: 'duel-bracket-groups',
    ROUND_ROBIN: 'duel-round-robin-group',
    FFA_BRACKET_GROUPS: 'ffa-bracket-groups',
    FFA_SIMPLE_STAGE: 'ffa-simple-stage',
    FFA_SINGLE_ELIMINATION: 'ffa-single-elimination',
    FFA_FLEXIBLE_STAGE: 'ffa-flexible-stage'
}

export const LABEL = {
    // Stages Forms
    STAGE_NAME: 'Stage Name',
    WINNERS_PER_MATCH: 'Winners Per Match',
    PARTICIPANTS_PER_MATCH: 'Participants Per Match',
    QUALIFICATION_THRESHOLD: 'Qualification Threshold',
    PLAY_OFF_FORMAT: 'Playoff Format',
    INCLUDE_THIRD_PLACE: 'Include Third Place',
    NAME:'Name',
    NUMBER_OF_MATCHES:'Number of Matches',
    THRESHOLD: 'Threshold',
    // Other Form Labels
    EMAIL_ADDRESS:'Email Address',
    ESPOTZ_USERNAME:'Espotz Username',
    PASSWORD:'Password',
    PROFILE:'Profile',
    OLD_PASSWORD:'Old Password',
    NEW_PASSWORD:'New Password',
    CONFIRM_PASSWORD:'Confirm Password'
}

export const PLACEHOLDER = {
    STAGE_NAME: 'Enter Stage Name',
    WINNERS_PER_MATCH: 'Enter Winners Per Match',
    PARTICIPANTS_PER_MATCH: 'Enter Participants Per Match',
    QUALIFICATION_THRESHOLD: 'Enter Qualification Threshold',
    NUMBER_OF_MATCHES: 'Enter Number of Matches',
    THRESHOLD: 'Enter Qualification Threshold'
}

export const WARNING = {
    NEXT_STAGE_WILL_BE_DELETED: 'Next stages will be deleted on edit of this stage. Are you sure you want to edit this stage?',
    SAME_PASSWORD: 'Passwords must be same',
    DELETE_MATCH: 'Are you sure you want to delete this match from this stage ?',
    ADD_NEW_MATCH: 'Are you sure you want to add new match to this stage ?',
    REMOVE_PARTICIPANT: 'Are you sure you want to remove this participant from this match ?',
    CLEAR_NOTIFICATIONS: 'Are you sure you want to clear all the notifications ?',
}

export const ERROR = {
    INVALID_INPUT: 'Invalid input value',
    MAX_THRESHOLD: 'Threshold should be less than max participants',
    NO_TEAM_FOR_TOURNAMENT: 'You dont have any team to join this tournament.',
    DISCORD_ANOTHER_ACCOUNT:'This discord account is already associated with some another account',
    NO_RECORD_FOUND: "We couldn't find what you're looking for"
}
export const SUCCESS = {
    STAGE_CREATED: `Stage created successfully`,
    STAGE_UPDATED: `Stage updated successfully`,
    STAGE_DELETED: `Stage deleted successfully`,
    POS_HAS_BEEN_DELETED: 'Post has been deleted successfully',
    POS_HAS_BEEN_HIDE: 'Post has been hidden successfully',
    POS_HAS_BEEN_UNHIDE: 'Post has been unhidden successfully'
}

export const SOCIAL_MEDIA_PLATFORM = {
    INSTAGRAM:{
        LABEL: 'instagram',
        KEY: 'instagram',
    },
    FACEBOOK:{
        LABEL: 'facebook Gaming',
        KEY: 'facebook',
    },
    TWITTER:{
        LABEL: 'twitter',
        KEY: 'twitter',
    },
    TELEGRAM:{
        LABEL: 'telegram',
        KEY: 'telegram',
    },
    YOUTUBE:{
        LABEL: 'youtube',
        KEY: 'youtube',
    },
    LOCO:{
        LABEL: 'loco',
        KEY: 'loco',
    },
    TWITCH:{
        LABEL: 'twitch',
        KEY: 'twitch',
    }
}

export const ENUMS = {
    BETTING_SECTION :{
        MATCHES: 'matches',
        BETS: 'bets'
    }
}

export const ASSETS = {
    DEFAULT_COVER : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/Copy+of+YouTube+Channel+Art+Gaming+Template.jpeg',
    EMPTY_COLLECTION : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/no_tournament_found.png',
    AVATAR : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/image+59.png',
    DEFAULT_AVATAR : LOGOS.ORG, //'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/',
    NO_TEAMS: 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/5d3af730-2063-11ed-b91c-15299f1c8047',
    OPTIONS : {
        0 : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-a.png', // A
        1 : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-b.png', // B
        2 : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-c.png', // C
        3 : 'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-d.png', // D
    }
}

export const DEN ={
    POST_TYPES : {
        SAVED: 'saved',
        DRAFT: 'draft',
        HIDDEN: 'hidden'
    },
    FOLLOW_TYPE : {
        FOLLOWERS: 'followers',
        FOLLOWINGS: 'followings'
    },
    SPOTLIGHT: {
        TYPE : {
            USER: "USER",
            POST: "POST",
            COMMENT: "COMMENT"
        }
    },
    INTERACTIONS: {
        DISABLE:{
            REACTION: 'reaction',
            COMMENT: 'comment',
            SHARE: 'share',
            SPOTLIGHT: 'spotlight',
        }
    },
    SEARCH: {
        TYPES :{
            ALL: "ALL",
            USER: "USER",
            CLAN: "CLAN",
            ORG: "ORG",
            POST: "POST"
        }
    },
    TABS:{
        HOME: "HOME",
        POSTS: "POSTS",
        ACHIEVEMENTS : "ACHIEVEMENTS",
        BLOCKED_USERS: "BLOCKED USERS",
        FOLLOW_REQUESTS: "FOLLOW REQUESTS",
        INNER_CIRCLE : "INNER CIRCLE",

    },
    ACHIEVEMENTS: {
        TYPE: {
            TOP_FAN : {
                KEY : "TOP_FAN",
                VALUE : "Top Fans",
            },
            ACHIEVEMENTS : {
                KEY : "ACHIEVEMENTS",
                VALUE : "Achievement",
            }
        }
    }
}

export const STATE_VARIABLE = {
    SEARCH_TYPE : "searchType"
}


export const POST_FEELINGS = ['Feeling...', 'Watching match 👀', 'Playing Game 🎮', 'Organizing Tournament 👼', 'Participating', 'Staking 🎲', 'Planning Tournament 📅', 'Exploring 🚀'];

