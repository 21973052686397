import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//styles and icons
import { BsInfoCircle } from 'react-icons/bs';
import '../../../../styles/Tournaments.css';
import { FaUserCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

export default class PremiumPaidCF extends Component {
    constructor(props) {
        super(props)

        this.state = {
            kycVerified: true,
            entryFeeAmount: props?.initialValues?.entryFeeAmount ? props?.initialValues?.entryFeeAmount : '',
            maxParticipants: props?.maxParticipants ? props?.maxParticipants : 0,
            entryCommission: props?.initialValues?.entryCommission ? props?.initialValues?.entryCommission : '',
            espotzCommission: props?.initialValues?.espotzCommission ? props?.initialValues?.espotzCommission : 10,
            buyEC: props?.initialValues?.buyEC ? props?.initialValues?.buyEC : '',
            moneyCollectedPerEntryFee: props?.initialValues?.moneyCollectedPerEntryFee ? props?.initialValues?.moneyCollectedPerEntryFee : '',
            prizePoolAmount: props?.initialValues?.prizePoolAmount ? props?.initialValues?.prizePoolAmount : '',
            ECToBeReceived: props?.initialValues?.ECToBeReceived ? props?.initialValues?.ECToBeReceived : '',
        }
        console.log(' commissionValues ::',  props.commissionValues);
    }

    componentDidMount() { }

    componentWillReceiveProps(newProps) {
        this.setState({
            entryFeeAmount: newProps?.initialValues?.entryFeeAmount ? newProps?.initialValues?.entryFeeAmount : '',
            maxParticipants: newProps?.maxParticipants ? newProps?.maxParticipants : 0,
            entryCommission: newProps?.initialValues?.entryCommission ? newProps?.initialValues?.entryCommission : '',
            espotzCommission: newProps?.initialValues?.espotzCommission ? newProps?.initialValues?.espotzCommission : '',
            buyEC: newProps?.initialValues?.buyEC ? newProps?.initialValues?.buyEC : '',
            moneyCollectedPerEntryFee: newProps?.initialValues?.moneyCollectedPerEntryFee ? newProps?.initialValues?.moneyCollectedPerEntryFee : '',
            prizePoolAmount: newProps?.initialValues?.prizePoolAmount ? newProps?.initialValues?.prizePoolAmount : '',
            ECToBeReceived: newProps?.initialValues?.ECToBeReceived ? newProps?.initialValues?.ECToBeReceived : '',
        });
    }

    updateDetails = (e) => {
        e.preventDefault();
        let { maxParticipants, entryCommission, espotzCommission, moneyCollectedPerEntryFee, prizePoolAmount } = this.state;
        moneyCollectedPerEntryFee = (100 - entryCommission - espotzCommission) * (e.target.value) / 100;
        prizePoolAmount = maxParticipants * moneyCollectedPerEntryFee;
        //console.log(`entryFeeAmount-${e.target.value}  moneyCollectedPerEntryFee-${moneyCollectedPerEntryFee}  prizePoolAmount-${prizePoolAmount}`);
        this.setState({
            entryFeeAmount: e.target.value,
            moneyCollectedPerEntryFee,
            prizePoolAmount
        })
    }

    calculateFields = (el) => {
        let { entryFeeAmount, maxParticipants, espotzCommission, moneyCollectedPerEntryFee, prizePoolAmount } = this.state;
        moneyCollectedPerEntryFee = (100 - el - espotzCommission) * (entryFeeAmount) / 100;
        prizePoolAmount = maxParticipants * moneyCollectedPerEntryFee;
        //console.log(`entryCommission-${el}  moneyCollectedPerEntryFee-${moneyCollectedPerEntryFee}  prizePoolAmount-${prizePoolAmount}`);
        this.setState({
            entryCommission: el,
            moneyCollectedPerEntryFee,
            prizePoolAmount
        })
    }

    validateTOCommission = (val) => {
        let error;
        if (val + this.state.espotzCommission > 40) {
            error = `Max available commission is ${40 - this.state.espotzCommission}%`;
        }
        this.calculateFields(val);
        return error;
    }

    validateMaxParticipants = (val) => {
        let error;
        if (val > this.state.maxParticipants) {
            error = `Max participants cannot be more than ${this.state.maxParticipants}`;
        }
        return error;
    }


    passDetails = (obj) => {
        this.props.onSubmitData(obj)
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="float" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    render() {
        return (
            <section className="">
                <Formik
                    enableReinitialize
                    initialValues={{
                        entryFeeAmount: this.state.entryFeeAmount,
                        maxParticipants: this.state.maxParticipants,
                        entryCommission: this.state.entryCommission,
                        espotzCommission: this.state.espotzCommission,
                        buyEC: this.state?.buyEC ? this.state.buyEC : '',
                        moneyCollectedPerEntryFee: this.state.moneyCollectedPerEntryFee,
                        prizePoolAmount: this.state.prizePoolAmount,
                        ECToBeReceived: this.props?.initialValues?.ECToBeReceived ? this.props?.initialValues?.ECToBeReceived : '',
                    }}
                    validationSchema={Yup.object({
                        entryFeeAmount: Yup.number().integer().required('Enter Entry Fee Amount').min(0, 'Cannot be less than Zero'),
                        maxParticipants: Yup.number().integer().required('Enter max participants allowed').min(0, 'Cannot be less than Zero'),
                        espotzCommission: Yup.number(),
                        moneyCollectedPerEntryFee: Yup.number().required('*required'),
                        prizePoolAmount: Yup.number()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.passDetails(values)
                            setSubmitting(false);
                        }, 400);
                    }}>
                    <Form className="row mt-3" autoComplete="off">
                        {/* Entry Fee Amount */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Entry Fee Amount</label>
                            <div className="">
                                <Field
                                    name='entryFeeAmount' type="number" placeholder="Enter in EC"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 fw-500 height-45"
                                    onKeyUp={(e) => this.updateDetails(e)}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="entryFeeAmount" className="error-msg" />
                        </div>

                        {/* Max Participants */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Max Participants</label>
                            <div className="">
                                <FaUserCircle className='btn-size-20 ms-2 position-absolute' style={{ marginTop: '11px' }} />
                                <Field
                                    type="number" name='maxParticipants' placeholder="Enter max participants"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fw-500 height-45"
                                    validate={this.validateMaxParticipants}
                                    disabled
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="maxParticipants" className="error-msg" />
                        </div>

                        {/* Max Prize Pool */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Max Prize Pool</label>
                            <div className="">
                                <Field
                                    name='prizePoolAmount' type="number"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 fw-500 height-45"
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="prizePoolAmount" className="error-msg" />
                        </div>

                        {/* Entry Commission */}
                        <div className="col-lg-6 my-3">
                            <div className="d-flex justify-content-between align-items-center my-3">
                                <label htmlFor='customField' className="fs-14 fw-600 text-white">Entry Commission %</label>
                                <div className="">
                                    <Field
                                        name='entryCommission' type="number" placeholder='Enter TO Commission'
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-info fs-14 fw-500 height-45"
                                        validate={this.validateTOCommission}
                                        min={0} step={1} onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                            </div>
                            <ErrorMessage component="span" name="entryCommission" className="error-msg" />
                        </div>
                        {/* Espotz Commission */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">Espotz Commission %</label>
                                <Field
                                    name='espotzCommission' type='number'
                                    className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    disabled
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="espotzCommission" className="error-msg" />
                        </div>
                        {/* Buy EC */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <div className="">
                                    <label htmlFor="custom-btn" className="fs-14 fw-600 text-white w-100" style={{ maxWidth: 'max-content' }}> Buy EC </label>
                                    <a href="#" title="Amount of EC required to Deposit to Create Tournament." className="tooltip-info">
                                        <BsInfoCircle className="text-white btn-size-12 ms-1" />
                                    </a>
                                </div>
                                <Field
                                    className='purple-field d-flex align-items-center w-75 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    name='buyEC' type='number'
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="buyEC" className="error-msg" />
                        </div>
                        {/*EC To Be Received */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">EC To Be Recieved</label>
                                <Field
                                    name='ECToBeReceived' type='number' disabled
                                    className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="ECToBeReceived" className="error-msg" />
                        </div>
                        {/*Money Collected Per Entry Fee */}
                        <div className='col-lg-6 my-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="fs-14 fw-600 text-white">Commission Per Entry Fee</label>
                                <Field
                                    name='moneyCollectedPerEntryFee' type='number' disabled
                                    className='purple-field d-flex align-items-center w-25 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="moneyCollectedPerEntryFee" className="error-msg" />
                        </div>

                        <div className='col-lg-6 my-3'>
                            <button className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-2'>
                                KYC Verification
                            </button>
                        </div>
                        {/* <button
                            className='tournament-btn category-btn w-100 my-2'
                            type='submit'
                            disabled={this.state.kycVerified ? false : true}
                            style={{ opacity: this.state.kycVerified ? 1 : 0.6 }}>
                            Pay
                        </button> */}
                        <div className='col-lg-6 my-3'>
                            <button type='submit' className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-4 mt-lg-2' >
                                Next
                            </button>
                        </div>
                    </Form>
                </Formik>
            </section>
        )
    }
}
