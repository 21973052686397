import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Images
import latestResultIc from "../../../images/icon/LatestResult-ic.png";
import participantAvtar from "../../../images/participantAvtar.jpg";
import clipBoardList from "../../../images/icon/clipBoardList-ic.png";
import winIc from '../../../images/icon/win-ic.png';
import greenChat from "../../../images/icon/greenChat-ic.png";
import greenDispute from "../../../images/icon/greenDispute-ic.png";
import fileAttach from '../../../images/icon/fileAttach-ic.png';
import { DropdownButton, Dropdown, Button, ButtonGroup, SplitButton } from 'react-bootstrap';

// icons
import { AiFillCloseCircle } from "react-icons/ai";
import { MdArrowBackIosNew } from 'react-icons/md';
import MatchesApis from '../../../helper/matches.api';
import { DisputeStatus, errorHandler, getAllMembersIdFromClan, getMonthAndDate, getParticipantsIds, updateLoader } from '../../../utils/common.utils';
import { profile } from '../../../utils/localstorage.utils';
import CommonApis from '../../../helper/common.api';
import { toast } from 'react-toastify';
import { HiUserGroup } from 'react-icons/hi';
import { createAndSendNotification } from '../../../utils/notification.utils';
import TeamApis from '../../../helper/teams.api';
import { ParticipantType } from '../../../utils/enums.utils';

export default class LatestResult extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectMatch: true,
            openRaisedDispute: false,
            stageIndex: 0,
            tournamentId: this.props?.tournamentId ? this.props.tournamentId : null,
            stageDetails: null,
            tournamentDetails: this.props?.tournamentDetails ? this.props?.tournamentDetails : null,
            isMatchDetailsAdded: true,
            groupIndex: 0,
            roundIndex: 1,
            selectedMatches: [],
            matchDetails: null,
            inMatchRole: '',
            bracketType: 'Winners',
            isDoubleBracket: false,
            doubleBracketData: [],
            disputImages: [],
            sortedParticipants: [],
            selectedMatchIndex: null,
            disputeTitle: '',
            disputeDescription: '',
            selectedMatchVersion: null
        }
        this.MatchesApis = new MatchesApis();
        this.commonApi = new CommonApis();
        this.teamApis = new TeamApis();
    }

    componentDidMount() {
        this.getStageDetails(this.state.stageIndex);
    }

    checkRoleInMatch = (matchDetails) => {
        if (this.props.tournamentDetails?.participantType === 'TEAM') {
            const data = {
                userId: profile()?.id,
                matchId: matchDetails._id
            }
            this.MatchesApis.isParticipantInMatch(data).then(
                (res) => {
                    updateLoader(false)
                    this.setState({ inMatchRole: res?.data?.result?.role })
                }
            ).catch(
                (err) => {
                    updateLoader(false)
                    console.log(' error::', err)
                }
            )
        } else {
            let players = matchDetails?.participants?.map(el => { return el?.id })
            if (players.includes(profile()?.id)) {
                this.setState({ inMatchRole: 'Player' })
            }
        }
    }

    getStageDetails = (stageIndex) => {
        updateLoader(true)
        this.MatchesApis.getStageDetails(this.props.tournamentId, stageIndex).then(
            (res) => {
                updateLoader(false)
                const stage = res.data.result
                if (stage?.bracketType === 'DOUBLE' && stage?.type === 'DUEL' && stage?.subType === "Bracket Groups") {
                    let selectedMatches = res.data.result.groups[0][this.state.groupIndex][this.state.roundIndex]
                    this.setState({ stageDetails: res.data.result, selectedMatches: selectedMatches, isDoubleBracket: true, doubleBracketData: res.data.result })

                } else {
                    let selectedMatches = res.data.result.groups[this.state.groupIndex][this.state.roundIndex]
                    this.setState({ stageDetails: res.data.result, selectedMatches: selectedMatches, isDoubleBracket: false, doubleBracketData: [] })
                }
            }
        ).catch(
            (err) => {
                updateLoader(false)
            }
        )
    }

    createFinalResult = (matchDetails) => {
        var participants = [];
        matchDetails?.participants?.forEach((participant, index) => {
            if (participant){
            console.log('participant--',participant);
            participant['result'] = [];
            participant['finalResult'] = {};
            var result = []
            matchDetails.results.forEach((res, i) => {
                var r = matchDetails.results[i][index];
                result[i] = r;
            });
            participant.finalResult = matchDetails.finalResult[index];
            participant.result = result;
            participants.push(participant);
            }
        });
        let sortedResult = participants.sort((a, b) => parseFloat(b.finalResult.score) - parseFloat(a.finalResult.score));
        // console.log("$$$$ participants :: ", participants);
        // console.log("$$$$ sortedResult :: ", sortedResult);
        this.setState({ sortedParticipants: sortedResult })
    }

    triggerInputFile = () => this.fileInput.click();

    OnclickRaisedDispute = () => {
        this.setState({
            openRaisedDispute: !this.state.openRaisedDispute
        })
    }

    matchResult = (matchDetails, i, selectedMatchVersion) => {
        this.createFinalResult(matchDetails);
        if (matchDetails.participants?.length) {
            const selectMatch = this.state.selectMatch;
            this.setState({
                selectMatch: !selectMatch,
                matchDetails: matchDetails ? matchDetails : null,
                selectedMatchIndex: i,
                selectedMatchVersion: selectedMatchVersion
            });
            this.checkRoleInMatch(matchDetails)
        }
    }

    selectStage = (index) => {
        this.setState({ stageIndex: index, groupIndex: 0, roundIndex: 1 })
        this.getStageDetails(index)
    }

    selectGroup = (index) => {
        let selectedMatches;
        if (this.state.stageDetails.bracketType === 'DOUBLE' && this.state.stageDetails.type === 'DUEL' && this.state.stageDetails.subType === "Bracket Groups") {
            selectedMatches = this.state.stageDetails?.groups[this.state.bracketType === 'Winners' ? 0 : 1][index][1]
        } else {
            selectedMatches = this.state.stageDetails?.groups[index][1]
        }
        this.setState({ groupIndex: index, roundIndex: 1, selectedMatches: selectedMatches })
    }

    selectRound = (index) => {
        let selectedMatches;
        if (this.state.stageDetails.bracketType === 'DOUBLE' && this.state.stageDetails.type === 'DUEL' && this.state.stageDetails.subType === "Bracket Groups") {
            selectedMatches = this.state.stageDetails?.groups[this.state.bracketType === 'Winners' ? 0 : 1][this.state.groupIndex][index]
        } else {
            selectedMatches = this.state.stageDetails?.groups[this.state.groupIndex][index]
        }
        this.setState({ roundIndex: index, selectedMatches: selectedMatches })
    }

    selectBracket = (type) => {
        this.setState({ bracketType: type })
        let data = this.state.doubleBracketData.groups[type === 'Winners' ? 0 : 1][this.state.groupIndex][this.state.roundIndex]
        this.setState({ selectedMatches: data })
    }

    convertDate = (date) => {
        const matchTime = date
        const scheduledTime = new Date(matchTime)
        const matchDateTime = getMonthAndDate(date)
        const matchYear = scheduledTime.getFullYear()
        return `${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`
    }

    uploadImages = (event) => {
        if (event.target.files?.length && event.target.files?.length < 6 && this.state.disputImages?.length < 6) {
            const formData = new FormData();
            for (let i = 0; i < event.target.files?.length; i++) {
                formData.append(i, event.target.files[i])
            }

            this.commonApi.uploadMultipleImg(formData)
                .then((response) => {
                    let disputImages = this.state.disputImages
                    for (let i = 0; i < response.data.result?.length; i++) {
                        if (disputImages?.length < 6) {
                            disputImages.push(response.data.result[i])
                        }
                    }
                    this.setState({
                        disputImages: disputImages
                    });
                })
                .catch((err) => console.log(err));
        }
    }

    removeImg = (i) => {
        let images = this.state.disputImages
        images.splice(i, 1)
        this.setState({ disputImages: images })
    }

    riseDispute = (values) => {
        const data = {
            disputeFrom: profile()?.id,
            disputeTitle: values.disputeTitle,
            dispute: values.disputeDescription,
            date: new Date(),
            images: this.state.disputImages,
            disputeStatus: DisputeStatus.DISPUTERAISED,
            matchId: this.state.matchDetails._id,
            tournamentId: this.props.tournamentId,
            tournamentName: this.props.tournamentDetails.name,
            gameId: this.props.tournamentDetails.gameId,
            gameName: this.props.tournamentDetails.gameDetails.name,
            tournamentType: this.props.tournamentDetails.competitionType,
            stageName: this.state.stageIndex,
            round: this.state.roundIndex,
            matchName: ''
        }

        updateLoader(true)
        this.MatchesApis.addDispute(this.state.matchDetails?._id, data).then((res) => {
            updateLoader(false)
            toast.success("Dispute raised");
            this.sendDisputesNotificationToPlayersAndOperators(this.state.matchDetails);
            this.setState({ openRaisedDispute: false, disputImages: [] })
        }).catch(
        (err) => {
            updateLoader(false);
            this.sendDisputesNotificationToPlayersAndOperators(this.state.matchDetails);
            errorHandler(err)
        });
    }

    // Send dispute has been raised notification to TO and Participants
    sendDisputesNotificationToPlayersAndOperators = (matchDetails) => {

        try{
            
            // For Tournament Operators :
            var operators = this.props.tournamentDetails?.organizationDetails?.members?.owner.concat(this.props.tournamentDetails?.organizationDetails?.members?.admin)
        
            var operatorsNotification = {
                type : "TOURNAMENT",
                heading : "Dispute Alert ⚠️",
                message : `A dispute has been raised on Match ${this.state.selectedMatchVersion} of Tournament '${this.props.tournamentDetails?.name}' click here to resolve dispute.`,
                link : `/organization/${this.props.tournamentDetails?.organizationDetails?._id}/tournament/${this.props.tournamentDetails?._id}/overview/dispute-management/${this.state.matchDetails?._id}`
            };

            createAndSendNotification(operatorsNotification, [...new Set(operators)]);

            // For Match Participants :  
            var teamIds = getParticipantsIds(this.props.tournamentDetails.participantType, matchDetails.participants);

            var playersNotification = {
                type : "TOURNAMENT",
                heading : "Dispute Alert ⚠️",
                message : `A dispute has been raised on Match ${this.state.selectedMatchVersion} of Tournament '${this.props.tournamentDetails?.name}' that you have participated. Click here to know more details.`,
                link : null
            };

            if(this.props.tournamentDetails.participantType === ParticipantType.SINGLE){
                createAndSendNotification(playersNotification, teamIds)
            }else{
                updateLoader(true);
                this.teamApis.getParticipantDetails({teamIds : teamIds}).then((res) => {
                    createAndSendNotification(playersNotification, res.data['result'])
                    updateLoader(false);
                }).catch((err) => {
                    updateLoader(false);
                    errorHandler(err);
                });
            }

        }catch(error){
            console.error(error?.message)
        }

    }

    noOfGameWon = (matchIndx, playerIndx) => {
        let count = 0
        let matches = this.state.stageDetails.groups[this.state.groupIndex][this.state.roundIndex]
        matches[matchIndx]?.results?.map((el, i) => {
            if (el[playerIndx]?.isWinner) {
                count = count + 1
            }
        })
        return count
    }

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            return el.profilePicture?.url
        } else {
            return el.logo.url
        }
    }

    updateDispute = (e, key) => {
        e.preventDefault();
        this.setState({[key] : e.target.value});
    }

    render() {

        return (
            <section className='tournament-Latest-page text-white'>
                {
                    this.state?.stageDetails &&

                    <div className='d-flex my-2 matches-btn mb-3 justify-content-around' style={{ flexWrap: 'wrap', fontSize: "10px !important" }}>
                        <SplitButton as={ButtonGroup} key={0} id={`dropdown-button-drop-${2}`} size="sm" title={`Stage ${this.state.stageIndex + 1}`} className='mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                            {this.state.tournamentDetails?.stages.map((stage, index) => {
                                return <Dropdown.Item eventKey={index} key={index} onClick={() => this.selectStage(index)} >Stage {index + 1}</Dropdown.Item>
                            })
                            }
                        </SplitButton>

                        {
                            this.state.isDoubleBracket &&
                            <SplitButton as={ButtonGroup} key={1} id={`dropdown-button-drop-${2}`} size="sm" title={this.state.bracketType} className='mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                                <Dropdown.Item eventKey={1} onClick={() => this.selectBracket('Winners')}>Winners</Dropdown.Item>
                                <Dropdown.Item eventKey={2} onClick={() => this.selectBracket('Loosers')}>Loosers</Dropdown.Item>
                            </SplitButton>
                        }
                        {
                            this.state.isDoubleBracket ?
                                <SplitButton as={ButtonGroup} key={2} id={`dropdown-button-drop-${2}`} size="sm" title={`Group ${this.state.groupIndex + 1}`} className='mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                                    {this.state.stageDetails?.groups[this.state.bracketType === 'Winners' ? 0 : 1]?.map((group, index) => {
                                        return <Dropdown.Item eventKey={index} key={index} onClick={() => this.selectGroup(index)}>Group {index + 1}</Dropdown.Item>
                                    })
                                    }
                                </SplitButton>
                                :
                                <SplitButton as={ButtonGroup} key={2} id={`dropdown-button-drop-${2}`} size="sm" title={`Group ${this.state.groupIndex + 1}`} className='mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                                    {this.state.stageDetails?.groups?.map((group, index) => {
                                        return <Dropdown.Item eventKey={index} key={index} onClick={() => this.selectGroup(index)}>Group {index + 1}</Dropdown.Item>
                                    })
                                    }
                                </SplitButton>
                        }
                        {
                            this.state.isDoubleBracket ?
                                <div className='mt-2'>
                                    <SplitButton as={ButtonGroup} key={3} id={`dropdown-button-drop-${3}`} size="sm" variant="primary" title={`Round ${this.state.roundIndex}`} className='mx-2 my-1 flex-shrink-0 filter-btn-sm'>
                                        {
                                            Object.keys(this.state?.stageDetails?.groups[this.state.bracketType === 'Winners' ? 0 : 1][this.state?.groupIndex])?.map((param, index) => {
                                                return <Dropdown.Item eventKey={param} key={param} onClick={() => this.selectRound(index + 1)}>Round {param}</Dropdown.Item>
                                            })
                                        }
                                    </SplitButton>
                                </div>
                                :
                                <SplitButton as={ButtonGroup} key={3} id={`dropdown-button-drop-${3}`} size="sm" title={`Round ${this.state.roundIndex}`} className='mx-2 my-1 flex-shrink-0 filter-btn-sm' >
                                    {
                                        Object.keys(this.state?.stageDetails?.groups[this.state?.groupIndex])?.map((param, index) => {
                                            return <Dropdown.Item eventKey={param} key={param} onClick={() => this.selectRound(index + 1)}>Round {param}</Dropdown.Item>
                                        })
                                    }
                                </SplitButton>
                        }
                    </div>
                }
                <div className='container'>
                    <div className="row">

                        {this.state.selectMatch ?
                            <div className="col-12 mt-2">
                                <span className='d-flex'>
                                    <img src={latestResultIc} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>{this.state?.stageDetails?.name}</h1>
                                </span>
                                {
                                    this.state?.stageDetails?.type === 'DUEL' ?
                                        <div className='row'>
                                            {
                                                this.state.selectedMatches.map((el, i) => {
                                                    const matchTime = el?.matchSchedule
                                                    const scheduledTime = new Date(matchTime)
                                                    const matchDateTime = getMonthAndDate(el?.matchSchedule)
                                                    const matchYear = scheduledTime.getFullYear()
                                                    return (
                                                        <div className='col-12 col-md-6 col-lg-4' key={i}>
                                                            <div className='grey-card mt-2 p-3'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>
                                                                        <h1 className='fs-16 fw-700'>{el?.status} <span className='fs-12 fw-bold'>( M {this.state.roundIndex}.{i + 1} )</span></h1>
                                                                        <h1 className='fs-12 fw-700 text-white-50'>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h1>
                                                                    </span>
                                                                    {
                                                                        el?.participants?.length ?
                                                                            <button className='green-round-btn rounded-circle fw-600 border border-2 border-dark d-flex justify-content-center align-items-center btn-size-40' onClick={() => { this.matchResult(el, i,`${this.state.roundIndex}.${i + 1}`) }}>
                                                                                <img src={clipBoardList} className="" alt='' style={{ width: '20px' }} />
                                                                            </button> : null
                                                                    }
                                                                </div>
                                                                <div className='row justify-content-around text-center mt-2'>
                                                                    <div className='col-5'>
                                                                        <img src={el?.participants[0] ? this.getURL(el.participants[0]) : participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                                        <h5 className='fs-14 text-white fw-500 mt-2 text-truncate'>{el?.participants[0] ? this.state.tournamentDetails?.participantType === 'SINGLE' ? el?.participants[0]?.inGameName : el?.participants[0]?.name : 'Not Joined'}</h5>
                                                                    </div>
                                                                    <div className='col-2 p-0'>
                                                                        <h5 className='fs-22 text-white fw-500 mt-1'>{this.noOfGameWon(i, 0)} - {this.noOfGameWon(i, 1)}</h5>
                                                                    </div>
                                                                    <div className='col-5'>
                                                                        <img src={el?.participants[1] ? this.getURL(el.participants[1]) : participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                                        <h5 className='fs-14 text-white fw-500 mt-2 text-truncate'>{el?.participants[1] ? this.state.tournamentDetails?.participantType === 'SINGLE' ? el?.participants[1]?.inGameName : el?.participants[1]?.name : 'Not Joined'}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='row'>
                                            {
                                                this.state.selectedMatches.map((el, i) => {
                                                    const matchTime = el?.matchSchedule
                                                    const scheduledTime = new Date(matchTime)
                                                    const matchDateTime = getMonthAndDate(el?.matchSchedule)
                                                    const matchYear = scheduledTime.getFullYear()
                                                    return (
                                                        <div className='col-12 col-md-6 col-lg-4' key={i}>
                                                            <div className='grey-card mt-2 p-3'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>
                                                                        <h1 className='fs-16 fw-700'>{el?.status} <span className='fs-12 fw-bold'>( M {this.state.roundIndex}.{i + 1} )</span></h1>
                                                                        <h1 className='fs-12 fw-700 text-white-50'>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h1>
                                                                    </span>
                                                                    {
                                                                        el?.participants?.length ?
                                                                            <button className='green-round-btn rounded-circle fw-600 border border-2 border-dark d-flex justify-content-center align-items-center btn-size-40' onClick={() => { this.matchResult(el, i,`${this.state.roundIndex}.${i + 1}`) }}>
                                                                                <img src={clipBoardList} className="" alt='' style={{ width: '20px' }} />
                                                                            </button> : null
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-between text-center mt-2'>
                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                        {
                                                                            el?.resultAdded ? null :
                                                                                <div className='d-flex'>
                                                                                    <img src={participantAvtar} className="rounded-circle btn-size-32 me-2" alt='' />
                                                                                    <h5 className='fs-14 text-white fw-500 mt-2'>Winner yet TBD</h5>
                                                                                </div>
                                                                        }

                                                                    </div>
                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                        <HiUserGroup className='fs-20 text-white-50 me-2 ' />
                                                                        <p className='text-white-50 fw-500 mt-1 mb-0'>{el.participants?.length}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>

                            :

                            <div className="col-12 mt-2 p-0">
                                <span className='d-flex ms-2'>
                                    <MdArrowBackIosNew onClick={() => { this.setState({ selectMatch: !this.state.selectMatch, selectedMatchVersion: null }) }} /><h1 className='fs-16 fw-700 ms-2'>Let It Go <span className='fs-12 fw-bold text-white-50'>( M {this.state.selectedMatchVersion} )</span></h1>
                                </span>
                                <div className='row pt-3'>
                                    {
                                        this.state?.stageDetails?.type === 'DUEL' ?
                                            <div className='col-lg-6 col-12 grey-card-shadow text-center d-flex justify-content-around align-items-end pt-3'>
                                                <div className=''>
                                                    {
                                                        this.state.matchDetails?.finalResult[0].isWinner ?
                                                            <span className='winner-shadow' style={{ marginTop: '5px' }}></span> : null
                                                    }
                                                    <span className='d-flex flex-column align-items-center'>
                                                        {
                                                            this.state.matchDetails?.finalResult[0].isWinner ?
                                                                <img src={winIc} className="rounded-circle btn-size-20" alt='' style={{ zIndex: '1' }} /> : null
                                                        }
                                                        <img src={this.state.matchDetails?.participants[0] ? this.getURL(this.state.matchDetails?.participants[0]) : participantAvtar} className="rounded-circle btn-size-52 mt-2" alt='' style={{ zIndex: '1' }} />
                                                    </span>

                                                    <h5 className='text-white fw-500 fs-16 mt-2'>{this.state.matchDetails?.participants[0]?.name}</h5>
                                                    {/* <p className='fs-12 fw-600 text-white-50 mb-0'>
                                                        {this.state.matchDetails?.finalResult[0].score}
                                                        0/0/0
                                                    </p> */}
                                                    <p className='fs-12 fw-600 text-white-50'>{this.state.matchDetails?.finalResult[0].score}</p>
                                                </div>
                                                <div className=''>
                                                    <h5 className='text-white fw-500 fs-30 mt-1 mb-3'>{this.noOfGameWon(this.state.selectedMatchIndex, 0)} - {this.noOfGameWon(this.state.selectedMatchIndex, 1)}</h5>
                                                    {/* <p className='text-white-50 fw-bold mb-0 pt-3 fs-12'>K/D/A</p> */}
                                                    <p className='text-white-50 fw-bold mb-3 fs-12'>Score</p>
                                                </div>
                                                <div className=''>
                                                    {
                                                        this.state.matchDetails?.finalResult[1].isWinner ?
                                                            <span className='winner-shadow' style={{ marginTop: '5px' }}></span> : null
                                                    }
                                                    <span className='d-flex flex-column align-items-center'>
                                                        {
                                                            this.state.matchDetails?.finalResult[1].isWinner ?
                                                                <img src={winIc} className="rounded-circle btn-size-20" alt='' style={{ zIndex: '1' }} /> : null
                                                        }
                                                        <img src={this.state.matchDetails?.participants[1] ? this.getURL(this.state.matchDetails?.participants[1]) : participantAvtar} className="rounded-circle btn-size-52 mt-2" alt='' style={{ zIndex: '1' }} />
                                                    </span>

                                                    {/* <img src={participantAvtar} className="rounded-circle w-50 mt-2" alt='' style={{ zIndex: '1' }} /> */}

                                                    <h5 className='text-white fw-500 fs-16 mt-2'>{this.state.matchDetails?.participants[1]?.name}</h5>
                                                    {/* <p className='fs-12 fw-600 text-white-50 mb-0'>0/0/0</p> */}
                                                    <p className='fs-12 fw-600 text-white-50'>{this.state.matchDetails?.finalResult[1].score}</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-12 mt-2">
                                                <div className='' style={{ overflowX: 'auto' }}>
                                                    <table className="table grey-card"
                                                    // style={{ width: 'auto' }}
                                                    >
                                                        <thead>
                                                            <tr className='text-warning fs-16 fw-500'>
                                                                <th scope="col" className='fw-600 text-center'>Rank</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col" className='fw-600 text-center' style={{ minWidth: '100px' }}>Score</th>
                                                                {
                                                                    this.state.matchDetails?.results.map((el, j) => {
                                                                        return (
                                                                            <th key={j} scope="col" className='fw-600 text-center' style={{ minWidth: '100px' }}>Game {j + 1}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody className='table-borderless text-white '>
                                                            {
                                                                this.state.sortedParticipants.map((el, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th scope="row" className='fw-500 text-center'>{i + 1}</th>
                                                                            <td className='fw-600' >
                                                                                {/* <span className='rank-shadow rank-1-shadow'></span> */}
                                                                                {el?.name}</td>
                                                                            <td className='text-center'>{el?.finalResult.score}</td>
                                                                            {
                                                                                el?.result.map((td, j) => {
                                                                                    return (
                                                                                        <td key={j} className='text-center'>{td.score}</td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    }


                                    <div className='col-lg-6 col-12 px-4 text-center'>
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>TOURNAMENT :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>{this.state.tournamentDetails?.name}</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>DATE :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>{this.convertDate(this.state.matchDetails?.matchSchedule)}</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>STATUS :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>{this.state.matchDetails?.status} </h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>GAME :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>{this.state.tournamentDetails?.gameDetails?.name}</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>STAGE :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>{this.state.stageDetails.name}</h6>
                                            </div>
                                            <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>ROUND :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>Round {this.state.roundIndex}</h6>
                                            </div>
                                            {/* <div className='col-12 d-flex justify-content-between mt-2'>
                                                <h6 className='text-white fw-bold mb-0 fs-12'>MATCH :</h6>
                                                <h6 className='text-white-50 fw-bold mb-0 fs-12'>M 2.1</h6>
                                            </div> */}
                                        </div>
                                    </div>


                                    {
                                        this.state.inMatchRole &&
                                        <div className='col-12 d-flex justify-content-end'>
                                            <div className='position-fixed me-2' style={{ bottom: '60px', zIndex: '1' }}>
                                                <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark me-3 btn-size-40'
                                                    onClick={this.OnclickRaisedDispute}>
                                                    <img src={greenDispute} className="react-btn " alt='' width={'20px'} />
                                                </button>
                                                {
                                                    !this.state.matchDetails.chatEnabled &&
                                                    <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40' onClick={() => { this.props.history.push(`/tournament/${this.state.tournamentId}/chatlobby/${this.props.tournamentDetails?.participantType}/${this.state.matchDetails?._id}`) }}>
                                                        <img src={greenChat} className="react-btn " alt='' width={'20px'} />
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    }

                                </div>

                            </div>
                        }

                    </div>
                </div>


                <Modal centered show={this.state.openRaisedDispute} dialogClassName="raised-dispute-modal grey-modal-bg">
                    <Modal.Body>
                        <div className='pt-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className="fw-bold fs-20 text-center text-white mb-0 position-absolute" style={{ right: '30%' }}>Raise a Dispute</p>
                                    <AiFillCloseCircle className="btn-size-20 text-white float-end me-3 mt-1" onClick={this.OnclickRaisedDispute} />
                                </div>
                            </div>

                            <Formik
                                initialValues={{
                                    disputeTitle: '',
                                    disputeDescription: '',
                                    // disputeImages: []

                                }}
                                validationSchema={Yup.object({
                                    disputeTitle: Yup.string().required('Dispute title is Required').max(20, 'Max 20 characters'),
                                    disputeDescription: Yup.string().required('Dispute Description is Required').max(150, 'Max 150 characters'),
                                    // disputeImages: Yup.array().required('Dispute image is Required').min(1, "select at least 1 file").max(5, "select max 5 file")
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.riseDispute(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                <Form className="mx-3" autoComplete="off">

                                    <div className="form-group mt-3">
                                        <span className='d-flex justify-content-between'>
                                            <label htmlFor="disputeTitle" className='text-white fs-16 fw-600'>Title</label>
                                            <p className='text-white-50 fw-bold mb-0 fs-12'>{this.state.disputeTitle.length} / 20</p>
                                        </span>
                                        <Field name="disputeTitle" type="text" className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" placeholder="Enter Dispute Title" onKeyUp={(e) => this.updateDispute(e, 'disputeTitle')} maxLength={20} />
                                        <ErrorMessage component="span" className="error-msg" name="disputeTitle" />
                                    </div>

                                    <div className="form-group mt-3">
                                        <span className='d-flex justify-content-between'>
                                            <label htmlFor="disputeDescription" className='text-white fs-16 fw-600'>Dispute</label>
                                            <p className='text-white-50 fw-bold mb-0 fs-12'>{this.state.disputeDescription.length} / 150</p>
                                        </span>
                                        <Field name="disputeDescription" type="text" className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" placeholder="Enter Dispute here..." onKeyUp={(e) => this.updateDispute(e, 'disputeDescription')} maxLength={150} />
                                        <ErrorMessage component="span" className="error-msg" name="disputeDescription" />
                                    </div>

                                    <div className="form-group mt-3">
                                        <span className='d-flex justify-content-between'>
                                            <label htmlFor="disputeImages" className='text-white fs-16 fw-600'>Upload Screenshot</label>
                                            <p className='text-white-50 fw-bold mb-0 fs-12'>(max 5)</p>
                                        </span>
                                        <div className='d-flex mt-2'>
                                            {
                                                this.state.disputImages?.map((el, i) => {
                                                    return (
                                                        <span key={i}>
                                                            <AiFillCloseCircle className="btn-size-15 position-absolute text-white" style={{ marginTop: '-8px' }} onClick={() => { this.removeImg(i) }} />
                                                            <img src={el?.url} alt='' className='mx-2' height="50px" width="50px" />
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="dispute-upload-section d-flex align-items-center justify-content-center mt-1">
                                            <input
                                                name="disputeImages"
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                id="upload_cover_file"
                                                hidden
                                                multiple
                                                ref={fileInput => this.fileInput = fileInput}
                                                onChange={this.uploadImages}
                                            />
                                            <div className="" onClick={this.triggerInputFile}>
                                                <img src={fileAttach} alt='' className='btn-size-20' />
                                            </div>
                                        </div>
                                        <ErrorMessage component="span" className="error-msg" name="disputeImages" />
                                    </div>

                                    <button type="submit" className="flat-btn border-0 fw-600 mt-3 d-flex mx-auto mb-3 px-2 py-1">Submit</button>
                                </Form>
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}
