import React, { Component } from 'react';
import { getMonthAndDate, linkWithDiscord, saveCloseRegistration, showHideMatchInvite } from '../../../../../../utils/common.utils';
import { HiUserAdd } from "react-icons/hi";
import { Modal } from "react-bootstrap";
import MatchInvite from "../../../../../popup/MatchInvite";
import AcceptInvite from '../../../../../popup/AcceptInvite';
import { addParticipantInTournament, getMyTeams, getStageDetails } from '../../../../../../utils/Tournament.utils';
import { toast } from 'react-toastify';
import Registration from '../../../Registration';
import winCup from '../../../../../../images/icon/winCup.png';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import { profile } from '../../../../../../utils/localstorage.utils';
import { ERROR } from '../../../../../../utils/Strings.utils';
import Scoreboard from '../../../Scoreboard';

export default class FFASingleEliminationMatches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stageDetails: props?.stageDetails ? props.stageDetails : null,
            tournamentDetails: props?.tournamentDetails ? props.tournamentDetails : null,
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null,
            showRegistrationScreen: false,
            matchIndex: '0.0',
            showScoreBoard: false,
            matchDetails: null,
            matchNo: null
        };

        this.enableLoading = false;

    }

    componentDidMount() {
        saveCloseRegistration(this.closeRegistrationScreen);
    }

    componentWillReceiveProps(newProps) {
        if (!this.enableLoading) {
            this.setState({
                stageDetails: newProps?.stageDetails ? newProps.stageDetails : this.state.stageDetails,
                tournamentDetails: newProps?.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,
            })
        }
    }

    // START :: Tournament Registration  Process
    registerForTournament = (e, matchDetail, matchIndex) => {
        e.preventDefault();
        if (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true && this.state.tournamentDetails?.participantType === 'TEAM') {
            this.switchRegistrationScreens(matchDetail, matchIndex);
        } else {

            this.enableLoading = true;
            if (this.state.tournamentDetails?.participantType === 'SINGLE') {
                this.setState({
                    initiateRegistration: true,
                    selectedMatchDetail: matchDetail,
                    selectedMatchIndex: matchIndex
                });
            } else {
                this.setState({
                    selectedMatchDetail: matchDetail
                });
                getMyTeams(this.state.tournamentDetails.gameId, matchDetail, this.addParticipantInTournament, this.selectTeams)
            }
        }
    }

    selectTeams = (success, data) => {
        if (success) {
            this.setState({
                teamsArray: data.teamsArray,
                initiateRegistration: true,
                selectedMatchDetail: data.selectedMatchDetail
            });
        } else {
            toast.warning(ERROR.NO_TEAM_FOR_TOURNAMENT);
        }

    }

    addParticipantInTournament = (matchId, teamId) => {
        console.log(`\n this.state.selectedMatchDetail._id :: ${this.state.selectedMatchDetail._id},\n
        teamId :: ${teamId},\n
        this.state.stageDetails :: ${this.state.stageDetails},\n
        this.state.tournamentDetails :: ${this.state.tournamentDetails}
        `);
        if (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true) {
            this.switchRegistrationScreens(this.state.selectedMatchDetail, this.state.selectedMatchIndex, teamId);
            this.resetRegistration();
        } else {
            addParticipantInTournament(this.updateStageDetails, this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails);
        }

    }

    onClickOpenInvite = (joinTeamId) => {
        this.setState({
            initiateRegistration: !this.state.initiateRegistration
        });
        if (joinTeamId) {
            addParticipantInTournament(this.updateStageDetails, this.state.selectedMatchDetail?._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
        }
        this.resetRegistration();
    }

    updateStageDetails = (response) => {
        this.setState({
            stageDetails: response,
        });
        this.resetRegistration();
    }

    resetRegistration = () => {
        this.setState({
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null
        });
        //this.enableLoading = false;
    }


    switchRegistrationScreens = (matchDetail, matchIndex, teamId) => {
        this.setState({
            showRegistrationScreen: !this.state.showRegistrationScreen,
            selectedMatchDetail: matchDetail,
            matchIndex: matchIndex,
            selectedTeams: (teamId ? teamId : null)
        });
    }

    // END :: Tournament Registration Process

    closeRegistrationScreen = () => {
        getStageDetails(this.state.tournamentDetails?._id, this.props.index, this.setData)
    }

    setData = (values) => {
        if (values) {
            this.setState({
                stageDetails: values,
                showRegistrationScreen: false
            });
        } else {
            this.setState({
                showRegistrationScreen: false
            });
        }
    }

    onClickRegister = () => {

        if (profile()?.discordProfile?.discordUsername) {
            return (
                <Modal.Body>
                    <AcceptInvite
                        clearInvitations={this.resetRegistration}
                        tournamentId={this.state.tournamentDetails?._id}
                        addParticipantInTournament={this.addParticipantInTournament}
                        joinTeamRole={null}
                        close={this.resetRegistration}
                        type="add"
                        info={{}}
                        title='Register'
                        role='player'
                        matchId={this.state.selectedMatchDetail?._id}
                        isTournamentInvitation={true}
                    />
                </Modal.Body>
            );
        } else {
            return (
                <Modal.Body>
                    <ConfirmationModal confirm={linkWithDiscord()} onClickOpenConfirm={this.resetRegistration} confirmed={this.confirmed} />
                </Modal.Body>
            );
        }
    }

    confirmed = () => {
        this.resetRegistration();
        this.props.history.push('/profile');
    }

    // hideAndShowScoreBoard = (matchDetails) => {
    //     this.setState({
    //         showScoreBoard: !this.state.showScoreBoard,
    //         matchDetails: !this.state.showScoreBoard === true ? matchDetails : null
    //     });
    // }

    hideAndShowScoreBoard = (matchDetails, matchNo) => {
        if (matchDetails?.resultAdded) {
            this.setState({
                showScoreBoard: !this.state.showScoreBoard,
                matchDetails: !this.state.showScoreBoard === true ? matchDetails : null,
                matchNo
            });
        }
    }


    render() {

        if (this.state.stageDetails) {
            if (this.state.showRegistrationScreen) {
                return (
                    <Registration
                        tournamentDetails={this.state.tournamentDetails}
                        stageDetails={this.state.stageDetails}
                        selectedMatchDetail={this.state.selectedMatchDetail}
                        matchIndex={this.state.matchIndex}
                        selectedTeams={this.state.selectedTeams}
                    />
                )
            } else {
                if (this.state.showScoreBoard) {
                    return (
                        <Scoreboard
                            matchDetails={this.state.matchDetails}
                            hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                            tournamentDetails={this.state.tournamentDetails}
                            matchNo={this.state.matchNo}
                        />
                    )
                } else {

                    const isTeamPlayer = (this.state.tournamentDetails?.participantType === "TEAM" ? true : false);
                    return (
                        <div id='tournament-date'>
                            {/* Brackets Structure */}
                            <div className='w-100 d-flex overflow-auto' style={{ height: '-webkit-fill-available' }}>
                                <div className='' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow: 'none', width: 'fit-content' }}>
                                    {
                                        Object.keys(this.state.stageDetails.groups[0]).map((key, i) => {
                                            return (
                                                <div className="py-1 px-3" key={key}>
                                                    <button type="button" className={`green-olive-card rounded-1 text-warning score-showdown-btn me-2 pt-1 rounds mt-2`}>ROUND {key}</button>
                                                    <div className='d-flex overflow-auto my-3'>
                                                        {
                                                            this.state.stageDetails?.groups[0][key]?.map((match, index) => {
                                                                if (typeof match === 'object') {

                                                                    var dateAndTime = getMonthAndDate(match.matchSchedule);
                                                                    var players = [];

                                                                    for (let j = 0; j < this.state.stageDetails.maxParticipantsPerMatch; j++) {

                                                                        var player = match.participants[j] ? match.participants[j] : null;

                                                                        if (match.resultAdded && player && player !== undefined) {
                                                                            player.isWinner = match?.finalResult[j]?.isWinner;
                                                                        }

                                                                        players.push(player);

                                                                    }
                                                                    const canParticipate = showHideMatchInvite(match, this.state.tournamentDetails);

                                                                    const matchNo = `M ${key}.${index + 1}`;
                                                                    return (
                                                                        <div onClick={() => this.hideAndShowScoreBoard(match, matchNo)} key={`${key}.${index + 1}`} className="tournament-btn green-flat-btn d-flex flex-column justify-content-start align-items-start flex-shrink-0 me-2 pb-2 mt-2" style={{ height: 'auto', width: '320px' }} >
                                                                            <span className="match-tag bg-black text-warning">Match {key}.{index + 1}</span>
                                                                            <div className="row ms-0 w-100 mt-1 justify-content-between">

                                                                                <div className="col-5">
                                                                                    <div className="d-flex flex-column">
                                                                                        {players.map((player, ind) => {
                                                                                            if (player !== null) {
                                                                                                return (
                                                                                                    <div className="d-flex align-items-center" key={ind}>
                                                                                                        <span className="player-name my-1 text-black text-truncate" key={ind}>
                                                                                                            <img src={isTeamPlayer ? player?.logo?.url : player?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt='' />
                                                                                                            {isTeamPlayer ? player.name : player.inGameName}
                                                                                                        </span>
                                                                                                        {match.resultAdded && player.isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt='' /> : null}
                                                                                                    </div>
                                                                                                );
                                                                                            } else {
                                                                                                return <span key={ind} className="fs-12 fw-bold text-white my-1">Vacant</span>
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {(key == 1 && canParticipate) ?
                                                                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                                                                        {((this.state.stageDetails.level !== 1) || (match.participants.length === this.state.stageDetails.maxParticipantsPerMatch)) ? null :
                                                                                            <button onClick={(e) => this.registerForTournament(e, match, `1.${(index + 1)}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-40 mx-2" >
                                                                                                <HiUserAdd className="btn-size-15" />
                                                                                            </button>
                                                                                        }

                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }

                                                                                <div className="col-4 d-flex align-items-center justify-content-end">
                                                                                    <div className="d-flex flex-column float-end">
                                                                                        <span className="fs-12 fw-bold text-black my-1">{dateAndTime[0]}</span>
                                                                                        <span className="fs-12 fw-bold text-black">{dateAndTime[1]}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                            })
                                                        }
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            </div>

                            {/* Tournament Invitation Section */}
                            <Modal centered show={this.state.initiateRegistration} dialogClassName="ClanInvite-modal">
                                {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                                    this.onClickRegister()
                                    :
                                    <Modal.Body>
                                        <MatchInvite
                                            onClickOpenInvite={this.onClickOpenInvite}
                                            teamsArray={this.state.teamsArray}
                                            choice="Join Tournament"
                                            isRoundRobin={false}
                                        />
                                    </Modal.Body>
                                }
                            </Modal>
                        </div>
                    );
                }
            }

        } else {
            return (
                <div>
                    <h1>No Data Found</h1>
                </div>
            );
        }

    }
}