import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import standingIc from '../../../images/icon/standing-ic.png';
import { IoIosCloseCircleOutline, IoMdReturnLeft } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { AiFillPlusCircle } from "react-icons/ai";
import currencyIc from '../../../images/icon/currency-ic.png'
import TournamentHeader from './common/TournamentHeader';
import { errorHandler, getTournamentData, remainingTime } from '../../../utils/common.utils';
import disputeIc from '../../../images/icon/dispute-ic.png';
import TournamentApis from '../../../helper/tournament.api';
import { HiOutlineTrash } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import ConfirmationModal from '../../popup/ConfirmationModal';
const options = [
    { name: 'Swedish', value: 'sv' },
    { name: 'English', value: 'en' },
    {
        type: 'group',
        name: 'Group name',
        items: [
            { name: 'Spanish', value: 'es' },
        ]
    },
];
class FinalStanding extends Component {

    constructor(props) {
        super(props)
        this.tournamentApis = new TournamentApis();
        this.state = {
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : null,
            confirm: {
                msg: '',
                key: ''
            },
            prizeDistributionModal: false,
            leaderBoard: null,
            structure: 'STAGE',
            noOfParticipants: '',
            isParticipant: false
        }
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
    }


    componentDidMount() {
        this.props.showOrHideNav(true);
        this.tournamentId = this.props.match.params.id
        this.getData()
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    setValues = (key, val) => {
        this.setState({ [key]: val })
        if (key === 'noOfParticipants' && val) {
            this.setState({ isParticipant: false })
        }else if(key === 'isParticipant' && val){
            this.setState({ noOfParticipants: '' }) 
        }
    }

    distributePrize = () => {
        const data = {
            tournamentId: this.props.match.params.id
        }
        this.props.updateLoader(true);
        this.tournamentApis.distributePrizeToLeaderboard(data)
            .then(
                (res) => {
                    this.props.updateLoader(false);
                    // this.props.dispatchData(res.data.result)
                    toast.success("Prize distributed successfully");
                    this.props.history.push({
                        pathname: `/organization/${res.data.result.organizationId}/tournament/${this.props.match.params.id}/overview`
                    });
                }
            ).catch(
                (err) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
    }

    getData = () => {
        this.props.updateLoader(true);
        this.tournamentApis.getLeaderBoard(this.props.match.params.id)
            .then(
                (res) => {
                    this.props.updateLoader(false);
                    let data = res?.data?.result[0]
                    this.setState({ structure: data?.leaderBoardType ? data?.leaderBoardType : 'DAY', noOfParticipants: data?.noOfParticipantsInLeaderboard ? data?.noOfParticipantsInLeaderboard : '', isParticipant: data?.noOfParticipantsInLeaderboard ? false : true })
                }
            ).catch(
                (err) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
    }

    submit = (values) => {
        let data = {
            leaderBoardType: values.finalStandingStructure,
            noOfParticipantsInLeaderboard: values.finalStandingParticipants
        }
        
        console.log('values---------', data);

        this.props.updateLoader(true);
        this.tournamentApis.updateLeaderBoard(this.props.match.params.id, data)
            .then(
                (res) => {
                    this.props.updateLoader(false);
                    toast.success("Final standing updated successfully");
                    this.props.history.push({
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview`
                    });
                }
            ).catch(
                (err) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
    }
    render() {
        return (
            <div className="body-section tournament-sponsor">
                <div className="container mt-lg-3">
                    <div className="row p-3">
                        <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={standingIc} heading="Final Standing"
                            subHeading={<h1 className="stage-sub-heading text-center">Time Left: <span className='error-msg'>{remainingTime(this.state.tournamentDetails?.tournamentEndTime)}</span></h1>}
                            history={this.props.history} />

                        <Formik
                            enableReinitialize
                            initialValues={{
                                finalStandingStructure: this.state.structure,
                                finalStandingParticipants: this.state.noOfParticipants,
                                showAllParticipants: this.state.isParticipant,
                            }}
                            validationSchema={Yup.object({
                                finalStandingParticipants: Yup.number().min(0, 'Cannot be less than Zero'),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.submit(values)
                                    setSubmitting(false);
                                }, 400);
                            }}>
                            <Form className="row mt-3" autoComplete="off">
                                {/* Final Standing Structure */}
                                <div className="col-lg-6 my-2">
                                    <label htmlFor='customField' className="fs-14 fw-600 text-white">Final Standing Structure</label>
                                    <div className="">
                                        <Field
                                            name='finalStandingStructure' as='select'
                                            value={this.state.structure}
                                            onChange={(e) => { this.setValues('structure', e.target.value) }}
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 fw-500 height-45"
                                        >
                                            <option value='STAGE'>Stage Wise</option>
                                            <option value='DAY'>Day Wise</option>
                                        </Field>
                                    </div>
                                    <ErrorMessage component="span" name="finalStandingStructure" className="error-msg" />
                                </div>

                                <div className="col-lg-6 my-2">
                                    <label htmlFor='customField' className="fs-14 fw-600 text-white">Participants to be shown in final standing</label>
                                    <div className="">
                                        <Field
                                            name='finalStandingParticipants' type="number" placeholder="Enter a number"
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 fw-500 height-45"
                                            value={this.state.noOfParticipants}
                                            onChange={(e) => { this.setValues('noOfParticipants', e.target.value) }}
                                            min={1} step={1} onWheel={(e) => e.target.blur()}
                                        />
                                    </div>
                                    <ErrorMessage component="span" name="finalStandingParticipants" className="error-msg" />
                                </div>
                                <div className="col-lg-6 my-2">
                                    <Field name='showAllParticipants' type='checkbox' checked={this.state.isParticipant}
                                        onChange={(e) => { this.setValues('isParticipant', e.target.checked) }} />
                                    <label htmlFor='customField' className="fs-14 fw-600 text-white ms-2">Show all participants</label>
                                </div>
                                <button type='submit' className="white-btn-shadow w-100 fw-600 fs-16 px-3 mt-3" style={{ height: '45px' }}>Submit</button>

                            </Form>
                        </Formik>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className='position-fixed d-flex justify-content-center'>
                            <div className='text-center mx-2 mx-md-4 mx-lg-4'>
                                <NavLink to={{ pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview/dispute-management` }} className='mx-4'>
                                    <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0  btn-size-45 m-auto'>
                                        <img src={disputeIc} className='btn-size-25' alt='' />
                                    </span>
                                    <p className="fs-12 fw-bold text-white mt-1">Disputes</p>
                                </NavLink>
                            </div>

                            <div className='text-center align-self-center mx-2 mx-md-4 mx-lg-4'>
                                {/* <NavLink to={{ pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview` }} className='mx-4'> */}
                                <button className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-4' type="button" onClick={() => { this.state.tournamentDetails?.isPrizeDistributed ? toast.warning('Prize is already distributed.') : this.setState({ prizeDistributionModal: true }) }} >
                                    <img src={currencyIc} className='btn-size-25' alt='' />
                                </button>
                                <p className="fs-12 fw-bold text-white mt-1">Prize Release</p>
                                {/* </NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>


                <Modal show={this.state.prizeDistributionModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={{
                                msg: (
                                    <p className="fs-14 fw-600 text-center text-white-50">
                                        Make sure you have updated all match scores and resolved all disputes before prize distribution as you cannot repeat this action again.<br/><br/>
                                        Are you sure you want to distribute prize to seleted leaderboard ?
                                    </p>
                                ),
                                key: "",
                            }}
                            onClickOpenConfirm={() => { this.setState({ prizeDistributionModal: false }) }}
                            confirmed={this.distributePrize}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(FinalStanding)