import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import pastIc from '../../../images/icon/past-ic.png';
import prizeIc from '../../../images/icon/prize-ic.png';

import Dropdown from 'react-bootstrap/Dropdown';
import { GiCrossedSwords } from 'react-icons/gi';
import { FaGlobeAsia } from 'react-icons/fa';
import { IoIosPerson } from 'react-icons/io';
import { SiGooglemaps } from 'react-icons/si';
import { TiGroup } from 'react-icons/ti';
import { FaUserAlt } from "react-icons/fa";
import { AiOutlineLock } from "react-icons/ai";

import ecIc from '../../../images/icon/ec-ic.png';
import desktopIc from '../../../images/icon/desktop-ic.png';
import StarRatingComponent from 'react-star-rating-component';
import { errorHandler, getMonthAndDate } from '../../../utils/common.utils';
import TournamentApis from '../../../helper/tournament.api';

export default class TournamentsRating extends Component {

  constructor(props) {
    super(props)

    this.state = {
      ratings: 0,
      tournaments: [],
      selectedSort: 'Newest'
    }
    this.tournamentApis = new TournamentApis();
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    const data = {
      organizationId: this.props.match.params.orgId,
      sortBy: 'tournamentStartTime',
      orderBy: 'desc'
    }
    this.getData(data)
  }
  getData = (data) => {
    this.props.updateLoader(true);
    this.tournamentApis.getTournaments(data).then(
      (res) => {
        this.setState({ tournaments: res.data.result });
        this.props.updateLoader(false);
      }
    ).catch(
      (err) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }
  convertDate = (date) => {
    const matchTime = date
    const scheduledTime = new Date(matchTime)
    const matchDateTime = getMonthAndDate(date)
    const matchYear = scheduledTime.getFullYear()
    if (matchYear) {
      return `${matchDateTime[0]} ${matchYear}`
    } else {
      return ''
    }
  }
  onRoleChangeHandler = (e) => {
    this.setState({
      selectedSort: e.target.name,
    });
    if (e.target.name === 'Newest') {
      const data = {
        organizationId: this.props.match.params.orgId,
        sortBy: 'tournamentStartTime',
        orderBy: 'desc'
      }
      this.getData(data)
    } else {
      const data = {
        organizationId: this.props.match.params.orgId,
        sortBy: 'tournamentStartTime',
        orderBy: 'asc'
      }
      this.getData(data)
    }
  };
  render() {
    const { ratings } = this.state;
    return (
      <section className="body-section tournament-rating-page">
        <div className="container px-4 pt-3">
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h1 className="fs-20 fw-600 text-white text-center"><img src={pastIc} className='btn-size-20' /> Rating Review</h1>
            <Dropdown className="mb-2 select-game-dropdown">
              <Dropdown.Toggle className='dropdown-basic fs-12 fw-600 px-3 py-2 game-fs team-dropdown-arrow' style={{ width: '100px' }}>{this.state.selectedSort}</Dropdown.Toggle>
              <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                <Dropdown.Item href="#" name='Newest' className='text-white'>Newest</Dropdown.Item>
                <Dropdown.Item href="#" name='Oldest' className='text-white'>Oldest</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className='row'>
            {
              this.state.tournaments.map((el, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className='mulberry-purple-card p-3'>
                      <NavLink key={i} className="" to={{ pathname: `/organization/tournament/ratings/${el._id}` }}>

                        <div className="row ">
                          <div className='col-2'>
                            <p className='fs-12 fw-600 text-info text-center'>{el?.competitionType}</p>
                          </div>
                          <div className='col-8'>
                            <h1 className='fs-20 fw-bold text-white text-center'>{el?.name}</h1>
                            <p className='fs-12 fw-bold text-white-50 text-center text-truncate'>{this.convertDate(el?.tournamentStartTime)}  - {this.convertDate(el?.tournamentEndTime)}</p>
                          </div>
                          <div className='col-2'>
                            {
                              el?.visibility === 'PUBLIC' ?
                                <FaGlobeAsia className='float-end' />
                                :
                                <AiOutlineLock className='float-end' />
                            }
                          </div>
                          <div className='col-6'>
                            {
                              el?.entryFeeAndPrizeDetails?.entryFeeAmount ?
                                <p className='fs-14 fw-600 text-white text-center my-2'><img src={ecIc} className='btn-size-15 me-2' />{el?.entryFeeAndPrizeDetails?.entryFeeAmount} EC</p> : null
                            }
                          </div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white text-center my-2'><img src={desktopIc} className='btn-size-15 me-2' />{el?.gameDetails?.name}</p>
                          </div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white mb-2'><SiGooglemaps className='btn-size-20 text-white me-2' /> Region:</p></div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white-50 text-end mb-2'>{el?.region}</p>
                          </div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white mb-2'> <GiCrossedSwords className='btn-size-20 text-white me-2' />Match Type :</p></div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white-50 text-end mb-2'>
                              {
                                el.participantType === 'SINGLE' ?
                                  <span>
                                    <FaUserAlt className='btn-size-20 text-white-50 me-1' />Vs<FaUserAlt className='btn-size-20 text-white-50 ms-1' /></span> :
                                  <span><TiGroup className='btn-size-20 text-white-50 me-1' />Vs<TiGroup className='btn-size-20 text-white-50 ms-1' /></span>
                              }
                            </p>
                          </div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white mb-2'> <IoIosPerson className='btn-size-20 text-white me-2' />Particiapnts :</p></div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white-50 text-end mb-2'>{el?.participantUserId?.length ? el?.participantUserId.length : '0'} / {el?.maxParticipant}</p>
                          </div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white mb-2'><img src={prizeIc} className='btn-size-15 me-2' /> Prize Pool :</p></div>
                          <div className='col-6'>
                            <p className='fs-14 fw-600 text-white-50 text-end mb-2'>$ {el?.entryFeeAndPrizeDetails?.prizePoolAmount ? el?.entryFeeAndPrizeDetails.prizePoolAmount : 'TBD'}</p>
                          </div>
                          <div className='col-12'>
                            <h1 className='fs-16 fw-600 text-white text-center mb-1'>Overall Rating</h1>
                            <div className='d-flex justify-content-center fs-24'>
                              <StarRatingComponent name="rate1" starCount={5} value={el.overallRating} emptyStarColor="#b4b4b4" editing={false} />
                              <h6 className='text-white fw-bold mb-0 fs-16 align-self-center ms-2'>{ratings}</h6>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                )
              })
            }

          </div>

        </div>
      </section>
    )
  }
}
