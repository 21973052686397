import React, { Component } from 'react';
import ecTicket from '../../../../images/icon/ecTicket-ic.png';
import winCup from '../../../../images/icon/winCup.png';
import betIc from '../../../../images/icon/bet-ic.png';
import ecBetGreen from '../../../../images/icon/ecBetGreen-ic.png';
import ecBetRed from '../../../../images/icon/ecBetRed-ic.png';
import ecBet from '../../../../images/icon/ecBet-ic.png';
import tournamentLogo from '../../../../images/image75.png';
import betButton from '../../../../images/icon/bet-button.png';
import maskIc from '../../../../images/icon/mask-ic.png';
import maskIcSad from '../../../../images/icon/mask-ic-sad.png';
import { BsFillBellFill } from 'react-icons/bs';
import { ASSETS } from '../../../../utils/Strings.utils';
import { Badge } from 'react-bootstrap';

export default class MyBets extends Component {

    constructor(props) {
        super(props)
        this.state = {
            betsDetails: props.betsDetails,
        }

    }

    componentWillReceiveProps(newProps){
        this.setState({
            betsDetails: newProps.betsDetails
        })
    }

    getSelectedOptionDetails = (selectedOption, statementDetails, choices) => {

        if(statementDetails.type === "CUSTOM"){
            return selectedOption
        }else{
            const result = choices.filter(option => option._id === selectedOption)
            return statementDetails.type === "PLAYER" ? result[0].inGameName : result[0].name;
    
        }

    }


    render() {

        return (
            <div className={`${this.props.addRow ? 'row' : ''} my-3`}>
                {
                    this.state.betsDetails?.length ?
                    this.state.betsDetails.map((bet,index) =>{
                        return(
                            <div className='col-12 col-md-6' key={index}>
                                <div className='betting-card mx-2 mb-4 p-3'>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <h1 className='fs-16 fw-700 text-white'>{bet.bettingStatementDetails.bettingStatement.statement}</h1>
                                                </div>
                                                <div className='col-12 my-1'>
                                                    <div className='row'>
                                                        <div className='col-6 pe-0'>
                                                            <div className='d-flex'>
                                                                <img src={ecTicket} className='' width='16' height='16' alt='' />
                                                                <p className='fs-12 fw-700 text-white ms-2'>{bet.bettingStatementDetails.entryFees} EC</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='d-flex'>
                                                                <img src={winCup} className='' width='16' height='16' alt='' />
                                                                <p className='fs-12 fw-700 text-white ms-2'>{bet.bettingStatementDetails.bettingPool} EC</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 my-1'>
                                                    <div className='row'>
                                                        <div className='col-6 pe-0'>
                                                            <div className='d-flex'>
                                                                <img src={betIc} className='' width='16' height='16' alt='' />
                                                                <p className='fs-12 fw-700 text-white ms-2'>{bet.noOfTickets} BETS</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            {bet.isResultAdded ?
                                                                bet.isWon ?
                                                                    <div className='d-flex'> 
                                                                        <img src={ecBetGreen} className='' width='16' height='16' alt='' />
                                                                        {/* <p className='fs-12 fw-700 ms-2 lime-green-text'> +{((bet.bettingStatementDetails.bettingPool)*(bet.noOfTickets)/bet.bettingStatementDetails.noOfWinners).toFixed(2)} </p> */}
                                                                        <p className='fs-12 fw-700 ms-2 lime-green-text'> +{bet?.wonAmount} </p>
                                                                    </div>
                                                                    :
                                                                    <div className='d-flex'>
                                                                        <img src={ecBetRed} className='' width='16' height='16' alt='' />
                                                                        <p className='fs-12 fw-700 ms-2 light-coral-text'> -{(bet.bettingStatementDetails.entryFees).toFixed(2)} </p>
                                                                    </div>
                                                                :
                                                                <div className='d-flex'>
                                                                    <img src={ecBet} className='' width='16' height='16' alt='' />
                                                                    <p className='fs-12 fw-700 ms-2'>{(bet.noOfTickets*bet.bettingStatementDetails.entryFees)}</p>
                                                                    {/*  Amount is going to win per ticket :   <p className='fs-12 fw-700 ms-2'>--{(bet.bettingStatementDetails.bettingPool/bet.bettingStatementDetails.noOfBets).toFixed(2)}</p> */}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 my-1'>
                                                {
                                                    (this.props.competitionType !== "FFA" && bet.bettingStatementDetails.bettingStatement.type === "TEAM") ?
                                                        <div className='col-12 selected-bet'>
                                                            <div className='d-flex justify-content-evenly align-items-center'>
                                                                <div disabled className={`text-center ${bet.chosenOne === bet.bettingStatementDetails.choices[0]._id ? 'active-tournament-selected' : 'active-tournament'}`}>
                                                                    <img src={bet.bettingStatementDetails.choices[0].logo.url} width='40' height='40' alt='' />
                                                                    <p className='fs-12 fw-700 text-white mb-0'>{bet.bettingStatementDetails.choices[0].name}</p>
                                                                </div>
                                                                <div disabled className={`text-center ${bet.chosenOne === bet.bettingStatementDetails.choices[1]._id ? 'active-tournament-selected' : 'active-tournament'}`}>
                                                                    <img src={bet.bettingStatementDetails.choices[1].logo.url} width='40' height='40' alt='' />
                                                                    <p className='fs-12 fw-700 text-white mb-0'>{bet.bettingStatementDetails.choices[1].name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='bet-selected-options d-flex align-items-center  w-100 ps-3 position-static' style={{ height: '52px' }}>
                                                            <p className='fs-16 fw-500 text-white mb-0'>{this.getSelectedOptionDetails(bet.chosenOne,bet.bettingStatementDetails.bettingStatement,bet.bettingStatementDetails.choices)}</p>
                                                        </div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                        {/* {bet.isResultAdded &&
                                            <div className='col-2'>
                                                <img src={bet.isWon ? maskIc : maskIcSad} className='btn-size-25' alt=''  />
                                            </div>
                                        } */}
                                        <div className='col-2'>
                                            <div className='h-100'>
                                                {bet.isResultAdded && <img src={bet.isWon ? maskIc : maskIcSad} className='btn-size-25' alt=''  />}
                                                {/* <Badge bg='warning' className='justify-self-center'>Test</Badge> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="text-center">
                        <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>
                    
                }
              
                <div className='col-12 col-md-6 offset-md-3'>
                    <div className='px-2'>
                        <button className='reminder-btn fs-16 fw-600 border-0 w-100 py-2 text-white'>Remind TO for score updation  <BsFillBellFill className='' /> </button>
                    </div>
                </div>
            </div>
        );
    }
}