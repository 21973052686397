import React, { Component } from 'react';
import '../../styles/den.css';
//npm packages
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
// Images
import locationRight from '../../images/icon/location-right.png';
// Icons

//components
import DenCommonTabs from '../Den/userDenSection/common/DenCommonTabs';
import LeftSideBar from '../Den/userDenSection/common/LeftSideBar';
import LeftSideBarUnRegistered from '../Den/userDenSection/common/LeftSideBarUnRegistered';
import PostCard from '../Den/userDenSection/common/PostCard';
import ReportPost from '../Den/userDenSection/common/ReportPost';
import RightSideBar from '../Den/userDenSection/common/RightSideBar';
import PostActions from '../popup/den/PostActions';
//utils
import { getDenMultiplePosts } from '../../utils/Den.utils';
import { getDenProfiles, getPostInteractionsDetails, reportDenPost } from '../../utils/Den.utils';
import { profile } from '../../utils/localstorage.utils';
import { getDetailsObj } from '../../utils/common.utils';
import UndoReportPost from '../common/UndoReportPost';


export default class DenPostLiveStream extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPostActionModal: false,
            openPostReportModal: false,
            selectedPostIndex: null,
            showWelcomePopup: true,
            feedDetails: [], //sample feed
            showMoreNotificationIndex: null,
            selectedActionIndex: null,
            selectedReportType: null,
            selectedReportTypeDescription: null,
            activeTab: 'HOME',
            denProfile: null,
            isSparked:  false,
            postToInteract: null,
            openSpotlightForPostIndex: null,
            searchString: null,
            openMobileSubTabs: false,
            isSpinned: true,
            pageNo:1,
            hasMorePosts: true,
            reportedDetails: null,
            detailsObj: null
        };
       //this.componentRef=[];
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        if(this.props.match.params.denId){
            console.log("DEN/ID : ", this.props.match.params.denId)
            getDenProfiles({profiles:[this.props.match.params.denId ]},this.setCurrentUserDenProfile)

        }
    }

    setCurrentUserDenProfile = (result) =>{
        
        if(result[0]){
            this.setState({
                denProfile: result[0],
                isSparked: result[0].isSparked
            });
            this.fetchPostDetails(this.props.match.params.postId);
            getDetailsObj(result[0], this.setDetailsObj)
        }
    }

    setDetailsObj = (res) => {
        if(res){ 
            this.setState({detailsObj: res})
        }
    }

    updateDenPostsOnTimeline = (feedDetails) =>{
        this.setState({
            feedDetails: feedDetails
        });
    }

    updateState = (key,val)=>{
        this.setState({
            [key] : val
        });
    } 

    showHidePostAction = (index, val1, val2) => {

        if(!val1 && !val2){
            this.setState({
                selectedActionIndex: null,
                selectedReportType: null,
                selectedReportTypeDescription: null,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }else{
            this.setState({
                selectedActionIndex: index,
                openPostActionModal: val1, 
                openPostReportModal: val2
            })
        }
    }

    toggleWelcomePopup = (val) => { 
        this.setState({showWelcomePopup: val})
    }

    onCheckReportType = (value) =>{
        this.setState({
            selectedReportType: ((this.state.selectedReportType === value)? null : value)
        });
    }

    onChangeReportDescription = (val) =>{
        this.setState({
            selectedReportTypeDescription: val
        })
    }

    onSubmitReport = () =>{
        reportDenPost(this.state.feedDetails[this.state.selectedActionIndex]._id, {type: this.state.selectedReportType, description:this.state.selectedReportTypeDescription }, this.showHidePostAction, this.enableUndoReport);
    }

    enableUndoReport = (report) => {
        console.log(report);
        this.setState({
            reportedDetails: report
        });
        setTimeout(() => {
            this.clearUndoReportPopup();
        }, 10000);
      
    }

    clearUndoReportPopup = () => {
        this.setState({
            reportedDetails: null
        });
    }

    updateProfileDetails = (updatedProfileDetails) =>{
        this.showHidePostAction(null, false, false);
        //updateDenProfileInLocalStorage(updatedProfileDetails);
        this.setState({
            denProfile : updatedProfileDetails
        });
    }

    updatePostDetails = (result,selectedActionIndex, message) =>{

        if(message){ toast.success(message) }
        const updatedPostDetails = this.state.feedDetails.map((post,i)=>{
            if(post._id === result._id){
                result.from = this.state.feedDetails[i].from;
                return result;
            }else{
                return post;
            }
        });

        this.setState({
            feedDetails: updatedPostDetails
        });
       this.showHidePostAction(null, false, false);
    }

    postToInteract = (index) =>{
        this.setState({
            postToInteract: index
        });
    }

    updateSearch = (e) => { 
        const searchTerm = e.target.value;
       
        if(searchTerm.trim().length > 0){
            this.props.history.push(`/den/${this.state.denProfile?._id}/search?param=${searchTerm}`)
        }
     
    }

    fetchPostDetails = (postId) =>{
        getDenMultiplePosts({posts: [postId]},((result)=>{
            this.updateDenPostsOnTimeline(result)
        }));
    }

    setFeedPosts = (newPosts) => {
        if(newPosts.length > 0){
            this.setState({
                feedDetails :  this.state.feedDetails.concat(newPosts),
                pageNo :  this.state.pageNo+1
            });
        }else{
            this.setState({
                hasMorePosts: false
            })
        }
    }

    getDenFeed = () => {
        var isRoportingEnabled = (this.state.selectedReportType !== null); 
      
        return(
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column'>
                    
                    {/* Posts on feed */}
                    <div className='row my-3'>
                    {/* <InfiniteScroll
                        dataLength={this.state.feedDetails.length}
                        next={this.fetchMorePosts}
                        hasMore={this.state.hasMorePosts}
                        loader={<div className='text-center'><img src={GIF.POST_LOAD} alt='' style={{ height: '75px', width: '-webkit-fill-available'}} /></div>}
                    > */}
                        {this.state.feedDetails.map((post, i) => {
                       
                            var likes = 0;
                            var likedUsers = [];    
                            Object.keys(post.likes).map((key,i)=>{ 
                                likes += post.likes[key].length 
                                likedUsers = likedUsers.concat(post.likes[key]);
                            });
                            
                          
                            const interactions = getPostInteractionsDetails(i, post, likedUsers, this.state.denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                            const isPrivatePost =   (!post.from[0]?.isPublic && !post.from[0]?.followers?.includes(this.state.denProfile?._id))
                               
                            return (
                                <div>
                                    <PostCard 
                                        key={i} 
                                        i={i} 
                                        post={post} 
                                        isPrivatePost={isPrivatePost}
                                        denProfile={this.state.denProfile} 
                                        postType={null}
                                        interactions={interactions} 
                                        feedDetails={this.state.feedDetails} 
                                        selectedPostIndex={this.state.selectedPostIndex}
                                        selectedReportType={this.state.selectedReportType}
                                        showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                        postToInteract={this.state.postToInteract}
                                        showHidePostAction={this.showHidePostAction}
                                        updateProfileDetails={this.updateProfileDetails}
                                        updatePostDetails={this.updatePostDetails}
                                        history={this.props.history}
                                        showLiveComments={true}
                                        isLiveStraming={true}
                                    />
                                    {(this.state.reportedDetails && this.state.reportedDetails?.postId  === post._id) && <UndoReportPost updateState={this.updateState} reportedDetails={this.state.reportedDetails}  clearUndoReportPopup={this.clearUndoReportPopup} />}
                                </div>
                            )
                            })
                        }
                    {/* </InfiniteScroll> */}
                    </div>
                </div>

                <DenCommonTabs show={this.state.openMobileSubTabs} updateState={this.updateState} denProfile={this.state.denProfile} />
                
                {/* Post Action Div */}
                <PostActions 
                    postType={null}
                    show={this.state.openPostActionModal} 
                    denProfile={this.state.denProfile}
                    feedDetails={this.state.feedDetails}
                    updatePostDetails={this.updatePostDetails}
                    updateProfileDetails={this.updateProfileDetails}
                    selectedActionIndex={this.state.selectedActionIndex}
                    showHidePostAction={this.showHidePostAction}
                    onClose={() => this.showHidePostAction(null, false, false)}  
                />

                {/* Report Post Details */}
                <ReportPost 
                    onSubmitReport={this.onSubmitReport} 
                    showHidePostAction={this.showHidePostAction} 
                    onChangeReportDescription={this.onChangeReportDescription} 
                    onCheckReportType={this.onCheckReportType} 
                    isRoportingEnabled={isRoportingEnabled} 
                    openPostReportModal={this.state.openPostReportModal} 
                    selectedReportType={this.state.selectedReportType} 
                    selectedReportTypeDescription={this.state.selectedReportTypeDescription} 
                />
            </div>
        )
    }

    nonDenUserFeed = () => { 
        var isRoportingEnabled = (this.state.selectedReportType !== null && this.state.selectedReportTypeDescription?.length > 0); 
        return(
            <div className='col-12 col-lg-6'>
                <div className='d-flex flex-column' style={{height:'90vh', overflow: 'auto'}}>
                    {/* Nav on users feed */}
                    <NavLink to='/den-profile' className="gold_magenta-gradient border-0 d-flex justify-content-center align-items-center my-2">
                        <span className='fs-16 fw-500 py-2 px-3 text-white'>Complete profile to use Den</span>
                        <img src={locationRight} className='btn-size-15 ms-2' alt=''/>
                    </NavLink>

                    {/* Posts on feed */}
                    <div className='row my-3'>

                        {this.state.feedDetails.map((post, i) => {
                       
                            var likes = 0;
                            var likedUsers = [];    
                            Object.keys(post.likes).map((key,i)=>{ 
                                likes += post.likes[key].length 
                                likedUsers = likedUsers.concat(post.likes[key]);
                            });
                            
                    
                            const interactions = getPostInteractionsDetails(i, post, likedUsers, this.state.denProfile, this.state.postToInteract, this.state.openSpotlightForPostIndex);
                            const isPrivatePost =   (!post.from[0]?.isPublic && !post.from[0]?.followers?.includes(this.state.denProfile?._id))
                            return (
                                <div>
                                <PostCard 
                                    key={i} 
                                    i={i} 
                                    post={post} 
                                    isPrivatePost={isPrivatePost}
                                    postType={null}
                                    denProfile={this.state.denProfile} 
                                    interactions={interactions} 
                                    history={this.props.history}
                                    feedDetails={this.state.feedDetails} 
                                    selectedPostIndex={this.state.selectedPostIndex}
                                    selectedReportType={this.state.selectedReportType}
                                    showMoreNotificationIndex={this.state.showMoreNotificationIndex}
                                    postToInteract={this.state.postToInteract}
                                    showHidePostAction={this.showHidePostAction}
                                    updateProfileDetails={this.updateProfileDetails}
                                    updatePostDetails={this.updatePostDetails}
                                    isLiveStraming={true}
                                />
                                 {(this.state.reportedDetails && this.state.reportedDetails?.postId  === post._id) && <UndoReportPost updateState={this.updateState} reportedDetails={this.state.reportedDetails}  clearUndoReportPopup={this.clearUndoReportPopup} />}
                                </div>
                            )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        console.log("### DEN/PROFILE :: ",this.state.denProfile)
        return (
            <div className='body-section den_page' style={{height: 'auto'}}>
                <div className='container p-3'>
                    <div className='row'>
                        {this.state.denProfile ?
                            <LeftSideBar history={this.props.history} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile} detailsObj={this.state.detailsObj} />
                        :
                            <LeftSideBarUnRegistered profile={profile()} /> // UnAuth User Den Side Panel
                        }
                        {this.state.denProfile ? this.getDenFeed() : this.nonDenUserFeed()}
                        <RightSideBar denId={this.props.match.params.denId} activeTab={this.state.activeTab} updateActiveTab={this.updateActiveTab} denProfile={this.state.denProfile}/>
                    </div>
                </div>
            </div>
        );
    }
}