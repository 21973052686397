import React, { Component } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import "../../styles/activate-account.css";
import { toast } from "react-toastify";
import instagram from '../../images/games/instagram.png';
import discord from '../../images/games/discord.png';
import loader from '../../images/common/Loader.png';
import {
  FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton,
  FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon
} from "react-share";

class ShareStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invitationLink: null,
      inviteType: this.props.inviteType,
      imageUrl: this.props.imageUrl
    };
  }

  renderSocailShare = () => {
    let inviteText = `Stats image`;

    return (
      <div>
        <div className="d-flex justify-content-center my-2">
          <img src={this.state.imageUrl} alt='' className="" style={{height:'auto', width:'50%'}} />
        </div>
        <div className="d-flex justify-content-center my-2">
          <div className="d-flex align-items-center w-50 mb-0">
            <p className="flex-grow-1 bg-white my-auto" style={{height:'1px'}}></p>
            <p className="fs-14 fw-normal text-white-50 mx-2 my-0">OR</p>
            <p className="flex-grow-1 bg-white my-auto" style={{height:'1px'}}></p>
          </div>
        </div>
        <div className="text-center">

          <WhatsappShareButton className="px-1" url='https://www.facebook.com/Cristiano' quote={inviteText} title={inviteText}>
            <WhatsappIcon size={"2rem"} round={true} />
          </WhatsappShareButton>

          <FacebookShareButton className="px-1" url='https://www.npmjs.com/package/react-share' content={<img src={this.state.imageUrl} alt='' />} title={inviteText}>
            <FacebookIcon size={"2rem"} round={true} />
          </FacebookShareButton>

          <TelegramShareButton className="px-1" url='https://www.facebook.com/Cristiano' title={inviteText}>
            <TelegramIcon size={"2rem"} round={true} />
          </TelegramShareButton>

          <TwitterShareButton className="px-1" url='https://www.facebook.com/Cristiano' title={inviteText}>
            <TwitterIcon size={"2rem"} round={true} />
          </TwitterShareButton>

          {/* <TwitterShareButton className="px-1">
            <img src={discord} alt='' sizes={'2rem'} round={true} />
          </TwitterShareButton>

          <TwitterShareButton className="px-1">
            <img src={instagram} alt='' sizes={'2rem'} round={true} />
          </TwitterShareButton> */}

          <TwitterShareButton className="px-1">
            <img src={loader} alt='' style={{height:'28px'}} />
          </TwitterShareButton>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className="p-3">
        <IoIosCloseCircleOutline className="close-icon position-absolute" style={{right:'25px',top:'20px'}} onClick={() => this.props.toggleOpenShare()} />
        {this.renderSocailShare()}
      </div>
    );
  }
}

export default ShareStats;
