import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class MicroWebSiteApis {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = import.meta.env.VITE_APP_API_URL;
        this.public_postfix = "/public/api/v1/auth/";
        this.private_postfix = "/private/api/v1/auth/";
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = `${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    getMicroWebsite = (data) => {
        return this.init().post("/public/api/v1/microWebsite/getMicroWebsite", data);
    }
    createMicroWebsite = (data) => {
        return this.init().post("/private/api/v1/microWebsite/createMicroWebsite", data);
    }
    updateMicroWebsite = (id, data) => {
        return this.init().put(`/private/api/v1/microWebsite/updateMicroWebsite/${id}`, data);
    }
    getSingleMicroWebsite = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/private/api/v1/microWebsite/getMicroWebsites?${searchString}`);
    }
    deleteSponsor = (id) => {
        return this.init().delete(`/private/api/v1/sponsor/deleteSponsor/${id}`);
    }
    updateSponsor = (data) => {
        return this.init().put(`/private/api/v1/sponsor/updateSponsor/${data._id}`, data);
    }
}