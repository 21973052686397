import React, { Component } from 'react';
import winCup from '../../../../images/icon/winCup.png';
import betButton from '../../../../images/icon/bet-button.png';
import betIc from '../../../../images/icon/bet-ic.png';
import ecTicket from '../../../../images/icon/ecTicket-ic.png';
import ecBet from '../../../../images/icon/ecBet-ic.png';
import { Badge } from 'react-bootstrap';
import { checkBettingStatus } from '../../../../utils/Betting.utils';
export default class AllBets extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props.tournamentDetails,
            matchDetails: props.matchDetails,
            paidBettingStatements: []
        }
    }

    // To show only paid statements to end user for staking
    componentDidMount() {
        var paidBettingStatements = this.props.matchDetails.matchBettingStatements.filter(function (bet) { return bet.isPaid });
        this.setState({
            paidBettingStatements: paidBettingStatements
        });
    }

    render() {
        //console.log("matchDetails :: ",this.state.matchDetails.results.length)
        var canAddBets = checkBettingStatus(this.state.matchDetails?.matchSchedule);
        return (
            <div className='row my-3'>
                {this.state.paidBettingStatements?.map((bet,i)=>{
                    var correctAnswer = null;
                    if(bet.resultAdded){
                        if(bet.bettingStatement.type === 'CUSTOM'){
                            correctAnswer = bet.choices.filter(choice => choice === bet.correctChoice)[0];
                        }else{
                            correctAnswer = bet.choices.filter(choice => choice._id === bet.correctChoice)[0];
                        }
                        //console.log(`correctAnswer ${i+1}`,correctAnswer);
                    }
                    return (
                        <div className='col-12 col-md-6' key={i}>
                            <div className='betting-card mx-2 mb-4 p-3'>
                                <div className='row'>
                                    <div className='col-10'>
                                        <div className='row'>
                                            <div className='col-12 my-2'>
                                                <h1 className='fs-16 fw-700 text-white'>{bet.bettingStatement.statement}</h1>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row my-2'>
                                                    <div className='col-6 pe-0'>
                                                        <div className='d-flex'>
                                                            <img src={ecTicket} className='' width='16' height='16' alt='' />
                                                            <p className='fs-12 fw-700 text-white ms-2'>{bet.entryFees} EC</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex'>
                                                            <img src={winCup} className='' width='16' height='16' alt='' />
                                                            <p className='fs-12 fw-700 text-white ms-2'>{bet.bettingPool} EC</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className='row my-2'>
                                                        <div className='col-6 pe-0'>
                                                            <div className='d-flex'>
                                                                <img src={betIc} className='' width='16' height='16' alt='' />
                                                                <p className='fs-12 fw-700 text-white ms-2'>{bet.noOfBets} STAKE(s)</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className='d-flex'>
                                                                <img src={ecBet} className='' width='16' height='16' alt='' />
                                                                <p className='fs-12 fw-700 ms-2'>{bet.totalEntryFees}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {(this.state.tournamentDetails.competitionType !== "FFA" && bet.bettingStatement.type === "TEAM") ?
                                                    <div className='col-12' style={{display: canAddBets ? 'block' : 'none'}}>
                                                        <div className='d-flex justify-content-evenly align-items-center'>
                                                            <div className='active-tournament text-center' onClick={() => this.props.selectOption(false, bet.choices[0]._id, bet)}>
                                                                <img src={bet.choices[0].logo.url} width='40' height='40' alt='' />
                                                                <p className='fs-12 fw-700 text-white mb-0'>{bet.choices[0].name}</p>
                                                            </div>
                                                            <div className='active-tournament text-center' onClick={() => this.props.selectOption(false, bet.choices[1]._id, bet)}>
                                                                <img src={bet.choices[1].logo.url} width='40' height='40' alt='' />
                                                                <p className='fs-12 fw-700 text-white mb-0'>{bet.choices[1].name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-12' style={{display: canAddBets ? 'block' : 'none'}}>
                                                        {bet.bettingStatement.type === "TEAM" ?
                                                            <select onChange={(e) => this.props.selectOption(true, e, bet)} value={(this.props.selectedChoice && (bet._id === this.props.selectedChoiceDetails?._id)) ? this.props.selectedChoice : ''} name='choice' className='select-dropdown fs-16 fw-500 border-0 text-white w-100 ps-3' style={{ height: '52px', outline: 'none' }}>
                                                                <option className='text-dark' value='' >Select from teams</option>
                                                                {
                                                                    bet.choices.map((option, index) => {
                                                                        return <option value={option._id} key={index} className='text-white'>{option.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            :
                                                            <select onChange={(e) => this.props.selectOption(true, e, bet)} value={(this.props.selectedChoice && (bet._id === this.props.selectedChoiceDetails?._id)) ? this.props.selectedChoice : ''} name='choice' className='select-dropdown fs-16 fw-500 border-0 text-white w-100 ps-3' style={{ height: '52px', outline: 'none' }}>
                                                                <option className='text-dark' value='' >{bet.bettingStatement.type === "CUSTOM" ? 'Select from options' : 'Select from players'}</option>
                                                                {
                                                                    bet.choices.map((option, index) => {
                                                                        const val = bet.bettingStatement.type === "CUSTOM" ? option : option._id;
                                                                        return <option value={val} key={index} className='text-white'>{bet.bettingStatement.type === "CUSTOM" ? option : option.inGameName}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        }

                                                    </div>
                                                }
                                                {bet.resultAdded ?
                                                    <select value={bet.bettingStatement.type === "CUSTOM" ? correctAnswer : (bet.bettingStatement.type === "TEAM" ? correctAnswer.name : correctAnswer.inGameName)} name='choice' className='select-dropdown fs-16 fw-500 border-0 text-white w-100 ps-3' style={{ height: '52px', outline: 'none' }} disabled>
                                                        <option className='text-dark' value={bet.bettingStatement.type === "CUSTOM" ? correctAnswer : (bet.bettingStatement.type === "TEAM" ? correctAnswer.name : correctAnswer.inGameName)} >{bet.bettingStatement.type === "CUSTOM" ? correctAnswer : (bet.bettingStatement.type === "TEAM" ? correctAnswer.name : correctAnswer.inGameName)}</option>
                                                    </select>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        {/* <div className='col-2 align-self-center'>
                                            <button disabled={this.props.selectedChoiceDetails?._id !== bet._id} className='border-0 bg-transparent bet-select-btn' onClick={() => this.props.initiateBet(bet)}>
                                                <img src={betButton} className='bet_button' alt='' />
                                            </button>
                                        </div> */}
                                    </div>
                                    {(this.state.matchDetails.results.length > 1) && (bet.playoffFormatIndex !== 0) ?
                                        <div className='col-2 align-self-center p-0'>
                                            <div className=' d-flex flex-column p-0'>
                                            <Badge bg='warning'>{bet.playoffFormatIndex} of {this.state.matchDetails.results.length}</Badge> 
                                            <button className='border-0 bg-transparent bet-select-btn align-self-center' onClick={() => this.props.initiateBet(bet)} style={{display: canAddBets ? 'block' : 'none'}}>
                                                <img src={betButton} className='bet_button' alt='' style={{marginLeft:'15px'}} />
                                            </button>
                                            </div>
                                        </div>
                                        :
                                        <div className='col-2 align-self-center p-0'>
                                            <div className=' d-flex flex-column p-0'>
                                            <Badge bg='warning'>Final</Badge> 
                                            <button className='border-0 bg-transparent bet-select-btn align-self-center' onClick={() => this.props.initiateBet(bet)} style={{display: canAddBets ? 'block' : 'none'}}>
                                                <img src={betButton} className='bet_button' alt='' style={{marginLeft:'15px'}} />
                                            </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}