import React, { Component } from 'react';
import { getMarginTopForRounds, getMonthAndDate, saveCloseRegistration, errorHandler, linkWithDiscord, showHideMatchInvite } from '../../../../../../utils/common.utils';
import { addParticipantInTournament, addParticipantToFirstRound2, getMyTeams, getStageDetails } from '../../../../../../utils/Tournament.utils';
import participantAvatar from '../../../../../../images/participantAvtar.jpg';
import { HiUserAdd } from "react-icons/hi";
import { toast } from 'react-toastify';
import AcceptInvite from '../../../../../popup/AcceptInvite';
import MatchInvite from '../../../../../popup/MatchInvite';
import { Modal } from "react-bootstrap";
import Registration from '../../../Registration';
import DoubleBrackets from '../../../../../common/DoubleBrackets';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import { profile } from '../../../../../../utils/localstorage.utils';
import { ERROR } from '../../../../../../utils/Strings.utils';
import Scoreboard from '../../../Scoreboard';


export default class DuelsDoubleBracketGroups extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stageDetails: props?.stageDetails ? props.stageDetails : null,
      tournamentDetails: props?.tournamentDetails ? props.tournamentDetails : null,
      isWinningBrackets: true,
      groupIndex: 0,
      teamsArray: [],
      initiateRegistration: false,
      selectedMatchDetail: null,
      selectedMatchIndex: null,
      selectedTeams: null,
      showRegistrationScreen: false,
      matchIndex: '0.0',
      showScoreBoard: false,
      matchDetails: null,
      matchNo: null
    }

    this.enableLoading = false;
  }

  componentDidMount() {
    if (this.props.setGetAlertMethod) {
      this.props.setGetAlertMethod(this.registerForTournament);
    }

    saveCloseRegistration(this.closeRegistrationScreen);
  }

  componentWillReceiveProps(newProps) {
    if (!this.enableLoading) {
      this.setState({
        stageDetails: newProps?.stageDetails ? newProps.stageDetails : this.state.stageDetails,
        tournamentDetails: newProps?.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,
      })
    }
  }

  calculateMatches = (num, threshold) => {
    var stages = 1;
    var arr = [num];
    while (num !== 1) {
      num = (num / 2);
      arr.push(num);
      stages++;
      if (num === threshold) break;
    }
    return arr;
  }

  getMarginTopForRounds = (index) => {
    var marginTop = 0;
    switch (index) {
      case 0:
        marginTop = 0;
        break;
      case 1:
        marginTop = 35;
        break;
      case 2:
        marginTop = 105;
        break;
      default:
        marginTop = ((this.prevValue * 2) + 35);
        break;
    }
    return marginTop;
  }

  getWiningBrackets = (marginTopDiv, structure, groupsArrayIndex) => {
    var pairs = (this.state.stageDetails.maxParticipants / 2)
    var threshold = this.state.stageDetails.qualificationThreshold === 1 ? 1 : (this.state.stageDetails.qualificationThreshold / 2);
    //var structure = this.calculateMatches(pairs, threshold);
    var marginTopDiv = 0;

    var brackets = structure.map((value, i) => {
      marginTopDiv = (marginTopDiv === 0 ? (35) : ((marginTopDiv * 2)));
      var isFirstRow = (i === 0);
      if (i !== structure.length - 1) {
        this.prevValue = this.getMarginTopForRounds(i);
      }
      //this.prevValue = getMarginTopForRounds(i,this.prevValue)
      return (
        <div key={i} >
          <button type="button" className={`green-olive-card rounded-1 text-warning score-showdown-btn me-2 pt-1 rounds mb-4 ms-${(i === 0) ? 2 : 5}`}>ROUND {i + 1}</button>

          <div className="d-flex" key={i}>

            <div style={{ marginTop: `${(marginTopDiv - (marginTopDiv / 2) < 28 ? 0 : (marginTopDiv - (marginTopDiv / 2) - 7))}px`, width: '1px', display: `${isFirstRow ? 'none' : 'block'}` }} >
              {[...Array(value)].map((p, k) => {
                if (i !== structure.length - 1) {
                  return <p className="vertical-line border-white opacity-100" key={k} style={{ height: `${marginTopDiv + 2}px`, marginTop: `${k === 0 ? null : marginTopDiv}px` }}></p>
                }
              })}
            </div>

            <div className="group-tab" style={{ marginTop: `${this.prevValue}px` }}>
              <div className="row pe-0">
                {(isFirstRow) ?
                  <div className="col-12 pe-0">
                    {[...Array(value)].map((e, j) => {

                      var match_detail = this.state.stageDetails.groups[this.state.groupIndex][groupsArrayIndex][1][j];

                      if (typeof match_detail === 'object') {
                        var dateAndTime = getMonthAndDate(match_detail.matchSchedule);
                        var player_1 = match_detail?.participants[0];
                        var player_2 = match_detail?.participants[1];
                        const canParticipate = showHideMatchInvite(match_detail, this.state.tournamentDetails);
                        console.log('canParticipate ---', canParticipate);
                        return (
                          <div className="d-flex pe-0" style={{ alignItems: 'center', marginLeft: '-5px' }} key={j}>
                            <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 green-flat-btn w-100">
                              <span className="match-tag rounded-pill bg-black text-warning">Match 1.{(j + 1)}</span>
                              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                <div className="d-flex flex-column">
                                  {
                                    player_1 ?
                                      <div className="d-flex align-items-center">
                                        {this.state.isSwappingEnabled ? <input type='checkbox' id={player_1._id} onChange={(e) => this.handleInputChange(e, match_detail, 1, j)} /> : null}
                                        <span className="fw-bold fs-12 mt-1 text-black d-flex align-items-center justify-content-center">
                                          <img src={(this.state.tournamentDetails?.participantType === 'SINGLE') ? player_1.profilePicture?.url : player_1.logo?.url} className='btn-size-15 rounded-circle me-2' alt='' /> {player_1.name}
                                        </span>
                                      </div>
                                      :
                                      <span className="fw-bold fs-12 my-1 text-white">Vacant</span>

                                  }
                                  {
                                    player_2 ?
                                      <div className="d-flex align-items-center">
                                        {this.state.isSwappingEnabled ? <input type='checkbox' id={player_2._id} onChange={(e) => this.handleInputChange(e, match_detail, 1, j)} /> : null}
                                        <span className="fw-bold fs-12 my-1 text-black d-flex align-items-center justify-content-center">
                                          <img src={(this.state.tournamentDetails?.participantType === 'SINGLE') ? player_2.profilePicture?.url : player_2.logo?.url} className='btn-size-15 rounded-circle me-2' alt='' /> {player_2.name}
                                        </span>
                                      </div>
                                      :
                                      <span className="fw-bold fs-12 text-white">Vacant</span>

                                  }
                                </div>
                                {(((this.state.stageDetails.level !== 1) || (match_detail.participants.length === 2)) && canParticipate) ? null :
                                  <button onClick={(e) => this.registerForTournament(e, match_detail, `1.${(j + 1)}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                    <HiUserAdd className="btn-size-15" />
                                  </button>
                                }
                                <div className="d-flex flex-column">
                                  <span className="fw-bold fs-12 mt-1 text-black">{dateAndTime[0]}</span>
                                  <span className="fw-bold fs-12 text-black">{dateAndTime[1]}</span>
                                </div>
                              </div>
                            </div>
                            <hr className="mid-line text-white opacity-100" />
                          </div>
                        )
                      }
                    })}
                  </div>
                  :
                  [...Array(value)].map((e, j) => {
                    var match_detail = this.state.stageDetails.groups[this.state.groupIndex][groupsArrayIndex][i][j];

                    if (typeof match_detail === 'object') {
                      var dateAndTime = getMonthAndDate(match_detail.matchSchedule);
                      var dateAndTime = getMonthAndDate(match_detail.matchSchedule)

                      return (
                        <div className="col-12 pe-0" style={{ marginTop: (j === 0 ? '0px' : `${this.prevValue * 2}px`) }} key={j}>
                          <div className="d-flex" style={{ alignItems: 'center', marginLeft: '-5px' }}>
                            <hr className="mid-line text-white opacity-100" />
                            <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 green-flat-btn w-100">
                              <span className="match-tag rounded-pill bg-black text-warning">    {(structure.length - 1) === i ? `Final` : `Match ${(i + 1) + "." + (j + 1)}`}</span>
                              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                <div className="d-flex flex-column">
                                  <span className="fw-bold fs-12 my-1 text-white">Vacant</span>
                                  <span className="fw-bold fs-12 text-white">Vacant</span>
                                </div>
                                <div className="d-flex flex-column align-items-end">
                                  <span className="fw-bold fs-12 my-1 text-black">{dateAndTime[0]}</span>
                                  <span className="fw-bold fs-12 text-black">{dateAndTime[1]}</span>
                                </div>
                              </div>
                            </div>
                            {((structure.length - 1) === i) && (this.state.stageDetails.enabledGrandFinal !== "DOUBLE") ? null : <hr className="mid-line text-white opacity-100" />}
                          </div>
                        </div>
                      )
                    }

                  })
                }

              </div>
            </div>

          </div>

        </div>
      )
    });

    if (this.state.stageDetails.enabledGrandFinal === "DOUBLE") {

      var dateAndTime = getMonthAndDate(this.state.stageDetails.groups[0][structure.length + 1][0].matchSchedule)

      const grandFinale1 = (
        <div key='G1'>
          <div>
            <button type="button" className={`green-olive-card rounded-1 text-warning score-showdown-btn me-2 pt-1 rounds mb-4 ms-5`}>GF ROUND 2</button>
            <div className="d-flex" key={structure.length + 1}>

              <div className="group-tab" style={{ marginTop: `${this.prevValue + 14}px` }}>
                <div className="row pe-0">
                  <div className="d-flex pe-0" style={{ alignItems: 'center', marginLeft: '-5px' }} >
                    <hr className="mid-line text-white opacity-100" />
                    <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 green-flat-btn w-100">
                      <span className="match-tag rounded-pill bg-black text-warning">Grand Final 2</span>
                      <div className="d-flex justify-content-between align-items-center w-100 px-2">
                        <div className="d-flex flex-column">
                          <span className="fw-bold fs-12 my-1 text-white">Vacant</span>
                          <span className="fw-bold fs-12 my-1 text-white">Vacant</span>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <span className="fw-bold fs-12 my-1 text-black">{dateAndTime[0]}</span>
                          <span className="fw-bold fs-12 text-black">{dateAndTime[1]}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      brackets = [...brackets, grandFinale1];
    }

    return (
      <div id='tournament-date'>
        <div className="d-flex overflow-auto pt-3 pe-5"  >
          {brackets}
        </div>
      </div>
    );
  }

  getLosingBrackets = (marginTopDiv, structure, groupsArrayIndex) => {

    var brackets = structure.map((value, i) => {

      var isFirstRow = (i === 0);

      if (i % 2 === 0) {
        marginTopDiv = (marginTopDiv === 0 ? (35) : ((marginTopDiv * 2)));
        this.prevValue = (i > 1) ? (this.getMarginTopForRounds(i === 2 ? 1 : i - 2)) : (this.getMarginTopForRounds(i));

      }

      return (
        <div key={i}>
          <button type="button" className={`green-olive-card rounded-1 text-warning score-showdown-btn me-2 pt-1 rounds mb-4 ms-${(i === 0) ? 3 : 5}`}>LB ROUND {i + 1}</button>
          <div className="d-flex" key={i}>

            <div style={{ marginTop: `${(marginTopDiv - (marginTopDiv / 2) < 28 ? 0 : (marginTopDiv - (marginTopDiv / 2) - 7))}px`, width: '1px', display: `${isFirstRow ? 'none' : 'block'}` }} >
              {[...Array(value)].map((p, k) => {
                if (i % 2 === 0) {
                  return <p className="vertical-line border-white opacity-100" key={k} style={{ height: `${marginTopDiv + 2}px`, marginTop: `${k === 0 ? null : marginTopDiv}px` }}></p>
                }
              })}
            </div>

            <div className="group-tab" style={{ marginTop: `${this.prevValue}px` }}>
              <div className="row pe-0">
                {(i === 0 || i === 1) ?
                  <div className="col-12 pe-0">
                    {[...Array(value)].map((e, j) => {

                      var match_detail = this.state.stageDetails.groups[this.state.groupIndex][groupsArrayIndex][i + 1][j];

                      if (typeof match_detail === 'object') {
                        var dateAndTime = getMonthAndDate(match_detail.matchSchedule);
                        var player_1 = match_detail?.participants[0];
                        var player_2 = match_detail?.participants[1];
                        return (
                          <div className="d-flex pe-0" style={{ alignItems: 'center', marginLeft: '-5px' }} key={j}>
                            <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 green-flat-btn w-100">
                              <span className="match-tag rounded-pill bg-black text-warning">Match 1.{(j + 1)}</span>
                              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                                <div className="d-flex flex-column">
                                  {
                                    player_1 ?
                                      <div className="d-flex align-items-center">
                                        {this.state.isSwappingEnabled ? <input type='checkbox' id={player_1._id} onChange={(e) => this.handleInputChange(e, match_detail, 1, j)} /> : null}
                                        <span className="fw-bold fs-12 my-1 text-black">{player_1.name}</span>
                                      </div>
                                      :
                                      <span className="fw-bold fs-12 my-1 text-white">Vacant</span>

                                  }
                                  {
                                    player_2 ?
                                      <div className="d-flex align-items-center">
                                        {this.state.isSwappingEnabled ? <input type='checkbox' id={player_2._id} onChange={(e) => this.handleInputChange(e, match_detail, 1, j)} /> : null}
                                        <span className="fw-bold fs-12 text-black">{player_2.name}</span>
                                      </div>
                                      :
                                      <span className="fw-bold fs-12 text-white">Vacant</span>

                                  }
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="fw-bold fs-12 my-1 text-black">{dateAndTime[0]}</span>
                                  <span className="fw-bold fs-12 text-black">{dateAndTime[1]}</span>
                                </div>
                              </div>
                            </div>
                            <hr className="mid-line text-white opacity-100" />
                          </div>
                        )
                      }

                    })}
                  </div>
                  :
                  [...Array(value)].map((e, j) => {

                    var dateAndTime = getMonthAndDate(this.state.stageDetails.groups[this.state.groupIndex][groupsArrayIndex][i + 1][j].matchSchedule)


                    return (
                      <div className="col-12 pe-0" style={{ marginTop: (j === 0 ? '0px' : `${this.prevValue * 2}px`) }} key={j}>
                        <div className="d-flex" style={{ alignItems: 'center', marginLeft: '-5px' }}>
                          <hr className="mid-line text-white opacity-100" />
                          <div className="fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white mb-3 green-flat-btn w-100">
                            <span className="match-tag rounded-pill bg-black text-warning">Match {(i + 1) + "." + (j + 1)}</span>
                            <div className="d-flex justify-content-between align-items-center w-100 px-2">
                              <div className="d-flex flex-column">
                                <span className="fw-bold fs-12 my-1 text-white">Vacant</span>
                                <span className="fw-bold fs-12 text-white">Vacant</span>
                              </div>
                              <div className="d-flex flex-column align-items-end">
                                <span className="fw-bold fs-12 my-1 text-black">{dateAndTime[0]}</span>
                                <span className="fw-bold fs-12 text-black">{dateAndTime[1]}</span>
                              </div>
                            </div>
                          </div>
                          {(structure.length - 1) === i ? null : <hr className="mid-line text-white opacity-100" />}
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>

        </div>
      )
    });

    return (
      <div id='tournament-date'>
        <div className="d-flex overflow-auto pt-3 pe-5"  >
          {brackets}
        </div>
      </div>
    );

  }

  // START :: Tournament Registration  Process
  registerForTournament = (e, matchDetail, matchId, matchIndex) => {
    e.preventDefault();

    if (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true && this.state.tournamentDetails?.participantType === 'TEAM') {
      this.switchRegistrationScreens(matchDetail, matchIndex);
    } else {
      this.enableLoading = true;
      if (this.state.tournamentDetails?.participantType === 'SINGLE') {
        this.setState({
          initiateRegistration: true,
          selectedMatchDetail: matchDetail,
          selectedMatchIndex: matchIndex
        });
      } else {
        this.setState({
          selectedMatchDetail: matchDetail
        });
        getMyTeams(this.state.tournamentDetails.gameId, matchDetail, this.addParticipantInTournament, this.selectTeams)
      }
    }

  }

  selectTeams = (success, data) => {
    if (success) {
      this.setState({
        teamsArray: data.teamsArray,
        initiateRegistration: true,
        selectedMatchDetail: data.selectedMatchDetail
      });
    } else {
      toast.warning(ERROR.NO_TEAM_FOR_TOURNAMENT);
    }

  }

  addParticipantInTournament = (matchId, teamId) => {
    if (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true) {
      this.switchRegistrationScreens(this.state.selectedMatchDetail, this.state.selectedMatchIndex, teamId);
      this.resetRegistration();
    } else {
      const stageInfo = this.state.tournamentDetails.stages[0];
      if (stageInfo.type === "DUEL" && (stageInfo.subType === "Single Elimination" || stageInfo.subType === "Double Elimination" || stageInfo.subType === "Bracket Groups")) {
        var data = {
          participantId: teamId,
          groupIndex: this.state.groupIndex
        }
        addParticipantToFirstRound2(this.state.tournamentDetails, data, this.updateStageDetails);

      } else {
        addParticipantInTournament(this.updateStageDetails, this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails);
      }
    }
  }

  // onClickOpenInvite = (joinTeamId) => {
  //     this.setState({
  //         initiateRegistration: !this.state.initiateRegistration
  //     });
  //     if (joinTeamId) {
  //         addParticipantInTournament (this.updateStageDetails,this.state.selectedMatchDetail._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
  //     }
  //     this.resetRegistration();
  // }


  onClickOpenInvite = (joinTeamId) => {
    this.setState({
      initiateRegistration: !this.state.initiateRegistration
    });
    if (joinTeamId) {
      const stageInfo = this.state.tournamentDetails.stages[0];
      if (stageInfo.type === "DUEL" && (stageInfo.subType === "Single Elimination" || stageInfo.subType === "Double Elimination" || stageInfo.subType === "Bracket Groups")) {
        var data = {
          participantId: joinTeamId,
          groupIndex: this.state.groupIndex
        }
        addParticipantToFirstRound2(this.state.tournamentDetails, data, this.updateStageDetails);

      } else {
        addParticipantInTournament(this.updateStageDetails, this.state.selectedMatchDetail._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
      }
    }
    this.resetRegistration();
  }

  updateStageDetails = (response) => {
    this.setState({
      stageDetails: response,
    });
    this.resetRegistration();
  }

  resetRegistration = () => {
    this.setState({
      teamsArray: [],
      initiateRegistration: false,
      selectedMatchDetail: null,
      selectedMatchIndex: null,
      selectedTeams: null
    });
  }

  switchRegistrationScreens = (matchDetail, matchIndex, teamId) => {
    this.setState({
      showRegistrationScreen: !this.state.showRegistrationScreen,
      selectedMatchDetail: matchDetail,
      matchIndex: matchIndex,
      selectedTeams: (teamId ? teamId : null)
    })
  }

  // END :: Tournament Registration Process

  closeRegistrationScreen = () => {
    getStageDetails(this.state.tournamentDetails?._id, this.props.index, this.setData)
  }

  setData = (values) => {
    if (values) {
      this.setState({
        stageDetails: values,
        showRegistrationScreen: false
      });
    } else {
      this.setState({
        showRegistrationScreen: false
      });
    }
  }


  onClickRegister = () => {

    if (profile()?.discordProfile?.discordUsername) {
      return (
        <Modal.Body>
          <AcceptInvite
            clearInvitations={this.resetRegistration}
            tournamentId={this.state.tournamentDetails?._id}
            addParticipantInTournament={this.addParticipantInTournament}
            joinTeamRole={null}
            close={this.resetRegistration}
            type="add"
            info={{}}
            title='Register'
            role='player'
            // matchId={this.state.selectedMatchDetail?._id}
            isTournamentInvitation={true}
          />
        </Modal.Body>
      );
    } else {
      return (
        <Modal.Body>
          <ConfirmationModal confirm={linkWithDiscord()} onClickOpenConfirm={this.resetRegistration} confirmed={this.confirmed} />
        </Modal.Body>
      );
    }
  }

  confirmed = () => {
    this.resetRegistration();
    this.props.history.push('/profile');
  }

  hideAndShowScoreBoard = (matchDetails, matchNo) => {
    this.setState({
      showScoreBoard: !this.state.showScoreBoard,
      matchDetails: !this.state.showScoreBoard === true ? matchDetails : null,
      matchNo
    });
  }

  render() {
    if (this.state.stageDetails) {
      if (this.state.showRegistrationScreen) {
        return (
          <Registration
            tournamentDetails={this.state.tournamentDetails}
            stageDetails={this.state.stageDetails}
            selectedMatchDetail={this.state.selectedMatchDetail}
            matchIndex={this.state.matchIndex}
            selectedTeams={this.state.selectedTeams}
          />
        )
      } else {

        var structure = [];
        var groupsArrayIndex = this.state.isWinningBrackets ? 0 : 1;
        Object.keys(this.state.stageDetails.groups[this.state.groupIndex][groupsArrayIndex]).map((key, i) => {
          structure[i] = this.state.stageDetails.groups[this.state.groupIndex][groupsArrayIndex][key].length;
        });

        return (
          <div id='tournament-date'>
            {/* Groups Filter */}
            <div className='overflow-auto ms-2' >
              <div className='' style={{ width: 'max-content' }}>
                {this.state.stageDetails?.groups?.map((group, index) => {
                  return (
                    <button style={{ padding: '15px 45px', boxShadow: '0.35px 3.35px 0.625px rgb(255 255 255 / 0%), 2.65px 2.65px 6.625px rgb(21 0 0 / 68%), inset -2.65px -2.65px 5.3px rgb(17 13 64 / 50%), inset 2.65px 2.65px 6.625px rgb(255 255 255 / 80%)', opacity: this.state.groupIndex === (index) ? '1' : '0.5' }} key={index} onClick={() => this.setState({ groupIndex: index })} className='black-round-btn fs-16 fw-600 border border-dark text-warning me-4 rounded-10 mb-1' type='submit'>
                      {this.state.stageDetails.groupsName[index]}
                    </button>
                  )
                })}
              </div>
            </div>

            {/* Brackets Structure */}
            {this.state.showScoreBoard ?
              <Scoreboard
                matchDetails={this.state.matchDetails}
                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                tournamentDetails={this.state.tournamentDetails}
                matchNo={this.state.matchNo}
              />
              :
              <DoubleBrackets
                structure={structure}
                includeThirdPlace={false}
                isSwappingEnabled={false}
                handleInputChange={this.handleInputChange}
                openInviteModal={this.registerForTournament}
                stageMatchDetails={this.state.stageDetails.groups[this.state.groupIndex]}
                isTournamentOperator={false}
                showLoosingBracket={(this.props.isWinningBrackets === 0 ? false : true)}
                enabledGrandFinal={false}
                tournamentDetails={this.state.tournamentDetails}
                stageDetails={this.state.stageDetails}
                index={this.props.index}
                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                marginTopDiv=''
              />
            }

            {/* Tournament Invitation Section */}
            <Modal centered show={this.state.initiateRegistration} dialogClassName="ClanInvite-modal">
              {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                this.onClickRegister()
                :
                <Modal.Body>
                  <MatchInvite
                    onClickOpenInvite={this.onClickOpenInvite}
                    teamsArray={this.state.teamsArray}
                    choice="Join Tournament"
                    isRoundRobin={false}
                  />
                </Modal.Body>
              }
            </Modal>

          </div>
        );
      }

    } else {
      return (
        <div>
          <h1>No Data Found</h1>
        </div>
      );
    }

  }
}