import React, { Component } from 'react';
import { getWebData } from '../../utils/common.utils';
import { CgNotes } from 'react-icons/cg';

export default class BettingRules extends Component {

    constructor(props) {
        super(props)
      
        this.state = {}
    
        this.websiteData = getWebData();
    
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }
    
    render() {
        const rules = this.websiteData?.STATIC_PAGE?.BETTING_RULES?.SET_UP_BETTING_RULES?.split("&&");
        
        return (
            <section className="body-section">      
                <div className="p-3 d-flex flex-column mx-auto notifications" >
                    <div className="row text-center">
                        <div className="col-12">
                        <h1 className="fs-20 fw-bold text-white mb-2 mt-3"> <CgNotes style={{color: 'white'}} />  Staking Rules </h1>
                        </div>
                    </div>
                    <div className='row '>
                    <ol className='text-white'>
                        {rules?.map((rule,i)=>{
                        return <li key={i}>{rule}</li>
                        })}
                    </ol>
                    </div>
                </div>
            </section>
        )
    }

}
