import React, { Component } from 'react';
// Assets
import { HiUserAdd } from 'react-icons/hi';
import winCup from "../../images/icon/winCup.png";
// Utils
import { disableSwap, getMonthAndDate, getStructureCount, getStructuresLineCount, showHideMatchInvite } from '../../utils/common.utils';
// Commons
import ZoomButton from '../common/ZoomButton';

export default class DoubleBrackets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
            structure: props.structure ? props.structure : [],
            isSwappingEnabled: props.isSwappingEnabled ? props.isSwappingEnabled : false,
            stageMatchDetails: props.stageMatchDetails ? props.stageMatchDetails : null,
            isTournamentOperator: props.isTournamentOperator ? props.isTournamentOperator : false,
            enabledGrandFinal: props.enabledGrandFinal ? props.enabledGrandFinal : "NONE",
            tournamentDetails: props.tournamentDetails ? props.tournamentDetails : null,
            stageDetails: props.stageDetails,
            includeThirdPlace: false,
            showLoosingBracket: false,
            isDoubleBracket:props.isDoubleBracket ? props.isDoubleBracket : false,
            style: {
                text: props.isTournamentOperator ? 'player-name' : 'fs-12 fw-bold text-black',
                border: props.isTournamentOperator ? 'border-white' : 'border-warning',
                midLine: props.isTournamentOperator ? null : 'border border-1 border-warning',
                label: props.isTournamentOperator ? 'score-showdown-btn' : 'score-showdown-btn2 text-warning',
                match: props.isTournamentOperator ? 'match-tag' : 'match-tag bg-black text-warning',
                background: props.isTournamentOperator ? 'body-section' : null,
                card: props.isTournamentOperator ? 'pink-skyblue-gradient-btn' : 'green-flat-btn',
                vacant: props.isTournamentOperator ? 'vacant' : null,
                // top: props.isTournamentOperator ? 'pt-5' : null
            }
        };
        this.maxWidth = 100;
        this.prevTop = -1;

    }

    // Refresh structure base on updated stage details 
    componentWillReceiveProps(newProps) {
        this.setState({
            structure: newProps.structure ? newProps.structure : this.state.structure,
            isSwappingEnabled: newProps.isSwappingEnabled ? newProps.isSwappingEnabled : this.state.isSwappingEnabled,
            stageMatchDetails: newProps.stageMatchDetails ? newProps.stageMatchDetails : this.state.stageMatchDetails,
            enabledGrandFinal: newProps.enabledGrandFinal ? newProps.enabledGrandFinal : this.state.enabledGrandFinal,
            tournamentDetails: newProps.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,      
            stageDetails: newProps.stageDetails ? newProps.stageDetails : this.state.stageDetails
        });
    }

    // Set zooming parameters
    setZoomState = (scale, maxWidth) => {
        this.maxWidth = maxWidth;
        this.setState({
            scale: scale
        });
    }

    // Get each rounds all matches structure
    getStructure = (num, col, top, secondChance, columnIndex, matchDetail) => {
       

        if (typeof matchDetail === 'object' && matchDetail !== null) {
            const prevTop = this.prevTop;
            this.prevTop = top;
            const diff = `${80 * (getStructuresLineCount(col))}px`;
           
            return (
                <div className='d-flex' style={{ paddingRight: ((this.state.structure.length === (col + 1) && secondChance) ? '25px' : '0px') }}>
                    <div style={{ marginTop: ((prevTop >= 0) ? `-${prevTop + 4}px` : '-4px') }}>
                        {[...Array(num)].map((ele, i) => {
                            if (!secondChance) {
                                return <p key={i} style={{ marginTop: ((i === 0) ? '0px' : diff), height: diff, opacity: (col === 0 ? '0' : '100') }} className="vertical-lines border-white mb-0" />
                            }
                        })}
                    </div>
                    <div>
                        {[...Array(num)].map((ele, i) => { return this.getMatch(i, num, col, top, false, secondChance, columnIndex) })}
                    </div>
                </div>
            );
        }
    }

    // Get match details to display in match card on brackets structure 
    getMatch = (i, num, col, top, includeThirdPlace, secondChance, columnIndex) => {
        const { style, showLoosingBracket, structure, tournamentDetails, stageMatchDetails, enabledGrandFinal } = this.state;
        const column = (col + 1);
        const row = (i + 1);
        const lastRound = (structure.length === column);
        const isTeamPlayer = (tournamentDetails?.participantType === "TEAM" ? true : false);
        const lastStageThreshold = ((parseInt(this.props.index) !== 0) ? parseInt(this.props.stageDetails?.qualificationThreshold) : 0);
        
        var matchDetail;
        try {
            matchDetail = stageMatchDetails[showLoosingBracket ? 1 : 0][columnIndex][i];

        } catch (err) {
            matchDetail = null;
        }

        if (matchDetail) {
            const dateAndTime = getMonthAndDate(matchDetail?.matchSchedule);
            var players = [matchDetail?.participants[0],matchDetail?.participants[1]];
           
            if (matchDetail?.resultAdded) {
                [...Array(2)].map((ele, k) => {
                    if (players[k] !== undefined) {
                        players[k].isWinner = matchDetail?.finalResult[k]?.isWinner;
                    }
                });
            }
         
            const topSpacing = ((((col > 0) && (i > 0)) ? `${(top * 2)}px` : '0px'));
            const canParticipate = showHideMatchInvite(matchDetail, tournamentDetails);
            const matchNo = includeThirdPlace ? '3rd place' : ((lastRound && num === 1 && enabledGrandFinal !== 'DOUBLE') ? 'Final' : ((structure.length - 1 === column && num === 2) ? `Semi ${row}` : `M ${column}.${row}`));
            return (
                <div className='d-flex' style={{ marginTop: topSpacing }} key={`${column}.${includeThirdPlace ? row + 1 : row}2`} onClick={()=>this.hideAndShowScoreBoard(matchDetail, matchNo)}>
                    {col > 0 ? <hr className="mid-line opacity-100 mt-35" /> : null}
                    <div style={{ minWidth: '350px', maxWidth: '350px', height: '70px', marginBottom: '10px' }} className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white ${style.card}`} >
                        <span className={style.match}>{includeThirdPlace ? '3rd place' : ((lastRound && num === 1 && enabledGrandFinal !== 'DOUBLE') ? 'Final' : ((structure.length - 1 === column && num === 2) ? `Semi ${row}` : `M ${column}.${row}`))} </span>
                        <div className="row d-flex justify-content-between align-items-center w-100 mx-2 my-2">
                            {/* Registered users anf vacancy info */}
                            <div className="col-5 d-grid">
                                {[...Array(2)].map((ele, i) => {
                                    if (players[i]) {
                                        return (
                                            // Players details or vacant info
                                            <div className='d-flex align-items-center text-truncate' key={i}>
                                                {this.props.isSwappingEnabled ?
                                                    <input disabled={disableSwap(matchDetail)} name='checking' type='checkbox' className='me-2' id={players[i]._id} onChange={(e) => this.props.handleInputChange(e, matchDetail, i)} />
                                                    :
                                                    <img src={isTeamPlayer ? players[i]?.logo?.url : players[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt='' />
                                                }
                                                <div className='text-truncate'>
                                                    <span className={`${style.text} my-1 text-truncate`}>{isTeamPlayer ? players[i].name : players[i].inGameName}</span>
                                                </div>
                                                {matchDetail.resultAdded && players[i].isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt='' /> : null}
                                            </div>
                                        );
                                    } else {
                                        return <span className={`player-name my-1 ${style.vacant}`} key={i}>Vacant</span>
                                    }
                                })}
                            </div>

                            {/* Invite user OR Register user option on match */}
                            {(column === 1 && !showLoosingBracket && matchDetail.participants.length !== 2 && parseInt(this.props.index) !== 0 && this.props.isTournamentOperator && canParticipate) ?                
                                <div className='col-2 ps-0'>
                                    {this.props.isBracketGroup ?
                                        <button disabled={i < (this.props.placeOccupied / 2)} onClick={(e) => this.props.openInviteModal(e, matchDetail, matchDetail._id, `M ${column}.${row}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                            <HiUserAdd className="btn-size-15" />
                                        </button>
                                        :
                                        <button disabled={(i < (lastStageThreshold / 2) && (lastStageThreshold !== 1))} onClick={(e) => this.props.openInviteModal(e, matchDetail, matchDetail._id, `M ${column}.${row}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                            <HiUserAdd className="btn-size-15" />
                                        </button>
                                    }
                                </div>
                                :
                                null
                            }

                            {/* Date and time */}
                            <div className="col-5 d-grid">
                                <span className={`${style.text} text-end pe-3`}>{dateAndTime[0]}</span>
                                <span className={`${style.text} text-end pe-3`}>{dateAndTime[1]}</span>
                            </div>
                        </div>
                    </div>
                    <hr className="mid-line opacity-100" style={{ marginTop: '35px' }} />
                </div>
            )
        }

    }

    getMatchDetail = (index) =>{
        try {
            return this.state.stageMatchDetails[this.state.showLoosingBracket ? 1 : 0][index + 1][0];
        } catch (err) {
            return null;
        }
    }

    hideAndShowScoreBoard = (matchDetails, matchNo) =>{
     
        if(!this.state.isTournamentOperator && matchDetails?.resultAdded){
            this.props.hideAndShowScoreBoard(matchDetails, matchNo)
        }
    }


    render() {
        var { showLoosingBracket, style, structure, scale, enabledGrandFinal,isDoubleBracket } = this.state;

        if (showLoosingBracket) {
            structure = structure.slice(1);
        }
        structure = [...new Set(structure)];
        var columnIndex = 0;

        return (
            <div className={`${this.props.marginTopDiv}`}>
            {/* <div style={{ marginTop: ( !this.props.isTournamentOperator ? '0px' : (parseInt(this.props.index) === 0 ? (isDoubleBracket ? '20px':'165px') : '120px')) }}> */}
                <div className={`container pe-0 `}>
                    {/* Switch between winners & loser brackets */}
                    <div className={`d-flex mt-2 pe-2`} style={{ width: '100%' }} role="group" aria-label="Basic example">
                        <button type="button" onClick={() => this.setState({ showLoosingBracket: !showLoosingBracket })} style={{ width: '50%', opacity: !showLoosingBracket ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds `}>Winners</button>
                        <button type="button" onClick={() => this.setState({ showLoosingBracket: !showLoosingBracket })} style={{ width: '50%', opacity: showLoosingBracket ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds `}>Losers</button>
                    </div>
                    {/* Double eliminations brackets section */}
                    <div className='d-flex overflow-auto pt-2' style={{ transform: `scale(${scale})`, transformOrigin: `0% 0% 0px`, width: `fit-content`, maxWidth: `${this.maxWidth}%` }}>
                        {structure.map((count, index) => {
                            var rounds = [];
                            columnIndex = (columnIndex + 1);

                            // Get brackets structure count to keep structure spacing and line
                            const structureCount = getStructureCount(index);
                            var matchDetail = this.getMatchDetail(index);
       
                            // Winning brackets structure
                            if(matchDetail !== null && this.state.stageMatchDetails[1][columnIndex] !== undefined){
                               
                                rounds[0] = (
                                    <div key={index}>
                                        <div className='mb-4'>
                                            <button type="button" className={`${style.label} me-2 rounds ms-${(index === 0) ? 0 : 5}`}>ROUND {columnIndex}</button>
                                        </div>
                                        <div key={index} style={{ paddingTop: `${structureCount}px` }} >
                                            {this.getStructure(count, index, structureCount, false, columnIndex, matchDetail)}
                                        </div>
                                    </div>
                                );
                            }
                           

                            if (showLoosingBracket) {
                                if(matchDetail !== null && this.state.stageMatchDetails[1][columnIndex] !== undefined){
                                    
                                    // Loosing brackets structure
                                    columnIndex = columnIndex + 1;
                                    
                                    rounds[1] = (
                                        <div key={index + 2}>
                                            <div className='mb-4'>
                                                <button type="button" className={`${style.label} me-2 rounds ms-${(index === 0) ? 0 : 5}`}>ROUND {columnIndex}</button>
                                            </div>
                                            <div key={index} style={{ paddingTop: `${structureCount}px` }} >
                                                {this.getStructure(count, index, structureCount, true, columnIndex, matchDetail)}
                                            </div>
                                        </div>
                                    );
                                }
                            } else {
                                // Grand finale brackets structure
                                if (count === 1 && (enabledGrandFinal === 'SIMPLE' || enabledGrandFinal === 'DOUBLE')) {
                                    let num = (enabledGrandFinal === ('SIMPLE' ? 1 : 2));
                                    rounds[1] = [...Array(num)].map((ele, i) => {
                                        columnIndex = columnIndex + 1;
                                        matchDetail = this.getMatchDetail((index + (i + 1)));
                                       
                                        if(matchDetail !== null){
                                            return (
                                                <div key={index + (i + 1)}>
                                                    <div className='mb-4'>
                                                        <button type="button" className={`${style.label} me-2 rounds ms-${(index === 0) ? 0 : 5}`}>Grand Final {(i + 1) === 2 ? '2' : ''}</button>
                                                    </div>
                                                    <div style={{ paddingTop: `${structureCount}px` }} >
                                                        {this.getStructure(count, (index + (i + 1)), structureCount, true, columnIndex, matchDetail)}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    });
                                }
                            }
                            return rounds;
                        })}
                    </div>
                </div>

                {/* Zooming Options */}
                <ZoomButton openInviteModal2={this.props.openInviteModal2}  isTournamentOperator={this.props.isTournamentOperator} stageIndex={parseInt(this.props.index)} zoom={this.zoom} scale={scale} maxWidth={this.maxWidth} setZoomState={this.setZoomState} />
            </div>
        );
    }
}