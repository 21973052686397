import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import participantAvtar from "../../../images/participantAvtar.jpg";
import TournamentHeader from "./common/TournamentHeader";
import participants from '../../../images/icon/participants.png';
import { getTournamentData } from "../../../utils/common.utils";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';

class ParticipantForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentDetails: props?.tournamentData?._id?props?.tournamentData:null,
      participantDetails: [null],
    };
    if (!this.props?.tournamentData?._id) {
      getTournamentData(this.props.match.params.id, this.setData)
    }
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

    // when data not came from location.state it returns data from API
    setData = (values) => {
      if (values) {
          this.setState({
              tournamentDetails: values,
          })
          this.props.dispatchData(values)
      } else {
          this.props.history.push('/pageNotFound')
      }
  }
  render() {
    console.log("dfdfd  :: ", this.props.location.state)
    return (
      <div className="body-section participant-form">
        <div className="container p-3 mt-lg-3">
          <TournamentHeader
            tournamentDetails={this.state.tournamentDetails}
            logo={participants}
            heading="Participants Details"
            subHeading={null}
            history={this.props.history}
          />
          <div className="row pt-3">
            <div className="col-12 mb-5">
              <div className="mulberry-purple-card d-flex align-content-center align-items-center py-3">
                <div className="col-2">
                  <img
                    src={participantAvtar}
                    className="rounded-circle ms-3"
                    alt=""
                  />
                </div>
                <div className="col-7">
                  <h6 className="ms-3 mt-2 text-truncate">BlueThorns</h6>
                </div>
                <div className="col-3">
                  <button className="white-flat-btn">Ban</button>
                </div>
              </div>
            </div>

            {this.state.participantDetails.map((participant, i) => (
              <div className="col-12 mb-5" key={i}>
                <div className="participant-form border rounded  mt-4 pt-4 px-3">
                  <div className="d-flex justify-content-center">
                    <img
                      src={participantAvtar}
                      className="rounded-circle position-absolute"
                      style={{ marginTop: "-16%" }}
                      alt=""
                    />
                    <h4 className="mt-3">JS</h4>
                  </div>
                  <Form className="createClan-form">
                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>Real Name</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          disabled
                          type="email"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>In-Game Name</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>In-Game ID</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>Discord ID</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-group my-3">
                      <Form.Group className=" mb-3" controlId="formBasicEmail">
                        <Form.Label>Custom Detail</Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          // placeholder="Enter email"
                          className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              </div>
            ))}

            {/* <div className='col-12 mb-5'>
                            <div className='participant-form border rounded mt-4 pt-4 px-4 '>
                                <div className='d-flex justify-content-center'>
                                    <img src={participantAvtar} className="rounded-circle position-absolute" style={{ marginTop: "-60px", border: "5px solid #200e45" }} />
                                    <h4 className='mt-3'>JS</h4>
                                </div>
                                <Form className="createClan-form" >
                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>Real Name</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="email"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>In-Game Name</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>In-Game ID</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>Discord ID</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>

                                    <div className="form-group my-3">
                                        <Form.Group className=" mb-3" controlId="formBasicEmail">
                                            <Form.Label>Custom Detail</Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                // placeholder="Enter email"
                                                className='purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45' />
                                        </Form.Group>
                                    </div>
                                </Form>

                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default (connect(mapStateToProps, mapDispatchToProps))(ParticipantForm)