import React, { Component } from "react";

// Images
import latestResultIc from "../../../images/icon/LatestResult-ic.png";
//common 
import { getWebData, getGameRules } from '../../../utils/common.utils';


export default class TournamentRules extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        rules: props?.tournamentDetails?.rules?  props?.tournamentDetails?.rules : [],
      }
      this.gameRules = getGameRules(props.tournamentDetails?.gameDetails?.name)
      this.websiteData = getWebData();
      

    }

    componentWillReceiveProps(props) {
        this.setState({ 
        rules: props?.tournamentDetails?.rules?  props?.tournamentDetails?.rules : [],
        });
    }
    
    render() {
        const mandatoryRules = this.websiteData?.STATIC_PAGE?.RULES?.SET_UP_RULES?.split("&&");
        const bettingRules = this.websiteData?.STATIC_PAGE?.BETTING_RULES?.SET_UP_BETTING_RULES?.split("&&");
      
        return (
            <section className='tournament-rules text-white'>
                <div className='container'>
                    <div className="row">
                        {/* Rules */}
                        <div className="col-12 mt-2">
                            <span className='d-flex'>
                                <img src={latestResultIc} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>Rules</h1>
                            </span>
                            <div className='row'>
                                <div className='col-12'>
                                    <ol className='ms-4'>
                                        {this.state.rules.length>0 ?
                                         this.state.rules?.map((rule,i) => {return <li className='fs-14 fw-400 mb-1' key={i}>{rule}</li>})
                                         : 
                                         <li className='fs-14 fw-500'>Rules yet to be added.</li>
                                        }
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/* Mandatory Rules */}
                        <div className="col-12 mt-4">
                            <span className='d-flex'>
                                <img src={latestResultIc} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>Mandatory Rules</h1>
                            </span>
                            <div className='row'>
                                <div className='col-12'>
                                    <ol className='ms-4'>
                                        {mandatoryRules?.map((rule,i) => {
                                            return <li className='fs-14 fw-400 mb-1' key={i}>{rule}</li>
                                        })}
                                    </ol>
                                </div>
                            </div>
                        </div>
                         {/* Staking Rules */}
                         <div className="col-12 mt-4">
                            <span className='d-flex'>
                                <img src={latestResultIc} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>Staking Rules</h1>
                            </span>
                            <div className='row'>
                                <div className='col-12'>
                                    <ol className='ms-4'>
                                        {bettingRules?.map((rule,i) => {
                                            return <li className='fs-14 fw-400 mb-1' key={i}>{rule}</li>
                                        })}
                                    </ol>
                                </div>
                            </div>
                        </div>

                        {/* Game Rules */}
                        <div className="col-12 mt-4">
                            <span className='d-flex'>
                                <img src={latestResultIc} className='' alt='' style={{ height: '20px', width: '16px' }} /><h1 className='fs-16 fw-700 ms-2'>Game Rules</h1>
                            </span>
                            <div className='row'>
                                <div className='col-12'>
                                    <ol className='ms-4'>
                                        {this.gameRules?.[0]?.rule?.map((rule,i) => {
                                            return <li className='fs-14 fw-400 mb-1' key={i}>{rule.description}</li>
                                        })}
                                    </ol>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}
