import React, { Component } from 'react';
// Assets
import { Modal, Button } from "react-bootstrap";
import { AiOutlineDownload } from 'react-icons/ai';

export default class ImagePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageSource: props.previewImageDetails.src
        }
    }

    // To close image preview
    closePreview = () => {
        this.props.previewImage(null, false);
    }

    // To download previewed  image
    download = (e) => {
        fetch(e.target.href, {
            method: "GET",
            headers: {}
        }).then(response => {
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "image.png"); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.props.history.goBack()
            });
        }).catch(err => {
            console.error("Error in download() :: ",err);
        });
    };

    render() {
        return (
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <img className="image-preview" src={this.state.imageSource} alt="" />
                    <div className='d-flex'>
                        <Button variant='secondary' className='fs-14 fw-600 text-white mx-auto my-2' onClick={this.closePreview}>Close</Button>
                        {
                            this.props.isDownload &&
                            <a href={this.state.imageSource} onClick={(e) => { this.download(e) }}>
                                <Button variant='secondary' className='fs-14 fw-600 text-white mx-auto my-2'><AiOutlineDownload></AiOutlineDownload></Button>
                            </a>
                        }
                    </div>
                </div>
            </Modal.Body>
        );
    }
}