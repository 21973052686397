import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import scoreIc from '../../../../../../images/icon/score-ic.png';
import { dateFormat, errorHandler, getHeading } from '../../../../../../utils/common.utils';
import disputeIc from '../../../../../../images/icon/dispute-ic.png';
import chatIc from '../../../../../../images/icon/chat-ic.png';
import TournamentHeader from '../../../common/TournamentHeader';
import Switch from "react-switch"

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../../../../utils/Tournament.utils';
import MatchesApis from '../../../../../../helper/matches.api';

import { Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';


import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import BettingApis from '../../../../../../helper/betting.api';
import { getBettingStatements, getMatchDetails } from '../../../../../../utils/Betting.utils';
import { MdDoubleArrow } from 'react-icons/md';
import { toast } from 'react-toastify';


class MatchesFFAScore extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails,
            disableChat: false,
            matchDetails: null,
            gameNo: -1,
            gameDetails: [],
            isBettingEnabled: true, // false
            bettingStatementModal: false,
            openKycVerification: false,
            openDetailsModal: false,
            detailsModalObj: {},
            bettingStatements: [],
            selectedBettingStatements: [],
            payableAmount: 0,
            publishedTournamentCount: 0

        }
        this.matchesApis = new MatchesApis();
        this.bettingApis = new BettingApis();
        
        getMatchDetails(this.props.matchId,this.setSelectedBettingStatementsDetails)
        getBettingStatements(this.props.matchId, this.setBettingStatements);
        
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ tournamentDetails: newProps.tournamentDetails });
    }

    setSelectedBettingStatementsDetails = (response) =>{ 
        try{
            var selectedBettingStatements = [];
            var payableAmount = 0;
            var publishedTournamentCount =0;
            var totalPublishedBettingPool=0;
            response?.matchBettingStatements?.map((statement,i)=>{
                if(!statement.isPaid){
                //    console.log("statement ",statement)
                    selectedBettingStatements.push({
                        _id: statement._id,
                        statement: statement.bettingStatement,
                        options: statement.choices,
                        bettingPool: statement.bettingPool,
                        entryFees: statement.entryFees
                    });
                    payableAmount += statement.bettingPool;
                }else{
                    publishedTournamentCount++;
                    totalPublishedBettingPool += statement.bettingPool;
                }
            });


            this.setState({
                matchDetails: response,
                gameDetails: Object.values(response.finalResult),
                isBettingEnabled: response.isBettingEnabled,
                disableChat: response?.chatEnabled,
                selectedBettingStatements: selectedBettingStatements,
                payableAmount: payableAmount,
                publishedTournamentCount: publishedTournamentCount, 
                totalPublishedBettingPool: totalPublishedBettingPool
                
            })

        }catch(err){
            console.log(err)
        }
       
    }

    setBettingStatements = (bettingStatements) => {
        this.setState({
            bettingStatements: bettingStatements
        });
    }


    async getMatchDetails(matchId) {
        var data = {
            ids: [matchId]
        }
        this.props.updateLoader(true);
        await this.matchesApis.getMultipleMatches(data).then(
            (res) => {
                var response = res.data['result']
                console.log("Match Data : ", response);
                this.setState({
                    matchDetails: response[0],
                    gameDetails: Object.values(response[0].finalResult)
                })
                this.props.dispatchData(response[0])
                this.props.updateLoader(false);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }
    updateMatch = () => {
        this.props.updateLoader(true)
        let data = {
            chatEnabled: !this.state.disableChat
        }
        this.matchesApis.updateMatchResults(this.props.matchId, data).then(
            (res) => {
                this.setState({ disableChat: res.data['result']?.chatEnabled })
                this.props.updateLoader(false);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }
    updateGameDetails = (game, gameNo) => {
        if(game){
            this.setState({
                gameDetails: Object.values(game),
                gameNo
            })
        } else {
            toast.warning("No game found");
        }
    }

    getUpdateScorePage = (game, i) => {
        this.updateGameDetails(game, i);
        this.props.history.push(`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/matches/${this.props.type}/${this.props.matchId}/score/update/${i}`);
    }

    updateBettingStatus = () => {
        this.props.updateLoader(true)
        let data = {
            isBettingEnabled: !this.state.isBettingEnabled
        }
        this.matchesApis.editMatchDetails(this.props.matchId, data).then(
            (res) => {
                this.setState({ isBettingEnabled: res.data['result']?.isBettingEnabled })
                this.props.updateLoader(false);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    checkAndRedirectToBetting = () => {
        const game = this.state.tournamentDetails?.gameDetails;
        if(game.isBettingEnabled || this.state.isBettingEnabled){
            this.props.history.push(`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/match/FFA/${this.props.matchId}/betting`);
        }else{
            toast.error('Staking for this game is currently disabled by Espotz admin.');
        }
    }

    render() {
        console.log('gameDetails ---', this.state.gameDetails);
        var sortedArray;
        if(this.state.gameNo === -1){
            if(this.state.matchDetails?.results.length>1){
                //console.log('Inside IF')
                sortedArray = this.state.gameDetails?.sort((a, b) => parseInt(b.score) - parseInt(a.score));
            }else{
                //console.log('Inside ELSE');
                sortedArray = this.state.gameDetails?.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
            }
        }else{
            sortedArray = this.state.gameDetails?.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
        }
        console.log('sortedArray', sortedArray);
        var title= '';
        //console.log('tournamentDetails -------', this.state.tournamentDetails);
        //console.log('matchDetails -------', this.state.matchDetails);
        console.log('gameNo --------', this.state.gameNo);
        //console.log('-------boolean value-----------', (this.state.tournamentDetails && this.state.matchDetails && this.state.gameNo === -1));
        if (this.state.tournamentDetails){
            title = getHeading(this.props.tournamentDetails);
        }
        return (
            <div className="body-section match-ffa-score">
                <div className="container p-3 mb-5 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={scoreIc} heading={<h1 className="clan-heading  text-center d-inline">{title}</h1>} subHeading={null} history={this.props.history} />
                    
                    <button type='button' className='fs-14 fs-lg-18 fw-600 height-45 rounded-10 d-flex align-items-center  justify-content-center outline-0 border-0 text-white my-2 pink-skyblue-gradient-btn w-100' onClick={this.checkAndRedirectToBetting}>
                        Set-up Staking <MdDoubleArrow className='ms-3'/>
                    </button>
                

                    {this.state.tournamentDetails  &&

                        <div>

                            <div className='d-flex overflow-auto mt-2 '>
                                <button type="button" className="score-showdown-btn me-2" onClick={() => this.updateGameDetails(this.state.matchDetails?.finalResult, -1)} style={{ opacity: this.state.gameNo === -1 ? 1 : 0.5 }}>MATCH</button>

                                {this.state.matchDetails &&
                                    this.state.matchDetails?.results?.map((game, i) => {
                                        return <button type="button" className="score-showdown-btn me-2" key={i} onClick={() => this.updateGameDetails(game, i)} style={{ opacity: this.state.gameNo === i ? 1 : 0.5 }}>GAME {i + 1}</button>;
                                    })
                                }
                            </div>
                            <div className='d-flex justify-content-between align-items-center mt-2 mb-2'>
                                <h1 className="fs-16 fw-bold">Show Down</h1>
                                <h1 className="stage-sub-heading">{dateFormat(this.state.matchDetails?.matchSchedule)}</h1>
                                {/* <h1 className="stage-sub-heading">STATUS: {this.state.matchDetails?.status}</h1> */}
                            </div>
                            <div className='row'>
                                
                                <div className="col-12 d-flex justify-content-center my-3">
                                    <NavLink to={`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/matches/${this.props.type}/${this.props.matchId}/score/update/${this.state.gameNo}`} style={{ color: 'white' }}>
                                        <button className="white-btn-shadow w-100 fw-600 fs-16 px-3" style={{ height: '40px', display: (this.state.gameNo !== -1) ? 'block' : 'none' }}>Enter Score</button>
                                    </NavLink>
                                </div>

                                {(this.state.tournamentDetails && this.state.matchDetails && this.state.gameNo === -1) ?
                                    this.state.tournamentDetails?.participantType === 'SINGLE' ?
                                        sortedArray?.map((el, i) => {
                                            //console.log('single el -------------', el);
                                            if(el.participantDetails){
                                                return(
                                                <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                                    <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                                        <div className="d-flex justify-content-center">
                                                            <img src={el?.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                                            <h4 className="mt-3 fs-14">{el.participantDetails.inGameName}</h4>
                                                        </div>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className="fs-14 text-white">Rank : {this.state.matchDetails?.resultAdded ? i+1 : 'TBD'}</p>
                                                            <p className="fs-14 text-white">Kills : {el.kills ? el.kills : 'TBD'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })
                                        :
                                        sortedArray?.map((el, i) => {
                                            if(el.teamDetails){;
                                            //console.log(`team ${i+1} score object ::`, el);
                                            return(
                                                <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                                    <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                                        <div className="d-flex justify-content-center">
                                                            <img src={el?.teamDetails?.logo?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                                            <h4 className="mt-3 fs-14">{el.teamDetails.name}</h4>
                                                        </div>
                                                        <p className="mt-1 mb-1 fs-12 text-white text-center">{`Team Kills: ${el?.kills ? el?.kills : 0}`}</p>
                                                        {/* <div className='d-flex justify-content-between'>
                                                            <h1 className="fs-20 text-white">Rank : {el.rank ? el.rank : 'TBD'}</h1>
                                                            <h1 className="fs-20 text-white">Kills : {el.kills ? el.kills : 'TBD'}</h1>
                                                        </div> */}
                                                        {el?.individualScore?.map((player, i) => {
                                                            if(player.participantDetails){
                                                                //console.log(`player ${i+1} ::`, player);
                                                                return(
                                                                    <div className='d-flex align-items-center my-1'>
                                                                        <img src={player?.participantDetails?.profilePicture?.url} alt='' className="rounded-circle btn-size-32 me-2" />
                                                                        <p className="fs-14 fw-500 text-white my-0 me-auto">{player?.participantDetails?.inGameName}</p>
                                                                        <p className="fs-14 text-white my-0">Kills: {el.kills ? player?.kills : 0}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        })
                                    :
                                    null
                                }
                                
                                {(this.state.tournamentDetails && this.state.matchDetails && this.state.gameNo !== -1) ?
                                    this.state.tournamentDetails?.participantType === 'SINGLE' ?
                                        sortedArray?.map((el, i) => {
                                            //console.log('single el -------------', el);
                                            if(el.participantDetails){
                                            return(
                                            <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                                <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                                    <div className="d-flex justify-content-center">
                                                        <img src={el?.participantDetails?.profilePicture?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                                        <h4 className="mt-3 fs-14">{el.participantDetails.inGameName}</h4>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="fs-14 text-white">Rank : {el.resultAdded ? el.rank : 'TBD'}</p>
                                                        <p className="fs-14 text-white">Kills : {el.resultAdded ? el.kills : 'TBD'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                        })
                                        :
                                        sortedArray?.map((el, i) => {
                                            if(el.teamDetails){;
                                            console.log(`team ${i+1} score object ::`, el);
                                            return(
                                                <div className="col-12 col-md-6 col-lg-3 mb-5" key={i}>
                                                    <div className="participant-form border rounded-10  mt-4 py-3 px-3">
                                                        <div className="d-flex justify-content-center">
                                                            <img src={el?.teamDetails?.logo?.url} className="rounded-circle position-absolute btn-size-45" style={{ marginTop: "-40px" }} alt="" />
                                                            <h4 className="mt-3 fs-14">{el.teamDetails.name}</h4>
                                                        </div>
                                                        <p className="mt-1 mb-1 fs-12 text-white text-center">{`Team Kills: ${el?.resultAdded ? el?.kills : 'TBD'}`}</p>
                                                        {/* <div className='d-flex justify-content-between'>
                                                            <h1 className="fs-20 text-white">Rank : {el.rank ? el.rank : 'TBD'}</h1>
                                                            <h1 className="fs-20 text-white">Kills : {el.kills ? el.kills : 'TBD'}</h1>
                                                        </div> */}
                                                        {el?.individualScore?.map((player, i) => {
                                                            if(player.participantDetails){
                                                                console.log(`player ${i+1} ::`, player);
                                                                return(
                                                                    <div className='d-flex align-items-center my-1'>
                                                                        <img src={player?.participantDetails?.profilePicture?.url} alt='' className="rounded-circle btn-size-32 me-2" />
                                                                        <p className="fs-14 fw-500 text-white my-0 me-auto">{player?.participantDetails?.inGameName}</p>
                                                                        <p className="fs-14 text-white my-0">Kills: {el.resultAdded ? player?.kills : 'TBD'}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        })
                                    :
                                    null
                                }

                                
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-3 mt-4">
                                <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                                    <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">Disable Chat</p>
                                    <Switch checked={this.state.disableChat}
                                        checkedIcon={false} uncheckedIcon={false} className="me-2"
                                        onChange={this.updateMatch} />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <div className='d-flex justify-content-center position-fixed' style={{ bottom: '60px' }}>

                                    <button type='button' disabled={this.state.disableChat} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2' onClick={() => {
                                        this.props.history.push(
                                            {
                                                pathname: `/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/chatLobby/${this.props.matchId}`,
                                                state: {
                                                    orgId: this.props.orgId,
                                                    tournamentDetails: this.props.tournamentDetails
                                                }
                                            }
                                        );
                                    }}>
                                        <img src={chatIc} alt="" className='btn-size-25' />
                                    </button>


                                    <NavLink to={{
                                        pathname: `/organization/${this.props.orgId}/tournament/${this.props.tournamentId}/overview/dispute-management`,
                                        state: {
                                            orgId: this.props.orgId,
                                            tournamentDetails: this.props.tournamentDetails
                                        }
                                    }}>
                                        <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2'>
                                            <img src={disputeIc} alt="" className='btn-size-25' />
                                        </button>
                                    </NavLink>


                                </div>
                            </div>
                        </div>
                    }

               

                </div>

                <Modal show={this.state.bettingStatementModal} centered dialogClassName="betting-statement-modal">
                    <Modal.Body>
                        <div className="p-3 px-md-5 px-lg-5">
                            <IoIosCloseCircleOutline
                                className="close-icon position-absolute" style={{ right: '15px' }}
                                onClick={() => this.setState({ bettingStatementModal: false })}
                            />
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    statement: '',
                                    options: '',
                                    entryFees: '',
                                    bettingPool: ''
                                }}

                                validationSchema={Yup.object({
                                    label: Yup.string().required("Please enter label"),
                                    link: Yup.string().required('Please enter website'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                <Form className="mt-3" autoComplete='off'>
                                    <div className="">
                                        <label htmlFor="statement" className="fs-14 fw-600 text-white">Statement</label>
                                        <Field name="statement" as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                            <option value=''>Which team will win?</option>
                                            <option value=''>Who will get first blood?</option>
                                            <option value=''>Who will be dead first?</option>
                                            <option value=''>Will match tie?</option>
                                        </Field>
                                        <ErrorMessage component="span" className="error-msg" name="statement" />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="options" className="fs-14 fw-600 text-white">Options</label>
                                        <Field name="options" as="select" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                            <option value=''>BlueThorns</option>
                                            <option value=''>Alphafortniters</option>
                                        </Field>
                                        <ErrorMessage component="span" className="error-msg" name="options" />
                                    </div>

                                    <div className="mt-2">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className=''>
                                                <label htmlFor="entryFees" className="fs-14 fw-600 text-white">Entry Fees</label>
                                            </div>
                                            <Field name="entryFees" type="number" placeholder="Add EC" className="purple-field d-flex align-items-center border-0 ps-3 text-info fs-14 height-45" />
                                        </div>
                                        <ErrorMessage component="span" className="error-msg" name="entryFees" />
                                    </div>

                                    <div className="mt-2">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className=''>
                                                <label htmlFor="bettingPool" className="fs-14 fw-600 text-white">Staking pool</label>
                                            </div>
                                            <Field name="bettingPool" type="number" placeholder="Add EC" className="purple-field d-flex align-items-center border-0 ps-3 text-info fs-14 height-45" />
                                        </div>
                                        <ErrorMessage component="span" className="error-msg" name="bettingPool" />
                                    </div>
                                    <button type="submit" className="white-flat-btn d-flex fw-bold py-1 px-4 m-auto mt-3" >
                                        Add
                                    </button>
                                </Form>
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openKycVerification} centered dialogClassName="complete-kyc-modal">
                    <Modal.Body>
                        <div className='p-3 px-md-5 px-lg-5'>
                            <h1 className='fs-20 fw-600 text-white text-center'>KYC Verification</h1>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => this.setState({ openKycVerification: false })} />
                            <p className='fs-14 fw-600 text-white text-center mt-2 mb-4' style={{ textAlign: 'justify' }}>You should complete KYC verification in order to publish staking statements</p>

                            <div className=''>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        ageLimit: false,
                                        termsConditions: false,
                                        legalBetting: false
                                    }}
                                    validationSchema={Yup.object({
                                        ageLimit: Yup.bool().oneOf([true], 'Accept  18 years old or older Condition'),
                                        termsConditions: Yup.bool().oneOf([true], 'Accept staking terms & conditions'),
                                        legalBetting: Yup.bool().oneOf([true], 'Accept staking is legal in jurisdiction')
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            setSubmitting(false);
                                        }, 400);
                                    }}>
                                    <Form className="d-flex flex-column p-0 m-0" autoComplete="off">
                                        <div className="d-flex my-2">
                                            <Field type="checkbox" name='ageLimit' className="mt-1 me-2" />
                                            <p className="text-white fs-14 fw-normal mb-0">I agree to the terms & conditions</p>
                                        </div>
                                        <div className="d-flex my-0 ms-3">
                                            <ErrorMessage component="span" name="ageLimit" className="error-msg" />
                                        </div>
                                        <div className="d-flex my-2">
                                            <Field type="checkbox" name='termsConditions' className="mt-1 me-2" />
                                            <p className="text-white fs-14 fw-normal mb-0">I agree to staking terms & conditions</p>
                                        </div>
                                        <div className="d-flex my-0 ms-3">
                                            <ErrorMessage component="span" name="termsConditions" className="error-msg" />
                                        </div>
                                        <button type='submit' className='white-flat-btn fw-bold p-2 m-auto my-3'>
                                            Submit
                                        </button>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        );
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(MatchesFFAScore)