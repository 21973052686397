import React, { Component } from 'react'
import { capitalize } from '../../../../utils/common.utils'
import { likeDenPost } from '../../../../utils/Den.utils'
import { POST_INTERACTIONS } from '../../../../utils/Images.utils'
import { profile } from '../../../../utils/localstorage.utils';

export default class Sparks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            denProfile: props.denProfile
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            denProfile: newProps.denProfile
        });
    }

 

  render() {
    const {denProfile} = this.state;
    return (
        <div className={`${this.props.selectedPostIndex === this.props.index ? 'interacion-icons-box cursor-pointer' : 'd-none'}`} style={{marginTop:'-90px', zIndex: '1'}}>
            { Object.keys(POST_INTERACTIONS.SPARKS).map((key,i)=>{
                return(
                    <div className='d-flex flex-column align-items-center hover-zoomin' key={i}>
                        <img src={POST_INTERACTIONS.SPARKS[key].LOGO} alt='spark' style={{width:'35px', height:'35px'}} onClick={()=>{ likeDenPost(this.props.feedDetails[this.props.selectedPostIndex]._id, {denId: this.state.denProfile?._id,type: POST_INTERACTIONS.SPARKS[key].NAME}, this.props.updateLikedPostDetails, this.props.updateProfileDetails)}} />
                        <span className='fs-14 fw-600 text-white-50'>{capitalize(POST_INTERACTIONS.SPARKS[key].NAME)}</span>
                        <span className='fs-12 fw-600 text-white-50'>{denProfile?.sparks?.[POST_INTERACTIONS.SPARKS[key].NAME]}</span>
                    </div>
                )
            })}
        </div>
    )
  }
}
