import React, { Component } from 'react';
import { getMonthAndDate } from '../../../../utils/common.utils';
import { getDenProfileGameStats } from '../../../../utils/Den.utils';
import { DEN } from '../../../../utils/Strings.utils';

export default class TournamentStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserDenProfile: props.currentUserDenProfile,
            gameStats:[]
        }
    }

    componentDidMount() {
        getDenProfileGameStats(this.state.currentUserDenProfile.clanOrOrgId, this.state.currentUserDenProfile.type, (result)=>{
         
            this.setState({
                gameStats: result
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ currentUserDenProfile: nextProps.currentUserDenProfile})
    }

    getUserStats = () => {
        console.log("###### selectedGameId :: ",this.props.selectedGameId)
       
        return(
            <div className='row p-2'>
                {this.state.gameStats?.map((tournament, i) => {
                    if(tournament && (this.props.selectedGameId === 'All' || this.props.selectedGameId === tournament.gameId)){
                        return(
                            <div className='col-12 col-lg-6 my-1' key={i}>
                                <div className='' style={{borderRadius: '8px', border: '1px solid rgba(173, 38, 255, 0.5)'}}>
                                    <div className='d-flex justify-content-between p-2'>
                                        <h1 className='fs-16 fw-700 my-0'>{tournament.name}</h1>
                                        <p className='fs-10 fw-700 text-white-50 my-0'>{getMonthAndDate(tournament.start)[0]} - {getMonthAndDate(tournament.end)[0]}</p>
                                    </div>
                                    <div className='row p-2'>
                                        <div className='col-6'>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>ORGANIZATION</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.orgName}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>GAME</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.gameName}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL MATCH(S)</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.totalMatches}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>ENTRY FEE</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.entryFees.entryFeeAmount}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>PRIZE WON</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.prizeWon}</p>
                                            </div>
                                        </div>
                                    
                                        <div className='col-6'>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL WINS / TOTAL GAME(S)</p>
                                                <p className='fs-12 fw-600 my-0' style={{color:'#30D158'}}>{tournament.totalWins} / {tournament.totalGames}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL LOSSES / TOTAL GAME(S)</p>
                                            <p className='fs-12 fw-600 my-0'style={{color:'#FF3636'}}>{tournament.totalLoss} / {tournament.totalGames}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>MATCH MVP</p>
                                                <p className='fs-12 fw-600 my-0'style={{color:'#FFB800'}}>X {tournament.matchMVP}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TEAM MVP</p>
                                                <p className='fs-12 fw-600 my-0'style={{color:'#9CDAF2'}}>X {tournament.teamMVP}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>WIN RATIO</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.winRation.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    getClanStats = () => {
        return(
            <div className='row p-2'>
               {this.state.gameStats?.map((tournament, i) => {
                 if(tournament && (this.props.selectedGameId === 'All' || this.props.selectedGameId === tournament.gameId)){
                    return(
                        <div className='col-12 col-lg-6 my-1' key={i}>
                            <div className='' style={{borderRadius: '8px', border: '1px solid rgba(173, 38, 255, 0.5)'}}>
                                <div className='d-flex justify-content-between p-2'>
                                    <h1 className='fs-16 fw-700 my-0'>{tournament.name}</h1>
                                    <p className='fs-10 fw-700 text-white-50 my-0'>{getMonthAndDate(tournament.start)[0]} - {getMonthAndDate(tournament.end)[0]}</p>
                                </div>
                                <div className='row p-2'>
                                    <div className='col-6'>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>ORGANIZATION</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.orgName}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                        <p className='fs-10 fw-700 text-white-50 my-0'>GAME</p>
                                        <p className='fs-12 fw-600 my-0'>{tournament.gameName}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL PLAYERS PARTICIPATED</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.totalPlayersParticipated}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL MATCHES PLAYED</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.totalMatches}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>WIN RATE</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.winRation.toFixed(2)}</p>
                                        </div>
                                    </div>
                                
                                    <div className='col-6'>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>OVERALL RATING</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.rating}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>ENTRY FEE</p>
                                            <p className='fs-12 fw-600 my-0'>EC {tournament.entryFees.entryFeeAmount}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>TEAMS PARTICIPATED</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.totalTeamsParticipated ? tournament.totalTeamsParticipated : '--'}</p>
                                        </div>
                                        <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>MATCHES WON/NO OF GAMES</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.totalWins} / {tournament.totalGames}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                 }
                })}
            </div>
        )
    }

    getOrgStats = () => {
        return(
            <div className='row p-2'>
                 {this.state.gameStats?.map((tournament, i) => {
                    if(tournament && (this.props.selectedGameId === 'All' || this.props.selectedGameId === tournament.gameId)){
                        return(
                            <div className='col-12 col-lg-6 my-1' key={i}>
                                <div className='' style={{borderRadius: '8px', border: '1px solid rgba(173, 38, 255, 0.5)'}}>
                                    <div className='d-flex justify-content-between p-2'>
                                        <h1 className='fs-16 fw-700 my-0'>{tournament.name}</h1>
                                        <p className='fs-10 fw-700 text-white-50 my-0'>{getMonthAndDate(tournament.start)[0]} - {getMonthAndDate(tournament.end)[0]}</p>
                                    </div>
                                    <div className='row p-2'>
                                        <div className='col-6'>
                                            {/* <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>ORGANIZATION</p>
                                                <p className='fs-12 fw-600 my-0'>Hindustan League</p>
                                            </div> */}
                                            <div className='d-flex flex-column my-2'>
                                            <p className='fs-10 fw-700 text-white-50 my-0'>GAME</p>
                                            <p className='fs-12 fw-600 my-0'>{tournament.gameName}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL PLAYERS PARTICIPATED</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.totalPlayersParticipated}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL WINNERS</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.totalWinners}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL STAKING USERS</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.totalBettingUsers}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL DISPUTES</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.totalDisputes}</p>
                                            </div>
                                        </div>
                                    
                                        <div className='col-6'>
                                        <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>OVERALL RATING</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.rating}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>ENTRY FEE</p>
                                                <p className='fs-12 fw-600 my-0'>EC {tournament.entryFees.entryFeeAmount}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>STAKING ENABLED MATCHES</p>
                                                <p className='fs-12 fw-600 my-0'>{tournament.bettingEnabledMatches}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>TOTAL STAKING COLLECTION</p>
                                                <p className='fs-12 fw-600 my-0'>EC {tournament.totalBettingCollection}</p>
                                            </div>
                                            <div className='d-flex flex-column my-2'>
                                                <p className='fs-10 fw-700 text-white-50 my-0'>STAKING POOL DISTRIBUTED</p>
                                                <p className='fs-12 fw-600 my-0'>EC {tournament.bettingPoolDistributed}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    getStats = () => {
        const {currentUserDenProfile} = this.state 
        switch (currentUserDenProfile.type) {
            case DEN.SEARCH.TYPES.USER:
                return this.getUserStats();
            case DEN.SEARCH.TYPES.CLAN:
                return this.getClanStats();
            case DEN.SEARCH.TYPES.ORG:
                return this.getOrgStats();
            default:
                return this.getUserStats();
        }
    }

    render() {
        return this.getStats()
    }
}