import React, { Component } from 'react';
import ecIc from '../../../../../../images/icon/ec-ic.png';
import winCupBlue from '../../../../../../images/icon/winCupBlue-ic.png';
import Accordion from 'react-bootstrap/Accordion'
import { ASSETS } from '../../../../../../utils/Strings.utils';
import { errorHandler, updateLoader } from '../../../../../../utils/common.utils';
import BettingApis from '../../../../../../helper/betting.api';
import { toast } from 'react-toastify';

export default class BettingStatementResult extends Component {

    constructor(props) {
        super(props)
        this.state = {
            matchDetails: props.matchDetails,
            statement: props.statement,
            options: props.options,
            entryFees: props.entryFees,
            bettingPool: props.bettingPool
        }

        this.bettingApis = new BettingApis();

    }

    componentWillReceiveProps(newProps){
        this.setState({
            statement: newProps.statement,
            options: newProps.options,
            matchDetails: newProps.matchDetails,
            entryFees: newProps.entryFees,
            bettingPool: newProps.bettingPool
        })
    }

    updateMultipleBettingResults = (data) =>{

        updateLoader(true);
        this.bettingApis.updateMultipleBettingResults(data).then((res) => {
            updateLoader(false);
            toast.success("Staking result updated successfully");
           
        }).catch((reason) => {
            updateLoader(false);
            errorHandler(reason);
        });
    }

    render() {
        
        return (
            <div className='col-12 col-md-6 col-lg-3 my-3'>
                <div className="my-3 p-3 border-1 mt-3 mt-lg-5 position-relative border border-1 rounded-3">
                    <div className="d-flex justify-content-center position-absolute top-0 start-50 translate-middle px-2" style={{ backgroundColor: '#200e45' }}>
                        <p className='fs-14 fw-600 my-0' style={{ width: 'max-content' }}>Staking Statement {this.props.index}</p>
                    </div>
                    <div className="">
                        <label htmlFor='statement' className="fs-14 fw-600 text-white">Statement</label>
                        <div className="">
                            <input type="text" placeholder="" value={this.state.statement?.statement?.statement} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor='options' className="fs-14 fw-600 text-white">Options</label>
                        {((typeof this.state.statement?.options) !== 'string') ?
                            <section className='bet-options'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item className='mt-3 rounds-time'>
                                        <Accordion.Header className='round-slot-details rounds-time'>Preview Options</Accordion.Header>
                                        <Accordion.Body>
                                            {this.state.statement?.options?.map((player, index) => {
                                                if(this.state.statement.statement.type === 'CUSTOM'){
                                                    return(
                                                        <div className={`mulberry-purple-card d-flex align-items-center py-2 mb-2 ${ (this.state.statement.correctChoice === player) ? 'epz-active-field' : ''}`} key={index} >
                                                            <div className="col-12 fw-600 fs-14 text-white text-truncate" onClick={()=>this.props.onSelectCorrectAnswer(this.props.indexId, player, this.state.statement)}>
                                                                <img className="rounded-circle btn-size-32 border border-1 mx-3" src={ASSETS.OPTIONS[index]} alt="owner-avatar" />
                                                                {player}
                                                            </div>
                                                        </div>
                                                    );
                                                }else{
                                                    return(
                                                        <div className={`mulberry-purple-card d-flex align-items-center py-2 mb-2 ${ (this.state.statement.correctChoice === player._id) ? 'epz-active-field' : ''}`} key={index} >                                                        
                                                            <div className="col-12 fw-600 fs-14 text-white text-truncate" onClick={()=>this.props.onSelectCorrectAnswer(this.props.indexId, player._id, this.state.statement)}>
                                                                <img className="rounded-circle btn-size-32 border border-1 mx-3" src={player?.logo?.url ? player?.logo?.url : ASSETS.AVATAR} alt="owner-avatar" />
                                                                { this.props.isTeamGame ? player.name : player.inGameName }
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </section>
                        :
                            <div className="">
                                <input type="text" placeholder="" value={this.state.statement.options} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                            </div>
                        }
                    </div>

                    <div className='ps-2 pe-2'>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <div className='h-100 d-flex align-items-center'>
                                    <img src={ecIc} className='btn-size-15 me-2' alt=''/>
                                    <label htmlFor='options' className="fs-14 fw-600 text-white mb-1">Entry Fees</label>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="d-flex justify-content-end">
                                    <label htmlFor='options' className="fs-14 fw-600 mb-1 color-blue">EC {this.state.statement.entryFees}</label>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-6'>
                                <div className='h-100 d-flex align-items-center'>
                                    <img src={winCupBlue} className='btn-size-15 me-2' alt=''/>
                                    <label htmlFor='options' className="fs-14 fw-600 text-white mb-1">Staking pool</label>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="d-flex justify-content-end">
                                    <label htmlFor='options' className="fs-14 fw-600 mb-1 color-blue">EC {this.state.statement.bettingPool}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}