import React, { Component } from 'react';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Icons
import { FiPlus } from "react-icons/fi";
import stages from "../../../../images/icon/stages.png";

import '../../../../styles/Tournaments.css';
import TournamentHeader from '../common/TournamentHeader';
import { errorHandler, getMatchesStructure, getTournamentData, is_Natural, PlayoffFormats, updateLoader } from '../../../../utils/common.utils';
import TournamentApis from '../../../../helper/tournament.api';
import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';

import { AiFillDelete, AiOutlineCheck } from "react-icons/ai";
import { Modal } from 'react-bootstrap';
import ConfirmationModal from '../../../popup/ConfirmationModal';
import ReactTooltip from 'react-tooltip';
import { BsInfoCircle } from 'react-icons/bs';
import { ERROR, LABEL, PLACEHOLDER, WARNING } from '../../../../utils/Strings.utils';

export default class FFlexibleStage extends Component {

    constructor(props) {
        super(props)
        const queryParams = new URLSearchParams(window.location.search);
        let localData = JSON.parse(localStorage.getItem('ffa-flexible-stage'))
        this.state = {
            tournamentDetails: null,
            openConfirmationModal: false,
            openEditConfirmationModal: false,
            threshold: (!queryParams.get('isUpdate') && localData?.threshold && (localData?.tournamentId === props.match.params.id)) ? localData.threshold : '',
            updatedMatches: false,
            stageName: (!queryParams.get('isUpdate') && localData?.stageName && (localData?.tournamentId === props.match.params.id)) ? localData.stageName : '',
            numberOfMatches: (!queryParams.get('isUpdate') && localData?.numberOfMatches && (localData?.tournamentId === props.match.params.id)) ? localData.numberOfMatches : '',
            maxParticipantsPerMatch: (!queryParams.get('isUpdate') && localData?.maxParticipantsPerMatch && (localData?.tournamentId === props.match.params.id)) ? localData.maxParticipantsPerMatch : '',
            qualificationThreshold: (!queryParams.get('isUpdate') && localData?.qualificationThreshold && (localData?.tournamentId === props.match.params.id)) ? localData.qualificationThreshold : '',
            playoffFormat: (!queryParams.get('isUpdate') && localData?.playoffFormat && (localData?.tournamentId === props.match.params.id)) ? localData.playoffFormat : '',
        }
        this.localObject = {
            threshold: (!queryParams.get('isUpdate') && localData?.threshold && (localData?.tournamentId === props.match.params.id)) ? localData.threshold : '',
            stageName: (!queryParams.get('isUpdate') && localData?.stageName && (localData?.tournamentId === props.match.params.id)) ? localData.stageName : '',
            numberOfMatches: (!queryParams.get('isUpdate') && localData?.numberOfMatches && (localData?.tournamentId === props.match.params.id)) ? localData.numberOfMatches : '',
            maxParticipantsPerMatch: (!queryParams.get('isUpdate') && localData?.maxParticipantsPerMatch && (localData?.tournamentId === props.match.params.id)) ? localData.maxParticipantsPerMatch : '',
            qualificationThreshold: (!queryParams.get('isUpdate') && localData?.qualificationThreshold && (localData?.tournamentId === props.match.params.id)) ? localData.qualificationThreshold : '',
            playoffFormat: (!queryParams.get('isUpdate') && localData?.playoffFormat && (localData?.tournamentId === props.match.params.id)) ? localData.playoffFormat : '',
            tournamentId: this.props.match.params.id
        }
        this.minimumParticipants = 0
        updateLoader(true);
        getTournamentData(this.props.match.params.id, this.setData)

        this.tournament = new TournamentApis();

        this.isUpdate = queryParams.get('isUpdate');
        this.index = queryParams.get('index');
        this.editIsUpdate = false;
        this.editStages = [];
        this.isValuesChanged = false;
        this.currentStages = null;
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            var lastIndex;
            if (this.isUpdate) {
                lastIndex = (this.index - 1);
            } else {
                lastIndex = (values?.stages?.length - 1);
            }
            this.minimumParticipants = (values?.stages[lastIndex]?.qualificationThreshold * values?.stages[lastIndex]?.numberOfMatches);
            this.setState({
                tournamentDetails: values,
                stageName: values?.stages[this.index]?.name ? values?.stages[this.index]?.name : this.state.stageName,
                numberOfMatches: values?.stages[this.index]?.numberOfMatches ? values?.stages[this.index]?.numberOfMatches : this.state.numberOfMatches,
                maxParticipantsPerMatch: values?.stages[this.index]?.maxParticipantsPerMatch ? values?.stages[this.index]?.maxParticipantsPerMatch : this.state.maxParticipantsPerMatch,
                qualificationThreshold: values?.stages[this.index]?.qualificationThreshold ? values?.stages[this.index]?.qualificationThreshold : this.state?.qualificationThreshold,
                playoffFormat: values?.stages[this.index]?.playoffFormat ? values?.stages[this.index]?.playoffFormat : this.state.playoffFormat,
            });
            updateLoader(false);
        } else {
            updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    onSubmit = (values) => {
        var isUpdate;
        if (this.isUpdate === null) {
            isUpdate = false;
        } else {
            isUpdate = true;
        }


        var stage = {
            level: this.isUpdate ? this.currentStages[this.index].level : ((this.state.tournamentDetails?.stages?.length) + 1),
            name: values.stageName,
            type: 'FFA',
            subType: 'FFA Flexible Stage',
            path: 'ffa-flexible-stage',
            status: 'UPCOMING',
            numberOfMatches: parseInt(values.numberOfMatches),
            maxParticipantsPerMatch: parseInt(values.maxParticipantsPerMatch),
            qualificationThreshold: parseInt(values.qualificationThreshold),//qualificationThreshold
            playoffFormat: (values.playoffFormat ? parseInt(values.playoffFormat) : 1)

        }

        if (isUpdate && !this.state.updatedMatches) {
            stage.groups = this.currentStages[this.index].groups;
        }
        else {
            stage.groups = getMatchesStructure(stage, 8);
        }

        this.editIsUpdate = isUpdate;
        this.editStages = [stage];
        let previousStageData = this.state.tournamentDetails?.stages[this.index]

        if (isUpdate) {
            if (
                previousStageData.name !== values.stageName ||
                previousStageData.numberOfMatches !== values.numberOfMatches ||
                previousStageData.maxParticipantsPerMatch !== values.maxParticipantsPerMatch ||
                previousStageData.threshold !== values.threshold ||
                previousStageData.qualificationThreshold !== values.qualificationThreshold ||
                previousStageData.playoffFormat !== stage.playoffFormat
            ) {
                this.isValuesChanged = true;
            }
        }

        if (isUpdate && (parseInt(this.index) !== parseInt((this.state.tournamentDetails?.stages.length - 1))) && this.isValuesChanged) {
            this.setState({ openEditConfirmationModal: true })
        } else {
            this.addOrUpdate([stage], isUpdate);
        }
    }

    addOrUpdate = (stages, isUpdate) => {
        if (this.state.tournamentDetails?.participantUserId?.length && this.isUpdate) {
            toast.error('Cannot update the stage after participants added in tournament')
        } else {
            updateLoader(true);
            //addOrUpdateFlexibleStage
            this.tournament.addOrUpdateStage(this.props.match?.params?.id, stages, isUpdate)
                .then((res) => {

                    updateLoader(false);
                    if (isUpdate) {

                        this.props.history.push(`/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure`);
                        toast.success(`Stage updated successfully`);

                    } else {
                        localStorage.removeItem('ffa-flexible-stage')
                        var index = this.state.tournamentDetails?.stages?.length === 0 ? 0 : this.state.tournamentDetails?.stages?.length;
                        this.props.history.push(`/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure/tournament-stages/${index}/slot-details/0`);
                        toast.success(`Stage created successfully`);
                    }
                }).catch((reason) => {

                    updateLoader(false);
                    errorHandler(reason);
                });
        }
    }

    deleteStage = (index) => {
        var data = {
            tournamentId: this.props.match?.params?.id,
            stage: index
        }
        // var stages = this.state.tournamentDetails.stages;
        // stages.splice(index, 1);
        // this.update(stages,"deleted");
        updateLoader(true)
        this.tournament.deleteStage(data)
            .then((res) => {
                updateLoader(false);
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview/structure`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
                toast.success(`Stage ${parseInt(index) + 1} deleted successfully`)

            }).catch((reason) => {

                updateLoader(false);
                errorHandler(reason);
            });
    }

    //open confirmation popup
    onClickOpenConfirm = () => {
        this.setState({
            openConfirmationModal: !this.state.openConfirmationModal,
        });
    };

    updateQuantity = (e, key) => {
        e.preventDefault()
        if (!this.isUpdate) {
            this.localObject[key] = e.target.value;
            localStorage.setItem('ffa-flexible-stage', JSON.stringify(this.localObject))
        }
        this.setState({
            [key]: e.target.value,
            updatedMatches: true
        })
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="solid" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    noOfMatchesValidation = (val) => {
        let error;
        let number = parseFloat(val)
        if (!is_Natural(number)) {
            error = ERROR.INVALID_INPUT;
        } else if (number < 1) {
            error = "Please enter minimum 1 match"
        }
        return error
    }

    participantPerMatchValidation = (val) => {
        let error;
        let number = parseFloat(val)
        if (this.state.numberOfMatches === 0) {
            error = 'Number of matches cannot be 0';
            return error;
        }
        if (!is_Natural(number)) {
            error = ERROR.INVALID_INPUT;
        } else if (this.state.numberOfMatches && this.minimumParticipants && ((number * parseInt(this.state.numberOfMatches)) < (this.minimumParticipants))) {
            error = `Minimum participant per match is ${Math.ceil(this.minimumParticipants / this.state.numberOfMatches)}`
        } else if (number < 3) {
            error = "Please enter minimum 3 participants"
        }
        return error
    }

    thresholdValidation = (val) => {
        let error;
        let number = parseFloat(val)
        if (!is_Natural(number)) {
            error = ERROR.INVALID_INPUT;
        } else if (number < 1) {
            error = "Minimum threshold should 1"
        } else if (number > (this.state.maxParticipantsPerMatch - 1)) {
            error = `Maximum threshold should ${(this.state.maxParticipantsPerMatch - 1)}`
        }
        return error
    }
    render() {
        this.currentStages = this.state.tournamentDetails ? this.state.tournamentDetails.stages : false;
        const isUpdated = ((this.isUpdate && this.currentStages) ? true : false);
        const initials = {
            stageName: this.state.stageName,
            numberOfMatches: this.state.numberOfMatches,
            maxParticipantsPerMatch: this.state.maxParticipantsPerMatch,
            qualificationThreshold: this.state.qualificationThreshold,
            playoffFormat: this.state.playoffFormat,
        };

        return (
            <section className="body-section clanView-page ffa-single-elimination espotz-form-control tooltip-text">
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={stages} heading={`${isUpdated ? "Update" : "Add"} Stage Details`}
                        subHeading={<h1 className="stage-sub-heading text-center">( FFA Flexible Stage )</h1>}
                        history={this.props.history} />

                    <Formik
                        enableReinitialize
                        initialValues={initials}

                        validationSchema={Yup.object({
                            stageName: Yup.string().required("*required").max(30, 'Max 30 characters'),
                            //.oneOf([2,4,8,16,32,64,128,256,512,1024,2048,4096,8192,16384],'Max participants should be in form of 2,4,8,16,32...'),
                            qualificationThreshold: Yup.string().required("*required"),
                            numberOfMatches: Yup.string().required("*required"),
                            maxParticipantsPerMatch: Yup.string().required("*required")
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.onSubmit(values)
                                setSubmitting(false);
                            }, 400);
                        }}

                    >
                        <Form className="row f-flexible-form m-1 mt-lg-5" autoComplete='off'>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="stageName" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">{LABEL.STAGE_NAME}</label>
                                <Field name="stageName" onKeyUp={e => this.updateQuantity(e, 'stageName')} placeholder={PLACEHOLDER.STAGE_NAME} type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" />
                                <ErrorMessage component="span" className="error-msg" name="stageName" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="numberOfMatches" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">{LABEL.NUMBER_OF_MATCHES}</label>

                                <a href="#" title="It is No. of Matches that are going to be Played in Total Participants." class="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field validate={this.noOfMatchesValidation} onKeyUp={e => this.updateQuantity(e, 'numberOfMatches')} name="numberOfMatches" type="number" placeholder={PLACEHOLDER.NUMBER_OF_MATCHES} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    min={1} step={1} onWheel={(e) => e.target.blur()} />
                                <ErrorMessage component="span" className="error-msg" name="numberOfMatches" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="maxParticipantsPerMatch" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">{LABEL.PARTICIPANTS_PER_MATCH}</label>

                                <a href="#" title="Maximum No. of Participants that can Participate in Tournament or Stage" class="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="maxParticipantsPerMatch" validate={this.participantPerMatchValidation} onKeyUp={e => this.updateQuantity(e, 'maxParticipantsPerMatch')} type="number" placeholder={PLACEHOLDER.PARTICIPANTS_PER_MATCH} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    min={1} step={1} onWheel={(e) => e.target.blur()} />
                                <ErrorMessage component="span" className="error-msg" name="maxParticipantsPerMatch" />
                            </div>




                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="qualificationThreshold" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">{LABEL.THRESHOLD} ( Per Match )</label>

                                <a href="#" title="No. of Participants that will get Qualified at the end of Stage." className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field name="qualificationThreshold" validate={this.thresholdValidation} onKeyUp={e => this.updateQuantity(e, 'qualificationThreshold')} type="number" placeholder={PLACEHOLDER.THRESHOLD} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                    min={1} step={1} onWheel={(e) => e.target.blur()} />
                                <ErrorMessage component="span" className="error-msg" name="qualificationThreshold" />
                            </div>

                            <div className="col-lg-6 col-md-6 col-12">
                                <label htmlFor="playoffFormat" className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3">Playoff Format</label>

                                <a href="#" title="Defines the Match Format Of Particular Stage." className="tooltip-info">
                                    <BsInfoCircle className="text-white btn-size-12 ms-1 mt-2" />
                                </a>
                                <Field value={this.state.playoffFormat} onChange={e => this.updateQuantity(e, 'playoffFormat')} name="playoffFormat" as="select" id="playOff" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                    {Object.keys(PlayoffFormats).map(function (key, i) {
                                        return <option key={i} value={PlayoffFormats[key]}>{key}</option>
                                    })}
                                </Field>
                                <ErrorMessage component="span" className="error-msg" name="playoffFormat" />
                            </div>


                            <div className="mt-5 d-flex justify-content-center">
                                {isUpdated ?
                                    <div className='d-flex justify-content-center'>
                                        <button type="submit" className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45">
                                            <AiOutlineCheck className="fs-24" />
                                        </button>
                                        <button type="button" onClick={(e) => this.onClickOpenConfirm(e)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45">
                                            <AiFillDelete className="fs-24" />
                                        </button>
                                    </div>
                                    :
                                    <button type="submit" className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-45">
                                        <FiPlus className="fs-24" />
                                    </button>
                                }
                            </div>
                        </Form>
                    </Formik>
                </div>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.deleteStage}
                            confirm={{
                                msg: (
                                    (parseInt(this.index) === parseInt((this.state.tournamentDetails?.stages.length - 1))) ?
                                        <p className="fs-14 fw-600 text-center text-white-50">
                                            Are you sure you want to remove <span className="error-msg">{isUpdated ? this.currentStages[this.index]?.name : ""}</span> from the stages ?
                                        </p> :
                                        <p className="fs-14 fw-600 text-center text-white-50">
                                            On the the deletion of <span className="error-msg">{isUpdated ? this.currentStages[this.index]?.name : ""}</span> stage, Next stages will be get deleted automatically !
                                        </p>
                                ),
                                key: (isUpdated ? this.index : null)
                            }}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openEditConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={{
                                msg: (
                                    <p className="fs-14 fw-600 text-center text-white-50">
                                        {WARNING.NEXT_STAGE_WILL_BE_DELETED}
                                    </p>
                                ),
                                key: (isUpdated ? this.index : null)
                            }}
                            onClickOpenConfirm={() => { this.setState({ openEditConfirmationModal: false }) }}
                            confirmed={() => { this.addOrUpdate(this.editStages, this.editIsUpdate) }} />
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}
