import React, { Component } from 'react';
import validator from 'validator';

//images
import camera from '../../../images/den/camera.png';
import cover from '../../../images/den/cover.png';
import profileLogo from '../../../images/den/den-profile-logo.png';
import discordIcon from '../../../images/icon/discord.png';
import male from '../../../images/den/male.png';
import female from '../../../images/den/female.png';
import nonveg from '../../../images/den/nonveg.png';
import veg from '../../../images/den/veg.png';
//icons
import {  BsCheckCircleFill, BsPersonLinesFill, BsDiscord } from 'react-icons/bs';
import { FiEye, FiEyeOff, FiUser, FiEdit, FiRefreshCw } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import { AiFillDelete, AiFillPlusCircle, AiOutlineLock, AiOutlineMail, AiOutlineMinusCircle, AiOutlinePoweroff } from 'react-icons/ai';
import { FaGlobeAfrica, FaUserAlt } from 'react-icons/fa';
import { Dropdown, Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { getAllGamesDetails } from '../../../utils/Games.utils';
import { toast } from 'react-toastify';
import ClanOrgModal from '../../popup/ClanOrgModal';
import { GoVerified } from 'react-icons/go';
import { getMonthYear } from '../../../utils/dateTime.utils';
import { capitalize, errorHandler, loadCountryCodes, updateLoader } from '../../../utils/common.utils';
import CommonApis from '../../../helper/common.api';
//components
import ReactTooltip from 'react-tooltip';
import { getToken, profile } from '../../../utils/localstorage.utils';
import { DEN_PROFILE_TAGS, VerificationStatus } from '../../../utils/enums.utils';
import { HiOutlineTrash } from 'react-icons/hi';
import DenApi from '../../../helper/den.api';
import ConfirmationModal from '../../popup/ConfirmationModal';
import { emailValidation, requiredField } from '../../../utils/Validations';
import AuthApis from '../../../helper/auth.api';
import UpdatePassword from '../../common/UpdatePassword';
import { LOGOS, POST_INTERACTIONS, SOCIAL_MEDIA_ICONS } from '../../../utils/Images.utils';
import Image from '../../common/Image';
//import { MultiSelect } from "react-multi-select-component";
import { Multiselect } from "multiselect-react-dropdown";
import { DEN, VERIFICATION_STATUS } from '../../../utils/Strings.utils';
import ImagePreview from '../../common/ImagePreview';

export default class DenProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            discordConnected: true,
            profileLogo: null,
            profileCover: null,
            extraDetails: SOCIAL_MEDIA_ICONS.MEDIA_ASSETS,
            openSocialMediaModal: false,
            openClanOrgModal: false,
            isClanModal: false,
            editDataObj: null,
            gamesArray: [],
            socialMediaDetails: [],
            myClan: [],
            myOrganization: [],
            name: '',
            AKA: '',
            email: '',
            countryCodes: null,
            city: '',
            gender: 'male',
            foodPreference: 'veg',
            profileTag: 'Player',
            miniDescription: '',
            bio: '',
            gameOfInterest: [],
            isPublic: true,
            age: '',
            editName: false,
            editUsername: false,
            country: null,
            discordProfile: props.discordProfile,
            publicStatus: {
                matchPlayed: true,
                matchWin: true,
                spotlightCollection: true,
                mvpCount: true,
                gameStats: true
            },
            PrivateFields: {
                age: true,
                city: true,
                country: true,
                gender: true,
                foodPreference: true
            },
            privateStatus: {
                bettingStatistics: true,
                tournamentCount: true,
                age: true,
                city: true,
                country: true,
                gender: true,
                foodPreference: true
            },
            verificationStatus: VerificationStatus.NOT_VERIFIED,
            isDenProfileDeleted: false,
            isDenProfileActive: true,
            openDeleteCnf: null,
            updatePassword: false,
            showPassword: false,
            showNewPassword: false,
            nameErr: '',
            validUsername: true,
            userNameErr: '',
            nameReadOnly: true,
            emailReadOnly: true,
            usernameReadonly: true,
            requireArray: [],
            deleteKey: null,
            deleteIndex: null,
            editIndex: null,
            selectedGames: [],
            previewImageDetails: {
                display: false,
                src: LOGOS.USER
            },
        };
        this.requiredFields = ['age', 'country', 'city']
        this.commonApi = new CommonApis()
        this.denApi = new DenApi()
        this.authApi = new AuthApis();
        getAllGamesDetails(this.setAllGamesDetails);
        loadCountryCodes(this.setCountryCodes);
        this.authUser = profile();
        // VerificationStatus
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.updateForm(this.props.authUser);
        this.setState({
            name: this.authUser.name,
            AKA: this.authUser.username,
            email: this.authUser.email
        })
        //this.getProfile()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.discordProfile !== this.props.discordProfile) {
            this.setState({ discordProfile: this.props.discordProfile })
        }
    }
    getProfile = () => {
        updateLoader(true);
        this.denApi.getCurrentProfile().then(
            (res) => {
                let data = res.data.result;
                let den = res.data.result?.denProfile;
                this.setState({
                    isDenProfileActive: (den?.isActive === true) ? true : false,
                    verificationStatus: data.verificationStatus,
                    isDenProfileDeleted: (den?.isDeleted === true) ? true : false,
                    profileLogo: den?.profilePicture ? den?.profilePicture : this.props.authUser.user.profilePicture,
                    AKA: den?.AKA ? den?.AKA : '',
                    username: den?.AKA ? den?.AKA : '',
                    bio: den?.bio ? den.bio : this.state.bio,
                    name: den?.name ? den.name : this.state.name,
                    email: den?.email ? den.email : this.state.email,
                    miniDescription: den?.miniDescription ? den.miniDescription : this.state.miniDescription,
                    profileTag: den?.profileTag ? den.profileTag : this.state.profileTag,
                    myClan: den?.myClan ? den.myClan : this.state.myClan,
                    myOrganization: den?.myOrganization ? den?.myOrganization : this.state.myOrganization,
                    gender: den?.gender ? den.gender : this.state.gender,
                    foodPreference: den?.foodPreference ? den.foodPreference : this.state.foodPreference,
                    age: den?.age ? den.age : '',
                    country: den?.countryCode ? JSON.stringify(den.countryCode) : this.state.country,
                    city: den?.city ? den.city : this.state.city,
                    isPublic: (den?.isPublic === true) ? true : false,
                    profileCover: den?.profileCover ? den.profileCover : this.state.profileCover,
                    gameOfInterest: den?.gameOfInterest?.length ? this.state.gamesArray.filter(el => den.gameOfInterest.includes(el._id)) : [],
                    socialMediaDetails: den?.socialMedia ? den.socialMedia : this.state.socialMediaDetails,
                    PrivateFields: den?.PrivateFields ? den.PrivateFields : this.state.PrivateFields,
                    publicStatus: den?.publicStatus ? den.publicStatus : this.state.publicStatus,
                    privateStatus: den?.privateStatus ? den.privateStatus : this.state.privateStatus,
                    //extraDetails: den?.socialMedia ? den.socialMedia : this.state.extraDetails
                })
                updateLoader(false);
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err);
            }
        )
    }
    updateDenProfile = () => {
        //Add validations
        const {name, AKA, city, age, bio, miniDescription, gameOfInterest} = this.state;
        let alphaNumericUnderScoreRegex = new RegExp('^[a-zA-Z0-9_-]*$');
        let alphabetRegex = new RegExp('^[a-zA-Z ]*$');
        let numberRegex = new RegExp('^[0-9]*$');

        //Name validations
        if(name.trim() === ''){
            toast.error('Name is required');
            return false;
        }
        if(!alphabetRegex.test(name.trim())){
            toast.error('Name should only contain alphabets');
            return false;
        }

        //User name validations
        if(AKA.trim() === ''){
            toast.error('Username is required');
            return false;
        }
        if(!alphaNumericUnderScoreRegex.test(AKA.trim())){
            toast.error('Username allows only alpha numerics and underscore');
            return false;
        }
        if(numberRegex.test(AKA.trim())){
            toast.error('Username cannot be only numbers');
            return false;
        }

        //City validations
        if(city.trim() === ''){
            toast.error('City is required');
            return false;
        }
        if(!alphabetRegex.test(city.trim())){
            toast.error('City should only contain alphabets');
            return false;
        }

        //Age validations
        if(age === ''){
            toast.error('Age is required');
            return false;
        }
        if(!(age >=10 && age <=100)){
            toast.error('Age must be in range of  10 and 100');
            return false;
        }
        //Bio and mini description validations
        // if(bio.trim() === ''){
        //     toast.error('Bio is required');
        //     return false;
        // }
        if(miniDescription.trim() === ''){
            toast.error('Mini description is required');
            return false;
        }

        if(!gameOfInterest.length){
            toast.error('Please select atleast on game of interest');
            return false;
        }

        let reqFields = []
        for (let key of this.requiredFields) {
            if (!this.state[key]) {
                reqFields.push(key)
            }
        }
        let countrycode = this.state.country ? JSON.parse(this.state.country) : {};
        let data = {
            // profilePicture: this.state.profileLogo,
            denData: {
                username: this.state.username.trim(),
                name: this.state.name.trim(),
                email: this.state.email,
                AKA: this.state.AKA.trim(),
                bio: this.state.bio,
                miniDescription: this.state.miniDescription,
                profileTag: this.state.profileTag,
                myClan: this.state.myClan,
                myOrganization: this.state.myOrganization,
                gender: this.state.gender,
                foodPreference: this.state.foodPreference,
                age: this.state.age,
                countryCode: countrycode,
                city: this.state.city,
                isPublic: this.state.isPublic,
                profileCover: this.state.profileCover,
                gameOfInterest: this.state.gameOfInterest.filter(el => typeof el === 'object'),
                socialMedia: this.state.socialMediaDetails,
                PrivateFields: this.state.PrivateFields,
                publicStatus: this.state.publicStatus,
                privateStatus: this.state.privateStatus,
                profilePicture: this.state.profileLogo,
                type: DEN.SEARCH.TYPES.USER,
                clanOrOrgId: profile().id
            }
        }
        if (reqFields.length) {
            this.setState({ requireArray: reqFields })
        } else {
            this.updateApiCall(data, 'Profile updated successfully', 100)
        }
    }
    updateApiCall = (data, successMsg, key) => {
        updateLoader(true);
        this.denApi.updateDenProfile(data).then(
            (res) => {
                if (key === 101) {
                    this.setState({ verificationStatus: VerificationStatus.VERIFICATION_REQUESTED })
                } else if (key === 102) {
                    this.setState({ openDeleteCnf: null, isDenProfileDeleted: true })
                } else if (key === 103) {
                    this.setState({ openDeleteCnf: null, isDenProfileActive: false })
                }else if (key === 104) {
                    this.setState({ openDeleteCnf: null, isDenProfileActive: true })
                }
                updateLoader(false);
                toast.success(successMsg)
            }
        ).catch(
            (err) => {
                console.error("profile errror - ", err)
                updateLoader(false);
                errorHandler(err);
            }
        )
    }
    verificationRequest = () => {
        let data = { verificationStatus: VerificationStatus.VERIFICATION_REQUESTED }
        this.updateApiCall(data, 'Applied for verification', 101)
    }
    deleteDenProfile = () => {
        let data = {
            denData: { isDeleted: true }
        }
        this.updateApiCall(data, 'Den profile deleted successfully', 102)
    }
    deactivateDenProfile = () => {
        let data = {
            denData: { isActive: false }
        }
        this.updateApiCall(data, 'Den profile deactivated successfully', 103)
    }
    activateDenProfile = () => {
        let data = {
            denData: { isActive: true }
        }
        this.updateApiCall(data, 'Den profile activated successfully', 104)
    }
    deleteClanOrg = () => {
        const {deleteKey, deleteIndex} = this.state;
        if(deleteKey === 'clan'){
            let {myClan} = this.state;
            myClan.splice(deleteIndex,1);
            this.setState({ myClan: myClan, openDeleteCnf: false});
        }else{
            let { myOrganization } = this.state;
            myOrganization.splice(deleteIndex, 1);
            this.setState({ myOrganization: myOrganization, openDeleteCnf: false});
        }
    }
    confirmAction = (key) => {
        if (key === 'delete') {
            this.deleteDenProfile()
        } else if (key === 'deactivate') {
            this.deactivateDenProfile()
        }else if(key === 'activate'){
            this.activateDenProfile()
        }else{
            this.deleteClanOrg()
        }
    }
    updateForm = (authUser) => {
        this.setState({
            user: authUser.user,
            name: authUser.user?.name,
            email: authUser.user?.email,
            username: authUser.user?.username
        });
    }

    refreshMyProfileSection = () => {
        this.setState({
            nameReadOnly: true,
            emailReadOnly: true,
            usernameReadonly: true,
            user: this.props.authUser.user,
            openLogout: false,
            updatePassword: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
    }

    updateProfile = (e, param) => {
        e.preventDefault();
        var data;
        if (param === "profilePicture") {
            data = {
                [param]: this.profileLogoUrl
            }
        } else if (param === 'username') {
            data = {
                [param]: this.state.username
            }
        } else {
            data = {
                [param]: this.state[param]
            }
        }

        if (param === "email") {
            this.validateEmail()
        }

        if ((param === "email" && this.state.emailErr) || !this.state.validName || !this.state.validUsername) {
            return 0;
        }
        this.props.updateLoader(true);
        this.authApi.updateProfile(data)
            .then((response) => {

                this.refreshMyProfileSection();
                localStorage.setItem('login', JSON.stringify({
                    login: true,
                    token: getToken(),
                    authUser: {
                        user: response.data.result
                    }
                }));
                if (param === "profilePicture") {
                    this.setState({
                        profileLogo: this.profileLogoUrl.url
                    });
                }

                this.props.storeCollector();
                this.updateForm({ user: response.data.result });
                this.props.updateLoader(false);
                toast.success(capitalize(param) + " updated successfully !!");
            }).catch((reason) => {
                this.props.updateLoader(false);
                errorHandler(reason);
            });

    }
    openCnf = (key, index) => {
        let data = null
        if (key === 'deactivate') {
            data = {
                msg: <p className="fs-14 fw-600 text-center text-white">Are you sure, you want to deactivate your den profile?</p>,
                key: 'deactivate'
            }
        } else if (key === 'delete') {
            data = {
                msg: <p className="fs-14 fw-600 text-center text-white">Are you sure, you want to delete your den profile?</p>,
                key: 'delete'
            }
        } else if(key === 'activate'){
            data = {
                msg: <p className="fs-14 fw-600 text-center text-white">Are you sure, you want to activate your den profile?</p>,
                key: 'activate'
            }
        }else{
            data = {
                msg: <p className="fs-14 fw-600 text-center text-white">{`Are you sure, you want to delete this ${key} from your den profile?`}</p>,
                key: key
            }
            this.setState({deleteKey: key, deleteIndex: index})
        }
        this.setState({ openDeleteCnf: data })
    }
    setCountryCodes = (codes) => {
        if (codes) {
            this.setState({ countryCodes: codes, country: JSON.stringify(codes[0])});
        }
    }

    setAllGamesDetails = (response) => {
        this.setState({ gamesArray: response });
        this.getProfile()
    }

    //to append org social contacts in tournament
    appendOrgDetails = (e) => {
        this.setState({ checked: !this.state.checked })
        if (!this.state.orgSocialDetails.contact) {
            toast.error('There is no contact details in organization');
            this.setState({ checked: false });
            return false;
        }
        let data = this.state.extraDetails
        let incoming = this.state.orgSocialDetails.contact
        if (this.state.checked) {
            for (let key in incoming) {
                let index = data.map(el => { return el.id }).indexOf(key)
                if (index >= 0) {
                    data[index].value = '';
                    data[index].isChecked = false;
                }
            }
        } else {
            for (let key in incoming) {
                let index = data.map(el => { return el.id }).indexOf(key)
                if (index >= 0) {
                    data[index].value = incoming[key];
                    data[index].isChecked = true;
                }
            }
        }
        this.setState({ extraDetails: data, socialMediaDetails: data })
    }

    showFields = (e, i, type) => {
        e.preventDefault()
        const newArray = this.state.extraDetails
        let temp = this.state.extraDetails[i]
        temp.isChecked = type
        if (type === false) {
            temp.value = ''
        }
        newArray[i] = temp
        this.setState({ extraDetails: newArray })
    }

    updateDetails = (e, i) => {
        let { extraDetails } = this.state;
        extraDetails[i].value = e.target.value
        this.setState({ extraDetails });
    }

    getAddButton = () => {
        const { extraDetails } = this.state
        const booleanArray = extraDetails.map(detail => detail.isChecked)
        if (booleanArray.includes(true)) {
            return true
        }
        return false
    }

    validateDetails = () => {
        let errors = 0
        const filledFields = this.state.extraDetails
        filledFields.map((field) => {
            if (field.value !== '') {
                // const res = field.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (!field.value.includes(field.id)) {
                    field.error = 'Enter url of respective field';
                    errors += 1
                }
                else if ((validator.isURL(field.value)) && (field.value.includes(field.id))) {
                    field.error = ''
                }
                else {
                    errors += 1
                    field.error = 'Enter a valid url'
                }
            }
        })
        this.setState({ socialFieldArr: filledFields })
        if (errors > 0) {
            return false
        } else {
            return true
        }
    }

    addDetails = () => {
        if (this.validateDetails()) {
            const { extraDetails } = this.state
            this.setState({
                socialMediaDetails: extraDetails,
                openSocialMediaModal: !this.state.openSocialMediaModal
            })

        }
    }

    changePublicPrivateStat = (type, key, val) => {
        let data = this.state[type]
        data[key] = val;
        this.setState({ type: data })
    }

    handleClanOrgModal = (val1, val2) => {
        this.setState({
            openClanOrgModal: val1,
            isClanModal: val2,
            editDataObj: (!val1 && !val2) ? null : this.state.editDataObj
        });
    }

    appendData = (data) => {
        if (this.state.isClanModal) {
            const { myClan } = this.state;
            let updatedData;
            if(this.state.editDataObj){
                myClan.splice(this.state.editIndex, 1, data);
                updatedData = myClan;
            }else{
                updatedData = [...myClan, data];
            }
            this.setState({
                myClan: updatedData,
                isClanModal: false,
                openClanOrgModal: false,
                editDataObj: null,
                editIndex: null
            })
        } else {
            const { myOrganization } = this.state;
            let updatedData;
            if(this.state.editDataObj){
                myOrganization.splice(this.state.editIndex, 1, data);
                updatedData = myOrganization;
            }else{
                updatedData = [...myOrganization, data];
            }
            this.setState({
                myOrganization: updatedData,
                isClanModal: false,
                openClanOrgModal: false,
                editDataObj: null,
                editIndex: null
            })
        }
    }

    addImg = (event, key) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                toast.warning('Please select a valid image type.');
                return false;
            }
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            updateLoader(true);
            this.commonApi
                .uploadSingleImg(formData)
                .then((response) => {
                    this.setState({
                        [key]: response.data.result
                    })
                    updateLoader(false);
                })
                .catch((err) => {
                    updateLoader(false);
                    console.error(err);
                })
        }
    }
    onSubmit = (values) => {
        // e.preventDefault();
        // this.setState({
        var data = {
            oldPassword: values.oldPassword.trim(),
            newPassword: values.newPassword.trim()
        }

        updateLoader(true);
        this.authApi.changePassword(data)
            .then((response) => {

                this.refreshMyProfileSection();
                updateLoader(false);
                this.setState({ updatePassword: false })
                toast.success("Password updated successfully");
            }).catch((reason) => {
                updateLoader(false);
                errorHandler(reason);
            });
    }
    triggerInputFile = () => this.fileInput.click();
    

    triggerInputFile2 = (e) => {
        e.stopPropagation();
        this.fileInput2.click()
        this.previewImage(null, false)
    }

    updateChanges = (key, val) => {
        let data = this.state.requireArray;
        if (data.includes(key)) {
            data.splice(data.indexOf(key), 1)
        }
        this.setState({ [key]: val, requireArray: data })
    }

    editClanOrg = (obj, val1, val2, i) => {
        this.setState({
            editDataObj: obj,
            openClanOrgModal: val1,
            isClanModal: val2,
            editIndex: i
        })
    }
    connectDiscord = () => {
        window.open(`${import.meta.env.VITE_APP_API_URL}/public/api/v1/auth/discord?type=CONNECT&userId=${this.authUser?.id}`, "_self");
    }
    handleEditToggle = (key) => {
        this.setState(prevState => ({ [key]: !prevState[key] }))
    }
    updateState = (key, e) => {
        this.setState({ [key]: e.target.value });
        if (key === 'email') {
            this.validateEmail();
        }
    }
    //Name Validation
    nameValidation = () => {
        let result = requiredField(this.state.name)
        this.setState({ validName: !result["error"], nameErr: result["message"] })
    }
    //Username Validation
    usernameValidation = () => {
        let result = requiredField(this.state.username)
        this.setState({ validUsername: !result["error"], userNameErr: result["message"] })
    }
    //email validation
    validateEmail = () => {
        let result = emailValidation(this.state.email)
        this.setState({ emailErr: !result["error"], emailErrMsg: result["message"] })
    };
    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="solid" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    addItem = (e) => {

        const {gameOfInterest} = this.state;
        var updatedGames;
        if(this.state.gameOfInterest.length){
            updatedGames = [...new Set(gameOfInterest.concat(e))];
        }else{
            updatedGames = e;
        }
        this.setState({ gameOfInterest: updatedGames});
    }

    removeItem = (e) => {
        this.setState({ gameOfInterest: e })
    }

    previewImage = (src, param) => {
        this.setState({
          previewImageDetails: {
            display: param,
            src: (param ? src : LOGOS.USER)
          }
        });
    }

    render() {
        const { showPassword, showNewPassword } = this.state;
        return (
            <div className='body-section den-profile-page'>
                <div className='container'>
                    <div className='d-flex justify-content-center align-items-center my-3'>
                        <FaUserAlt className='den-icon me-3' />
                        <h4 className='fs-24 fw-700 my-0'>Profile</h4>
                    </div>
                    {
                        this.state.updatePassword ?
                            <UpdatePassword showPassword={showPassword} showNewPassword={showNewPassword} handleEditToggle={this.handleEditToggle} onSubmit={this.onSubmit} />
                            :
                            <form className='my-3' onSubmit={(e) => e.preventDefault()}>
                                <div className='row'>
                                    <div className='col-12 col-lg-6'>

                                        <div className='d-flex flex-column align-items-center'>
                                            <div className='profile-cover-container d-flex p-2' style={{ backgroundImage: `url(${this.state.profileCover ? this.state.profileCover?.url : cover})`, backgroundSize: 'cover' }}  onClick={() => this.previewImage((this.state.profileCover?.url ? this.state.profileCover?.url : LOGOS.USER), true)}>
                                                {/* {this.state.profileCover ? <img src={this.state.profileCover?.url} alt='cover' className='w-100' /> : null} */}
                                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'profileCover')} ref={fileInput2 => this.fileInput2 = fileInput2} name="" className="" />
                                                <img src={camera} alt='' className='upload-camera mt-auto ms-auto' onClick={this.triggerInputFile2} />
                                            </div>
                                            <div className='profile-logo-container d-flex' style={{ marginTop: '-50px' }}>
                                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'profileLogo')} ref={fileInput => this.fileInput = fileInput} name="" className="" />
                                                {/* <img src={this.state.profileLogo ? this.state.profileLogo?.url : profileLogo} alt='' className='logo' /> */}
                                                <div className="" onClick={() => this.previewImage((this.state.profileLogo?.url ? this.state.profileLogo?.url : LOGOS.USER), true)}>
                                                    <Image src={this.state.profileLogo?.url} params={this.state.name} className='logo' />
                                                </div>
                                                {/* <Image src={this.state.profileLogo?.url} params={this.state.name} className='logo' /> */}
                                                <img src={camera} alt='' className='upload-camera mt-auto' style={{ marginLeft: '-25px' }} onClick={(e) =>this.triggerInputFile(e)} />
                                                
                                            </div>
                                        </div>
                                        {/* Name */}
                                        <div className='my-2'>
                                            <label htmlFor="name" className="fs-16 fs-lg-20 fw-600 text-white">Name*</label>
                                            <div className="">
                                                <FiUser className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                {/* {this.state.nameReadOnly ?
                                                    <>
                                                        <FiEdit data-tip data-for="editName" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => this.handleEditToggle('nameReadOnly')} />
                                                        {this.showToolTip('editName', 'top', 'warning', 'Edit Name')}
                                                    </>
                                                    :
                                                    <>
                                                        <BsCheckCircleFill data-tip data-for="saveName" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={(e) => this.updateProfile(e, "name")} />
                                                        {this.showToolTip('saveName', 'top', 'success', 'Save Name')}
                                                    </>
                                                } */}
                                                <input
                                                    type="text" id="name" ref="name" value={this.state.name} 
                                                    //readOnly={this.state.nameReadOnly} onBlur={this.nameValidation}
                                                    className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 fs-lg-18 height-45"
                                                    onChange={(e) => this.updateState('name', e)} maxLength={50}
                                                />

                                            </div>
                                            <p className="error-msg">{this.state.nameErr}</p>
                                        </div>
                                        {/* Username */}
                                        <div className='my-2'>
                                            <label htmlFor="username" className="fs-16 fs-lg-20 fw-600 text-white">Espotz Username(A.K.A)*</label>
                                            <div className="">
                                                <BsPersonLinesFill className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                {/* {this.state.usernameReadonly ?
                                                    <>
                                                        <FiEdit data-tip data-for="editUserame" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('usernameReadonly') }} />
                                                        {this.showToolTip('editUserame', 'top', 'warning', 'Edit Username')}
                                                    </>
                                                    :
                                                    <>
                                                        <BsCheckCircleFill data-tip data-for="saveUsername" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={(e) => this.updateProfile(e, "username")} />
                                                        {this.showToolTip('saveUsername', 'top', 'success', 'Save Username')}
                                                    </>
                                                } */}
                                                <input
                                                    type="text" id="username" ref="username"
                                                    className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 fs-lg-18 height-45"
                                                    value={this.state.AKA} 
                                                    //readOnly={this.state.usernameReadonly} onBlur={this.usernameValidation}
                                                    onChange={(e) => this.updateState('AKA', e)} maxLength={50}
                                                />
                                            </div>
                                            <p className="error-msg">{this.state.userNameErr}</p>
                                        </div>
                                        {/* Email Address */}
                                        <div className='my-2'>
                                            <label htmlFor="email" className="fs-16 fs-lg-20 fw-600 text-white">Email Address*</label>
                                            <div className="">
                                                <AiOutlineMail className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                {this.props.authUser?.user.isEmailVerified ?
                                                    <>
                                                        <MdVerified data-tip data-for="verified" className="btn-size-20 text-white ms-3 field-eye-icon text-success float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} />
                                                        {this.showToolTip('verified', 'top', 'success', 'Verified Account')}
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.emailReadOnly ?
                                                            // <MdEdit className="eye-icon" onClick={this.handleEmail} />
                                                            <>
                                                                <FiEdit data-tip data-for="editMail" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('emailReadOnly') }} />
                                                                {this.showToolTip('editMail', 'top', 'warning', 'Edit Email')}
                                                            </>
                                                            :
                                                            // <MdDone className="eye-icon" onClick={(e) => this.updateProfile(e, "email")} />
                                                            <>
                                                                <BsCheckCircleFill data-tip data-for="saveEmail" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={(e) => this.updateProfile(e, "email")} />
                                                                {this.showToolTip('saveEmail', 'top', 'success', 'Save Email')}
                                                            </>
                                                        }
                                                    </>
                                                }
                                                <input
                                                    type="text" id="email" ref="email" readOnly={this.state.emailReadOnly}
                                                    className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white pe-5 fs-14 fs-lg-18 height-45"
                                                    value={this.state.email}  onChange={(e) => this.updateState('email', e)}
                                                />
                                            </div>
                                            {this.state.emailErr && <p className="error-msg">{this.state.emailErrMsg}</p>}
                                        </div>
                                        {/* Change Password, Discord Button */}
                                        <div className='row'>
                                            <div className='col-12 col-md-6 my-2'>
                                                <button className="white-flat-btn w-100 height-45 fw-bold py-2" onClick={() => { this.setState({ updatePassword: true }) }}>Change Password</button>
                                            </div>
                                            {
                                                this.state.discordProfile ?
                                                    <div className='col-12 col-md-6 my-2'>
                                                        <div className="">
                                                            <BsDiscord className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                            <MdVerified className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} width={'15px'} />
                                                            <input
                                                                type="text" id="discordUsername" ref="username"
                                                                className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 fs-lg-18 height-45 green-flat-btn"                                                                
                                                                value={this.state.discordProfile} disabled={true}
                                                            />
                                                        </div>
                                                        {/* <p className="error-msg">{this.state.userNameErr}</p> */}
                                                    </div>
                                                    :
                                                    <div className='col-12 col-md-6 my-2'>
                                                        <button className="white-flat-btn w-100 height-45 fw-bold py-2 blue-flat-btn" onClick={this.connectDiscord}>
                                                            <img src={discordIcon} className='mx-2' alt="discord icon" width={'15px'} />
                                                            Connect Discord
                                                        </button>
                                                    </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center my-2">
                                            <hr className="hr-line" />
                                            <p className="fs-16 fw-600 text-white my-0 mx-2">KYC</p>
                                            <hr className="hr-line" />
                                        </div>
                                        {/* KYC VERIFICATION */}
                                        <button className='pink-skyblue-gradient-btn w-100 height-55 d-flex align-items-center justify-content-center border-0'>
                                            <p className="text-white fs-16 fw-600 mb-0">Complete KYC Verification</p>
                                        </button>
                                        {/* Age */}
                                        <div className='my-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className='fs-20 fw-600'>Age</label>
                                                {this.state.privateStatus.age ?
                                                    <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'age', false) }} />
                                                    :
                                                    <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'age', true) }} />
                                                }
                                            </div>
                                            <div className='den-input-container'>
                                                <input type='number' value={this.state.age} className='den-input flex-grow-1 ps-3' min={1} step={1} onWheel={(e) => e.target.blur()} onChange={(e) => this.updateChanges('age', parseInt(e.target.value))} />
                                            </div>
                                            { this.state.requireArray.includes('age') ? <p className="error-msg">Age is required</p> : null }
                                        </div>
                                        {/* Country */}
                                        <div className='my-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className='fs-20 fw-600'>Country</label>
                                                {this.state.privateStatus.country ?
                                                    <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'country', false) }} />
                                                    :
                                                    <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'country', true) }} />
                                                }
                                            </div>
                                            <div className='den-input-container'>
                                                <select className='den-input flex-grow-1 px-3' value={this.state.country} onChange={(e) => this.updateChanges('country', e.target.value)}>
                                                    {this.state.countryCodes?.map((country, i) => {
                                                        return <option value={JSON.stringify(country)} className='dropdown-option' key={i}>{country.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            { this.state.requireArray.includes('country') ? <p className="error-msg">Country is required</p> : null }
                                        </div>
                                        {/* City */}
                                        <div className='my-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className='fs-20 fw-600'>City</label>
                                                {this.state.privateStatus.city ?
                                                    <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'city', false) }} />
                                                    :
                                                    <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'city', true) }} />
                                                }
                                            </div>
                                            <div className='den-input-container'>
                                                <input type='text' value={this.state.city} maxLength={50} className='den-input flex-grow-1 ps-3' onChange={(e) => this.updateChanges('city', e.target.value)} maxLength={30} />
                                            </div>
                                            { this.state.requireArray.includes('city') ? <p className="error-msg">City is required</p> : null }
                                        </div>
                                        {/* Gender */}
                                        <div className='my-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className='fs-20 fw-600'>Gender</label>
                                                {this.state.privateStatus.gender ?
                                                    <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'gender', false) }} />
                                                    :
                                                    <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'gender', true) }} />
                                                }
                                            </div>
                                            <div className='d-flex align-items-center my-2'>
                                                <div className='d-flex align-items-center me-4'>
                                                    <input type='radio' value='male' checked={this.state.gender === 'male'} id='male' onClick={(e) => this.updateChanges('gender', e.target.value)} />
                                                    <label className='fs-14 fw-600 ms-2'><img src={male} alt='male' className='me-2' />Male</label>
                                                </div>
                                                <div className='d-flex align-items-center me-4'>
                                                    <input type='radio' value='female' id='female' checked={this.state.gender === 'female'} onClick={(e) => this.updateChanges('gender', e.target.value)} />
                                                    <label className='fs-14 fw-600 ms-2'><img src={female} alt='female' className='me-2' />Female</label>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <input type='radio' value='other' id='other' checked={this.state.gender === 'other'} onClick={(e) => this.updateChanges('gender', e.target.value)} />
                                                    <label className='fs-14 fw-600 ms-2'>Other</label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Food Preference */}
                                        <div className='my-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <label className='fs-20 fw-600'>Food Preference</label>
                                                {this.state.privateStatus.foodPreference ?
                                                    <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'foodPreference', false) }} />
                                                    :
                                                    <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'foodPreference', true) }} />
                                                }
                                            </div>
                                            <div className='d-flex align-items-center my-2'>
                                                <div className='d-flex align-items-center me-4'>
                                                    <input type='radio' value='veg' id='vegetarian' checked={this.state.foodPreference === 'veg'} onClick={(e) => this.updateChanges('foodPreference', e.target.value)} />
                                                    <label className='fs-14 fw-600 ms-2'><img src={veg} alt='vegetarian' className='label-icon me-2' />Vegetarian</label>
                                                </div>
                                                <div className='d-flex align-items-center me-4'>
                                                    <input type='radio' value='nonveg' id='nonvegetarian' checked={this.state.foodPreference === 'nonveg'} onClick={(e) => this.updateChanges('foodPreference', e.target.value)} />
                                                    <label className='fs-14 fw-600 ms-2'><img src={nonveg} alt='nonvegetarian' className='label-icon me-2' />Non-Vegetarian</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        {/* Profile Tag */}
                                        <div className='my-3'>
                                            <label className='fs-20 fw-600'>Profile Tag*</label>
                                            <div className='den-input-container'>
                                                <select className='den-input flex-grow-1 ps-3' value={this.state.profileTag} onChange={(e) => this.updateChanges('profileTag', e.target.value)}>
                                                    {DEN_PROFILE_TAGS.map((tag, i) => { return <option value={tag} className='dropdown-option' key={i}>{tag}</option> })}
                                                </select>
                                            </div>
                                        </div>
                                        {/* Mini Description */}
                                        <div className='my-3'>
                                            <label className='fs-20 fw-600'>Mini Description*</label>
                                            <div className='den-input-container'>
                                                <input type='text' className='den-input flex-grow-1 ps-3'
                                                    maxLength={50} value={this.state.miniDescription} onChange={(e) => this.updateChanges('miniDescription', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        {/* Bio */}
                                        <div className='my-3 d-flex flex-column'>
                                            <label className='fs-20 fw-600'>Bio</label>
                                            <textarea className='bio-text w-100 p-2 fw-600' rows="5" value={this.state.bio} maxLength={1000} onChange={(e) => this.updateChanges('bio', e.target.value)} />
                                            { this.state.requireArray.includes('bio') ? <p className="error-msg">Bio is required</p> : null }
                                        </div>
                                        {/* Game of Interest */}
                                        {/* <div className='my-3'>
                                            <label className='fs-20 fw-600'>Game of Interest*</label>
                                            <div className='den-input-container'>
                                                <select className='den-input flex-grow-1 ps-3' value={this.state.gameInterest} onChange={(e) => this.updateChanges('gameInterest', e.target.value)}>
                                                    {this.state.gamesArray?.map((game, i) => {
                                                        return <option className='dropdown-option' value={game._id} key={i}>{game.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div> */}

                                        <div className='my-3'>
                                            <label className='fs-20 fw-600'>Game of Interest*</label>
                                            <div className=''>
                                                <Multiselect
                                                    options={this.state.gamesArray}
                                                    displayValue="name"
                                                    selectedValues={this.state.gameOfInterest}
                                                    onSelect={(e) => this.addItem(e)}
                                                    onRemove={(e) => this.removeItem(e)}
                                                />
                                            </div>
                                        </div>

                                        
                                        {/* Stats */}
                                        <div className='row'>
                                            <div className='col-12 col-md-6'>
                                                <div className="d-flex justify-content-center align-items-center my-2">
                                                    <hr className="hr-line" />
                                                    <p className="fs-16 fw-600 text-white my-0 mx-2">Public Stats</p>
                                                    <hr className="hr-line" />
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>Matches Played</label>
                                                    {this.state.publicStatus.matchPlayed ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('publicStatus', 'matchPlayed', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('publicStatus', 'matchPlayed', true) }} />
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>Matches Won</label>
                                                    {this.state.publicStatus.matchWin ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('publicStatus', 'matchWin', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('publicStatus', 'matchWin', true) }} />
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>Spotlight Collection</label>
                                                    {this.state.publicStatus.spotlightCollection ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('publicStatus', 'spotlightCollection', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('publicStatus', 'spotlightCollection', true) }} />
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>MVP Count</label>
                                                    {this.state.publicStatus.mvpCount ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('publicStatus', 'mvpCount', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('publicStatus', 'mvpCount', true) }} />
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>Game Stats</label>
                                                    {this.state.publicStatus.gameStats ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('publicStatus', 'gameStats', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('publicStatus', 'gameStats', true) }} />
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6'>
                                                <div className="d-flex justify-content-center align-items-center my-2">
                                                    <hr className="hr-line" />
                                                    <p className="fs-16 fw-600 text-white my-0 mx-2">Private Stats</p>
                                                    <hr className="hr-line" />
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>Tournament Count</label>
                                                    {this.state.privateStatus.tournamentCount ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'tournamentCount', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'tournamentCount', true) }} />
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='fs-20 fw-600'>Staking Stats</label>
                                                    {this.state.privateStatus.bettingStatistics ?
                                                        <FiEye onClick={() => { this.changePublicPrivateStat('privateStatus', 'bettingStatistics', false) }} />
                                                        :
                                                        <FiEyeOff onClick={() => { this.changePublicPrivateStat('privateStatus', 'bettingStatistics', true) }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* Clans */}
                                        <hr className="hr-line w-100 my-3" />

                                        <div className='my-2'>
                                            <div className='d-flex align-items-center'>
                                                <img src={POST_INTERACTIONS.AS.CLAN} alt='' className='me-2' style={{width: 'auto', height: '20px'}} />
                                                <label className='fs-20 fw-600'>My Clans</label>
                                            </div>
                                            <div className='my-3'>
                                                {this.state.myClan?.map((clan, i) => {
                                                    return (
                                                        <div className='den-clan-container' key={i}>
                                                            <div className='d-flex align-items-center mb-2'>
                                                                <p className='fs-16 fw-600 my-0 flex-grow-1'>Clan #{i+1}</p>
                                                                <FiEdit className='mx-2' onClick={() => this.editClanOrg(clan, true, true, i)} />
                                                                <HiOutlineTrash className='mx-2' onClick={() => { this.openCnf('clan', i) }} />
                                                            </div>
                                                            <p className='fs-14 fw-600'>Name: {clan.name}</p>
                                                            {clan.roles?.map(((role, j) => {
                                                                return (
                                                                    <div className="d-flex align-items-start my-0" key={j}>
                                                                        <div className='d-flex flex-column align-items-center my-0'>
                                                                            <div className='clan-dot-container'></div>
                                                                            <div className='ver-line my-1' style={{display : (j+1 === clan.roles?.length) ? 'none' : 'block'}}></div>
                                                                        </div>
                                                                        <div className='mx-2 my-0'>
                                                                            <p className='fs-14 fw-600 my-0'>{role.role}</p>
                                                                            <p className='fs-10 fw-600 my-0'>{getMonthYear(role.startDate)} - {role.endDate !== '' ? getMonthYear(role.endDate) : 'Now' }</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }))}
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2" onClick={() => this.handleClanOrgModal(true, true)}>
                                                <button className="btn">
                                                    <AiFillPlusCircle className="btn-size-25 text-white" />
                                                </button>
                                            </div>
                                        </div>
                                        {/* Organisations */}
                                        <hr className="hr-line w-100 my-3" />
                                        <div className='my-2'>
                                            <div className='d-flex align-items-center'>
                                                <img src={POST_INTERACTIONS.AS.ORG} alt='' className='me-2' style={{width: 'auto', height: '20px'}} />
                                                <label className='fs-20 fw-600'>My Organizations</label>
                                            </div>
                                            <div className='my-3'>
                                                {this.state.myOrganization?.map((org, i) => {
                                                    return (
                                                        <div className='den-clan-container' key={i}>
                                                            <div className='d-flex align-items-center mb-2'>
                                                            <p className='fs-16 fw-600 my-0 flex-grow-1'>Organization #{i+1}</p>
                                                                <FiEdit className='mx-2' onClick={() => this.editClanOrg(org, true, false, i)} />
                                                                <HiOutlineTrash className='mx-2' onClick={() => { this.openCnf('organization', i) }} />
                                                            </div>
                                                            <p className='fs-14 fw-600'>Name: {org.name}</p>
                                                            {org.roles?.map(((role, j) => {
                                                                return (
                                                                    <div className="d-flex align-items-start my-0" key={j}>
                                                                        <div className='d-flex flex-column align-items-center my-0'>
                                                                            <div className='org-dot-container'></div>
                                                                            <div className='ver-line-org my-1' style={{display : (j+1 === org.roles?.length) ? 'none' : 'block'}}></div>
                                                                        </div>
                                                                        <div className='mx-2 my-0'>
                                                                            <p className='fs-14 fw-600 my-0'>{role.role}</p>
                                                                            <p className='fs-10 fw-600 my-0'>{getMonthYear(role.startDate)} - {role.endDate !== '' ? getMonthYear(role.endDate) : 'Now' }</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }))}

                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2" onClick={() => this.handleClanOrgModal(true, false)}>
                                                <button className="btn">
                                                    <AiFillPlusCircle className="btn-size-25 text-white" />
                                                </button>
                                            </div>
                                        </div>
                                        {/* Broadcasting */}
                                        <div className='my-3'>
                                            <div className="d-flex justify-content-center align-items-center my-2">
                                                <hr className="hr-line flex-grow-1" />
                                                <img src={SOCIAL_MEDIA_ICONS.BROADCAST.url} alt='' className='mx-1' style={{height:'20px', width:'auto'}} />
                                                <p className="fs-16 fw-600 text-white-50 my-0 mx-1">Broadcasting</p>
                                                <hr className="hr-line flex-grow-1" />
                                            </div>
                                            {this.state.socialMediaDetails?.map((details, i) => {
                                                if (details.value !== '' && details.error === '') {
                                                    return (
                                                        <div className="my-2" key={i}>
                                                            <img src={details.inlineUrl} className="btn-size-20 ms-3 position-absolute" style={{ marginTop: '12px' }} alt="" />
                                                            <input
                                                                type='text'
                                                                placeholder="SocialMedia.com/help"
                                                                className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 height-45"
                                                                value={details.value}
                                                                disabled
                                                            />
                                                            <AiOutlineMinusCircle className="btn-size-20 me-3 text-white flex-shrink-0 float-end" style={{ marginTop: '-30px' }} onClick={e => { this.showFields(e, i, false) }} />
                                                        </div>
                                                    )
                                                }

                                            })}
                                            <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2" onClick={(e) => { e.preventDefault(); this.setState({ openSocialMediaModal: !this.state.openSocialMediaModal }) }}>
                                                <button className="btn">
                                                    <AiFillPlusCircle className="btn-size-25 text-white" />
                                                </button>
                                            </div>
                                        </div>
                                        {/* Den Status */}
                                        <div className="my-3">
                                            <label className='fs-20 fw-600'>Den Status</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 border-0 ${this.state.isPublic ? 'epz-active-field' : ''}`} onClick={(e) => this.updateChanges('isPublic', true)}>
                                                        <FaGlobeAfrica className="btn-size-20 text-white" />
                                                        <h6 className="fs-12 fw-500 text-white mb-0">Public</h6>
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 border-0 ${!this.state.isPublic ? 'epz-active-field' : ''}`} onClick={(e) => this.updateChanges('isPublic', false)}>
                                                        <AiOutlineLock className="btn-size-20 text-white" />
                                                        <h6 className="fs-12 fw-500 text-white mb-0">Private</h6>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {this.state.user?.den ?
                                    <div className="d-flex justify-content-center my-3">
                                        <div className="d-flex flex-column align-items-center mx-3">
                                            <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={this.updateDenProfile}><FiRefreshCw /></button>
                                            <span className="fs-12 fw-600 my-1">Update</span>
                                        </div>
                                        {/* <div className="d-flex flex-column align-items-center mx-3">
                                            <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" disabled={this.state.verificationStatus !== VerificationStatus.NOT_VERIFIED} onClick={this.verificationRequest}><GoVerified /></button>
                                            <span className="fs-12 fw-600 my-1">{VERIFICATION_STATUS[this.state.verificationStatus]}</span>
                                        </div> */}
                                        <div className="d-flex flex-column align-items-center mx-3">
                                            <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={() => {this.state.isDenProfileActive ? this.openCnf('deactivate') : this.openCnf('activate')}}><AiOutlinePoweroff /></button>
                                            <span className="fs-12 fw-600 my-1">{this.state.isDenProfileActive ? 'Deactivate' : 'Activate'}</span>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mx-3">
                                            <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" disabled={this.state.isDenProfileDeleted} onClick={() => { this.openCnf('delete') }}><AiFillDelete /></button>
                                            <span className="fs-12 fw-600 my-1">Delete</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-center my-3">
                                        <div className="d-flex flex-column align-items-center mx-3">
                                            <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={this.updateDenProfile}><FiRefreshCw /></button>
                                            <span className="fs-12 fw-600 my-1">Save</span>
                                        </div>
                                    </div>
                                }
                                
                            </form>
                    }

                </div>
                <Modal show={this.state.openSocialMediaModal} centered dialogClassName="social-media-modal">
                    <Modal.Body>
                        <div className="p-3">
                            <p className="fs-18 fw-bold text-white text-center mb-2">Social Media Details</p>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={() => { this.setState({ openSocialMediaModal: !this.state.openSocialMediaModal }) }} />

                            <div className="my-3">
                                {this.state.extraDetails.map((details, i) => {
                                    if (details.isChecked) {
                                        return (
                                            <div className="my-1" key={i}>
                                                <div className="purple-field d-flex align-items-center w-100 border-0 ">
                                                    <img src={details.inlineUrl} className="mx-2" alt="" style={{width: '20px', height: 'auto'}} />
                                                    <input
                                                        type='text'
                                                        placeholder="SocialMedia.com/help"
                                                        className="text-white fs-14 height-45 border-0 flex-grow-1"
                                                        value={details.value}
                                                        onChange={e => this.updateDetails(e, i)}
                                                        style={{ backgroundColor: 'transparent', outline: 'none' }}
                                                    />
                                                    <AiOutlineMinusCircle className="btn-size-20 mx-2 text-white-50" onClick={e => { this.showFields(e, i, false) }} />
                                                </div>
                                                <p className="error-msg">{details.error}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {this.state.extraDetails.map((media, i) => {
                                    return (
                                        <button key={i} className="purple-field border-0 rounded-circle d-flex justify-content-center align-items-center btn-size-52 mb-1 mx-1" onClick={e => { this.showFields(e, i, true) }}>
                                            <img src={media.url} alt={media.id} style={{width: '28px', height: 'auto'}} />
                                        </button>
                                    )
                                })}
                            </div>
                            {this.getAddButton() ? <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" onClick={this.addDetails}>Add</button> : ''}
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openClanOrgModal} centered dialogClassName="AddMoreDetails-modal">
                    <Modal.Body>
                        <ClanOrgModal
                            editDataObj={this.state.editDataObj}
                            isClanModal={this.state.isClanModal}
                            handleClanOrgModal={this.handleClanOrgModal}
                            appendData={this.appendData}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openDeleteCnf} centered dialogClassName="accept-invite-modal">
                    <Modal.Body>
                        <ConfirmationModal confirm={this.state.openDeleteCnf} onClickOpenConfirm={() => { this.setState({ openDeleteCnf: null }) }} confirmed={this.confirmAction} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </div>
        )
    }
}
