import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

// Modals
import { Badge, Modal } from 'react-bootstrap';
import Logout from '../popup/Logout';
import SignUp from '../auth/SignUp';
import Login from '../auth/Login.js';
import ActivateAccount from '../auth/ActivateAccount.js';
import ForgotPassword from '../auth/ForgotPassword.js';
import VerifyChangePassword from '../auth/VerifyChangePassword.js';
import CreateNewPassword from '../auth/CreateNewPassword.js';
import SignUpPolicies from '../auth/SignUpPolicies';
// Assets
import { AiOutlineClose, AiOutlineUser } from "react-icons/ai";
import { GiStaryu } from 'react-icons/gi';
import { IoHomeSharp } from 'react-icons/io5';
import logo from '../../images/common/espotz_beta-logo.png';
// Utils
import packageJson from '../../../package.json'
import { profile } from '../../utils/localstorage.utils';
import { mapDispatchToPropsEC, mapStateToPropsEC } from '../../utils/common.utils';
// Services
import WalletApi from '../../helper/wallet.api';
// Style
import '../../styles/Navbar.css';
import AgeRestrictionsModal from '../popup/AgeRestrictionsModal';
import io from "socket.io-client";
import { makeNotificationSound, saveUpdateNotification } from '../../utils/notification.utils';
import { BOTTOM_NAVBAR_ASSETS, NAVBAR_ASSETS } from '../../utils/Images.utils';
import Image from '../common/Image';
import socketService from '../../services/socket/socket.service';

// Initials state
const resetState = {
    openSignUp: false,
    openLogin: false,
    openLogout: false,
    openActivateAccount: false,
    openForgetPassword: false,
    showVerifyAccount: false,
    backActivateAccount: false,
    openNavbar: false,
    openDropdown: false,
    createNewPassword: false,
    createNewPasswordToken: null,
    isPasswordUpdating: false,
    opacity: 1,
    openSignUpPolicies: false,
    socialMediaType: null,
    activePage: 'home',
    balance: 0,
    walletDetailsAdded: false,
    ageRestrictionsModal: false,
    pendingNotifications: 1,
    notifications: [],
    denProfile: profile()?.denProfile ? profile().denProfile : null,
    denProfileId: profile()?.denProfile?._id
}

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: props.isAuthenticated,
            authUser: props.authUser,
            denProfile: props.authUser?.user?.denProfile ? props.authUser?.user?.denProfile : (profile()?.denProfile ? profile().denProfile : null),
            denProfileId: props.authUser?.user?.den ? props.authUser?.user?.den : null,
            ...resetState,
        };
        this.authUser = profile()
        this.walletApi = new WalletApi()

        if (this.authUser?.id) {
            this.getMyWalletInfo(this.authUser?.id)
        }
        this.componentId = `navbar-${Date.now()}`;
    }

    componentDidMount() {
        this.setupSocketListeners();
        this.getNotification();
        saveUpdateNotification(this.getNotification);
        try {
            var route = (window.location.pathname.split('/'))[1];

            if (route) {
                /* den, bet, EC, clan */
                var path;
                if (route.includes('organization')) {
                    path = 'org';
                } else if (route.includes('notifications')) {
                    path = 'notifications'
                } else if (route.includes('den')) {
                    path = 'den';
                } else if (route.includes('betting')) {
                    path = 'bet';
                } else if (route.includes('wallet')) {
                    path = 'EC';
                } else if (route.includes('clan')) {
                    path = 'clan'
                }
                else if (route.includes('tournament')) {
                    path = 'tournament'
                }
                else if (route.includes('profile')) {
                    path = 'profile'
                } else {
                    path = 'home'
                }
                this.redirectTo(path);
            }
        } catch (error) {
            console.error("Nav Error :: ", error);
        }
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    setupSocketListeners() {
        if (this.authUser?.id) {
            // Set up socket event listeners with unique identifiers
            socketService.on(`get-notifications-sent-${this.authUser.id}`, (e) => {
                const receivedNotifications = e.result.notifications
                    .filter(el => !el.isRead);

                makeNotificationSound();
                this.setState({ notifications: receivedNotifications });
            }, this.componentId);

            socketService.on(`get-notifications-error-${this.authUser.id}`, (e) => {
                console.error('Error getting notifications:', e);
            }, this.componentId);
        }
    }

    removeSocketListeners() {
        if (this.authUser?.id) {
            // Clean up socket event listeners
            socketService.off(`get-notifications-sent-${this.authUser.id}`, this.componentId);
            socketService.off(`get-notifications-error-${this.authUser.id}`, this.componentId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.authUser !== this.props?.authUser) {
            this.setState({ authUser: this.props?.authUser });
        }
        if (prevProps?.isAuthenticated !== this.props?.isAuthenticated) {
            this.setState({ isAuthenticated: this.props?.isAuthenticated });

            // If user just logged in, setup notification listeners
            if (!prevProps?.isAuthenticated && this.props?.isAuthenticated) {
                this.setupSocketListeners();
            }
            // If user just logged out, remove notification listeners
            else if (prevProps?.isAuthenticated && !this.props?.isAuthenticated) {
                this.removeSocketListeners();
            }
        }
        if (prevProps?.UpdatedEc !== this.props?.UpdatedEc) {
            this.setState({ balance: this.props?.UpdatedEc });
        }
        if (this.props.openLogin) {
            this.resetAndUpdateFlag(true, 'openLogin', true);
            this.props.resetLoginStatus();
        } else if (this.props.openSignUp) {
            this.resetAndUpdateFlag(true, 'openSignUp', true);
            this.props.resetLoginStatus();
        }

        var authToken = this.props.newPasswordToken;
        if (authToken && !this.state.createNewPassword) {
            this.setState({
                createNewPassword: true,
                createNewPasswordToken: authToken
            });
        }
    }

    componentWillReceiveProps(newProps) {
        // Preserve denProfileId when updating state from new props
        const currentDenProfileId = this.state.denProfileId || (profile()?.denProfile?._id ? profile()?.denProfile?._id : null);
        const newDenProfileId = newProps.authUser?.user?.den || currentDenProfileId;

        this.setState({
            isAuthenticated: newProps.isAuthenticated,
            authUser: newProps.authUser,
            denProfile: newProps.authUser?.user?.denProfile ? newProps.authUser?.user?.denProfile : (profile()?.denProfile ? profile().denProfile : null),
            denProfileId: newDenProfileId
        });
    }

    componentWillUnmount() {
        this.removeSocketListeners();
        window.removeEventListener('scroll', this.listenScrollEvent);
    }

    // Fetch wallet details to show on navigation
    getMyWalletInfo = (params) => {
        this.walletApi.getWalletInfo(params).then((res) => {
            this.props.dispatchData(res.data.result.balance)
            this.setState({
                balance: res.data.result.balance,
                walletDetailsAdded: true
            });
        }).catch(
            (err) => {
                this.setState({
                    walletDetailsAdded: true
                })
                // errorHandler(err, this.props.logout);
            });
    }

    getNotification = () => {
        if (this.authUser) {
            socketService.emit('get-notifications', { userId: this.authUser.id });
        }
    }

    // Remove URL param
    removeParam(parameter) {
        var url = document.location.href;
        var urlParts = url.split('?');

        if (urlParts.length >= 2) {
            var urlBase = urlParts.shift();
            var queryString = urlParts.join("?");

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = queryString.split(/[&;]/g);
            for (var i = pars.length; i-- > 0;)
                if (pars[i].lastIndexOf(prefix, 0) !== -1)
                    pars.splice(i, 1);
            url = urlBase + '?' + pars.join('&');
            window.history.pushState('', document.title, url); // added this line to push the new url directly to url bar .

        }
        return url;
    }

    // To reset and updated selected fields flag
    resetAndUpdateFlag = (reset, key, value) => {
        if (reset) {
            this.setState({ ...resetState, [key]: value });
        } else {
            this.setState({ [key]: value });
        }
    }

    // To logout from application
    logout = () => {
        this.setState({ ...resetState });
        this.props.logout();
    }

    // Enable policy modal
    openPoliciesPopup = (param) => {
        this.setState({ ...resetState, socialMediaType: param, openSignUpPolicies: true })
    }

    // Close set new password modal and go back to home page
    onClickCloseCreateNewPassword = () => {
        this.props.backToHome();
        this.setState({ ...resetState })
    }

    handleDropdownClick = () => {
        const { openDropdown } = this.state
        this.setState({ openDropdown: !openDropdown })
    }

    ToggleRestrictionsModal = (param) => {
        this.setState({ ...resetState, openRestrictionsModal: param })
    }

    // To Navigate to another page - FIXED to preserve denProfileId
    redirectTo = (page) => {
        if (page === 'den') {
            this.getCurrentDenProfileDetails(page);
        } else {
            this.setState({
                activePage: page
                // We no longer reset denProfileId to null, which was causing profile pic issues
            });
        }
    }

    getCurrentDenProfileDetails = (page) => {
        var routeParams = (window.location.href).toString().split('/');

        if (routeParams[3] && routeParams[3] === 'den' && routeParams[4]?.length === 24) {
            this.setState({
                denProfileId: routeParams[4],
                activePage: page
            });
        } else {
            // Preserve the current denProfileId if available
            this.setState({
                activePage: page
            });
        }
    }

    render() {
        const { openSignUp, openLogin, openForgetPassword, showVerifyAccount, openNavbar, openLogout, openDropdown, openActivateAccount, socialMediaType, openSignUpPolicies, createNewPassword, createNewPasswordToken, openRestrictionsModal, activePage } = this.state;

        const navClass = openNavbar ? 'display-block' : 'display-none';
        const showDropdown = openDropdown ? 'show' : '';
        const version = packageJson.version;

        return (
            <div>
                {/* Top Navigation Section */}
                <nav className={`navbar navbar-expand-lg fixed-top navbar-dark main-nav`}>
                    <div className="container-fluid">
                        <NavLink className="navbar-brand" to="/">
                            <img src={logo} className="home-logo" alt="profile-logo" onClick={() => this.redirectTo('/')} />
                        </NavLink>
                        <button className="navbar-toggler navbar-dark border-0" type="button" data-toggle="collapse" data-target="#navbar1" onClick={(e) => this.resetAndUpdateFlag(false, 'openNavbar', !this.state.openNavbar)}>
                            {this.state.isAuthenticated ?
                                <div className="d-flex align-items-center">
                                    <Image
                                        src={this.state.authUser?.user?.profilePicture?.url}
                                        param={this.state.authUser?.user?.profilePicture?.url ? null : this.state.authUser?.user?.name?.charAt(0)}
                                        className="btn-size-32 border border-1 border-dark rounded-circle"
                                        height='32px'
                                        width='32px'
                                    />
                                    <AiOutlineClose className={`position-absolute close-icon ${navClass}`} />
                                </div>
                                :
                                <div>
                                    {this.state.openNavbar ? <AiOutlineClose className={`position-absolute close-icon`} /> : <span className={`navbar-toggler-icon`}></span>}
                                </div>
                            }
                        </button>
                        {/* ---------------------START ------------------------------*/}
                        <div className={`navbar-collapse ${navClass}`} id="navbar1">
                            <div className="navbar-nav ms-auto nav-center align-items-center" onClick={(e) => this.resetAndUpdateFlag(false, 'openNavbar', !this.state.openNavbar)}>
                                <div className="nav-item">
                                    <NavLink className='nav-link me-1' to="/clans" onClick={() => this.redirectTo('clan')}><img src={activePage === NAVBAR_ASSETS.ASSETS.CLAN.NAME ? NAVBAR_ASSETS.ASSETS.CLAN.ON : NAVBAR_ASSETS.ASSETS.CLAN.OFF} alt={NAVBAR_ASSETS.ASSETS.CLAN.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                </div>
                                <div className="nav-item">
                                    <NavLink className='nav-link me-1' to="/organizations" onClick={() => this.redirectTo('org')}><img src={activePage === NAVBAR_ASSETS.ASSETS.ORG.NAME ? NAVBAR_ASSETS.ASSETS.ORG.ON : NAVBAR_ASSETS.ASSETS.ORG.OFF} alt={NAVBAR_ASSETS.ASSETS.ORG.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                </div>
                                <div className="nav-item">
                                    <NavLink className='nav-link me-1' to="/tournaments" onClick={() => this.redirectTo('tournament')}><img src={activePage === NAVBAR_ASSETS.ASSETS.TOURNAMENT.NAME ? NAVBAR_ASSETS.ASSETS.TOURNAMENT.ON : NAVBAR_ASSETS.ASSETS.TOURNAMENT.OFF} alt={NAVBAR_ASSETS.ASSETS.TOURNAMENT.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                </div>
                                {import.meta.env.VITE_APP_ENV !== 'production' && <div className="nav-item">
                                    <NavLink className='nav-link me-1' to="/betting" onClick={() => this.redirectTo('bet')}><img src={activePage === NAVBAR_ASSETS.ASSETS.BET.NAME ? NAVBAR_ASSETS.ASSETS.BET.ON : NAVBAR_ASSETS.ASSETS.BET.OFF} alt={NAVBAR_ASSETS.ASSETS.BET.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                </div>}
                                {import.meta.env.VITE_APP_ENV !== 'production' ? (<div className="nav-item">
                                    <NavLink className='nav-link me-1' exact to={`/den/${this.state.denProfileId ? this.state.denProfileId : this.state.denProfile?._id}`} onClick={() => this.redirectTo('den')}><img src={activePage === NAVBAR_ASSETS.ASSETS.DEN.NAME ? NAVBAR_ASSETS.ASSETS.DEN.ON : NAVBAR_ASSETS.ASSETS.DEN.OFF} alt={NAVBAR_ASSETS.ASSETS.DEN.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                </div>) : (
                                    <NavLink className='nav-link me-1' exact to={`/den`} onClick={() => this.redirectTo('den')}><img src={activePage === NAVBAR_ASSETS.ASSETS.DEN.NAME ? NAVBAR_ASSETS.ASSETS.DEN.ON : NAVBAR_ASSETS.ASSETS.DEN.OFF} alt={NAVBAR_ASSETS.ASSETS.DEN.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                )}
                                {import.meta.env.VITE_APP_ENV !== 'production' ? (<div className="nav-item">
                                    <NavLink className='nav-link' exact to={`/den/${this.state.denProfileId ? this.state.denProfileId : this.state.denProfile?._id}/live-streaming`} onClick={() => this.redirectTo('tv')}><img src={activePage === NAVBAR_ASSETS.ASSETS.TV.NAME ? NAVBAR_ASSETS.ASSETS.TV.ON : NAVBAR_ASSETS.ASSETS.TV.OFF} alt={NAVBAR_ASSETS.ASSETS.TV.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                </div>) : (
                                    <NavLink className='nav-link' exact to={`/tv`} onClick={() => this.redirectTo('tv')}><img src={activePage === NAVBAR_ASSETS.ASSETS.TV.NAME ? NAVBAR_ASSETS.ASSETS.TV.ON : NAVBAR_ASSETS.ASSETS.TV.OFF} alt={NAVBAR_ASSETS.ASSETS.TV.NAME} className="" style={{ width: 'auto', height: '20px' }} /></NavLink>
                                )}
                                {this.state.isAuthenticated &&
                                    <div>
                                        <div className="d-block d-lg-none align-self-center">
                                            <li className="nav-item">
                                                <NavLink to="/profile" className="nav-link text-center text-white fs-18 fw-400 pt-3" onClick={() => this.redirectTo('profile')}>Profile</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/wallet" className="nav-link text-center text-white fs-18 fw-400 pt-3" onClick={() => this.redirectTo('EC')}>Wallet</NavLink>
                                            </li>
                                            {import.meta.env.VITE_APP_ENV !== 'production' ? (<li className="nav-item">
                                                <NavLink to={`/den/${this.state.denProfileId ? this.state.denProfileId : this.state.denProfile?._id}`} className="nav-link text-center text-white fs-18 fw-400 pt-3" onClick={() => this.redirectTo('den')}>DEN</NavLink>
                                            </li>) : (<li className="nav-item">
                                                <NavLink to={`/den`} className="nav-link text-center text-white fs-18 fw-400 pt-3" onClick={() => this.redirectTo('den')}>DEN</NavLink>
                                            </li>)}
                                            <li className="nav-item">
                                                <NavLink to="/notifications" className="nav-link text-center text-white fs-18 fw-400 pt-3" onClick={() => this.redirectTo('notifications')}>Notifications</NavLink>
                                            </li>
                                        </div>
                                        <li className="nav-item position-absolute mb-5 d-block d-lg-none" style={{ bottom: '20px' }}>
                                            <p className="nav-link text-center text-white fs-18 fw-400 cyan-process-btn w-100 px-3 py-2" onClick={(e) => this.resetAndUpdateFlag(false, 'openLogout', true)}>Sign out</p>
                                            <p className='text-center text-white-50 fs-12 fw-500 mt-3'>v {version}</p>
                                        </li>
                                    </div>
                                }
                            </div>
                            {this.state.isAuthenticated ?
                                <div className="dropdown auth-btn navbar-nav d-none d-lg-flex trail">
                                    <div className="nav-item d-flex me-1">
                                        <NavLink className='nav-link' to="/notifications" onClick={() => this.redirectTo('notifications')}><img src={activePage === NAVBAR_ASSETS.ASSETS.NOTIFICATION.NAME ? NAVBAR_ASSETS.ASSETS.NOTIFICATION.ON : NAVBAR_ASSETS.ASSETS.NOTIFICATION.OFF} alt={NAVBAR_ASSETS.ASSETS.NOTIFICATION.NAME} className="" style={{ width: '25px', height: 'auto' }} /></NavLink>
                                        <div className={this.state.notifications?.length ? 'column-center-align-div message-count-box fw-bold text-white fs-10 p-0' : 'd-none'} style={{ marginLeft: '-18px' }}>{this.state.notifications?.length > 99 ? '99+' : this.state.notifications?.length}</div>
                                    </div>
                                    <div className="nav-item">
                                        <NavLink className={`nav-link text-center text-info fs-18 fw-400 ${(this.state.activePage === 'home' || this.state.activePage === '/') ? 'd-none' : 'd-flex'}`} to="/wallet" onClick={() => this.redirectTo('EC')}> <span className='d-flex align-items-center'>
                                            <GiStaryu className="fs-24 me-1" />
                                            <p className='text-warning fs-18 mb-0 fw-500 mx-2'>{Number.isInteger(this.state.balance) ? this.state.balance : this.state.balance.toFixed(2)}</p>
                                        </span>
                                            EC</NavLink>
                                    </div>
                                    <div className="dropdown align-self-center">
                                        <div
                                            className="cursor-pointer"
                                            onClick={this.handleDropdownClick}>
                                            <Image
                                                src={this.state.authUser?.user?.profilePicture?.url}
                                                param={this.state.authUser?.user?.profilePicture?.url ? null : this.state.authUser?.user?.name?.charAt(0)}
                                                className="btn-size-32 border border-1 border-dark rounded-circle"
                                                height='32px'
                                                width='32px'
                                            />
                                        </div>

                                        <ul className={`dropdown-menu dropdown-show-profile text-center px-5 ${showDropdown}`} style={{ right: '0px', top: '65px' }} aria-labelledby="dropdownMenuLink" onClick={this.handleDropdownClick}>
                                            <li className="dropdown-item border-bottom">
                                                <NavLink to="/profile" className='text-white' onClick={() => this.redirectTo('profile')}>My Profile</NavLink>
                                            </li>
                                            <li className="dropdown-item border-bottom text-white">
                                                <NavLink to="/wallet" className='text-white' onClick={() => this.redirectTo('EC')}>Wallet</NavLink>
                                            </li>

                                            <li className="dropdown-item mt-5">
                                                <p className="nav-link text-center text-white fs-16 fw-400 cyan-process-btn w-100 px-3 py-1" onClick={(e) => this.resetAndUpdateFlag(false, 'openLogout', true)}>Sign out</p>
                                                <p className='text-center text-white-50 fs-12 fw-500 mt-3'>v {version}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                :
                                <div className="auth-btn navbar-nav position-absolute w-100 mb-5 mb-lg-0" style={{ bottom: '20px', left: '0px' }}>
                                    <ul className="d-flex justify-content-center mb-0 ps-0" style={{ listStyle: 'none' }} onClick={(e) => this.resetAndUpdateFlag(false, 'openNavbar', !this.state.openNavbar)}>
                                        <li className="nav-item active me-2">
                                            <button type="button" className="fs-16 fw-bold nav-link text-center rounded-10 py-2 px-4" style={{ border: '1px solid #32ADE6', color: '#18ACFE', backgroundColor: '#163A67' }} onClick={(e) => this.resetAndUpdateFlag(true, 'openLogin', true)}>Access</button>
                                        </li>
                                        <li className="nav-item active ms-2">
                                            <button type="button" className="fs-16 fw-bold nav-link text-center rounded-10 py-2 px-4 border-0 text-white" style={{ backgroundColor: '#18ACFE' }} onClick={(e) => this.resetAndUpdateFlag(true, 'openSignUp', true)}>Enroll</button>
                                        </li>
                                    </ul>
                                    <p className='text-center text-white-50 fs-12 fw-500 d-lg-none d-block mt-3'>v {version}</p>
                                </div>
                            }
                        </div>
                        {/* ----------------------END-------------------------------- */}
                    </div>
                </nav>

                {/* Bottom Navigation Section */}
                <div className='' id='tournament-date'>
                    <div className="bottom-navbar d-lg-none d-block overflow-auto">
                        <div className='d-flex navbar-block'>
                            <NavLink exact to="/" className='fw-500 mt-2' onClick={() => this.redirectTo('home')}>
                                <IoHomeSharp className='' style={{ height: '20px', width: 'auto' }} />HOME
                            </NavLink>
                            <NavLink to="/clans" className='fw-500 mt-2' onClick={() => this.redirectTo('clan')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.CLAN.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.CLAN.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.CLAN.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.CLAN.NAME} className="" style={{ width: 'auto', height: '20px' }} />CLAN
                            </NavLink>
                            <NavLink to="/organizations" className='fw-500 mt-2' onClick={() => this.redirectTo('org')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.ORG.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.ORG.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.ORG.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.ORG.NAME} className="" style={{ width: 'auto', height: '20px' }} />ORG
                            </NavLink>
                            <NavLink to="/tournaments" className='fw-500 mt-2' onClick={() => this.redirectTo('tournament')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.TOURNAMENT.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.TOURNAMENT.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.TOURNAMENT.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.TOURNAMENT.NAME} className="" style={{ width: 'auto', height: '20px' }} />PLAY
                            </NavLink>
                            {import.meta.env.VITE_APP_ENV !== 'production' && <NavLink to="/betting" className='fw-500 mt-2' onClick={() => this.redirectTo('bet')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.BET.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.BET.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.BET.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.BET.NAME} className="" style={{ width: 'auto', height: '20px' }} />
                                <span className="position-absolute mt-1 fs-8 rounded-circle border border-danger " style={{ marginLeft: '35px', paddingTop: '2px', paddingLeft: '2px', width: '17px', height: '17px' }}>
                                    18+
                                    <span className="visually-hidden">unread messages</span>
                                </span>
                                STAKE
                            </NavLink>}
                            {import.meta.env.VITE_APP_ENV !== 'production' ? (<NavLink exact to={`/den/${this.state.denProfileId ? this.state.denProfileId : this.state.denProfile?._id}`} className='fw-500 mt-2' onClick={() => this.redirectTo('den')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.NAME} className="" style={{ width: 'auto', height: '20px' }} />DEN
                            </NavLink>) :
                                (<NavLink exact to={`/den`} className='fw-500 mt-2' onClick={() => this.redirectTo('den')}>
                                    <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.DEN.NAME} className="" style={{ width: 'auto', height: '20px' }} />DEN
                                </NavLink>)}
                            {import.meta.env.VITE_APP_ENV !== 'production' ? (<NavLink exact to={`/den/${this.state.denProfileId ? this.state.denProfileId : this.state.denProfile?._id}/live-streaming`} className='fw-500 mt-2' onClick={() => this.redirectTo('tv')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.TV.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.TV.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.TV.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.TV.NAME} className="" style={{ width: 'auto', height: '20px' }} />TV
                            </NavLink>) :
                                (<NavLink exact to={`/tv`} className='fw-500 mt-2' onClick={() => this.redirectTo('tv')}>
                                    <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.TV.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.TV.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.TV.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.TV.NAME} className="" style={{ width: 'auto', height: '20px' }} />TV
                                </NavLink>)}
                            <NavLink exact to="/notifications" className='fw-500 mt-2' onClick={() => this.redirectTo('notifications')}>
                                <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.NOTIFICATION.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.NOTIFICATION.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.NOTIFICATION.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.NOTIFICATION.NAME} className="" style={{ width: 'auto', height: '20px' }} />
                                {
                                    this.state.notifications?.length ?
                                        <span className={`position-absolute mt-1 fs-8 ${this.state.pendingNotifications ? '' : 'd-none'}`} style={{ marginLeft: '35px', paddingTop: '2px', paddingLeft: '2px', width: '17px', height: '17px' }}>
                                            <Badge bg="danger">{this.state.notifications?.length}</Badge>
                                        </span> : null
                                }
                                MSG
                            </NavLink>
                            <NavLink to="/wallet" className='fw-500 mt-2' onClick={() => this.redirectTo('EC')}>
                                <span className='d-flex align-items-center mt-2'>
                                    <img src={activePage === BOTTOM_NAVBAR_ASSETS.ASSETS.EC.NAME ? BOTTOM_NAVBAR_ASSETS.ASSETS.EC.ON : BOTTOM_NAVBAR_ASSETS.ASSETS.EC.OFF} alt={BOTTOM_NAVBAR_ASSETS.ASSETS.EC.NAME} className="" style={{ width: 'auto', height: '20px' }} />
                                    <p className='text-warning fs-10 mb-0 fw-500'>17K</p>
                                </span>
                                EC
                            </NavLink>


                            <NavLink to="/profile" className='fw-500 mt-2' onClick={() => this.redirectTo('profile')}>
                                <AiOutlineUser className="" style={{ height: '20px', width: 'auto' }} />PROFILE
                            </NavLink>
                        </div>
                    </div>
                </div>

                {/* Modals Section */}
                <div id="auth-modals">

                    <Modal show={openLogin} centered dialogClassName="login-modal">
                        <Modal.Body>
                            <Login openLogin={openLogin} resetAndUpdateFlag={this.resetAndUpdateFlag} saveToLocalStorage={this.props.saveToLocalStorage} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openSignUp} centered dialogClassName="signUp-modal">
                        <Modal.Body>
                            <SignUp openSignUp={openSignUp} resetAndUpdateFlag={this.resetAndUpdateFlag} saveToLocalStorage={this.props.saveToLocalStorage} openPoliciesPopup={this.openPoliciesPopup} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openActivateAccount} centered dialogClassName="activate-account-modal">
                        <Modal.Body>
                            <ActivateAccount openActivateAccount={openActivateAccount} resetAndUpdateFlag={this.resetAndUpdateFlag} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openForgetPassword} centered dialogClassName="forgot-password-modal">
                        <Modal.Body>
                            <ForgotPassword resetAndUpdateFlag={this.resetAndUpdateFlag} openForgetPassword={openForgetPassword} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={showVerifyAccount} centered dialogClassName="verify-account-modal">
                        <Modal.Body>
                            <VerifyChangePassword showVerifyAccount={showVerifyAccount} resetAndUpdateFlag={this.resetAndUpdateFlag} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={createNewPassword} centered dialogClassName="create-new-password-modal">
                        <Modal.Body>
                            <CreateNewPassword resetAndUpdateFlag={this.resetAndUpdateFlag} showCreateNewPassword={createNewPassword} closeCreateNewPassword={this.onClickCloseCreateNewPassword} createNewPasswordToken={createNewPasswordToken} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openLogout}>
                        <Modal.Body>
                            <Logout openLogout={openLogout} resetAndUpdateFlag={this.resetAndUpdateFlag} logout={this.logout} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openSignUpPolicies} centered dialogClassName="sign-up-policies">
                        <Modal.Body>
                            <SignUpPolicies resetAndUpdateFlag={this.resetAndUpdateFlag} socialMediaType={socialMediaType} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openRestrictionsModal} centered dialogClassName="age_restrictions-modal">
                        <Modal.Body>
                            <AgeRestrictionsModal openLogout={openRestrictionsModal} resetAndUpdateFlag={this.resetAndUpdateFlag} />
                        </Modal.Body>
                    </Modal>

                    <Modal show={openRestrictionsModal} centered dialogClassName="age_restrictions-modal">
                        <Modal.Body>
                            <div className="logout-modal position-fixed rounded-15 p-4">
                                <p className="fs-20 fw-700 text-white text-center mb-3">Are you 18+ ?</p>
                                <p className="fs-14 fw-600 text-white-50  text-center mb-3">By continuing you agree that your are 18 years old or older.</p>
                                <div className="d-flex justify-content-center">
                                    <NavLink to="/betting" className={this.state.activePage === 'bet' ? 'd-none' : 'fw-500'} onClick={() => {
                                        this.redirectTo('bet');
                                        this.resetAndUpdateFlag(true, 'openRestricitonsModal', false);
                                    }
                                    } >
                                        <button className="white-flat-btn fw-bold py-1 px-4 m-auto mb-2 me-2" >Yes</button>
                                    </NavLink>
                                    <button className="white-flat-btn fw-bold py-1 px-4 m-auto mb-2 ms-2" onClick={(e) => this.props.resetAndUpdateFlag(true, 'openRestricitonsModal', false)}>No</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        );
    }
}

export default (connect(mapStateToPropsEC, mapDispatchToPropsEC))(Navbar)
