import React, { useRef, useEffect, useState } from 'react';
import './ScrollingText.css'; // Import external CSS file

// Improved styling to ensure proper containment
const styles = {
  scrollingContainer: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    display: 'block'
  },
  scrollingText: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    textOverflow: 'ellipsis'
  },
  animate: {
    animation: 'scrollText 20s linear infinite',
    paddingLeft: '100%' // Start from outside the container
  }
};

const ScrollingText = ({ text, className = '', separator = '' }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);
  
  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && textRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const textWidth = textRef.current.scrollWidth;
        
        // Consider text overflowing if it's wider than the container
        const isOverflowing = textWidth > containerWidth;
        setShouldScroll(isOverflowing);
      }
    };
    
    // Check after initial render and whenever text changes
    checkOverflow();
    
    // Also check after images and fonts have loaded
    window.addEventListener('load', checkOverflow);
    return () => window.removeEventListener('load', checkOverflow);
  }, [text]);

  return (
    <div 
      ref={containerRef} 
      style={styles.scrollingContainer}
      className="scrolling-text-container"
    >
      <div 
        ref={textRef}
        className={`${className} ${shouldScroll ? 'scrolling' : ''}`}
        style={{
          ...styles.scrollingText,
          ...(shouldScroll ? styles.animate : {})
        }}
        aria-label={text}
      >
        {text}
        {shouldScroll && separator && <span className="px-4">{separator}</span>}
        {shouldScroll && text}
      </div>
    </div>
  );
};

export default ScrollingText;