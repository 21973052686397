import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { MEDIA } from '../../../../utils/Images.utils'
import profileLogo from '../../../../images/den/den-profile-logo.png';
import { profile } from '../../../../utils/localstorage.utils';
import Image from '../../../common/Image';
import { getDenProfilePicture } from '../../../../utils/Den.utils';
import { toast } from 'react-toastify';

export default class PostSomething extends Component {

  constructor(props) {
    super(props);
    this.state = {
      denProfile: props.denProfile
    };
  }

  componentWillReceiveProps(newProps){
    this.setState({denProfile: newProps.denProfile})
  }

  openCommontabs = () => {
    this.props.updateState('openMobileSubTabs', true);
  }

  redirectPage = (type) => {
    if(!this.state.denProfile?.isActive){
      toast.error('Please re-activate your den profile to perform any action');
      return false;
    }
    if(type){
      this.props.history.push(`/den/${this.state.denProfile?._id}/create-post/${null}?select=${type}`)
    }else{
      this.props.history.push(`/den/${this.state.denProfile?._id}/create-post/${null}`)
    }
  }

  render() {

    var profilePicture = getDenProfilePicture(this.state.denProfile);
    return (
      <div className='post-card-bg d-flex align-items-center cursor-pointer p-2 mb-2'>
        <p className='fs-14 fw-400 text-white flex-grow-1 my-0' onClick={() => this.redirectPage()}>Want to post something?</p>
        <p onClick={() => this.redirectPage('VIDEO')}><Image src={MEDIA.VIDEO} param={'VIDEO'} alt='' className='mx-1' style={{ height: '16px', width: '22px'}} /></p>
        <p onClick={() => this.redirectPage('IMAGE')}><Image src={MEDIA.IMAGE} param={'IMAGE'} alt='' className='mx-1' style={{ height: '16px', width: '16px'}} /></p>
        <div onClick={this.openCommontabs} className='d-block d-lg-none'>
          <Image src={profilePicture?.url} alt='' param={this.state.denProfile?.name} className='mx-1 rounded-circle' style={{ height: '30px', width: '30px'}} />
        </div>
      </div>
    )
  }
}
