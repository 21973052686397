import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getDenPostById, getDenProfilePicture, getDenProfiles, getPostLikedUsersByID } from '../../../../utils/Den.utils';
import { POST_INTERACTIONS } from '../../../../utils/Images.utils';
import { STYLE } from '../../../../utils/Style.utils';
import Image from '../../../common/Image';
import DenInteractions from '../../../popup/den/DenInteractions';

export default class ReactionsNavInCommments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            likes : [],
            showInteractions: false
        }
    }

    componentDidMount() {
        getPostLikedUsersByID(this.props.postId, this.updateLikesState)
      
    }

    updateLikesState = (likesDetails) =>{

        var allUsers = [];
        {Object.keys(likesDetails).map((key) => { 
            const newArr = likesDetails[key].map(v => ({...v, likeType: key}))
            allUsers = allUsers.concat(newArr)
        })}

      
        this.setState({
            likes: this.getShuffledArr(allUsers)
        })
    }

    getShuffledArr = (arr) => {
    
        const newArr = arr.slice()
        for (let i = newArr.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
        }
        return newArr
    };
    


    render() {
        var likes = this.state.likes.slice(0,7);
        const {showInteractions} = this.state;
      
        return (
            <div>
                <p className='fs-16 fw-500 my-0'>Reactions</p>
                <div className='d-flex align-items-center my-2'>
                {likes.map((profile, i) => {
                    var profilePicture = getDenProfilePicture(profile);
                    return (
                        <div className='d-flex' key={i}>
                            <Image src={profilePicture?.url} param={profile.name? profile.name : 'Test'}  className='rounded-circle' style={{ height:'32px', width:'32px' }}/>
                            <img src={POST_INTERACTIONS.SPARKS[(profile.likeType).toUpperCase()].LOGO} alt='' className='rounded-circle mt-auto' style={STYLE.DEN.REACTED_USERS} />
                        </div>
                    )
                })}
                {this.props.denProfile && <div onClick={()=>this.setState({showInteractions: true})} className='d-flex justify-content-center rounded-circle fw-800 mx-2' style={STYLE.DEN.REACTED_USERS_ICON}>...</div>}
                </div>
                {showInteractions && 
                    <Modal show={showInteractions} centered dialogClassName="transaction-details-modal">
                        <Modal.Body>
                            <DenInteractions
                                denId={this.props.denProfile?._id}
                                id={this.props.postId}
                                type="post"
                                close={()=>this.setState({showInteractions: false})}
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
        )
    }
}
