import React, { Component } from "react";
// Assets
import { BsArrowLeftShort } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
// Utils
import { AxiosResponse, AxiosError } from "axios";
import { errorHandler, updateLoader } from "../../utils/common.utils";
import { toast } from 'react-toastify';
//Services
import AuthApis from "../../helper/auth.api";
// Style
import "../../styles/activate-account.css";
import "../../styles/ForgotPassword.css";

export default class ActivateAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        
        this.api = new AuthApis();
    }

    // To re-send the email verification link to respective email
    resendEmailVerificationLink = () => {
        updateLoader(true);
        this.api.resendEmailVerificationLink()
            .then((response) => {
                updateLoader(false);
                toast.success(response.data.result);
            }).catch((reason) => {
                updateLoader(false);
                errorHandler(reason);
            });
    }

    // Go to mails 
    handleVerifyActivateAccount = () => {
        this.handleCloseClick();
        window.open("https://mail.google.com/mail/", "_blank");
    }

    // To close activate account modal
    handleCloseClick = () => {
        this.props.resetAndUpdateFlag(false,'openActivateAccount',false);
    }

    // Go back to sign up page
    handleBackPress = () => {
        this.handleCloseClick();
        this.props.resetAndUpdateFlag(true,'openSignUp',true);
    }

    render() {
        return (
            <div className="activate-account-page p-4">
                <div className="row">
                    <div className="col-lg-6 col-md-7 col-12 d-flex flex-column">
                        <div className="">
                            <BsArrowLeftShort className="back-arrow position-absolute"  style={{top:'23px', left:'15px'}} onClick={this.handleBackPress} />
                            <h1 className="text-heading fs-20 fw-600 text-white text-center">Activate Account</h1>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{top:'23px', right:'15px'}} onClick={this.handleCloseClick} />
                        </div>
                        <p className="text-description fs-14 text-white text-center pt-2 fw-normal mb-1">Check your E-Mail Address to activate your Espotz Account</p>


                        <button className="white-flat-btn fw-bold py-2 py-2 mx-auto my-3 d-block" onClick={this.handleVerifyActivateAccount} type="submit">
                            Check Your Mail Box
                        </button>
                        <p className="fs-16 text-white mb-0">
                            Did not receive the verification link?<br />
                            <span className="fs-14 fw-bold text-white mt-1 mb-0 cursor-pointer text-decoration-underline" onClick={this.resendEmailVerificationLink}>Send Again</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
