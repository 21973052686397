import React from 'react';
import TeamApis from "../helper/teams.api";
import { errorHandler, updateLoader } from "./common.utils";


const teamApis = new TeamApis();

  export const getAllGamesDetails = (setAllGamesDetails) => {
    updateLoader(true);
    teamApis.getGames().then(
        (res) => {
            updateLoader(false);
            setAllGamesDetails(res.data.result)
        }
    ).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err);
        }
    )
}