import React, { Component } from 'react'
import { BiGlobe } from 'react-icons/bi';
import { receivedAt } from '../../../../utils/dateTime.utils';
import { POST_INTERACTIONS } from '../../../../utils/Images.utils';
import { profile } from '../../../../utils/localstorage.utils';
import Image from '../../../common/Image';
import PostComments from './PostComments';
import { MdOutlineArrowForwardIos, MdOutlineLock } from 'react-icons/md';
import DenPoll from './DenPoll';
import { Carousel } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';
import Sparks from './Sparks';
import { createAndShareDenPost, getDenProfilePicture, updateDenProfileInLocalStorage } from '../../../../utils/Den.utils';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import RePostCard from './RePostCard';
import { DEN } from '../../../../utils/Strings.utils';
import Interactions from './Interactions.js'
import PrivatePost from './PrivatePost';
import { STYLE } from '../../../../utils/Style.utils';
import ReactPlayer from 'react-player';
import LiveChat from './LiveChat';
import { getNumberFormat } from '../../../../utils/Numbers.utils';

export default class PublicPostCard extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            post: props.post,
            i: props.i,
            denProfile: props.denProfile,
            interactions: props.interactions,
            feedDetails: props.feedDetails,
            selectedPostIndex: null,
            postToInteract: null,
        };
    }

    componentWillReceiveProps(newProps){
        this.setState({
            post: newProps.post,
            i: newProps.i,
            denProfile: newProps.denProfile,
            interactions: newProps.interactions,
            feedDetails: newProps.feedDetails,
        })
    }

    updateState = (key, val) => {
        this.setState({[key]: val});
    }

    getFilterredDescription = (description) => {

        var array = description.split(' ');

        return (
            <div>
                {array.map((text,i)=>{
                    if(text.includes("#")){
                        return <b key={i} className='has-tag text-white"'> {text}</b>
                    }else{
                        return <span key={i} className="fs-12 fw-400 text-white" > {text}</span>
                    }
                })}
            </div>
        )

    }

    jumpToLiveStream = (e, postId) => { 
        e.preventDefault();
        this.props.history.push(`/den/${this.state.denProfile?._id}/live-streaming/${postId}`);
    }

    showWarning = () => {
        toast.warning('Please complete your den porfile to perform this action');
        return false;
    }


    render() {
        const {i, post, interactions, denProfile, feedDetails,postToInteract} = this.state;
        
        var sparkDetails = {
            logo : [],
            likes : 0,
            users : []
        };
    
        Object.keys(post.likes).map((key,i)=>{
            sparkDetails.likes += post.likes[key].length 
            sparkDetails.users = sparkDetails.users.concat(post.likes[key]); 
            if(post.likes[key].length >0){
                sparkDetails.logo.push(key)
            }
        });

        const openCommentSection = (this.state.postToInteract !== null && this.state.postToInteract === i);

        if(post.rePostId){
            return <RePostCard 
                key={i}
                sparkDetails={sparkDetails}
                selectedPostIndex={this.state.selectedPostIndex} 
                updatePostDetails={this.showWarning} 
                updateState={this.showWarning} 
                post={post} 
                denProfile={denProfile} 
                feedDetails={feedDetails} 
                i={i} 
                showHidePostAction={this.showWarning}
                postToInteract={postToInteract}
                interactions={interactions} 
                showMoreNotificationIndex={this.state.showMoreNotificationIndex} 
                getFilterredDescription={this.getFilterredDescription}
                updateLikedPostDetails={this.showWarning}
                denPostShared={this.showWarning}
                goToUserDenProfile={this.showWarning}
                updateProfileDetails={this.showWarning}
            />
        }else{
            var profilePicture = getDenProfilePicture(post.from[0]);
            return (
                <div className='col-12 pt-2' key={i} >
                    {this.props.isPrivatePost ?
                         <PrivatePost 
                            post={post} 
                            getFilterredDescription={this.getFilterredDescription} 
                            interactions={interactions} 
                            profilePicture={profilePicture} 
                            sparkDetails={sparkDetails}
                            goToUserDenProfile={this.showWarning}
                        />
                        :   
                        <div className='steel_gray-card'>
                            
                            <div className='row px-3 pt-3'>
                                <div className='col-12'>
                                    <div className="d-flex">
                            
                                        <div onClick={()=>this.showWarning(post.from[0]?._id)}  className="d-flex flex-grow-1 cursor-pointer">
                                            <Image src={profilePicture?.url} param={post.from[0]?.name? post.from[0]?.name : 'Test' } className="rounded-circle btn-size-32 border-1"   alt="owner-avatar" />
                                            <div className="ms-2 flex-grow-1">
                                                <h1 className='fs-14 fw-600 text-white mb-0'>{post.from[0]?.name? post.from[0]?.name : null}  <span style={STYLE.FONT.WEIGHT.W300}>{POST_INTERACTIONS.FEELINGS[post.feeling]? `is feeling ${POST_INTERACTIONS.FEELINGS[post.feeling].NAME}` : post.feeling}</span>  {POST_INTERACTIONS.FEELINGS[post.feeling] && <img src={POST_INTERACTIONS.FEELINGS[post.feeling]?.LOGO} alt='' style={STYLE.FONT.HEIGHT.H18} />}  </h1> 
                                                
                                                <p className='fs-12 text-white-50 fw-bold opacity-25'>{receivedAt(post.createdAt)} <span className='me-1'>.</span>
                                                    {post.from[0]?.isPublic ? <BiGlobe className='text-white btn-size-12' /> :  <MdOutlineLock className='text-white btn-size-12' /> }
                                                </p>
                                            </div>
                                        </div>

                                        {/* <div className="cursor-pointer" onClick={() => this.showWarning(i,true, false)}>
                                            <Image src={POST_INTERACTIONS.ACTIONS.MORE} params={'action'} className="" style={STYLE.AREA.WIDTH.W24} />
                                        </div> */}

                                    </div>
                                </div>
                                <div className='col-12 my-1'>
                                    {post.description &&
                                    <>
                                    { ((this.state.showMoreNotificationIndex !== null && this.state.showMoreNotificationIndex === i) || post.description.length < 251 ) ?
                                        <span className='fs-12 fw-400 text-white' style={STYLE.FONT.SPACE}>{this.getFilterredDescription(post.description)}</span>
                                        :
                                        <span className='fs-12 fw-400 text-white' style={STYLE.FONT.SPACE}>
                                            <em>{this.getFilterredDescription(post.description.slice(0, ( 250-post.description.length)))}...</em>
                                            <a className="fs-14 fw-bold cursor-pointer den-show-more" onClick={() => this.updateState('showMoreNotificationIndex', i)}>Show More</a>
                                        </span>
                                    }
                                    </>
                                    }
                                </div>
                            </div>

                            {post.type && post.type === 'TV' ?
                                <div className='d-flex flex-column align-items-center den-assets cursor-pointer nawaz2' onClick={(e)=>this.showWarning(e, post._id)}>
                                    <div className='cursor-pointer' style={STYLE.DEN.POST.FULL_WIDTH}>
                                        <ReactPlayer className='cursor-pointer' url={post?.assets[0]?.url} width='100%' playing={true} height='210px'  />
                                    </div>
                                </div>
                              :
                                <div className='d-flex flex-column align-items-center den-assets'>

                                    {post.type && post.type === 'POST' ?
                                    
                                    <Carousel className={`${post.assets.length > 1 ? '' : 'single'}`}>
                                        {post.assets.map((asset,i)=>{
                                            return(
                                                <Carousel.Item key={i} interval={null} className="">
                                                    {asset.type.includes('video') ? 
                                                        <video width="320" height="240" className='feed_post-image' controls autoPlay>
                                                            <source src={asset.url} type="video/mp4"/>
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        :
                                                        <img src={asset.url} className='feed_post-image' alt=''  onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src="https://espotz-webapp-images.s3.ap-south-1.amazonaws.com/assets/broken-1.png"
                                                        }} />
                                                    }
                                                </Carousel.Item>
                                            )
                                        })}
                                    </Carousel>
                                    :
                                    <DenPoll updateState={()=>{}} post={post} denProfile={this.state.denProfile} updatePostDetails={this.showWarning} enableEdit={false} unRegisterDenProfile={true} />
                                    }
                                    
                                    {/* Grid of spark's on a post */}
                                    {this.state.selectedPostIndex !== null &&  <Sparks denProfile={this.state.denProfile} index={i} feedDetails={feedDetails} selectedPostIndex={this.state.selectedPostIndex} updateLikedPostDetails={this.updateLikedPostDetails} updateProfileDetails={this.updateProfileDetails} /> }
                                </div>
                            }
                            
                            <div className='d-flex px-2 py-2'>
                                <div className='d-flex align-items-center cursor-pointer'>
                                    {sparkDetails?.logo.map((key,k)=>{
                                        return  <img key={k} src={POST_INTERACTIONS.SPARKS[key.toUpperCase()].LOGO} alt='' className='rounded-circle' style={(k === sparkDetails?.logo.length-1) ? { height:'16px', width:'16px' , marginLeft:'-5px'} : { height:'16px', width:'16px' , marginLeft:'-5px'}} />
                                    })}
                                    <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{sparkDetails?.likes > 0 ? getNumberFormat(sparkDetails?.likes) : null}</span>
                                </div>
                                <div className='d-flex align-items-center mx-2 flex-grow-1'></div>
                                <div className='d-flex align-items-center mx-2'>
                                    <img src={POST_INTERACTIONS.HAMMER.OFF} alt='' style={STYLE.DEN.POST.INTERACTIONS} />
                                    <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{post.hammer.length}</span>
                                </div>
                                <div className='d-flex align-items-center mx-2'>
                                    <img src={POST_INTERACTIONS.SHARE.OFF} alt='' style={STYLE.DEN.POST.INTERACTIONS} />
                                    <span className='fs-14 fw-600 text-white-50 ms-2 my-0'>{post.shares.length}</span>
                                </div>
                            </div>
                            <hr className='my-1' style={STYLE.DEN.POST.LINE} />
                            
                            <Interactions 
                                index={i} 
                                interactions={interactions} 
                                styleLogo={STYLE.DEN.POST_STYLE} 
                                openCommentSection={openCommentSection} 
                                post={post} 
                                selectedPostIndex={this.state.selectedPostIndex}
                                postToInteract={this.state.postToInteract}
                                denProfile={denProfile} 
                                updateState={this.showWarning}
                                shareDenPost={this.showWarning}
                                showWarning={this.showWarning}
                                unRegisterDenProfile={true}
                                isLiveStraming={this.props.isLiveStraming}
                            />
                        
                            {/* {openCommentSection ?  <PostComments denProfile={denProfile} updateProfileDetails={this.updateProfileDetails} postDetails={post} feedDetails={feedDetails} postId={post._id}/> : null} */}
                            {/* {this.props.showLiveComments ? <LiveChat denProfile={denProfile} updateProfileDetails={this.updateProfileDetails} postDetails={post} feedDetails={feedDetails} postId={post._id}/> : null} */}
                        </div>
                    }
                </div>
            )
        }
    }
}
