import React, { Component } from "react";
import "../../styles/activate-account.css";

class AlertBox extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
    }

    render() {
        return (
            <>
                <div className=" p-3">
                    <p className="fs-18 fw-bold text-white text-center mb-3">{this.props.title}</p>
                    <p className="fs-14 flex-grow-1 text-center error-msg" style={{fontWeight:300}}>{this.props.message}</p>
                    <div className="">
                        <button className="white-flat-btn fw-bold py-2 px-4 m-auto mb-2" onClick={() => { this.props.closeAlert() }}>Close</button>
                    </div>
                </div>
            </>
        )
    }
}

export default AlertBox