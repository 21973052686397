import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
// Components
import Policies from '../components/common/Polices';
import ReportBug from '../components/common/ReportBug';
import RequestFeature from '../components/common/RequestFeature';
import TermsAndConditions from '../components/common/TermsAndConditions';
import NotFound from '../components/error/NotFound';
import Home from '../components/home/Home';
import EmailVerification from '../components/auth/EmailVerification';
import Unauthorized from '../components/unauth/Unauthorized';
import Tournament from '../components/tournament/Tournament';
import StreamingComingSoon from 'src/components/TV/ComingSoon/ComingSoon.component';

class UnAuthRoutes extends Component {

    constructor(props) {
        super(props);
        this.state = props.state;
    }
    componentDidUpdate(preProps) {
        if(preProps.isAuthenticated !== this.props.isAuthenticated){
            this.setState({isAuthenticated:this.props.isAuthenticated})
        }
    }
    render() {
        return (
            <Switch>
                <Route exact path="/" render={(props) => (this.state.emailVerificationToken ? <EmailVerification showOrHideNav={this.props.showOrHideNav} {...props} /> : <Home openLogin={this.props.openLogin} isAuthenticated={this.state.isAuthenticated} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />)} />
                <Route exact path="/home" render={(props) => <Home openLogin={this.props.openLogin} isAuthenticated={this.state.isAuthenticated} updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/(organizations|clans|profile|organization/create|tournaments/my-tournaments|notifications|wallet|betting)/" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/(clan/view|clan/team/view)/:id" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/micro-website/layoutOne" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/micro-website/layoutTwo" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/micro-website/layoutThree" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/(view|micro-website/configuration)/:id" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/create" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/(cancel-and-refund| publish-tournament|participant|structure|sponsors|streams|matches-duels|matches|entry-fee-prize-details|final-standing|registration-form|general-setting|participant-form|dispute-management|chatLobby|matches/score-duels|matches/score|matches/update-score-duels)" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournament/:id/overview/structure/tournament-stages/(ffa-single-elimination|ffa-bracket-groups|ffa-simple-stage|duel-bracket-groups|duel-double-elimination|duel-round-robin-group|duel-single-elimination|book-slot)" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/organization/:orgId/tournaments-ratings" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/den/:denId/(create-post/:draftPostId|search|posts|achievements|inner-circle|follow-request|blocked-users|feed/:type/:id|profile-page/:currentUserDenId|profile-page/:currentUserDenId/:followType|live-streaming|live-streaming/:postId|top-fans)" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                
                {/* <Route exact path="/den" render={(props) => <Den updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/betting" render={(props) => <Betting updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} /> */}
                
                <Route exact path="/tournaments" render={(props) => <Tournament updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/report-bug" render={(props) => <ReportBug updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/request-feature" render={(props) => <RequestFeature updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/tournament/:id" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/(clan/view|clan/team/view)/:id" render={(props) => <Unauthorized updateLoader={this.props.updateLoader} saveToLocalStorage={this.props.saveToLocalStorage} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/policies" render={(props) => <Policies updateLoader={this.props.updateLoader} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/terms-and-conditions" render={(props) => <TermsAndConditions updateLoader={this.props.updateLoader} authUser={this.state.authUser} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                
                <Route exact path="/den" render={(props) => <StreamingComingSoon updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/tv" render={(props) => <StreamingComingSoon updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
                <Route exact path="/coming-soon" render={(props) => <StreamingComingSoon updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />

                <Route render={(props) => <NotFound updateLoader={this.props.updateLoader} showOrHideNav={this.props.showOrHideNav} {...props} />} />
            </Switch>
        );
    }
}

export default UnAuthRoutes;