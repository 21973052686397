import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { RiFileCopy2Line } from 'react-icons/ri'
import { FiCheck } from 'react-icons/fi'
import { IoMdClose } from 'react-icons/io'
import TournamentHeader from './common/TournamentHeader';
import disputeIc from '../../../images/icon/disputewhite-ic.png';
import { AiFillCloseCircle } from "react-icons/ai";
import participantAvtar from "../../../images/participantAvtar.jpg";
import { DisputeStatus, errorHandler } from '../../../utils/common.utils';
import ConfirmationModal from '../../popup/ConfirmationModal';

import MatchesApis from '../../../helper/matches.api';
import TournamentApis from '../../../helper/tournament.api';


export default class DisputeManagement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openDisputeManagement: false,
            openConfirm: false,
            tournamentDetails: {},
            disputes: [],
            confirm: {
                msg: '',
                key: ''
            },
            actionType: '',
            selectedDisput: null,
            matchDetails: null,
            sortedParticipants: [],
        }
        this.MatchesApis = new MatchesApis()
        this.tournamentApis = new TournamentApis();
        this.getTournamentData(props.match.params.id)
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        if (this.props.match.params?.matchId) {
            const data = {
                tournamentId: this.props.match.params?.id,
                matchId: this.props.match.params?.matchId
            }
            this.getDisputs(data)
        } else {
            const data = {
                tournamentId: this.props.match.params?.id,
            }
            this.getDisputs(data)
        }
    }

    getTournamentData = (id) => {
        var data = {
            _id: id
        }
        this.props.updateLoader(true)
        this.tournamentApis.getTournaments(data).then(
            (res) => {
                this.props.updateLoader(false)
                //console.log('response --', res.data.result);
                this.setState({ tournamentDetails: res.data.result[0] });
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    getMatchDetails = (disputData) => {
        var data = {
            ids: [disputData.matchId]
        }
        this.props.updateLoader(true)
        this.MatchesApis.getMultipleMatches(data).then(
            (res) => {
                this.createFinalResult(res.data.result[0])
                this.props.updateLoader(false)
                var response = res.data['result'][0]
                this.setState({
                    matchDetails: response,
                    selectedDisput: disputData,
                    openDisputeManagement: true
                })
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }
    noOfGameWon = (playerIndx) => {
        let count = 0
        this.state.matchDetails?.results.map((el, i) => {
            if (el[playerIndx]?.isWinner) {
                count = count + 1
            }
        })
        return count
    }
    createFinalResult = (matchDetails) => {
        var participants = [];
        matchDetails.participants.forEach((participant, index) => {
            participant['result'] = [];
            participant['finalResult'] = {};
            var result = []
            matchDetails.results.forEach((res, i) => {
                var r = matchDetails.results[i][index];
                result[i] = r;
            });
            participant.finalResult = matchDetails.finalResult[index];
            participant.result = result;
            participants.push(participant);
        });
        let sortedResult = participants.sort((a, b) => parseFloat(b.finalResult.score) - parseFloat(a.finalResult.score));
        // console.log("$$$$ participants :: ", participants);
        // console.log("$$$$ sortedResult :: ", sortedResult);
        this.setState({ sortedParticipants: sortedResult })
    }
    rejectMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">
                Are you sure you want to close this dispute ?
            </p>
        );
    };
    resolveMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">
                Are you sure you want to resolve this dispute ?
            </p>
        );
    };
    getDisputs = (params) => {
        this.props.updateLoader(true)
        this.MatchesApis.getDisputs(params).then(
            (res) => {
                this.props.updateLoader(false)
                this.setState({ disputes: res.data.result })
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false)
                errorHandler(err)
            }
        )
    }
    onClickOpenConfirm = (key, msg, type, disput) => {
        const data = {
            msg: msg ? msg : "",
            key: key ? key : "",
        };
        this.setState({
            selectedDisput: disput,
            openConfirm: true,
            confirm: data,
            actionType: type
        });
    };
    disputAction = (i) => {
        let data = this.state.selectedDisput
        if (this.state.actionType === DisputeStatus.RESOLVED) {
            data.disputeStatus = DisputeStatus.RESOLVED
        } else if (this.state.actionType === DisputeStatus.REJECTED) {
            data.disputeStatus = DisputeStatus.REJECTED
        }
        this.props.updateLoader(true)
        this.MatchesApis.updateDisput(data._id, data).then(
            (res) => {
                this.props.updateLoader(false)
                let changeData = this.state.disputes
                changeData[i] = data;
                // if (this.state.actionType === DisputeStatus.RESOLVED) {
                //     changeData[i].disputeStatus = DisputeStatus.RESOLVED
                // } else if (this.state.actionType === DisputeStatus.REJECTED) {
                //     changeData[i].disputeStatus = DisputeStatus.REJECTED
                // }
                this.setState({ openConfirm: false, disputes: changeData })
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false)
                errorHandler(err)
            }
        )
    }
    closePopup = () => {
        this.setState({ openConfirm: false })
    }

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            return el.profilePicture?.url
        } else {
            return el.logo.url
        }
    }

    render() {
        return (
            <div className='body-section clanView-page tournament-overview dispute-management'>
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={disputeIc} heading="Dispute Management" subHeading={null} history={this.props.history} />

                    <div className='row'>
                        {this.state.disputes.map((el, i) => {
                            return (
                                <div className='col-lg-4 col-md-6 col-12' key={i}>
                                    <div className='port-gore-card mt-4 pt-2 pb-2'>
                                        <div className='row p-3'>
                                            <div className='col-6'>
                                                <h6 className='fw-bold text-warning mb-0 mt-1'>Dispute #{i + 1} <RiFileCopy2Line className='btn-size-20 ms-2 mb-1' onClick={() => { this.getMatchDetails(el) }} /></h6>
                                            </div>
                                            {
                                                el?.disputeStatus === DisputeStatus.DISPUTERAISED ?
                                                    <div className='col-6'>
                                                        <span onClick={() => { this.onClickOpenConfirm(i, this.resolveMsg(), DisputeStatus.RESOLVED, el) }} className='round-check-btn btn-size-20 rounded-circle d-flex justify-content-center align-items-center float-end'><FiCheck className='black-rock fs-20' /></span>
                                                        <span onClick={() => { this.onClickOpenConfirm(i, this.rejectMsg(), DisputeStatus.REJECTED, el) }} className='round-close-btn btn-size-20 rounded-circle d-flex justify-content-center align-items-center float-end me-3'><IoMdClose className='black-rock fs-20' /></span>
                                                    </div>
                                                    :
                                                    <div className='col-6'>
                                                        <span className='fs-14 fw-500 float-end mt-1'>{el?.disputeStatus}</span>
                                                    </div>
                                            }

                                            <div className='col-12 mt-2'>
                                                <h6 className='fw-bold'>Title</h6>
                                                <p className='fw-normal text-white-50 fs-14'>{el?.disputeTitle}</p>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <h6 className='fw-bold'>Dispute</h6>
                                                <p className='fw-normal text-white-50 fs-14 text-break'>{el?.dispute}</p>
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <h6 className='fw-bold mb-0'>Attachments</h6>
                                                <div className='d-flex mt-2'>
                                                    {
                                                        el?.images?.map((el, j) => {
                                                            return (
                                                                <span key={j}>
                                                                    <img src={el?.url} alt='' className='mx-2 rounded-2' height="50px" width="50px" />
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                        {
                            this.state.disputes?.length === 0 &&
                            <p className='text-center fw-500 mt-5'>No Dispute Found</p>
                        }
                    </div>
                </div>


                <Modal centered show={this.state.openDisputeManagement} dialogClassName="dispute-management-modal">
                    <Modal.Body>
                        <div className='pt-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <RiFileCopy2Line className='btn-size-20 position-absolute text-warning' style={{ right: '50%' }} />
                                    <AiFillCloseCircle className="btn-size-20 text-white float-end me-3" onClick={() => { this.setState({ openDisputeManagement: false }) }} />
                                </div>

                                <div className='col-12 mt-4 ps-5'>
                                    <h6 className='text-white fw-bold mb-0'>{this.state.selectedDisput?.tournamentName}</h6>
                                    <p className='fw-bold text-white-50'></p>
                                </div>
                            </div>
                            <div className='dispute-match p-3 text-center'>
                                <div className='row'>
                                    {
                                        this.state.selectedDisput?.tournamentType == "FFA" ?
                                            <div className='col-12'>
                                                <div className='' style={{ overflowX: 'auto' }}>
                                                    <table className="table grey-card"
                                                    // style={{ width: 'auto' }}
                                                    >
                                                        <thead>
                                                            <tr className='text-warning fs-16 fw-500'>
                                                                <th scope="col" className='fw-600 text-center'>Rank</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col" className='fw-600 text-center' style={{ minWidth: '100px' }}>Score</th>
                                                                {
                                                                    this.state.matchDetails?.results.map((el, j) => {
                                                                        return (
                                                                            <th key={j} scope="col" className='fw-600 text-center' style={{ minWidth: '100px' }}>Game {j + 1}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody className='table-borderless text-white '>
                                                            {
                                                                this.state.sortedParticipants.map((el, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th scope="row" className='fw-500 text-center'>{i + 1}</th>
                                                                            <td className='fw-600' >
                                                                                {/* <span className='rank-shadow rank-1-shadow'></span> */}
                                                                                {el?.name}</td>
                                                                            <td className='text-center'>{el?.finalResult.score}</td>
                                                                            {
                                                                                el?.result.map((td, j) => {
                                                                                    return (
                                                                                        <td key={j} className='text-center'>{td.score}</td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            :
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <img src={this.state.matchDetails?.participants[0] ? this.getURL(this.state.matchDetails?.participants[0]) : participantAvtar} className="rounded-circle w-50 " alt='' />
                                                        <h5 className='text-white fw-bold mt-3' style={{ fontSize: '15px' }}>{this.state.matchDetails?.participants[0]?.name}</h5>
                                                        {/* <p className='text-white fw-bold mb-0'>0/0/0</p> */}
                                                        <p className='text-white fw-bold'>{this.state.matchDetails?.finalResult[0].score}</p>
                                                    </div>
                                                    <div className='col-4'>
                                                        <h5 className='text-white fw-bold mt-1' style={{ fontSize: '30px' }}>{this.noOfGameWon(0)} - {this.noOfGameWon(1)}</h5>
                                                        {/* <p className='text-white-50 fw-bold mb-0 mt-5'>K/D/A</p> */}
                                                        <p className='text-white-50 fw-bold mb-0'>Score</p>
                                                    </div>
                                                    <div className='col-4'>
                                                        <img src={this.state.matchDetails?.participants[1] ? this.getURL(this.state.matchDetails?.participants[1]) : participantAvtar} className="rounded-circle w-50 " alt='' />
                                                        <h5 className='text-white fw-bold mt-3' style={{ fontSize: '15px' }}>{this.state.matchDetails?.participants[1]?.name}</h5>
                                                        {/* <p className='text-white fw-bold mb-0'>8/5/7</p> */}
                                                        <p className='text-white fw-bold'>{this.state.matchDetails?.finalResult[1].score}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>TOURNAMENT :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.tournamentName}</h6>
                                    </div>
                                    {/* <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>DATE :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>DEC 15 @7:15 PM</h6>
                                    </div>
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>STATUS :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>Completed </h6>
                                    </div> */}
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>GAME :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.gameName}</h6>
                                    </div>
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>STAGE :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.stageName}</h6>
                                    </div>
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>ROUND :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.round}</h6>
                                    </div>
                                    {/* <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>MATCH :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>M 2.1</h6>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal
                            confirm={this.state.confirm}
                            onClickOpenConfirm={this.closePopup}
                            confirmed={this.disputAction}
                        />
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}
