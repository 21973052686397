import React, { Component } from 'react';
import { calculateMatches, linkWithDiscord, saveCloseRegistration } from "../../../../../../utils/common.utils";
import { Modal } from "react-bootstrap";
import MatchInvite from "../../../../../popup/MatchInvite";
import AcceptInvite from '../../../../../popup/AcceptInvite';
import { addParticipantInTournament, addParticipantToFirstRound2, getMyTeams, getStageDetails } from '../../../../../../utils/Tournament.utils';
import { toast } from 'react-toastify';
import Registration from '../../../Registration';
import SingleBrackets from '../../../../../common/SingleBrackets';
import { profile } from '../../../../../../utils/localstorage.utils';
import ConfirmationModal from '../../../../../popup/ConfirmationModal';
import { ERROR } from '../../../../../../utils/Strings.utils';
import Scoreboard from '../../../Scoreboard';

export default class DuelsSingleEliminationMatches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stageDetails: props?.stageDetails ? props.stageDetails : null,
            tournamentDetails: props?.tournamentDetails ? props.tournamentDetails : null,
            scale: 1.1,
            openInvite: false,
            selectedPlayers: [],
            isSwappingEnabled: false,
            matchId: -1,
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null,
            showRegistrationScreen: false,
            matchIndex: '0.0',
            confirm: null,
            showScoreBoard: false,
            matchDetails: null,
            matchNo: null
        };

        this.prevValue = 0;
        this.maxWidth = 0;
        this.isSwapped = false;
        this.enableLoading = false;
    }

    componentDidMount() {
        if (this.props.setGetAlertMethod) {
            this.props.setGetAlertMethod(this.registerForTournament);
        }

        saveCloseRegistration(this.closeRegistrationScreen);
    }

    componentWillReceiveProps(newProps) {
        if (!this.enableLoading) {
            this.setState({
                stageDetails: newProps?.stageDetails ? newProps.stageDetails : this.state.stageDetails,
                tournamentDetails: newProps?.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,
            })
        }
    }

    switchRegistrationScreens = (matchDetail, matchIndex, teamId) => {
        this.setState({
            showRegistrationScreen: !this.state.showRegistrationScreen,
            selectedMatchDetail: matchDetail,
            matchIndex: matchIndex,
            selectedTeams: (teamId ? teamId : null)
        })
    }

    // START :: Tournament Registration  Process
    registerForTournament = (e, matchDetail, matchIndex, matchNo) => {
        if (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true && this.state.tournamentDetails?.participantType === 'TEAM') {
            this.switchRegistrationScreens(matchDetail, matchIndex);
        } else {


            this.enableLoading = true;
            if (this.state.tournamentDetails?.participantType === 'SINGLE') {
                this.setState({
                    initiateRegistration: true,
                    selectedMatchDetail: matchDetail,
                    selectedMatchIndex: matchIndex
                });
            } else {
                this.setState({
                    selectedMatchDetail: matchDetail
                });
                getMyTeams(this.state.tournamentDetails.gameId, matchDetail, this.addParticipantInTournament, this.selectTeams)
            }

        }

    }

    selectTeams = (success, data) => {
        if (success) {
            this.setState({
                teamsArray: data.teamsArray,
                initiateRegistration: true,
                selectedMatchDetail: data.selectedMatchDetail
            });
        } else {
            toast.warning(ERROR.NO_TEAM_FOR_TOURNAMENT);
        }

    }

    addParticipantInTournament = (matchId, teamId) => {
        if (this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFee === true) {
            this.switchRegistrationScreens(this.state.selectedMatchDetail, this.state.selectedMatchIndex, teamId);
            this.resetRegistration();
        } else {
            const stageInfo = this.state.tournamentDetails.stages[0];
            if (stageInfo.type === "DUEL" && (stageInfo.subType === "Single Elimination" || stageInfo.subType === "Double Elimination" || stageInfo.subType === "Bracket Groups")) {
                var data = { participantId: teamId }
                addParticipantToFirstRound2(this.state.tournamentDetails, data, this.updateStageDetails);

            } else {
                addParticipantInTournament(this.updateStageDetails, this.state.selectedMatchDetail._id, teamId, this.state.stageDetails, this.state.tournamentDetails);
            }
        }
    }

    onClickOpenInvite = (joinTeamId) => {
        this.setState({
            initiateRegistration: !this.state.initiateRegistration
        });
        if (joinTeamId) {
            const stageInfo = this.state.tournamentDetails.stages[0];
            if (stageInfo.type === "DUEL" && (stageInfo.subType === "Single Elimination" || stageInfo.subType === "Double Elimination" || stageInfo.subType === "Bracket Groups")) {
                var data = { participantId: joinTeamId }
                addParticipantToFirstRound2(this.state.tournamentDetails, data, this.updateStageDetails);

            } else {
                addParticipantInTournament(this.updateStageDetails, this.state.selectedMatchDetail._id, joinTeamId, this.state.stageDetails, this.state.tournamentDetails);
            }
        }
        this.resetRegistration();
    }

    updateStageDetails = (response) => {
        this.setState({
            stageDetails: response,
        });
        this.resetRegistration();
    }

    resetRegistration = () => {
        this.setState({
            teamsArray: [],
            initiateRegistration: false,
            selectedMatchDetail: null,
            selectedMatchIndex: null,
            selectedTeams: null
        });
    }

    // END :: Tournament Registration Process

    closeRegistrationScreen = () => {
        getStageDetails(this.state.tournamentDetails?._id, this.props.index, this.setData)
    }

    setData = (values) => {
        if (values) {
            this.setState({
                stageDetails: values,
                showRegistrationScreen: false
            });
        } else {
            this.setState({
                showRegistrationScreen: false
            });
        }
    }

    onClickRegister = () => {

        if (profile()?.discordProfile?.discordUsername) {
            return (
                <Modal.Body>
                    <AcceptInvite
                        clearInvitations={this.resetRegistration}
                        tournamentId={this.state.tournamentDetails?._id}
                        addParticipantInTournament={this.addParticipantInTournament}
                        joinTeamRole={null}
                        close={this.resetRegistration}
                        type="add"
                        info={{}}
                        title='Register'
                        role='player'
                        // matchId={this.state.selectedMatchDetail?._id}
                        isTournamentInvitation={true}
                    />
                </Modal.Body>
            );
        } else {
            return (
                <Modal.Body>
                    <ConfirmationModal confirm={linkWithDiscord()} onClickOpenConfirm={this.resetRegistration} confirmed={this.confirmed} />
                </Modal.Body>
            );
        }
    }

    confirmed = () => {
        this.resetRegistration();
        this.props.history.push('/profile');
    }

    hideAndShowScoreBoard = (matchDetails, matchNo) => {
        this.setState({
            showScoreBoard: !this.state.showScoreBoard,
            matchDetails: !this.state.showScoreBoard === true ? matchDetails : null,
            matchNo
        });
    }

    render() {

        if (this.state.stageDetails) {
            var pairs = (this.state.stageDetails.maxParticipants / 2)
            var threshold = this.state.stageDetails.qualificationThreshold === 1 ? 1 : (this.state.stageDetails.qualificationThreshold / 2);
            const pop = ((!this.state.stageDetails.includeThirdPlace && this.state.stageDetails.qualificationThreshold !== 1) ? true : false)

            var structure = calculateMatches(pairs, threshold, pop, this.state.stageDetails.includeThirdPlace, false);


            return (
                <div id='tournament-date'>
                    {this.state.showRegistrationScreen ?
                        <Registration
                            tournamentDetails={this.state.tournamentDetails}
                            stageDetails={this.state.stageDetails}
                            selectedMatchDetail={this.state.selectedMatchDetail}
                            matchIndex={this.state.matchIndex}
                            selectedTeams={this.state.selectedTeams}
                        />
                        :
                        this.state.showScoreBoard ?
                            <Scoreboard
                                matchDetails={this.state.matchDetails}
                                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                                tournamentDetails={this.state.tournamentDetails}
                                matchNo={this.state.matchNo}
                            />
                            :
                            <SingleBrackets
                                structure={structure}
                                includeThirdPlace={this.state.stageDetails?.includeThirdPlace}
                                openInviteModal={this.registerForTournament}
                                stageMatchDetails={this.state.stageDetails.groups[0]}
                                isTournamentOperator={false}
                                tournamentDetails={this.state.tournamentDetails}
                                stageDetails={this.state.stageDetails}
                                index={this.props.index}
                                hideAndShowScoreBoard={this.hideAndShowScoreBoard}
                                marginTopDiv=''
                            />

                    }

                    {/* Tournament Invitation Section */}
                    <Modal centered show={this.state.initiateRegistration} dialogClassName="ClanInvite-modal">
                        {this.state.tournamentDetails?.participantType === 'SINGLE' ?
                            this.onClickRegister()
                            :
                            <Modal.Body>
                                <MatchInvite
                                    onClickOpenInvite={this.onClickOpenInvite}
                                    teamsArray={this.state.teamsArray}
                                    choice="Join Tournament"
                                    isRoundRobin={false}
                                />
                            </Modal.Body>
                        }
                    </Modal>

                </div>
            );
        } else {
            return (
                <div>
                    <h1>No Data Found</h1>
                </div>
            );
        }

    }
}
