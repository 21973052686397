import React, { Component } from 'react';
import formInfo from '../../../../../images/icon/formInfo.png';
import { getMatchAndParticipantsDetailsForBracketsDoubleElimination, getTournamentAdditionalData } from '../../../../../utils/common.utils';
import MatchesFFA from './MatchesFFA';
import MatchesDuels from './MatchesDuels';
import TournamentHeader from '../../common/TournamentHeader';
import { ASSETS } from '../../../../../utils/Strings.utils';

export default class Matches extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: null,
            openMatchesFilter: false,
            stageIndex: 0,
            matchDetailsAdded: false,
        }
        props.updateLoader(true);
        getTournamentAdditionalData(this.props.match.params.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            });
            //this.props.updateLoader(false);
            if (values.stages.length > 0 && typeof (values.stages[0].groups[0][1][0]) !== 'object') {
                this.props.updateLoader(true);
                //getMatchAndParticipantsDetails3(values.stages,this.setMatchData,this.state.stageIndex);
                getMatchAndParticipantsDetailsForBracketsDoubleElimination(values._id, this.setMatchData, this.state.stageIndex, values.stages);
                this.props.updateLoader(false);
            }

        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }


    setMatchData = (values) => {
        if (values) {
            var tournamentDetails = this.state.tournamentDetails;
            tournamentDetails.stages = values;
            this.setState({
                tournamentDetails: tournamentDetails,
                matchDetailsAdded: true
            });
            this.props.updateLoader(false);

        } else {
            this.props.updateLoader(false);
        }
    }

    updateStage = (id) => {

        if (id !== this.state.stageIndex) {
            this.setState({
                stageIndex: id
            });

            if (this.state.tournamentDetails.stages.length > 0 && typeof (this.state.tournamentDetails.stages[id].groups[0][1][0]) !== 'object') {
                this.props.updateLoader(true);
                // getMatchAndParticipantsDetails3(this.state.tournamentDetails.stages,this.setMatchData,id);
                getMatchAndParticipantsDetailsForBracketsDoubleElimination(this.state.tournamentDetails._id, this.setMatchData, id, this.state.tournamentDetails.stages);

            }
        }

    }

    getMatches = () => {
        if (this.state.matchDetailsAdded) {
            return (
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={formInfo} heading="Matches" subHeading={null} history={this.props.history} />
                    <div className=''>
                        {(this.props.match.params?.type).includes('FFA') ?
                            <MatchesFFA
                                stageIndex={this.state.stageIndex}
                                updateStage={this.updateStage}
                                tournamentDetails={this.state.tournamentDetails}
                                location={this.props.location}
                                match={this.props.match}
                                history={this.props.history}
                                updateLoader={this.props.updateLoader}
                            />
                            :
                            <MatchesDuels
                                stageIndex={this.state.stageIndex}
                                updateStage={this.updateStage}
                                tournamentDetails={this.state.tournamentDetails}
                                location={this.props.location}
                                match={this.props.match}
                                history={this.props.history}
                                updateLoader={this.props.updateLoader}
                            />
                        }
                    </div>


                </div>
            );
        } else {
            return (
                <div className="container p-3 d-flex flex-column h-100 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={formInfo} heading="Matches" subHeading={null} history={this.props.history} />
                    <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                        <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt='' />
                    </div>
                </div>
            );
        }
    }



    render() {
        const getMatches = this.getMatches();
        return (
            <div className="body-section tournament-matches">
                {getMatches}
            </div >
        )
    }
}
