import React, { Component, createRef } from 'react';
import { Modal } from "react-bootstrap";
import ClanInvite from '../popup/ClanInvite';
import CaptainIcon from '../../images/icon/captain-ic.png';
import AddMember from '../popup/AddMember';
import AssignMember from '../popup/AssignMember';
import AcceptInvite from '../popup/AcceptInvite';
import { toast } from 'react-toastify';
import TeamApis from "../../helper/teams.api";
import '../../styles/TeamView.css';
import DefaultImage from '../../images/image 65.jpg';
import DefaultImageLoader from '../../images/img_loader.gif';
import ShowMember from '../popup/ShowMember';
import ClanApis from "../../helper/clan.api";
import { ellipsis, errorHandler, defaultPreviewImage, capitalize, getMembersIds, getAllMembersIdFromTeam } from '../../utils/common.utils'
import { profile } from '../../utils/localstorage.utils'
import ConfirmationModal from '../popup/ConfirmationModal';
import { NavLink } from 'react-router-dom';
import { IoArrowBackCircleOutline, IoPersonAdd } from "react-icons/io5";
import { AiOutlineSetting, AiFillPlusCircle, AiFillMinusCircle, AiFillCopyrightCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ImagePreview from '../common/ImagePreview';
import { BsCircle } from 'react-icons/bs'
import leaveIcon from '../../images/icon/leave-ic.png'
import deleteIcon from '../../images/icon/delete-ic.png'
import participateIcon from '../../images/icon/participate-ic.png'
import lineupsIc from '../../images/icon/linups-ic.png'
import editInfoIc from '../../images/icon/edit-info-ic.png'
import instructionIcon from '../../images/icon/instruction-ic.png'
import { BiPlusMedical } from 'react-icons/bi';
import { createAndSendNotification, removeOwnerOrAdminNotification } from '../../utils/notification.utils';

class TeamView extends Component {

    constructor(props) {
        super(props);

        this.teamMembers = ['players', 'substitutes', 'coach', 'manager'];
        this.state = {
            teamView: 100,
            openClanInvite: false,
            openAcceptInvite: false,
            openAssignMember: false,
            openAddMember: false,
            openShowMember: false,
            teamApi: new TeamApis(),
            clanApi: new ClanApis(),
            teamId: this.props.match.params.id,
            teamData: '',
            showClanMember: [],
            clanData: {},
            currentUserId: profile()?.id,
            currentUserTeamPosition: '',
            currentUserClanPosition: '',
            inviteAs: '',
            openConfirm: false,
            confirm: {},
            removeMember: {},
            selectedPlayers: [],
            selectedSubstitute: [],
            selectedCaptain: {},
            inGameInfo: {},
            participateInTeam: false,
            participantRole: this.teamMembers[0],
            multipleTeams: false,
            previewImageDetails: {
                display: false,
                src: defaultPreviewImage
            },
            teamMembers: ['players', 'substitutes', 'coach', 'manager']

        };

        this.multiselectRef = createRef();
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.getData(this.props.match.params.id)
    }
    componentDidUpdate(previous) {
        if (previous.parentUpdate !== this.props.parentUpdate) {
            this.getData(this.props.match.params.id)
        }
    }
    // After confirmation popup true
    confirmed = (key) => {
        const data = this.state.teamData;
        if (key === 'remove') {
            const userData = {
                teamId: this.state.teamId,
                userId: this.state.removeMember?.id,
                role: this.state.removeMember.from
            }
            this.removeMember(userData)
        } else if (key === 'leave') {
            let index = data?.members[this.state.currentUserTeamPosition]?.map(el => { return el.id }).indexOf(this.state.currentUserId)
            let teamMemberId = data?.members[this.state.currentUserTeamPosition][index]._id
            const userData = {
                teamId: this.state.teamId,
                userId: teamMemberId,
            }
            this.leaveTeam(userData)
        } else if (key === 'delete') {
            this.deleteTeam()
        }

        this.setState({
            openConfirm: !this.state.openConfirm,
        });
    }

    //Remove member Msg
    removeMsg = (position, name) => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to remove {name} from the team {position} position?</p>
        )
    }

    //Leave msg
    leaveMsg = () => {
        return (
            (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') ?
                <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to leave this team?</p> :
                this.state.multipleTeams ?
                    <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to leave this team?</p> :
                    <p className="fs-14 fw-600 text-center text-white-50"> By leaving this team, you are leaving the clan as well. Are you sure you want to leave the team?</p>
        )
    }

    /*Clan delete Confirm Msg */
    teamDeleteMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50"> <span className="error-msg">Upon deletion of the team, every member will be removed from the team.<br />
                The team will no longer exist, so no Espotz tournaments can be registered by using this team anymore. </span> Are you sure you want to <span className="error-msg">delete</span> the team?</p>
        )
    }

    //Open Confirmation popups
    onClickOpenConfirm = (key, msg) => {
        const data = {
            msg: msg ? msg : '',
            key: key ? key : ''
        }
        this.setState({
            openConfirm: !this.state.openConfirm,
            confirm: data
        });
    }

    //Set Remove member id and position
    setRemoveData(user, id, from, name) {
        
        const data = {
            id: id,
            from: from,
            name: name,
            userId: user?.id
        }
        this.setState({ removeMember: data })
    }

    // To delete Team
    deleteTeam() {

        this.props.updateLoader(true)
        this.state.teamApi.deleteTeam(this.state.teamId).then(
            (res) => {
                this.props.updateLoader(false)
                toast.success('Team deleted successfully');
                this.sendNotifications('Delete');
                this.props.history.push(`/clan/view/${this.state.teamData.clanId}`)
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false)
                //console.log('delete error::', err)
                errorHandler(err, this.props.logout);
            }
        )
    }

    sendNotifications = (action,sendTo) => {
        var to;
        
        if(sendTo){
            to = sendTo;
        }else{
            to = getAllMembersIdFromTeam(this.state?.teamData?.members);
        }
    
        var data;
        switch (action) {
          case 'Delete':
            data =  {
              type : "TEAM",
              heading : "Team Deleted 🗑️ :",
              message : `Your team '${this.state?.teamData?.name}' of clan '${this.state?.clanData?.name}' has been deleted by ${profile().name}.`,
              link : null
            };
            break;
        case 'Captain':
            data =  {
                type : "TEAM",
                heading : "Team Shuffled 🔀 :",
                message : `Your team '${this.state?.teamData?.name}' has been re-shuffled by ${profile().name} and now you are the Captain of this team.`,
                link : `/clan/team/view/${this.state?.teamData?._id}`
            };
            break;
        case 'Player':
            data =  {
                type : "TEAM",
                heading : "Team Shuffled 🔀 :",
                message : `Your team '${this.state?.teamData?.name}' has been re-shuffled by ${profile().name} and now you are the Player of this team.`,
                link : `/clan/team/view/${this.state?.teamData?._id}`
            };
            break;
        case 'Substitute':
            data =  {
                type : "TEAM",
                heading : "Team Shuffled 🔀 :",
                message : `Your team '${this.state?.teamData?.name}' has been re-shuffled by ${profile().name} and now you are the Substitute of this team.`,
                link : `/clan/team/view/${this.state?.teamData?._id}`
            };
            break;
          default:
            break;
        }
    
        createAndSendNotification(data, to);
    }

    removeFromPlayers = (el, i) => {
        if (this.state.selectedSubstitute.length >= 3) {
            toast.error('Team Cannot Have more than 3 substitute')
            return false
        } else {
            let data = this.state.selectedSubstitute;
            let removeData = this.state.selectedPlayers;
            data.push(el);
            removeData.splice(i, 1);
            this.setState({
                selectedSubstitute: data,
                selectedPlayers: removeData
            })
            if (el?._id === this.state.selectedCaptain?._id && removeData?.length) {
                this.setState({ selectedCaptain: removeData[0] })
            }
            if (!removeData?.length) {
                this.setState({ selectedCaptain: null })
            }
        }

    }

    //remove from substitute and add in players
    removeFromSubstitute = (el, i) => {
        let data = this.state.selectedPlayers;
        let removeData = this.state.selectedSubstitute;
        data.push(el);
        removeData.splice(i, 1);
        this.setState({
            selectedSubstitute: removeData,
            selectedPlayers: data
        })
        if (data?.length === 1) {
            this.setState({ selectedCaptain: data[0] })
        }
    }

   
    makeCaptain = (el) => {
        this.setState({ 
            selectedCaptain: el
        });
    }

    //Initial get Data
    getData(id) {
        const data = {
            _id: id
        }
        this.props.updateLoader(true);
        this.state.teamApi.getTeams(data).then(
            (res) => {
                if (res.data['result']?.length === 0) {
                    this.props.history.push(`/clans`);
                }
                let data = res.data['result'][0]
                this.getClan(data.clanId)
                this.getMyTeams(data.clanId)
                this.setInitial(data)
                this.props.updateLoader(false);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    //Get My teams
    getMyTeams = (id) => {
        const data = {
            clanId: id
        }
        this.props.updateLoader(true)
        this.state.teamApi.getMyTeams(data).then(
            (res) => {
                if (res.data.result.length > 1) {
                    this.setState({
                        multipleTeams: true,
                    })
                }
                this.props.updateLoader(false);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    //Set initial value after get and update
    setInitial = (data) => {
        this.setState({
            teamData: data,
            teamView: 100
        })
        if ((data?.members?.players && data?.members?.players?.map(el => { return el?.id }).includes(this.state.currentUserId))) {
            this.setState({ currentUserTeamPosition: 'players' });
        } else if (data?.members?.substitutes && data?.members?.substitutes?.map(el => { return el?.id }).includes(this.state.currentUserId)) {
            this.setState({ currentUserTeamPosition: 'substitutes' });
        } else if (data?.members?.coach && data?.members?.coach?.map(el => { return el?.id }).includes(this.state.currentUserId)) {
            this.setState({ currentUserTeamPosition: 'coach' });
        } else if (data?.members?.manager && data?.members?.manager?.map(el => { return el?.id }).includes(this.state.currentUserId)) {
            this.setState({ currentUserTeamPosition: 'manager' });
        }
        let selectedPlayers = []
        let selectedSubstitutes = []
        data?.members?.players?.map(el => {
            if (el) {
                selectedPlayers.push(el)
            }
        })
        data?.members?.substitutes?.map(el => {
            if (el) {
                selectedSubstitutes.push(el)
            }
        })
        this.setState({ selectedCaptain: data?.members?.captain, selectedPlayers: selectedPlayers, selectedSubstitute: selectedSubstitutes })
        let index = data?.members[this.state.currentUserTeamPosition]?.map(el => { return el.id }).indexOf(this.state.currentUserId)
        if (this.state.currentUserTeamPosition) {
            this.setState({
                inGameInfo: data?.members[this.state.currentUserTeamPosition][index]
            })
        }
    }

    //Team Shuffle
    teamShuffle = () => {
        if (this.state.selectedSubstitute.length > 3) {
            toast.error('Team Cannot Have more than 3 substitute')
            return false
        }

        if (this.state.selectedPlayers.length === 0) {
            this.setState({ selectedCaptain: {} })
        }
        if (this.state.selectedPlayers.length > this.state.teamData?.gameDetails?.maxPlayersPerTeam) {
            toast.error(`Team Cannot Have more than ${this.state.teamData?.gameDetails?.maxPlayersPerTeam} players`)
            return false
        } else {
            const data = this.state.teamData
            data.members.captain = this.state.selectedCaptain
            data.members.players = this.state.selectedPlayers
            data.members.substitutes = this.state.selectedSubstitute
            this.setState({ teamData: data })
            this.updateTeam('Team reassigned successfully', 544)
        }

    }

    //getClans
    getClan(id) {
        const data = {
            _id: id
        }
        this.props.updateLoader(true)
        this.state.clanApi.getClans(data).then(
            (res) => {
                var data = res.data['result'][0]
                this.setState({
                    clanData: data
                })
                if ((data?.members?.owner && data?.members?.owner?.map(el => { return el?.id }).includes(this.state.currentUserId))) {
                    this.setState({ currentUserClanPosition: 'owner' });
                } else if (data?.members?.admin && data?.members?.admin?.map(el => { return el?.id }).includes(this.state.currentUserId)) {
                    this.setState({ currentUserClanPosition: 'admin' });
                }
                this.props.updateLoader(false)
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false)
                errorHandler(err, this.props.logout);
            }
        )
    }

    // Set member info and open modal
    onClickOpenShowMember = (members, title) => {
        const data = {
            members: members ? members : '',
            title: title ? title : ''
        }
        this.setState({
            openShowMember: !this.state.openShowMember,
            showClanMember: data
        })
    }

    //Toggle popups
    toggelModal = (key) => {
        this.setState({
            [key]: !this.state[key]
        });
    }

    //Edit My Bio
    bioChange = (data) => {
        this.setState({
            inGameInfo: data,
            openAcceptInvite: false
        });
    }

    //Remove member from team
    removeMember = (data) => {
        this.props.updateLoader(true);
        this.state.teamApi.removeTeamMember(data).then(
            (res) => {
                removeOwnerOrAdminNotification('team', this.state?.teamData, this.state.removeMember?.userId, this.state.removeMember?.from);
                this.setInitial(res.data.result)
                let data = res.data['result'];
                this.props.updateLoader(false);
                toast.success(`${this.state.removeMember?.name} Removed Successfully`)
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    //Leave Team
    leaveTeam = (data) => {
        this.props.updateLoader(true)
        this.state.teamApi.leaveTeam(data).then(
            (res) => {
                this.props.updateLoader(false);
                toast.success('Team Left Successfully')
                if (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') {
                    this.props.history.push(`/clan/view/${this.state.teamData.clanId}`)
                } else {
                    if (this.state.multipleTeams) {
                        this.props.history.push(`/clan/view/${this.state.teamData.clanId}`)
                    } else {
                        this.props.history.push(`/clans`)
                    }
                }
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    //Update team api 
    updateTeam(updateMsg, type) {
        const data = this.state.teamData;
        delete data.gameDetails;
        delete data.clanDetails
        data.members.captain = data.members?.captain?._id;
        data.members.players = data?.members?.players?.map(el => { return el?._id })
        data.members.substitutes = data?.members?.substitutes?.map(el => { return el?._id })

        if (this.state.teamData.members?.manager && this.state.teamData.members?.manager?.length) {
            data.members.manager = data?.members?.manager?.map(el => { return el?._id })
        }
        if (this.state.teamData.members?.coach && this.state.teamData.members?.coach?.length) {
            data.members.coach = data?.members?.coach?.map(el => { return el?._id })
        }
        this.props.updateLoader(true);
        this.state.teamApi.updateTeam(this.state.teamId, data).then(
            (res) => {
         
                let data = res.data['result'];

                if (type = 544) {
                    this.sendNotificationsAfterShuffle(this.state);
                    this.setState({
                        selectedPlayers: [],
                        selectedSubstitutes: [],
                        selectedCaptain: {}
                    });
                }
                //this.setInitial(data)
                this.getData(data._id)
                this.props.updateLoader(false);
                toast.success(updateMsg)
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    sendNotificationsAfterShuffle = (state) => {
        var {selectedCaptain, selectedSubstitute, selectedPlayers} = state;
        // console.log("### selectedCaptain :: ",selectedCaptain);
        // console.log("### selectedSubstitute :: ",selectedSubstitute);
        // console.log("### selectedPlayers :: ",selectedPlayers);

      
        var players = [];
        var substitutes = [];

        selectedPlayers.forEach((player,i)=>{
            if(player.id !== selectedCaptain.id){
                players.push(player.id);
            }

        });

        selectedSubstitute.forEach((substitute,i)=>{
            substitutes.push(substitute.id);
        });

        // Send Notification to Captain
        this.sendNotifications('Captain', [selectedCaptain.id]);
        this.sendNotifications('Player', players);
        this.sendNotifications('Substitute', substitutes);

    }

    //participate in team if admin and owner of clan want to join team
    participateInTeam = (param) => {
        this.setState({
            participateInTeam: param
        });
    }

    //Join team invite link
    joinTeam = () => {
        this.participateInTeam(false);
        this.props.joinTeamAfterCreation(this.state.teamData._id, this.state.participantRole, 'Team Profile')
    }


    previewImage = (src, param) => {
        this.setState({
            previewImageDetails: {
                display: param,
                src: (param ? src : defaultPreviewImage)
            }
        });
    }

    removeFromCaptain = () => {
        //console.log('Remove Captain Called')
    }

    render() {
        const { openAddMember, openAssignMember, openClanInvite, openAcceptInvite, openShowMember } = this.state
        return (
            <><section className="body-section team-view-page">
                <div className="teamView-banner">
                    <img className="clanView-background" src={this.state.clanData?.coverImage?.url ? this.state?.clanData?.coverImage?.url : DefaultImageLoader} width="50" height="50" alt='' />

                    <img className="clanView-fronImage"
                        src={this.state.clanData?.coverImage?.url ? this.state?.clanData?.coverImage?.url : DefaultImageLoader}
                        onClick={() => this.previewImage((this.state.clanData?.coverImage?.url ? this.state?.clanData?.coverImage?.url : DefaultImageLoader), true)}
                        width="50" height="50" alt='' />

                    <div className="team-name position-absolute w-100">
                        {/* <h1 className="gameBlock-header">Game</h1> */}
                        <h1 className=" fs-12 fw-500 text-white-50 border border-1 float-end me-2 rounded-10 py-1 py-md-1 py-lg-2 px-2 px-md-3 px-lg-4" style={{ background: '#353550' }}>{this.state.teamData?.gameDetails?.name}</h1>
                    </div>
                </div>

                {(this.state.teamView === 100) &&
                    <div className="container teamView-section ps-4 pe-4">
                        <NavLink to={`/clan/view/${this.state.clanData?._id}`}>
                            <IoArrowBackCircleOutline className="back-btn" />
                        </NavLink>

                        <div className="row d-flex flex-row position-relative" style={{ alignItems: 'flex-end', marginTop: '-50px' }}>
                            <div className="col-lg-2 col-3">
                                <img
                                    src={this.state.teamData?.logo?.url ? this.state?.teamData?.logo?.url : DefaultImageLoader}
                                    onClick={() => this.previewImage((this.state.teamData?.logo?.url ? this.state?.teamData?.logo?.url : DefaultImageLoader), true)}
                                    alt="clan logo"
                                    className="team-logo rounded-circle border border-1 border-white" />
                            </div>

                            <div className="col-lg-9 col-7">
                                <h6 className="fs-20 fs-lg-24 fw-600 text-white mb-0 ms-4 text-truncate">
                                    <span className='fs-12 fs-lg-16 fw-600 text-white-50'>Welcome to </span> <br />
                                    {this.state?.teamData?.name?.substring(0, 25)}{(this.state?.teamData?.name?.length > 25) ? '...' : null}
                                </h6>
                            </div>
                            <div className='col-lg-1 col-2'>
                                <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32 float-end" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ teamView: 101 }) }}>
                                    <AiOutlineSetting className="btn-size-20" />
                                </button>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-12">
                                
                                <div className="row my-3">
                                    <div className="col-lg-6 offset-lg-3 col-md-12 col-12 text-center">
                                        <h6 className="fw-600 fs-16"><img src={CaptainIcon} className="btn-size-20 pb-1" alt='' /> Team Captain</h6>
                                    </div>
                                    {this.state.teamData?.members?.captain ?
                                        <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 mb-2">
                                            <div className='husk-gradient-card d-flex align-items-center py-2' >
                                                <div className="col-3">
                                                    <img className="rounded-circle btn-size-25 border border-1 d-block m-auto" src={this.state?.teamData?.members?.captain?.profilePicture ? this.state?.teamData?.members?.captain?.profilePicture?.url : DefaultImage} alt="owner-avatar" />
                                                </div>
                                                <div className="col-6">
                                                    <div className="fs-16 fw-500 text-white text-start text-truncate">{this.state.teamData?.members?.captain?.inGameName}</div>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <div className='col-lg-4 offset-lg-4'>
                                            <p disabled={!this.clanData?.isActive} className="black-flat-btn p-2 text-center mb-0 w-100 rounded-10">
                                                <AiFillPlusCircle className='fs-24' onClick={() => { this.toggelModal('openClanInvite'); this.setState({ inviteAs: 'substitutes' }) }} />
                                            </p>
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row mt-4 justify-content-lg-center">
                                    <div className="col-12 d-flex justify-content-between mb-2 align-items-center">
                                        <h6 className="fs-20 fw-600 text-white mb-0">Team Members</h6>
                                        {/* <hr className='mx-4 flex-grow-1 opacity-100' /> */}
                                        {
                                            ((this.state.teamData?.members?.players?.length < (parseInt(this.state.teamData?.gameDetails?.maxPlayersPerTeam))) || !this.state.teamData?.members?.players?.length) && (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&

                                            <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={() => { this.toggelModal('openClanInvite'); this.setState({ inviteAs: 'players' }) }}>
                                                <BiPlusMedical className='text-black' />
                                            </button>
                                        }
                                    </div>
                                    {/* Players */}
                                    {this.state.teamData?.members?.players?.length !== 0 ?
                                        this.state.teamData?.members?.players?.map((el, i) => {
                                            return (
                                                <div className="col-lg-2 col-md-2 col-4 mb-4" key={i}>
                                                    <div className={` ${this.state.teamData?.members?.captain?.id === el?.id ? 'husk-gradient-card' : 'member-card'} showAddMember-body pt-2`}>
                                                        {
                                                            (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                                            <button type="button" className="bg-transparent border-0 position-absolute p-0 text-white d-flex" style={{ marginTop: '-15px', marginLeft: '85px' }} data-dismiss="modal"
                                                                aria-label="Close" onClick={() => { this.onClickOpenConfirm('remove', this.removeMsg('players', el?.inGameName)); this.setRemoveData(el, el?._id, 'players', el?.inGameName) }}>
                                                                <AiFillMinusCircle className="fs-20" />
                                                            </button>
                                                        }

                                                        <img className="btn-size-32 border-1 border-dark rounded-circle my-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="profile img" />
                                                        <div className="fs-10 text-white mt-1 fw-500">{ellipsis(el?.inGameName, 12)}</div>
                                                        {(this.state.teamData?.members?.captain?.id === el?.id) &&
                                                            <div className="fs-10 text-white fw-500 mt-1">
                                                                <span className="text-warning d-flex justify-content-center">Captain
                                                                    {(el?.id === this.state.currentUserId) &&
                                                                        <div className="fs-10 text-white fw-500 ms-1">(you)</div>
                                                                    }
                                                                </span>
                                                            </div>
                                                        }

                                                        {(this.state.teamData?.members?.captain?.id !== el?.id) && (el?.id !== this.state.currentUserId) && <div className="fs-10 text-white mt-1 fw-500">Member
                                                            {(el?.id === this.state.currentUserId) &&
                                                                <div className="fs-10 text-white fw-500 ms-1">(you)</div>
                                                            }
                                                        </div>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='col-lg-2 col-md-2 col-4 mb-4'>
                                            <div className="no-member-card showAddMember-body d-flex align-items-center justify-content-center">
                                                <div className="fs-12 fw-600 text-white-50">No Member</div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            {/* Substitutes */}
                            <div className="col-12">
                                <div className="row justify-content-lg-center">
                                    <div className="col-12 d-flex justify-content-between mb-2 align-items-center">
                                        <h6 className="fs-20 fw-600 text-white mb-0">Substitutes</h6>
                                        {/* <hr className='mx-4 flex-grow-1 opacity-100' /> */}
                                        {
                                            (this.state.teamData.members?.substitutes?.length < 3 || !this.state.teamData.members?.substitutes?.length) && (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&

                                            <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={() => { this.toggelModal('openClanInvite'); this.setState({ inviteAs: 'substitutes' }) }}>
                                                <BiPlusMedical className='text-black' />
                                            </button>
                                        }
                                    </div>

                                    {(this.state.teamData?.members?.substitutes && this.state.teamData?.members?.substitutes?.length !== 0) ?
                                        this.state.teamData?.members?.substitutes?.map((el, i) => {
                                            return (
                                                <div className="col-lg-2 col-md-2 col-4 mb-4" key={i}>
                                                    <div className="substitute-card showAddMember-body pt-2">
                                                        {
                                                            (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                                            <button type="button" className="bg-transparent border-0 position-absolute p-0 text-white d-flex" style={{ marginTop: '-15px', marginLeft: '85px' }} data-dismiss="modal"
                                                                aria-label="Close" onClick={() => { this.onClickOpenConfirm('remove', this.removeMsg('substitutes', el?.inGameName)); this.setRemoveData(el, el?._id, 'substitutes', el?.inGameName) }}>
                                                                <AiFillMinusCircle className="fs-20" />
                                                            </button>
                                                        }
                                                        <img className="btn-size-32 border-1 border-dark rounded-circle my-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="profile img" />
                                                        <div className="fs-10 text-white mt-1 fw-500">{ellipsis(el?.inGameName, 12)}</div>
                                                        <div className="fs-10 text-white mt-1 fw-500">Substitute</div>
                                                    </div>


                                                </div>
                                            )
                                        })
                                        :
                                        <div className='col-lg-2 col-md-2 col-4 mb-4'>
                                            <div className="no-member-card showAddMember-body d-flex align-items-center justify-content-center">
                                                <div className="fs-12 fw-600 text-white-50 p-2">No Substitutes</div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row justify-content-lg-center">
                                    <div className="col-12 d-flex justify-content-between mb-2 align-items-center">
                                        <h6 className="fs-20 fw-600 text-white mb-0">Coach</h6>
                                        {/* <hr className='mx-4 flex-grow-1 opacity-100' /> */}
                                        {
                                            (this.state.teamData?.members?.coach?.length < 3 || !this.state.teamData?.members?.coach?.length) && (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&

                                            <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={() => { this.toggelModal('openClanInvite'); this.setState({ inviteAs: 'coach' }) }}>
                                                <BiPlusMedical className='text-black' />
                                            </button>
                                        }
                                    </div>

                                    {(this.state.teamData?.members?.coach && this.state.teamData?.members?.coach?.length !== 0) ?
                                        this.state.teamData?.members?.coach?.map((el, i) => {
                                            return (
                                                <div className="col-lg-2 col-md-2 col-4 mb-4" key={i}>
                                                    <div className="coach-card showAddMember-body pt-2">
                                                        {
                                                            (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                                            <button type="button" className="bg-transparent border-0 position-absolute p-0 text-white d-flex" style={{ marginTop: '-15px', marginLeft: '85px' }} data-dismiss="modal"
                                                                aria-label="Close" onClick={() => { this.onClickOpenConfirm('remove', this.removeMsg('coach', el?.inGameName)); this.setRemoveData(el, el?._id, 'coach', el?.inGameName) }}>
                                                                <AiFillMinusCircle className="fs-20" />
                                                            </button>
                                                        }

                                                        <img className="btn-size-32 border-1 border-dark rounded-circle my-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="profile img" />
                                                        <div className="fs-10 text-white mt-1 fw-500">{ellipsis(el?.inGameName, 12)}</div>
                                                        <div className="fs-10 text-white mt-1 fw-500"> <span className="text-success">Coach</span></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='col-lg-2 col-md-2 col-4 mb-4'>
                                            <div className="no-member-card showAddMember-body d-flex align-items-center justify-content-center">
                                                <div className="fs-12 fw-600 text-white-50">No Coach</div>
                                            </div>
                                        </div>
                                    }
                                    
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row mb-4 justify-content-lg-center">

                                    <div className="col-12 d-flex justify-content-between mb-2 align-items-center">
                                        <h6 className="fs-20 fw-600 text-white mb-0">Managers</h6>
                                        {/* <hr className='mx-4 flex-grow-1 opacity-100' /> */}
                                        {
                                            (this.state.teamData?.members?.manager?.length < 3 || !this.state.teamData?.members?.manager?.length) && (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&

                                            <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={() => { this.toggelModal('openClanInvite'); this.setState({ inviteAs: 'manager' }) }}>
                                                <BiPlusMedical className='text-black' />
                                            </button>
                                        }
                                    </div>

                                    {(this.state.teamData?.members?.manager && this.state.teamData?.members?.manager?.length !== 0) ?
                                        this.state.teamData?.members?.manager?.map((el, i) => {
                                            return (
                                                <div className="col-lg-2 col-md-2 col-4 mb-4" key={i}>
                                                    <div className="manager-card showAddMember-body pt-2">
                                                        {
                                                            (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                                            <button type="button" className="bg-transparent border-0 position-absolute p-0 text-white d-flex" style={{ marginTop: '-15px', marginLeft: '85px' }} data-dismiss="modal"
                                                                aria-label="Close" onClick={() => { this.onClickOpenConfirm('remove', this.removeMsg('manager', el?.inGameName)); this.setRemoveData(el, el?._id, 'manager', el?.inGameName) }}>
                                                                <AiFillMinusCircle className="fs-20" />
                                                            </button>
                                                        }
                                                        <img className="btn-size-32 border-1 border-dark rounded-circle my-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="profile img" />
                                                        <div className="fs-10 text-white mt-1 fw-500">{ellipsis(el?.inGameName, 12)}</div>
                                                        <div className="fs-10 text-white mt-1 fw-500">Manager</div>
                                                        {/* <div className="member-name">Member(you)</div> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='col-lg-2 col-md-2 col-4 '>
                                            <div className="no-member-card showAddMember-body d-flex align-items-center justify-content-center">
                                                <div className="fs-12 fw-600 text-white-50 p-2">No Manager</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {(this.state.teamView === 101) &&
                    <div className="container setting-section ps-4 pe-4">
                        <IoArrowBackCircleOutline className="back-btn" onClick={() => { this.setState({ teamView: 100 }) }} />

                        <div className="row d-flex flex-row position-relative" style={{ alignItems: 'flex-end', marginTop: '-50px' }}>
                            <div className="col-lg-2 col-3">
                                <img
                                    src={this.state.teamData?.logo?.url ? this.state?.teamData?.logo?.url : DefaultImageLoader}
                                    onClick={() => this.previewImage((this.state.teamData?.logo?.url ? this.state?.teamData?.logo?.url : DefaultImageLoader), true)}
                                    alt="clan logo"
                                    className="team-logo rounded-circle border border-1 border-white" />
                            </div>

                            <div className="col-lg-10 col-9">
                                <h6 className="fs-20 fs-lg-24 fw-600 text-white mb-0 ms-4 text-truncate">
                                    <span className='fs-12 fs-lg-16 fw-600 text-white-50 '>Welcome to </span> <br />
                                    {this.state?.teamData?.name?.substring(0, 25)}{(this.state?.teamData?.name?.length > 25) ? '...' : null}
                                </h6>
                            </div>
                            {/* <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ teamView: 100 }) }}>
                                <AiOutlineSetting className="btn-size-20" />
                            </button> */}
                        </div>

                        <div className="row d-flex justify-content-center pt-5">
                            {
                                this.state.teamMembers.includes(this.state.currentUserTeamPosition) ?
                                    <div className='col-lg-2 col-md-2 col-4 text-center'>
                                        <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={() => { this.onClickOpenConfirm('leave', this.leaveMsg()); }}>
                                            <img src={leaveIcon} style={{ height: '20px' }} alt='' />
                                        </button>
                                        <p className='fs-12 fw-bold text-white mt-2'>Leave Team</p>
                                    </div>
                                    : null
                            }

                            {
                                this.state.teamMembers.includes(this.state.currentUserTeamPosition) ?
                                    // <button className="btn clan-btn clanViewSetting-btn" onClick={() => { this.toggelModal('openAcceptInvite') }}>Edit My Info</button>
                                    null
                                    :
                                    (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                    <div className='col-lg-2 col-md-2 col-4 text-center'>
                                        <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={() => { this.participateInTeam(true); }}>
                                            <img src={participateIcon} style={{ height: '20px' }} alt=''/>
                                        </button>
                                        <p className='fs-12 fw-bold text-white mt-1 mb-2'>Participate</p>
                                    </div>
                            }

                            {
                                (this.state.currentUserTeamPosition === 'players' || this.state.currentUserTeamPosition === 'substitutes') &&
                                <div className='col-lg-2 col-md-2 col-4 text-center'>
                                    <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={() => { this.toggelModal('openAcceptInvite'); }}>
                                        <img src={editInfoIc} style={{ height: '20px' }} alt=''/>
                                    </button>
                                    <p className='fs-12 fw-bold text-white mt-1 mb-2'>Edit My Info</p>
                                </div>
                            }

                            {
                                (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                <div className='col-lg-2 col-md-2 col-4 text-center mt-2'>
                                    <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" onClick={() => { this.onClickOpenConfirm('delete', this.teamDeleteMsg()); }}>
                                        <img src={deleteIcon} style={{ height: '20px' }} alt=''/>
                                    </button>
                                    <p className='fs-12 fw-bold text-white mt-1 mb-2'>Delete Team</p>
                                </div>
                            }

                            {
                                (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                <div className='col-lg-2 col-md-2 col-4 text-center mt-2'>
                                    <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45" disabled={!this.state.teamData?.members?.players?.length && !this.state.teamData?.members?.substitutes?.length}
                                        onClick={() => { this.setState({ teamView: 102 }) }}>
                                        <img src={lineupsIc} style={{ height: '20px' }} alt=''/>
                                    </button>
                                    <p className='fs-12 fw-bold text-white mt-1 mb-2'>Line-up</p>
                                </div>
                            }

                            {
                                (this.state.currentUserClanPosition === 'owner' || this.state.currentUserClanPosition === 'admin') &&
                                <div className='col-lg-2 col-md-2 col-4 text-center mt-2'>
                                    <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45"
                                        onClick={() => { this.toggelModal('openClanInvite'); this.setState({ inviteAs: 'players' }) }}>
                                        <IoPersonAdd className='fs-20' />
                                    </button>
                                    <p className='fs-12 fw-bold text-white mt-1 mb-2'>Invite</p>
                                </div>
                            }
                        </div>

                        <div className="row mt-4 mx-1">
                            <div className='col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12'>
                                <div className='text-center'>
                                    <img src={instructionIcon} className='' height={'40px'} alt='' />
                                </div>
                                <div className='mt-3'>
                                    <ul className='ps-0'>
                                        <li className='fs-12 fs-lg-16 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                        <li className='fs-12 fs-lg-16 fw-500 text-start'>Integer sit amet odio aliquet, rhoncus augue et, semper nibh.</li>
                                        <li className='fs-12 fs-lg-16 fw-500 text-start'>Donec ac tellus vel magna iaculis pretium.</li>
                                        <li className='fs-12 fs-lg-16 fw-500 text-start'>Pellentesque id ligula rhoncus, consectetur lacus id, vulputate mi.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {(this.state.teamView === 102) &&
                    <div className="container reAssign-section ps-4 pe-4">
                        <IoArrowBackCircleOutline className="back-btn" onClick={() => { this.setState({ teamView: 101 }) }} />


                        <h5 className="mt-5 mb-4 fs-16 fs-lg-20 fw-600">Line-ups</h5>

                        <div className='d-flex align-items-center mt-4'>
                            <h6 className="fs-16 fw-600 text-white mb-1">Players</h6>
                            <hr className='mx-2 flex-grow-1 opacity-100' />
                        </div>
                        <div className="row mt-1 mx-1 justify-content-center">
                            {
                                this.state.selectedPlayers.map((el, i) => {
                                    return (
                                        <div className='col-12 col-md-6 col-lg-4 mb-3' key={i}>
                                            <div className='mulberry-purple-card d-flex align-items-center py-2'>
                                                <div className="col-8 fw-600 fs-14 text-white text-truncate">
                                                    <img className="rounded-circle btn-size-32 border border-1 mx-3" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="owner-avatar" />
                                                    {el?.inGameName}
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (this.state.selectedCaptain?._id === el?._id) ?
                                                            <AiFillCopyrightCircle className='btn-size-20' /> :
                                                            <BsCircle className='btn-size-20' onClick={() => { this.makeCaptain(el) }} />
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    <IoIosCloseCircleOutline className="btn-size-20" onClick={() => { this.removeFromPlayers(el, i) }} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                !this.state.selectedPlayers?.length &&
                                <p>Players not selected</p>
                            }
                        </div>

                        <div className='d-flex align-items-center mt-4'>
                            <h6 className="fs-16 fw-600 text-white mb-1">Substitutes</h6>
                            <hr className='mx-2 flex-grow-1 opacity-100' />
                        </div>
                        <div className="row mt-1 mx-1">
                            {
                                this.state.selectedSubstitute.map((el, i) => {
                                    return (
                                        <div className='col-12 col-md-6 col-lg-4 mb-3' key={i}>
                                            <div className="mulberry-purple-card d-flex align-items-center py-2">
                                                <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                                    <img className="rounded-circle btn-size-32 border border-1 mx-3" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="owner-avatar" />
                                                    {el?.inGameName}
                                                </div>
                                                <div className="col-2">
                                                    <IoIosCloseCircleOutline className="btn-size-20" onClick={() => { this.removeFromSubstitute(el, i) }} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                !this.state.selectedSubstitute?.length &&
                                <p>Substitute not selected</p>
                            }
                        </div>
                        <div className="">
                            <button className="white-flat-btn d-block fs-14 fw-600 py-2 px-5 m-auto mt-3 mb-2" onClick={this.teamShuffle}>Save</button>
                        </div>

                        <div className="row mt-4 mx-1">
                            <div className='col-12'>
                                <div className='text-center'>
                                    <img src={instructionIcon} className='' height={'40px'} alt=''/>
                                </div>
                                <div className='mt-3'>
                                    <ul className='ps-0'>
                                        <li className='fs-12 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                        <li className='fs-12 fw-500 text-start'>Integer sit amet odio aliquet, rhoncus augue et, semper nibh.</li>
                                        <li className='fs-12 fw-500 text-start'>Donec ac tellus vel magna iaculis pretium.</li>
                                        <li className='fs-12 fw-500 text-start'>Pellentesque id ligula rhoncus, consectetur lacus id, vulputate mi.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>

                <Modal show={openAddMember} centered dialogClassName="AddMember-modal">
                    <Modal.Body>
                        <AddMember
                            onClick={this.toggelModal} />
                    </Modal.Body>
                </Modal>
                <Modal show={openAssignMember} centered dialogClassName="AssignMember-modal">
                    <Modal.Body>
                        <AssignMember
                            onClick={this.toggelModal} />
                    </Modal.Body>
                </Modal>
                <Modal show={openClanInvite} centered dialogClassName="ClanInvite-modal">
                    <Modal.Body>
                        <ClanInvite
                            onClick={this.toggelModal} inviteType="team" inviteAs={this.state.inviteAs} teamId={this.state.teamId} name={this.state.teamData?.name} />
                    </Modal.Body>
                </Modal>
                <Modal show={openAcceptInvite} centered dialogClassName="accept-invite-modal">
                    <Modal.Body>
                        <AcceptInvite
                            updateLoader={this.props.updateLoader}
                            title="Update Team Profile"
                            close={this.toggelModal}
                            bioChange={this.bioChange}
                            type="update"
                            joinTeamRole={null}
                            info={this.state.inGameInfo}
                            role={this.state.participantRole}
                            isTournamentInvitation={false}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={openShowMember} centered dialogClassName="ShowMember-modal">
                    <Modal.Body>
                        <ShowMember members={this.state.showClanMember}
                            onClickOpenShowMember={this.onClickOpenShowMember} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal confirm={this.state.confirm}
                            onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.confirmed} insteadDeactivate={this.insteadDeactivate} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.participateInTeam} centered dialogClassName="teamView-participate-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => this.participateInTeam(false)} />
                            <p className="fw-bold fs-20 text-center text-white mb-0">Participate</p>
                            <div id="invite-section">
                                <p className="fs-14 fw-600 text-center text-white-50 mt-4 mb-1">
                                    Please select your role in team
                                </p>
                                <Dropdown className="mb-2 select-game-dropdown ">
                                    <Dropdown.Toggle className='dropdown-basic text-start w-100 height-45'>
                                        {capitalize(this.state.participantRole)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu onClick={(e) => this.setState({ participantRole: e.target.name })}>
                                        {
                                            this.state.teamMembers.map((role, i) => {
                                                return (
                                                    <Dropdown.Item key={i} href="#" className='text-white' name={role}>{ellipsis(capitalize(role), 25)}</Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                                <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" type="submit" onClick={this.joinTeam}>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </>

        );
    }
}

export default TeamView;
