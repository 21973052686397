import React, { Component } from "react";
import { profile } from '../../../utils/localstorage.utils';
import socketService from '../../../services/socket/socket.service';
import MatchesApis from '../../../helper/matches.api';
import { errorHandler } from '../../../utils/common.utils';
import CommonApis from '../../../helper/common.api';
import ImagePreview from '../../common/ImagePreview';
import { Modal } from 'react-bootstrap';
import greenPlan from '../../../images/icon/greenPlan-ic.png';
import greenImage from '../../../images/icon/greenImage-ic.png';
import { MdArrowBackIosNew } from 'react-icons/md';

export default class UserChatLobby extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            role: '',
            uploadImages: [],
            previewImageDetails: {
                display: false,
                src: ''
            },
            message: ''
        };

        this.socket = socketService.getSocket();
        this.componentId = `user-chat-lobby-${Date.now()}`;
        this.MatchesApis = new MatchesApis();
        this.commonApi = new CommonApis();
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        this.setupSocketListeners();
        
        if (this.props.match.params.type === 'TEAM') {
            this.checkRoleInMatch();
        } else if (this.props.match.params.type === 'SINGLE') {
            this.getMatchDetails();
        } else {
            this.props.history.push(`/`);
        }
    }

    componentWillUnmount() {
        this.removeSocketListeners();
    }

    setupSocketListeners() {
        socketService.on(`data-sent-${this.props.match.params.matchId}`, (e) => {
            this.setState({ messages: e });
            console.log("eee - ", e);
            if (this.messagesEnd) {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            }
        }, this.componentId);
        
        socketService.on(`get-data-error-${this.props.match.params.matchId}`, (e) => {
            console.error('Error getting chat data:', e);
        }, this.componentId);
        
        socketService.on(`data-saved-${this.props.match.params.matchId}`, (e) => {
            this.getMessages();
        }, this.componentId);
        
        socketService.on(`chat-message-error-${this.props.match.params.matchId}`, (e) => {
            this.setState({ message: '' });
        }, this.componentId);
    }

    removeSocketListeners() {
        socketService.off(`data-sent-${this.props.match.params.matchId}`, this.componentId);
        socketService.off(`get-data-error-${this.props.match.params.matchId}`, this.componentId);
        socketService.off(`data-saved-${this.props.match.params.matchId}`, this.componentId);
        socketService.off(`chat-message-error-${this.props.match.params.matchId}`, this.componentId);
    }

    checkRoleInMatch = () => {
        const data = {
            userId: profile()?.id,
            matchId: this.props.match.params.matchId
        };
        this.props.updateLoader(true);
        this.MatchesApis.isParticipantInMatch(data).then(
            (res) => {
                this.props.updateLoader(false);
                this.getMessages();
                this.setState({ role: res?.data?.result?.role });
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
            }
        );
    }

    getMatchDetails = () => {
        var data = {
            ids: [this.props.match.params.matchId]
        };
        this.props.updateLoader(true);
        this.MatchesApis.getMultipleMatches(data).then(
            (res) => {
                this.props.updateLoader(false);
                this.getMessages();
                var response = res.data['result'][0];
                let players = response.participants.map(el => { return el.id });
                if (players.includes(profile()?.id)) {
                    this.setState({ role: 'Player' });
                }
                console.log("response  - ", response);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        );
    }

    getMessages = () => {
        socketService.emit('get-data', { matchId: this.props.match.params.matchId });
    }

    sendMessage = (e) => {
        e.preventDefault();
        if (!this.state.message.trim()) return;
        
        const messageBody = {
            from: profile()?.id,
            matchId: this.props.match.params.matchId,
            tournamentId: this.props.match.params.id,
            message: this.state.message,
            role: this.state.role,
            messageType: 'TEXT'
        };
        
        this.setState({ message: '' });
        console.log(messageBody);
        socketService.emit('chat-message', messageBody);
    }

    uploadImages = (event) => {
        event.preventDefault();
        console.log("event.target.files?.length - ", event.target.files?.length);
        if (event.target.files?.length && event.target.files?.length < 6) {
            const formData = new FormData();
            for (let i = 0; i < event.target.files?.length; i++) {
                formData.append(i, event.target.files[i]);
            }

            this.props.updateLoader(true);
            this.commonApi.uploadMultipleImg(formData)
                .then((response) => {
                    let uploadImages = [];
                    for (let i = 0; i < response.data.result?.length; i++) {
                        if (i < 6) {
                            uploadImages.push(response.data.result[i]);
                        }
                    }
                    
                    const messageBody = {
                        from: profile()?.id,
                        matchId: this.props.match.params.matchId,
                        tournamentId: this.props.match.params.id,
                        role: this.state.role,
                        messageType: 'IMAGES',
                        images: uploadImages
                    };
                    
                    console.log(JSON.stringify(messageBody));
                    socketService.emit('chat-message', messageBody);
                    this.props.updateLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    this.props.updateLoader(false);
                });
        }
    }

    triggerInputFile = () => this.fileInput.click();

    previewImage = (src, param) => {
        this.setState({
            previewImageDetails: {
                display: param,
                src: src?.url ? src?.url : null
            }
        });
    }

    render() {
        return (
            <div className="body-section tournament-matches overflow-hidden h-100">
                <div className="py-3" style={{ background: '#1C1C1C' }}>
                    <div className='container mt-5'>
                        <div className='position-fixed w-100 d-flex py-3 user-chat-header'>
                            <MdArrowBackIosNew className='mt-1 me-2' onClick={() => this.props.history.goBack()} /> <p className='fs-16 fw-600 text-white mb-0'>Chat Lobby <span className='fs-12 fw-600 text-white-50'>( Time Left: <span className='text-danger'>1 hr 05 min</span> )</span></p>
                        </div>
                        <div className="chatWindow">
                            <div className="chat" id="chatList" style={{ height: `${(window.innerHeight * 80) / 100}px`, overflowY: 'scroll', overflowX: 'auto' }}>
                                {
                                    this.state.messages.map((el, i) => {
                                        return (
                                            <div key={i} className={`${el?.userDetails?._id === profile().id ? 'self li' : 'other li'}`}>
                                                <div className='row'>
                                                    {
                                                        el?.userDetails?._id !== profile().id ?
                                                            <div className='col-2 p-0'>
                                                                <img src={el?.userDetails?.profilePicture?.url} className="rounded-circle btn-size-32 ms-1" alt='' />
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        (el?.messageType?.length && el?.messageType === 'IMAGES') ?
                                                            <div className='col-10' style={{ textAlign: `${(el?.userDetails?._id === profile().id) ? 'right' : 'left'}` }}>
                                                                <div className="msg">
                                                                    <p className="text-end mb-0 fs-12 fw-700"><span>{el?.userDetails?.name}</span> <span>~ {el?.role}</span></p>
                                                                    <div className=''>
                                                                        {
                                                                            el?.images.map((img, j) => {
                                                                                return (
                                                                                    <img key={j} src={img?.url} onClick={() => this.previewImage(img, true)} alt='' className='mx-1 my-2' style={{ width: '162px', height: '162px' }} />
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='col-10'>
                                                                <div className="msg">
                                                                    <p className="text-end"><span>{el?.userDetails?.name}</span> <span>~ {el?.role}</span></p>
                                                                    <div className="message">{el?.message}</div>
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        el?.userDetails?._id === profile().id ?
                                                            <div className='col-2 p-0'>
                                                                <img src={el?.userDetails?.profilePicture?.url} className="rounded-circle btn-size-32 me-2" alt='' />
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='row' style={{ height: '20px' }} ref={(el) => { this.messagesEnd = el; }}></div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-8 offset-lg-2 col-12'>
                                <div className="w-100" style={{ bottom: '55px' }}>
                                    <div className='d-flex'>
                                        <div className='w-100' style={{ zIndex: '1' }}>
                                            <input 
                                                type="text" 
                                                value={this.state.message} 
                                                onChange={(e) => { this.setState({ message: e.target.value }) }} 
                                                onKeyPress={(e) => e.key === 'Enter' && this.sendMessage(e)}
                                                className="grey-card purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14" 
                                                style={{ height: '40px' }} 
                                            />
                                        </div>
                                        <div className='' style={{ zIndex: '1' }}>
                                            <button className='bg-transparent rounded-circle m-auto btn-size-40 border-0' onClick={this.triggerInputFile}><img src={greenImage} className='btn-size-20' alt="Upload" /></button>
                                        </div>
                                        <div className=''>
                                            <button className='black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 d-flex justify-content-center align-items-center ' disabled={!this.state.message.trim()} onClick={this.sendMessage}><img src={greenPlan} className='btn-size-20' alt="Send" /></button>
                                        </div>
                                        <input
                                            name="disputeImages"
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            id="upload_cover_file"
                                            hidden
                                            multiple
                                            ref={fileInput => this.fileInput = fileInput}
                                            onChange={this.uploadImages}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview isDownload={true} history={this.props.history} previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </div>
        )
    }
}