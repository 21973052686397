import React, { Component } from 'react';
import { AiFillCopyrightCircle } from "react-icons/ai";

export default class TournamentEntryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myTeamDetails: props.myTeamDetails || {},
            teamDetails: props.teamDetails,
            tournamentDetails: props.tournamentDetails
        };
    }

    // Use modern lifecycle method instead of the deprecated componentWillReceiveProps
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            myTeamDetails: nextProps.myTeamDetails || {},
            teamDetails: nextProps.teamDetails,
            tournamentDetails: nextProps.tournamentDetails
        };
    }

    getArray = () => {
        const arr = [];
        const { myTeamDetails, tournamentDetails } = this.state;
        
        // Early return if not team type or no members
        if (tournamentDetails?.participantType !== 'TEAM' || !myTeamDetails?.members) {
            return arr;
        }
        
        const { members } = myTeamDetails;
        
        // Add captain if exists
        if (members?.captain) {
            arr.push(members.captain);
        }
        
        // Add players if they exist
        if (members?.players?.length > 0) {
            // Filter out null values
            const validPlayers = members.players.filter(player => player !== null);
            arr.push(...validPlayers);
        }
        
        // Add coaches if they exist
        if (members?.coach?.length > 0) {
            // Filter out null values
            const validCoaches = members.coach.filter(coach => coach !== null);
            arr.push(...validCoaches);
        }
        
        // Add managers if they exist
        if (members?.manager?.length > 0) {
            // Filter out null values
            const validManagers = members.manager.filter(manager => manager !== null);
            arr.push(...validManagers);
        }
        
        return arr;
    }

    renderSingleParticipant() {
        const { myTeamDetails } = this.state;
        return (
            <div className='col-12 col-md-6 col-lg-4 mb-3'>
                <div className='mulberry-purple-card d-flex align-items-center py-2'>
                    <div className="col-10 fw-600 fs-14 text-white text-truncate">
                        <img 
                            className="rounded-circle btn-size-32 border border-1 mx-3" 
                            src={myTeamDetails?.profilePicture?.url} 
                            alt="owner-avatar" 
                        />
                        {myTeamDetails?.inGameName}
                    </div>
                </div>
            </div>
        );
    }

    renderTeamParticipants() {
        const { myTeamDetails } = this.state;
        const members = myTeamDetails?.members || {};

        return (
            <div>
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    {members?.captain && (
                        <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2'>
                            <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                <img 
                                    className="rounded-circle btn-size-32 border border-1 mx-3" 
                                    src={members.captain?.profilePicture?.url} 
                                    alt="owner-avatar" 
                                />
                                {members.captain?.inGameName}
                            </div>
                            <div className="col-2">
                                <AiFillCopyrightCircle className='btn-size-20' />
                            </div>
                        </div>
                    )}

                    {members?.players?.length > 0 && 
                        members.players.map((player, i) => {
                            if (player === null) return null;
                            return (
                                <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2' key={i}>
                                    <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                        <img 
                                            className="rounded-circle btn-size-32 border border-1 mx-3" 
                                            src={player.profilePicture?.url} 
                                            alt="owner-avatar" 
                                        />
                                        {player?.inGameName}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                
                <div className='d-flex align-items-center mt-3'>
                    <h6 className="fs-14 fs-lg-18 fw-600 text-white mb-1">COACH & MANAGERS</h6>
                    <hr className='mx-2 flex-grow-1 opacity-100' />
                </div>
                
                <div className="row mt-1">
                    {members?.coach?.length > 0 && 
                        members.coach.map((coach, i) => {
                            if (coach === null) return null;
                            return (
                                <div className='col-12 col-md-6 col-lg-4 mb-2' key={i}>
                                    <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2'>
                                        <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                            <img 
                                                className="rounded-circle btn-size-32 border border-1 mx-3" 
                                                src={coach.profilePicture?.url} 
                                                alt="owner-avatar" 
                                            />
                                            {coach?.inGameName}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    
                    {members?.manager?.length > 0 && 
                        members.manager.map((manager, i) => {
                            if (manager === null) return null;
                            return (
                                <div className='col-12 col-md-6 col-lg-4 mb-2' key={i}>
                                    <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2'>
                                        <div className="col-10 fw-600 fs-14 text-white text-truncate">
                                            <img 
                                                className="rounded-circle btn-size-32 border border-1 mx-3" 
                                                src={manager.profilePicture?.url} 
                                                alt="owner-avatar" 
                                            />
                                            {manager?.inGameName}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    renderFormField(label, value) {
        return (
            <div className="my-1">
                <label className="fs-14 fw-600 text-white mb-1">{label}</label>
                <input 
                    disabled 
                    type="text"
                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                    value={value || ''}
                />
            </div>
        );
    }

    renderParticipantForm(member) {
        return (
            <div className="participant-form border rounded-10 m-3 pt-4 px-3">
                <div className="d-flex justify-content-center">
                    <img 
                        src={member?.profilePicture?.url} 
                        className="rounded-circle position-absolute btn-size-45" 
                        style={{ marginTop: "-47px" }} 
                        alt=""
                    />
                    <h4 className="mt-3">{member?.inGameName}</h4>
                </div>
                <form className="px-3 pb-3">
                    {this.renderFormField("Real Name", member?.name)}
                    {this.renderFormField("Email address", member?.email)}
                    {this.renderFormField("In-Game Name", member?.inGameName)}
                    {this.renderFormField("In-Game ID", member?.inGameId)}
                    {this.renderFormField("Mobile Number", member?.mobileNo)}
                    {this.renderFormField("Discord ID", member?.inDiscordId)}
                    {this.renderFormField("Custom Detail", null)}
                </form>
            </div>
        );
    }

    renderSingleFormDetails() {
        const { myTeamDetails } = this.state;
        return (
            <div className="col-lg-4 col-md-6 col-12 mb-5">
                {this.renderParticipantForm(myTeamDetails)}
            </div>
        );
    }

    renderTeamFormDetails() {
        const membersArray = this.getArray();
        
        return (
            <div>
                {membersArray.length > 0 &&
                    membersArray.map((member, i) => (
                        <div className="col-lg-4 col-md-6 col-12 mb-5" key={i}>
                            {this.renderParticipantForm(member)}
                        </div>
                    ))
                }
            </div>
        );
    }

    render() {
        const { teamDetails, tournamentDetails } = this.state;
        const isSingleParticipant = tournamentDetails?.participantType === 'SINGLE';

        return (
            <div>
                {teamDetails === 'rooster' && (
                    <div className='col-12'>
                        <div className="reAssign-section">
                            <h5 className="mt-3 mb-4 fs-16 fs-lg-20 fw-600">Team Line-up</h5>
                            <div className='d-flex align-items-center mt-3'>
                                <h6 className="fs-14 fs-lg-18 fw-600 text-white mb-1">PLAYERS</h6>
                                <hr className='mx-2 flex-grow-1 opacity-100' />
                            </div>
                            <div className="row mt-1">
                                {isSingleParticipant 
                                    ? this.renderSingleParticipant() 
                                    : this.renderTeamParticipants()
                                }
                            </div>
                        </div>
                    </div>
                )}

                {teamDetails === 'formDetails' && (
                    <div className="row mt-1">
                        {isSingleParticipant 
                            ? this.renderSingleFormDetails() 
                            : this.renderTeamFormDetails()
                        }
                    </div>
                )}
            </div>
        );
    }
}