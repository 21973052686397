import React, { Component } from "react";
import '../../../styles/Tournaments.css';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

//images, icons
import { FaEye } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import publish from '../../../images/games/publish.png';
import publish1 from '../../../images/games/publish1.png';
import TournamentHeader from './common/TournamentHeader';
import ConfirmationModal from '../../popup/ConfirmationModal';
import { Modal } from "react-bootstrap";
import { errorHandler, getTermsAndConditionsByType, getTournamentData } from '../../../utils/common.utils';
import { AxiosResponse, AxiosError } from "axios";
import TournamentApis from '../../../helper/tournament.api';
import TermsConditions from '../../popup/TermsConditions';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import { TERMS_AND_CONDITIONS_TYPES, TournamentStatus } from '../../../utils/enums.utils';

class PublishTournament extends Component {
    tournamentApis = new TournamentApis()
    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : null,
            confirm: null,
            toggleConfirm: false,
            openTermsModal: false,
            acceptCheck: false,
        }
        this.getTermsData = getTermsAndConditionsByType(TERMS_AND_CONDITIONS_TYPES.TOURNAMENT_PUBLISHING);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            })
            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    goLiveMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center error-msg">Are you sure you want to make this tournament live?</p>
        )
    }
    isPublished = (msg, key) => {
        if (!this.state.acceptCheck) {
            toast.error("Please accept terms and conditions");
        } else if (!this.state.tournamentDetails?.entryFeeAndPrizeDetails?.listingType) {
            toast.error("Please enter fees and prize details");
        } else if (!this.state.tournamentDetails?.stages?.length) {
            toast.error("Please create stages before going live");
        } else if(!this.state.tournamentDetails?.leaderboardStatsAdded) {
            toast.error("Please finalize final-standings before going live");
        } else {
            const data = {
                msg: msg ? msg : '',
                key: key ? key : ''
            }
            this.setState({
                confirm: data,
                toggleConfirm: !this.state.toggleConfirm
            })
        }

    }
    confirmed = (key) => {
        if (key === 'goLive') {
            let data = {
                tournamentStatus: TournamentStatus.UPCOMING
            }
            this.tournamentApis.updateTournaments(this.props.match.params.id, data).then(
                (res) => {
                    this.props.dispatchData(res.data.result)
                    this.setState({ toggleConfirm: false })
                    this.props.history.push({
                        pathname: `/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview`,
                        state: {
                            orgId: res.data.result.organizationId,
                            tournamentDetails: res.data.result
                        }
                    });
                }
            ).catch(
                (err) => {
                    errorHandler(err, this.props.logout);
                }
            )
        }
    }

    render() {
        //console.log('getTermsData ::', this.getTermsData);
        return (
            <section className="body-section publish-tournament">
                <div className="container h-100 d-flex flex-column p-3 mt-lg-3">
                    {/* Header */}
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={publish} heading="Publish" subHeading={null} history={this.props.history} />


                    {/* Content */}
                    <div className='d-flex flex-column my-4 mx-2'>
                        {/* Basic Details */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                            </div>
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Basic Details <span className='text-danger fs-20'>*</span> </label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/create/${this.state.tournamentDetails?._id}`,
                                    state: {
                                        to: 'Update',
                                    }
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        <span className='stepper ms-3'></span>
                        {/* General Settings */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            {
                                this.state.tournamentDetails?.rules ?

                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                                    :
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle red-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                            }
                            <label className='fw-600 fw-600 flex-grow-1 mb-0 ms-2'>General Settings <span className='text-danger fs-20'>*</span></label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/general-setting`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        <span className='stepper ms-3'></span>
                        {/* Streams */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            {
                                this.state.tournamentDetails?.streams?.length ?
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                                    :
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle red-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                            }
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Streams</label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/streams`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        <span className='stepper ms-3'></span>
                        {/* Registration Form */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                            </div>
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Registration Form <span className='text-danger fs-20'>*</span></label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/registration-form`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        <span className='stepper ms-3'></span>
                        {/* Sponsors Info */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            {
                                this.state.tournamentDetails?.sponsorDetails?.length ?
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                                    :
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle red-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                            }
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Sponsors Info</label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/sponsors`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        <span className='stepper ms-3'></span>
                        {/* Entry Fee & Prize Details */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            {
                                this.state.tournamentDetails?.entryFeeAndPrizeDetails?.listingType ?
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                                    :
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle red-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                            }
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Entry Fee & Prize Details <span className='text-danger fs-20'>*</span></label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/entry-fee-prize-details`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        <span className='stepper ms-3'></span>
                        {/* Structure */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            {
                                this.state.tournamentDetails?.stages?.length ?
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                                    :
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle red-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                            }
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Structure <span className='text-danger fs-20'>*</span></label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/structure`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>

                        <span className='stepper ms-3'></span>
                        {/* Structure */}
                        <div className='d-flex align-items-center w-100 my-0'>
                            {
                                this.state.tournamentDetails?.leaderboardStatsAdded ?
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle green-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                                    :
                                    <div className='btn-size-32 rounded-circle border border-1 border-white'>
                                        <div className='btn-size-25 rounded-circle red-signal me-3' style={{ margin: '2px' }}></div>
                                    </div>
                            }
                            <label className='fw-600 flex-grow-1 mb-0 ms-2'>Final Standings <span className='text-danger fs-20'>*</span></label>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32'>
                                <NavLink to={{
                                    pathname: `/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.props.match.params.id}/overview/final-standing`
                                }}>
                                    <BsArrowRight className='black-rock fs-14' />
                                </NavLink>
                            </span>
                        </div>
                        
                    </div>
                    <div className="mt-4">
                        <div className='d-flex justify-content-center align-items-center w-100 my-2' onClick={() => { this.setState({ openTermsModal: true }) }}>
                            <input type="checkbox" className='me-2' id="dialog" />
                            <label htmlFor="dialog" className='fs-14 fw-500 mb-0'>I accept the terms of service</label>
                        </div>
                        <div className='d-flex justify-content-center pb-5'>
                            <button className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-4' onClick={() => this.props.history.goBack()}>
                                <FaEye className='btn-size-20' style={{ color: '240f4b' }} />
                            </button>
                            <button className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-4' onClick={() => { this.isPublished(this.goLiveMsg(), 'goLive') }}>
                                <img src={publish1} alt='' />
                            </button>
                        </div>
                    </div>
                    {/* Footer */}



                </div>

                <Modal show={this.state.toggleConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal confirm={this.state.confirm}
                            onClickOpenConfirm={() => { this.setState({ toggleConfirm: false }) }} confirmed={this.confirmed}
                        />
                    </Modal.Body>
                </Modal>
                <Modal centered show={this.state.openTermsModal} dialogClassName="social-media-modal">
                    <Modal.Body>
                        <TermsConditions accept={() => { this.setState({ openTermsModal: false, acceptCheck: true }) }} onClose={() => { this.setState({ openTermsModal: false, acceptCheck: false }) }} terms={this.getTermsData?.rule} />
                    </Modal.Body>
                </Modal>
            </section>
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(PublishTournament)