import { toast } from 'react-toastify';
import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { AiFillDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { checkDenStatus, choosePollOption } from '../../../../utils/Den.utils';

export default class DenPoll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            post: props.post,
            denProfile: props.denProfile
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            post: newProps.post,
            denProfile: newProps.denProfile
        })
    }

    choosePollOption = (denId, postId, index, postDetails) =>{
        if(checkDenStatus(this.state.denProfile)){
            choosePollOption( denId ,postId,index,this.props.updatePostDetails, this.props.enableEdit)
        }
    }

    render() {

    var allVoters = [];
    
    this.state.post.assets.forEach(option => { 
        allVoters = allVoters.concat(option.choosed);
    });

    return (
      <div className='poll-bg w-100 px-2 py-4'>
        {/* Add the props condition here */}
        {this.props.enableEdit &&
            <div className={`${true ? 'd-flex' : 'd-none'} my-0`}>
                <FiEdit className='cursor-pointer ms-auto' onClick={()=>this.props.onClicEditPoll()} />
                <AiFillDelete className='cursor-pointer mx-2' onClick={()=>this.props.updateState('pollData',null)} />
            </div>
        }
        <p className='fs-16 fw-700'>{this.state.post?.pollDescription}</p>
        <div className='d-flex'>
            <span className='fs-14 fw-500'>Poll</span>
            {allVoters.includes(this.state.denProfile?._id) && <span className='fs-14 fw-500 ms-2'>{`(${allVoters.length} votes)`}</span>}
        </div>
        <div className='d-flex flex-column'>
            {this.state.post.assets.map((option,i)=>{
                
                const percentage = (option?.choosed?.length/allVoters?.length * 100).toFixed(2);
                const isChoosedOption = option?.choosed?.includes(this.state.denProfile?._id);
                if(allVoters?.includes(this.state.denProfile?._id)){
                    
                    return( 
                        <div className='d-flex flex-column px-2' key={i}>
                            <p className='fs-14 fw-bold my-0 py-1'>{option.answer} ( {percentage}% ) </p>
                            <ProgressBar variant={isChoosedOption ? 'info':'warning'} className='w-100' now={percentage} />
                        </div>
                    )
               }else{
                    return( 
                        <div className='d-flex flex-column px-2' key={i} onClick={()=> this.props.unRegisterDenProfile ? toast.warning('Please complete your den porfile to perform this action') : this.choosePollOption( this.state.denProfile?._id ,this.state.post._id,i,this.state.post)}>
                            <p className='fs-14 fw-bold my-0  py-1'><IoMdRadioButtonOff className='me-2'/>{option.answer}</p>
                            <hr className='hr-line w-100 my-1' />
                        </div>
                    )
                }
            })}

        </div>
      </div>
    )
  }
}
