import React, { Component } from 'react';
import betPoolIc from '../../../images/icon/betPool-ic.png';
import betPeopleIc from '../../../images/icon/betPeople-ic.png';
import '../../../styles/betting.css';
import betCreatedIc from '../../../images/icon/betCreated-ic.png';
import { ASSETS, ENUMS } from '../../../utils/Strings.utils';
import { TiArrowUnsorted, TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import UpcomingBetsCard from '../common/UpcomingBetsCard';
import { getUserBetMatches, getUpcomingBets, getTournamentAllBetMatches } from '../../../utils/Betting.utils';
import UpcomingMatchesCard from '../../../components/common/UpcomingMatchesCard';
import { profile } from '../../../utils/localstorage.utils';

export default class BettingZone extends Component {

    constructor(props) {
        super(props)
        this.state = {
            display: ENUMS.BETTING_SECTION.MATCHES,
            matches: null,
            matchDetails:null,
            BettingDetailsView: 'AllBets',
            betConfirmationModal: false,
            tournamentDetails: props.tournamentDetails,
            gameDetails: [],
            myBets: null,
            allBetMatches: null,
            sortSection: null,
            sortType: null,
            isAssending: null      
        }

        getUpcomingBets(props.tournamentDetails?._id,this.setMatchDetails);
        getUserBetMatches(`?userId=${profile()?.id}&tournamentId=${props.tournamentDetails?._id}`,this.setMyBetsDetails);
        getTournamentAllBetMatches(`?tournamentId=${props.tournamentDetails?._id}`,this.setAllBets)
    }

    setAllGamesDetails = (response) =>{
        this.setState({
            gameDetails : response
        });
    }

    setAllBets = (response) => { 
        console.log('setAllBets ::', response);
        this.setState({allBetMatches: response});
    }

    setMyBetsDetails = (response) =>{
        this.setState({
            myBets: response
        })
    }

    setMatchDetails = (response) =>{
        console.log("\n\n\n--setMatchDetails :: ",response)
        this.setState({
            matches: response
        });
    }

    switchToBets = (matchData) => {
        if (this.state.display === ENUMS.BETTING_SECTION.MATCHES) {
            this.setState({
                display: ENUMS.BETTING_SECTION.BETS,
                matchDetails: matchData
            });
            
        } else {
            this.setState({
                display: ENUMS.BETTING_SECTION.MATCHES,
                matchDetails: null
            });
            getUpcomingBets(this.props.tournamentDetails?._id,this.setMatchDetails);
        }

    }

    onSort = (sortSection, sortType, matches ) =>{
        if(matches){
            var isAssending = this.state.isAssending ?  !this.state.isAssending : true;
            if(sortSection === 'All'){
                if(sortType === 'EC'){
                    isAssending ? matches.sort((a, b) => Number(a.cumulativePool) - Number(b.cumulativePool)) : matches.sort((a, b) => Number(b.cumulativePool) - Number(a.cumulativePool))
                }else{
                    isAssending ? matches.sort((a, b) => new Date(a.matchSchedule) - new Date(b.matchSchedule)) :   matches.sort((a, b) => new Date(b.matchSchedule) - new Date(a.matchSchedule))
                }
                this.setState({
                    sortSection: sortSection,
                    sortType: sortType,
                    isAssending: isAssending,
                    allBetMatches: matches
                });
            }else{
                if(sortType === 'EC'){
                    isAssending ? matches.sort((a, b) => Number(a.cumulativePool) - Number(b.cumulativePool)) : matches.sort((a, b) => Number(b.cumulativePool) - Number(a.cumulativePool))
                }else{
                    isAssending ? matches.sort((a, b) => new Date(a.matchSchedule) - new Date(b.matchSchedule)) :   matches.sort((a, b) => new Date(b.matchSchedule) - new Date(a.matchSchedule))
                }
                this.setState({
                    sortSection: sortSection,
                    sortType: sortType,
                    isAssending: isAssending,
                    matches: matches
                });
            }

        }

    }



    getMatchesDetails = () => {
        console.log("matches-------",this.state.matches)
        return (
            <div className='container'>
                {/* Head Section */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='dark-gray-card border-0 text-center p-4'>
                            <div className='mb-2'>
                                <img src={betPoolIc} width='40' height='40' alt='' />
                            </div>
                            <div className=''>
                                <p className='fs-14 fw-700 text-white'>STAKING POOL <span className='fs-18 fw-700 text-warning ms-2'>{this.state.tournamentDetails.cumulativePool} EC </span></p>
                            </div>
                            <div className='d-flex align-items-center justify-content-center'>
                                <img src={betPeopleIc} width='20' height='20' alt='' />
                                <p className='fs-14 fw-700 text-white ms-2 mb-0'>{this.state.tournamentDetails.noOfBetters} STAKERS</p>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Upcoming Bets */}
                <div className='row mt-3'>
                    <div className='col-6'>
                        <div className='d-flex align-items-center ms-2'>
                            <img src={betCreatedIc} className='btn-size-20' alt='' />
                            <p className='fs-16 fw-700 text-white ms-2 mb-0'>Upcoming Stakes</p>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='d-flex align-items-center justify-content-evenly'>
                            <p className='fs-14 fw-700 text-white-50 my-0' onClick={()=>this.onSort('Upcoming', 'EC', this.state.matches)}>{(this.state.sortSection === 'Upcoming' && this.state.sortType === 'EC') ? (this.state.isAssending ? <TiArrowSortedDown /> : <TiArrowSortedUp />): <TiArrowUnsorted />}EC</p>
                            <p className='fs-14 fw-700 text-white-50 my-0' onClick={()=>this.onSort('Upcoming', 'Time', this.state.matches)}>{(this.state.sortSection === 'Upcoming' && this.state.sortType === 'Time') ? (this.state.isAssending ? <TiArrowSortedDown /> : <TiArrowSortedUp />): <TiArrowUnsorted />}Time</p>
                        </div>
                    </div>
                    {/* List of Matches */}
                    {this.state.matches?.length ? 
                        this.state.matches.map((betDetails,i)=>{
                            const tournamentDetails = betDetails.tournament;
                            if(tournamentDetails && betDetails){
                                return <UpcomingMatchesCard 
                                key={i} 
                                matchesDetails={[betDetails]} 
                                switchToBets={this.switchToBets} 
                                tournamentDetails={tournamentDetails}
                                showDateMonthYear={false} 
                                />
                            }

                        })
                        :
                        <div className="text-center">
                            <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                        </div>
                    }
                </div>

                {/* User's Bet Matches */}
                <div className='row mt-3'>
                    <div className='col-6'>
                        <div className='d-flex align-items-center ms-2'>
                            <img src={betCreatedIc} className='btn-size-20' alt='' />
                            <p className='fs-16 fw-700 text-white ms-2 mb-0'>All Stake Matches</p>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='d-flex align-items-center justify-content-evenly'>
                            <p className='fs-14 fw-700 text-white-50' onClick={()=>this.onSort('All', 'EC', this.state.allBetMatches)} >{(this.state.sortSection === 'All' && this.state.sortType === 'EC') ? (this.state.isAssending ? <TiArrowSortedDown /> : <TiArrowSortedUp />): <TiArrowUnsorted />} EC</p>
                            <p className='fs-14 fw-700 text-white-50' onClick={()=>this.onSort('All', 'Time', this.state.allBetMatches)} >{(this.state.sortSection === 'All' && this.state.sortType === 'Time') ? (this.state.isAssending ? <TiArrowSortedDown /> : <TiArrowSortedUp />): <TiArrowUnsorted />}Time</p>
                        </div>
                    </div>

                    {this.state.allBetMatches?.length ?
                        this.state.allBetMatches.map((betDetails,i)=>{
                            const tournamentDetails = betDetails.tournament;
                            if(tournamentDetails && betDetails){
                                return <UpcomingMatchesCard 
                                key={i} 
                                matchesDetails={[betDetails]} 
                                switchToBets={this.switchToBets} 
                                tournamentDetails={tournamentDetails}
                                showDateMonthYear={true}
                                />
                            }

                        })
                        :
                        <div className="text-center">
                            <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                        </div>
                    }
                </div>
            </div>
        );
    }
    
    render() {
        return (
            <section className='user_betting-zone'>

                {this.state.display === ENUMS.BETTING_SECTION.MATCHES ?
                    this.getMatchesDetails()
                    :
                    <UpcomingBetsCard matchDetails={this.state.matchDetails} tournamentDetails={this.state.tournamentDetails} switchToBets={this.switchToBets}/>
                }
            </section>
        )
    }
}
