import React, { Component } from "react";
import "../../styles/Organization.css";
import orgCard from "../../images/image74.jpg";
import orgLogo from "../../images/image75.png";
import { NavLink } from "react-router-dom";
import OrganizationApis from "../../helper/organization.api";
import { AxiosResponse, AxiosError } from "axios";
import { ellipsis, errorHandler, getWebData } from "../../utils/common.utils";
import MatchesApis from "../../helper/matches.api";
import TeamApis from "../../helper/teams.api";
import { profile } from "../../utils/localstorage.utils";
import { BsPlusLg } from "react-icons/bs";
import bioIcon from "../../images/icon/bio-icon.png";
import welcomeBackground from "../../images/organization-welcome-background.png";
import { Modal } from "react-bootstrap";
import ConfirmationModal from "../popup/ConfirmationModal";
import { WEBSITE_ENUM } from "../../utils/enums.utils";
import { toast } from "react-toastify";

class Organization extends Component {
  organizationApi = new OrganizationApis();
  authUser = profile();
  constructor() {
    super();
    this.state = {
      organizations: [],
      orgDetailsFetched: false,
      bioLimit: 100,
      discordModal: false,
      confirm: null,
    };

    this.matchesApis = new MatchesApis();
    this.teamApi = new TeamApis();
  }
  componentDidMount() {
    const data = {
      msg: this.discordMsg(),
      key: "",
    };
    this.setState({ confirm: data });
    this.props.showOrHideNav(true);
    this.getData();
  }

  setWebsiteData = (res) => {
    console.log("website response", res);
    this.setState({ websiteData: res[0] });
  };

  getData() {
    this.props.updateLoader(true);
    this.organizationApi
      .getOrganizations()
      .then((res) => {
        var data = res.data["result"];
        this.setState({
          organizations: data,
        });
        this.setState({ orgDetailsFetched: true });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.setState({ orgDetailsFetched: true });
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      });
  }

  createTournament = () => {
    if (profile()?.block) {
      toast.warning(
        "You cannot perform this action as you are blocked by Espotz Admin"
      );
      return false;
    }
    if (profile()?.discordProfile?.discordUsername) {
      this.props.history.push("/organization/create");
    } else {
      this.setState({ discordModal: true });
    }
    // this.props.history.push("/organization/create");
  };
  discordMsg = () => {
    return (
      <p className="fs-14 fw-600 text-center text-white">
        You haven't connected to discord,click yes to connect
      </p>
    );
  };

  checkOrgStatus = (el) => {
    if (el.block) {
      toast.warning("This organization is currently blocked by Espotz admin");
      return false;
    }
    this.props.history.push(`/organization/view/${el._id}`);
  };

  getOrganizationsList = (organizations) => {
    const requiredObject = getWebData();

    if (this.state.orgDetailsFetched) {
      // When no organization available
      if (this.state.organizations?.length === 0) {
        return (
          <div>
            <img
              src={welcomeBackground}
              alt="..."
              className="get-started-background"
            />
            <div
              className="position-absolute w-100 text-center"
              style={{ left: "0px", bottom: "10px" }}
            >
              <div className="row mb-md-5">
                <div className="col-12 col-lg-6 text-lg-end pe-lg-5">
                  <h1 className="fs-24 fs-lg-34 fw-bold text-white">
                    WELCOME TO
                  </h1>
                  <h1 className="fs-20 fs-lg-34 fw-800 text-white mb-4">
                    ORGANIZATIONS
                  </h1>
                </div>
                <div className="col-12 col-lg-6 text-lg-start ps-lg-5">
                  {this.authUser.isEmailVerified ? (
                    <div className="">
                      <button
                        type="button"
                        className="sky-blue-flat-btn text-white w-50 fw-bold p-2 p-lg-3 px-md-4 px-lg-5 m-auto mb-2"
                        style={{ maxWidth: "fit-content" }}
                        onClick={this.createTournament}
                      >
                        Get Started
                      </button>
                    </div>
                  ) : (
                    <div className="">
                      <button
                        type="button"
                        className="sky-blue-flat-btn text-white w-50 fw-bold p-2 m-auto mb-2"
                        onClick={() => this.props.openPolicies(true)}
                      >
                        Get Started
                      </button>
                    </div>
                  )}
                  <p className="fs-14 fs-lg-18 fw-bold text-white mb-2">
                    Create Your organization Today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }
      // When One organization available
      else if (this.state.organizations?.length === 1) {
        var organizations = this.state.organizations.map((el, i) => {
          return (
            <div
              className="col-12 col-md-6 col-lg-12 my-2 clanPage_Cards"
              key={i}
              onClick={() => this.checkOrgStatus(el)}
            >
              <div className="cursor-pointer" key={i}>
                <div
                  className={`card organizationCard mb-3 ${
                    el.isOrganizationActive ? "" : "epz-clan-deactivate"
                  }`}
                >
                  <img
                    src={el?.coverImage?.url ? el.coverImage.url : orgCard}
                    alt="..."
                    className="orgCardImage"
                    style={{ height: "130px" }}
                  />
                  {el.isOrganizationActive ? null : (
                    <p className="deactivated">Deactivated</p>
                  )}
                  <div className="row px-3 pb-3 pt-1">
                    <div className="col-4 col-md-4 col-lg-2">
                      <img
                        src={el?.logo?.url ? el.logo.url : orgLogo}
                        className="logo-card position-absolute rounded-15 ms-lg-5"
                        alt=""
                        style={{ top: "90px" }}
                      />
                    </div>
                    <div className="col-8 col-md-8 col-lg-10">
                      <h5 className="fs-20 fs-lg-24 fw-600 text-white ms-1 ms-md-2 ms-lg-4 text-truncate">
                        {el?.name}
                      </h5>
                      <p className="fs-16 fs-lg-20 fw-600 text-white ms-1 ms-md-2 ms-lg-4 text-truncate">
                        {el?.liveTournamentCount} Live Tournaments
                      </p>
                    </div>
                    <div className="col-12 col-lg-11 m-auto">
                      <span className="d-flex align-items-center mb-1">
                        <img
                          src={bioIcon}
                          className="ms-1 mb-1"
                          alt=""
                          style={{ width: "20px" }}
                        />
                        <p className="fs-14 fw-600 ms-2 mb-0 text-white">Bio</p>
                      </span>
                      <p
                        className="bio-ContentSetting text-white rounded-10 fw-500 fs-14"
                        style={{
                          textAlign: "justify",
                          overflowWrap: "break-word",
                        }}
                      >
                        {el?.bio}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        return (
          <div className="row justify-content-center px-3">
            <h1 className="fs-20 fs-lg-24 fw-bold text-white">Organizations</h1>
            <p className="fs-14 fs-lg-18 fw-500 text-white-50">
              {requiredObject?.INFORMATION_TEXT?.CLAN_ORG?.ORG_DESCRIPTION}
            </p>
            {organizations}
            <div className="d-flex justify-content-end">
              {this.authUser.isEmailVerified ? (
                // <NavLink name='' to="/organization/create" className='d-flex justify-content-end'>
                <button
                  type="button"
                  className="white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed"
                  style={{ bottom: "60px" }}
                  onClick={this.createTournament}
                >
                  <BsPlusLg className="text-info" />
                </button>
              ) : (
                // </NavLink>
                <button
                  type="button"
                  className="white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed"
                  style={{ bottom: "60px" }}
                  onClick={() => this.props.openPolicies(true)}
                >
                  <BsPlusLg className="text-info" />
                </button>
              )}
            </div>
          </div>
        );
      }
      // When Two organization available
      else if (this.state.organizations?.length === 2) {
        var organizations = this.state.organizations.map((el, i) => {
          return (
            <div
              className="col-12 col-md-6 col-lg-6 my-2 clanPage_Cards"
              key={i}
              onClick={() => this.checkOrgStatus(el)}
            >
              <div className="cursor-pointer" key={i}>
                <div
                  className={`card organizationCard mb-3 ${
                    el.isOrganizationActive ? "" : "epz-clan-deactivate"
                  }`}
                >
                  <img
                    src={el?.coverImage?.url ? el.coverImage.url : orgCard}
                    alt="..."
                    className="orgCardImage"
                  />
                  {el.isOrganizationActive ? null : (
                    <p className="deactivated">Deactivated</p>
                  )}
                  <div className="row px-3 pb-3 pt-1">
                    <div className="col-4 col-md-4">
                      <img
                        src={el?.logo?.url ? el.logo.url : orgLogo}
                        className="logo-card position-absolute rounded-15"
                        alt=""
                      />
                    </div>
                    <div className="col-8 col-md-8">
                      <h5 className="fs-16 fs-lg-20 fw-600 text-white text-truncate">
                        {el?.name}
                      </h5>
                      <p className="fs-12 fs-lg-16 fw-600 text-white mb-2">
                        {el?.liveTournamentCount} Live Tournaments
                      </p>
                    </div>
                    <div className="col-12 col-lg-11 m-auto">
                      <span className="d-flex align-items-center mb-1">
                        <img
                          src={bioIcon}
                          className="ms-1 mb-1"
                          alt=""
                          style={{ width: "20px" }}
                        />
                        <p className="fs-14 fw-600 ms-2 mb-0 text-white">Bio</p>
                      </span>
                      <p
                        className="bio-ContentSetting fs-14 fw-normal text-white-50 rounded-10 mb-1"
                        style={{
                          textAlign: "justify",
                          overflowWrap: "break-word",
                          height: "110px",
                        }}
                      >
                        {ellipsis(el.bio, 100)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        return (
          <div className="row justify-content-center px-3">
            <h1 className="fs-20 fs-lg-24 fw-bold text-white">Organizations</h1>
            <p className="fs-14 fs-lg-18 fw-500 text-white-50">
              {requiredObject.INFORMATION_TEXT?.CLAN_ORG?.ORG_DESCRIPTION}
            </p>
            {organizations}
            <div className="d-flex justify-content-end">
              {this.authUser.isEmailVerified ? (
                // <NavLink className='d-flex justify-content-end' to="/organization/create">
                <button
                  type="button"
                  className="white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed"
                  style={{ bottom: "60px" }}
                  onClick={this.createTournament}
                >
                  <BsPlusLg className="text-info" />
                </button>
              ) : (
                // </NavLink>
                <button
                  type="button"
                  className="white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed"
                  style={{ bottom: "60px" }}
                  onClick={() => this.props.openPolicies(true)}
                >
                  <BsPlusLg className="text-info" />
                </button>
              )}
            </div>
          </div>
        );
      }
      // When multiple organization available
      else {
        var organizations = this.state.organizations.map((el, i) => {
          return (
            <div
              className="col-12 col-md-6 col-lg-4 my-2 clanPage_Cards"
              key={i}
              onClick={() => this.checkOrgStatus(el)}
            >
              <div className="cursor-pointer" key={i}>
                <div
                  className={`card organizationCard mb-3 ${
                    el.isOrganizationActive ? "" : "epz-clan-deactivate"
                  }`}
                >
                  <img
                    src={el?.coverImage?.url ? el.coverImage.url : orgCard}
                    alt="..."
                    className="orgCardImage"
                  />
                  {el.isOrganizationActive ? null : (
                    <p className="deactivated">Deactivated</p>
                  )}
                  <div className="row px-3 pb-3 pt-1">
                    <div className="col-4 col-md-4">
                      <img
                        src={el?.logo?.url ? el.logo.url : orgLogo}
                        className="logo-card position-absolute rounded-15"
                        alt=""
                      />
                    </div>
                    <div className="col-8 col-md-8">
                      <h5 className="fs-16 fs-lg-20 fw-600 text-white text-truncate">
                        {el?.name}
                      </h5>
                      <p className="fs-12 fs-lg-16 fw-600 text-white mb-2">
                        {el?.liveTournamentCount} Live Tournaments
                      </p>
                    </div>
                    <div className="col-12 col-lg-11 m-auto">
                      <span className="d-flex align-items-center mb-1">
                        <img
                          src={bioIcon}
                          className="ms-1 mb-1"
                          alt=""
                          style={{ width: "20px" }}
                        />
                        <p className="fs-14 fw-600 ms-2 mb-0 text-white">Bio</p>
                      </span>
                      <p
                        className="bio-ContentSetting fs-14 fw-normal text-white-50 rounded-10 mb-1"
                        style={{
                          textAlign: "justify",
                          overflowWrap: "break-word",
                          height: "110px",
                        }}
                      >
                        {ellipsis(el.bio, 100)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
        return (
          <div className="row justify-content-center px-3">
            <h1 className="fs-20 fs-lg-24 fw-bold text-white">Organizations</h1>
            <p className="fs-14 fs-lg-18 fw-500 text-white-50">
              {requiredObject.INFORMATION_TEXT?.CLAN_ORG?.ORG_DESCRIPTION}
            </p>
            {organizations}
            <div className="d-flex justify-content-end">
              {this.authUser.isEmailVerified ? (
                // <NavLink className='d-flex justify-content-end' to="/organization/create">
                <button
                  type="button"
                  className="white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed"
                  style={{ bottom: "60px" }}
                  onClick={this.createTournament}
                >
                  <BsPlusLg className="text-info" />
                </button>
              ) : (
                // </NavLink>
                <button
                  type="button"
                  className="white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed"
                  style={{ bottom: "60px" }}
                  onClick={() => this.props.openPolicies(true)}
                >
                  <BsPlusLg className="text-info" />
                </button>
              )}
            </div>
          </div>
        );
      }
    }
  };

  render() {
    const OrganizationsList = this.getOrganizationsList();
    return (
      <div>
        <section className="body-section organization-page">
          <div className="container p-3 mt-1 mt-lg-5">{OrganizationsList}</div>
        </section>
        <Modal
          show={this.state.discordModal}
          centered
          dialogClassName="accept-invite-modal"
        >
          <Modal.Body>
            <ConfirmationModal
              confirm={this.state.confirm}
              onClickOpenConfirm={() => {
                this.setState({ discordModal: false });
              }}
              confirmed={() => {
                this.props.history.push("/profile");
              }}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Organization;
