import React, { Component } from "react";
// Style
import '../../styles/VerifyChangePassword.css';

export default class VerifyChangePassword extends Component {

    constructor(props){
        super(props);
        this.state={};
    }

    // Go to create new password link
    handleChangePassword = () => {
        this.props.resetAndUpdateFlag(true,'createNewPassword',true);
    }

    render() {
        return (
            <div className="verifyPassword-modal">
                <div className="row">
                    <div className="col-12">
                        <h1 className="fs-20 text-dark fw-bold">
                            Change Your Espotz Account Password
                        </h1>
                        <p className="horizontal-line-1"></p>
                        <p className="fw-bold fs-16 text-white-50 mt-3">Hi User,</p>
                        <p className="fs-16 mt-3 text-dark">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lorem viverra hendrerit amet
                            scelerisque senectus. Ut posuere massa nisi diam.
                        </p>
                        <button className="change-password-button fs-20 fw-500 text-white cursor-pointer border-0 rounded-10" onClick={this.handleChangePassword}>Change Password</button>
                        <p className="question-text fs-12 fw-400 text-start ">
                            Did you receive this email without signing up?
                            <span className="cursor-pointer" style={{color:'blue'}}> Click Here</span>
                        </p>
                        <p className="regards-text fs-16 fw-bold">Regards,</p>
                        <p className="regards-text fs-16 fw-bold mb-2">Team Espotz</p>
                        <p className="horizontal-line-2"></p>

                        <p className="disclaimer-text fs-10">Disclaimer 1</p>
                        <p className="disclaimer-text fs-10">Disclaimer 2</p>
                        <p className="disclaimer-text fs-10">Disclaimer 3</p>
                    </div>
                </div>

            </div>
        )
    }
}