import React from 'react';
import CommonApis from "../helper/common.api";
import NotificationsApi from "../helper/notifications.api"
import TeamApis from "../helper/teams.api";
import TournamentApis from "../helper/tournament.api";
import { dateFormat, errorHandler, getMembersIds, updateLoader } from "./common.utils"
import { ParticipantType } from "./enums.utils";
import { profile } from "./localstorage.utils";
// import notificationSound from '../sound/notification.mp3'
import AdminApis from "../helper/admin.api";

var updateNotification = null;
var notification = null;

const notificationsApi = new NotificationsApi()
const commonApis = new CommonApis();
const tournamentApis = new TournamentApis();
const teamApis = new TeamApis();
const adminApis = new AdminApis();

export const makeNotificationSound = () => {
    // var audio = new Audio(notificationSound);
    // audio.play();
}

export const saveUpdateNotification = (getNotification) => {
    updateNotification = getNotification;
}

export const updateNotificationCount = () => {
    if(updateNotification){
        updateNotification();
    }
}


export const createAndSendNotification = (notificationDetails, sendTo) => {


    var data = {
        data: notificationDetails,
        userIds: sendTo
    };

    updateLoader(true);
    notificationsApi.createAndSendNotification(data).then((res) => {
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}


export const getNotificationBody = (type, request, response) => {

    switch (type) {
        case 'clan':
            return {
                type : "CLAN",
                heading : "Invitation Accepted 🙌",
                message : `${profile().name} has accepted your invitation to join as '${request.role}' of clan '${response.name}'.`,
                link : `/clan/view/${request.clanId}`
            };
        
        case 'team':
            return {
                type : "TEAM",
                heading : "Invitation Accepted 🙌",
                message : `${profile().name} has accepted your invitation to join as '${request.role}' of team '${response.name}'.`,
                link : `/clan/team/view/${request.teamId}`
            };
        
        case 'organization':
            return {
                type : "ORGANIZATION",
                heading : "Invitation Accepted 🙌",
                message : `${profile().name} has accepted your invitation to join as '${request.role}' of organization '${response.name}' .`,
                link : `/organization/view/${request.organizationId}`
            };
        
        default:
        
            break;
    }

}

// To notify clan, team or organization member when user accepts the invitation to join
export const sendNotificationToMembers = (type,request) => {
    updateLoader(true);

    const params = {
        clan : { clanId: request.clanId },
        team : { teamId: request.teamId },
        organization : { orgId: request.organizationId }
    } 
    var param = params[type];
    
    commonApis.getClanOrTeamOrOrgDetails(param).then((res) => {
        var response = res.data['result'];
        var to = getMembersIds(type, response.members)
    
        var data = getNotificationBody(type, request, response)
        if(type === 'team'){
            // var to2 = getMembersIds(type, response.members)
            sendNotificationsToClanOwners({ clanId: response.clanId },data);
         }
        createAndSendNotification(data,to)
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });

}

export const sendNotificationsToClanOwners = (param,data) =>{
    updateLoader(true);
    commonApis.getClanOrTeamOrOrgDetails(param).then((res) => {
        var response = res.data['result'];
        var to = getMembersIds('clan', response.members)
        createAndSendNotification(data,to);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });

}


// To notify user if he has removed from organization by admin or owner
export const removeOwnerOrAdminNotification = (type, source, removedUserId, role) =>{

    var data;

    if(type === 'organization'){
        data = {
            type : "ORGANIZATION",
            heading : "Removed from Organization ❌",
            message : `${profile().name} has removed you from role of ${role} of organization '${source.name}' and you can no longer perform any activity into this organization.`,
            link : null
        };
    }else if(type === 'clan'){
        data = {
            type : "CLAN",
            heading : "Removed from Clan ❌",
            message : `${profile().name} has removed you from role of ${role} of clan '${source.name}' and you can no longer perform any activity into this clan.`,
            link : null
        };
    }else{
        data = {
            type : "TEAM",
            heading : "Removed from Team ❌",
            message : `${profile().name} has removed you from role of ${role} of team '${source.name}' and you can no longer perform any activity into this team.`,
            link : null
        };
    }

    createAndSendNotification(data,[removedUserId]);
}

export const saveInvitationInNotifications = async(type, request, viaNotification) =>{

    if(viaNotification === undefined || viaNotification === null){
        switch (type) {
            case 'team':
                getClanOrTeamOrOrgDetails(request).then((data) => {
                    notification = {
                        type : "TEAM",
                        heading : "Invitation to Join Team 🙏",
                        message : `You have invited to join team '${data?.name}' as ${request.role}. Please click here to accept that invitation.`,
                        link : `/?joinTeamId=${request.teamId}&role=${request.role}&viaNotification=true`,
                        reload: true
                    };
                    createAndSendNotification(notification, [profile().id])
                });
                break;
            case 'clan':
                getClanOrTeamOrOrgDetails(request).then((data) => {
                    notification = {
                        type : "CLAN",
                        heading : "Invitation to Join Clan 🙏",
                        message : `You have invited to join clan '${data?.name}' as ${request.role}. Please click here to accept that invitation.`,
                        link : `/?joinClanId=${request.clanId}&role=${request.role}&viaNotification=true`,
                        reload: true
                    };
                    createAndSendNotification(notification, [profile().id])
                });
                break;
            case 'organization':
                getClanOrTeamOrOrgDetails(request).then((data) => {
                    notification = {
                        type : "ORGANIZATION",
                        heading : "Invitation to Join Organization 🙏",
                        message : `You have invited to join organization '${data?.name}' as ${request.role}. Please click here to accept that invitation.`,
                        link : `/?joinOrgId=${request.orgId}&role=${request.role}&viaNotification=true`,
                        reload: true
                    };
                    createAndSendNotification(notification, [profile().id])
                });
                break;
            default:
                updateLoader(true);
                tournamentApis.getTournamentsById({ _id: request.tournamentId }).then((res) => {
                    notification = {
                        type : "TOURNAMENT",
                        heading : "Invitation to Join Tournament 🙏",
                        message : `You have invited to join tournament '${res.data?.result[0]?.name}'. Please click here to accept that invitation.`,
                        link : `/${request.search}&viaNotification=true`,
                        reload: true
                    };
                    createAndSendNotification(notification, [profile().id])
                    updateLoader(false);
                }).catch((err) => {
                    updateLoader(false);
                    errorHandler(err);
                });
    
                break;
        }
    }

}

export const getClanOrTeamOrOrgDetails = (param) => {
    return new Promise((resolve, reject) => {
        commonApis.getClanOrTeamOrOrgDetails(param).then((res) => {
            updateLoader(false);
            resolve(res.data['result']);
        }).catch((err) => {
            updateLoader(false);
            errorHandler(err);
            reject(null);
        });
    });
}

export const sendTimeChangeNotificationsToParticipants = (tournamentDetails, name, participants, matchSchedule) => {
 
    updateLoader(true);
    teamApis.getParticipantDetails({teamIds : participants}).then((res) => {
        notification = {
            type : "TIME",
            heading : "Match Time Changed 🕑",
            message :  `The timing of match ${name} of Tournament '${tournamentDetails.name}' has been changed by TO ${profile().name} to ${dateFormat(matchSchedule)}.`,
            link : `/tournament/${tournamentDetails._id}`
        };
        createAndSendNotification(notification, res.data['result'])
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });

}

export const sendJoinedTournamentNotificationToMembers = (param, data) => {
    if(param?.joinTournamentDetails?.participantType === ParticipantType.TEAM){

         updateLoader(true);
     
        commonApis.getClanOrTeamOrOrgDetails({teamId : data.participantId }).then((res) => {
            var result = res.data['result'];
            var sendTo = [];
 

            if(result.members.captain?.userId){
                sendTo.push(result.members?.captain?.userId)
            }

            if(result.members.coach?.length > 0 ){
                result.members.coach.forEach((user,i)=>{
                    sendTo.push(user.userId)
                });
            }

            if(result.members.manager?.length > 0 ){
                result.members.manager.forEach((user,i)=>{
                    sendTo.push(user)
                });
            }

            if(result.members.players?.length > 0 ){
                result.members.players.forEach((user,i)=>{
                    sendTo.push(user.userId)
                });
            }

            if(result.members.substitutes?.length > 0 ){
                result.members.substitutes.forEach((user,i)=>{
                    sendTo.push(user.userId)
                });
            }

           
            //var sendTo = getMembersIds('team', result.members)
            notification = {
                type : "TOURNAMENT",
                heading : "Congratulations !",
                message : `Your Team '${result.name}' has been successfully registered for Tournament '${param?.joinTournamentDetails?.name}' get ready to compete for Victory.`,
                link : `/tournament/${param?.joinTournamentDetails?._id}?page=Matches`
            };
            createAndSendNotification(notification, sendTo)

            updateLoader(false);
        }).catch((err) => {
            updateLoader(false);
            errorHandler(err);
        });
      
    }else{
        updateLoader(true);
        notification = {
            type : "TOURNAMENT",
            heading : "Congratulations !",
            message : `You have been successfully registered for Tournament '${param?.joinTournamentDetails?.name}' get ready to compete for Victory.`,
            link : `/tournament/${param?.joinTournamentDetails?._id}?page=Matches`
        };
        createAndSendNotification(notification,[profile().id]);
        updateLoader(false);
    }
}


export const getNotifications = () =>{
    updateLoader(true);
    adminApis.getNotifications().then(
        (res) => {
            updateLoader(false);

    }).catch((err) => {
        errorHandler(err);
        updateLoader(false);
    });
}

