import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//styles and icons
import { BsInfoCircle } from 'react-icons/bs';
import '../../../../styles/Tournaments.css';
import ReactTooltip from 'react-tooltip';
import { COMMISSION_QUESTION_TYPE } from '../../../../utils/enums.utils';

export default class EntryPaidPrizeFixed extends Component {
    constructor(props) {
        super(props)
        this.localObject = props.localObject
        this.state = {
            entryFeeAmount: props?.initialValues?.buyEC ? this.props?.initialValues?.entryFeeAmount : (props.localObject?.entryFeeAmount && props.id == props.localObject?.id) ? props.localObject.entryFeeAmount : '',
            prizePoolAmount: props?.initialValues?.buyEC ? this.props?.initialValues?.prizePoolAmount : (props.localObject?.prizePoolAmount && props.id == props.localObject?.id) ? props.localObject.prizePoolAmount : '',
            espotzCommission: props.commissionValues ? props.commissionValues.questions[COMMISSION_QUESTION_TYPE.REGULAR_FIXED_PC] : 10,
            buyEC: props?.initialValues?.buyEC ? this.props?.initialValues?.buyEC : '',
        }
    }

    componentDidMount() { }

    updatebuyEC = (value) => {
        let { espotzCommission, buyEC } = this.state
        buyEC = (value * espotzCommission) / 100
        this.setState({ buyEC })
    }

    updateDetails = (e, type) => {
        e.preventDefault()
        this.localObject[type] = e.target.value;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        this.setState({ [type]: e.target.value })
        if (type === 'prizePoolAmount') {
            this.updatebuyEC(e.target.value)
        }
    }

    validatePricePool = (val) => {
        let error;
        const buy = (this.state.espotzCommission * val) / 100;
        if (buy > this.props.balance) {
            error = `Your available wallet balance is ${this.props.balance}. Please lower your Prize Pool.`
        }
        return error;
    }

    passDetails = (obj) => {
        this.props.onSubmitData(obj)
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="float" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    render() {
        return (
            <section className="">
                <Formik
                    enableReinitialize
                    initialValues={{
                        entryFeeAmount: this.state?.entryFeeAmount ? this.state.entryFeeAmount : '',
                        prizePoolAmount: this.state?.prizePoolAmount ? this.state.prizePoolAmount : '',
                        espotzCommission: this.state?.espotzCommission ? this.state.espotzCommission : '',
                        buyEC: this.state?.buyEC ? this.state.buyEC : ''
                    }}
                    validationSchema={Yup.object({
                        entryFeeAmount: Yup.number().integer().required('Enter Entry Fee Amount').min(0, 'Cannot be nagative'),
                        prizePoolAmount: Yup.number().integer().required('Enter Prize Pool Amount').min(0, 'Cannot be nagative'),
                        espotzCommission: Yup.string()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.passDetails(values)
                            setSubmitting(false);
                        }, 400);
                    }}>
                    <Form className="row mt-3" autoComplete="off">
                        {/* Entry Fee Amount */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Entry Fee Amount</label>
                            <div className="">
                                <Field
                                    name='entryFeeAmount' type="text"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 fw-500 height-45"
                                    placeholder="Enter in EC"
                                    onKeyUp={(e) => this.updateDetails(e, 'entryFeeAmount')}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="entryFeeAmount" className="error-msg" />
                        </div>
                        {/* Prize Pool */}
                        <div className="col-lg-6 my-2">
                            <label htmlFor='customField' className="fs-14 fw-600 text-white">Prize Pool Amount</label>
                            <div className="">
                                <Field
                                    name='prizePoolAmount' type="number"
                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 fw-500 height-45"
                                    placeholder="Enter in EC"
                                    validate={this.validatePricePool}
                                    onKeyUp={(e) => this.updateDetails(e, 'prizePoolAmount')}
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            {/* <ErrorMessage component="span" name="prizePoolAmount" className="error-msg" /> */}
                        </div>
                        {/* Espotz Commission */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <label className="fs-14 fw-600 text-white">Espotz Commission(in %)</label>
                                <Field
                                    name='espotzCommission' type='number' disabled
                                    className='purple-field d-flex align-items-center w-25 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    placeholder='in %'
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="espotzCommission" className="error-msg" />
                        </div>
                        {/* Buy EC */}
                        <div className='col-lg-6'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <div className="">
                                    <label htmlFor="custom-btn" className="fs-14 fw-600 text-white w-100" style={{ maxWidth: 'max-content' }}> Buy EC </label>
                                    <a href="#" title="Amount of EC required to Deposit to Create Tournament." className="tooltip-info">
                                        <BsInfoCircle className="text-white btn-size-12 ms-1" />
                                    </a>
                                </div>
                                <Field
                                    className='purple-field d-flex align-items-center w-50 border-0 ps-3 text-info fs-14 fw-500 height-45'
                                    name='buyEC' type='number' disabled
                                    min={0} step={1} onWheel={(e) => e.target.blur()}
                                />
                            </div>
                            <ErrorMessage component="span" name="prizePoolAmount" className="error-msg" />
                        </div>
                        <div className='col-lg-6'>
                            <button type='submit' className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-4' >
                                Next
                            </button>
                        </div>
                    </Form>
                </Formik>
            </section>
        )
    }
}
