import React, { Component } from 'react';
import betIc from '../../images/icon/bet-ic.png';
import tournamentLogo from '../../images/image75.png';
import betPeopleIc from '../../images/icon/betPeople-ic.png';
import winCup from '../../images/icon/winCup.png';
import maskIc from '../../images/icon/mask-ic.png';
import maskIcSad from '../../images/icon/mask-ic-sad.png';
import winCupGreen from '../../images/icon/winCupGreen-ic.png';
import winCupRed from '../../images/icon/winCupRed-ic.png';
import { defaultPreviewImage, remainingTime } from '../../utils/common.utils';
import { ASSETS } from '../../utils/Strings.utils';
import { getPlayersLogoGrid } from '../../utils/Betting.utils';
import { getDateMonthYear } from '../../utils/dateTime.utils';

export default class PastBetsMatchCards extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props.tournamentDetails,
            matchesDetails: props.matchesDetails
        }
    }

    componentWillReceiveProps(newProps){
        if(newProps.matchesDetails){
            this.setState({
                matchesDetails: newProps.matchesDetails
            })
        }
    }

    jumpToBet = (match) => { 
        if(this.props.redirect){
            const tournamentDetails = match.tournament;
            this.props.history.push(`/tournament/${tournamentDetails?._id}?page=BettingZone`);
        }else{
            return false;
        }
    }


    render() {
        const isTeamGame = (this.state.tournamentDetails.participantType === "TEAM");
        if(this.state.matchesDetails){
            return (
                <div className='col-12 col-md-6 my-3'>
                    {
                        this.state.matchesDetails?.map((match,index)=>{
                            const wonBet = match.userTotalMatchProfitOrLoss > 0;
                            return (
                                <div className='betting-card' key={index} onClick={(e) => this.jumpToBet(match)}>
                                    <div className='pseudo fs-10 fw-600'>
                                        {getDateMonthYear(match?.matchSchedule)}
                                        <span className="before" style={{ background: 'rgb(28, 28, 28)' }}> </span>
                                        <span className="after" style={{ background: 'rgb(28, 28, 28)' }}> </span>
                                    </div>

                                    {this.state.tournamentDetails.competitionType === "DUEL" ?
                                        <div className='row'>
                                            <div className='col-5 text-center'>
                                                <img src={isTeamGame ? match?.participants[0]?.logo?.url : match?.participants[0]?.profilePicture?.url} width='40' height='40' alt='' className='rounded-circle' />
                                                <p className='fs-12 fw-700 text-white mb-0'>{isTeamGame ? match?.participants[0]?.name : match?.participants[0]?.inGameName}</p>
                                            </div>
                                            <div className='col-2 text-center align-self-center'>
                                                <p className='fs-16 fw-700 text-white'>VS</p>
                                            </div>
                                            <div className='col-5 text-center'>
                                                <img src={isTeamGame ? match?.participants[1]?.logo?.url : match?.participants[1]?.profilePicture?.url} width='40' height='40' alt='' className='rounded-circle' />
                                                <p className='fs-12 fw-700 text-white mb-0'>{isTeamGame ? match?.participants[1]?.name : match?.participants[1]?.inGameName}</p>
                                            </div>
                                        </div>
                                        :
                                        <div className='row'>
                                            {getPlayersLogoGrid(match)}
                                        </div>
                                    }

                                    <div className='row my-1'>
                                        <div className='col-5 align-self-center'>
                                            <div className='d-flex ms-3'>
                                                <img src={betIc} width='16' height='16' alt=''/>
                                                <p className='fs-12 fw-700 text-white mb-0 ms-2'>Total: {match?.userBetCount} Stake(s)</p>
                                            </div>
                                        </div>
                                        <div className='col-2 text-center'>
                                            <img src={wonBet ? maskIc : maskIcSad} width='30' height='30' alt=''/>
                                        </div>
                                        <div className='col-5 align-self-center'>
                                            <div className='d-flex float-end me-3'>
                                                <img src={betPeopleIc} width='16' height='16' alt=''/>
                                                <p className='fs-12 fw-700 text-white-50 ms-2 mb-0'>{match?.noOfBetters}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center px-3 mb-1'>
                                        <img src={wonBet ? winCupGreen : winCupRed} width='16' height='16' alt='' />
                                        <p className={`fs-12 fw-700 ms-2 mb-0 ${wonBet ? 'lime-green-text' : 'light-coral-text'}`}>
                                            {wonBet ? 'Win: ' : 'Loss: '}{Math.abs(match?.userTotalMatchProfitOrLoss)} EC
                                        </p>
                                    </div>

                                    <div className='betting_card-footer d-flex align-items-center justify-content-between px-3'>
                                        <p className='fs-12 fw-700 light-yellow my-1'>{this.state.tournamentDetails?.name} <span className='fs-8'>By {match?.organization?.name}</span></p>
                                        {/* <p className='fs-12 fw-700 dodger-blue my-1'>{match?.organization?.name}</p> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            );
        }else{
            return (
                <div className="text-center">
                    <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                </div>
            )
        }
    }
}