import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// assets
import player from '../../../../../../images/player.png'
import score from '../../../../../../images/icon/score.png';
import disputes from '../../../../../../images/icon/dispute-ic.png'
import refresh from '../../../../../../images/icon/refresh-ic.png'
import streams from '../../../../../../images/icon/streaming.png';
import comments from '../../../../../../images/icon/comments.png'
import scanIc from '../../../../../../images/icon/scan-ic.png';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import {MdDoubleArrow} from 'react-icons/md'
// components
import ParticipantDetails from "../../../../../popup/ParticipantDetails";
import TournamentHeader from '../../../common/TournamentHeader';
// services
import MatchesApis from '../../../../../../helper/matches.api';
import BettingApis from '../../../../../../helper/betting.api';
// utils
import { dateFormat, errorHandler } from '../../../../../../utils/common.utils';
import { getBettingStatements, getMatchDetails, getTeamsAndPlayers } from '../../../../../../utils/Betting.utils';
import Switch from "react-switch"
import { setUpdateScore } from '../../../../../../redux/actions/tournametActions';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// modals
import { Button, Modal } from 'react-bootstrap';
// style
import { toast } from 'react-toastify';

const mapStateToProps = (props) => {
    return {
        score: props.updateScore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data) => dispatch(setUpdateScore(data))
    }
}
class MatchesDuelScore extends Component {

    counter = 0;
    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails,
            disableChat: false,
            matchDetails: null,
            openDetailsModal: false,
            detailsModalObj: {},
            isBettingEnabled: false,//true,
            openKycVerification: false,
            bettingStatements: [],
            selectedBettingStatements: [],
            payableAmount: 0,
            publishedTournamentCount: 0,
            totalPublishedBettingPool: 0,
            players: [],
            teamMVP: false,
            matchMVP: false
        }

        this.matchesApis = new MatchesApis();
        this.bettingApis = new BettingApis();
        
        getMatchDetails(this.props.matchId,this.setSelectedBettingStatementsDetails)
        getBettingStatements(this.props.matchId, this.setBettingStatements);
       
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    componentWillReceiveProps(newProps) {
       // this.setState({ tournamentDetails: newProps.tournamentDetails });
    }
    showDetails = (data) => {
        this.setState({
            detailsModalObj: data,
            openDetailsModal: true
        })
    }

    

    setSelectedBettingStatementsDetails = (response) =>{ 
     
        
        try{
            var selectedBettingStatements = [];
            var payableAmount = 0;
            var publishedTournamentCount =0;
            var totalPublishedBettingPool=0;
            response?.matchBettingStatements?.map((statement,i)=>{
                if(!statement.isPaid){
                    selectedBettingStatements.push({
                        _id: statement._id,
                        statement: statement.bettingStatement,
                        options: statement.choices,
                        bettingPool: statement.bettingPool,
                        entryFees: statement.entryFees
                    });
                    payableAmount += statement.bettingPool;
                }else{
                    publishedTournamentCount++;
                    totalPublishedBettingPool += statement.bettingPool;
                }
            });

            var playersDetails = getTeamsAndPlayers(this.state.tournamentDetails.participantType,response.results[0])
           
           
            this.setState({
                matchDetails: response,
                isBettingEnabled: response.isBettingEnabled,
                disableChat: response?.chatEnabled,
                selectedBettingStatements: selectedBettingStatements,
                payableAmount: payableAmount,
                publishedTournamentCount: publishedTournamentCount, 
                totalPublishedBettingPool: totalPublishedBettingPool,
                teamMVP: response.teamMVP ? response.teamMVP : false,
                matchMVP : response.matchMVP ? response.matchMVP : false,
                players: playersDetails[1]? playersDetails[1] : []
            });


            
       
        }catch(err){
             console.log(err)
        }
       
    }


    getPlayers = () => { 
        if(this.state.players.length){
            return false;
        }
        var response = getTeamsAndPlayers(this.state.tournamentDetails?.participantType, this.state.matchDetails?.results);
        this.setState({players: response[1]})
    }

    updateMatch = () => {
        this.props.updateLoader(true)
        let data = {
            chatEnabled: !this.state.disableChat
        }
        this.matchesApis.updateMatchResults(this.props.matchId, data).then(
            (res) => {
                this.setState({ disableChat: res.data['result']?.chatEnabled })
                this.props.updateLoader(false);
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }

    
    updateMatchDetails = () => { 
        let result = { };
        if(!this.state.matchDetails?.resultAdded){
            toast.warning('Update scores of all games.');
            return false;
        }
        if( (this.state.matchDetails.results.length>1) && (this.state.matchMVP === false) ){
            toast.error('Please select match MVP');
            return false;
        }else{
            result['matchMVP'] = this.state.matchMVP;
        }
        if(this.state.tournamentDetails.participantType === 'TEAM' ){
            if((this.state.matchDetails.results.length>1) && (this.state.teamMVP === false) ){
                toast.error('Please select team MVP');
                return false;
            }else{
                result['teamMVP'] = this.state.teamMVP;
            }
        }
        this.props.updateLoader(true);
        this.matchesApis.updateMatchResults(this.props.matchId, result).then(
            (res) => {
                getMatchDetails(this.props.matchId);
                this.props.updateLoader(false);
                this.props.history.goBack();
                toast.success('Match result updated successfully');
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }
    
    getURL = (el) => {
        if (this.state.tournamentDetails?.participantType === 'SINGLE') {
            if(el.profilePicture){
                return el.profilePicture?.url;
            }
            return player;
        } else {
            return el.logo.url;
        }
    }


    setBettingStatements = (bettingStatements) => {
        this.setState({
            bettingStatements: bettingStatements
        });
    }

    checkAndRedirectToBetting = () => {
        const game = this.state.tournamentDetails?.gameDetails;
        if(game.isBettingEnabled || this.state.isBettingEnabled){
            this.props.history.push(`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/match/FFA/${this.props.matchId}/betting`);
        }else{
            toast.error('Staking for this game is currently disabled by Espotz Admin.');
        }
    }

    render() {
        
        const { matchDetails, selectedBettingStatements, players } = this.state;
        const scheduledTime = new Date(matchDetails?.matchSchedule)
        const currentTime = new Date()
        const isUpdateDisabled = currentTime < scheduledTime
        
        return (
            <section className="body-section match-duel-score">
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={score} heading="Match Score" subHeading={null} history={this.props.history} />
                    
                    <button type='button'className='fs-14 fs-lg-18 fw-600 height-45 rounded-10 d-flex align-items-center  justify-content-center outline-0 border-0 text-white my-2 pink-skyblue-gradient-btn w-100' onClick={this.checkAndRedirectToBetting}>
                        Set-up Staking <MdDoubleArrow className='ms-3'/>
                    </button>

                    {this.state.tournamentDetails &&

                        <div>

                            <div className='d-flex justify-content-between my-3'>
                                <h6 className='fs-16 fw-bold'>Show Down</h6>
                                <h6 className='fs-12 fw-bold' style={{ opacity: "0.7" }}>{dateFormat(matchDetails?.matchSchedule)}</h6>
                            </div>

                            {/* Final Result */}
                            {this.state.matchDetails?.results?.length > 1 &&
                                <div className='mulberry-purple-card rounded-10 my-3 p-2' style={{ boxShadow: '-4px -4px 5px rgb(64 67 88 / 25%), 4px 4px 5px rgb(0 0 0 / 25%)', border: '2px solid #0BB5FE'}}>
                                    <h6 className='fs-20 fw-bold text-center my-3'>Final Result</h6>
                                    <div className='row d-flex justify-content-between align-items-center'>
                                        {matchDetails?.participants[0] ?
                                            <div className='col-4'>
                                                <div className='row'>
                                                    <div className='col-12 text-center'>
                                                        <img src={matchDetails?.participants[0] ? this.getURL(matchDetails?.participants[0]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{this.state.tournamentDetails?.participantType === 'SINGLE' ? matchDetails?.participants[0]?.inGameName : matchDetails?.participants[0]?.name}</h6>
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        {matchDetails?.resultAdded ?
                                                        matchDetails?.finalResult[0]?.isWinner ?
                                                            <Button variant='success' className='p-0 w-75'>Winner</Button>
                                                            :
                                                            <Button variant='secondary' className='p-0 w-75'>Winner</Button>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(matchDetails?.participants[0]) }}>
                                                            <p className='fs-12 fw-500 my-1'>View Details</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='col-4'>
                                                <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                                            </div>
                                        }

                                        <div className='col-4'>
                                            <p className='fs-20 text-info fw-bold text-center'>{`${matchDetails?.finalResult[0]?.score} - ${matchDetails?.finalResult[1]?.score}`}</p>
                                            <p className='fs-14 text-info fw-bold text-center my-0'>({`${matchDetails?.finalResult[0]?.noOfGamesWon ? matchDetails?.finalResult[0]?.noOfGamesWon : 0} - ${matchDetails?.finalResult[1]?.noOfGamesWon ? matchDetails?.finalResult[1]?.noOfGamesWon : 0}`})</p>
                                        </div>
                                        {matchDetails?.participants[1] ?
                                            <div className='col-4'>
                                                <div className='row'>
                                                    <div className='col-12 text-center'>
                                                        <img src={matchDetails?.participants[1] ? this.getURL(matchDetails?.participants[1]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{this.state.tournamentDetails?.participantType === 'SINGLE' ? matchDetails?.participants[1]?.inGameName : matchDetails?.participants[1]?.name}</h6>
                                                    </div>
                                                    <div className='col-12 text-center'>
                                                        {matchDetails?.resultAdded ?
                                                        matchDetails?.finalResult[1]?.isWinner ?
                                                            <Button variant='success' className='p-0 w-75'>Winner</Button>
                                                            :
                                                            <Button variant='secondary' className='p-0 w-75'>Winner</Button>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    
                                                    <div className='col-12 text-center'>
                                                        <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(matchDetails?.participants[1]) }}>
                                                            <p className='fs-12 fw-500 my-1'>View Details</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='col-4'>
                                                <h6 className="fs-12 fw-bold my-1 px-3 text-center">{'Empty Slot'}</h6>
                                            </div>
                                        }
                                    </div>
                                    {this.state.tournamentDetails?.participantType === 'TEAM' &&
                                    <div className='d-flex flex-column mb-2'>
                                        <label htmlFor='teamMVP' className="fs-14 fw-600 text-white">Team MVP</label>
                                        <select id='teamMVP' name='teamMVP' value={JSON.stringify(this.state.teamMVP)} onChange={(e)=>this.setState({teamMVP: JSON.parse(e.target.value)})} className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                                            <option value='' >Select player</option>
                                            {/* <option value='Player 1' >Player 1</option>
                                            <option value='Player 2' >Player 2</option>
                                            <option value='Player 3' >Player 3</option> */}
                                            {
                                                this.state.players.map((el, i) => {
                                                    return (
                                                        <option value={JSON.stringify(el)} key={i}>{el?.inGameName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                }

                                <div className='d-flex flex-column mb-2'>
                                    <label htmlFor='matchMVP' className="fs-14 fw-600 text-white">Match MVP</label>
                                    <select id='matchMVP' name='matchMVP' value={JSON.stringify(this.state.matchMVP)} onChange={(e)=>this.setState({matchMVP: JSON.parse(e.target.value)})} className='sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-2 text-white fs-14 height-45'>
                                        <option value='' >Select player</option>
                                        {/* <option value='Player 1' >Player 1</option>
                                        <option value='Player 2' >Player 2</option>
                                        <option value='Player 3' >Player 3</option> */}
                                        {
                                            this.state.players.map((el, i) => {
                                                return (
                                                    <option value={JSON.stringify(el)} key={i}>{el?.inGameName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                </div>
                            }

                            {/* Matches */}
                            {(matchDetails && this.state.tournamentDetails) ?
                                matchDetails.results.map((el, i) => {
                                    return (
                                        <div className='mulberry-purple-card rounded-10 my-3 p-2' style={{ boxShadow: '-4px -4px 5px rgb(64 67 88 / 25%), 4px 4px 5px rgb(0 0 0 / 25%)', border: '2px solid white'}} key={i}>
                                            <h6 className='fs-20 fw-bold text-center my-3'>Game {i + 1}</h6>
                                            <div className='row d-flex justify-content-between align-items-center'>
                                                {matchDetails?.participants[0] ?
                                                    <div className='col-4'>
                                                        <div className='row'>
                                                            <div className='col-12 text-center'>
                                                                <img src={matchDetails?.participants[0] ? this.getURL(matchDetails?.participants[0]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{this.state.tournamentDetails?.participantType === 'SINGLE' ? matchDetails?.participants[0]?.inGameName : matchDetails?.participants[0]?.name}</h6>
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                {this.state.matchDetails?.results[i][0]?.isWinner ?
                                                                    <Button variant='success' className='p-0 w-75'>Winner</Button>
                                                                    :
                                                                    <Button variant='secondary' className='p-0 w-75'>Winner</Button>
                                                                }
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(matchDetails?.participants[0]) }}>
                                                                    <p className='fs-12 fw-500 my-1'>View Details</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-4'>
                                                        <h6 className="fs-12 fw-bold my-1 px-3">{'Empty Slot'}</h6>
                                                    </div>
                                                }

                                                <div className='col-4'>
                                                    <p className='fs-20 text-info fw-bold text-center'>{`${matchDetails?.results[i][0]?.score} - ${matchDetails?.results[i][1]?.score}`}</p>
                                                </div>
                                                {matchDetails?.participants[1] ?
                                                    <div className='col-4'>
                                                        <div className='row'>
                                                            <div className='col-12 text-center'>
                                                                <img src={matchDetails?.participants[1] ? this.getURL(matchDetails?.participants[1]) : player} className='my-2 rounded-circle btn-size-52' alt='' />
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                <h6 className="fs-14 fw-bold mx-1 my-1 text-truncate">{this.state.tournamentDetails?.participantType === 'SINGLE' ? matchDetails?.participants[1]?.inGameName : matchDetails?.participants[1]?.name}</h6>
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                {this.state.matchDetails?.results[i][1]?.isWinner ?
                                                                    <Button variant='success' className='p-0 w-75'>Winner</Button>
                                                                    :
                                                                    <Button variant='secondary' className='p-0 w-75'>Winner</Button>
                                                                }
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                <button className='px-2 py-1 bg-transparent outline-none border border-1 border-white rounded-3 text-white cursor-pointer my-2' onClick={() => { this.showDetails(matchDetails?.participants[1]) }}>
                                                                    <p className='fs-12 fw-500 my-1'>View Details</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='col-4'>
                                                        <h6 className="fs-12 fw-bold my-1 px-3 text-center">{'Empty Slot'}</h6>
                                                    </div>
                                                }
                                            </div>
                                            
                                            <button className='white-flat-btn text-center w-100 rounded-10 height-45 my-3 fw-600 px-3' disabled={isUpdateDisabled} style={{ border: 'none', outline: 'none' }}>
                                                {false ? 'Update Score' :
                                                    <NavLink to={`/organization/${this.state.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/matches/${this.props.type}/${this.props.matchId}/score/update/${i}`} style={{ color: '#0E0334' }}>Update Score</NavLink>
                                                }
                                            </button>
                                        </div>
                                    )
                                })
                                :
                                null
                            }

                            {/* Chat Options */}
                            <div className='row'>
                                <div className='col-lg-6 offset-lg-3 col-12'>
                                    <div className="contact-toggle d-flex align-items-center rounded-10 height-45 my-3">
                                        <p className="fs-16 fw-600 mb-0 flex-grow-1 ps-3">Disable Chat</p>
                                        <Switch checked={this.state.disableChat} checkedIcon={false} uncheckedIcon={false} className="me-2" onChange={this.updateMatch} />
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex justify-content-center'>
                                <div className='position-relative d-flex justify-content-center'>
                                    <div className='text-center'>
                                    <button type='submit' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={this.updateMatchDetails}>
                                        <img src={refresh} className='btn-size-20' alt="" />
                                    </button>
                                    <p className="fs-12 fw-bold text-white mt-1">Update</p>
                                </div>

                                <div className='text-center'>
                                    <button type='button' disabled={this.state.disableChat} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-2' onClick={() => {
                                        this.props.history.push(
                                            {
                                                pathname: `/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/chatLobby/${this.props.matchId}`,
                                                state: {
                                                    orgId: this.props.orgId,
                                                    tournamentDetails: this.props.tournamentDetails
                                                }
                                            }
                                        )
                                    }}>
                                        <img src={comments} alt="" className='btn-size-25' />
                                    </button>
                                    <p className="fs-12 fw-bold text-white mt-1">Chat</p>

                                </div>

                                <div className='text-center'>
                                    <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={() => { this.props.history.push(`/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/dispute-management/${this.props.matchId}`) }}>
                                        <img src={disputes} alt="" className='btn-size-25' />
                                    </button>
                                    <p className="fs-12 fw-bold text-white mt-1">Disputes</p>

                                </div>

                                <div className='text-center'>
                                    <button type='button' className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-45 mx-3' onClick={() => { this.props.history.push(`/organization/${this.state?.tournamentDetails?.organizationId}/tournament/${this.state.tournamentDetails?._id}/overview/streams`) }}>
                                        <img src={streams} alt="" />
                                    </button>
                                    <p className="fs-12 fw-bold text-white mt-1">Stream</p>

                                </div>
                                </div>
                            </div>
                        </div>
                    }

                  
                </div>
                <Modal show={this.state.openDetailsModal} centered dialogClassName="ClanInvite-modal">
                    <Modal.Body>
                        <ParticipantDetails selectedBettingStatements={this.state.selectedBettingStatements} updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails?.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openKycVerification} centered dialogClassName="complete-kyc-modal">
                    <Modal.Body>
                        <div className='p-3 px-md-5 px-lg-5'>
                            <h1 className='fs-20 fw-600 text-white text-center'>KYC Verification</h1>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => this.setState({ openKycVerification: false })} />
                            <p className='fs-14 fw-600 text-white text-center mt-2 mb-4' style={{ textAlign: 'justify' }}>You should complete KYC verification in order to publish staking statements</p>

                            <div className=''>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        aadharName: '',
                                        aadharNumber: '',
                                        panName: '',
                                        panNumber: ''
                                    }}
                                    validationSchema={Yup.object({

                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            setSubmitting(false);
                                        }, 400);
                                    }}>
                                    <Form className="d-flex flex-column p-0 m-0" autoComplete="off">
                                        <div className='d-flex'>
                                            <h1 className='fs-16 fw-700 text-info'>Scan aadhar card</h1>
                                            <img src={scanIc} className='btn-size-20 ms-2' alt='scan-ic' />
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor='aadharName' className="fs-16 fw-600 text-white">Name of aadhar card</label>
                                            <Field
                                                name='aadharName'
                                                type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                                placeholder="Enter aadhar name"
                                            />
                                            <ErrorMessage component="span" name="aadharName" className="error-msg" />
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor='aadharNumber' className="fs-16 fw-600 text-white">Aadhar card number</label>
                                            <Field
                                                name='aadharNumber'
                                                type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                                placeholder="XXXX-XXXX-XXXX"
                                            />
                                            <ErrorMessage component="span" name="aadharNumber" className="error-msg" />
                                        </div>
                                        <div className="d-flex flex-row align-items-center mx-auto my-2" style={{ width: '40%' }}>
                                            <p className="modal-hr-line my-auto"></p>
                                            <p className="fs-16 fw-600 text-white mx-2 my-0">OR</p>
                                            <p className="modal-hr-line my-auto"></p>
                                        </div>
                                        <div className='d-flex'>
                                            <h1 className='fs-16 fw-700 text-info'>Scan pan card</h1>
                                            <img src={scanIc} className='btn-size-20 ms-2' alt='scan-ic' />
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor='panName' className="fs-16 fw-600 text-white">Name of pan card</label>
                                            <Field
                                                name='panName'
                                                type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                                placeholder="Enter pan name"
                                            />
                                            <ErrorMessage component="span" name="panName" className="error-msg" />
                                        </div>
                                        <div className="mt-1">
                                            <label htmlFor='panNumber' className="fs-16 fw-600 text-white">Pan card number</label>
                                            <Field
                                                name='panNumber'
                                                type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                                placeholder="XXXX-XXXX-XXXX"
                                            />
                                            <ErrorMessage component="span" name="panNumber" className="error-msg" />
                                        </div>

                                        <div className="d-flex my-2">
                                            <Field type="checkbox" name='ageLimit' className="mt-1 me-2" />
                                            <p className="text-white fs-14 fw-normal mb-0">I agree to the terms & conditions</p>
                                        </div>
                                        <div className="d-flex my-0 ms-3">
                                            <ErrorMessage component="span" name="ageLimit" className="error-msg" />
                                        </div>
                                        <div className="d-flex my-2">
                                            <Field type="checkbox" name='termsConditions' className="mt-1 me-2" />
                                            <p className="text-white fs-14 fw-normal mb-0">I agree to staking terms & conditions</p>
                                        </div>
                                        <div className="d-flex my-0 ms-3">
                                            <ErrorMessage component="span" name="termsConditions" className="error-msg" />
                                        </div>
                                        <button type='submit' className='white-flat-btn fw-bold p-2 m-auto my-3'>
                                            Submit
                                        </button>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* <Modal show={openKycVerification} centered dialogClassName="kyc-verification-modal">
                    <Modal.Body>
                        <KycVerification openKycVerification={openKycVerification} updateLoader={updateLoader} />
                    </Modal.Body>
                </Modal> */}
            </section>
        );
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(MatchesDuelScore)