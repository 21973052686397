import React, { Component } from 'react';
import winCup from "../../images/icon/winCup.png";

import { toast } from 'react-toastify';
import { errorHandler, getMonthAndDate, showHideMatchInvite, updateLoader } from '../../utils/common.utils';
import { HiUserAdd } from 'react-icons/hi';
import { Button, Modal } from 'react-bootstrap';
import { AiFillPlusCircle, AiOutlineDelete } from 'react-icons/ai';
import { FaMinusCircle } from 'react-icons/fa';
import NoRecordFound from './NoRecordFound';
import { WARNING } from '../../utils/Strings.utils';
import ConfirmationModal from '../popup/ConfirmationModal';
import TournamentApis from '../../helper/tournament.api';
import {getStageDetails} from '../../utils/Tournament.utils';

export default class FlexibleBrackets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stageDetails: props?.stageDetails ? props.stageDetails : null,
            tournamentDetails: props?.tournamentDetails ? props.tournamentDetails : null,
            isSwappingEnabled: props.isSwappingEnabled ? props.isSwappingEnabled : false,
            style: {
                text: props.isTournamentOperator ? 'player-name' : 'fs-12 fw-bold text-black',
                border: props?.isTournamentOperator ? 'border-white' : 'border-warning',
                midLine: props?.isTournamentOperator ? null : 'border border-1 border-warning',
                label: props?.isTournamentOperator ? null : 'bg-black text-warning',
                match: props?.isTournamentOperator ? 'match-tag' : 'match-tag bg-black text-warning',
                background: (props?.isTournamentOperator && !props?.isBracketGroup) ? 'body-section' : null,
                card: props?.isTournamentOperator ? 'pink-skyblue-gradient-btn' : 'green-flat-btn',
                vacant: props?.isTournamentOperator ? 'vacant' : 'my-vacant'
            },
            removeParticipant: {
                msg: <p className="fs-14 fw-600 text-center text-white">{WARNING.REMOVE_PARTICIPANT}</p>,
                key: ''
            },
            toggleRemovePlayerModal: false,
            selectedParticipant: null
        };

        this.checkedBoxCount = 0;
        this.tournamentApis = new TournamentApis();
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            stageDetails: newProps?.stageDetails ? newProps.stageDetails : this.state.stageDetails,
            tournamentDetails: newProps?.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,
            isSwappingEnabled: newProps.isSwappingEnabled ? newProps.isSwappingEnabled : this.state.isSwappingEnabled
        });
    }

    // To handle selected players for swapping
    handleInputChange = (e, matchDetail, i) => {
        this.checkedBoxCount = document.querySelectorAll('input[name="checking"]:checked').length;
        if (this.checkedBoxCount > 2) {
            e.target.checked = false;
            e.preventDefault();
            toast.warning("At a time only two players can be swapped")
        } else {
            this.props.handleInputChange(e, matchDetail, 1, i);
        }
    }

    onClickOpenConfirm = (player) => {
        this.setState({
            toggleRemovePlayerModal: !this.state.toggleRemovePlayerModal,
            selectedParticipant: player ? player : null
        })
    }

    setData = (response) => { 
        this.setState({stageDetails: response})
    }

    removeParticipant = () => {
        updateLoader(true);
        var data = {
            tournamentId: this.state.tournamentDetails?._id,
            participantId: this.state.selectedParticipant?._id
        }

        this.tournamentApis.removeParticipant(data).then(
            (res) => {
                this.onClickOpenConfirm( );
                getStageDetails(this.state.tournamentDetails?._id, this.props.index, this.setData);
                updateLoader(false);
                toast.success("Player has been removed successfully");
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err);
            }
        );
    }

    hideAndShowScoreBoard = (matchDetails, matchNo) =>{
     
        if(!this.state.isTournamentOperator && matchDetails?.resultAdded){
            this.props.hideAndShowScoreBoard(matchDetails, matchNo)
        }
    }

    render() {

        if (this.state.stageDetails) {

            const matches = this.state.stageDetails?.groups[0][1];
            const participantType = (this.state.tournamentDetails?.participantType === 'TEAM' ? true : false);
            const style = this.state.style;
            return (
                <div className="container xxx">
                    <div className="row pt-3 px-3"  >
                        {
                            matches?.map((matchDetail, index) => {
                                if (typeof matchDetail === 'object') {
                                    const dateAndTime = getMonthAndDate(matchDetail.matchSchedule);
                                    var players = [];
                                    var maxParticipantsPerMatch = this.state.stageDetails.maxParticipantsPerMatch;

                                    for (let j = 0; j < maxParticipantsPerMatch; j++) {
                                        var player = matchDetail.participants[j] ? matchDetail.participants[j] : null;

                                        if (matchDetail.resultAdded && player && player !== undefined) {
                                            player.isWinner = matchDetail?.finalResult[j]?.isWinner;
                                        }
                                        players.push(player);

                                    }
                                    const canParticipate = showHideMatchInvite(matchDetail, this.state.tournamentDetails);
                                    const matchNo = `M ${index + 1}`;

                                    return (
                                        <div key={index} className="col-lg-4 col-md-6 col-12" onClick={()=>this.hideAndShowScoreBoard(matchDetail, matchNo)}>

                                            <div style={{ alignItems: 'center', marginLeft: '-5px' }} key={index}
                                                className={` mb-4 fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 ${style.card} h-auto`} >
                                                <span className={`match-tag ${style.label} align-self-center py-1 fs-14`}>Match {(index + 1)}</span>
                                                {(!matchDetail?.resultAdded && this.props.isTournamentOperator) ? <AiOutlineDelete onClick={() => this.props.deleteMatch(matchDetail._id, true)} className='fs-20 d-block ms-auto me-2 bg-black rounded-circle p-1' style={{ marginTop: '-10px' }} /> : null}

                                                <div className="row d-flex justify-content-between align-items-center w-100 px-2 pb-3">

                                                    <div className="col-5">
                                                        {
                                                            players.map((player, i) => {
                                                                const uid = (index + ":" + i);
                                                                const isResultAdded = matchDetail?.resultAdded;
                                                                if (player !== null && typeof player === 'object') {
                                                                    return (
                                                                        <div className="d-flex align-items-center" key={uid}>
                                                                            {this.props.isSwappingEnabled ?
                                                                                <input onChange={(e) => this.handleInputChange(e, matchDetail, i)} disabled={isResultAdded} name='checking' type='checkbox' className='me-2'  id={players[i]._id} />
                                                                                :
                                                                                <div className='d-flex'>
                                                                                    {(!isResultAdded && this.props.isTournamentOperator) && <FaMinusCircle className="me-1" style={{color:'orange'}} onClick={ () => this.onClickOpenConfirm(player) } />}
                                                                                    <img src={participantType ? player?.logo?.url : player?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt='' />
                                                                                </div>
                                                                            }
                                                                            <div className='text-truncate'>
                                                                                <span className={`${style.text} my-1 text-truncate`}>{participantType ? player.name : player.inGameName}</span>
                                                                            </div>
                                                                            {isResultAdded && player.isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt=''/> : null}
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (<span className={`player-name d-block ${style.vacant}`} key={uid}> Vacant</span>)

                                                                }
                                                            })

                                                        }
                                                    </div>
                                                    {/* Invite user OR Register user option on match */}
                                                    {((matchDetail.participants.length !== this.state.stageDetails.maxParticipantsPerMatch) && canParticipate) ?
                                                        <div className='col-2 ps-0'>
                                                            {this.props.isTournamentOperator ?
                                                                <button disabled={(this.props.index !== 0) && ((this.props.participantMatch * (index + 1)) <= this.props.bookedSlots)} onClick={(e) => this.props.openInviteModal(e, matchDetail._id)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                                                    <HiUserAdd className="btn-size-15" />
                                                                </button>
                                                                :
                                                                <button onClick={(e) => this.props.openInviteModal(e, matchDetail, matchDetail._id, `M 1.${index + 1}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                                                    <HiUserAdd className="btn-size-15" />
                                                                </button>
                                                            }


                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {/* Date and time */}
                                                    <div className="col-5 d-grid">
                                                        <span className={`${style.text} text-end `}>{dateAndTime[0]}</span>
                                                        <span className={`${style.text} text-end `}>{dateAndTime[1]}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                }
                            })
                        }
                        {this.props.isTournamentOperator ?
                            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12 black-flat-btn d-flex flex-row align-items-center justify-content-center height-45 rounded-15" onClick={() => { this.setState({ sponsorForUpdate: {} }) }}  >
                                <button className="btn text-white fw-600" type="button" onClick={() => this.props.onClickOpenConfirm(null)} >
                                    <AiFillPlusCircle className="text-white btn-size-25 me-2" />
                                    Add match
                                </button>
                            </div>

                            :
                            null
                        }

                    </div>
                    <Modal show={this.state.toggleRemovePlayerModal} centered dialogClassName="accept-invite-modal">
                        <Modal.Body>
                            <ConfirmationModal confirm={this.state.removeParticipant} onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.removeParticipant} />
                        </Modal.Body>
                    </Modal>
                </div>
            );
        } else {
            <NoRecordFound />
        }
    }
}