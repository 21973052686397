import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
//images
import participantAvtar from "../../../../images/participantAvtar.jpg";
import winnerCrown from "../../../../images/icon/winner-crown.png";
import orgIc from "../../../../images/icon/org-ic.png";
import { ellipsis, scoreCardGameURL } from '../../../../utils/common.utils';
import star from "../../../../images/score/star.png";
import SponsorCards from './SponsorCards';
import CardHeader from './CardHeader';

export default class FortniteCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            matchDetails: props.matchDetails,
            tournamentDetails: props.tournamentDetails
        }
        this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() { }

    componentWillReceiveProps(nextProps) {
        this.setState({
            matchDetails: nextProps.matchDetails,
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getTeamFFAFinalResults = (key) => {
        const tournamentDetails = this.state.tournamentDetails
        let arr = [];
        let sortedArray = [];
        if (key) {
            arr = Object.values(this.state.matchDetails?.finalResult).filter(k => k.teamDetails);
            sortedArray = arr.sort((a, b) => parseInt(b.score) - parseInt(a.score));
        } else {
            arr = Object.values(this.state.matchDetails?.results[0]).filter(k => k.teamDetails);
            sortedArray = arr.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
        }
        return (
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />

                    <div className='row mt-3'>
                        {sortedArray.map((el, i) => {
                            return (
                                <div className='col-6 px-1' key={i}>
                                    <div className='fortnite_card p-1 mb-1'>
                                        <div className='d-flex'>
                                            <div className='me-2'>
                                                <p className='fs-12 fw-700 text-warning'>#{i + 1}</p>
                                            </div>
                                            <div className='d-flex flex-grow-1'>
                                                <img src={el.teamDetails ? el.teamDetails?.logo?.url : participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                                <div className='ms-2'>
                                                    <h1 className='fs-8 fw-600 text-white mb-0'>{ellipsis(el.teamDetails?.name, 9)}</h1>
                                                    <p className='fs-8 fw-600 text-white mb-0'>Kills: {el.teamDetails?.kills}</p>
                                                    {(!key && (i == 0)) && <p className='fs-8 fw-700 text-warning my-0'>{this.props.winnersText}</p>}
                                                </div>
                                            </div>
                                            <div className=''>
                                                <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{el?.score}</p>
                                                <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[i + 1]} + K-{el.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            {el.individualScore.map((player, k) => {
                                                return (
                                                    <div className='fortnite-player-card me-2' key={k}>
                                                        <p className='fs-8 fw-600 text-white my-0'>{ellipsis(player?.participantDetails?.inGameName, 9)}</p>
                                                        <p className='fs-8 fw-600 text-white my-0'>K-{player.kills} D-{player.deaths}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    getTeamFFA = () => {
        const { matchDetails, tournamentDetails } = this.state;
        if (this.state.matchDetails?.results?.length > 1) {
            return (
                <Carousel>
                    <Carousel.Item>{this.getTeamFFAFinalResults(true)}</Carousel.Item>
                    {matchDetails.results.map((el, i) => {
                        var arr = Object.values(el).filter(k => k.teamDetails);
                        var sortedArray = arr.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
                        return (
                            <Carousel.Item key={i}>
                                <div className='col-12 col-lg-6 offset-lg-3'>
                                    <div className='scoreboard-bg-image mt-2 p-3'>
                                        <CardHeader tournamentDetails={tournamentDetails} />

                                        <div className='row mt-3'>
                                            {sortedArray.map((val, j) => {
                                                if (val.teamDetails) {
                                                    return (
                                                        <div className='col-6 px-1' key={i}>
                                                            <div className='fortnite_card p-1 mb-1'>
                                                                <div className='d-flex'>
                                                                    <div className='me-2'>
                                                                        <p className='fs-12 fw-700 text-warning'>#{val.rank}</p>
                                                                    </div>
                                                                    <div className='d-flex flex-grow-1'>
                                                                        <img src={val.teamDetails?.logo?.url} className="rounded-circle btn-size-20" alt='' />
                                                                        <div className='ms-2'>
                                                                            <h1 className='fs-8 fw-600 text-white mb-0'>{val.teamDetails.name}</h1>
                                                                            <p className='fs-8 fw-600 text-white mb-0'>Kills: {val.kills}</p>
                                                                            {(val.rank == 1) && <p className='fs-8 fw-700 text-warning my-0'>{this.props.winnersText}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{val.score}</p>
                                                                        <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[j + 1]} + K-{val.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    {val.individualScore.map((player, k) => {
                                                                        return (
                                                                            <div className='fortnite-player-card flex-grow-1 me-1' key={k}>
                                                                                <p className='fs-8 fw-600 text-center text-white my-0'>{player.participantDetails.inGameName}</p>
                                                                                <p className='fs-8 fw-600 text-center text-white my-0'>K-{player.kills}: D-{player.deaths}</p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}

                                        </div>
                                        <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            )
        } else {
            return this.getTeamFFAFinalResults(false);
        }
    }

    getSoloFFAFinalResult = (key) => {
        const tournamentDetails = this.state.tournamentDetails
        let finalResultsArray = [];
        let sortedArray = [];
        if (key) {
            finalResultsArray = Object.values(this.state.matchDetails?.finalResult).filter(k => k.participantDetails);
            sortedArray = finalResultsArray.sort((a, b) => parseInt(b.score) - parseInt(a.score));
        } else {
            finalResultsArray = Object.values(this.state.matchDetails?.results[0]).filter(k => k.participantDetails);
            sortedArray = finalResultsArray.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
        }

        // let sortedArray = finalResultsArray.sort((a, b) => parseInt(b.score) - parseInt(a.score));
        return (
            <div className='col-12 col-lg-6 offset-lg-3'>
                <div className='scoreboard-bg-image mt-2 p-3'>
                    <CardHeader tournamentDetails={tournamentDetails} />

                    <div className='row mt-3'>
                        {sortedArray.map((el, i) => {
                            return (
                                <div className='col-6 px-1' key={i}>
                                    <div className={`d-flex p-1 mb-1 fortnite_card`}>
                                        <div className='me-1'>
                                            <p className='fs-12 fw-700 text-warning my-0'>#{i + 1}</p>

                                        </div>
                                        <div className='d-flex flex-grow-1'>
                                            <img src={el.participantDetails.profilePicture ? el.participantDetails.profilePicture.url : participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                            <div className='ms-2'>
                                                <p className='fs-8 fw-bold text-white mb-0'>{el.participantDetails?.inGameName}{el.isWinner && <img src={winnerCrown} alt='' height='10' width='10' className='ms-2' />} </p>
                                                <p className='fs-8 fw-600 text-white mb-0'>Kills: {el.kills}</p>
                                                {(!key && (i == 0)) && <p className='fs-8 fw-700 text-warning my-0'>{this.props.winnersText}</p>}
                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{el.score}</p>
                                            <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[i + 1]} + K-{el.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                            {el.isWinner && <p className='fs-8 fw-700 text-success mb-0'>#Winner</p>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                </div>
            </div>
        )
    }

    getSoloFFA = () => {
        const { tournamentDetails } = this.state
        if (this.state.matchDetails.results.length > 1) {
            return (
                <Carousel>
                    <Carousel.Item>{this.getSoloFFAFinalResult(true)}</Carousel.Item>
                    {this.state.matchDetails.results.map((el, i) => {
                        var gameResult = Object.values(el);
                        var sortedArray = gameResult?.sort((a, b) => parseInt(a.rank) - parseInt(b.rank));
                        return (
                            <Carousel.Item key={i}>
                                <div className='col-12 col-lg-6 offset-lg-3'>
                                    <div className='scoreboard-bg-image mt-2 p-3'>
                                        <CardHeader tournamentDetails={tournamentDetails} />
                                        <div className='row mt-3'>
                                            {sortedArray.map((player, j) => {
                                                if (player.participantDetails) {
                                                    return (
                                                        <div className='col-6 px-1' key={j}>
                                                            <div className='fortnite_card d-flex p-1 mb-1'>
                                                                <div className='me-2'>
                                                                    <p className='fs-12 fw-700 text-warning'>#{player.rank}</p>
                                                                </div>
                                                                <div className='d-flex flex-grow-1'>
                                                                    <img src={player.participantDetails ? player.participantDetails.profilePicture?.url : participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                                                    <div className='ms-2'>
                                                                        <p className='fs-8 fw-bold text-white mb-0'>{player.participantDetails?.inGameName}{player.isWinner && <img src={winnerCrown} alt='' height='10' width='10' className='ms-2' />} </p>
                                                                        <p className='fs-8 fw-600 text-white mb-0'>Kills: {player.kills}</p>
                                                                        {(player.rank == 1) && <p className='fs-8 fw-700 text-warning my-0'>{this.props.winnersText}</p>}
                                                                    </div>
                                                                </div>
                                                                <div className=''>
                                                                    <p className='fs-8 fw-600 text-white mb-0'><img src={star} alt='' />{player.score}</p>
                                                                    <p className='fs-8 fw-600 text-white mb-0'>R-{tournamentDetails.gameDetails.pointSystem[j + 1]} + K-{player.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p>
                                                                    {el.isWinner && <p className='fs-8 fw-700 text-success mb-0'>#Winner</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}

                                        </div>
                                        <SponsorCards tournamentDetails={this.state.tournamentDetails} />
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            )
        } else {
            return this.getSoloFFAFinalResult(false);
        }

    }

    getCard = () => {
        const tournamentDetails = this.state.tournamentDetails;
        switch (true) {
            case (tournamentDetails?.participantType === 'SINGLE' && tournamentDetails?.competitionType === 'FFA'):
                return this.getSoloFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'FFA'):
                return this.getTeamFFA();
            case (tournamentDetails?.participantType === 'TEAM' && tournamentDetails?.competitionType === 'DUEL'):
                return this.getTeamDuel();
            default:
                break;
        }
    }

    render() {
        return (
            <div className="p-0">
                {this.getCard()}
                {/* This is Fortnite ScoreCard. */}
            </div>
        )
    }
}