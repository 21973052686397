import React, { Component } from 'react';
import { getAvatarForLetter } from '../../utils/common.utils';


export default class Image extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            id: props.id || null,
            class: props.className || null,
            key: props.key || null,
            param: props.param || null,
            url: props.src || null,
            height: props.height || null,
            width: props.width || null,
            style: props.style || null,
            imageError: false
        };
    }
    
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            id: nextProps.id || null,
            class: nextProps.className || null,
            key: nextProps.key || null,
            param: nextProps.param || null,
            url: nextProps.src || null,
            height: nextProps.height || null,
            width: nextProps.width || null,
            style: nextProps.style || null,
            imageError: prevState.imageError
        };
    }
    
    handleImageError = () => {
        this.setState({ imageError: true });
    }
    
    getAvatarImage = () => {
        const { param, height, width } = this.state;
        
        try {
            // Only attempt to get avatar if param exists and has at least one character
            if (param && param.length > 0) {
                return getAvatarForLetter(param.charAt(0).toLowerCase(), height, width);
            }
            return null;
        } catch (err) {
            console.error('Error generating avatar:', err);
            return getAvatarForLetter("?", height, width);
        }
    }
    
    render() {
        const { url, imageError, id, class: className, key, height, width, style, param } = this.state;
        
        // If no URL is provided or image failed to load, show the avatar
        if (!url || imageError) {
            // Only attempt to show SVG avatar if param exists
            if (param) {
                const avatarImage = this.getAvatarImage();
                const isSvg = typeof avatarImage === 'string' && avatarImage.trim().startsWith('<svg');
                
                if (isSvg) {
                    return (
                        <span
                            className={className}
                            id={id}
                            key={key}
                            style={{ 
                                ...style, 
                                display: 'inline-block', 
                                height: height, 
                                width: width 
                            }}
                            dangerouslySetInnerHTML={{ __html: avatarImage }}
                        />
                    );
                }
                
                // If not SVG but still has a fallback image
                return (
                    <img
                        src={avatarImage}
                        className={className}
                        id={id}
                        key={key}
                        height={height}
                        width={width}
                        alt={`Avatar for ${param}`}
                        style={style}
                    />
                );
            }
            
            // Return empty span if no param (no default avatar)
            return (
                <span
                    className={className}
                    id={id}
                    key={key}
                    style={{
                        ...style,
                        display: 'inline-block',
                        height: height,
                        width: width,
                        backgroundColor: '#e0e0e0' // Light gray background as placeholder
                    }}
                />
            );
        }
        
        // If URL is provided and image hasn't failed to load yet, show the image
        return (
            <img
                src={url}
                className={className}
                id={id}
                key={key}
                height={height}
                width={width}
                alt={param ? `Avatar for ${param}` : ""}
                style={style}
                onError={this.handleImageError}
            />
        );
    }
}