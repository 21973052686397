import React, { Component } from "react";
import { MdRefresh, MdZoomOutMap } from "react-icons/md";
import { ImZoomIn, ImZoomOut } from "react-icons/im";
import "../../../../../styles/brackets.css";
import "../../../../../styles/Tournaments.css";
import TournamentHeader from "../../common/TournamentHeader";
import structureIc from '../../../../../images/icon/stageImg.png';
import swapIc from '../../../../../images/icon/swap-ic.png';
import timestampIc from '../../../../../images/icon/timestamp-ic.png';
import { NavLink } from 'react-router-dom';
import winCup from '../../../../../images/icon/winCup.png';
import { Modal } from "react-bootstrap";
import { HiUserAdd } from "react-icons/hi";
import { disableSwap, errorHandler, getMonthAndDate, getTournamentAdditionalData, getTournamentData, months, showHideMatchInvite } from "../../../../../utils/common.utils";
import MatchInvite from "../../../../popup/MatchInvite";
import { toast } from 'react-toastify';
import TournamentApis from "../../../../../helper/tournament.api";
import MatchesApis from "../../../../../helper/matches.api";


export default class SimpleStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentDetails: props.state.tournamentDetails,
      structure: props.state.structure,
      scale: 1.1,
      openInvite: false,
      matchId: -1,
      selectedPlayers: [],
      isSwappingEnabled: false,
      displayRounds: false,
      groupMatches: null,
      selectedGroupe: 0
    };

    this.prevValue = 0;
    this.marginTopDiv = 35;
    this.maxWidth = 0;


    this.tournamentApis = new TournamentApis();
    this.matchesApis = new MatchesApis();

    this.isSwapped = false;
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  zoom = (key) => {
    if ((this.state.scale * 1.1) === 1.1) {
      this.maxWidth = 0;
    }
    switch (key) {
      case 'In':
        this.setState({ scale: this.state.scale * 1.1 });
        break;
      case 'Out':
        this.maxWidth = ((this.maxWidth === 0) ? (this.maxWidth + 10) : (this.maxWidth + this.maxWidth + 1));
        this.setState({ scale: this.state.scale / 1.1 });
        break;
      default:
        this.setState({ scale: 1.1 });
        break;
    }
  }

  getMarginTopForRounds = (index) => {
    var marginTop = 0;
    switch (index) {
      case 0:
        marginTop = 0;
        break;
      case 1:
        marginTop = 35;
        break;
      case 2:
        marginTop = 105;
        break;
      default:
        marginTop = ((this.prevValue * 2) + 35);
        break;
    }
    return marginTop;
  }

  calculateMatches = (num, threshold) => {
    var stages = 1;
    var arr = [num];
    while (num !== 1) {
      num = (num / 2);
      arr.push(num);
      stages++;
      if (num === threshold) break;
    }
    return arr;
  }

  onClickOpenInvite = () => {
    this.setState({
      openInvite: !this.state.openInvite
    })
  }

  openInviteModal = (e, matchId) => {
    e.preventDefault();
    this.setState({
      openInvite: true,
      matchId: matchId
    })
  }

  enableDisableSwapping = () => {
    this.setState({
      isSwappingEnabled: !this.state.isSwappingEnabled,
      selectedPlayers: []
    });
  }

  handleInputChange = (event, matchDetails, roundNumber, matchNumber) => {
    const target = event.target;
    const isChecked = target.type === 'checkbox' ? target.checked : target.value;
    const playerID = target.id;

    var selectedPlayers = this.state.selectedPlayers;
    if (isChecked) {
      if (selectedPlayers.length === 2) {
        toast.warning("At a time only two players can be swapped");
        target.checked = false;
      } else {
        selectedPlayers.push({
          teamID: playerID,
          matchDetails: matchDetails,
          roundNumber: roundNumber,
          matchNumber: matchNumber
        });
        this.setState({
          selectedPlayers: selectedPlayers
        });
      }
    } else if (selectedPlayers.length > 0) {
      selectedPlayers = selectedPlayers.filter(function (obj) { return obj.teamID !== playerID });
      this.setState({
        selectedPlayers: selectedPlayers
      });
    }

  }

  swapPositions = () => {
    this.isSwapped = true;
    var selectedPlayers = this.state.selectedPlayers;

    if (selectedPlayers[0].matchDetails._id === selectedPlayers[1].matchDetails._id) {
      toast.warning("You can not swipe two players within a same match.");
    } else {
      this.props.updateLoader(true);
      var updatedMatches = [];

      selectedPlayers.forEach((player, index) => {
        var participants = [];
        player.matchDetails.participants.forEach((teamDetails) => { participants.push(teamDetails._id) });
        player.matchDetails.participants = participants;
        selectedPlayers[index] = player;
        updatedMatches.push(player.matchDetails);
      });

      updatedMatches[0].participants = updatedMatches[0].participants.map((team) => { return team === selectedPlayers[0].teamID ? selectedPlayers[1].teamID : team });
      updatedMatches[1].participants = updatedMatches[1].participants.map((team) => { return team === selectedPlayers[1].teamID ? selectedPlayers[0].teamID : team });

      this.matchesApis.swapParticipants(updatedMatches).then(
        (res) => {
          this.enableDisableSwapping();

          this.setState({
            tournamentDetails: res.data.result
          });
          this.props.updateLoader(false);
          toast.success("Participants Swapped Successfully");
        }
      ).catch(
        (err) => {
          this.props.updateLoader(false);
          errorHandler(err);
        }
      );
    }
  }


  refresh = () => {
    setTimeout(() => {
      this.zoom('Reset');
    }, 1000);
  }

  hideOrShowRounds = (index) => {
    if (!this.state.isSwappingEnabled) {
      this.setState({
        selectedGroupe: index
      });
      var groupMatches;
      if (index !== undefined) {
        groupMatches = this.state.tournamentDetails?.stages[this.props.index].groups[index]

      } else {
        groupMatches = null
      }
      this.setState({
        displayRounds: !this.state.displayRounds,
        groupMatches: groupMatches
      });
    }
  }

  editTimeSlot = () => {
    this.props.history.push(`/organization/${this.props.orgId}/tournament/${this.props.id}/overview/structure/tournament-stages/${this.props.index}/slot-details/${this.state.selectedGroupe}`);
  }

  getTeamMembers = (groupDetails) => {
    var arr = [];
    Object.keys(groupDetails).map((key) => {
      groupDetails[key].map((obj) => {
        obj.participants.map((team) => { arr.push(team) });
      });
    });

    var jsonObject = arr.map(JSON.stringify);
    var uniqueSet = new Set(jsonObject);
    var uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    return uniqueArray;
  };

  isAlreadyExist = (id) => {

    var selectedPlayers = this.state.selectedPlayers;
    var result = false;

    selectedPlayers.forEach((team, i) => {
      if (team.playerID === id) {
        result = true
      }
    });

    return result;

  }

  render() {
    if (this.state.tournamentDetails?.stages?.length > 0) {

      // var structure = this.calculateMatches(pairs,threshold);
      if (this.state.tournamentDetails?.stages[this.props.index]) {
        var groupMatches = this.state.tournamentDetails.stages[this.props.index].groups[0];

        return (
          <section className="body-section" style={{ paddingBottom: '50px' }}>

            {/* Header Section */}
            <div className="container p-2 mt-lg-3">
              <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={structureIc} heading={this.state.tournamentDetails.stages[this.props.index].name} subHeading={<h1 className="stage-sub-heading text-center">( FFA Simple Stage )</h1>} history={this.props.history} />
              <div className='d-flex overflow-auto mt-2 add-time justify-content-around'>
                {this.state.selectedPlayers.length === 2 ?
                  <button className="add-btn fs-12 text-black me-2 mb-0 fw-600 rounded-15" onClick={this.swapPositions}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt='' /> Swap Position
                  </button>
                  :
                  <button className="fs-12 text-black me-2 add-btn mb-0 fw-600 rounded-15" onClick={this.enableDisableSwapping}>
                    <img src={swapIc} className='btn-size-20 me-2 mb-1' alt='' /> {this.state.isSwappingEnabled ? "Disable Swapping" : "Enable Swapping"}
                  </button>
                }

                <button type="button" onClick={this.editTimeSlot} className="add-btn fs-12 text-black fw-600 rounded-15" >
                  <img src={timestampIc} className='btn-size-20 me-2 mb-1' alt='' /> Edit Time Slot
                </button>
              </div>
            </div>

            <div className='container w-100 d-flex overflow-auto' style={{ height: '-webkit-fill-available' }}>
              <div className='' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, overflow: 'none', width: 'fit-content' }}>
                {
                  Object.keys(groupMatches).map((key, i) => {
                    return (
                      <div className="py-1 px-3" key={key}>
                        <button type="button" className={`score-showdown-btn me-2 rounds`}>ROUND {key}</button>
                        <div className='d-flex overflow-auto my-4'>
                          {
                            groupMatches[key].map((match, index) => {
                              var dateAndTime = getMonthAndDate(match.matchSchedule);
                              var players = [];
                              var numberOfPlayers = (this.state.tournamentDetails.stages[this.props.index].maxParticipants / this.state.tournamentDetails.stages[this.props.index].numberOfMatches)
                              let stageIndex = parseInt(this.props.index)
                              let lastStageThreshold = (stageIndex !== 0) ? parseInt(this.state.tournamentDetails.stages[stageIndex - 1].qualificationThreshold) : 0;
                              for (let j = 0; j < numberOfPlayers; j++) {

                                var player = match.participants[j] ? match.participants[j] : null;

                                if (match.resultAdded && player) {
                                  player.isWinner = match.finalResult[j].isWinner;
                                }

                                players.push(player);

                              }
                              const canParticipate = showHideMatchInvite(match, this.state.tournamentDetails);

                              return (
                                <div key={`${key}.${index + 1}`} className="tournament-btn pink-skyblue-gradient-btn d-flex flex-column justify-content-start align-items-start flex-shrink-0 me-2 pb-2" style={{ height: 'auto', width: '320px' }} >
                                  <span className="match-tag position-absolute">Match {key}.{index + 1}</span>
                                  <div className="row ms-0 w-100 mt-1 justify-content-between">
                                    <div className="col-5">
                                      <div className="d-flex flex-column">
                                        {players.map((player, ind) => {
                                          if (player !== null) {
                                            return (
                                              <div className="d-flex align-items-center" key={ind}>
                                                <span className="player-name my-1 text-truncate" key={ind}>
                                                  {(this.state.isSwappingEnabled && key < 2) ?
                                                    <input disabled={disableSwap(match)} name='checking' type='checkbox' className='me-2' id={player._id} onChange={(e) => this.handleInputChange(e, match, key, index)} />
                                                    :
                                                    <img src={this.state.tournamentDetails.participantType === 'TEAM' ? player?.logo?.url : player?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt='' />
                                                  }
                                                  {this.state.tournamentDetails.participantType === 'TEAM' ? player.name : player.inGameName}
                                                </span>
                                                {match.resultAdded && player.isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt='' /> : null}
                                              </div>)
                                          } else {
                                            return <span key={ind} className="player-name my-1 vacant">Vacant</span>
                                          }
                                        })}
                                      </div>
                                    </div>

                                    {(key == 1 && canParticipate) ?
                                      <div className="col-3 d-flex align-items-center justify-content-center">
                                        <button disabled={numberOfPlayers * (index + 1) <= lastStageThreshold} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-40 mx-2" onClick={(e) => this.openInviteModal(e, match._id)} style={{ height: "32px", width: "32px" }} >
                                          <HiUserAdd className="btn-size-15" />
                                        </button>
                                      </div>
                                      :
                                      null
                                    }

                                    <div className="col-4 d-flex align-items-center justify-content-end">
                                      <div className="d-flex flex-column float-end">
                                        <span className="player-name my-1">{dateAndTime[0]}</span>
                                        <span className="player-name">{dateAndTime[1]}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>

              {/* Zoom In/Out Section */}
              <div className="icons-box d-flex justify-content-center w-100">
                <div className="icon mx-2" onClick={() => this.zoom('Out')}>
                  <ImZoomOut className="zoom-icon" />
                </div>
                <div className="icon mx-2" onClick={() => this.zoom('Reset')}>
                  <MdZoomOutMap className="zoom-icon" />
                </div>
                <div className="icon mx-2" onClick={() => this.zoom('In')}>
                  < ImZoomIn className="zoom-icon" />
                </div>
              </div>
            </div>



            {/* Tournament Invitation Section */}
            <Modal centered show={this.state.openInvite} dialogClassName="ClanInvite-modal">
              <Modal.Body>
                <MatchInvite
                  onClickOpenInvite={this.onClickOpenInvite}
                  tournamentId={this.props.id}
                  stageId={this.props.index}
                  matchId={this.state.matchId}
                  gameId={this.state.tournamentDetails?.gameId}
                  participantType={this.state.tournamentDetails?.participantType}
                  choice="Send Invite"
                />
              </Modal.Body>
            </Modal>

          </section>
        );


      } else {
        return (
          <div>

          </div>
        );
      }

    } else {
      return (
        <div>
          <h1>No Data Found</h1>
        </div>
      );
    }

  }
}
