import React, { Component } from "react";
import '../../../../styles/Tournaments.css';
import { NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//images, icons
import { AiFillPlusCircle, AiFillCloseCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import refresh from '../../../../images/games/refresh.png';
import entryFee from '../../../../images/games/entry-fee.png';
import { HiOutlineTrash } from 'react-icons/hi';
import { FiEdit } from "react-icons/fi";
import { IoArrowBackCircleOutline } from 'react-icons/io5';

//components
import TournamentHeader from '../common/TournamentHeader';
import EntryFreePrizeFixed from './EntryFreePrizeFixed';
import EntryPaidPrizeFixed from './EntryPaidPrizeFixed';
import EntryPaidPrizeCF from './EntryPaidPrizeCF';
import PremiumFreeFixed from './PremiumFreeFixed';
import PremiumPaidFixed from './PremiumPaidFixed';
import PremiumPaidCF from './PremiumPaidCF';
import TournamentApis from '../../../../helper/tournament.api';
import { errorHandler, getCommissionByType, getTermsAndConditionsByType, getTournamentData } from '../../../../utils/common.utils';
import { toast } from 'react-toastify';
import CommonApis from '../../../../helper/common.api';
import { AxiosResponse, AxiosError } from "axios";
import Merchandise from './Merchandise';
import TermsConditions from '../../../popup/TermsConditions';
import ConfirmationModal from '../../../popup/ConfirmationModal';
import { connect } from 'react-redux';
import WalletApi from '../../../../helper/wallet.api';
import { profile } from '../../../../utils/localstorage.utils';
import ReactTooltip from 'react-tooltip';
import { setTournamentDetails } from '../../../../redux/actions/tournametActions';
import { setUpdatedEc } from '../../../../redux/actions/commonActions';
import { COMMISSION_TYPES, TERMS_AND_CONDITIONS_TYPES } from '../../../../utils/enums.utils';

const mapStateToProps = (props) => {
    return {
        tournamentData: props.tournamentData,
        UpdatedEc: props.UpdateEc,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data, key) => {
            switch (key) {
                case 'tournament':
                    dispatch(setTournamentDetails(data));
                    break;
                case 'updateEc':
                    dispatch(setUpdatedEc(data));
                    break;
                default:
                    break;
            }

        },
    }
}
class EntryFeePrizeDetails extends Component {
    authUser = profile()
    walletApi = new WalletApi()
    tournamentApis = new TournamentApis();
    commonApi = new CommonApis();

    constructor(props) {
        super(props);
        let localData = JSON.parse(localStorage.getItem('entryfeeNprize'))
        this.state = {
            listingType: props.tournamentData?.entryFeeAndPrizeDetails?.listingType ? props.tournamentData.entryFeeAndPrizeDetails.listingType : (localData?.listingType && (localData.id == props.tournamentData?._id)) ? localData.listingType : 'REGULAR',
            openTermsModal: false,
            acceptCheck: props.tournamentData?.entryFeeAndPrizeDetails?.prizePoolAmount ? true : false,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : {},
            details: {
                entryFee: props.tournamentData?.entryFeeAndPrizeDetails?.entryFee ? props.tournamentData.entryFeeAndPrizeDetails.entryFee : (localData?.entryFee && (localData.id == props.tournamentData?._id)) ? localData?.entryFee : false,
                entryFeeAmount: props.tournamentData?.entryFeeAndPrizeDetails?.entryFeeAmount ? props.tournamentData.entryFeeAndPrizeDetails.entryFeeAmount : (localData?.entryFeeAmount && (localData.id == props.tournamentData?._id)) ? localData?.entryFeeAmount : 0,
                prizePoolType: props.tournamentData?.entryFeeAndPrizeDetails?.prizePoolType ? props.tournamentData.entryFeeAndPrizeDetails.prizePoolType : (localData?.prizePoolType && (localData.id == props.tournamentData?._id)) ? localData?.prizePoolType : 'NONE',
            },
            prizeDistribution: props.tournamentData?.entryFeeAndPrizeDetails?.prizeDistribution ? props.tournamentData?.entryFeeAndPrizeDetails?.prizeDistribution : (localData?.prizeDistribution && (localData.id == props.tournamentData?._id)) ? localData?.prizeDistribution : [],
            openPrizeModal: false,
            merchandiseDetails: null,
            toggleConfirm: false,
            confirm: {},
            stepOneData: null,
            step: 1,
            showErrors: false,
            availablePricePool: 0,
            distributedPricePool: 0,
            balance: null
        }
        this.localObject = {
            id: props?.tournamentData?._id,
            // listingType: (localData.listingType && (localData.id == props.tournamentData?._id)) ? localData.listingType : 'REGULAR'
        }
        this.getTermsData = getTermsAndConditionsByType(TERMS_AND_CONDITIONS_TYPES.ENTRY_FEE_PRICE_DETAILS);
        this.commissionValues = getCommissionByType(COMMISSION_TYPES.ENTRY_FEE_PRICE_POOL)
    }
    componentDidMount() {
        this.props.showOrHideNav(true);
        if (!this.props?.tournamentData?._id) {
            getTournamentData(this.props.match.params.id, this.setData)
        }
        this.getMyWalletInfo(this.authUser?.id)
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            const details = {
                entryFee: values?.entryFeeAndPrizeDetails?.entryFee ? values.entryFeeAndPrizeDetails.entryFee : false,
                entryFeeAmount: values?.entryFeeAndPrizeDetails?.entryFeeAmount ? values.entryFeeAndPrizeDetails.entryFeeAmount : 0,
                prizePoolType: values?.entryFeeAndPrizeDetails?.prizePoolType ? values.entryFeeAndPrizeDetails.prizePoolType : 'NONE',
            }
            this.setState({
                tournamentDetails: values,
                details: details,
                listingType: values?.entryFeeAndPrizeDetails?.listingType ? values.entryFeeAndPrizeDetails.listingType : 'REGULAR',
                prizeDistribution: values?.entryFeeAndPrizeDetails?.prizeDistribution ? values?.entryFeeAndPrizeDetails?.prizeDistribution : [],
            })
            this.props.dispatchData(values, 'tournament')
        } else {
            this.props.history.push('/pageNotFound')
        }
    }
    deletePrizeMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center error-msg">Are you sure you want to delete this prize?</p>
        )
    }
    isDelete = (msg, key) => {
        const data = {
            msg: msg ? msg : '',
            key: key ? key : ''
        }
        this.setState({
            confirm: data,
            toggleConfirm: !this.state.toggleConfirm
        })
    }
    confirmed = async (key) => {
        let data = [...this.state.prizeDistribution];
        let currentData = await this.state.prizeDistribution[key ? key : 0]?.prizeAmount;
        let distributedAmount = this.state.distributedPricePool;
        distributedAmount = distributedAmount + parseInt(currentData);
        data.splice(key, 1)
        this.setState({ prizeDistribution: data, toggleConfirm: false, distributedPricePool: distributedAmount })
    }
    updateOpenTermsModalStatus = (type) => {
        this.setState({ openTermsModal: type })
    }
    onAcceptTerms = (type) => {
        this.setState({
            openTermsModal: false,
            acceptCheck: type,
        })
    }
    updateListingType = (e, type) => {
        e.preventDefault()
        this.localObject['listingType'] = type;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        this.setState({ listingType: type })
    }
    updateDetails = (e, key, value) => {
        e.preventDefault();
        this.localObject[key] = value;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        const data = this.state.details
        data[key] = value
        this.setState({ details: data })
    }
    renderForm = () => {
        const { listingType, details } = this.state
        switch (true) {
            case ((listingType === 'REGULAR') && (details.entryFee === false) && (details.prizePoolType === 'FIXED')):
                return <EntryFreePrizeFixed id={this.props.match.params.id} localObject={this.localObject} onSubmitData={this.onNextStep} balance={this.state.balance} initialValues={this.state?.tournamentDetails?.entryFeeAndPrizeDetails} maxParticipants={this.state?.tournamentDetails?.maxParticipant} commissionValues={this.commissionValues} />
            case ((listingType === 'REGULAR') && (details.entryFee === true) && (details.prizePoolType === 'FIXED')):
                return <EntryPaidPrizeFixed id={this.props.match.params.id} localObject={this.localObject} onSubmitData={this.onNextStep} balance={this.state.balance} initialValues={this.state?.tournamentDetails?.entryFeeAndPrizeDetails} maxParticipants={this.state?.tournamentDetails?.maxParticipant} commissionValues={this.commissionValues} />
            case ((listingType === 'REGULAR') && (details.entryFee === true) && (details.prizePoolType === 'CROWDFUNDED')):
                return <EntryPaidPrizeCF id={this.props.match.params.id} localObject={this.localObject} onSubmitData={this.onNextStep} balance={this.state.balance} initialValues={this.state?.tournamentDetails?.entryFeeAndPrizeDetails} maxParticipants={this.state?.tournamentDetails?.maxParticipant} commissionValues={this.commissionValues} />
            case ((listingType === 'PREMIUM') && (details.entryFee === false) && (details.prizePoolType === 'FIXED')):
                return <PremiumFreeFixed id={this.props.match.params.id} localObject={this.localObject} onSubmitData={this.onNextStep} balance={this.state.balance} initialValues={this.state?.tournamentDetails?.entryFeeAndPrizeDetails} maxParticipants={this.state?.tournamentDetails?.maxParticipant} commissionValues={this.commissionValues} />
            case ((listingType === 'PREMIUM') && (details.entryFee === true) && (details.prizePoolType === 'FIXED')):
                return <PremiumPaidFixed id={this.props.match.params.id} localObject={this.localObject} onSubmitData={this.onNextStep} balance={this.state.balance} initialValues={this.state?.tournamentDetails?.entryFeeAndPrizeDetails} maxParticipants={this.state?.tournamentDetails?.maxParticipant} commissionValues={this.commissionValues} />
            case ((listingType === 'PREMIUM') && (details.entryFee === true) && (details.prizePoolType === 'CROWDFUNDED')):
                return <PremiumPaidCF id={this.props.match.params.id} localObject={this.localObject} onSubmitData={this.onNextStep} balance={this.state.balance} initialValues={this.state?.tournamentDetails?.entryFeeAndPrizeDetails} maxParticipants={this.state?.tournamentDetails?.maxParticipant} commissionValues={this.commissionValues} />
            default:
                break;
        }
    }
    onNextStep = values => {
        const { listingType, details } = this.state;
        var prizeMoneyRemaining = 0
        if ((listingType === 'REGULAR') && (details.entryFee === true) && (details.prizePoolType === 'CROWDFUNDED')) {
            prizeMoneyRemaining = parseInt(values.moneyCollectedPerEntryFee) * parseInt(values.maxParticipants);
        } else {
            prizeMoneyRemaining = values.prizePoolAmount;
        }
        if (this.state.prizeDistribution?.length) {
            this.state.prizeDistribution?.map(prize => prizeMoneyRemaining = prizeMoneyRemaining - prize.prizeAmount);
        }
        const data = {
            listingType: this.state.listingType,
            entryFee: this.state.details.entryFee,
            prizePoolType: this.state.details.prizePoolType,
            prizeDistribution: this.state.prizeDistribution,
            entryFeeAmount: values?.entryFeeAmount ? parseInt(values.entryFeeAmount) : 0,
            prizePoolAmount: values?.prizePoolAmount ? parseInt(values.prizePoolAmount) : 0,
            espotzCommission: values?.espotzCommission ? parseInt(values.espotzCommission) : '',
            maxParticipants: values?.maxParticipants ? parseInt(values.maxParticipants) : '',
            entryCommission: values?.entryCommission ? parseInt(values.entryCommission) : '',
            moneyCollectedPerEntryFee: values?.moneyCollectedPerEntryFee ? parseInt(values.moneyCollectedPerEntryFee) : '',
            ECToBeReceived: values?.ECToBeReceived ? parseInt(values.ECToBeReceived) : '',
            buyEC: values?.buyEC ? parseInt(values.buyEC) : '',
            prizeMoneyRemaining,
        }
        let distributedAmount = values.prizePoolAmount ? values.prizePoolAmount : this.state.distributedPricePool;
        if (this.state.prizeDistribution?.length) {
            for (let i = 0; i < this.state.prizeDistribution.length; i++) {
                distributedAmount = distributedAmount - parseInt(this.state.prizeDistribution[i]?.prizeAmount)
            }
        }
        this.setState({ stepOneData: data, step: 2, availablePricePool: values.prizePoolAmount ? distributedAmount : this.state.availablePricePool, distributedPricePool: distributedAmount })
    }
    onSubmit = () => {

        const { prizeMoneyRemaining } = this.state.stepOneData;

        // Validation for negative prize pool
        if (prizeMoneyRemaining < 0) {
            toast.error("The prize pool has been over-allocated. Please adjust the prize distribution to ensure it does not exceed the total prize pool amount.");
            return false;
        }

        // Validation for under-distributed prize pool
        if (prizeMoneyRemaining > 0) {
            toast.error("The entire prize pool amount has not been distributed. Please allocate the remaining prize pool to the winners.");
            return false;
        }


        if (!this.state.acceptCheck) {
            this.setState({ showErrors: true })
            return false
        }
        const data = {
            entryFeeAndPrizeDetails: this.state.stepOneData
        }
        data.entryFeeAndPrizeDetails.prizeDistribution = this.state.prizeDistribution
        this.props.updateLoader(true);
        this.tournamentApis.addEntryFeeAndPrize(this.props.match.params.id, data).then(
            (res) => {
                this.props.dispatchData(res.data.result, 'tournament')
                this.props.updateLoader(false);
                toast.success("Details Updated Successfully");
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${this.props.match.params.id}/overview`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    getMyWalletInfo = (params) => {
        this.props.updateLoader(true)
        this.walletApi.getWalletInfo(params).then(
            (res) => {
                this.props.dispatchData(res.data?.result?.balance, 'updateEc')
                this.setState({
                    balance: res.data?.result?.balance,
                    availablePricePool: res.data?.result?.balance,
                    distributedPricePool: res.data?.result?.balance
                })
                setTimeout(() => this.props.updateLoader(false), 1000);;
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    addMerchandise = (val) => {
        const { stepOneData } = this.state;
        let moneyLeft = stepOneData.prizeMoneyRemaining - val.prizeAmount;
        if (moneyLeft >= 0) {
            stepOneData.prizeMoneyRemaining = moneyLeft;
            let data = [...this.state.prizeDistribution, val];
            this.setState({ prizeDistribution: data, openPrizeModal: false, distributedPricePool: moneyLeft, stepOneData });
            this.localObject['prizeDistribution'] = data;
            localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject))
        } else {
            toast.error("Prize pool exceed");
        }

    }

    updateMerchandise = (i, val) => {
        let { prizeDistribution, stepOneData } = this.state;

        let oldPrizeAmount = prizeDistribution[i - 1]?.prizeAmount || 0; // Previous prize amount
        let newPrizeAmount = val.prizeAmount; // New updated prize amount

        let prizeMoneyRemaining = stepOneData.prizeMoneyRemaining + oldPrizeAmount - newPrizeAmount;

        // Ensure the total distributed prize doesn't exceed the prize pool
        if (prizeMoneyRemaining < 0) {
            toast.error("Prize pool exceeded. Adjust prize distribution.");
            return;
        }

        // Update the prize distribution with the new value
        let updatedPrizes = [...prizeDistribution];
        updatedPrizes[i - 1] = val;

        // Update the state with recalculated values
        this.setState({
            prizeDistribution: updatedPrizes,
            openPrizeModal: false,
            distributedPricePool: stepOneData.prizePoolAmount - prizeMoneyRemaining,
            stepOneData: {
                ...stepOneData,
                prizeMoneyRemaining: prizeMoneyRemaining
            }
        });

        this.localObject['prizeDistribution'] = updatedPrizes;
        localStorage.setItem('entryfeeNprize', JSON.stringify(this.localObject));
    };


    openMerchandise = (index, val) => {
        const data = {
            rank: index + 1,
            data: val
        }
        this.setState({ merchandiseDetails: data, openPrizeModal: true })
    }

    showToolTip = (id, place = "top", type = "warning", text) => {
        return (
            <ReactTooltip id={id} place={place} effect="float" type={type}>
                {text}
            </ReactTooltip>
        )
    }

    render() {
        const { prizeDetails, details } = this.state;
        return (
            <section className="body-section">
                <div className="container p-3 mt-lg-3">
                    <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={entryFee} subHeading={null} heading="Entry Fee & Prize Details" history={this.props.history} />
                    {
                        this.state.step === 2 &&
                        <IoArrowBackCircleOutline onClick={() => this.setState({ step: 1 })} className="back-btn mt-2" />
                    }

                    {this.state.step === 1 &&
                        <div>
                            {/* Listing Type */}
                            <div className='row mt-3'>
                                <div className='col-12 my-2'>
                                    <div className="row">
                                        <div className="">
                                            <label htmlFor="custom-btn" className="fs-14 fw-600 text-white mb-2"> Listing Type </label>
                                            <a href="#" title="There are two Type of Listing Regular, Premium
                                                Regular Tournament is Completely managed By Tournament Operator.
                                                Premium Tournament is Exclusively managed By Espotz Admin" className="tooltip-info">
                                                <BsInfoCircle className="text-white btn-size-12 ms-1" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            {/* <button className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white pink-skyblue-gradient-btn w-100 ${this.state.listingType === 'PREMIUM' ? 'epz-active-field' : ''}`} onClick={(e) => this.updateListingType(e, 'PREMIUM')}>
                                                Premium
                                            </button> */}
                                            <button className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-black button-disabled w-100`}>
                                                Premium (Coming Soon)
                                            </button>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <button className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white pink-skyblue-gradient-btn w-100 ${this.state.listingType === 'REGULAR' ? 'epz-active-field' : ''}`} onClick={(e) => this.updateListingType(e, 'REGULAR')}>
                                                Regular
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 my-2'>
                                    {/* entry type */}
                                    <label htmlFor='visibility' className="fs-14 fw-600 text-white">Entry Fee</label>
                                    <select className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45 mt-1"
                                        onChange={(e) => this.updateDetails(e, 'entryFee', JSON.parse(e.target.value))}
                                        value={this.state.details.entryFee}
                                        disabled={!this.state.tournamentDetails?.gameDetails?.isEntryFeeEnabled}
                                    >
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                                {!this.state.tournamentDetails?.gameDetails?.isEntryFeeEnabled &&
                                    <div className='col-12 my-0'>
                                        <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#8C2FB8' }}>{`Entry Fee for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`}<span className='text-warning px-4'>$$$</span> {`Entry Fee for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`} <span className='text-warning px-4'>$$$</span> {`Entry Fee for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`} <span className='text-warning px-4'>$$$</span> {`Entry Fee for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`}</marquee>
                                    </div>
                                }

                                <div className='col-lg-12 col-12'>
                                    {/* Prize Pool Y/N */}
                                    <div className="col-12 my-2">
                                        <label className="fs-14 fw-600 text-white">Prize Pool</label>
                                        <select className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                            onChange={(e) => this.updateDetails(e, 'prizePoolType', e.target.value)}
                                            value={this.state.details.prizePoolType}
                                            disabled={!this.state.tournamentDetails?.gameDetails?.isPrizePoolEnabled}
                                        >
                                            <option value='NONE'>No</option>
                                            <option value='FIXED'>Fixed</option>
                                            <option value='CROWDFUNDED' disabled={(!details.entryFee || this.state.listingType === 'PREMIUM') ? true : false}>Crowd Funded</option>
                                        </select>
                                    </div>
                                </div>
                                {!this.state.tournamentDetails?.gameDetails?.isPrizePoolEnabled &&
                                    <div className='col-12 my-0'>
                                        <marquee className='marquee-text fs-14 fw-800' width="100%" direction="left" style={{ color: '#8C2FB8' }}>{`Prize Pool for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`}<span className='text-warning px-4'>$$$</span> {`Prize Pool for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`} <span className='text-warning px-4'>$$$</span> {`Prize Pool for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`} <span className='text-warning px-4'>$$$</span> {`Prize Pool for the game ${this.state.tournamentDetails?.gameDetails?.name} is currently disabled by the Espotz Admin.`}</marquee>
                                    </div>
                                }
                                <div className='col-12 my-2'>
                                    {this.renderForm()}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 offset-lg-3 col-12'>
                                    {
                                        this.state.details.prizePoolType === 'NONE' &&
                                        <button className='fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 my-2'
                                            onClick={() => { this.onNextStep({}) }}>
                                            Next
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.step === 2 &&
                        <div className="container d-flex flex-column p-3">
                            {/* Prize Distribution */}
                            <div className='my-3'>
                                <div className="d-flex align-items-center">
                                    <hr className="border-1 flex-grow-1" />
                                    <div className="d-flex align-items-center mx-3">
                                        <p className="fs-16 fw-bold text-white-50 mb-0">Prize Distribution</p>
                                    </div>
                                    <hr className="border-1 flex-grow-1" />
                                </div>
                                <p className="fs-14 fw-bold text-white-50 mb-0 text-center">Prize Money Remaining = EC {this.state.stepOneData?.prizeMoneyRemaining}</p>
                            </div>
                            {/* Add Prize Button */}
                            <div className="">
                                {this.state.prizeDistribution.map((el, i) => {
                                    return (
                                        <div className="black-flat-btn sponsor-border rounded-10 pt-4 pb-4" style={{ height: "auto" }} key={i}  >
                                            <div className="col-10 offset-1 d-flex justify-content-center">
                                                <h1 className="fs-16 fw-700 text-white mt-2" > Prize {i + 1} </h1>
                                                <FiEdit className="text-white mt-2 ms-3" onClick={e => this.openMerchandise(i, el)} />
                                                <HiOutlineTrash className="text-white mt-2 ms-2" onClick={() => { this.isDelete(this.deletePrizeMsg(), i) }}
                                                />
                                            </div>
                                            {
                                                el?.merchandise &&
                                                <div className="col-10 offset-1">
                                                    <label className="fs-16 fw-700 text-white mt-2 float-start" htmlFor="" >
                                                        Heading
                                                    </label>
                                                    <input type="text" value={el?.merchandiseHeading} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                                </div>
                                            }
                                            {
                                                el?.merchandise &&
                                                <div className="col-10 offset-1">
                                                    <label className="fs-16 fw-700 text-white mt-2 float-start" htmlFor="" >
                                                        Description
                                                    </label>
                                                    <input type="text" value={el?.merchandiseDescription} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                                </div>
                                            }
                                            <div className="col-10 offset-1">
                                                <label className="fs-16 fw-700 text-white mt-2 float-start" htmlFor="" >
                                                    Prize
                                                </label>
                                                <input type="text" value={el?.prizeAmount} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                            </div>
                                            {
                                                el?.merchandiseImage?.url &&
                                                <div className="text-center mt-3">
                                                    <img src={el?.merchandiseImage?.url} alt="" width="150" height="85" className="border border-2 rounded-10" />
                                                </div>
                                            }

                                        </div>
                                    );
                                })}
                            </div>
                            <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-3 w-100">
                                <button className="btn" onClick={e => this.openMerchandise(this.state.prizeDistribution?.length, null)}>
                                    <AiFillPlusCircle className="btn-size-20 text-white" />
                                </button>
                            </div>

                            <div className='d-flex justify-content-center align-items-center w-100 my-3'>
                                <input type="checkbox" className='me-2' id="dialog"
                                    checked={this.state.acceptCheck}
                                    onChange={() => this.setState({ openTermsModal: !this.state.openTermsModal, showErrors: false })}
                                />
                                <label htmlFor="dialog" className='fs-14 fw-600 mb-0'>I accept the terms of service</label>
                            </div>
                            {this.state.showErrors &&
                                <span className='error-msg'>Please accept terms and conditions</span>
                            }
                            <div className="d-flex justify-content-center my-5">
                                {/* <NavLink className="text-white" to={{
                                    pathname: `/configureMenu/${this.props.match.params.id}`,
                                    state: {
                                        orgId: this.props.location.state?.orgId,
                                    }
                                }}> */}
                                <button className="border-0 bg-transparent cursor-pointer" type="button" onClick={this.onSubmit}>
                                    <img src={refresh} alt="" />
                                </button>
                                {/* </NavLink> */}
                            </div>

                            {/* Terms and Services Modal */}
                            <Modal centered show={this.state.openTermsModal} dialogClassName="social-media-modal">
                                <Modal.Body>
                                    <TermsConditions accept={() => { this.setState({ openTermsModal: false, acceptCheck: true }) }} onClose={() => { this.setState({ openTermsModal: false, acceptCheck: false }) }} terms={this.getTermsData?.rule} />
                                </Modal.Body>
                            </Modal>
                            {/* Prize Distribution Modal */}
                            <Modal centered show={this.state.openPrizeModal} dialogClassName="merchandise-modal">
                                <Modal.Body>
                                    <Merchandise merchandiseDetails={this.state.merchandiseDetails} onAdd={this.addMerchandise} onUpdate={this.updateMerchandise} onClose={() => { this.setState({ openPrizeModal: false }) }} updateLoader={this.props.updateLoader} />
                                </Modal.Body>
                            </Modal>
                            {/* Confirmation Modal */}
                            <Modal centered show={this.state.toggleConfirm} dialogClassName="social-media-modal">
                                <Modal.Body>
                                    <ConfirmationModal confirm={this.state.confirm}
                                        onClickOpenConfirm={() => { this.setState({ toggleConfirm: false }) }} confirmed={this.confirmed} />
                                </Modal.Body>
                            </Modal>
                        </div>
                    }
                </div>
            </section>
        )
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(EntryFeePrizeDetails)
