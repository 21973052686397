import React, { Component } from 'react';
import { ellipsis } from '../../../../utils/common.utils';
import Image from '../../../../components/common/Image'
//images
import orgIc from "../../../../images/icon/org-ic.png";
import crown from "../../../../images/score/escrown.png";
import star from "../../../../images/score/star.png";

export default class SoloDuelStandings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leaderBoardType: props.leaderBoardType,
            participant: props.participant,
            tournamentDetails: props.tournamentDetails,
            key: props.index
        }
        //this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {
        //getLeaderBoardDetails(this.props.tournamentDetails._id, this.setLeaderBoardDetails)
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            leaderBoardType: newProps.leaderBoardType,
            participant: newProps.participant,
            isTeamGame: newProps.isTeamGame,
            key: newProps.index
        })
    }

    getData = () => {
        const { participant, key } = this.state
        if (this.state.leaderBoardType === 'STAGE') {
            return (
                <div className='col-6 my-1' key={key}>
                    <div className='participant-card p-1'>
                        <div className='row'>
                            <div className='col-6 d-flex flex-column pe-0'>
                                <div className='d-flex'>
                                    <p className='fs-10 fw-bold me-2'>#{key + 1}</p>
                                    {/* <img src={participant.participantDetails?.profilePicture?.url} alt='' className='rounded-circle' style={{ height: '25px', width: '25px' }} /> */}
                                    <Image src={participant.participantDetails?.profilePicture?.url} param={participant.participantDetails?.inGameName} className='rounded-circle' height='25px' width='25px' id={key} />
                                    <div>
                                        <p className='fs-10 fw-600 my-0'>{ellipsis(participant.participantDetails?.inGameName, 9)}</p>
                                        <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>Kills: {participant.kills}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-column'>
                                    {/* <p className='fs-10 fw-600 my-0 text-success'>#Qualified</p> */}
                                    <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>Matches Won-{participant.noOfGamesWonCount}</p>
                                </div>
                            </div>
                            <div className='col-6 ps-0 text-end'>
                                <p className='fs-8 fw-600 my-0'>{`K/D :: ${participant.kills}/${participant.deaths}`}</p>
                                {/* <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>R-{tournamentDetails.gameDetails.pointSystem[i]} + K-{participant.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='col-6 my-1' key={key}>
                    <div className='participant-card p-1'>
                        <div className='row'>
                            <div className='col-1 d-flex flex-column me-2'>
                                <p className='fs-14 fw-700 my-0' style={{ color: key == 0 ? '#FFE420' : 'white' }}>#{key + 1}</p>
                                <img src={crown} alt='' style={{ height: '12px', width: '12px', display: key == 0 ? 'block' : 'none' }} />
                            </div>
                            <div className='col-5 d-flex px-0'>
                                <Image src={participant.participantDetails?.profilePicture?.url} param={participant.participantDetails?.inGameName} className='rounded-circle me-2' height='25px' width='25px' id={key} />
                                {/* <img src={participant?.participantDetails?.profilePicture ? participant?.participantDetails?.profilePicture?.url : orgIc} alt='' className='rounded-circle me-2' style={{ height: '25px', width: '25px' }} /> */}
                                <div className='d-flex flex-column'>
                                    <p className='fs-10 fw-600 my-0'>{ellipsis(participant.participantDetails.name, 9)}</p>
                                    <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>Kills: {participant.kills}</p>
                                </div>
                            </div>
                            <div className='col-4 pe-0'>
                                <p className='fs-8 fw-600 my-0'>{`K/D :: ${participant.kills}/${participant.deaths}`}</p>
                                {/* <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>R-{tournamentDetails.gameDetails.pointSystem[i]} + K-{participant.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        //console.log('props ::', this.props);
        return this.getData();
    }
}