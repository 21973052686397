import logo from '../../logo.svg';
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Login from '../auth/Login';
import SignUp from '../auth/SignUp';
import ActivateAccount from '../auth/ActivateAccount';
import ForgotPassword from '../auth/ForgotPassword';
import SignUpPolicies from '../auth/SignUpPolicies';
import { BACKGROUNDS } from '../../utils/Images.utils';

const resetState = {
    openLogin : false,
    openSignUp : false,
    openForgetPassword : false,
    openActivateAccount : false,
    openSignUpPolicies: false
};

class Unauthorized extends Component {
    constructor(props) {
        super(props);
        this.state = { ...resetState};
    }

    componentDidMount(){
        this.setState({
            openLogin : true
        });
    }

    onClickOpenSignUp =()=>{
        this.setState({ ...resetState, openSignUp: true});
    }

    onClickOpenLogin =()=>{
        this.setState({ ...resetState, openLogin: true});
    }

    onClickOpenActivateAccount=()=>{
        this.setState({ ...resetState, openActivateAccount: true});
    }

    onClickOpenForgotPassword=()=>{
        this.setState({ ...resetState, openForgetPassword: true});
    }
    
    onClickCloseLogin=()=>{
        this.setState({ ...resetState});
        this.props.history.push("/");
        // if(this.props.match.params.id){
        //     this.props.history.push('/tournaments');
        // }else{
        //     this.props.history.push("/");
        // }
    }

    onClickCloseSignUp=()=>{
        this.setState({ ...resetState, openLogin: true});
    }
    
    onClickCloseActivateAccount=()=>{
        this.setState({ ...resetState});
    }

    onClickCloseForgotPassword=()=>{
        this.setState({ ...resetState, openLogin: true});
    }

    openClosePoliciesPopup = (param) => {
        if(param){
            this.setState({...resetState,  openSignUpPolicies: param})
        }else{
            this.onClickCloseSignUp();
        }
    }

    resetAndUpdateFlag = (reset, key, value) => {
        if (reset) {
            this.setState({ ...resetState, [key]: value });
        } else {
            this.setState({ [key]: value });
            //this.props.history.push("/");
        }
        if(this.props.match.params.id){
            this.props.history.push("/tournaments");
        }
    }

    render() {
        console.log('Unauthorized props ::', this.props);
        return(
        <div className="App">
            <header className="App-header column-center-align-div">
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <img src={BACKGROUNDS.UNAUTH} alt='' className="d-block d-lg-none" style={{width:'80%'}} />
            <img src={BACKGROUNDS.UNAUTH_DESKTOP} alt='' className="d-none d-lg-block" style={{width:'70%'}} />
            <p className="fs-24 fs-lg-24 fw-600 text-center" style={{color:'#FF6060'}}>Access Denied</p>
            <p className="fs-18 fs-lg-14 fw-500 text-center" style={{color:'#FFBFBF'}}>You currently does not have access to this page.Login is required to visit this page</p>
            <Button onClick={ () => this.onClickOpenLogin(true) }>Login</Button>
            </header>
            
            <Modal show={this.state.openLogin} centered dialogClassName="login-modal">
                <Modal.Body>
                    <Login openLogin={this.state.openLogin}
                        onClickOpenSignUp={this.onClickOpenSignUp}
                        onClickCloseLogin={this.onClickCloseLogin}
                        onClickOpenForgotPassword={this.onClickOpenForgotPassword}
                        updateLoader={this.props.updateLoader}
                        resetAndUpdateFlag={this.resetAndUpdateFlag}
                        saveToLocalStorage={this.props.saveToLocalStorage} />
                </Modal.Body>
            </Modal>

            <Modal show={this.state.openSignUp} centered dialogClassName="signUp-modal">
                <Modal.Body>
                    <SignUp openSignUp={this.state.openSignUp}
                        onClickCloseSignUp={this.onClickCloseSignUp}
                        onClickOpenActivateAccount={this.onClickOpenActivateAccount}
                        onClickOpenLogin={this.onClickOpenLogin}
                        saveToLocalStorage={this.props.saveToLocalStorage}
                        updateLoader={this.props.updateLoader}
                        resetAndUpdateFlag={this.resetAndUpdateFlag}
                        openPoliciesPopup={(e)=>this.openClosePoliciesPopup(true)}
                        />
                </Modal.Body>
            </Modal>

            <Modal show={this.state.openActivateAccount} centered dialogClassName="activate-account-modal">
                <Modal.Body>
                    <ActivateAccount openActivateAccount={this.state.openActivateAccount}
                        onClickCloseActivateAccount={this.onClickCloseActivateAccount}
                        onClickBackActivateAccount={this.onClickCloseActivateAccount}
                        resetAndUpdateFlag={this.resetAndUpdateFlag}
                        onClickOpenSignUp={this.onClickOpenSignUp} />
                </Modal.Body>
            </Modal>

            <Modal show={this.state.openForgetPassword} centered dialogClassName="forgot-password-modal">
                <Modal.Body>
                    <ForgotPassword openForgetPassword={this.state.openForgetPassword}
                        onClickCloseForgotPassword={this.onClickCloseForgotPassword}
                        backToLogin={this.onClickCloseForgotPassword}
                        resetAndUpdateFlag={this.resetAndUpdateFlag}
                        onClickOpenVerifyAccount={this.onClickOpenVerifyAccount} />
                </Modal.Body>
            </Modal>

            <Modal show={this.state.openSignUpPolicies} centered dialogClassName="sign-up-policies">
                <Modal.Body>
                    <SignUpPolicies 
                    socialMediaType = {global.socialMediaType}
                    resetAndUpdateFlag={this.resetAndUpdateFlag}
                    closePoliciesPopup={(e)=>this.openClosePoliciesPopup(false)} />
                </Modal.Body>
            </Modal>

        </div>
        );
    }
}

export default Unauthorized;
