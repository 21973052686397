import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import participantAvtar from "../../../images/participantAvtar.jpg";
import { NavLink } from "react-router-dom";
import formInfoIc from "../../../images/icon/formInfo-ic.png";

import "../../../styles/Tournaments.css";
import TournamentHeader from "./common/TournamentHeader";
import participants from "../../../images/icon/participants.png";
import { errorHandler, getTournamentData } from "../../../utils/common.utils";
import TournamentApis from "../../../helper/tournament.api";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { HiUserGroup } from "react-icons/hi";
import ParticipantDetails from "../../popup/ParticipantDetails";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';

class TournamentParticipant extends Component {
  constructor(props) {
    super(props);
    this.unfilterList = []
    this.tournamentApis = new TournamentApis();
    this.state = {
      tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : {},
      participantList: [],
      openDetailsModal: false,
      detailsModalObj: {}
    };
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
    if (!this.props?.tournamentData?._id) {
      getTournamentData(this.props.match.params.id, this.setData)
    }
    this.getParticipant()
  }

  // when data not came from location.state it returns data from API
  setData = (values) => {
    if (values) {
      this.setState({
        tournamentDetails: values,
      })
      this.props.dispatchData(values)
    } else {
      this.props.history.push('/pageNotFound')
    }
  }

  getParticipant = () => {
    this.props.updateLoader(true);
    this.tournamentApis.getParticipantsOfTournament(this.props.match.params.id).then(
      (res) => {
        if (res.data.result?.length) {
          this.setState({
            participantList: res.data.result
          })
          this.unfilterList = res.data.result
        }

        this.props.updateLoader(false);
      }
    ).catch(
      (err) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }
  onSearchChange = (e) => {
    this.setState({ search: e.target.value })
    const unfilteredData = this.unfilterList;
    let data = unfilteredData.filter(data =>
      data.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    this.setState({ participantList: data })
    // if(!e.target.value){
    //     this.setState({ selectedMember: {} })
    // }
  }
  showDetails = (data) => {
    this.setState({
      detailsModalObj: data,
      openDetailsModal: true
    })
  }
  render() {
    return (
      <>
        <div className="body-section clanView-page tournament-participant espotz-form-control">
          <div className="container p-3 mt-lg-3">
            <TournamentHeader
              tournamentDetails={this.state.tournamentDetails}
              logo={participants}
              heading="Participants"
              subHeading={null}
              history={this.props.history}
            />

            <div className="row mt-3">
              {
                this.state.participantList?.length ?
                  <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12">
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder={this.state.tournamentDetails.participantType == 'TEAM' ? "Search Team..." : "Search Player..."}
                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45 flex-grow-1 ms-3"
                        onKeyUp={(e) => { this.onSearchChange(e) }}
                      />
                      <FaSearch className="btn-size-20 position-relative" style={{ left: '-35px' }} />
                    </div>
                  </div>
                  :
                  <div className="text-center fw-500 mt-4">
                    Participants yet to join
                  </div>
              }
            </div>

            {
              this.state.tournamentDetails?.participantType == 'TEAM' ?
                <div className="row pt-3">
                  {/* Search by Team */}
                  {
                    this.state.participantList?.map((el, i) => {
                      return (
                        <div className="col-lg-4 col-md-6 col-12 mb-2" key={i}>
                          <div className="mulberry-purple-card d-flex align-content-center align-items-center py-3">
                            <div className="col-2">
                              <img src={el?.logo?.url} className="rounded ms-3" width={'45px'} height={'45px'} alt="" />
                            </div>
                            <div className="col-8 ps-4">
                              <h6 className="fs-16 fw-bold mb-0 text-truncate">{el?.name}</h6>
                              <p className="fs-14 fw-600 text-white-50 mb-1"><HiUserGroup className="fs-16 me-2 mb-1" />{el.members?.players?.length}</p>
                              {/* <span className="avatar">
                                  <img
                                    src={participantAvtar}
                                    style={{ width: "30px", height: "30px" }}
                                    alt=""
                                  />
                                  <img
                                    src={participantAvtar}
                                    style={{ width: "30px", height: "30px" }}
                                    alt=""
                                  />
                                </span> */}
                              {/* <span className="avatar d-inline-flex">
                                            <p>+{this.state?.clanData?.members?.owner?.length - 4}</p>
                                        </span> */}
                            </div>
                            <div className="col-2">
                              <span
                                className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32 participant-round-btn"
                                onClick={() => { this.showDetails(el) }}
                              >
                                <img src={formInfoIc} className="rounded-none" style={{ width: "15px" }} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </div>
                :
                <div className="row pt-3">
                  {/* Search by Participant */}
                  {this.state.participantList?.map((el, i) => (
                    <div className="col-lg-4 col-md-6 col-12 mb-2" key={i}>
                      <div
                        className="mulberry-purple-card d-flex align-content-center align-items-center py-2">
                        <div className="col-2">
                          <img src={el?.profilePicture?.url} className="rounded-circle ms-3" width={'40px'} height={'40px'} alt="" />
                        </div>
                        <div className="col-8">
                          <h6 className="ms-3 mt-2 text-truncate">{el?.inGameName}</h6>
                        </div>
                        <div className="col-2">
                          <span className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-32 participant-round-btn"
                            onClick={() => { this.showDetails(el) }} >
                            <img src={formInfoIc} className="rounded-none" width={'15px'} alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
            }
          </div>
        </div>
        <Modal show={this.state.openDetailsModal} centered dialogClassName="Participant-details-modal">
          <Modal.Body>
            <ParticipantDetails updateLoader={this.props.updateLoader} details={this.state.detailsModalObj} type={this.state.tournamentDetails.participantType} onClose={() => { this.setState({ openDetailsModal: false }) }} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default (connect(mapStateToProps, mapDispatchToProps))(TournamentParticipant)