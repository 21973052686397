import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from 'react-redux';

//images, icons
import { RiBankLine } from 'react-icons/ri';
import { BsCreditCard2Back } from 'react-icons/bs'
import { ImArrowDownLeft2, ImArrowUpRight2 } from 'react-icons/im';
import { AiFillCloseCircle, AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai';
import { IoIosCloseCircleOutline, IoIosArrowForward } from "react-icons/io";
import noData from '../../images/clip-arts/noRecordFound.png';
//components
import { profile } from '../../utils/localstorage.utils';
import WalletApi from '../../helper/wallet.api';
import { AxiosResponse, AxiosError } from "axios";
import { dateFormat, errorHandler, getCommissionByType, mapDispatchToPropsEC, mapStateToPropsEC, roundOffNumber, updateLoader } from '../../utils/common.utils';
import TransactionDetails from '../popup/TransactionDetails';

import "../../styles/wallet.css";
import { toast } from 'react-toastify';
import { createAndSendNotification } from '../../utils/notification.utils';
import { ASSETS } from '../../utils/Strings.utils';
import CommonApis from '../../helper/common.api';
import { makeWalletPayment } from '../../utils/Wallet.utils';
import { MdOutlineContentCopy } from 'react-icons/md';
import { COMMISSION_TYPES } from '../../utils/enums.utils';
import Pagination from 'react-js-pagination';

class ClanOrOrgWallet extends Component {
    authUser = profile()
    walletApi = new WalletApi()
    constructor(props) {
        super(props);
        this.state = {
            openTransactionModal: false,
            openPaymentModal: false,
            openPaymentBarcode: false,
            openPaymentOptionModal: false,
            openPaymentCardModal: false,
            transactionHistory: [],
            transactionType: 'All Transactions',
            type: '',
            balance: null,
            transactionDetails: null,
            totalResults: null,
            uploadScreenshot: false,
            screenshotImage: null,
            UPI: '950xxxxx46@ybl'
        };
        this.params= {
            pageNo:0,
            limit:30,
            transactionType: 'All Transactions'
        }
        
        this.getMyWalletInfo(this.props.match.params.id);

        this.commonApi = new CommonApis();
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    getMyWalletInfo = (id) => {
        const params = this.params;
        if(params.transactionType === 'All Transactions'){
          delete params.transactionType;
        }
        updateLoader(true)
        this.walletApi.getWalletInfo1(id,params).then(
            (res) => {
                this.setState({
                    transactionHistory: res.data.result.transactionHistory,
                    balance: res.data.result.balance,
                    totalResults: res?.data?.totalResults
                })
                setTimeout(() => updateLoader(false), 1000);;
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    onSelectTransactionType = (e) => {
        this.setState({ transactionType: e.target.name })
        this.params.transactionType = e.target.name;
        this.params.pageNo = 0
        // if (e.target.name !== 'All Transactions'){
        //     this.getMyWalletInfo(this.props.match.params.id, e.target.name);
        // }else{
        //     this.getMyWalletInfo(this.props.match.params.id)
        // }
        this.getMyWalletInfo(this.props.match.params.id)
    }

    walletDeposit = (data) => {
        
        updateLoader(true)
        this.walletApi.walletDeposit(data).then(
            (res) => {
                this.props.dispatchData(res.data.result.balance)
                this.setState({
                    transactionHistory: res.data.result.transactionHistory,
                    balance: res.data.result.balance
                });
                this.sendNotifications('deposit',data.depositAmount)
                setTimeout(() => updateLoader(false), 1000);
                toast.success('Amount deposited successfully');
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    walletWithdraw = (data) => {
        updateLoader(true)
        this.walletApi.walletWithdraw(data).then(
            (res) => {
                this.props.dispatchData(res.data.result.balance)
                this.setState({
                    transactionHistory: res.data.result.transactionHistory,
                    balance: res.data.result.balance
                });
                this.sendNotifications('withdrawn',data.withdrawAmount);
                setTimeout(() => updateLoader(false), 1000);
                toast.success('Amount withdrawn successfully');
            }
        ).catch(
            (err) => {
                updateLoader(false);
                //errorHandler(err, this.props.logout);
                this.getMyWalletInfo(this.props.match.params.id);
                toast.warning('Insufficient balance to complete the transaction');
            }
        )
    }

    onSubmit = (val) => {
        const details = { userId: this.authUser?.id }
        if (this.state.type === 'DEPOSIT') {
            if(val.amount > 10000000){
                toast.warning('You cannot deposit more than 10,000,000');
                return false;
            }
            if(val.amount + this.state.amount > 100000000){
                toast.warning(`Your can deposit a maximum of ${100000000 - this.state.balance} as max wallet limit is 100,000,000`);
                return false;
            }
            details.depositAmount = val.amount;
            this.setState({ openPaymentModal: false })
            this.walletDeposit(details);
        }
        else {
            details.withdrawAmount = val.amount;
            this.setState({ openPaymentModal: false })
            this.walletWithdraw(details);
        }
    }

    toggleTransactionModal = () => {
        this.setState({ openTransactionModal: false })
    }

    initializePayment = (type) => {
        // console.log(`type = ${type} and balance===${this.state.balance}`)
        if(type === 'DEPOSIT' && this.state.balance >= 100000000) {
            toast.error('Wallet balance has reached its limit.');
            return false;
        }
        this.setState({ openPaymentModal: true, type})
    }

    sendNotifications = (type,amount) => {
        var notification;

        if(type === 'deposit'){
            notification = {
                type : "WALLET",
                heading : "EC has been deposited 💸",
                message : `\n ${amount} EC is successfully deposited in your Espotz Wallet. Please click here to check your balance.`,
                link : `/wallet`,
            };
        }else{
            notification = {
                type : "WALLET",
                heading : "EC has been withdraw 💸",
                message : `\n ${amount} EC is successfully withdrawn from your Espotz Wallet. Please click here to check your balance.`,
                link : `/wallet`,
            };
        }
        createAndSendNotification(notification,[profile().id]);
    }

    triggerInputFile = () => this.fileInput.click();

    // Upload image
    onImageChange = (event, type) => {
        event.preventDefault();

        this.setState({
        invalidImage: null,
        });

        //type : screenshotImage
        if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
            toast.warning('Please select a valid image type.');
            return false;
        }
        this.setState({
            [type]: URL.createObjectURL(img), //loader image
        });

        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        this.props.updateLoader(true)
        this.commonApi.uploadSingleImg(formData).then((response) => {
            this.props.updateLoader(false)
            this.setState({
            [type]: response.data.result, //
            reqErrorMsg: null,
            });
        }).catch((err) => {
            errorHandler(err, this.props.logout);
            this.setState({
            [type]: null,
            });
            this.props.updateLoader(false)
        }); //set null to image and display error message in toast
        }
    }

    submitPaymentDetails = () =>{
        let isClan = this.props.match.path.includes('clan');
        var data = {
            parentId: this.props.match.params.id, 
            type: isClan ? "CLAN" : "ORG",
            fromUserId: this.authUser?.id,
            screenshot: this.state.screenshotImage
        }

        makeWalletPayment(data,(result)=>{
            toast.success("Your payment details has been submitted. Please wait for admin to approve.");
            this.setState({
                uploadScreenshot: false,
                screenshotImage: null,
                openPaymentBarcode: false
            });
            console.log("@@@@@@@@  submitPaymentDetails :: ",result)
        });

    }

    copyLinkToClipBoard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(this.state.UPI);
        toast.success("UPI ID has been copied to the clipboard.");
    }

    handlePageChange(pageNumber) {
        //console.log(`pageNumber is ${pageNumber}`);
        this.params.pageNo = pageNumber-1;
        this.getMyWalletInfo(this.authUser?.id)
    }

    render() {
        const commissionValues = getCommissionByType(COMMISSION_TYPES.WALLET);
        //console.log("commissionValues :: ",commissionValues)
        return (
            <section className='body-section wallet-page ec-background'>
                <div className='container mb-4'>
                    <div className='row'>
                        <div className='col-12 mt-3'>
                            <p className='fs-14 fw-bold text-white-50 text-end'>1 INR = 1 EC</p>
                        </div>
                        <div className='col-12 '>
                            <h1 className='fs-30 fw-bold text-white-50 text-center'>{this.state.balance ? this.state.balance?.toFixed(2) : this.state.balance} EC</h1>
                            <h1 className='fs-14 fw-bold text-white-50 text-center'>BALANCE</h1>
                        </div>

                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center my-3'>
                                <div>
                                    <h1 className='fs-16 fw-600 text-white mb-0' >Transactions</h1>
                                </div>
                                <div>
                                    <Dropdown className="select-game-dropdown">
                                        <Dropdown.Toggle className='dropdown-basic w-100 text-start ps-3'>
                                            {this.state.transactionType}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu onClick={(e) => this.onSelectTransactionType(e)}>
                                            <Dropdown.Item name="All Transactions" className="text-white">All Transactions</Dropdown.Item>
                                            <Dropdown.Item name="DEPOSIT" className="text-white">Deposit</Dropdown.Item>
                                            {/* <Dropdown.Item name="Deposit Failed" className="text-white">Deposit Failed</Dropdown.Item> */}
                                            <Dropdown.Item name="WITHDRAW" className="text-white">Withdraw</Dropdown.Item>
                                            {/* <Dropdown.Item name="Withdraw Failed" className="text-white">Withdraw Failed</Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.transactionHistory?.length ?
                        <div className='row '>
                            {this.state.transactionHistory?.map((transaction, i) => {
                                // console.log('Transaction ::', transaction)
                                if (transaction.transactionStatus === 'SUCCESS') {
                                    return (
                                        <div className='col-lg-8 offset-lg-2 col-12 mt-2' key={i}>
                                            <div className='purple-field border-0 text-white' onClick={() => { this.setState({ openTransactionModal: true, transactionDetails: transaction }) }}>
                                                <div className='row'>
                                                    <div className='col-2 align-self-center text-end text-md-center text-lg-center'>
                                                        {transaction.transactionType === 'DEPOSIT' ?
                                                            <ImArrowDownLeft2 className='fs-20 text-success' />
                                                            :
                                                            <ImArrowUpRight2 className='fs-20 text-warning' />
                                                        }
                                                    </div>
                                                    <div className='col-6 py-2'>
                                                        <h1 className='fs-16 fw-600 text-white mb-0'>{transaction.transactionType}</h1>
                                                        <p className='fs-14 fw-600 text-white-50 mb-0'>{dateFormat(transaction.transactionDateAndTime)}</p>
                                                    </div>
                                                    <div className='col-4 align-self-center'>
                                                        <p className={`fs-14 fw-600 mb-0 ${transaction.transactionType === 'DEPOSIT' ? 'text-success' : 'text-warning'}`}>{transaction.transactionAmount.toFixed(2)} EC</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='col-lg-8 offset-lg-2 col-12 mt-2 mt-lg-3' key={i}>
                                            <div className='purple-field border-0 text-white' onClick={() => { this.setState({ openTransactionModal: true, transactionDetails: transaction }) }}>
                                                <div className='row'>
                                                    {/* <div className='col-2 align-self-center text-end'><AiOutlineClose className='fs-20 text-white-50' /></div> */}
                                                    <div className='col-2 align-self-center text-end text-md-center text-lg-center'>
                                                        <AiOutlineClose className='fs-20 text-danger' />
                                                    </div>
                                                    <div className='col-6 py-2'>
                                                        <h1 className='fs-16 fw-600 text-white mb-0'>{transaction.transactionType}</h1>
                                                        <p className='fs-14 fw-600 text-white-50 mb-0'>{dateFormat(transaction.transactionDateAndTime)}</p>
                                                    </div>
                                                    <div className='col-4 align-self-center'>
                                                        <p className='fs-14 fw-600  mb-0 text-danger'>{transaction.transactionAmount.toFixed(2)} EC</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        :
                        <div className="text-center">
                            <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                        </div>
                    }

                    { (this.state.totalResults > this.params.limit) ? <Pagination activePage={this.params.pageNo+1} itemsCountPerPage={this.params.limit} totalItemsCount={this.state.totalResults} pageRangeDisplayed={5} onChange={this.handlePageChange.bind(this)} className='mt-2' /> : null }


                    <div className="d-flex justify-content-center">
                        <div className='position-fixed d-flex justify-content-center mt-3' style={{ bottom: '60px' }}>
                            {/* <button type="submit" className="white-flat-btn d-block fw-bold py-2 px-3 mx-4 rounded-15" onClick={()=>this.initializePayment('DEPOSIT')}>
                                Deposit
                            </button> */}
                            <button type="submit" className="white-flat-btn d-block fw-bold py-2 px-3 mx-4 rounded-15" onClick={()=>this.setState({openPaymentBarcode: true})}>
                                Deposit
                            </button>
                            <button type="button" className="white-flat-btn d-block fw-bold py-2 px-3 mx-4 rounded-15" onClick={()=>this.initializePayment('WITHDRAW')}>
                                Withdraw
                            </button>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.openTransactionModal} centered dialogClassName="transaction-details-modal">
                    <Modal.Body>
                        <TransactionDetails
                            toggleTransactionModal={this.toggleTransactionModal}
                            transactionDetails={this.state.transactionDetails}
                        />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openPaymentBarcode} centered dialogClassName="payment-barcode-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => { this.setState({ openPaymentBarcode: false, screenshotImage: null, uploadScreenshot:false}) }} />
                            {!this.state.uploadScreenshot ?
                                <div>
                                <p className="fs-20 fw-bold text-center text-white mb-3">Scan Me</p>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <img className='' style={{width:'70%'}} src={'https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/f1c59ca0-64a3-11ed-8b7e-75af81bee07e'} alt="" />
                                    <div className='d-flex justify-content-center align-items-center' style={{width: '70%'}}>
                                        <hr className='text-white me-2' style={{width: '45px'}} />
                                        <p className='fs-14 fw-600 text-white my-0'>OR</p>
                                        <hr className='text-white ms-2' style={{width: '45px'}} />
                                    </div>
                                    <label className="text-white fw-bold me-auto">UPI ID:</label>
                                    <div className="d-flex align-items-center purple-field w-100 border-0 ps-3 text-white height-45">
                                        <input type="text" className="" value={this.state.UPI}   disabled
                                            style={{background: 'transparent', outline: 'none', border: 'none'}}
                                        />
                                        <MdOutlineContentCopy className="text-white ms-auto me-3" onClick={(e) => this.copyLinkToClipBoard(e)}/>
                                    </div>
                                    <button type="submit" onClick={()=>this.setState({uploadScreenshot: true})} className="white-flat-btn d-block fw-bold py-2 px-3 m-auto mt-4 mb-4 rounded-15" > Upload Screenshot </button>
                                </div>
                                
                                
                            </div>
                            :
                            <div>
                                <p className="fs-20 fw-bold text-center text-white mb-3">Upload Screenshot</p>
                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden ref={(fileInput) => (this.fileInput = fileInput)} onChange={(e) => this.onImageChange(e, "screenshotImage")} name="" className="image-input" />
                                <div className='d-flex flex-column align-items-center'>
                                    {this.state.screenshotImage ? 
                                        
                                            
                                        <div className="d-flex" style={{width: '80%'}}>
                                            <div className="upload-banner w-100 d-flex flex-column align-items-center justify-content-center mx-auto">
                                                <div className='d-flex flex-column'>
                                                    <div className="d-flex" style={{ marginBottom: '-10px', marginRight: '-10px', zIndex: 1 }}>
                                                        <AiFillCloseCircle className="btn-size-20 text-white ms-auto" onClick={(e) => this.setState({screenshotImage:null})} />
                                                    </div>
                                                    <div className="after-uploaded-image" onClick={this.triggerInputFile} >
                                                    <img src={this.state?.screenshotImage?.url} alt="" style={{width:'100%'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    : 
                                        <div className="before-uploaded-panel d-flex" style={{height: '200px', width: '80%'}}>
                                            <div className="upload-banner w-100 d-flex flex-column align-items-center justify-content-center mx-auto">
                                                <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile} >
                                                <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Screenshot</h1>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.screenshotImage ?
                                    <button type="submit" className="white-flat-btn d-block fw-bold py-2 px-3 m-auto mt-4 mb-4 rounded-15" onClick={()=>this.submitPaymentDetails()} > Submit </button>
                                    :
                                    <button type="submit" className="white-flat-btn d-block fw-bold py-2 px-3 m-auto mt-4 mb-4 rounded-15" onClick={()=>this.setState({uploadScreenshot: false})} > Back </button>
                                    }

                                </div>
                            </div>
                            }
                            <p className='text-white-50 text-center fw-600'>{commissionValues?.questions?.ESPOTZ_COMMMISSION_ON_DIEW}% of amount deposited will be charged as processing fees and rest will be credited into your Espotz Wallet.</p>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openPaymentModal} centered dialogClassName="payment-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => { this.setState({ openPaymentModal: false }) }} />
                            <p className="fs-20 fw-bold text-center text-white mb-3">{this.state.type === 'DEPOSIT' ? 'Deposit' : 'Withdraw'} EC</p>
                            <Formik
                                innerRef={this.formikRef}
                                initialValues={{
                                    amount: '',
                                }}
                                validationSchema={Yup.object({
                                    amount: Yup.number().required('Enter amount').min(0, 'Cannot be negative'),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.onSubmit(values)
                                        setSubmitting(false);
                                    }, 400);
                                }} >

                                <Form className="mx-2 mt-3" autoComplete="off">
                                    <div className="mt-2">
                                        <Field name="amount" type="number" 
                                            className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                            placeholder="Enter Amount"
                                            min={1} step={1} onWheel={(e) => e.target.blur()}
                                        />
                                        <ErrorMessage component="span" className="error-msg" name="amount" />
                                    </div>

                                    <button type="submit" className="white-flat-btn d-block fw-bold py-2 px-3 m-auto mt-2 rounded-15" > {this.state.type === 'DEPOSIT' ? 'Deposit' : 'Withdraw'} </button>
                                </Form>
                            </Formik>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openPaymentOptionModal} centered dialogClassName="payment-option-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => { this.setState({ openPaymentOptionModal: false }) }} />
                            <p className="fs-20 fw-bold text-center text-white mb-3">Payment Options</p>

                            {/* <p className="fs-14 fw-600 text-white text-center">Waiting for the payment gateway</p> */}

                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row mb-2'>
                                        <div className='col-7'>
                                            <p className="fs-14 fw-600 text-white-50 text-start">Amount to be added</p>
                                        </div>
                                        <div className='col-5'>
                                            <p className="fs-14 fw-600 text-white-50 text-end">$ 500</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-2'>
                                    <p className="fs-14 fw-600 text-white-50 text-start mb-1">UPI</p>
                                    <div className='row'>
                                        <div className='col-2 text-center'>
                                            <img src="https://img.icons8.com/color/48/000000/google-pay-india.png" className='btn-size-25' alt='' />
                                        </div>
                                        <div className='col-8'>
                                            <p className="fs-14 fw-600 text-white-50 text-start mb-1">Google Pay</p>
                                        </div>
                                        <div className='col-2'>
                                            <IoIosArrowForward className='text-white-50 fs-20' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-2 text-center'>
                                            <img src="https://img.icons8.com/color/50/000000/phone-pe.png" className='btn-size-25' alt='' />
                                        </div>
                                        <div className='col-8'>
                                            <p className="fs-14 fw-600 text-white-50 text-start mb-1">Phone Pe</p>
                                        </div>
                                        <div className='col-2'>
                                            <IoIosArrowForward className='text-white-50 fs-20' />

                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 mt-2'>
                                    <p className="fs-14 fw-600 text-white-50  text-start mb-1">Debit/Credit Card</p>
                                    <div className='row'>
                                        <div className='col-2 text-center'>
                                            <BsCreditCard2Back className='text-white-50 fs-20' />
                                        </div>
                                        <div className='col-8'>
                                            <p className="fs-14 fw-600 text-white-50 text-start mb-0">Add New Card</p>
                                        </div>
                                        <div className='col-2'>
                                            <IoIosArrowForward className='text-white-50 fs-20' />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 mt-2'>
                                    <p className="fs-14 fw-600 text-white-50 text-start mb-1">Other Option</p>
                                    <div className='row'>
                                        <div className='col-2 text-center'>
                                            <RiBankLine className='text-white-50 fs-20' />
                                        </div>
                                        <div className='col-8'>
                                            <p className="fs-14 fw-600 text-white-50 text-start mb-0" onClick={() => { this.setState({ openPaymentCardModal: true, openPaymentOptionModal: false }) }}>Net Banking</p>
                                        </div>
                                        <div className='col-2'>
                                            <IoIosArrowForward className='text-white-50 fs-20' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openPaymentCardModal} centered dialogClassName="payment-card-modal">
                    <Modal.Body>
                        <div className='p-3'>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px' }} onClick={() => { this.setState({ openPaymentCardModal: false }) }} />
                            <p className="fs-20 fw-bold text-center text-white mb-3">Add New Card</p>

                            <Formik
                                innerRef={this.formikRef}
                                initialValues={{
                                    cardNumber: '',
                                    expiry: '',
                                    cvv: ''
                                }}

                                validationSchema={Yup.object({
                                    cardNumber: Yup.string().required("Card number is required").matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only alphabets allowed.'),
                                    expiry: Yup.string().required("Expiry Date is required").matches(/^[a-zA-Z0-9]*$/g, 'Only alpha numerics without any spaces'),
                                    cvv: Yup.string().required("CVV required").matches(/^[0-9]*$/g, 'Only numerics allowed').min(3, "Must be of length 3").max(3, "Must be of length 3"),
                                })}

                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {

                                    }, 400);
                                }} >

                                <Form className="row mt-3" autoComplete="off">
                                    <div className="col-12 my-2">
                                        <label htmlFor="cardNumber" className="fs-14 fw-600 text-white">Enter Card Number</label>
                                        <Field name="cardNumber" type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45" placeholder="Enter Card Number" />
                                        <ErrorMessage component="span" className="error-msg" name="cardNumber" />
                                    </div>

                                    <div className="col-6 my-2">
                                        <label htmlFor="expiry" className="fs-14 fw-600 text-white">Expiry (MM/YY)</label>
                                        <Field name="expiry" type="date" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45" placeholder="Enter Date" />
                                        <ErrorMessage component="span" className="error-msg" name="expiry" />
                                    </div>
                                    <div className="col-6 my-2">
                                        <label htmlFor="cvv" className="fs-14 fw-600 text-white">Enter CVV</label>
                                        <Field name="cvv" type="text" className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45" placeholder="Enter CVV" />
                                        <ErrorMessage component="span" className="error-msg" name="cvv" />
                                    </div>

                                    <p className='fs-12 fw-600 text-white-50 mb-0'>Your CVV will not be saved</p>
                                    <div className="col-6 offset-3 my-2">
                                        <button type="submit" className="white-flat-btn fw-bold py-1 px-3 m-auto mt-3 d-block border-0" > Proceed </button>

                                    </div>
                                </Form>
                            </Formik>


                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}
export default (connect(mapStateToPropsEC, mapDispatchToPropsEC))(ClanOrOrgWallet)
