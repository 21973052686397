import React from 'react';
import TeamApis from "../helper/teams.api";
import { errorHandler, updateLoader } from './common.utils';

export const getTeamMembers = (teamData) => {

    console.log('teamData', teamData);
    var teamMembers = [];
    //console.log('Team Members ::', teamMembers);
    
    if(teamData.members.captain && teamData.members.captain.length){
        teamMembers.push(teamData.members.captain)
    }
    
    if(teamData.members.players && teamData.members.players.length){
        teamData.members.players.map(player => teamMembers.push(player))
    }
    
    if(teamData.members.substitutes && teamData.members.substitutes.length){
        teamData.members.substitutes.map(player => teamMembers.push(player))
    }
    
    if(teamData.members.coach && teamData.members.coach.length){
        teamData.members.coach.map(player => teamMembers.push(player))
    }
    
    if(teamData.members.manager && teamData.members.manager.length){
        teamData.members.manager.map(player => teamMembers.push(player))
    }
    
    console.log('Team Members ::', teamMembers);
    return teamMembers;
}


export const getAllTeamsFromClan = (id, setDetails) => {
    const teamApis = new TeamApis();
    updateLoader(true);
    const data = { clanId: id };
    
    teamApis.getMyTeams(data).then((res) => {
        //console.log('All Teams ::', res.data.result);
        setDetails(res.data.result)
        updateLoader(false);
    }).catch((err) => {
        setDetails(null);
        updateLoader(false);
        errorHandler(err);
    });
}