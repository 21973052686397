import validator from "validator";

//required validaton
export const requiredField = (value) => {
    let message;
    let error;
    if (!value) {
        message = '*required'
        error = true
    } else {
        message = ''
        error = false
    }

    return { error, message }
}


//email validation
export const emailValidation = (email) => {
    let message;
    let error;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email === undefined) {
        error = true;
        message = '*required'
    }
    else if (email.match(regexEmail)) {
        error = false
        message = ''
    } else {
        error = true
        message = 'invalid email'
    }

    return { error, message }
}

//phone number validation
export const phoneNumberValidation = (number) => {
    let message;
    let error;
    if (!number) {
        error = true;
        message = "Please enter your phone number.";
    }

    if (typeof number !== "undefined") {

        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(number)) {
            error = true;
            message = "Please enter only number.";
        } else if (number.length !== 10 && number.length !== 11) {
            error = true;
            message = "Please enter valid phone number.";
        } else {
            error = false
            message = ''
        }
    }

    return { error, message }
}

//password validation
export const passwordValidation = (password) => {
    let message;
    let error;
    if (!password) {
        error = true
        message = '*Password required'
    } else {
        if (
            validator.isStrongPassword(password, {
                minLength: 6,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1,
            })
        ) {
            error = false
            message = ''

        } else {
            error = true
            message = 'Password not strong enough!'
        }
    }
    return { error, message }
}
export const validateMaxParticipant = (maxParticipants, newCount) => {
    return maxParticipants >= newCount ? true : false
}

export const powerOfTwo = (n) => {
    return n && (n & (n - 1)) === 0;
}

export const halfThreshold = (val, participant) => {
    return val < (participant / 2) + 1 ? true : false;
}

export const isPrime = num => {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++)
        if (num % i === 0) return false;
    // return !num > 1;
    return (num > 1) ? true : false
}

export const isRightGroupNo = (participant, num) => {
    return ((participant % num) === 0) ? true : false
}

export const roundRobinMaxThreshold = (maxGrp, maxparticipant, val) => {
    return (((maxparticipant / maxGrp) + 1) > val) ? true : false
}
