import React, { Component } from 'react'
import { getDenProfilePicture } from '../../utils/Den.utils';
import { profile } from '../../utils/localstorage.utils';
import Image from './Image';

export default class ImageGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: props.users
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            users: newProps.users
        })
    }
  
    render() {
        if(this.state.users?.length > 3){
            return (
                <div className='d-flex justify-content-center align-items-center'>
                    {this.state.users.slice(0, 3)?.map((profile,i)=>{
                        var profilePicture = getDenProfilePicture(profile);
                        return <Image key={i} src={profilePicture?.url} param={profile.name? profile.name : "TEST"} className='rounded-circle' style={i===0 ? {width:'30px',height: '30px'}:{width:'30px', marginLeft:'-15px',height: '30px'}} />
                    })}
                    <div className='extra-numbers-box d-flex justify-content-center align-items-center' style={{marginLeft:'-15px'}}>+{this.state.users.length-3}</div>
                </div>
            )
        }else{
            return (
                <div className='d-flex justify-content-center align-items-center'>
                    {this.state.users?.map((profile,i)=>{
                        var profilePicture = getDenProfilePicture(profile);
                        return <Image key={i} src={profilePicture?.url} param={profile.name? profile.name : "TEST"} className='rounded-circle' style={i===0 ? {width:'30px',height: '30px'}:{width:'30px', marginLeft:'-15px',height: '30px'}} />
                    })}
                </div>
            )
        }
        
    }
}
