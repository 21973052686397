import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { AiFillCloseCircle, AiFillPlusCircle, AiOutlineCaretDown, AiOutlineClose } from 'react-icons/ai';
import { MdOutlineArrowBack } from 'react-icons/md';
import { isValidString } from '../../../../../utils/common.utils';
import { CREATE_POST_VIEWS } from '../../../../../utils/enums.utils';


export default class PostPollSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
           question: props.pollData?.question ? props.pollData.question : null,
           options: props.pollData?.options ? props.pollData.options :[null,null]
        };
    }

    updateState = (key, val) => {

        if(key === 'question'){
            this.setState({
                [key] : val
            });
        }else{
            var options =  this.state.options;
            options[key] = val;
            this.setState({
                options : options
            });
        }


    }

    onSubmit = () => {
        var validOptions = true;
        this.state.options.map((option,i)=>{
            if(!isValidString(option)){
                validOptions = false;
            }
        })

        if(isValidString(this.state.question) && validOptions){

            var data = {
                postAs:"USER",
                type : "POLL",
                pollDescription: this.state.question,
                assets:[]
            }
            this.state.options.forEach((option,i)=>{
                data.assets.push(            {
                    answer: option,
                    choosed: []
                })
            });

         

            this.props.backToMainView('currentView',CREATE_POST_VIEWS.CREATE_POST, 'pollData',data)
            this.props.updateState('pollDescription', this.state.question);
            
            
        }else{
            toast.warning("Please enter valid Question/Options in input fields");
        }
        
    }

    addMoreOptions = () =>{

        var options = this.state.options;
        options.push(null);
        this.setState({
            options: options
        });

    }

    isSubmitEnabled = () => {
        if(isValidString(this.state.question) && this.state.options.length > 1 && !this.state.options.includes(null)){
            return true;
        }else{
            return false;
        }
    }
  
    render() {

        console.log("######## question :: ",this.state.question)
        console.log("######## options :: ",this.state.options)
        console.log("########################################")
        const isSubmitEnabled = this.isSubmitEnabled();
        return(
            <div className='col-12 col-lg-6 p-3'>
                <div className='d-flex flex-column' style={{height:'82vh', overflow: 'auto'}}>
                    <div className='post-card-bg p-2 flex-grow-1'>
                        <div className='row px-2'>
                            <div className='col-2'>
                                {this.props.pollData ? 
                                    <MdOutlineArrowBack style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)} />
                                :
                                    <AiOutlineClose style={{height:'20px', width:'20px', cursor:'pointer'}} onClick={() => this.props.updateState('currentView',CREATE_POST_VIEWS.CREATE_POST)} />
                                }
                                </div>
                            <div className='col-8'>
                                <p className='fs-16 text-center fw-700 my-0'>Poll Options</p>
                            </div>
                            <div className='col-2'>
                                {isSubmitEnabled ?
                                    <p className='cursor-pointer fs-16 fw-600 my-0 mx-1' style={{ color:'#AD26FF'}} onClick={() => this.onSubmit()}>Done</p>                                
                                    :
                                    <p className='cursor-pointer fs-16 fw-600 my-0 mx-1 opacity-50' style={{ color:'#AD26FF'}} onClick={() => {toast.warning("Please enter the all deatils correctly (i.e Question and 2 Options)")}} >Done</p>
                                }
                            </div>
                        </div>
                        <hr className='hr-line' />
                        <p className='fs-16 fw-bold'>Who can see your post?</p>
                        <p className='fs-14 fw-500 my-0'>Your post will appear in your feed, on your profile and in search results</p>
                        <hr className='hr-line' />
                        <div className='p-2 my-2'>
                            <p className='fs-16 fw-bold'>Question</p>
                            <div className='purple-field poll-input-container d-flex w-100 p-2'>
                                <input onChange={(e) => this.updateState('question', e.target.value)} type='text' defaultValue={this.state.question} className='poll-input flex-grow-1' placeholder='Enter Question' />
                            </div>

                            <p className='fs-16 fw-bold mt-3'>Poll Options</p>
                            {this.state.options.map((option,i)=>{
                                return(
                                    <div className='purple-field poll-input-container d-flex w-100 p-2 my-2'>
                                        <input onChange={(e) => this.updateState(i, e.target.value)} type='text' className='poll-input flex-grow-1' defaultValue={option} placeholder={`Option ${i+1}`} />
                                    </div>
                                )
                            })}
                          
                            {this.state.options.length < 4 &&
                                <div>
                                    <p className='fs-14 fw-400 my-0'>You can add {(4-this.state.options.length) } more</p>
                                    <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2">
                                        <button className="btn" onClick={()=>this.addMoreOptions()}>
                                            <AiFillPlusCircle className="btn-size-25 text-white" />
                                        </button>
                                    </div>
                                </div>
                            }
                           
                        </div>

                    
                        
                    </div>
                </div>
            </div>
        )
    }
}
