import React, { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavLink } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
//images
import streamingIc from '../../../images/icon/streaming-ic.png';
import formIc from '../../../images/icon/forms-ic.png';
import prizeIc from '../../../images/icon/prize-ic.png';
import matchesIc from '../../../images/icon/matches-ic.png';
import sponsorIc from '../../../images/icon/sponsor-ic.png';
import standingIc from '../../../images/icon/standing-ic.png';
import structureIc from '../../../images/icon/structure-ic.png';
import addressIc from '../../../images/icon/address-card.png';
import settingIc from '../../../images/icon/setting-ic.png';
import disputeIc from '../../../images/icon/dispute-ic.png';
//icons
import { BiCopy } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";
//components and styles
import ConfirmationModal from '../../popup/ConfirmationModal';
import TournamentApis from '../../../helper/tournament.api';
import { errorHandler, updateLoader } from '../../../utils/common.utils';
import '../../../styles/Tournaments.css';
import { TournamentStatus } from '../../../utils/enums.utils';

export default class ConfigureMenuComponent extends Component {
    tournamentApis = new TournamentApis()
    constructor(props) {
        super(props)
        this.state = {
            toggleConfirm: false,
            createCopyToggle: false,
            isConfig: props.isConfig ? props.isConfig : false,
            formikDetails: {},
            confirm: {}
        }
    }

    componentDidMount() { }

    componentWillReceiveProps(newProps) {
        this.setState({
            isConfig: newProps.isConfig ? newProps.isConfig : this.state.isConfig,
        })
    }

    /*Leave Confirmation Msg */
    leaveClanMsg = () => {
        return <p className="fs-14 fw-600 text-center error-msg">Are you sure you want to delete this draft tournament?</p>
    }

    isDelete = (msg, key) => {
        const data = {
            msg: msg ? msg : '',
            key: key ? key : ''
        }
        this.setState({
            confirm: data,
            toggleConfirm: !this.state.toggleConfirm
        })
    }

    confirmed = (key) => {
        if (key === 'delete') {
            this.tournamentApis.deleteTournaments(this.props.tournamentId).then(
                (res) => {
                    this.setState({ toggleConfirm: false })
                    toast.success('Tournament deleted successfully')
                    this.props.history.replace(`/organization/view/${this.props.orgId}`)
                }
            ).catch(
                (err) => {
                    errorHandler(err, this.props.logout);
                }
            )
        }
    }

    saveFormikDetails = (e, key) => {
        e.preventDefault();
        if (key === 'RegStartDate' || key === 'RegEndDate' || key === 'tourStartDate' || key === 'tourEndDate') {
            let givenDate = new Date(e.target.value) // future Date
            let diff = new Date().getTime() - givenDate.getTime();
            if (diff > 0) {
                return false
            }
        }
        let data = this.state.formikDetails
        data[key] = e.target.value
        this.setState({ formikDetails: data })
    }

    createCopy = (values) => {
        let data = this.props.tournamentDetails
        data.registrationStartTime = new Date(values.RegStartDate).toUTCString();
        data.registrationEndTime = new Date(values.RegEndDate).toUTCString();
        data.tournamentStartTime = new Date(values.tourStartDate).toUTCString();
        data.tournamentEndTime = new Date(values.tourEndDate).toUTCString();
        data.name = values.name;
        data.normalEntrantsCount = 0;
        data.wildCardEntrantsCount = 0;
        data.overallAudienceRating = 0;
        data.overallParticipantRating = 0;
        data.overallRating = 0;
        data.audienceRating = [];
        data.participantRating = [];
        data.registrationStatus = 'DRAFT';
        data.tournamentStatus = 'DRAFT';
        data.entryFeeAndPrizeDetails = { entryFee: false, entryFeeAmount: 0 }
        //delete sponsor ids before duplicating so that backend does not throw error
        if(data.sponsorDetails?.length){
            let newSponsors = [];
            data.sponsorDetails.map((sponsor) => {
                delete sponsor?._id;
                newSponsors.push(sponsor)
            });
            data.sponsorDetails = newSponsors;
        }
        delete data.stages;
        delete data?.streams;
        delete data._id;
        delete data._v;
        delete data.teams;
        delete data.participantUserId;
        delete data.isBettingEnabled;
        delete data.leaderboardStatsAdded;
        delete data.leaderBoard;
        updateLoader(true)
        this.tournamentApis.createTournament(data).then(
            (res) => {
                this.setState({ createCopyToggle: false })
                this.props.history.replace(`/organization/view/${this.props.orgId}`)
                updateLoader(false)
                toast.success('Copy created successfully');
            }
        ).catch(
            (err) => {
                updateLoader(false)
                errorHandler(err, this.props.logout);
            }
        )
    }

    render() {
        return (
            <div className='row mt-3'>
                <div className='col-lg-4 col-md-6 col-6 mb-1'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/create/${this.props.match.params.id}`,
                        state: {
                            to: 'Update',
                        }
                    }} style={{ textDecoration: 'none' }}>
                        <img src={addressIc} className='btn-size-20 mb-lg-1' alt=''/>Basic Details
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-1'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/structure`
                    }}>
                        <img src={structureIc} className='btn-size-20 mb-lg-1' alt='' />Structure
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-1'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/matches/${this.props.tournamentDetails?.competitionType}?stage=0&group=0&round=1`
                    }}>
                        <img src={matchesIc} className='btn-size-20 mb-lg-1' alt='' />Matches
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-1'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={
                        {
                            pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/sponsors`
                        }}>
                        <img src={sponsorIc} className='btn-size-20 mb-lg-1' alt='' />Sponsors
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-12 col-12 mb-1'>
                    {
                        this.props.tournamentDetails?.tournamentStatus === TournamentStatus.DRAFT ?
                            <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                                pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/entry-fee-prize-details`
                            }}>
                                <img src={prizeIc} className='btn-size-20 mb-lg-1' alt='' />Entry Fee & Prize Details
                            </NavLink>
                            :
                            <div className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5'>
                                <img src={prizeIc} className='btn-size-20 mb-lg-1' alt='' />Entry Fee & Prize Details
                            </div>
                    }
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-1'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/streams`
                    }}>
                        <img src={streamingIc} className='btn-size-20 mb-lg-1' alt='' />Streams
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-1'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/final-standing`
                    }}>
                        <img src={standingIc} className='btn-size-20 mb-lg-1' alt='' />Final Standing
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-5'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={{
                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/registration-form`
                    }}>
                        <img src={formIc} className='btn-size-20 mb-lg-1' alt='' />Forms
                    </NavLink>
                </div>
                <div className='col-lg-4 col-md-6 col-6 mb-5'>
                    <NavLink className='fs-14 fs-lg-18 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 p-lg-5' to={
                        {
                            pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/general-setting`
                        }}>
                        <img src={settingIc} className='btn-size-20 mb-lg-1' alt='' />Rules
                    </NavLink>
                </div>


                <div className="d-flex justify-content-center">
                    <div className={`position-relative d-flex justify-content-center cmc-bottom-button ${this.props.isConfig ? 'mt-5' : 'mt-3'}`}>
                        <div className='text-center'>
                            <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45'><BiCopy className='black-rock fs-20 btn-size-25' onClick={() => {
                                this.setState({ createCopyToggle: true })
                            }} />
                            </span>
                            <p className="fs-12 fw-bold text-white mt-1">Duplicate</p>
                        </div>

                        <div className='text-center'>
                            <NavLink to={
                                {
                                    pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.tournamentId}/overview/dispute-management`
                                }}>
                                <span className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45'><img src={disputeIc} className='btn-size-25' alt='' /></span>
                                <p className="fs-12 fw-bold text-white mt-1">Dispute</p>
                            </NavLink>
                        </div>

                        {
                            this.props.tournamentDetails?.tournamentStatus === TournamentStatus.DRAFT &&
                            <div className='text-center'>

                                <span onClick={() => { this.isDelete(this.leaveClanMsg(), 'delete') }} className='round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45'> <AiFillDelete className='black-rock fs-20 btn-size-25' />

                                </span>
                                <p className="fs-12 fw-bold text-white mt-1">Delete</p>
                            </div>
                        }

                    </div>
                </div>


                <Modal show={this.state.toggleConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal confirm={this.state.confirm}
                            onClickOpenConfirm={() => { this.setState({ toggleConfirm: false }) }} confirmed={this.confirmed}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.createCopyToggle} centered dialogClassName="tournament-copy-modal">
                    <Modal.Body>
                        <div className="p-3">
                            <IoIosCloseCircleOutline
                                className="close-icon position-absolute" style={{ right: '15px', marginTop: '5px' }}
                                onClick={() => {
                                    this.setState({ createCopyToggle: false })
                                }}
                            />
                            <p className="fw-bold fs-20 text-center text-white mb-0">Create Copy</p>
                            <div>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        name: this.state.formikDetails?.name ? this.state.formikDetails.name : '',
                                        RegStartDate: this.state.formikDetails?.RegStartDate ? this.state.formikDetails.RegStartDate : '',
                                        RegEndDate: this.state.formikDetails?.RegEndDate ? this.state.formikDetails.RegEndDate : '',
                                        tourStartDate: this.state.formikDetails?.tourStartDate ? this.state.formikDetails.tourStartDate : '',
                                        tourEndDate: this.state.formikDetails?.tourEndDate ? this.state.formikDetails.tourEndDate : ''
                                    }}

                                    validationSchema={Yup.object({
                                        name: Yup.string().required("Tournament name is required").matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, 'Only alphabets allowed.').max(25, 'Max 25 characters'),
                                        RegStartDate: Yup.date().required("Registration start time required").min(new Date(), 'Start time cannot be in past'),
                                        RegEndDate: Yup.date().required("Registration end time required").min(Yup.ref('RegStartDate'), "End time can't be before start date"),
                                        tourStartDate: Yup.date().required("Tournament start time is required").min(Yup.ref('RegEndDate'), "Can't start before registration end time"),
                                        tourEndDate: Yup.date().required("Tournament end time is required").min(Yup.ref('tourStartDate'), "Can't end before tournament start time"),
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            this.createCopy(values)
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    <Form className="createClan-form mt-3" autoComplete="off">
                                        <div className="">
                                            <label htmlFor="name" className="fs-14 fw-600 text-white mb-1"> Name</label>
                                            <Field
                                                name="name"
                                                type="text"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                placeholder="Enter Tournament Name"
                                                onKeyUp={(e) => { this.saveFormikDetails(e, 'name') }}
                                            />
                                            <ErrorMessage component="span" className="error-msg" name="name"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="RegStartDate" className="fs-14 fw-600 text-white mb-1">Registration Start Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="RegStartDate" type="datetime-local"
                                                    minDate={new Date()}
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Registration Start Date-Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'RegStartDate') }}
                                                />
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="RegStartDate" />
                                        </div>
                                        <div className="mt-2 startDate" >
                                            <label htmlFor="RegEndDate" className="fs-14 fw-600 text-white mb-1">Registration End Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="RegEndDate"
                                                    type="datetime-local"
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Registration End Date Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'RegEndDate') }}
                                                />
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="RegEndDate" />
                                        </div>
                                        <div className="mt-2 tourStart">
                                            <label htmlFor="tourStartDate" className="fs-14 fw-600 text-white mb-1">Tournament Start Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="tourStartDate"
                                                    type="datetime-local"
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Tournament Start Date & Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'tourStartDate') }}
                                                />
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="tourStartDate" />
                                        </div>
                                        <div className="mt-2 tourEnd">
                                            <label htmlFor="tourEndtDate" className="fs-14 fw-600 text-white mb-1">Tournament End Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="tourEndDate"
                                                    type="datetime-local"
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Tournament Start Date & Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'tourEndDate') }}
                                                />
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="tourEndDate" />
                                        </div>
                                        <button type="submit" className="white-flat-btn fw-bold py-2 px-4 d-flex m-auto mt-2" >
                                            Create
                                        </button>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
