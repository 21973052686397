import React, { Component } from 'react';
import { ellipsis } from '../../../../utils/common.utils';
//images
import orgIc from "../../../../images/icon/org-ic.png";
import crown from "../../../../images/score/escrown.png";
import star from "../../../../images/score/star.png";
import Image from '../../../common/Image';

export default class TeamFFAStandings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leaderBoardType: props.leaderBoardType,
            participant: props.participant,
            tournamentDetails: props.tournamentDetails,
            key: props.index
        }
        //this.gameUrl = scoreCardGameURL(props.tournamentDetails?.gameDetails?.name)
    }

    componentDidMount() {
        //getLeaderBoardDetails(this.props.tournamentDetails._id, this.setLeaderBoardDetails)
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            leaderBoardType: newProps.leaderBoardType,
            participant: newProps.participant,
            isTeamGame: newProps.isTeamGame,
            key: newProps.index
        })
    }

    getData = () => {
        const { tournamentDetails, participant, leaderBoardType, key } = this.state
        if (leaderBoardType === 'STAGE') {
            return (
                <div className='col-6 my-1' key={key}>
                    <div className='participant-card'>
                        <div className='row'>
                            <div className='col-1 d-flex flex-column me-2'>
                                <p className='fs-14 fw-700 my-0' style={{ color: key == 0 ? '#FFE420' : 'white' }}>#{key + 1}</p>
                                <img src={crown} alt='' style={{ height: '12px', width: '12px', display: key == 0 ? 'block' : 'none' }} />
                            </div>
                            <div className='col-5 d-flex flex-column p-0'>
                                <div className='d-flex'>
                                    {/* <img src={participant.teamDetails?.logo?.url} alt='' className='rounded-circle' style={{ height: '25px', width: '25px' }} /> */}
                                    <Image src={participant.teamDetails?.logo?.url} param={participant.teamDetails?.name} className='rounded-circle' height='25px' width='25px' />
                                    <div>
                                        <p className='fs-10 fw-600 my-0'>{ellipsis(participant.teamDetails?.name, 9)}</p>
                                        <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>Kills: {participant.kills}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-column'>
                                    {/* <p className='fs-10 fw-600 my-0 text-success'>#Qualified</p> */}
                                    <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>Matches Won-{participant.noOfGamesWonCount}</p>
                                </div>
                            </div>
                            <div className='col-4 p-0'>
                                {participant.individualScore.map((player, k) => {
                                    return (
                                        <div className='d-flex justify-content-between pe-1' key={k}>
                                            <p className='text-white fw-600 my-0' style={{ fontSize: '8px' }}>{ellipsis(player?.participantDetails?.inGameName, 7)}</p>
                                            <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>{`${player.kills}/${player.deaths}/${player.assists}`}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='col-6 my-1' key={key}>
                    <div className='participant-card p-1'>
                        <div className='row'>
                            <div className='col-1 d-flex flex-column me-2'>
                                <p className='fs-14 fw-700 my-0' style={{ color: key == 0 ? '#FFE420' : 'white' }}>#{key + 1}</p>
                                <img src={crown} alt='' style={{ height: '12px', width: '12px', display: key == 0 ? 'block' : 'none' }} />
                            </div>
                            <div className='col-5 d-flex p-0'>
                                {/* <img src={participant.teamDetails?.logo?.url} alt='' className='rounded-circle' style={{ height: '25px', width: '25px' }} /> */}
                                <Image src={participant.teamDetails?.logo?.url} param={participant.teamDetails?.name} className='rounded-circle' height='25px' width='25px' />
                                <div className='d-flex flex-column'>
                                    <p className='fs-10 fw-600 my-0'>{ellipsis(participant.teamDetails?.name, 9)}</p>
                                    <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>Kills: {participant.kills}</p>
                                    {/* <p className='fs-10 fw-600 my-0'><img src={star} alt='' />{participant.score}</p> */}
                                    {/* <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>R-{tournamentDetails.gameDetails.pointSystem[key]} + K-{participant.kills * tournamentDetails?.gameDetails?.pointsPerKill}</p> */}
                                </div>
                            </div>
                            <div className='col-4 p-0'>
                                {participant.individualScore.map((score, j) => {
                                    return (
                                        <div className='d-flex justify-content-between' key={j}>
                                            <p className='text-white fw-600 my-0' style={{ fontSize: '8px' }}>{score.participantDetails?.inGameName}</p>
                                            <p className='text-white-50 fw-600 my-0' style={{ fontSize: '8px' }}>{score.kills}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return this.getData();
    }
}