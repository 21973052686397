import React, { Component } from 'react';
import { getHeading, getTournamentAdditionalData } from '../../../../../../../utils/common.utils';
import { GAMES_NAMES } from '../../../../../../../utils/enums.utils';
import CSGO from './CSGO';
import Fortnite from './Fortnite';
import LeagueOfLegends from './LeagueOfLegends';
import MatchesDuelScoreUpdate from './MatchesDuelScoreUpdate';
import MatchesFFAScoreUpdate from './MatchesFFAScoreUpdate';
import PUBG from './PUBG';
import Valorant from './Valorant';



export default class ScoreUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tournamentDetails: null
        };
        props.updateLoader(true);
        getTournamentAdditionalData(this.props.match.params.id, this.setData);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }

    // when data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
            });
            this.props.updateLoader(false);
        } else {
            this.props.updateLoader(false);
            this.props.history.push('/pageNotFound')
        }
    }

    getGameScoreUpdate = () => {
        const title = getHeading(this.state.tournamentDetails)
        switch (this.state.tournamentDetails?.gameDetails?.name) {
            case GAMES_NAMES.DOTA_2:
                return <LeagueOfLegends tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.LEAGUE_OF_LEGENDS:
                return <LeagueOfLegends tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.PUBG:
                return <PUBG tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.PUBG_MOBILE:
                return <PUBG tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.FREEFIRE:
                return <PUBG tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.CALL_OF_DUTY:
                return <PUBG tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.VALORANT:
                return <Valorant tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.FORTNITE:
                return <Fortnite tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.CS_GO:
                return <CSGO tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            case GAMES_NAMES.APEX_LEGENDS:
                return <Fortnite tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} gameNo={this.props.match?.params?.gameNo} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} props={this.props} matchId={this.props.match.params.matchId} history={this.props.history} title={title} />
            default:
                if ((this.props.match.params?.type).includes('FFA')) {
                    return <MatchesFFAScoreUpdate tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} history={this.props.history} matchId={this.props.match.params.matchId} match={this.props.match} />
                } else {
                    return <MatchesDuelScoreUpdate tournamentDetails={this.state.tournamentDetails} gameNo={this.props.match?.params?.gameNo} updateLoader={this.props.updateLoader} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} history={this.props.history} matchId={this.props.match.params.matchId} />
                }
        }
    }


    render() {
        return (
            <div>
                {/* {(this.props.match.params?.type).includes('FFA') ?
                    <MatchesFFAScoreUpdate tournamentDetails={this.state.tournamentDetails} updateLoader={this.props.updateLoader} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} history={this.props.history} matchId={this.props.match.params.matchId} match={this.props.match} />
                :
                    <MatchesDuelScoreUpdate tournamentDetails={this.state.tournamentDetails} gameNo={this.props.match?.params?.gameNo} updateLoader={this.props.updateLoader} id={this.props.match.params.id} showOrHideNav={this.props.showOrHideNav} history={this.props.history} matchId={this.props.match.params.matchId}/>
                } */}
                {this.state.tournamentDetails && this.getGameScoreUpdate()}

            </div>
        );
    }
}
