import React, { Component } from 'react';
import TournamentApis from '../../../helper/tournament.api';
import { errorHandler } from '../../../utils/common.utils';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
class Tournaments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tournamentType: props.match?.params?.type,
      orgId: props?.match?.params?.orgId,
      tournaments: []
    };
    this.tournamentApis = new TournamentApis();
  }
  componentDidMount() {
    this.props.showOrHideNav(true);
    this.props.dispatchData(null)
    if (this.props.match.params?.orgId) {
      this.getData()
    } else {
      this.getAllTournaments()
    }
  }

  getData = () => {
    const data = {
      organizationId: this.state.orgId,
      tournamentStatus: this.state.tournamentType
    }
    this.props.updateLoader(true);
    this.tournamentApis.getTournaments(data).then(
      (res) => {
        this.setState({ tournaments: res.data.result });
        this.props.updateLoader(false);
      }
    ).catch(
      (err) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  getAllTournaments = () => {
    this.props.updateLoader(true);
    this.tournamentApis.getMyTournaments().then(
      (res) => {
        this.setState({ tournaments: res.data.result });
        this.props.updateLoader(false);
      }
    ).catch(
      (err) => {
        this.props.updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  render() {
    // To arrange tournaments in its initial order
    const sortedTournaments = [].concat(this.state.tournaments).reverse();

    return (
      <section className="body-section tournament-page">
        <div className="container p-3">
          <h1 className="clan-heading mt-lg-4 mb-4">{this.state.tournamentType ? this.state.tournamentType : ""} Tournaments</h1>
          {sortedTournaments?.length ?
            <div className="row clan-grid">
              {
                sortedTournaments.map((el, i) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-3 my-2 clanPage_Cards" key={i}>
                      <NavLink
                        to={{
                          pathname: `/organization/${this.props.match.params.orgId}/tournament/${el._id}/overview`,
                          state: {
                            isConfig: false
                          }
                        }}>
                        <div className={`clan-card alpha-fortniters`} style={{ backgroundImage: `url(${el.baner.url})` }}>
                          <div className="clan-card-name mt-auto">
                            <p className='ps-1 py-2 fs-18 text-white text-center fw-600 mb-1 text-truncate'>{el.name}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  )
                })
              }

            </div> :
            <div className="joinAlert">
              <div className="join-alertImage"></div>
              <p className="text-center my-3 fw-500">There are no {this.state.tournamentType} tournaments....</p>
            </div>
          }
        </div>
      </section>
    );
  }
}

export default (connect(mapStateToProps, mapDispatchToProps))(Tournaments)