import React, { useState, useEffect, useCallback, useRef } from "react";
import { NotificationImages } from '../../utils/Images.utils';
import '../../styles/Notifications.css';
import { AiOutlineClose } from 'react-icons/ai';
import { errorHandler, updateLoader } from '../../utils/common.utils';
import NotificationsApi from '../../helper/notifications.api';
import { Modal } from 'react-bootstrap';
import ConfirmationModal from '../popup/ConfirmationModal';
import { WARNING } from '../../utils/Strings.utils';
import { receivedAt } from '../../utils/dateTime.utils';
import { updateNotificationCount } from '../../utils/notification.utils';

const Notifications = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [expandedNotifications, setExpandedNotifications] = useState({});
  const [clearAllNotifications, setClearAllNotifications] = useState(false);
  
  const notificationsApiRef = useRef(new NotificationsApi());
  
  const updateNotifications = useCallback((newNotifications) => {
    setNotifications(newNotifications);
    setClearAllNotifications(false);
  }, []);
  
  const fetchNotifications = useCallback(() => {
    updateLoader(true);
    return notificationsApiRef.current.getMyNotification()
      .then((res) => {
        const newNotifications = res.data.result.notifications;
        updateNotifications(newNotifications);
        updateLoader(false);
        return newNotifications;
      })
      .catch((err) => {
        updateLoader(false);
        errorHandler(err);
        return [];
      });
  }, [updateNotifications]);
  
  const markNotificationAsRead = useCallback((notificationId) => {
    const data = {
      notificationIds: [notificationId]
    };
    
    return notificationsApiRef.current.updateNotificationLogStatus(data)
      .then((res) => {
        updateNotifications(res.data.result.notifications);
        updateNotificationCount();
        return res.data.result.notifications;
      })
      .catch((err) => {
        errorHandler(err);
        return notifications; // Return current state on error
      });
  }, [notifications, updateNotifications]);
  
  // Delete notification
  const deleteNotification = useCallback((e, notificationId) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    updateLoader(true);
    
    // If notificationId is not provided, clear all notifications
    const apiCall = notificationId 
      ? notificationsApiRef.current.deleteNotificationLog(notificationId)
      : notificationsApiRef.current.deleteNotificationLog();
      
    apiCall.then((res) => {
      updateNotifications(res.data.result.notifications);
      updateLoader(false);
    }).catch((err) => {
      updateLoader(false);
      errorHandler(err);
    });
  }, [updateNotifications]);
  
  // Toggle show more
  const toggleShowMore = useCallback((e, notificationId, details) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Toggle the expanded state for this notification
    setExpandedNotifications(prev => ({
      ...prev,
      [notificationId]: !prev[notificationId]
    }));
    
    // Mark as read if not already read
    if (!details.isRead) {
      markNotificationAsRead(details.notificationId);
    }
  }, [markNotificationAsRead]);
  
  // Handle notification click
  const handleNotificationClick = useCallback((details) => {
    // Mark as read if not already read
    if (!details.isRead) {
      markNotificationAsRead(details.notificationId);
    }
    
    // Navigate if link is available
    if (details.notification.link && props.history) {
      props.history.push(details.notification.link);
      if (details.notification.reload) {
        window.location.reload();
      }
    }
  }, [markNotificationAsRead, props.history]);
  
  // Load notifications on mount
  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);
  
  // Display notifications in reverse chronological order
  const sortedNotifications = [...notifications].reverse();
  const unreadCount = sortedNotifications?.filter(notification => !notification.isRead).length || 0;

  return (
    <section className="body-section">
      <div className="p-3 d-flex flex-column mx-auto notifications">
        <div className="row justify-content-between">
          <div className="col-4">
            <h1 className="fs-20 fw-bold text-white mb-2 mt-3">
              Notifications
              <span className="fs-12 fw-500 my-0 text-white opacity-50 ps-2">
                <em>({unreadCount}/{sortedNotifications.length})</em>
              </span>
            </h1>
          </div>
          <div className="col-4">
            {sortedNotifications.length > 0 && (
              <button 
                className="btn btn-danger fs-12 fw-bold text-white mb-2 float-end mt-3" 
                onClick={() => setClearAllNotifications(true)}
              >
                <i className="fa fa-trash" /> Clear All
              </button>
            )}
          </div>
        </div>

        <div className="row">
          {sortedNotifications.length === 0 ? (
            <div className="col-12 text-center py-4">
              <p className="text-white-50">No notifications to display</p>
            </div>
          ) : (
            sortedNotifications.map((details, i) => {
              const { notificationId, notification, isRead } = details;
              const isExpanded = expandedNotifications[notificationId];
              
              return (
                <div className="col-12 my-1" key={notificationId || i}>
                  <div 
                    className={`row pt-3 pb-3 ps-1 pe-2 ${isRead ? '' : 'border-start border-3 border-info'}`} 
                    style={{ backgroundColor: '#231D36' }}
                  >
                    <div 
                      className="col-2 pe-0 cursor-pointer" 
                      onClick={() => handleNotificationClick(details)}
                    >
                      <div className="d-flex flex-column align-items-center h-100">
                        <img 
                          src={NotificationImages[notification.type] || NotificationImages.DEFAULT}
                          alt={notification.type || "Notification"} 
                          className="notification-icon"
                        />
                        <p className="fs-10 fw-500 text-white-50 mt-2">
                          {receivedAt(notification.createdAt)}
                        </p>
                      </div>
                    </div>

                    <div className="col-9 px-2">
                      <p className="fs-14 fw-500 my-0 text-white opacity-75">
                        <b 
                          className="cursor-pointer" 
                          onClick={() => handleNotificationClick(details)}
                        >
                          {notification.heading}{' '}
                        </b>
                        {isExpanded ? (
                          <em 
                            className="cursor-pointer" 
                            onClick={() => handleNotificationClick(details)}
                          >
                            {notification.message}
                          </em>
                        ) : (
                          <>
                            <em 
                              className="cursor-pointer" 
                              onClick={() => handleNotificationClick(details)}
                            >
                              {notification.message.slice(0, Math.max(30, 65 - notification.heading.length))}...
                            </em>
                            <button 
                              className="fs-14 fw-bold ms-1 cursor-pointer bg-transparent border-0 p-0 text-primary" 
                              onClick={(e) => toggleShowMore(e, notificationId, details)}
                            >
                              Show More
                            </button>
                          </>
                        )}
                      </p>
                    </div>

                    <div className="col-1 pe-0">
                      <AiOutlineClose 
                        className="text-secondary cursor-pointer" 
                        onClick={(e) => deleteNotification(e, notificationId)}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      <Modal 
        show={clearAllNotifications} 
        centered 
        dialogClassName="Confirmation-modal"
        onHide={() => setClearAllNotifications(false)}
      >
        <Modal.Body>
          <ConfirmationModal
            confirm={{ 
              msg: (<p className="fs-14 fw-600 text-center text-white-50">{WARNING.CLEAR_NOTIFICATIONS}</p>), 
              key: null 
            }}
            onClickOpenConfirm={() => setClearAllNotifications(false)}
            confirmed={() => deleteNotification(null)}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Notifications;