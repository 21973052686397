import React from 'react';
import { toast } from "react-toastify";
import DenApi from "../helper/den.api";
import { errorHandler, updateLoader } from "./common.utils";
import { POST_INTERACTIONS } from "./Images.utils";
import { getToken, profile, setItem } from "./localstorage.utils";
import { saveAs } from 'file-saver'
import { DEN } from "./Strings.utils";

const denApi = new DenApi()

export const getDenProfiles = (data, setDenProfiles) => {
    updateLoader(true);
    denApi.getDenProfiles(data).then((res) => {
        setDenProfiles(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getDenProfiles2 = (data, setDenProfiles) => {
    updateLoader(true);
    denApi.getDenProfiles(data).then((res) => {
        setDenProfiles(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        setDenProfiles(null);
        updateLoader(false);
        errorHandler(err);
    });
}

export const getPostLikedUsersByID = (postId, setDenProfiles) => {
    updateLoader(true);
    denApi.getPostLikedUsersByID(postId).then((res) => {
        setDenProfiles(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

/***************************************** Den Profile API's  ******************************************/

export const updateDenProfile = (id, data, updateProfileDetails) => {
    updateLoader(true);
    denApi.updateDenProfileDetails(id, data).then((res) => {
        updateProfileDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const denProfileSearch = (denId, searchString, updateSearchResults) => {
    updateLoader(true);
    denApi.denProfileSearch(denId, searchString).then((res) => {
        updateSearchResults(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const followDenProfile = (denId, profileId, afterFollowedDenProfile) => {
    updateLoader(true);
    denApi.followDenProfile(denId,profileId).then((res) => {
        afterFollowedDenProfile(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const unFollowDenProfile = (denId, profileId, afterFollowedDenProfile) => {
    updateLoader(true);
    denApi.unFollowDenProfile(denId, profileId).then((res) => {
        afterFollowedDenProfile(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const cancelRequest = (denId, profileId, afterCancelRequest) => {
    updateLoader(true);
    denApi.cancelRequest(denId, profileId).then((res) => {
        afterCancelRequest(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const acceptRequest = (denId, profileId, afterAcceptRequest) => {
    updateLoader(true);
    denApi.acceptRequest(denId, profileId).then((res) => {
        afterAcceptRequest(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const updateDenProfileInLocalStorage = (updatedDenProfile) => {
    var updatedProfile = profile();
    updatedProfile.denProfile = updatedDenProfile;
    localStorage.setItem('login', JSON.stringify({
        login: true,
        token: getToken(),
        authUser: {
          user: updatedProfile
        }
    }));
}

export const blockUnBlockDenProfile = (denId, profileId,isBlocked, updateProfileDetails) => {
    updateLoader(true);
    denApi.blockUnBlockDenProfile(denId, profileId, isBlocked).then((res) => {
        updateProfileDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const updateLastSpinDetails = (denId, data, updatedDenProfile) => {
    updateLoader(true);
    denApi.updateLastSpinDetails(denId,data).then((res) => {
        updatedDenProfile(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}
/***************************************** Den Post API's  ******************************************/

export const getDenPostsForTimeline = (data,updateDenPostsOnTimeline) => {
    updateLoader(true);
    denApi.getDenPostsForTimeline(data).then((res) => {
        updateDenPostsOnTimeline(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getDenPostById = (postId, updatePostDetails) => {
    updateLoader(true);
    denApi.getDenPostById(postId).then((res) => {
        updatePostDetails(res.data.result[0]);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getDenPostsByType = (denId, type, updatePostDetails) => {
    updateLoader(true);
    denApi.getDenPostsByType(denId, type).then((res) => {
        updatePostDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}


export const getDenMultiplePosts = (posts, updatePostDetails) => {
    updateLoader(true);
    denApi.getDenMultiplePosts(posts).then((res) => {
        updatePostDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getProfilePagePosts = (denId, updatePostDetails) => {
    updateLoader(true);
    denApi.getProfilePagePosts(denId).then((res) => {
        updatePostDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const createAndShareDenPost = (data, updateProfileDetails, isShare) =>{
    updateLoader(true);
    denApi.createDenPost(data).then((res) => {
        updateProfileDetails(res.data.result, isShare);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const createAndDraftDenPost = (data, updateProfileDetails, isShare) =>{
    updateLoader(true);
    denApi.draftDenPost(data).then((res) => {
        updateProfileDetails(res.data.result, isShare);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const updateDenPost = (postId, data, updatePostDetails, message) =>{
    updateLoader(true);
    denApi.updateDenPost(postId, data).then((res) => {
        updatePostDetails(res.data.result, message);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const updateAndShareDraftPost  = (postId, data, updatePostDetails) =>{
    updateLoader(true);
    denApi.updateAndShareDraftPost(postId, data).then((res) => {
        updatePostDetails(res.data.result,true);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const likeDenPost = (postId, data, updateDenPostsOnTimeline, updateProfileDetails) => {
    updateLoader(true);
    denApi.likeDenPost(postId, data).then((res) => {
       
        updateDenPostsOnTimeline(res.data.result.postDetails);
        updateProfileDetails(res.data.result.profileDetails);
        
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const likeDenPostComment = (commentId, data, updateDenPostsOnTimeline, updateProfileDetails) => {
    updateLoader(true);
    denApi.likeDenPostComment(commentId, data).then((res) => {
        updateDenPostsOnTimeline(res.data.result.postDetails);
        updateProfileDetails(res.data.result.profileDetails);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const reportDenPost = (postId, data, showHidePostAction,enableUndoReport) => {
    updateLoader(true);
    denApi.reportDenPost(postId, data).then((res) => {
        showHidePostAction(null, false, false);
        enableUndoReport(res.data.result);
        toast.success("Post has been reported !");
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const undoDenPostReport = (reportId, clearUndoReportPopup) => {
    updateLoader(true);
    denApi.undoDenPostReport(reportId).then((res) => {
        clearUndoReportPopup();
        toast.success("Report has been declined !");
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const saveDenPost = (denId, postId, isSave, updateProfileDetails) => {
    updateLoader(true);
    denApi.saveDenPost(denId, postId, isSave).then((res) => {
        updateProfileDetails(res.data.result);
        toast.success(`Post has been ${isSave ? 'saved' : 'unsaved'} !`);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const pinDenPost = (denId, postId, isPin, updateProfileDetails) => {
    updateLoader(true);
    denApi.pinDenPost(denId, postId, isPin).then((res) => {
        updateProfileDetails(res.data.result);
        toast.success(`Post has been ${isPin ? 'pinned' : 'un-pinned'} !`);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

// export const shareDenPost = (postId, updateProfileDetails, updatePostDetails) => {
//     updateLoader(true);
//     denApi.shareDenPost(postId).then((res) => {
//         updateProfileDetails(res.data.result.profileDetails);
//         updatePostDetails(res.data.result.postDetails, null);
//         toast.success("Post has been shared !");
//         updateLoader(false);
//     }).catch((err) => {
//         updateLoader(false);
//         errorHandler(err);
//     });
// }

export const choosePollOption = (denId, postId, option, updatePostDetails, enableEdit) =>{
    if(!enableEdit){
        updateLoader(true);
        denApi.choosePollOption(denId, postId, option).then((res) => {
            updatePostDetails(res.data.result, null);
            updateLoader(false);
        }).catch((err) => {
            updateLoader(false);
            errorHandler(err);
        });
    }
}

export const getDenPostsLeaderBoard = (setDenPostsLeaderBoard) =>{
    updateLoader(true);
    denApi.getDenPostsLeaderBoard().then((res) => {
        setDenPostsLeaderBoard(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}
//Feed for registered den users
export const getFeedPosts = (denId, searchString, setFeedPosts) =>{
    //updateLoader(true);
    denApi.getFeedPosts(denId, searchString).then((res) => {
        setFeedPosts(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}
//Feed for registered den users
export const getFeedTvPosts = (denId, searchString, setFeedPosts) =>{
    //updateLoader(true);
    denApi.getFeedTvPosts(denId, searchString).then((res) => {
        setFeedPosts(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

//Feed for unregistered den users
export const getPublicFeedPosts = (searchString, setFeedPosts) =>{
    //updateLoader(true);
    denApi.getPublicFeedPosts(searchString).then((res) => {
        setFeedPosts(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getPostInteractionsDetails = (postIndex, post, likedUsers, denProfile, openCommentForPostIndex, openSpotlightForPostIndex) => {
    
    const result = {
        spark : {
            logo : likedUsers.includes(denProfile?._id.toString()) ?  POST_INTERACTIONS.SPARK.ON : POST_INTERACTIONS.SPARK.OFF,
            color : likedUsers.includes(denProfile?._id.toString()) ? 'active-interaction' : null,
        },
        hammer : {
            logo : openCommentForPostIndex === postIndex ? POST_INTERACTIONS.HAMMER.ON : POST_INTERACTIONS.HAMMER.OFF,
            color : openCommentForPostIndex === postIndex ? 'active-interaction' : null,
        },
        spotlight:{
            logo : openSpotlightForPostIndex === postIndex ? POST_INTERACTIONS.SPOTLIGHT.ON : POST_INTERACTIONS.SPOTLIGHT.OFF ,
            color : openSpotlightForPostIndex === postIndex ? 'active-interaction' : null,
        },
        share:{
            logo : post.shares.includes(denProfile?._id.toString()) ? POST_INTERACTIONS.SHARE.ON : POST_INTERACTIONS.SHARE.OFF,
            color : post.shares.includes(denProfile?._id.toString())  ? 'active-interaction' : null,
        }
    };
    
    return result;
}

 
export const enableDisabelDenPostActions = (postId, data, updatePostDetails) =>{

    updateLoader(true);
    denApi.enableDisabelDenPostActions(postId, data).then((res) => {
        updatePostDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });

}

export const denPostSearch = (searchString, updateSearchResults) => {
    updateLoader(true);
    denApi.denPostSearch(searchString).then((res) => {
        updateSearchResults(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const denLiveStreamSearch = (denId, searchString, updateSearchResults) => {
    updateLoader(true);
    denApi.denLiveStreamSearch(denId, searchString).then((res) => {
        updateSearchResults(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

/***************************************** Den Comments API's  ******************************************/

export const getPostComments = (postId, setPostComments) =>{
    updateLoader(true);
    denApi.getPostComments(postId).then((res) => {
        setPostComments(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const updateComment = (commentId,data, setPostComments) =>{
    updateLoader(true);
    denApi.updateComment(commentId,data).then((res) => {
        setPostComments(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const pinComment = (commentId,data, setPostComments) =>{
    updateLoader(true);
    denApi.pinComment(commentId,data).then((res) => {
        setPostComments(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getPostCommentedUsersByID = (commentId, setPostComments) =>{
    updateLoader(true);
    denApi.getPostCommentedUsersByID(commentId).then((res) => {
        setPostComments(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const replyOnPostOrComment = (comment,isPostComment, setPostComments, updateProfileDetails) =>{
    updateLoader(true);
    denApi.replyOnPostOrComment(comment).then((res) => {
        setPostComments(res.data.result.commentDetails,isPostComment);
        updateProfileDetails(res.data.result.profileDetails);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const deleteCommentOnPostOrReply = (commentId, data, setPostComments) => {
    updateLoader(true);
    denApi.deleteCommentOnPostOrReply(commentId,data).then((res) => {
        setPostComments(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const deletePostCommentById = (commentId, setPostComments) => {
    updateLoader(true);
    denApi.deletePostCommentById(commentId).then((res) => {
        setPostComments(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}


/***************************************** Den Spotlight API's  ******************************************/



export const sendSpotlightToDenProfile = (data, updatedSpotlightDetails) => {
    updateLoader(true);
    denApi.sendSpotlightToDenProfile(data).then((res) => {
        updatedSpotlightDetails(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

/***************************************** Den Achievements API's  ******************************************/

export const getDenProfileAchivements = (denId, setDenProfileAchivements) => {
    updateLoader(true);
    denApi.getDenProfileAchivements(denId).then((res) => {
        setDenProfileAchivements(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}

export const getDenProfileGameStats = (id, type, setDenProfileGameStats) => {
    updateLoader(true);
    denApi.getDenProfileGameStats(id, type).then((res) => {
        setDenProfileGameStats(res.data.result);
        updateLoader(false);
    }).catch((err) => {
        updateLoader(false);
        errorHandler(err);
    });
}




/***************************************** Common  ******************************************/

export const downloadPostAssets = (post, onClose) => {
    post = (post.type === "REPOST") ? post.rePostDetails[0] : post;
    post.assets.map((doc,i)=>{
        saveAs(doc.url, `assets-${i+1}.jpg`)
    });
    onClose();    
}

export const getDenProfilePicture = (profile) => {
   return (profile?.type === DEN.SEARCH.TYPES.USER ? profile?.profilePicture : profile?.logo);
}

export const getDenCoverPicture = (profile) => {
    return (profile?.type === DEN.SEARCH.TYPES.USER ? profile?.profileCover : profile?.coverImage);
}

export const checkDenStatus = (denProfile) => {
    if(denProfile === null){
        toast.warning('Please complete your den porfile to perform this action');
        return false;
    }else if(!denProfile.isActive){
        toast.error('Please re-activate your den profile to perform any action');
        return false;
    }
    return true;
}







