import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class AuthApis {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = import.meta.env.VITE_APP_API_URL;
        this.public_postfix = "/public/api/v1/auth/";
        this.private_postfix = "/private/api/v1/auth/";
    }

    init = () => {
        this.api_token = getToken();

        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = `${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    signUp = (data) => {
        return this.init().post(`${this.public_postfix}signUp`, data);
    }

    signIn = (data) => {
        return this.init().post(`${this.public_postfix}signIn`, data);
    }

    forgotPassword = (data) => {
        return this.init().post(`${this.public_postfix}sendForgetPasswordLink`, data);
    };

    resetPassword = (data) => {
        return this.init().post(`${this.public_postfix}resetPasswordThroughLink`, data);
    }

    resetPasswordThroughLink = (data) => {
        return this.init().post(`${this.public_postfix}resetPasswordThroughLink`, data);
    }

    resendEmailVerificationLink = () => {
        return this.init().get(`${this.private_postfix}resendEmailVerificationLink`);
    }

    addNewClan = (data) => {
        return this.init().post("/users", data);
    };

    updateProfile = (data) => {
        return this.init().post(`${this.private_postfix}updateProfile`, data);
    }

    changePassword = (data) => {
        return this.init().post(`${this.private_postfix}changePassword`, data);
    }

    getProfile = (id) => {
        return this.init().get(`${this.public_postfix}getprofile/${id}`);
    }

    getPublicProfile = (id) => {
        return this.init().get(`${this.public_postfix}getprofile/${id}`);
    }

    verifyAccount = (data) =>{
        return this.init().post(`${this.public_postfix}verifyAccount`, data);
    }
    
}