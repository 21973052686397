import React, { Component } from 'react';
import { AiFillCopyrightCircle } from 'react-icons/ai';
import MatchesApis from '../../../../helper/matches.api';
import { errorHandler, updateLoader } from '../../../../utils/common.utils';

import '../../../../styles/Tournaments.css'
import { Badge } from 'react-bootstrap';
import { PATH } from '../../../../utils/Strings.utils';

export default class Seeding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tournamentDetails: props.tournamentDetails,
            stageDetails: props.stageDetails,
            selectedGroupeIndex: props.selectedGroupeIndex ? props.selectedGroupeIndex : null,
            participants: []
        }
        this.matchApis = new MatchesApis();
    }

    componentDidMount(){
       var participantIds = this.state.stageDetails?.playerId?.map((participant) => {return participant.id} );
        var data = {
            participantIds:participantIds
        }
      this.getPlayersDetails(this.state.tournamentDetails._id,data)
    }

    getPlayersDetails = (id,data) =>{
        updateLoader(true);
        this.matchApis.findPlayersOfFirstRound(id,data).then((res) => {
            this.setState({
                participants:res.data.result
            });
            updateLoader(false);
        }).catch(
            (err) => {
            updateLoader(false);
            errorHandler(err);
            }
        );
    }


    shufflePlayersAndAddToMatches = (data) =>{
     
//        console.log(this.state.tournamentDetails._id+" :: data :: ",data)


        updateLoader(true);
        this.matchApis.shufflePlayersAndAddToFirstRoundMatches(this.state.tournamentDetails._id,data).then((res) => {
            console.log("-- RESPONSE :: ",res.data.result)
            this.props.closeSeeding(this.state.tournamentDetails,res.data.result)
            updateLoader(false);
        }).catch(
            (err) => {
            updateLoader(false);
            errorHandler(err);
        });
    }

    render() {

        var pendingPlayers;
        var addedPlayers;

        if(this.props.selectedGroupeIndex !== null && this.props.selectedGroupeIndex > -1){
            pendingPlayers = this.state.participants.filter((player)=> player.isMapped === false && player.groupIndex === this.props.selectedGroupeIndex);
            addedPlayers = this.state.participants.filter((player)=> player.isMapped === true && player.groupIndex === this.props.selectedGroupeIndex);
    
        }else{
            pendingPlayers = this.state.participants.filter((player)=> player.isMapped === false);
            addedPlayers = this.state.participants.filter((player)=> player.isMapped === true);
    
        }
        const isTeamPlayer = (this.state.tournamentDetails?.participantType === "TEAM" ? true : false);
        var data = {
            participantIds : []
        }

        pendingPlayers.map((player,i)=>{
            data.participantIds.push(player._id)
        })

        return (
            <div className={`container ${this.props.marginTopDiv}`}>
                {pendingPlayers.length > 0 ?
                    <div>
                        {
                            pendingPlayers.map((participant,index)=>{
                                return (
                                    <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2' key={index} >
                                        <div className="col-7 fw-600 fs-14 text-white text-truncate">
                                            <img className="rounded-circle btn-size-32 border border-1 mx-3" src={isTeamPlayer ?  participant?.logo?.url : participant?.profilePicture?.url} alt="owner-avatar" />
                                        {isTeamPlayer ? participant.name : participant.inGameName}
                                        </div>
                                        <div className="col-5 text-end pe-3">
                                            <Badge bg="warning theme-color">pending</Badge>
                                        </div>
                                    </div>
                                );
                                
                            })
                        }
                        <div className='row px-3 mb-5'>
                            <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12'>
                                <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-3 " >
                                    <button onClick={()=>this.shufflePlayersAndAddToMatches(data)} className="btn text-white fw-bold">
                                        Add to matches
                                    </button>
                                </div>
                            </div>
                            {/* <div className='col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-12'>
                                <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-3 " >
                                    <button onClick={()=>this.shufflePlayersAndAddToMatches(data)} className="btn text-white fw-bold">
                                        Add to matches
                                    </button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    :
                    null
                }
                

                {
                    addedPlayers.map((participant,index)=>{
                        return (
                            <div className='mulberry-purple-card d-flex align-items-center py-2 mb-2' key={index} >
                                <div className="col-7 fw-600 fs-14 text-white text-truncate">
                                    <img className="rounded-circle btn-size-32 border border-1 mx-3" src={isTeamPlayer ?  participant?.logo?.url : participant?.profilePicture?.url} alt="owner-avatar" />
                                    {/* <img className="rounded-circle btn-size-32 border border-1 mx-3" src={participant.logo.url} alt="owner-avatar" /> */}
                                {isTeamPlayer ? participant.name : participant.inGameName}
                                </div>
                                <div className="col-5 text-end pe-3">
                                    <Badge bg="success theme-color">Added</Badge>
                                </div>
                            </div>
                        );
                    })
                }
                

            </div>
        );
    }
}