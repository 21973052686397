import React, { Component } from 'react';

// Images
import participantAvtar from "../../../../../images/participantAvtar.jpg";
import groupIc from '../../../../../images/icon/group1-ic.png';

import TournamentApis from '../../../../../helper/tournament.api';
import MatchesApis from '../../../../../helper/matches.api';
//import TeamApis from '../../../../../helper/teams.api';
import { errorHandler, getMonthAndDate } from '../../../../../utils/common.utils';

export default class UpcomingMatchesFFA extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectMatch: true,
            tournamentDetails: props.tournamentDetails ? props.tournamentDetails : {},
            matches: [],
            tournamentId: props.tournamentId ? props.tournamentId : null
        }
        this.tournamentApis = new TournamentApis;
        this.matchesApis = new MatchesApis;
        //this.teamsApis = new TeamApis;
        if (props.tournamentId) {
            this.getTournamentData(props.tournamentId);
            this.getMatches(props.tournamentId, 'UPCOMING');
            //this.getMyTeam(props.match.params.id);
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            tournamentDetails:  newProps.tournamentDetails ? newProps.tournamentDetails: {},
            tournamentId: newProps.tournamentId ? newProps.tournamentId : null,
        });
    }

    getTournamentData = (id) => {
        const data = { _id: id };
        this.props.updateLoader(true);
        this.tournamentApis.getTournamentsById(data).then(
            (res) => {
                this.props.updateLoader(false);
                //this.props.dispatchData(res.data.result)
                this.setState({ tournamentDetails: res.data.result[0] })
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    getMatches = async (id, type) => {
        
        const data = { tournamentId: id, status: type };
        this.props.updateLoader(true);
        await this.matchesApis.getMatchDetailsWithParticipants(data).then(
            (res) => {
                this.props.updateLoader(false);
                //this.props.dispatchData(res.data.result)
                this.setState({ matches: res.data.result })
            }
        ).catch(
            (err) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    matchResult = () => {
        const selectMatch = this.state.selectMatch;
        this.setState({
            selectMatch: !selectMatch,
        });
    };

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            return el.profilePicture?.url
        } else {
            return el.logo.url
        }
    }

    render() {
        return (
            <section className='tournament-upcoming-matches text-white'>
                <div className='container'>
                    <div className="row">

                        {this.state.tournamentDetails?.competitionType === 'FFA' ?
                            this.state.matches.length ?
                            this.state.matches?.map((match, i) => {
                                const matchTime = match.matchSchedule
                                const scheduledTime = new Date(matchTime)
                                const matchDateTime = getMonthAndDate(match.matchSchedule)
                                const matchYear = scheduledTime.getFullYear()
                                return(
                                <div className='col-lg-4 col-md-6 col-12 mt-3' key={i}>
                                    <div className='grey-card p-3'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <p className='type mb-0'>{this.state.tournamentDetails?.competitionType}</p>
                                            </div>
                                            <div className='col-6'>
                                                <h6 className='fw-bold text-center fs-14 mb-0'>Match {i+1}</h6> 
                                            </div>
                                            <div className='col-3'></div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-6'>
                                                <h6 className='fs-12 fw-bold'>Winner</h6>
                                                {match.status === 'COMPLETED' ?
                                                    <div className='d-flex my-2'>
                                                        <img src={participantAvtar} className="rounded-circle btn-size-20" alt='' />
                                                        <h6 className="fw-bold ms-1 mt-1" style={{ fontSize: "11px" }}>Participant 1</h6>
                                                    </div>
                                                    :
                                                    <h6 className="fs-12 fw-bold ms-1" style={{ fontSize: "11px" }}>TBD</h6>
                                                }
                                            </div>
                                            <div className='col-6'>
                                                <h6 className='fs-12 fw-bold float-end'>{match.status}</h6>
                                                <h6 className='fs-12 fw-bold float-end' style={{ opacity: "0.7" }}>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <img src={groupIc} className='mt-1 me-2' height={'20px'} alt='' />
                                            <p className='fs-18 fw-bold mb-1'>{match.participants.length}</p>
                                        </div>
                                    </div>
                                </div>
                                )
                            }):
                            null
                            
                            :
                            this.state.matches.length ?
                            this.state.matches?.map((match, i) =>{
                                const matchTime = match.matchSchedule
                                const scheduledTime = new Date(matchTime)
                                const matchDateTime = getMonthAndDate(match.matchSchedule)
                                const matchYear = scheduledTime.getFullYear()
                                const participants = match.participants
                                const result = match.results
                                return(
                                    <div className='col-lg-4 col-md-6 col-12 mt-3' key={i}>
                                        <div className="grey-card py-3">
                                            <div className='ms-3 mb-3'>
                                                <h1 className='fs-16 fw-700'>Show Down <span className='fs-12 fw-bold'>( M {i+1} )</span></h1>
                                                <h1 className='fs-12 fw-700 text-white-50'>{`${matchDateTime[0]} ${matchYear} @${matchDateTime[1]}`}</h1>
                                            </div>
                                            <div className='row justify-content-around text-center mt-2'>
                                                <div className='col-5'>
                                                    <img src={participants[0] ? this.getURL(participants[0]) : participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                    <h5 className='fs-14 text-white fw-500 mt-2 text-truncate'>{participants[0] ? participants[0].name : 'Yet To Join'}</h5>
                                                </div>
                                                <div className='col-2 p-0'>
                                                    <h5 className='fs-22 text-white fw-500 mt-1'>0 - 0</h5>
                                                </div>
                                                <div className='col-5'>
                                                    <img src={participants[1] ? this.getURL(participants[1]) : participantAvtar} className="rounded-circle btn-size-45" alt='' />
                                                    <h5 className='fs-14 text-white fw-500 mt-2 text-truncate'>{participants[1] ? participants[1].name : 'Yet To Join'}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            null
                        }
                    </div>
                </div>
            </section>
        )
    }
}
