import React, { Component } from 'react';
import avatar from '../../../images/participantAvtar.jpg';

export default class Registration extends Component {

    constructor(props) {
        super(props)

        this.state = {
            member : props.member
        }
    }

    render() {
        if(this.state.member){
            return(
                <div className='col-lg-4 col-md-6 col-12'>
                    <div className='border border-1 rounded-10 my-4 px-4 pb-4'>
                        <div className='row'>
                            <div className='col-4'></div>
                            <div className='col-4 d-flex'>
                                <img src={this.state.member.profilePicture?.url} className='btn-size-45 rounded-circle mx-auto' alt='avatar' style={{ marginTop: '-24px' }} />
                            </div>
                        </div>
                        <div className='row'>
                            <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">Real Name</label>
                                <input name='realName' defaultValue={this.state.member.name} type="text" className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" readOnly />
                            </div>
                            <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">Email Address</label>
                                <input name='emailAddress' defaultValue={this.state.member.email} type="text" className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" readOnly  />
                            </div>
                            <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">In-Game Name</label>
                                <input name='inGameName' defaultValue={this.state.member.inGameName} type="text" className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" readOnly />
                            </div>
                            <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">In-Game ID</label>
                                <input name='inGameId' defaultValue={this.state.member.inGameId} type="text" className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" readOnly />
                            </div>
                            <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">Mobile Number</label>
                                <input name='mobileNumber' defaultValue={this.state.member.mobileNo?.number} type="text"  className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" readOnly />
                            </div>
                            <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">Discord ID</label>
                                <input name='discordId' defaultValue={this.state.member.inDiscordId} type="text"  className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" readOnly />
                            </div>
                            {/* <div>
                                <label htmlFor='customField' className="fs-14 fw-600 text-white mt-2 mt-lg-3">Custom Details</label>
                                <input name='inGameName' value={this.state.member.name} type="text"  className="dark-gray-card d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" />
                            </div> */}
                        </div>
                    </div>
                </div>
            );
        }else{
            return null;
        }
        
    }
}


