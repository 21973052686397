import React, { Component } from 'react';
// Assets
import { HiUserAdd } from 'react-icons/hi';
import winCup from "../../images/icon/winCup.png";
// Utils
import { disableSwap, getMonthAndDate, getStructureCount, getStructuresLineCount, showHideMatchInvite} from '../../utils/common.utils';
import { toast } from 'react-toastify';
// Commons
import ZoomButton from './ZoomButton';

export default class SingleBrackets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
            structure: props.structure ? props.structure : [],
            isSwappingEnabled: props.isSwappingEnabled ? props.isSwappingEnabled : false,
            stageMatchDetails: props.stageMatchDetails ? props.stageMatchDetails : null,
            includeThirdPlace: props.includeThirdPlace ? props.includeThirdPlace : false,
            isTournamentOperator: props.isTournamentOperator,
            tournamentDetails: props.tournamentDetails,
            stageDetails: props.stageDetails,
            style: {
                text: props.isTournamentOperator ? 'player-name' : 'fs-12 fw-bold text-black',
                border: props?.isTournamentOperator ? 'border-white' : 'border-warning',
                midLine: props?.isTournamentOperator ? null : 'border border-1 border-warning',
                label: props?.isTournamentOperator ? 'score-showdown-btn' : 'score-showdown-btn2 text-warning',
                match: props?.isTournamentOperator ? 'match-tag' : 'match-tag bg-black text-warning',
                background: (props?.isTournamentOperator && !props?.isBracketGroup) ? 'body-section' : null,
                card: props?.isTournamentOperator ? 'pink-skyblue-gradient-btn' : 'green-flat-btn',
                vacant: props?.isTournamentOperator ? 'vacant' : null
            }
        };
        this.maxWidth = 100;
        this.prevTop = -1;
        this.checkedBoxCount = 0;
    }

    // Refresh structure base on updated stage details 
    componentWillReceiveProps(newProps) {
        if (newProps.stageMatchDetails !== this.state.stageMatchDetails) {
            document.querySelectorAll('input[name="checking"]:checked').forEach(el => el.checked = false);
        }
        this.setState({
            structure: newProps.structure ? newProps.structure : this.state.structure,
            isSwappingEnabled: newProps.isSwappingEnabled ? newProps.isSwappingEnabled : this.state.isSwappingEnabled,
            stageMatchDetails: newProps.stageMatchDetails ? newProps.stageMatchDetails : this.state.stageMatchDetails,
            includeThirdPlace: newProps.includeThirdPlace ? newProps.includeThirdPlace : this.state.includeThirdPlace,
            tournamentDetails: newProps.tournamentDetails ? newProps.tournamentDetails : this.state.tournamentDetails,
            stageDetails: newProps.stageDetails ? newProps.stageDetails : this.state.stageDetails
        });
    }

    // Set zooming parameters
    setZoomState = (scale, maxWidth) => {
        this.maxWidth = maxWidth;
        this.setState({
            scale: scale
        });
    }

    // Get each rounds all matches structure
    getStructure = (num, col, top) => {
        const prevTop = this.prevTop;
        this.prevTop = top;
        const margin = (80 * (getStructuresLineCount(col)));

        return (
            <div className='d-flex' style={{ paddingRight: ((this.state.structure.length === (col + 1)) ? '0px' : '0px') }}>
                <div style={{ marginTop: ((prevTop >= 0) ? `-${prevTop + 4}px` : '-4px') }}>
                    {[...Array(num)].map((ele, i) => {
                        return <p key={i} style={{ marginTop: ((i === 0) ? '0px' : margin), height: `${margin}px`, opacity: (col === 0 ? '0' : '100') }} className={`vertical-lines ${this.state.style.border} mb-0`} />
                    })}
                </div>
                <div>
                    {[...Array(num)].map((ele, i) => {
                        var match = [];
                        match[0] = this.getMatch(i, num, col, top, false, `${margin}px`);
                        if (this.state.structure.length === (col + 1) && this.state.includeThirdPlace) {
                            match[1] = this.getMatch(i + 1, num, col, top, true, margin);
                        }
                        return match;
                    })}
                </div>
            </div>
        );
    }

    // Get match details to display in match card on brackets structure 
    getMatch = (i, num, col, top, includeThirdPlace, margin) => {
        var { style, isSwappingEnabled, structure, stageMatchDetails, tournamentDetails } = this.state;
        const column = (col + 1);
        const row = (i + 1);
        const length = structure.length;
        const lastRound = (length === column);
        const isTeamPlayer = (tournamentDetails?.participantType === "TEAM" ? true : false);
        let stageIndex = parseInt(this.props.index);
        
        let lastStageThreshold = (stageIndex !== 0) ? parseInt(this.props.stageDetails?.qualificationThreshold) : 0;
      
        try {

            const dateAndTime = getMonthAndDate(stageMatchDetails[column][i]?.matchSchedule);
            const matchDetail = stageMatchDetails[column][i];

            if (typeof matchDetail === 'object') {
                var players = [];
                players[0] = matchDetail?.participants[0];
                players[1] = matchDetail?.participants[1];


                if (matchDetail?.resultAdded) {
                    [...Array(2)].map((ele, k) => {
                        if (players[k] !== undefined) {
                            players[k].isWinner = matchDetail?.finalResult[k]?.isWinner;
                        }
                    });
                }

                const cardStyle = {
                    margin: ((lastRound && includeThirdPlace) ? `${(Math.abs((margin / 2) - 80))}px` : (((col > 0) && (i > 0)) ? `${(top * 2)}px` : '0px')),
                    size: { minWidth: '350px', maxWidth: '350px', height: '70px', marginBottom: '10px' }
                }
                const canParticipate = showHideMatchInvite(matchDetail, tournamentDetails);
                const matchNo = includeThirdPlace ? `3rd place` : ((lastRound && num === 1) ? `Final` : ((length - 1 === column && num === 2) ? `Semi ${row}` : `M ${column}.${row} `));
                return (
                    <div className='d-flex' style={{ marginTop: cardStyle.margin }} key={`${column}.${includeThirdPlace ? row + 1 : row}`} onClick={()=>this.hideAndShowScoreBoard(matchDetail, matchNo)}>
                        {col > 0 ? <hr className={`mid-line opacity-100 mt-35 ${style.midLine}`} /> : null}
                        <div style={cardStyle.size} className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-start justify-content-start outline-0 border-0 text-white ${this.state.style.card}`} >
                            <span className={style.match}>{includeThirdPlace ? `3rd place` : ((lastRound && num === 1) ? `Final` : ((length - 1 === column && num === 2) ? `Semi ${row}` : `M ${column}.${row} `))} </span>
                            <div className="row d-flex justify-content-between align-items-center w-100 mx-2 my-2">
                                {/* Registered users anf vacancy info */}
                                <div className="col-5 d-grid">
                                    {[...Array(2)].map((ele, i) => {
                                        if (players[i]) {
                                            return (
                                                // Players details or vacant info
                                                <div className='d-flex align-items-center text-truncate' key={i}>
                                                    {this.props.isSwappingEnabled ?
                                                        <input disabled={disableSwap(matchDetail)} name='checking' type='checkbox' className='me-2' id={players[i]._id} onChange={(e) => this.handleInputChange(e, matchDetail, i)} />
                                                        :
                                                        <img src={isTeamPlayer ? players[i]?.logo?.url : players[i]?.profilePicture?.url} className='btn-size-15 rounded-circle me-2' alt='' />
                                                    }
                                                    <div className='text-truncate'>
                                                        <span className={`${style.text} my-1 text-truncate`}>{isTeamPlayer ? players[i].name : players[i].inGameName}</span>
                                                    </div>
                                                    {matchDetail.resultAdded && players[i].isWinner ? <img src={winCup} className='btn-size-15 ms-2' alt='' /> : null}
                                                </div>
                                            );
                                        } else {
                                            return <span className={`player-name my-1 ${style.vacant}`} key={i}>Vacant</span>
                                        }
                                    })}
                                </div>
                                {/* Invite user OR Register user option on match */}
                                {(column === 1 && matchDetail.participants.length !== 2 && parseInt(this.props.index) !== 0 && this.props.isTournamentOperator && canParticipate) ?
                                    <div className='col-2 ps-0'>
                                        {this.props.isBracketGroup ?
                                            <button disabled={i < (this.props.placeOccupied / 2)} onClick={(e) => this.props.openInviteModal(e, matchDetail, matchDetail._id, `M ${column}.${row}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                                <HiUserAdd className="btn-size-15" />
                                            </button>
                                            :
                                            <button disabled={(i < (lastStageThreshold / 2) && (lastStageThreshold !== 1))} onClick={(e) => this.props.openInviteModal(e, matchDetail, matchDetail._id, `M ${column}.${row}`)} className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 btn-size-32 mx-2" >
                                                <HiUserAdd className="btn-size-15" />
                                            </button>
                                        }

                                    </div>
                                    :
                                    null
                                }
                                {/* Date and time */}
                                <div className="col-5 d-grid">
                                    <span className={`${style.text} text-end pe-3`}>{dateAndTime[0]}</span>
                                    <span className={`${style.text} text-end pe-3`}>{dateAndTime[1]}</span>
                                </div>
                            </div>
                        </div>
                        <hr className={`mid-line opacity-100 mt-35 ${style.midLine}`} />
                    </div>
                )
            }
        } catch (err) {
            console.error('Errors in getMatch() :: ',err)
        }
    }

    // To handle selected players for swapping
    handleInputChange = (e, matchDetail, i) => {
        this.checkedBoxCount = document.querySelectorAll('input[name="checking"]:checked').length;
        if (this.checkedBoxCount > 2) {
            e.target.checked = false;
            e.preventDefault();
            toast.warning("At a time only two players can be swapped")
        } else {
            this.props.handleInputChange(e, matchDetail, 1, i);
        }

    }

    hideAndShowScoreBoard = (matchDetails, matchNo) =>{
     
        if(!this.state.isTournamentOperator && matchDetails?.resultAdded){
            this.props.hideAndShowScoreBoard(matchDetails, matchNo)
        }
    }

    render() {
        var structure = this.state.structure;

        if (this.props.isBracketGroup) {
            structure = [...new Set(structure)];
        }
        return (
            <div>
                {/* Brackets Section */}
                <div className={`${this.state.style.background} d-flex`} >
                    <div className={`container pe-0 ${this.props.marginTopDiv}`} >
                        <div className='pb-10' id='tournament-date'>
                            <div className='d-flex overflow-auto' style={{ transform: `scale(${this.state.scale})`, transformOrigin: `0% 0% 0px`, width: `fit-content`, maxWidth: `${this.maxWidth}%` }}>
                                {structure.map((count, index) => {
                                    const structureCount = getStructureCount(index);
                                    return (
                                        <div key={index}>
                                            <div className='mb-4'>
                                                <button type="button" className={`${this.state.style.label} me-2 rounds ms-${(index === 0) ? 0 : 5}`}>ROUND {index + 1}</button>
                                            </div>
                                            {/* Fetching stage's structure */}
                                            <div key={index} style={{ paddingTop: `${structureCount}px` }} >
                                                {this.getStructure(count, index, structureCount)}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Zooming Options */}
                <div className='w-100'>
                    <ZoomButton openInviteModal2={this.props.openInviteModal2} isTournamentOperator={this.props.isTournamentOperator} stageIndex={parseInt(this.props.index)} zoom={this.zoom} scale={this.state.scale} maxWidth={this.maxWidth} setZoomState={this.setZoomState} />
                </div>
            </div>
        );
    }
}