import React, { Component } from 'react';
import { Button, Badge, Form } from 'react-bootstrap'
import { IoIosCheckmarkCircle, IoIosPeople } from 'react-icons/io';
import { CgGames } from 'react-icons/cg';
import { BsCash } from 'react-icons/bs';

//images
import alanWake from '../../../images/layout2/Alan-Wake.png'
import battlefield from '../../../images/layout2/Battlefield.png'
import cs from '../../../images/layout2/CS.png'
import fortnite from '../../../images/layout2/Fortnite.png'
import league from '../../../images/layout2/League.png'
import outrider from '../../../images/layout2/Outrider.png'
import overwatch from '../../../images/layout2/Overwatch.png'
import valorant from '../../../images/layout2/Valorant.png'

import '../../../styles/Layout.css';
import MicroWebSiteNav from '../configuration/MicroWebSiteNav';
import TeamApis from '../../../helper/teams.api';
import { errorHandler, regions, textColors, backgroundColors, secondaryTextColors, ellipsis, remainingTime, updateLoader } from '../../../utils/common.utils';
import MicroWebSiteContactUs from '../configuration/MicroWebSiteContactUs';
import TournamentApis from '../../../helper/tournament.api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { NavLink } from 'react-router-dom';
import { ASSETS } from '../../../utils/Strings.utils';
import { getAllGamesDetails } from '../../../utils/Games.utils';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class LayoutTwo extends Component {
  service = new TeamApis();
  TournamentApis = new TournamentApis();

  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData: props.microWebSiteData,
      games: [],
      background: (props.microWebSiteData ? props.microWebSiteData.color.background : 4),
      primary: (props.microWebSiteData ? props.microWebSiteData.color.primary : 4),
      secondary: (props.microWebSiteData ? props.microWebSiteData.color.secondary : 4),
      openNavbar: false,
      tournaments: [],
      selectedGame: null,
    };
    if(props.microWebSiteData){
      this.getData(props.microWebSiteData.organizationId);
    }
    getAllGamesDetails(this.setAllGamesDetails);
  }

  componentDidMount() {
    this.props.showOrHideNav(true);
  }

  setAllGamesDetails = (response) => {
    this.setState({
      games : response,
      selectedGame : response[0]
    });
  }

  getData = (id) => {

    var data = {organizationId : id}
    
    updateLoader(true);
    this.TournamentApis.getTournamentsById(data).then(
      (res) => {
        var data = res.data['result']
        this.setState({ tournaments: data })
        updateLoader(false);
      }
    ).catch(
      (err) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  selectYourGame = (e, type, i) => {
    e.preventDefault();
    this.setState({
      [type]: i
    });
  }

  toggleOpenNavbar = () => {
    this.setState({ openNavbar: !this.state.openNavbar })
  }

  getSponsors = (sponsors) => {
    return (
      (sponsors.length > 1) ?
        <div className='games-container'>
          {sponsors.map((sponsor, i) => {
            return (
              <div key={i}>
                <div className="game-card" style={{ backgroundImage: `url(${sponsor?.logo?.url})` }}></div>
                <h6 className="text-center mt-2">
                  <Badge pill bg="none" className=" mt-1" style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}>{sponsor.type + " : " + ellipsis(sponsor.name, 20)}</Badge>
                </h6>
              </div>
            )
          })}
        </div>

        :
        <div className='games-container justify-content-center'>
          {sponsors.map((sponsor, i) => {
            return (
              <div key={i}>
                <div className="game-card" style={{ backgroundImage: `url(${sponsor?.logo?.url})` }}></div>
                <h6 className="text-center mt-2">
                  <Badge pill bg="none" className=" mt-1" style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}>{sponsor.type + " : " + ellipsis(sponsor.name, 20)}</Badge>
                </h6>
              </div>
            )
          })}
        </div>
    )
  }

  render() {
    return (
      <div>
        <MicroWebSiteNav showMenu={this.props.isPreview} path={this.props.path} redirectTo={this.props.redirectTo} state={this.state} microWebSiteData={this.state.microWebSiteData} toggleOpenNavbar={this.toggleOpenNavbar} />

        <section className='layout pb-7' style={{ display: (this.state.openNavbar) ? 'none' : 'block', backgroundColor: `${this.state.background}` }}>
          {this.state.microWebSiteData ?
            <>
              <div className="clanView-banner mt-6">
                <img className="clanView-background" src={this.state.microWebSiteData?.banner?.url ? this.state.microWebSiteData.banner.url : ASSETS.DEFAULT_COVER} width="50" height="50" alt="" />
                <img className="clanView-fronImage"
                  src={this.state.microWebSiteData?.banner?.url ? this.state.microWebSiteData.banner.url : ASSETS.DEFAULT_COVER} alt="" width="50" height="50" />
              </div>
              <div className="container pb-5">
                <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>Welcome to Organization</h1>
                <section className='games my-3'>
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>Select Your Game</h1>
                  <div className='games-container mt-3'>
                    {this.state.games.map((game, i) => {
                      return (<div className='game-card d-flex' key={i} style={{ backgroundImage: `url(${game.logo.url})` }} onClick={(e) => this.selectYourGame(e, "selectedGame", game)}>
                        {this.state.selectedGame?._id === game?._id ? <IoIosCheckmarkCircle style={{ opacity: 0.7, color: `${this.state.background}` }} className="select-ic select-game" /> : null}
                      </div>);
                    })}
                  </div>
                </section>

                <section className="tournaments d-flex flex-column mx-auto my-3">
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>Upcoming Tournaments</h1>
                  <div className='row'>
                    <div className='col-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3'>
                      <Form.Select aria-label="Floating label select example" className={`select-form mx-auto`} style={{ color: `${this.state.background}`, backgroundColor: `${this.state.primary}`, border: `1px solid ${this.state.secondary}` }}>
                        {this.state.selectedGame?.regions.map((region, i) => {
                          return (
                            <option className="form-option" key={i} value={region} style={{ color: `${this.state.background}` }}>{region}</option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </div>

                  {(this.state.tournaments?.length > 0) ?
                    <section className="tournament-section tournament-carousel">
                      <div className="container text-center pt-5">
                        <p className="fs-16 text-center text-white fw-400" style={{color: `${this.state.primary}`}}>TOURNAMENTS</p>
                        <Carousel
                         responsive={responsive}
                         infinite={true}
                         autoPlay={this.props.deviceType !== "mobile" ? true : false}
                         autoPlaySpeed={1500}
                         transitionDuration={500}
                         deviceType={this.props.deviceType}
                        >
                          {this.state.tournaments?.map((tournament, i) => {
                            const timeLeft = remainingTime(tournament?.registrationEndTime);
                            return (
                              <div className="card-container mx-2" key={i}>
                                <NavLink to={`/tournament/${tournament._id}`} style={{textDecoration:'none'}}>
                                <div className="tournament-card">
                                  <div className="top-section " style={{ backgroundImage: `url(${tournament?.baner?.url})` }}>
                                    <h1 className="card-name">{tournament?.gameDetails?.name}</h1>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                    <h1 className="fw-bold text-white text-center fs-14">{tournament?.name}</h1>

                                    <div className="d-flex flex-row justify-content-between my-2">
                                      <div className="text-left">
                                        <h1 className="fs-10 text-whitefs-10 text-white">Entry Fee</h1>
                                        <h1 className="fs-10 text-white fw-700" style={{ color: '#03d158' }}>{(tournament.entryFeeAndPrizeDetails?.entryFeeAmount) ? `EC ${tournament.entryFeeAndPrizeDetails.entryFeeAmount}` : 'TBD'}</h1>
                                      </div>
                                      <div className="text-right">
                                        <h1 className="fs-10 text-white">Time Left</h1>
                                        <h1 className="fs-10 text-white fw-700" style={{ color: '#ff453a' }}>{`${timeLeft[0]} ${timeLeft[1]}`}</h1>
                                      </div>
                                    </div>
                                    <p className="fs-8 text-start text-white flex-grow-1">
                                      {ellipsis(tournament?.bio, 200)}
                                    </p>                                    
                                  </div>
                                </div>
                                </NavLink>
                              </div>
                            )
                          }
                          )}
                        </Carousel>
                      </div>
                    </section>
                    :
                    <div className="text-center">
                      <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>}
                  <button
                    className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center my-3"
                    style={{ color: `${this.state.background}`, backgroundColor: `${this.state.secondary}` }}
                    onClick={() => this.props.redirectTo('tournament')}>
                    Browse All Tournaments
                  </button>
                </section>
                {/* Sponsors */}
                <section className="sponsors my-3">
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>Our Sponsors</h1>
                  {(this.state.microWebSiteData?.sponsors?.length > 0) ?
                    this.getSponsors(this.state.microWebSiteData.sponsors)
                    :
                    <div className="text-center">
                      <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                    </div>}

                </section>

                <section className='games my-3'>
                  <h1 className={`fs-20 text-center micro-web-site-text-${this.state.primary} my-3`} style={{color: `${this.state.primary}`}}>CUSTOM IMAGES</h1>
                  <div className='games-container mt-3'>
                    {this.state.microWebSiteData?.customImage.map((el, i) => {
                      return (<div className='game-card d-flex' key={i} style={{ backgroundImage: `url(${el?.url})` }}></div>);
                    })}
                  </div>
                </section>

                {/* Social Media Contacts */}
                <MicroWebSiteContactUs microWebSiteData={this.state.microWebSiteData} backgroundColors={backgroundColors} secondaryTextColors={secondaryTextColors} background={this.state.background} secondary={this.state.secondary} />
                {this.props.isPreview ?
                  <button className="close-preview" onClick={() => this.props.openPreview(false, this.state.microWebSiteData)}>Close Preview</button>
                  :
                  null}
              </div>
              </>
            :
            <div className="mt-6">
              <div className='banner' style={{ backgroundImage: `url(${valorant})` }}></div>
              <h1 className="fs-20 text-white text-center my-3">Welcome to Organization</h1>

              <div className="container pb-5">
                <h1 className={`fs-20 text-center  my-3`}>Welcome to Organization</h1>
                <section className='games my-3'>
                  <h1 className={`fs-20 text-center my-3`}>Select Your Game</h1>
                  <div className='games-container mt-3'>
                    <div className='game-card d-flex' style={{ backgroundColor: 'grey' }}>
                      </div>
                      <div className='game-card d-flex' style={{ backgroundColor: 'grey' }}>
                      </div>
                      <div className='game-card d-flex' style={{ backgroundColor: 'grey' }}>
                      </div>
                      <div className='game-card d-flex' style={{ backgroundColor: 'grey' }}>
                      </div>
                    </div>
                </section>

                <section className="tournaments d-flex flex-column mx-auto my-3">
                  <h1 className={`fs-20 text-center my-3`}>Upcoming Tournaments</h1>
                  <div className='row'>
                    <div className='col-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3'>
                      <Form.Select aria-label="Floating label select example" className={`select-form mx-auto`} style={{ color: '', backgroundColor: '', border: '1px solid white' }}>
                            <option className="form-option" value='Asia' style={{ color: '' }}>Asia</option>
                            <option className="form-option" value='Asia' style={{ color: '' }}>Asia</option>
                            <option className="form-option" value='Asia' style={{ color: '' }}>Asia</option>
                            <option className="form-option" value='Asia' style={{ color: '' }}>Asia</option>
                      </Form.Select>
                    </div>
                  </div>
                  
                  <section className="tournament-section tournament-carousel">
                      <div className="container text-center pt-5">
                        <p className="fs-16 text-center text-white fw-400">TOURNAMENTS</p>
                        <Carousel
                          responsive={responsive}
                          infinite={true}
                          swipeable={true}
                          autoPlay={this.props.deviceType !== "mobile" ? true : false}
                          autoPlaySpeed={1500}
                          className='d-lg-flex justify-content-lg-center'
                        // slidesToSlide={1}
                        >
                               <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3"></div>
                                </div>
                              </div>
                              <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3"></div>
                                </div>
                              </div>
                              <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                  </div>
                                </div>
                              </div>
                              <div className="card-container mx-2">
                                <div className="tournament-card w-100">
                                  <div className="top-section " style={{ backgroundColor: 'grey' }}>
                                  </div>
                                  <div className="bottom-section p-2 px-3">
                                  </div>
                                </div>
                              </div>
                        </Carousel>
                      </div>
                    </section>
                  {/* <button className="fs-16 fw-700 border-0 rounded-10 px-3 py-1 align-self-center my-3"> Browse All Tournaments </button> */}
                </section>
                {/* Sponsors */}
                <section className="sponsors my-3">
                  <h1 className={`fs-20 text-center my-3`}>Our Sponsors</h1>
                  <div className='games-container'>
              <div >
                <div className="game-card" style={{ backgroundColor: 'grey' }}></div>
                <h6 className="text-center mt-2">
                  <Badge pill bg="none" className=" mt-1" style={{ color: '', backgroundColor: '' }}></Badge>
                </h6>
              </div>
              <div >
                <div className="game-card" style={{ backgroundColor: 'grey' }}></div>
                <h6 className="text-center mt-2">
                  <Badge pill bg="none" className=" mt-1" style={{ color: '', backgroundColor: '' }}></Badge>
                </h6>
              </div>
              <div >
                <div className="game-card" style={{ backgroundColor: 'grey' }}></div>
                <h6 className="text-center mt-2">
                  <Badge pill bg="none" className=" mt-1" style={{ color: '', backgroundColor: '' }}></Badge>
                </h6>
              </div>
              <div >
                <div className="game-card" style={{ backgroundColor: 'grey' }}></div>
                <h6 className="text-center mt-2">
                  <Badge pill bg="none" className=" mt-1" style={{ color: '', backgroundColor: '' }}></Badge>
                </h6>
              </div>
        </div>
                </section>
                {/* Social Media Contacts */}
                <MicroWebSiteContactUs microWebSiteData={this.state.microWebSiteData} backgroundColors={backgroundColors} secondaryTextColors={secondaryTextColors} background={this.state.background} secondary={this.state.secondary} />
                {this.props.isPreview ?
                  <button className="close-preview" onClick={() => this.props.openPreview(false, this.state.microWebSiteData)}>Close Preview</button>
                  :
                  null}
              </div>
            </div>
          }

          {/* <MicroWebSiteNav path={this.props.path} toggleOpenNavbar={this.toggleOpenNavbar} /> */}

        </section>
      </div>
    );
  }
}

export default LayoutTwo;
