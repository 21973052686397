import * as axios from "axios";
import { getToken } from "../utils/localstorage.utils";

export default class TournamentApis {
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = import.meta.env.VITE_APP_API_URL;
    }

    init = () => {
        this.api_token = getToken();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 61000,
            headers: headers,
        });

        return this.client;
    };

    getTournaments = (params) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/tournament/getTournaments?${searchString}`);
        }
        return this.init().get("/private/api/v1/tournament/getTournaments");
    }

    getOtherTournaments = (params) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/tournament/getOtherTournaments?${searchString}`);
        }
        return this.init().get("/private/api/v1/tournament/getOtherTournaments");
    }

    getMyTournaments = (params) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/tournament/getMytournaments?${searchString}`);
        }
        return this.init().get("/private/api/v1/tournament/getMytournaments");
    }

    getClanTournaments = (params) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/tournament/getClantournaments?${searchString}`);
        }
    }

    getPublicTournaments = (params) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/public/api/v1/tournament/getTournaments?${searchString}`);
        }
        return this.init().get("/public/api/v1/tournament/getTournaments");
    }

    getTournamentsById = (params) => {
        const queryString = this.queryParams(params)
        return this.init().get(`/public/api/v1/tournament/getTournaments?${queryString}`);
    };

    createTournament = (data) => {
        return this.init().post("/private/api/v1/tournament/createTournament", data);
    }

    updateTournaments = (id, data) => {
        return this.init().put(`/private/api/v1/tournament/updateTournament/${id}`, data);
    }

    update = (id, data) => {
        return this.init().put(`/private/api/v1/tournament/updateTournament/${id}`, data);
    }

    deleteTournaments = (id) => {
        return this.init().delete(`/private/api/v1/tournament/deleteTournament/${id}`);
    }

    getStageTypes = (params) => {
        if (params) {
            const searchString = this.queryParams(params)
            return this.init().get(`/private/api/v1/stageType/getStageTypes?${searchString}`);
        }
        return this.init().get("/private/api/v1/stageType/getStageTypes");
    }

    getMultipleTournaments = (data) => {
        return this.init().post("/public/api/v1/tournament/getMultipleTournaments", data);
    }

    deleteStage = (data) => {
        return this.init().post("/private/api/v1/tournament/deleteStage", data);
    }

    addOrUpdateStage = (id, data, isUpdate) => {
        return this.init().put(`/private/api/v1/tournament/addOrUpdateStage/${id}?isUpdate=${isUpdate}`, data);
    }

    addFlexibleStage = (id, data, isUpdate) => {
        return this.init().put(`/private/api/v1/tournament/addFlexibleStage/${id}?isUpdate=${isUpdate}`, data);
    }

    addOrUpdateFlexibleStage = (id, data, isUpdate) => {
        return this.init().put(`/private/api/v1/tournament/addOrUpdateFlexibleStage/${id}?isUpdate=${isUpdate}`, data);
    }


    getParticipantsOfTournament = (id) => {
        return this.init().get(`/private/api/v1/tournament/getParticipantsOfTournament/${id}`);
    }

    joinRoundRobinTournament = (data) => {
        return this.init().post("/private/api/v1/tournament/addParticipantToRoundRobin", data);
    }

    swapParticipantsBetweenGroups = (data) => {
        return this.init().post("/private/api/v1/tournament/swapParticipantsBetweenGroups", data);
    }
    createTournamentRating = (data) => {
        return this.init().post("/private/api/v1/rating/addRating", data);
    }
    getTournamentRatings = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/private/api/v1/rating/getRatings?${searchString}`);
    }
    updateTournamentRating = (id, data) => {
        return this.init().put(`/private/api/v1/rating/updateRating/${id}`, data);
    }
    addLeaderboard = (id, data) => {
        return this.init().put(`/private/api/v1/tournament/addLeaderboard/${id}`, data);
    }
    addEntryFeeAndPrize = (id, data) => {
        return this.init().put(`/private/api/v1/tournament/addEntryFeeAndPrizePool/${id}`, data);
    }
    distributePrizeToLeaderboard = (data) => {
        return this.init().post("/private/api/v1/tournament/distributePrizeToLeaderboard", data);
    }
    unPublishAndRefund = (data) => {
        return this.init().post("/private/api/v1/tournament/cancelAndRefund", data);
    }
    updateLeaderBoard = (id, data) => {
        return this.init().put(`/private/api/v1/leaderboards/updateLeaderboard/${id}`, data);
    }
    getLeaderBoard = (id) => {
        return this.init().get(`/private/api/v1/leaderboards/getleaderboard?tournamentId=${id}`);
    }
    removeParticipant = (data) => {
        return this.init().post(`/private/api/v1/tournament/removeFromTournament`, data)
    }
}