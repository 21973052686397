import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

//images , icons
import registration from "../../../images/page/registration.png";
import captain from "../../../images/page/captain.png";
import arrow from "../../../images/page/arrow.png";
import tick from "../../../images/page/tick.png";
import swords from "../../../images/page/swords.png";
import resubmit from "../../../images/page/resubmit.png";
import { FaUsers, FaEdit } from "react-icons/fa";
import {
  errorHandler,
  closeRegistrationScreen,
  updateLoader,
  mapDispatchToPropsEC,
  mapStateToPropsEC,
  getTermsAndConditionsByType,
} from "../../../utils/common.utils";
import ClanApis from "../../../helper/clan.api";
import TeamApis from "../../../helper/teams.api";
import PlayersCard from "../common/PlayersCard";
import { profile } from "../../../utils/localstorage.utils";
import WalletApi from "../../../helper/wallet.api";
import { addParticipantToFirstRound2 } from "../../../utils/Tournament.utils";
import { MultiSelect } from "react-multi-select-component";
import MatchesApis from "../../../helper/matches.api";
import { toast } from "react-toastify";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TermsConditions from "../../popup/TermsConditions";
import { TERMS_AND_CONDITIONS_TYPES } from "../../../utils/enums.utils";
import Image from "../../common/Image";

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentDetails: props.tournamentDetails,
      stageDetails: props.stageDetails,
      selectedMatchDetail: props.selectedMatchDetail,
      matchIndex: props.matchIndex,
      selectedGroupIndex: props.selectedGroupIndex
        ? props.selectedGroupIndex
        : null,
      selectedTeams: props.selectedTeams ? props.selectedTeams : null,
      registrationView: 100,
      openSuccessModal: false,
      selectedClan: -1,
      selectedTeam: -1,
      selectedTeamMember: null,
      clans: null,
      teams: null,
      makePayment: false,
      viewPlayers: false,
      balance: 0,
      membersToDistributePrizeMoney: [],
      selectedMembersToDistributePrizeMoney: [],
      addFund: false,
      amountToBeAdd: 0,
      openTermsModal: false,
      acceptCheck: false,
    };

    this.clanApi = new ClanApis();
    this.teamApi = new TeamApis();
    this.walletApi = new WalletApi();
    this.authUser = profile();
    this.getTermsData = getTermsAndConditionsByType(
      TERMS_AND_CONDITIONS_TYPES.TOURNAMENT_REGISTRATION
    );
  }

  componentDidMount() {
    this.getMyWalletInfo(this.authUser?.id);
    this.getClan();
    if (this.props.selectedTeams) {
      this.getTeamMembersDetails(this.props.selectedTeams);
    }
  }

  getTeamMembersDetails = (id) => {
    updateLoader(true);
    this.teamApi
      .getTeamMember(id)
      .then((res) => {
        this.setState({
          selectedTeams: res.data.result[0],
        });
        updateLoader(false);
      })
      .catch((err) => {
        updateLoader(false);
        errorHandler(err);
      });
  };

  getMyWalletInfo = (params) => {
    updateLoader(true);
    this.walletApi
      .getWalletInfo(params)
      .then((res) => {
        let toBeAddFund = 0;
        let entryFee =
          this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount;
        if (entryFee && entryFee > res.data.result.balance) {
          toBeAddFund = entryFee - res.data.result.balance;
        }
        this.props.dispatchData(res.data.result.balance);
        this.setState({
          balance: res.data.result.balance,
          amountToBeAdd: toBeAddFund,
        });
        updateLoader(false);
      })
      .catch((err) => {
        updateLoader(false);
        errorHandler(err);
      });
  };

  getClan() {
    updateLoader(true);
    this.clanApi
      .getClans()
      .then((res) => {
        var data = res.data["result"];
        this.setState({
          clans: data,
        });
        updateLoader(false);
      })
      .catch((err) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      });
  }

  getTeams(clanId) {
    const data = {
      clanId: clanId,
      gameId: this.state.tournamentDetails?.gameId,
    };
    updateLoader(true);
    this.teamApi
      .getMyTeams(data)
      .then((res) => {
        this.setState({
          teams: res.data.result,
        });

        updateLoader(false);
      })
      .catch((err) => {
        updateLoader(false);
        errorHandler(err);
      });
  }

  passDetails = (values) => {
    this.setState({ registrationView: 100 });
  };

  toggleModalStatus = () => {
    this.setState({ openSuccessModal: !this.state.openSuccessModal });
  };

  filter = (e, param) => {
    e.preventDefault();
    if (param === "selectedClan") {
      this.getTeams(this.state.clans[parseInt(e.target.value)]._id);
    }

    this.setState({
      [param]: e.target.value,
    });
  };

  getTeamMembers = (members, key) => {
    if (members[key] && members[key].length > 0) {
      var teamMembers = members[key].map((member, i) => {
        return (
          <div className="col-lg-4 col-md-6 col-12" key={i}>
            <div className="green-card d-flex align-items-center w-100 p-3 my-1">
              <img
                src={member?.profilePicture?.url}
                className="rounded-circle border border-white border-1 me-3 btn-size-32"
                alt=""
              />

              <p className="fs-16 fw-bold text-white my-1 flex-grow-1">
                {member.name}
              </p>
            </div>
          </div>
        );
      });

      return teamMembers;
    } else {
      return null;
    }
  };

  initiateRegistration = (e) => {
    if (!this.state.acceptCheck) {
      toast.error("Please accept the terms of service");
      return false;
    }
    if (this.state.tournamentDetails?.participantType === "SINGLE") {
      if (
        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount >
        this.state.balance
      ) {
        toast.error("Insufficient balance for registration");
        return false;
      }
    } else {
      if (
        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount >
          this.state.balance ||
        !this.state.selectedMembersToDistributePrizeMoney?.length
      ) {
        toast.error(
          "Either you have insufficient balance or you have not selected members to distribute prize money"
        );
        return false;
      }
    }
    e.preventDefault();
    var distributePrizeTo =
      this.state.selectedMembersToDistributePrizeMoney.map((value, i) => {
        return value.id;
      });
    this.joinTournament(
      this.state.teams[this.state.selectedTeam]?._id,
      distributePrizeTo
    );
  };

  updateStageDetails = (result) => {
    closeRegistrationScreen();
  };

  addParticipantInTournament = (participantId, distributePrizeTo) => {
    if (this.state.teams?.[this.state.selectedTeam]?.block) {
      toast.warning("This team is currently blocked by Espotz admin");
      return false;
    }
    if (this.state.tournamentDetails?.participantType === "SINGLE") {
      if (
        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount >
        this.state.balance
      ) {
        toast.error("Insufficient balance for registration");
        return false;
      }
    } else {
      if (
        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount >
          this.state.balance ||
        !this.state.selectedMembersToDistributePrizeMoney?.length
      ) {
        toast.error(
          "Either you have insufficient balance or you haven not selected members to distributePrizeMoney"
        );
        return false;
      }
    }
    const matchesApis = new MatchesApis();
    var isRoundRobin =
      this.state.stageDetails.subType === "Round Robin Groups" ? true : false;
    if (isRoundRobin) {
      var data = {
        tournamentId: this.state.tournamentDetails._id,
        stageIndex: this.state.stageDetails.level - 1,
        groupIndex: this.state.selectedGroupIndex,
        participantId: participantId,
        distributePrizeTo: distributePrizeTo,
      };
      updateLoader(true);
      matchesApis
        .addParticipantToRoundRobin2(data)
        .then((res) => {
          toast.success("You are participated in tournament successfully");
          closeRegistrationScreen();
          updateLoader(false);
        })
        .catch((reason) => {
          errorHandler(reason);
          updateLoader(false);
        });
    } else {
      let data = {
        participantId: participantId,
        distributePrizeTo: distributePrizeTo,
      };

      updateLoader(true);
      matchesApis
        .addParticipants2(this.state.selectedMatchDetail?._id, data)
        .then((res) => {
          toast.success("You are participated in tournament successfully");
          closeRegistrationScreen();
          updateLoader(false);
        })
        .catch((reason) => {
          errorHandler(reason);
          updateLoader(false);
        });
    }
  };

  joinTournament = (participantId, distributePrizeTo) => {
    if (!this.state.acceptCheck) {
      toast.error("Please accept the terms of service");
      return false;
    }
    const stageInfo = this.state.tournamentDetails.stages[0];
    if (
      stageInfo.type === "DUEL" &&
      (stageInfo.subType === "Single Elimination" ||
        stageInfo.subType === "Double Elimination" ||
        stageInfo.subType === "Bracket Groups")
    ) {
      var data = {
        participantId: participantId,
        distributePrizeTo: distributePrizeTo,
      };
      addParticipantToFirstRound2(
        this.state.tournamentDetails,
        data,
        this.updateStageDetails
      );
    } else {
      this.addParticipantInTournament(participantId, distributePrizeTo);
    }
  };

  makePayment = (e) => {
    e.preventDefault();
    var membersToDistributePrizeMoney = [];
    Object.keys(this.state.clans[this.state.selectedClan].members).map(
      (key, i) => {
        if (key !== "creator") {
          this.state.clans[this.state.selectedClan].members[key].map(
            (member, index) => {
              if (this.state.selectedTeamMember !== `${member.name} (${key})`) {
                member.label = `${member.name} ( ${key} )`;
                member.value = member.id;
                membersToDistributePrizeMoney.push(member);
              }
              return false;
            }
          );
        }
        return false;
      }
    );

    this.setState({
      makePayment: true,
      membersToDistributePrizeMoney: membersToDistributePrizeMoney,
    });
  };

  checkDisable = () => {
    if (this.state.tournamentDetails?.participantType === "SINGLE") {
      if (
        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount >
        this.state.balance
      ) {
        return true;
      }
      return false;
    } else {
      if (
        this.state.tournamentDetails?.entryFeeAndPrizeDetails?.entryFeeAmount >
          this.state.balance ||
        !this.state.selectedMembersToDistributePrizeMoney?.length
      ) {
        return true;
      }
      return false;
    }
  };

  updateOpenTermsModalStatus = (type) => {
    this.setState({ openTermsModal: type });
  };

  onAcceptTerms = (type) => {
    this.setState({
      openTermsModal: false,
      acceptCheck: type,
    });
  };

  getRegistrationView = (selectedTeam) => {
    if (this.state.selectedTeams) {
      return (
        <section className="d-flex flex-column">
          {/* payment view */}
          <div className="common-bg rounded-15 d-flex flex-column my-1">
            <div className="d-flex justify-content-between px-3 py-2">
              <div>
                <p className="fs-16 fw-600 text-white my-1">Entry Fee Amount</p>
              </div>
              <div>
                <p className="fs-16 fw-600 text-white text-end my-1">
                  {this.state.tournamentDetails?.entryFeeAndPrizeDetails
                    ? this.state.tournamentDetails.entryFeeAndPrizeDetails
                        .entryFeeAmount
                    : 0}{" "}
                  EC
                </p>
                <p className="fs-12 fw-600 text-white-50 my-1">
                  BAL: {this.state.balance} EC
                </p>
              </div>
            </div>
            <button
              className="flat-btn fs-16 fw-600 text-dark mx-auto mb-2"
              onClick={() => {
                this.setState({ addFund: true });
              }}
              style={{
                display:
                  this.state.tournamentDetails?.entryFeeAndPrizeDetails
                    ?.entryFeeAmount < this.state.balance
                    ? "none"
                    : "block",
              }}
            >
              Add Funds
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center my-2">
            <h1 className="fs-16 fw-600 text-white my-1">
              Who will receive the money?
            </h1>
            {/* <FaEdit className='text-white ms-2' /> */}
          </div>
          <p className="fs-14 fw-400 text-white-50 text-center">
            Prize money will be credited to wallets.It will distributed equally
            among the selected players
          </p>

          <input
            value={
              typeof this.state.selectedTeams === "object"
                ? this.state.selectedTeams.name
                : null
            }
            type="text"
            className="dark-gray-card height-45 ps-3 text-white-50"
            disabled
          />

          <div className="d-flex justify-content-center align-items-center w-100 my-3">
            <input
              type="checkbox"
              checked={this.state.acceptCheck}
              onChange={() =>
                this.setState({ openTermsModal: !this.state.openTermsModal })
              }
              className="me-2"
              id="dialog"
            />
            <label className="fs-14 fw-600 mb-0" htmlFor="dialog">
              I accept the terms of service
            </label>
          </div>

          <button
            //disabled={this.checkDisable()}
            onClick={(e) =>
              this.joinTournament(this.state.selectedTeams._id, [
                this.state?.selectedTeams?.userId,
              ])
            }
            className="black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center"
            type="submit"
          >
            <img src={swords} alt="submit" height={"20px"} />
          </button>
        </section>
      );
    } else {
      if (!this.state.makePayment) {
        if (!this.state.viewPlayers) {
          return (
            <div>
              {/* registration view */}
              <div className="row">
                <div className="col-lg-6 col-12">
                  {/* Select Clan */}
                  <select
                    onChange={(e) => this.filter(e, "selectedClan")}
                    className="user-registration-dropdown w-100 common-bg rounded-15 fs-14 fw-600 text-white my-1 px-2 border-0"
                    style={{ height: "52px", outline: "none" }}
                  >
                    <option className="text-dark" value={-1}>
                      {this.state.selectedClan >= 0
                        ? this.state.clans[this.state.selectedClan].name
                        : "Select Clan"}
                    </option>
                    {this.state.clans?.map((clan, i) => {
                      if (parseInt(this.state.selectedClan) !== parseInt(i)) {
                        return (
                          <option
                            value={i}
                            key={i}
                            disabled={!clan.isActive}
                            className="text-dark"
                          >
                            {clan.name}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
                <div className="col-lg-6 col-12">
                  {/* Select Team */}
                  <select
                    onChange={(e) => this.filter(e, "selectedTeam")}
                    className="user-registration-dropdown w-100 common-bg rounded-15 fs-14 fw-600 text-white my-1 px-2 border-0"
                    style={{ height: "52px", outline: "none" }}
                  >
                    <option className="text-dark" value={-1}>
                      {this.state.selectedTeam >= 0
                        ? this.state.teams[this.state.selectedTeam].name
                        : "Select Team"}
                    </option>
                    {this.state.teams?.map((team, i) => {
                      if (parseInt(this.state.selectedTeam) !== parseInt(i)) {
                        return (
                          <option
                            value={i}
                            key={i}
                            disabled={team.block}
                            className="text-dark"
                          >
                            {team.name}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
              </div>

              {
                this.state.selectedClan >= 0 && this.state.selectedTeam >= 0 ? (
                  <div>
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center my-3">
                        <FaUsers
                          className="text-white me-2"
                          style={{ height: "16px", width: "22px" }}
                        />
                        <h1 className="fs-16 fw-600 text-white mb-0">
                          Team Line-up
                        </h1>
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex justify-content-lg-between justify-content-end">
                        <span className="d-none d-lg-block">
                          - - - - - - - - - - - - - - - - - - - - - - - - - - -
                          - - - - - - - - -
                        </span>
                        <span className="d-flex align-items-ceter ms-auto">
                          <FaEdit
                            className="text-white my-auto me-2"
                            onClick={() => this.setState({ viewPlayers: true })}
                          />
                          <p className="fs-16 fw-600 text-white my-1">
                            PLAYERS
                          </p>
                        </span>
                      </div>

                      {/* {selectedTeam?.members?.captain ?
                                            <div className='col-lg-4 col-md-6 col-12'>
                                                <div className='green-card d-flex align-items-center w-100 p-3 my-1 mt-lg-3'>
                                                    <img src={selectedTeam.members.captain.profilePicture.url} className="rounded-circle border border-white border-1 me-3 btn-size-32" alt="" />

                                                    <p className='fs-16 fw-bold text-white my-1 flex-grow-1'>{selectedTeam?.members?.captain.name}</p>
                                                    <img src={captain} className='btn-size-20' alt='captain' />
                                                </div>
                                            </div>
                                            :
                                            null
                                        } */}

                      {selectedTeam?.members?.players?.map((player, i) => {
                        return (
                          <div className="col-lg-4 col-md-6 col-12" key={i}>
                            <div className="green-card d-flex align-items-center w-100 p-3 my-1 mt-lg-3">
                              {/* <img src={player.profilePicture.url || ""} className="rounded-circle border border-white border-1 me-3 btn-size-32" alt="" /> */}
                              <Image
                                src={player?.profilePicture?.url}
                                param={player.name}
                                className="rounded-circle border border-white border-1 me-3 btn-size-32"
                                height="30px"
                                width="30px"
                              />
                              <p className="fs-16 fw-bold text-white my-1 flex-grow-1">
                                {player.name}
                              </p>
                              <img
                                src={captain}
                                className="btn-size-20"
                                alt="captain"
                                style={{
                                  opacity:
                                    selectedTeam?.members?.captain?._id ===
                                    player._id
                                      ? 1
                                      : 0.5,
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {Object.keys(selectedTeam.members).map((key, i) => {
                      if (key !== "captain" && key !== "players") {
                        return (
                          <div className="row" key={i}>
                            <div className="d-flex justify-content-lg-between justify-content-end">
                              <span className="d-none d-lg-block">
                                - - - - - - - - - - - - - - - - - - - - - - - -
                                - - - - - - - - - - - -
                              </span>
                              <p className="fs-16 fw-600 text-white text-end my-1">
                                {key.toUpperCase()}
                              </p>
                            </div>
                            {this.getTeamMembers(selectedTeam.members, key)}
                          </div>
                        );
                      }
                    })}

                    <button
                      onClick={(e) => this.makePayment(e)}
                      className="black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center"
                      type="submit"
                    >
                      <img src={arrow} alt="arrow" height={"16px"} />
                    </button>
                  </div>
                ) : null // No Team and Clan is selected yet
              }
            </div>
          );
        } else {
          return (
            <div className="d-flex flex-column">
              {/* players view */}

              {selectedTeam?.members?.captain ? (
                <div className="row">
                  <p className="fs-16 fw-600 text-white text-center my-1">
                    PLAYERS
                  </p>
                  {/* <PlayersCard member={selectedTeam?.members?.captain} /> */}
                  {selectedTeam?.members?.players?.map((player, i) => {
                    return <PlayersCard member={player} key={i} />;
                  })}
                </div>
              ) : null}

              {Object.keys(selectedTeam.members).map((key, i) => {
                if (key !== "captain" && key !== "players") {
                  return (
                    <div className="row" key={i}>
                      <p className="fs-16 fw-600 text-white text-center my-1">
                        {key.toUpperCase()}
                      </p>
                      {selectedTeam.members[key].map((member, i) => {
                        return <PlayersCard member={member} key={i} />;
                      })}
                    </div>
                  );
                }
              })}

              <div className="row">
                <button
                  onClick={() => this.setState({ viewPlayers: false })}
                  className="black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center"
                  type="submit"
                >
                  <img src={resubmit} alt="submit" height={"20px"} />
                </button>
              </div>
            </div>
          );
        }
      } else {
        return (
          <section className="d-flex flex-column">
            {/* payment view */}
            <div className="common-bg rounded-15 d-flex flex-column my-1">
              <div className="d-flex justify-content-between px-3 py-2">
                <div>
                  <p className="fs-16 fw-600 text-white my-1">
                    Entry Fee Amount
                  </p>
                </div>
                <div>
                  <p className="fs-16 fw-600 text-white text-end my-1">
                    {this.state.tournamentDetails?.entryFeeAndPrizeDetails
                      ? this.state.tournamentDetails.entryFeeAndPrizeDetails
                          .entryFeeAmount
                      : 0}{" "}
                    EC
                  </p>
                  <p className="fs-12 fw-600 text-white-50 my-1">
                    BAL: {this.state.balance} EC
                  </p>
                </div>
              </div>
              <button
                className="flat-btn fs-16 fw-600 text-dark mx-auto mb-2"
                onClick={() => {
                  this.setState({ addFund: true });
                }}
                style={{
                  display:
                    this.state.tournamentDetails?.entryFeeAndPrizeDetails
                      ?.entryFeeAmount < this.state.balance
                      ? "none"
                      : "block",
                }}
              >
                Add Funds
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center my-2">
              <h1 className="fs-16 fw-600 text-white my-1">
                Who will receive the money?
              </h1>
              {/* <FaEdit className='text-white ms-2' /> */}
            </div>
            <p className="fs-14 fw-400 text-white-50 text-center">
              Prize money will be credited to wallets.It will distributed
              equally among the selected players
            </p>

            <MultiSelect
              className="text-dark"
              options={this.state.membersToDistributePrizeMoney}
              value={this.state.selectedMembersToDistributePrizeMoney}
              onChange={(e) =>
                this.setState({ selectedMembersToDistributePrizeMoney: e })
              }
              labelledBy="Prize Money Collectors"
            />

            <div className="d-flex justify-content-center align-items-center w-100 my-3">
              <input
                type="checkbox"
                checked={this.state.acceptCheck}
                onChange={() =>
                  this.setState({ openTermsModal: !this.state.openTermsModal })
                }
                className="me-2"
                id="dialog"
              />
              <label className="fs-14 fw-600 mb-0" htmlFor="dialog">
                I accept the terms of service
              </label>
            </div>

            <button
              //disabled={this.checkDisable()}
              onClick={(e) => this.initiateRegistration(e)}
              className="black-round-btn rounded-circle fw-600 border border-2 border-dark btn-size-40 m-auto my-5 d-flex justify-content-center align-items-center"
              type="submit"
            >
              <img src={swords} alt="submit" height={"20px"} />
            </button>
          </section>
        );
      }
    }
  };

  addFunds = () => {
    updateLoader(true);
    const details = {
      userId: this.authUser?.id,
      depositAmount: parseInt(this.state.amountToBeAdd),
    };
    this.walletApi
      .walletDeposit(details)
      .then((res) => {
        updateLoader(false);
        this.props.dispatchData(res.data.result.balance);
        this.setState({
          balance: res.data.result.balance,
          addFund: false,
        });
        toast.success("Amount deposited successfully");
      })
      .catch((err) => {
        updateLoader(false);
        errorHandler(err);
      });
  };
  render() {
    const selectedTeam = this.state.teams
      ? this.state.teams[this.state.selectedTeam]
      : null;

    return (
      <div>
        <section className="registration d-flex flex-column p-3">
          {/* Common Section */}
          <div className="d-flex align-items-center">
            <img
              src={registration}
              alt="registration"
              className="me-2 btn-size-15"
            />
            <h1 className="fs-16 fw-600 text-white mb-0">Registration</h1>
          </div>
          <div className="common-bg rounded-15 d-flex justify-content-between align-items-center px-3 py-2 my-3">
            <div>
              <p className="fs-16 fw-600 text-white my-1">
                Stage {this.state.stageDetails?.level}
              </p>
              {/* <p className='fs-12 fw-600 text-white-50 my-1'>Nov 20 - Nov 30</p> */}
            </div>
            <div>
              <p className="fs-12 fw-600 text-white-50 my-1 text-end">
                M {this.state.matchIndex}
              </p>
              {/* <p className='fs-12 fw-600 text-white-50 my-1'>Dec 15 @7:15 PM</p> */}
            </div>
          </div>
          {this.getRegistrationView(selectedTeam)}
          {/* registration & player view */}
        </section>
        <Modal
          show={this.state.openSuccessModal}
          centered
          dialogClassName="success-modal"
        >
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <button className="review-btn">
                <img src={tick} alt="" />
              </button>
              <p className="fs-20 fw-600 text-white my-2">Registered</p>
              <button
                className="flat-btn fs-16 fw-600 text-dark p-2"
                onClick={this.toggleModalStatus}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.addFund} centered dialogClassName="">
          <Modal.Body>
            <div className="p-3">
              <IoIosCloseCircleOutline
                className="close-icon position-absolute"
                style={{ right: "15px", top: "15px" }}
                onClick={() => {
                  this.setState({ addFund: false });
                }}
              />
              <p className="fw-bold fs-20 text-center text-white mb-0">
                Add Amount
              </p>

              <form>
                <input
                  type="number"
                  className="martinique-card fs-12 fw-600 p-3 mt-3 text-white w-100"
                  value={this.state.amountToBeAdd}
                  onChange={(e) => {
                    this.setState({ amountToBeAdd: e.target.value });
                  }}
                />
                <button
                  className="white-flat-btn fw-bold py-2 px-4 d-block m-auto mt-3"
                  type="button"
                  onClick={this.addFunds}
                >
                  Add
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
        {/* Terms and Services Modal */}
        <Modal
          centered
          show={this.state.openTermsModal}
          dialogClassName="social-media-modal"
        >
          <Modal.Body>
            <TermsConditions
              accept={() => {
                this.setState({ openTermsModal: false, acceptCheck: true });
              }}
              onClose={() => {
                this.setState({ openTermsModal: false, acceptCheck: false });
              }}
              terms={this.getTermsData?.rule}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default connect(mapStateToPropsEC, mapDispatchToPropsEC)(Registration);
