import React, { Component } from 'react';
import { IoNewspaperOutline } from "react-icons/io5";
import { ellipsis, getWebData } from '../../utils/common.utils';
import orgLogo from '../../images/image75.png';
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi";
import { VscFoldUp , VscFoldDown} from 'react-icons/vsc';

export default class News extends Component {

    constructor(props) {
        super(props)
      
        this.state = {
            showMore : null
        }
    
        this.websiteData = getWebData();
    
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
    }
    
    render() {
        const news = this.websiteData?.NEWS;
        
        return (
            <section className="body-section">      
                <div className="p-3 d-flex flex-column mx-auto notifications" >
                    <div className="row text-center">
                        <div className="col-12">
                        <h1 className="fs-20 fw-bold text-white mb-2 mt-3"> <IoNewspaperOutline style={{color: 'white'}} />  News </h1>
                        </div>
                    </div>
                    <div className='row '>
                        {news.map((el,i)=>{
                            return (
                                <div className="col-12 col-md-6 col-lg-4 my-2 clanPage_Cards" key={i}>
                                    <div className={`card organizationCard mb-3`}  href={el.NEWS_SOURCE}>
                                      <img src={el?.NEWS_BANNER?.url ? el.NEWS_BANNER.url : orgLogo} alt="..." className="orgCardImage" style={{height: 'auto'}} />
                                  
                                      <div className="row px-3 pb-3 pt-3">
                                        <div className="col-12 col-lg-11 m-auto">  
                                          <p className="fs-14 fw-normal text-white rounded-10 mb-1" style={{ textAlign: 'justify', overflowWrap: 'break-word', minHeight: '110px' }}>
                                            {el.NEWS_SUMMARY.length < 140 ?
                                                <><b>{el?.NEWS_HEADING}</b> :<b className='text-white-75'> {el.NEWS_SUMMARY}</b></> 
                                                :
                                                (this.state.showMore === i ? 
                                                    <><b>{el?.NEWS_HEADING}</b> :<b className='text-white-75'> {el.NEWS_SUMMARY}</b> <a className="fs-14 fw-bold" href={el.NEWS_SOURCE}>Source Link</a> <VscFoldUp className="fs-14 fw-bold" onClick={() => this.setState({ showMore : null })} /> </> 
                                                    : 
                                                    <><b>{el?.NEWS_HEADING}</b> :<b className='text-white-75'>  {ellipsis(el.NEWS_SUMMARY, 140)}</b><VscFoldDown className="fs-14 fw-bold" onClick={() => this.setState({ showMore : i })} /> </>
                                                )
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              );
                        })}
                    </div>
                </div>
            </section>
        )
    }

}
