import React, { Component } from 'react';
// Style
import '../../../styles/Tournaments.css';
// Utils
import { capitalize, errorHandler, getTournamentAdditionalData, streamingPlatforms, updateLoader } from '../../../utils/common.utils';
import { mapStateToProps, mapDispatchToProps } from '../../../utils/Tournament.utils';
import { toast } from 'react-toastify';
import validator from 'validator';
import { connect } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// Modals
import { Modal, Accordion } from "react-bootstrap";
import ConfirmationModal from '../../popup/ConfirmationModal';
// Services
import TournamentApis from '../../../helper/tournament.api';
import MatchesApis from '../../../helper/matches.api';
// Commons
import TournamentHeader from './common/TournamentHeader';
import TournamentFilter from './common/TournamentFilter';
// Assets
import { AiFillPlusCircle } from "react-icons/ai";
import streamingIc from '../../../images/icon/streaming-ic.png';
import link from '../../../images/games/link.png';
import refresh from '../../../images/icon/refresh-ic.png';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";

class TournamentStreams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options: props?.tournamentData?._id ? props?.tournamentData?.streams : [],
            linkModal: false,
            formikDetails: null,
            operationId: null,
            organizationId: props.match.params?.orgId,
            tournamentDetails: props?.tournamentData?._id ? props?.tournamentData : {},
            openConfirm: false,
            confirm: {
                msg: '',
                key: ''
            },
            deleteItemIndex: null,
            linkInput: '',
            selectedStream: streamingPlatforms[0],
            stage: 0,
            group: 0,
            round: 1,
            matches: [],
            showLoosingBracket: false
        }
        this.tournamentApis = new TournamentApis();
        this.matchesApis = new MatchesApis();

    }


    componentDidMount() {
        this.props.showOrHideNav(true);
        if (!this.props?.tournamentData?._id) {
            getTournamentAdditionalData(this.props.match.params.id, this.setData);
        }
    }

    // When data not came from location.state it returns data from API
    setData = (values) => {
        if (values) {
            this.setState({
                tournamentDetails: values,
                organizationId: values.organizationId,
                options: values?.streams ? values.streams : []
            });
            if (values.stages.length > 0) {
                this.filterMatches(0, 0, 1);
            }

            this.props.dispatchData(values)
        } else {
            this.props.history.push('/pageNotFound')
        }
    }

    pushLink = (values) => {
        let data = this.state.options;
        data.push(values);
        this.setState({ options: data, linkModal: false, selectedStream: capitalize(streamingPlatforms[0]) })
    }

    toUpdate = (data, i) => {
        const stream = streamingPlatforms.find((platform) => platform === data.label);
        this.setState({
            formikDetails: data,
            linkModal: true,
            operationId: i,
            selectedStream: stream || streamingPlatforms[0],
            linkInput: data.link
        });
    };


    updateLink = (values) => {
        let data = this.state.options
        data[this.state.operationId].label = values.label
        data[this.state.operationId].link = values.link
        this.setState({ options: data, formikDetails: null, linkModal: false })
    }

    deleteLink = (i) => {
        let data = this.state.options
        data.splice(i, 1)
        this.setState({ options: data, linkModal: false, openConfirm: false })
    }

    detailsDeleteMsg = (name) => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">
                Are you sure you want to delete {name} from streams ?
            </p>
        );
    };

    onClickOpenConfirm = (key, msg) => {
        const data = {
            msg: msg ? msg : "",
            key: key ? key : "",
        };
        this.setState({
            openConfirm: !this.state.openConfirm,
            confirm: data,
        });
    };

    onSubmit = () => {
        const data = {
            streams: this.state.options
        }
        updateLoader(true);
        this.tournamentApis.updateTournaments(this.props.match.params.id, data).then(
            (res) => {
                updateLoader(false);
                this.props.dispatchData(res.data.result)
                toast.success("Streams updated successfully");
                this.props.history.push({
                    pathname: `/organization/${res.data.result.organizationId}/tournament/${this.props.match.params.id}/overview`,
                    state: {
                        orgId: res.data.result.organizationId,
                        tournamentDetails: res.data.result
                    }
                });
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    updateLinkInput = (e) => {
        e.preventDefault()
        this.setState({ linkInput: e.target.value })
    }

    updateSelectedStream = (val) => {
        this.setState({ selectedStream: val });
    }

    validateLink = (val) => {
        let error;
        if (!validator.isURL(val)) {
            error = 'Enter a valid URL';
        }
        if (this.state.selectedStream !== 'others' && !val.includes(this.state.selectedStream)) {
            error = 'Enter URL of selected stream';
        }
        return error;
    }

    filterMatches = (stage, group, round) => {

        this.setState({
            stage: stage,
            group: group,
            round: round
        });
        if (this.state.tournamentDetails?.stages[stage]?.subType === 'Bracket Groups' && this.state.tournamentDetails?.stages[stage]?.bracketType === 'DOUBLE') {
            this.getMatchDetails(this.state.tournamentDetails?.stages[stage]?.groups[this.state.showLoosingBracket ? 1 : 0][group][round]);
        } else {
            this.getMatchDetails(this.state.tournamentDetails?.stages[stage]?.groups[group][round]);
        }

    }

    getMatchDetails = (matches) => {

        var data = {
            ids: matches
        }
        updateLoader(true)
        this.matchesApis.getMultipleMatches(data).then(
            (res) => {
                updateLoader(false)

                this.setState({
                    matches: res.data.result
                })
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err);
            }
        )
    }
    onLinkChange = (e, key, i) => {
        // e.preventDefault();
        let tempMatches = this.state.matches
        if (tempMatches[i].matchStreamingLink) {
            tempMatches[i].matchStreamingLink[key] = e.target.value
        } else {
            let matchStreamingLink = {
                [key]: e.target.value
            }
            tempMatches[i] = { ...tempMatches[i], matchStreamingLink }
        }

        this.setState({ matches: tempMatches })
    }
    submitMatchStreamingLink = (e, matchId) => {
        e.preventDefault();
        var label = e.target.label.value;
        var link = e.target.link.value;
        const data = {
            matchStreamingLink: {
                label,
                link,
                id: ""
            }
        }
        if (!validator.isURL(link)) {
            toast.error('Enter a valid URL');
            return false;
        }
        if (!link.includes(label)) {
            toast.error('Enter URL of selected stream');
            return false
        }
        updateLoader(true);
        this.matchesApis.editMatchDetails(matchId, data)
            .then((res) => {
                updateLoader(false);
                toast.success('Match streaming link added successfully')
            }).catch((reason) => {
                updateLoader(false);
                errorHandler(reason);
            });

    }

    switchWinnersBracket = (value, stage, group, round) => {
        this.setState({
            showLoosingBracket: value
        });
        this.getMatchDetails(this.state.tournamentDetails?.stages[stage]?.groups[group][value ? 1 : 0][round]);
    }

    render() {

        const { stage, group, round, matches, tournamentDetails } = this.state;

        if (tournamentDetails?.stages) {
            const isDoublesBracketGroup = (tournamentDetails?.stages[stage]?.subType === 'Bracket Groups' && tournamentDetails?.stages[stage]?.bracketType === 'DOUBLE');
            return (
                <section className="body-section tournament-stream-page">
                    <div className="container p-3 mt-lg-3">
                        <TournamentHeader tournamentDetails={this.state.tournamentDetails} logo={streamingIc} heading="Streams" subHeading={null} history={this.props.history} />

                        {/* Tournaments Streaming Links */}
                        <p className="fs-14 fw-600 text-white mb-0 mt-2 mt-lg-3 ms-3">Tournaments Streaming Links</p>
                        {/* List of Links */}
                        {this.state.options.map((el, i) => {
                            return (
                                <div className="my-3 p-3 border-1 mt-3 mt-lg-5" key={i} style={{ borderRadius: '8px', border: '1px solid' }}>
                                    <div className="col-10 offset-1 d-flex justify-content-center">
                                        <p className='fs-14 fw-600 my-0'>Stream {i + 1}</p>
                                        <FiEdit className="text-white my-0 ms-3" onClick={() => { this.toUpdate(el, i) }} />
                                        <HiOutlineTrash className="text-white my-0 ms-2" onClick={() => {
                                            this.onClickOpenConfirm(i, this.detailsDeleteMsg(el.label))
                                        }}
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor='stream' className="fs-14 fw-600 text-white mb-2">Label</label>
                                        <div className="">
                                            <input type="url" placeholder="www.sdf.ajh/akjd" value={capitalize(el?.label)} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" disabled />
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <label htmlFor='stream' className="fs-14 fw-600 text-white mb-2">Tournament's Link</label>
                                        <div className="">
                                            <img src={link} className="btn-size-20 ms-3 position-absolute" style={{ marginTop: '11px' }} alt='' />
                                            <input type="url" value={el?.link} placeholder="www.sdf.ajh/akjd" className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 height-45" disabled />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* Add more links action */}
                        <div className='row mt-3 mt-lg-5 mb-5'>
                            <div className="col-lg-6 col-md-6 col-10 offset-1 black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10" onClick={() => { this.setState({ linkModal: true, formikDetails: null }) }}>
                                <button className="btn" >
                                    <AiFillPlusCircle className="btn-size-20 text-white" />
                                </button>
                            </div>
                        </div>


                        {/* Matches Streaming Links */}
                        <p className="fs-14 fw-600 text-white mb-1 mt-2 mt-lg-3 ms-3">Matches Streaming Links</p>
                        {tournamentDetails?.stages?.length > 0 ?
                            isDoublesBracketGroup ?
                                <TournamentFilter selectedGroupIndex={this.state.showLoosingBracket ? 1 : 0} isBracketGroupsDoubleElimination={true} stageIndex={this.state.stage} filterMatches={this.filterMatches} state={this.state} groups={tournamentDetails?.stages[stage]?.groups} groupIndex={this.state.group} />
                                :
                                <TournamentFilter isBracketGroupsDoubleElimination={false} stageIndex={this.state.stage} filterMatches={this.filterMatches} state={this.state} groups={tournamentDetails?.stages[stage]?.groups} groupIndex={this.state.group} />
                            :
                            null
                        }

                        {isDoublesBracketGroup ?
                            <div className="btn-group" style={{ width: '100%' }} role="group" aria-label="Basic example">
                                <button type="button" onClick={() => this.switchWinnersBracket(false, stage, group, round)} style={{ width: '50%', opacity: !this.state.showLoosingBracket ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds mb-4`}>Winners</button>
                                <button type="button" onClick={() => this.switchWinnersBracket(true, stage, group, round)} style={{ width: '50%', opacity: this.state.showLoosingBracket ? '1' : '0.4' }} className={`grey-card rounded-1 text-white score-showdown-btn  rounds mb-4`}>Lossers</button>
                            </div>
                            :
                            null
                        }

                        <Accordion defaultActiveKey="1">
                            {this.state.matches.map((match, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index} className='mt-3 rounds-time' style={{ backgroundColor: '#170d2a' }}>
                                        <Accordion.Header className='round-slot-details rounds-time'><b>Match {group + 1}.{round}.{index + 1}</b></Accordion.Header>
                                        <Accordion.Body className='row justify-content-center'>
                                            <div className="">
                                                <form autoComplete='off' onSubmit={(e) => this.submitMatchStreamingLink(e, match._id)} >
                                                    <div className="">
                                                        <label htmlFor="link" className="fs-14 fw-600 text-white"> Name</label>
                                                        <select name="label" onChange={(e) => { this.onLinkChange(e, 'label', index) }} value={match.matchStreamingLink ? match.matchStreamingLink.label : streamingPlatforms[0]} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45">
                                                            {streamingPlatforms.map((platform, i) => {
                                                                return <option value={platform} key={i} >{capitalize(platform)}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="mt-3">
                                                        <label htmlFor="name" className="fs-14 fw-600 text-white"> Link</label>
                                                        <input name="link" type="text" placeholder="Enter stream URL" onChange={(e) => { this.onLinkChange(e, 'link', index) }} value={match.matchStreamingLink ? match.matchStreamingLink.link : ''} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" />
                                                    </div>
                                                    <button type="submit" className="white-flat-btn d-flex fw-bold py-1 px-4 m-auto mt-3">Submit</button>
                                                </form>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                        </Accordion>

                    </div>

                    <div className="d-flex justify-content-center">
                        <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-4 btn-size-45 cursor-pointer position-fixed" style={{ bottom: '60px' }} type="submit" onClick={this.onSubmit}>
                            <img src={refresh} alt="" className='btn-size-20' />
                        </button>
                    </div>

                    {/* Add tournaments streaming link modal */}
                    <Modal show={this.state.linkModal} centered dialogClassName="social-media-modal">
                        <Modal.Body>
                            <div className="p-3">
                                <IoIosCloseCircleOutline
                                    className="close-icon position-absolute" style={{ right: '15px' }}
                                    onClick={() => { this.setState({ linkModal: false, linkInput: '' }) }}
                                />
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        label: this.state.formikDetails?.label ? this.state.formikDetails.label : this.state.selectedStream,
                                        link: this.state.formikDetails?.link ? this.state.formikDetails.link : '',
                                        id: this.state.formikDetails?._id ? this.state.formikDetails._id : '',
                                    }}

                                    validationSchema={Yup.object({
                                        label: Yup.string(),
                                        //.required("Please enter label"),
                                        link: Yup.string().required('Please enter website'),
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            if (this.state.formikDetails?.link) {
                                                this.updateLink(values)
                                            } else {
                                                this.pushLink(values)
                                            }

                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    <Form className="mt-3" autoComplete='off'>
                                        <div className="">
                                            <label htmlFor="link" className="fs-14 fw-600 text-white"> Name</label>

                                            <Field name="label" as="select"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                value={this.state.selectedStream || streamingPlatforms[0]}
                                                onChange={(e) => this.updateSelectedStream(e.target.value)}>
                                                {streamingPlatforms.map((platform, i) => {
                                                    return <option value={platform} key={i}>{capitalize(platform)}</option>
                                                })}
                                            </Field>
                                            <ErrorMessage component="span" className="error-msg" name="label" />
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="name" className="fs-14 fw-600 text-white"> Link</label>
                                            <Field name="link" type="url" placeholder="Enter stream URL" validate={this.validateLink} className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45" />
                                            <ErrorMessage component="span" className="error-msg" name="link" />
                                        </div>
                                        <button type="submit" className="white-flat-btn d-flex fw-bold py-1 px-4 m-auto mt-3" >
                                            {this.state.formikDetails?.label ? 'Update' : 'Add'}
                                        </button>
                                    </Form>
                                </Formik>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* Confirmation modal */}
                    <Modal show={this.state.openConfirm} entered dialogClassName="Confirmation-modal" >
                        <Modal.Body>
                            <ConfirmationModal confirm={this.state.confirm} onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.deleteLink} />
                        </Modal.Body>
                    </Modal>
                </section>
            )
        } else {
            return (
                <div>
                    No record found
                </div>
            )
        }

    }
}

export default (connect(mapStateToProps, mapDispatchToProps))(TournamentStreams)