import React, { Component } from 'react';
// Assets
import LoaderImage from '../../images/common/Loader.png';

export default class Loader extends Component {

    componentDidMount() {
        document.body.style.overflow = "hidden"
    }

    componentWillUnmount(){
        document.body.style.overflow = "scroll"
    }

    render() {
        return (
            <div>
                {/* Loader Section */}
                <div className="application-loading-container ">
                    <img className="image" src={LoaderImage} alt="" width="120" height="120"></img>
                </div>
            </div>
        )
    }
}
