import React from 'react';
import Image from "../components/common/Image";
import BettingApis from "../helper/betting.api";
import MatchesApis from "../helper/matches.api";
import { errorHandler, updateLoader } from "./common.utils";

const bettingApis = new BettingApis();
const matchesApis = new MatchesApis();

export const getBettingStatements = (matchId, setBettingStatements) => {

    updateLoader(true);
    bettingApis.getBettingStatements(matchId).then(
        (res) => {
            updateLoader(false);
            console.log("-------- res :: ", res.data.result);
            setBettingStatements(res.data.result);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err);
            setBettingStatements([]);
        }
    )
}

export const getMatchDetails = (matchId, setMatchDetails) => {

    var data = { ids: [matchId] };
    updateLoader(true);
    matchesApis.getMultipleMatches(data).then(
        (res) => {
            var response = res.data['result']
            setMatchDetails(response[0])
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            setMatchDetails(null)
            errorHandler(err);
        }
    )
}

export const getUpcomingBets = (tournamentId, setMatchDetails) => {
    //console.log("---tournamentId :: ",tournamentId)
    updateLoader(true);
    bettingApis.getUpcomingBets(tournamentId).then(
        (res) => {
            console.log("res--------", res)
            var response = res.data['result']
            setMatchDetails(response)
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            setMatchDetails(null)
            errorHandler(err);
        }
    )
}

export const getAllUpcomingBets = (queryParam, setMatchDetails) => {

    updateLoader(true);
    bettingApis.getAllUpcomingBets(queryParam).then(
        (res) => {
            console.log("res--------", res)
            var response = res.data['result']
            setMatchDetails(response)
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            setMatchDetails(null)
            errorHandler(err);
        }
    )
}



export const getPlayersLogoGrid = (match) => {

    return (
        <div className="avatars ms-3 d-flex mb-2">
            <span className="avatar" >
                {match?.participants?.length <= 5 ?
                    match?.participants.map((player, i) => {
                        //console.log(`player ${i+1}`, player);
                        //return <img className='btn-size-32 border border-1' key={i} src={player?.profilePicture?.url} alt="profile-logo" width="50" height="50" />
                        return <Image src={match?.participants[i]?.profilePicture ? match?.participants[i].profilePicture?.url : match?.participants[i].logo?.url} param={match?.participants[i]?.profilePicture ? player.inGameName : player.name} className='btn-size-32 border border-1' key={i} width="50" height="50" />
                    })
                    :
                    [...Array(5)].map((key, i) => {
                        //console.log("player :: ",match?.participants[i])
                        //return <img className='btn-size-32 border border-1' key={i} src={match?.participants[i]?.profilePicture? match?.participants[i].profilePicture?.url : match?.participants[i].logo?.url} alt="profile-logo" width="50" height="50" />
                        return <Image src={match?.participants[i]?.profilePicture ? match?.participants[i].profilePicture?.url : match?.participants[i].logo?.url} param={match?.participants[i]?.profilePicture ? match?.participants[i].inGameName : match?.participants[i].name} className='btn-size-32 border border-1' key={i} width="50" height="50" />
                    })
                }
            </span>
            {match?.participants?.length >= 5 &&
                <span className="avatar d-inline-flex">
                    <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center bg-secondary border-0'>{match?.participants?.length - 5}+</p>
                </span>
            }
        </div>
    );
}

export const getTeamsAndPlayers = (type, gameResult) => {
    var teams = [];
    var players = [];

    if (gameResult) {
        if (type === 'TEAM') {
            Object.keys(gameResult).forEach((el, i) => {
                teams.push(gameResult[i].teamDetails);
                if (gameResult[i].individualScore) {
                    gameResult[i].individualScore.forEach((val) => {
                        players.push(val.participantDetails);
                    });
                }
            });
        } else {
            Object.keys(gameResult).forEach((el, i) => {
                players.push(gameResult[i].participantDetails);
            });
        }

    }
    return [teams, players];
};

export const getBets = (queryParam, setBets) => {
    updateLoader(true);
    bettingApis.getUserBets(queryParam).then(
        (res) => {
            setBets(res.data['result']);
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err);
        }
    )
}

export const getMyBets = (queryParam, setBets) => {
    updateLoader(true);
    bettingApis.getUserBetsFromTournament(queryParam).then(
        (res) => {
            setBets(res.data['result']);
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err);
        }
    )
}

export const getUserBetMatches = (queryParam, setBets) => {
    updateLoader(true);
    bettingApis.getUserBetMatchesFromTournament(queryParam).then(
        (res) => {
            setBets(res.data['result']);
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err);
        }
    )
}

export const getAllPastBets = (queryParam, setMatchDetails) => {

    updateLoader(true);
    bettingApis.getPastBets(queryParam).then(
        (res) => {
            console.log("res--------", res)
            var response = res.data['result']
            setMatchDetails(response)
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            setMatchDetails(null)
            errorHandler(err);
        }
    )
}

export const checkBettingStatus = (time) => {
    var matchTime = new Date(time);
    var currentTime = new Date();
    var timeDifference = matchTime - currentTime;
    if (timeDifference > 300000) {
        return true;
    } else {
        return false;
    }
}

export const getTournamentAllBetMatches = (params, setAllBets) => {
    updateLoader(true);
    bettingApis.getAllTournamentBetMatches(params).then(
        (res) => {
            setAllBets(res.data['result']);
            updateLoader(false);
        }
    ).catch(
        (err) => {
            updateLoader(false);
            errorHandler(err);
        }
    )
}