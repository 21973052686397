import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
// Style
import {Navbar, Nav, Container} from 'react-bootstrap';
// Components
import MicroWebSiteContactUs from './MicroWebSiteContactUs';
// Utils
import { backgroundColors, secondaryTextColors } from '../../../utils/common.utils';
// Assets
import { AiOutlineMenu } from 'react-icons/ai';

export default class MicroWebSiteNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      microWebSiteData : props.microWebSiteData,
      background: (props.microWebSiteData ? props.microWebSiteData.color.background : 4),
      primary: (props.microWebSiteData ? props.microWebSiteData.color.primary : 4),
      secondary: (props.microWebSiteData ? props.microWebSiteData.color.secondary : 4)
    };

    this._isMounted = false;

  }
  
  componentDidMount(){  
    this._isMounted = true;
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  navbarStatus = () => {
    this.props.toggleOpenNavbar()
  }
  
  render() {

    return(
      <section className="org-navbar">
        <Navbar className='fixed-top micro-website-navbar' bg="dark" expand="lg" style={{backgroundImage:'none'}}>
          <Container>
            <Navbar.Brand href="#home" style={{color:'#fff'}}>
              {this.state.microWebSiteData ?
                <div className='d-flex align-items-center p-0'>
                  <img src={this.state.microWebSiteData.logo.url} alt="" className="" style={{borderRadius:'8px', width:'75px', height:'45px'}} /> 
                  <p className={`fs-16 fw-700 micro-web-site-text-${this.state?.primary} my-0 ms-3`}>{this.state.microWebSiteData?.title}</p>
                </div>          
              :
                <div className='d-flex align-items-center p-0'>
                  <img src={"https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/Screenshot_from_2021-12-07_17-09-15-removebg-preview.png"} alt="" className="" style={{width:'75px',height:'45px'}} />             
                  <p className={`fs-16 fw-700 micro-web-site-text-${this.state?.primary} my-0 ms-3`}>Website Heading</p>
                </div>
              }
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.navbarStatus} disabled={this.props.showMenu}>
              <AiOutlineMenu className={`btn-size-25 micro-web-site-text-${this.state?.primary}`} style={{color: 'white'}} />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="d-flex align-items-center">
                <NavLink to={`/${this.props.path}/home`} onClick={()=>this.props.redirectTo(this.props.path)} className={`fs-16 fw-700 mx-4 my-2 micro-web-site-text-${this.state?.primary}`} >Home</NavLink>
                <NavLink to={`/${this.props.path}/tournament`} onClick={()=>this.props.redirectTo("tournament")}  className={`fs-16 fw-700 mx-4 my-2 micro-web-site-text-${this.state?.primary}`}>Browse Tournaments</NavLink>
                <NavLink to={`/${this.props.path}/about`} onClick={()=>this.props.redirectTo("about")} className={`fs-16 fw-700 mx-4 my-2 micro-web-site-text-${this.state?.primary}`}>About Us</NavLink>
              </Nav>
              <div className="nav-bottom-section mx-auto d-lg-none">
                <MicroWebSiteContactUs microWebSiteData={this.state.microWebSiteData} backgroundColors={backgroundColors} secondaryTextColors={secondaryTextColors} background={5} secondary={this.state.secondary}/>
              </div>            
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </section>
    );
  }
}