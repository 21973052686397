import React, { Component}from 'react';
//images
import matrix from '../../../images/score/matrix.png';
import participantAvtar from "../../../images/participantAvtar.jpg";
import MatchesApis from '../../../helper/matches.api';
import { DisputeStatus, errorHandler, updateLoader, defaultPreviewImage } from '../../../utils/common.utils';
import { FiCheck } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { RiFileCopy2Line } from 'react-icons/ri';
import { Modal } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import ImagePreview from '../../common/ImagePreview';
import { ASSETS } from '../../../utils/Strings.utils';
import {profile} from '../../../utils/localstorage.utils'

export default class Disputes extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tournamentDetails: props.tournamentDetails,
            disputes: [],
            openDisputeManagement: false,
            openConfirm: false,
            confirm: {
                msg: '',
                key: ''
            },
            actionType: '',
            selectedDisput: null,
            matchDetails: null,
            sortedParticipants: [],
            previewImageDetails: {
                display: false,
                src: defaultPreviewImage
            },
            showAllBets: true
        }
        this.MatchesApis = new MatchesApis();
        this.authUser = profile();
    }

    componentDidMount() {
        const data = {
            tournamentId: this.props.tournamentDetails?._id,
            userId: this.authUser?.id
        }
        this.getMyDisputes(data);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            tournamentDetails: nextProps.tournamentDetails
        })
    }

    getMatchDetails = (disputData) => {
        var data = {
            ids: [disputData.matchId]
        }
        updateLoader(true)
        this.MatchesApis.getMultipleMatches(data).then(
            (res) => {
                this.createFinalResult(res.data.result[0])
                updateLoader(false)
                var response = res.data['result'][0]
                this.setState({
                    matchDetails: response,
                    selectedDisput: disputData,
                    openDisputeManagement: true
                })
            }
        ).catch(
            (err) => {
                updateLoader(false);
                errorHandler(err);
            }
        )
    }

    getMyDisputes = (params) => {
        updateLoader(true)
        this.MatchesApis.getMyDisputes(params).then(
            (res) => {
                updateLoader(false)
                this.setState({ disputes: res.data.result })
            }
        ).catch(
            (err) => {
                updateLoader(false)
                errorHandler(err)
            }
        )
    }

    noOfGameWon = (playerIndx) => {
        let count = 0
        this.state.matchDetails?.results.map((el, i) => {
            if (el[playerIndx]?.isWinner) {
                count = count + 1
            }
        })
        return count
    }

    createFinalResult = (matchDetails) => {
        var participants = [];
        matchDetails.participants.forEach((participant, index) => {
            participant['result'] = [];
            participant['finalResult'] = {};
            var result = []
            matchDetails.results.forEach((res, i) => {
                var r = matchDetails.results[i][index];
                result[i] = r;
            });
            participant.finalResult = matchDetails.finalResult[index];
            participant.result = result;
            participants.push(participant);
        });
        let sortedResult = participants.sort((a, b) => parseFloat(b.finalResult.score) - parseFloat(a.finalResult.score));
        this.setState({ sortedParticipants: sortedResult })
    }

    getURL = (el) => {
        if (this.state.tournamentDetails.participantType === 'SINGLE') {
            return el.profilePicture?.url
        } else {
            return el.logo.url
        }
    }

    previewImage = (src, param) => {
        this.setState({
          previewImageDetails: {
            display: param,
            src: (param ? src : defaultPreviewImage)
          }
        });
    }

    getDisputeCards = () => { 
        return(
            <div className='row'>
                {this.state.disputes.map((el, i) => {
                    return (
                        <div className='col-lg-4 col-md-6 col-12' key={i}>
                            <div className='port-gore-card mt-4 pt-2 pb-2' style={{background:'none'}}>
                                <div className='row p-3'>
                                    <div className='col-6'>
                                        <h6 className='fw-bold text-warning mb-0 mt-1'>Dispute #{i + 1} <RiFileCopy2Line className='btn-size-20 cursor-pointer ms-2 mb-1' onClick={() => { this.getMatchDetails(el) }} /></h6>
                                    </div>
                                    <div className='col-6'>
                                        <span className='fs-14 fw-500 float-end mt-1'>{el?.disputeStatus}</span>
                                    </div>

                                    <div className='col-12 my-1'>
                                        <h6 className='fw-bold'>Title</h6>
                                        <p className='fw-normal text-white-50 fs-14 my-0'>{el?.disputeTitle}</p>
                                    </div>
                                    <div className='col-12 my-1'>
                                        <h6 className='fw-bold'>Dispute</h6>
                                        <p className='fw-normal text-white-50 fs-14 text-break my-0'>{el?.dispute}</p>
                                    </div>
                                    <div className='col-12 my-1'>
                                        <h6 className='fw-bold mb-0'>Attachments</h6>
                                        <div className='d-flex mt-2'>
                                            {
                                                el?.images?.map((el, j) => {
                                                    return (
                                                        <span key={j}>
                                                            <img src={el?.url} onClick={() => this.previewImage(el?.url, true)} alt='' className='mx-2 rounded-2' height="50px" width="50px" />
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }

    render() {
        return (
            <section className='tournament-scoreboard-page text-white p-3'>
                <div className='container'>
                    <div className='d-flex align-items-center mb-3'>
                        <img src={matrix} alt='' />
                        <p className='fs-16 fw-bold my-0 ms-2'>Disputes</p>
                     </div>
                    {/*<div className='m-auto'>
                        <button className={` ${this.state.showAllBets ? 'clementine-flat-btn' : 'dim_gray-flat-btn'} fs-14 fw-500 px-3 py-1 rounded rounded-2 border-0 text-white me-2`} type="submit" onClick={() => { this.setState({ showAllBets: true}) }}>
                            All Bets
                        </button>
                        <button className={`${!this.state.showAllBets ? 'clementine-flat-btn' : 'dim_gray-flat-btn'} fs-14 fw-500 px-3 py-1 rounded rounded-2 border-0 text-white ms-2`} type="submit" onClick={() => { this.setState({ showAllBets: false }) }}>
                            My Bets
                        </button>
                    </div> */}
                    {this.state.disputes?.length ? 
                        this.getDisputeCards()
                        :
                        <div className="text-center">
                            <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                        </div>
                    }
                    
                </div>
                <Modal centered show={this.state.openDisputeManagement} dialogClassName="dispute-management-modal">
                    <Modal.Body>
                        <div className='pt-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <RiFileCopy2Line className='btn-size-20 position-absolute text-warning' style={{ right: '50%' }} />
                                    <AiFillCloseCircle className="btn-size-20 text-white float-end me-3" onClick={() => { this.setState({ openDisputeManagement: false }) }} />
                                </div>

                                <div className='col-12 mt-4 ps-5'>
                                    <h6 className='text-white fw-bold mb-0'>{this.state.selectedDisput?.tournamentName}</h6>
                                    <p className='fw-bold text-white-50'></p>
                                </div>
                            </div>
                            <div className='dispute-match p-3 text-center'>
                                <div className='row'>
                                    {
                                        this.state.selectedDisput?.tournamentType==="FFA" ?
                                            <div className='col-12'>
                                                <div className='' style={{ overflowX: 'auto' }}>
                                                    <table className="table grey-card">
                                                        <thead>
                                                            <tr className='text-warning fs-16 fw-500'>
                                                                <th scope="col" className='fw-600 text-center'>Rank</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col" className='fw-600 text-center' style={{ minWidth: '100px' }}>Score</th>
                                                                {
                                                                    this.state.matchDetails?.results.map((el, j) => {
                                                                        return (
                                                                            <th key={j} scope="col" className='fw-600 text-center' style={{ minWidth: '100px' }}>Game {j + 1}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody className='table-borderless text-white '>
                                                            {
                                                                this.state.sortedParticipants.map((el, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th scope="row" className='fw-500 text-center'>{i + 1}</th>
                                                                            <td className='fw-600' >
                                                                                {el?.name}</td>
                                                                            <td className='text-center'>{el?.finalResult.score}</td>
                                                                            {
                                                                                el?.result.map((td, j) => {
                                                                                    return (
                                                                                        <td key={j} className='text-center'>{td.score}</td>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            :
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <img src={this.state.matchDetails?.participants[0] ? this.getURL(this.state.matchDetails?.participants[0]) : participantAvtar} className="rounded-circle w-50 " alt='' />
                                                        <h5 className='text-white fw-bold mt-3' style={{ fontSize: '15px' }}>{this.state.matchDetails?.participants[0]?.name}</h5>
                                                        <p className='text-white fw-bold'>{this.state.matchDetails?.finalResult[0].score}</p>
                                                    </div>
                                                    <div className='col-4'>
                                                        <h5 className='text-white fw-bold mt-1' style={{ fontSize: '30px' }}>{this.noOfGameWon(0)} - {this.noOfGameWon(1)}</h5>
                                                        <p className='text-white-50 fw-bold mb-0'>Score</p>
                                                    </div>
                                                    <div className='col-4'>
                                                        <img src={this.state.matchDetails?.participants[1] ? this.getURL(this.state.matchDetails?.participants[1]) : participantAvtar} className="rounded-circle w-50 " alt='' />
                                                        <h5 className='text-white fw-bold mt-3' style={{ fontSize: '15px' }}>{this.state.matchDetails?.participants[1]?.name}</h5>
                                                        <p className='text-white fw-bold'>{this.state.matchDetails?.finalResult[1].score}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>TOURNAMENT :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.tournamentName}</h6>
                                    </div>
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>GAME :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.gameName}</h6>
                                    </div>
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>STAGE :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.stageName}</h6>
                                    </div>
                                    <div className='col-12 d-flex justify-content-between mt-2'>
                                        <h6 className='text-white fw-bold mb-0'>ROUND :</h6>
                                        <h6 className='text-white-50 fw-bold mb-0'>{this.state.selectedDisput?.round}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </section>
        )
    }
}